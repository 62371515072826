import { Pipe, PipeTransform } from "@angular/core";
import { ZecInput } from "../models/shared";

@Pipe({
    name: "zecInput"
})
export class ZecInputPipe implements PipeTransform {
    transform(input: ZecInput): string {
        let type = input.type_string;
        if (type === "rtmp_pull" || type === "rtmp_push") type = "RTMP";
        if (input.error_code) {
            //  Assuming ZECs don't transcode.
            return (
                input.id + " (" + type + ": " + (input.error && input.error.length > 0 ? input.error : "Error") + ")"
            );
        } else if (input.status === "Offline") return input.id + " (" + type + ": " + input.status + ")";
        else if (type === "HLS") return input.id + " (" + type + ": " + input.source + ")";
        else return input.id + " (" + type + ": " + input.source + ")";
    }
}
