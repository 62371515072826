import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";

import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { MultipleConfirmDialogComponent } from "./multiple-confirm-dialog/multiple-confirm-dialog.component";
import { SingleDatepickerDialogComponent } from "./single-datepicker-dialog/single-datepicker-dialog.component";
import { HistoryGraphDialogComponent } from "./history-graph-dialog/history-graph-dialog.component";
import { AssignTargetDialogComponent } from "./assign-target-dialog/assign-target-dialog.component";
import { SwitchChannelDialogComponent } from "./switch-channel-dialog/switch-channel-dialog.component";
import { GenerateReportDialogComponent } from "./generate-report-dialog/generate-report-dialog.component";
import { ConfirmNavigationDialogComponent } from "./confirm-navigation-dialog/confirm-navigation-dialog.component";
import { AddIncidentDialogComponent } from "./add-incident-dialog/add-incident-dialog.component";
import { MultipleEditDialogComponent } from "./multiple-edit-dialog/multiple-edit-dialog.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { AgentZInstallConfirmationComponent } from "./agentz-install-confirm/agentz-install-confirm-dialog.component";
import { RootCauseAttributionDialogComponent } from "./root-cause-attribution-dialog/root-cause-attribution-dialog.component";
import { DeleteDisableBroadcasterDialogComponent } from "./delete-disable-broadcaster-dialog/delete-disable-broadcaster-dialog.component";
import {
    SharePageDialogComponent,
    CreateConfigs,
    RegeneratePasswordConfigs
} from "./share-page-dialog/share-page-dialog.component";
import { ClusterRedirectionRuleDialogComponent } from "./cluster-redirection-rule-dialog/cluster-redirection-rule-dialog.component";

import { AnyTarget, ChannelTypes } from "../../../pages/channels/channel";
import { MultipleConfirmDialogResult, SomeZixiObject, Tag } from "./../../../models/shared";
import { Cluster } from "../../../pages/clusters/cluster";
import { Report } from "../../../pages/reports/report";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { ZmEvent } from "../../../pages/events/event";
import { Broadcaster } from "./../../../models/shared";
import { Incident } from "./../../../pages/incidents/incident";
import { EditTagsDialogComponent } from "./edit-tags-dialog/edit-tags-dialog.component";
import { RedirectionRouting } from "@zixi/models";

@Injectable({
    providedIn: "root"
})
export class ModalService {
    navigateAway$: Subject<boolean> = new Subject<boolean>();

    constructor(private ngbModal: NgbModal, private mixpanelService: MixpanelService) {}

    async confirm(
        action: string,
        type: string,
        confirmHandler: (b?: boolean) => Promise<unknown>,
        name?: string,
        options?: {
            note?: string;
            warning?: string;
            checkbox?: string;
            checkboxNote?: string;
            deleteInput?: boolean;
            alternativeBodyText?: string;
        }
    ) {
        const modal = this.ngbModal.open(ConfirmDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.action = action;
        modal.componentInstance.type = type;
        if (name) modal.componentInstance.name = name;
        modal.componentInstance.confirmHandler = confirmHandler;
        //
        if (options && options.note) modal.componentInstance.note = options.note;
        if (options && options.warning) modal.componentInstance.warning = options.warning;
        if (options && options.checkbox) modal.componentInstance.checkbox = options.checkbox;
        if (options && options.checkboxNote) modal.componentInstance.checkboxNote = options.checkboxNote;
        if (options && options.deleteInput) modal.componentInstance.deleteInput = options.deleteInput;
        if (options?.alternativeBodyText) modal.componentInstance.alternativeBodyText = options.alternativeBodyText;
        //
        await modal.result;
        return modal.result;
    }

    async confirmMultiple<T>(
        action: string,
        type: string,
        objects: T[],
        confirmHandler: (object: T, b?: boolean) => Promise<unknown>,
        options?: { note?: string; warning?: string; checkbox?: string; checkboxNote?: string; deleteInput?: boolean }
    ): Promise<MultipleConfirmDialogResult> {
        const modal = this.ngbModal.open(MultipleConfirmDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.action = action;
        modal.componentInstance.type = type;
        modal.componentInstance.objects = objects;
        modal.componentInstance.confirmHandler = confirmHandler;
        //
        if (options && options.note) modal.componentInstance.note = options.note;
        if (options && options.warning) modal.componentInstance.warning = options.warning;
        if (options && options.checkbox) modal.componentInstance.checkbox = options.checkbox;
        if (options && options.checkboxNote) modal.componentInstance.checkboxNote = options.checkboxNote;
        if (options && options.deleteInput) modal.componentInstance.deleteInput = options.deleteInput;
        //
        await modal.result;
        return modal.result;
    }

    // Edit Multiple Custom Modal
    async editMultiple<T>(
        type: string,
        objects: T[],
        confirmHandler: (object: T, model) => Promise<unknown>,
        options?: { note?: string; warning?: string; customContainer?: string }
    ) {
        const modal = this.ngbModal.open(MultipleEditDialogComponent, {
            backdrop: "static",
            centered: true,
            keyboard: false,
            scrollable: true,
            windowClass: "mimic-form",
            backdropClass: "hide",
            container: options && options.customContainer ? options.customContainer : ".left-container"
        });

        modal.componentInstance.type = type;
        modal.componentInstance.objects = objects;
        modal.componentInstance.confirmHandler = confirmHandler;
        //
        if (options && options.note) modal.componentInstance.note = options.note;
        if (options && options.warning) modal.componentInstance.warning = options.warning;
        //
        await modal.result;
        return modal.result;
    }

    confirmNavigation() {
        const modal = this.ngbModal.open(ConfirmNavigationDialogComponent, { backdrop: "static", centered: true });
        return modal;
    }

    singleDatepicker() {
        const modal = this.ngbModal.open(SingleDatepickerDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "xxs"
        });
        return modal.result;
    }

    historyGraph(link: string) {
        const modal = this.ngbModal.open(HistoryGraphDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.link = link;
        return modal.result;
    }

    async editTags(object: SomeZixiObject, type: string, canEdit: boolean) {
        const modal = this.ngbModal.open(EditTagsDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.object = object;
        modal.componentInstance.type = type;
        modal.componentInstance.canEdit = canEdit;

        await modal.result;
        return modal.result;
    }

    async assignTarget(channel: ChannelTypes, resourceTags: Tag[]) {
        const modal = this.ngbModal.open(AssignTargetDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.channel = channel;
        modal.componentInstance.resourceTags = resourceTags;

        await modal.result;
        return modal.result;
    }

    async switchChannel(targets: AnyTarget[], type: string) {
        const modal = this.ngbModal.open(SwitchChannelDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.targets = targets;
        modal.componentInstance.type = type;

        await modal.result;
        return modal.result;
    }

    async generateReport(report: Report) {
        const modal = this.ngbModal.open(GenerateReportDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.report = report;

        await modal.result;
        return modal.result;
    }

    async addIncident(object, from: moment.Moment, to: moment.Moment, event?: ZmEvent, cause?: string, name?: string) {
        const modal = this.ngbModal.open(AddIncidentDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.object = object;
        modal.componentInstance.from = from;
        modal.componentInstance.to = to;
        modal.componentInstance.event = event;
        if (cause) modal.componentInstance.likelyCause = cause;
        if (name) modal.componentInstance.incidentName = name;

        await modal.result;
        return modal.result;
    }

    async editIncident(incident: Incident) {
        const modal = this.ngbModal.open(RootCauseAttributionDialogComponent, { backdrop: "static", centered: true });

        modal.componentInstance.incident = incident;

        await modal.result;
        return modal.result;
    }

    error(errorText: string) {
        const modal = this.ngbModal.open(ErrorDialogComponent, { backdrop: "static", centered: true, size: "lg" });
        modal.componentInstance.errorText = errorText;
    }

    async confirmAgentzInstall(broadcaster: Broadcaster, confirmHandler: () => Promise<unknown>) {
        const modal = this.ngbModal.open(AgentZInstallConfirmationComponent, { backdrop: "static", centered: true });
        modal.componentInstance.broadcasterName = broadcaster.name;
        modal.componentInstance.confirmHandler = confirmHandler;
        await modal.result;
        return modal.result;
    }

    openSharedPageDialog(dialogConfigs: CreateConfigs | RegeneratePasswordConfigs) {
        const modal = this.ngbModal.open(SharePageDialogComponent, { backdrop: "static", centered: true });
        modal.componentInstance.dialogConfigs = dialogConfigs;
    }

    async confirmDeleteDisableBroadcasterWithRecovery(
        action: "DISABLE" | "DELETE",
        name: string,
        bxId: number,
        clusterBroadcasters: Broadcaster[],
        confirmHandler: (revover: boolean, target_broadcaster_id: number | null) => Promise<unknown>
    ) {
        const modal = this.ngbModal.open(DeleteDisableBroadcasterDialogComponent, {
            backdrop: "static",
            centered: true
        });

        modal.componentInstance.action = action;
        modal.componentInstance.name = name;
        modal.componentInstance.subjectBroadcasterId = bxId;
        modal.componentInstance.fillBroadcasters(bxId, clusterBroadcasters);
        modal.componentInstance.confirmHandler = confirmHandler;

        await modal.result;
        return modal.result;
    }

    async openClusterRedirectionRuleDialog(
        broadcasterClusterId: number,
        onSuccessHandler: () => Promise<unknown>,
        redirectionRouting: RedirectionRouting = null
    ) {
        const modal = this.ngbModal.open(ClusterRedirectionRuleDialogComponent, {
            backdrop: "static",
            centered: true
        });
        modal.componentInstance.onSuccessHandler = onSuccessHandler;
        modal.componentInstance.broadcasterClusterId = broadcasterClusterId;
        modal.componentInstance.redirectionRouting = redirectionRouting;
    }
}
