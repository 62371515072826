import { Directive, EventEmitter, Input, Output, AfterViewInit } from "@angular/core";

export type SortDirection = "asc" | "desc" | "" | string;
const rotate: { [key: string]: SortDirection } = { asc: "desc", desc: "asc", "": "asc" };

export interface SortEvent {
    column: string;
    direction: SortDirection;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[sortable]",

    host: {
        "[class.active]": 'direction === "asc" || direction === "desc"',
        "[class.asc]": 'direction === "asc"',
        "[class.desc]": 'direction === "desc"',
        "(click)": "rotate()"
    }
})
export class NgbSortableHeader implements AfterViewInit {
    @Input() sortable: string;
    @Input() direction: SortDirection = "";
    @Output() sort = new EventEmitter<SortEvent>();

    @Input() sortIf?: boolean;
    @Input() sortDisabled?: boolean;
    @Input() sortDirection?: string;

    rotate(sortDirection?: string) {
        if (this.sortDisabled) return;

        if (sortDirection) {
            if (sortDirection === "asc") this.direction = "desc";
            else if (sortDirection === "desc") this.direction = "asc";
        }
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            if (this.sortIf) {
                this.rotate(this.sortDirection);
            }
        }, 0);
    }
}
