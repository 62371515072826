import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "multilevelFilter"
})

// This pipe filters multilevel objects - objects with properties that are objects
// [league: 'West',{name: {first: 'John', last: 'Smith'}}
// It employs javascript 'dot' syntax eg name.first = 'John'
// It is built to unwind t three leves but could be easily expanded
export class MultiLevelFilter implements PipeTransform {
    transform(items: Array<object>, filterProps: Array<string>, filterVal: string): Array<object> {
        if (filterVal && filterVal.length > 0) {
            return items.filter(item => {
                for (const filterProp of filterProps) {
                    const props = filterProp.split(".");
                    if (props.length === 1) {
                        if (item[props[0]].toLowerCase().indexOf(filterVal.toLowerCase()) > -1) return true;
                    }
                    if (props.length === 2) {
                        const val = item[props[0]][props[1]].toLowerCase();
                        if (val.indexOf(filterVal.toLowerCase()) > -1) return true;
                    }
                    if (props.length === 3) {
                        const val = item[props[0]][props[1][props[2]]].toLowerCase();
                        if (val.indexOf(filterVal.toLowerCase()) > -1) return true;
                    }
                }
                return false;
            });
        } else {
            return items;
        }
    }
}
