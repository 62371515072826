import { Component, Input } from "@angular/core";

export enum IconTypes {
    CHECK = "check"
}

@Component({
    selector: "app-icon-column",
    templateUrl: "./icon-column.component.html"
})
export class IconColumnComponent {
    @Input() iconType: IconTypes;
    @Input() showIcon: boolean;
}
