<div class="form-group">
    <label [ngClass]="{ 'is-invalid': invalid }">
        {{ labelText || textFieldName }}

        <fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon>
        <!-- RestartContent -->
        <app-restart-required-icon *ngIf="isRestartRequiredTip"></app-restart-required-icon>
        <!-- NameContent -->
        <fa-icon *ngIf="lockTip" icon="lock" size="sm" [ngbTooltip]="NameContent" triggers="hover click" [closeDelay]="500"> </fa-icon>
        <ng-template #NameContent>{{ lockTip | translate }}</ng-template>
        <!-- PUContent -->
        <fa-icon *ngIf="circleTip" icon="info-circle" [ngbTooltip]="PUContent" triggers="hover click" [closeDelay]="500"> </fa-icon>
        <ng-template #PUContent>
            <p class="mb-0">{{ circleTip | translate }}</p>
        </ng-template>
    </label>
    <input
        placeholder="{{ placeHolder || textFieldName }}"
        class="form-control"
        [type]="type"
        [autocomplete]="autoComplete"
        [ngClass]="{ 'is-invalid': invalid, disabled: disabled }"
        [duplicateName]="checkDuplicateNames"
        [disabled]="disabled"
        [readonly]="disabled"
        (focus)="markAsTouched()"
        (change)="_onChange($event.target.value)"
        [value]="controlledValue"
        [formControl]="ngControl.control"
    />
    <app-input-errors-indicator
        [errors]="ngControl.errors"
        [patternErrorMessage]="patternErrorMessage"
        [fieldName]="textFieldName"
        [invalid]="invalid"
    ></app-input-errors-indicator>
    <small *ngIf="underLabel">
        {{ underLabel }}
    </small>
    <ng-content> </ng-content>
</div>
