<div class="details-form">
    <div class="form-group">
        <h5 translate>TYPE</h5>
        <div>
            {{ translate.instant(cs.processChannelType(channel).toUpperCase()) }}
        </div>
    </div>
    <div class="form-group" *ngIf="adaptiveChannel.is_enabled && adaptiveChannel.view_url">
        <h5 translate>PLAY_IN_BROWSER</h5>
        <div>
            <a href="javascript:void(0)" (click)="cs.openChannelPreview(channel)" class="btn btn-play btn-primary"><fa-icon icon="play" size="xs"></fa-icon></a>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.is_enabled && adaptiveChannel.view_url">
        <h5 translate>PLAY_IN_VLC</h5>
        <div>
            <a [href]="fileUrl" download="{{ channel.name }}.vlc" class="btn btn-play btn-primary"><fa-icon icon="play" size="xs"></fa-icon></a>
        </div>
    </div>
    <div class="form-group" *ngIf="(adaptiveChannel || passThroughChannel)?.processingCluster as processingCluster">
        <h5 translate>PROCESSING_CLUSTER</h5>
        <div class="ellipsis">
            <zx-cluster
                [model]="processingCluster"
                [showOtherIcons]="true"
                [showStatusIcon]="true"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
            ></zx-cluster>
        </div>
    </div>
    <div *ngIf="channel.type === 'adaptive'">
        <div class="form-group" *ngIf="adaptiveChannel.status?.active_broadcaster">
            <h5 translate>ACTIVE_BROADCASTER</h5>
            <div class="ellipsis">
                <zx-broadcaster
                    [model]="adaptiveChannel.status?.active_broadcaster"
                    [showTag]="false"
                    [showLink]="true"
                    [showCluster]="false"
                    [showStatusIcon]="false"
                    [clusterId]="adaptiveChannel.broadcaster_cluster_id"
                ></zx-broadcaster>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.mediaconnect">
        <h5 translate>REGION</h5>
        <div>
            <span>{{ getAWSRegionName(mediaConnectFlow.region) }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.failover && (channel.failoverSource.failoverSources | filter : 'priority' : '2')?.length > 0">
        <h5>{{ "PREFERRED" | translate }} {{ "SOURCES" | translate }}</h5>
        <div>
            <div class="ellipsis" *ngFor="let fs of channel.failoverSource.failoverSources | filter : 'priority' : '2'">
                <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.failover && (channel.failoverSource.failoverSources | filter : 'priority' : '1')?.length > 0">
        <h5>{{ "SECONDARY" | translate }} {{ "SOURCES" | translate }}</h5>
        <div>
            <div class="ellipsis" *ngFor="let fs of channel.failoverSource.failoverSources | filter : 'priority' : '1'">
                <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.failover && (channel.failoverSource.failoverSources | filter : 'priority' : '0')?.length > 0">
        <h5>{{ "SLATE" | translate }} {{ "SOURCE" | translate }}</h5>
        <div>
            <div class="ellipsis" *ngFor="let fs of channel.failoverSource.failoverSources | filter : 'priority' : '0'">
                <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
            </div>
        </div>
    </div>
    <div *ngIf="channel.type === 'adaptive'">
        <div class="form-group" *ngIf="adaptiveChannel.is_transcoding">
            <h5 translate>SOURCE</h5>
            <div class="ellipsis">
                <zx-source
                    [model]="adaptiveChannel.bitrates[0].source"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-source>
            </div>
        </div>
    </div>
    <div *ngIf="channel.type === 'adaptive'">
        <div class="form-group" *ngIf="adaptiveChannel.is_transcoding && adaptiveChannel.slateSource">
            <h5 translate>SLATE_SOURCE</h5>
            <div class="ellipsis">
                <zx-source
                    [model]="adaptiveChannel.slateSource"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-source>
            </div>
        </div>
    </div>
    <div *ngIf="channel.type === 'delivery'">
        <div class="form-group" *ngIf="passThroughChannel.target_broadcaster_id <= 0">
            <h5>{{ "TARGET_BROADCASTER_S" | translate }}</h5>
            <div>
                <span *ngIf="passThroughChannel.target_broadcaster_id === 0">{{ "ANY" | translate }}</span>
                <span *ngIf="passThroughChannel.target_broadcaster_id === -2">{{ "PRIMARY_BROADCASTERS_ONLY" | translate }}</span>
                <span *ngIf="passThroughChannel.target_broadcaster_id === -3">{{ "BACKUP_BROADCASTERS_ONLY" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="passThroughChannel.target_broadcaster_id > 0">
            <h5>{{ "TARGET_BROADCASTER_S" | translate }}</h5>
            <div>
                <zx-broadcaster
                    *ngIf="targetBroadcaster | async as bxModel; else loading"
                    [model]="bxModel"
                    [showTag]="false"
                    [showLink]="true"
                    [showCluster]="false"
                    [showStatusIcon]="true"
                    [clusterId]="passThroughChannel.broadcaster_cluster_id"
                ></zx-broadcaster>
                <ng-template #loading>
                    <spinner-animation-inline></spinner-animation-inline>
                </ng-template>
            </div>
        </div>

        <div class="form-group" *ngIf="primarySources > 0">
            <h5>{{ "PRIMARY" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let source of channel.sources">
                    <zx-source *ngIf="source.primary" [model]="source.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="source.primary && getActiveBroadcaster(source.source)">
                        <br />{{ "ACTIVE_BROADCASTER" | translate }}:
                        <zx-broadcaster
                            [model]="getActiveBroadcaster(source.source)"
                            [showTag]="false"
                            [showLink]="true"
                            [showCluster]="false"
                            [showStatusIcon]="true"
                            [clusterId]="passThroughChannel.broadcaster_cluster_id"
                        ></zx-broadcaster>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="backupSources > 0">
            <h5>{{ "BACKUP" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let source of channel.sources">
                    <zx-source *ngIf="!source.primary" [model]="source.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="!source.primary && getActiveBroadcaster(source.source)">
                        <br />{{ "ACTIVE_BROADCASTER" | translate }}:
                        <zx-broadcaster
                            [model]="getActiveBroadcaster(source.source)"
                            [showTag]="false"
                            [showLink]="true"
                            [showCluster]="false"
                            [showStatusIcon]="true"
                            [clusterId]="passThroughChannel.broadcaster_cluster_id"
                        ></zx-broadcaster>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="channel.mediaconnect">
        <div class="form-group" *ngIf="mediaConnectFlow.source">
            <h5 translate>SOURCE</h5>
            <div class="ellipsis">
                <zx-mediaconnect-source
                    [model]="mediaConnectFlow.source"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-mediaconnect-source>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>MEDIACONNECT_FLOW</h5>
            <div>
                <span>
                    <a [href]="cs.getFlowLink(channel)" target="_blank">{{ cs.getFlowName(channel) }} <fa-icon icon="external-link-alt" size="sm"></fa-icon></a>
                </span>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.description">
            <h5 translate>DESCRIPTION</h5>
            <div>
                <span>
                    {{ channel.description }}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="channel.medialive">
        <div class="form-group" *ngIf="mediaLiveChannel.source">
            <h5 translate>SOURCE</h5>
            <div class="ellipsis">
                <zx-mediaconnect-source
                    [model]="mediaLiveChannel.source"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-mediaconnect-source>
            </div>
        </div>
        <div class="form-group" *ngIf="mediaLiveChannel.flow">
            <h5 translate>MEDIACONNECT_FLOW</h5>
            <div class="ellipsis">
                <zx-mediaconnect-flow
                    [model]="mediaLiveChannel.flow"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-mediaconnect-flow>
            </div>
        </div>
        <div class="form-group" *ngIf="mediaLiveChannel.arn">
            <h5 translate>MEDIALIVE_CHANNEL</h5>
            <div>
                <span>
                    <a [href]="cs.getChannelLink(mediaLiveChannel)" target="_blank"
                        >{{ mediaLiveChannel.name }} <fa-icon icon="external-link-alt" size="sm"></fa-icon
                    ></a>
                </span>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.altChannel">
        <h5>{{ "ALTERNATIVE_CHANNEL" | translate }}</h5>
        <div>
            <zx-adaptive *ngIf="channel.type === 'adaptive'" [model]="channel.altChannel" [showLink]="true" [showTag]="false"></zx-adaptive>
            <zx-delivery *ngIf="channel.type === 'delivery'" [model]="channel.altChannel" [showLink]="true" [showTag]="false"></zx-delivery>
            <zx-failover-channel *ngIf="channel.type === 'failover'" [model]="channel.altChannel" [showLink]="true" [showTag]="false"></zx-failover-channel>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.type === 'adaptive'">
        <h5 translate>MODE</h5>
        <div>
            <span *ngIf="!adaptiveChannel.is_transcoding">{{ "EXISTING_SOURCES" | translate }}</span>
            <span *ngIf="adaptiveChannel.is_transcoding">{{ "TRANSCODING" | translate }}</span>
        </div>
    </div>
    <div *ngIf="channel.type === 'adaptive'">
        <div class="form-group">
            <h5 translate>PROTOCOLS</h5>
            <div>
                {{ protocols.join(", ") }}
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.alertingProfile">
        <h5 translate>ALERTING_PROFILE</h5>
        <div>
            <a [routerLink]="['/' + urls.configuration.eventsManagement, channel.alertingProfile.id]">
                {{ channel.alertingProfile.name }}
            </a>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.type === 'transcoded'">
        <h5 translate>CLOSED_CAPTION_PRESERVE</h5>
        <div>
            <span *ngIf="adaptiveChannel.copy_closed_captions">{{ "ENABLED" | translate }}</span>
            <span *ngIf="!adaptiveChannel.copy_closed_captions">{{ "DISABLED" | translate }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="channel.failover && channel.failoverSource">
        <h5 translate>CONTENT_ANALYSIS</h5>
        <div>
            <span *ngIf="channel.failoverSource.content_analysis">{{ "ENABLED" | translate }}</span>
            <span *ngIf="!channel.failoverSource.content_analysis">{{ "DISABLED" | translate }}</span>
        </div>
    </div>
</div>
