import { Component, Input } from "@angular/core";

@Component({
    selector: "app-incidents-name-column",
    templateUrl: "./incidents-name-column.component.html"
})
export class IncidentsNameColumnComponent {
    @Input() isZixiIncident = false;
    @Input() incidentName: string;
    @Input() searchTerm: string;
}
