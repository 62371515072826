import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "objectTypeLetter"
})
export class ObjectTypeLetterPipe implements PipeTransform {
    transform(value: string): string {
        if (!value || value.length === 0) return "";

        value = this.objTypeLetter(value);
        if (value === "delivery_channel") return "C";
        if (value === "pid_mapping_profile") return "PMP";
        if (value === "pid_mapping_profiles") return "PMP";
        if (value === "task_sets") return "TS";
        if (value === "user") return "U";
        if (value === "broadcaster_cluster") return "BC";
        return value;
    }

    objTypeLetter(type: string) {
        switch (type) {
            case "incident":
            case "incidents":
                return "I";
            case "zec":
                return "Z";
            case "feeder":
                return "F";
            case "receiver":
                return "R";
            case "source":
            case "mediaconnect_sources":
                return "S";
            case "broadcaster":
                return "B";
            case "channel":
            case "adaptive_channel":
            case "delivery_channel":
            case "failover_channel":
            case "mediaconnect_flows":
            case "medialive_channels":
                return "C";
            case "target":
            case "publishing_target":
            case "zixi_pull":
            case "zixi_push":
            case "rtmp_push":
            case "rtmp":
            case "push":
            case "pull":
            case "mediaconnect":
            case "mediastore":
            case "http":
            case "azure":
            case "gcp":
            case "s3":
            case "udp_rtp":
            case "srt_targets":
            case "ndi_targets":
            case "mediaconnect_cdi_targets":
            case "cdi":
            case "medialive_http_targets":
            case "medialive_http":
            case "mediaconnect_jpegxs_targets":
            case "jpegxs":
            case "srt":
            case "ndi":
            case "rist":
            case "youtube":
                return "T";
            case "remote_access":
                return "RA";
            default:
                return type;
        }
    }
}
