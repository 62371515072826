<!-- Grid Stack -->
<div class="gridstack-container" [ngClass]="{ isTab: !isMultiSelect, widgetResizing: resizing }">
    <gridstack [options]="gridOptions" (changeCB)="widgetChanged($event)" [ngClass]="{ 'opacity-0': refreshing }">
        <gridstack-item *ngFor="let widget of localWidgets; let i = index; trackBy: identify" [options]="widget">
            <div class="grid-item-container" id="{{ widget.title }}" [tourAnchor]="i === 0 ? 'tenthCustomLayoutAnchor' : ''">
                <div class="grid-item">
                    <h3 *ngIf="(isMultiSelect || isMobile) && !showLinkTitle" [tourAnchor]="i === 0 ? 'eleventhCustomLayoutAnchor' : ''">
                        {{ widget.title }}
                        <div class="drag-button" *ngIf="isMultiSelect && !isLocked">
                            <fa-icon [ngStyle]="{ top: '1px', position: 'relative' }" icon="grip-lines-vertical" size="sm"></fa-icon>
                        </div>
                    </h3>
                    <h3 *ngIf="(isMultiSelect || isMobile) && showLinkTitle" [tourAnchor]="i === 0 ? 'eleventhCustomLayoutAnchor' : ''">
                        <a href="javascript:void(0)" [ngStyle]="{ 'z-index': 2, position: 'relative' }" title="{{ widget?.name }}" (click)="goToLink(widget)">
                            {{ widget.title }}
                        </a>
                        <div class="drag-button" *ngIf="isMultiSelect && !isLocked">
                            <fa-icon [ngStyle]="{ top: '1px', position: 'relative' }" icon="grip-lines-vertical" size="sm"></fa-icon>
                        </div>
                    </h3>
                    <div class="widget-container">
                        <ng-container #container></ng-container>
                    </div>
                </div>
            </div>
        </gridstack-item>
    </gridstack>
</div>
<div style="display: none">
    <ng-container #hiddencontainer></ng-container>
</div>
