import { Pipe, PipeTransform } from "@angular/core";
import { BroadcasterInput } from "./../models/shared";

@Pipe({
    name: "broadcasterInputSource"
})
export class BroadcasterInputSourcePipe implements PipeTransform {
    transform(input: BroadcasterInput): string {
        if (input.type_string === "HLS") return input.hls_url;
        else return input.source;
    }
}
