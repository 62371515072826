<div class="details-form">
    <div class="form-group row">
        <h5 class="col-12" translate>NAME</h5>
        <div class="col-12">
            <span>{{ source.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="!source.medialive_channel_id">
        <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
        <div class="col-12">
            <span *ngIf="!source.mediaconnect_flow_id">-</span>
            <zx-mediaconnect-flow
                [model]="source.mediaconnectFlow"
                [showStatusIcon]="false"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
                *ngIf="source.mediaconnect_flow_id"
            ></zx-mediaconnect-flow>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.medialive_channel_id">
        <h5 class="col-12" translate>AWS_MEDIALIVE_CHANNEL</h5>
        <div class="col-12">
            <zx-medialive-channel
                [model]="source.mediaLiveChannel"
                [showStatusIcon]="false"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
            ></zx-medialive-channel>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.resourceTags && source.resourceTags.length">
        <h5 class="col-12" translate>TAGS</h5>
        <div class="col-12">
            <span *ngFor="let tag of source.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>TYPE</h5>
        <div class="col-12">
            <span>{{ "TO_MEDIACONNECT" | translate }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.awsAccount">
        <h5 class="col-12" translate>AWS_ACCOUNT</h5>
        <div class="col-12">
            <div class="ellipsis">
                <span>{{ source.awsAccount.name }}</span>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.elemental_link_region">
        <h5 class="col-12" translate>REGION</h5>
        <div class="col-12">
            <div class="ellipsis">
                <span>{{ source.elemental_link_region }}</span>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.feeder_id || source.broadcaster_id || source.input_id || source.elemental_link_id">
        <h5 class="col-12" translate>INPUT</h5>
        <div class="col-12">
            <zx-feeder *ngIf="source.feeder_id" [model]="source.feeder" [showTag]="true" [showLink]="true" [showStatusIcon]="false"></zx-feeder>
            <zx-broadcaster
                *ngIf="source.broadcaster_id"
                [model]="source.broadcaster"
                [showTag]="true"
                [showLink]="true"
                [showCluster]="false"
                [showStatusIcon]="false"
            ></zx-broadcaster>
            <span *ngIf="source.input_id"><span *ngIf="source.broadcaster_id || source.feeder_id">&nbsp;/&nbsp;</span>{{ source.input_id }}</span>
            <span *ngIf="source.elemental_link_id">{{ "ELEMENTAL_LINK" | translate }}&nbsp;/&nbsp;{{ source.elemental_link_id }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.feeder_id">
        <h5 class="col-12" translate>MAX_BITRATE</h5>
        <div class="col-12">
            <span>{{ source.max_bitrate | bitrate }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.feeder_id || source.broadcaster_id">
        <h5 class="col-12">{{ "LATENCY" | translate }} [ms]</h5>
        <div class="col-12">
            <span>{{ source.latency }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="source.alertingProfile">
        <h5 class="col-12" translate>ALERTING_PROFILE</h5>
        <div class="col-12">
            <span>{{ source.alertingProfile.name }}</span>
        </div>
    </div>
</div>
