import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-form-wrapper",
    templateUrl: "./form-wrapper.component.html",
    styleUrls: ["./form-wrapper.component.scss"]
})
export class FormWrapperComponent implements OnInit {
    @Input() isEdit = false;
    @Input() isClone = false;
    @Input() titleMsg: string;
    @Input() loading?: boolean;
    @Input() hasTourStartButton? = false;
    @Input() openTitleToolTip? = false;
    @Input() tourStartTooltipString?: string = "";
    @Input() mixpanelString?: string = "";

    public form: UntypedFormGroup;

    constants = Constants;

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        this.form = <UntypedFormGroup>this.controlContainer?.control;
    }
}
