import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[dynamicDuplicate]",
    providers: [{ provide: NG_VALIDATORS, useExisting: DynamicDuplicateDirective, multi: true }]
})
export class DynamicDuplicateDirective implements Validator {
    //  Trying to support undisclosed types.
    @Input() dynamicDuplicate: string;

    validate(control: AbstractControl): { [key: string]: unknown } | null {
        if (!this.dynamicDuplicate || control.value == null) return null;
        const strVal = control.value.toString();
        const stringValues = this.dynamicDuplicate.split(",");
        const containsDuplicates = stringValues.includes(strVal);
        return containsDuplicates ? { duplicateValue: true } : null;
    }
}
