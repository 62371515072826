<form
    zmid="multi-confirm-dialog"
    id="confirmForm"
    #confirmForm="ngForm"
    (ngSubmit)="confirmForm.valid && (checkboxIsChecked ? onSubmitIgnoreDeleted() : onSubmit())"
    [ngClass]="{ loading: saving }"
>
    <div class="modal-header">
        <h3 class="modal-title" *ngIf="state === 'default'">
            {{ action | translate }} {{ type | translate }}<span *ngIf="type !== 'REMOTE_ACCESS'">s</span><span *ngIf="type === 'REMOTE_ACCESS'">es</span>
        </h3>
        <h3 class="modal-title" *ngIf="state === 'done'">
            {{ action | translate }} {{ type | translate }}<span *ngIf="type !== 'REMOTE_ACCESS'">s</span><span *ngIf="type === 'REMOTE_ACCESS'">es</span> -
            {{ "COMPLETE" | translate }}
        </h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Question -->
        <p *ngIf="state === 'default'">
            {{ "DO_YOU_WANT_TO" | translate }} {{ action | translate | lowercase }} {{ "THE_FOLLOWING" | translate | lowercase }} {{ objectsStore.length }}
            {{ type | translate | lowercase }}<span *ngIf="type !== 'REMOTE_ACCESS'">s</span><span *ngIf="type === 'REMOTE_ACCESS'">es</span>?
        </p>
        <!-- No Action Taken -->
        <p *ngIf="state === 'done' && !statuses.hasActionTaken">
            {{ "NO" | translate }} {{ type | translate | lowercase }}s {{ "WERE" | translate | lowercase }} {{ action | translate | lowercase
            }}{{ action === "RESET" ? "" : "d" }}.
        </p>
        <!-- Done -->
        <p *ngIf="state === 'done' && statuses.hasActionTaken">
            {{ "THESE" | translate }} {{ type | translate | lowercase }}s {{ "WERE" | translate | lowercase }} {{ action | translate | lowercase
            }}{{ action === "RESET" ? "" : "d" }}:
        </p>
        <!-- Object List -->
        <ul class="list-style-none mimic-tags" *ngIf="statuses.hasActionTaken || state === 'default'">
            <li *ngFor="let object of objectsStore" [ngClass]="{ hide: object.hasError || object.ignored }">
                <div *ngIf="!object.hasError && !object.ignored" class="ellipsis">
                    <app-dialog-object [object]="object.object" [type]="type"></app-dialog-object
                    ><span *ngIf="object.saving && object.processing === 'start'">
                        &nbsp;-&nbsp;<span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>&nbsp;{{
                            "PROCESSING" | translate
                        }}...
                    </span>
                    <span *ngIf="!object.saving && !object.hasError && !object.ignored && object.processing === 'end'"
                        >&nbsp;-&nbsp;<span>{{ action | translate | titlecase }}{{ action === "RESET" ? "" : "d" }}</span>
                    </span>
                </div>
            </li>
        </ul>
        <!-- Ignored/Skipped -->
        <p *ngIf="statuses.hasIgnored">
            {{ "THESE" | translate }} {{ type | translate | lowercase }}s {{ "WERE" | translate | lowercase }} {{ "SKIPPED" | translate | lowercase }}:
        </p>
        <!-- Object List -->
        <ul *ngIf="statuses.hasIgnored" class="list-style-none mimic-tags">
            <li *ngFor="let object of objectsStore" [ngClass]="{ hide: !object.ignored }">
                <div *ngIf="object.ignored" class="ellipsis">
                    <app-dialog-object [object]="object.object" [type]="type"></app-dialog-object
                    ><span *ngIf="!object.saving && !object.hasError && object.ignored && object.processing === 'end'"
                        >&nbsp;-&nbsp;<span>{{ "SKIPPED" | translate }}</span>
                    </span>
                </div>
            </li>
        </ul>
        <!-- Error -->
        <p [hidden]="!statuses.hasError" class="mt-3" zmid="confirmation-error">
            {{ "CANT" | translate }} {{ action | translate | lowercase }} {{ "THESE" | translate | lowercase }} {{ type | translate | lowercase }}s:
        </p>
        <!-- Error List -->
        <ul *ngIf="statuses['hasError']" class="mb-0 list-style-none mimic-tags">
            <li *ngFor="let object of objectsStore" [ngClass]="{ hide: !object.hasError }">
                <div *ngIf="object.hasError">
                    <app-dialog-object [object]="object.object" [type]="type"></app-dialog-object>
                    <br /><span class="status-error" title="{{ 'ERROR' | translate }}:&nbsp;{{ object.error.message }}"
                        >{{ "ERROR" | translate }}:&nbsp;{{ object.error.message }}</span
                    >
                </div>
            </li>
        </ul>
        <!-- Note -->
        <p *ngIf="note">
            <span>{{ "NOTE" | translate }}: {{ note | translate }}</span>
        </p>
        <!-- Warning -->
        <p *ngIf="warning">
            <span>
                <strong>{{ "WARNING" | translate }}:</strong> {{ warning | translate }}
            </span>
        </p>
        <!-- Checkbox -->
        <div class="form-group mt-3" *ngIf="checkbox && type === 'TAG'">
            <div class="form-check lh-15">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="checkbox"
                    name="checkbox"
                    [(ngModel)]="checkboxModel"
                    [disabled]="type === 'TAG' ? saving : state === 'done' || saving"
                    (change)="type === 'TAG' && toggleChecked($event)"
                />
                <label class="form-check-label" for="checkbox">{{ checkbox | translate }}</label>
            </div>
            <small
                *ngIf="checkboxNote"
                class="pt-2"
                [ngStyle]="{
                    'padding-left.px': 20
                }"
                >{{ checkboxNote | translate }}</small
            >
        </div>
        <!-- Delete Input -->
        <div class="form-group mb-0" *ngIf="deleteInput">
            <label for="d" [ngClass]="{ 'is-invalid': confirmForm.submitted && d.errors }"
                >{{ "TO_CONFIRM_DELETION_TYPE" | translate }} <em>{{ "DELETE" | translate | lowercase }}</em> {{ "INTO_THE_FIELD" | translate
                }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <input
                type="text"
                id="d"
                name="d"
                class="form-control"
                [ngClass]="{ 'is-invalid': confirmForm.submitted && d.errors }"
                [(ngModel)]="deleteInputModel"
                required
                pattern="^delete$"
                #d="ngModel"
            />
            <div *ngIf="d.invalid" class="invalid-feedback">
                <div *ngIf="d.errors.required">{{ "DELETE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                <div *ngIf="d.errors.pattern">{{ "DELETE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            </div>
        </div>
        <!-- Error -->
        <app-error [hidden]="type === 'TAG'" [marginBottom]="false" [marginTop]="true"></app-error>
    </div>
    <div class="modal-footer flex-nowrap">
        <!-- Checkbox -->
        <div class="flex-grow-1 flex-shrink-1 m-0" *ngIf="action !== 'DELETE'">
            <div class="form-group mb-0">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="keep" name="keep" [(ngModel)]="keepSelected" />
                    <label class="form-check-label" for="keep"
                        >{{ "KEEP" | translate }} {{ type | translate | lowercase }}<span *ngIf="type !== 'REMOTE_ACCESS'">s</span
                        ><span *ngIf="type === 'REMOTE_ACCESS'">es</span> {{ "SELECTED_ON_MODAL_CLOSE" | translate }}</label
                    >
                </div>
            </div>
        </div>
        <!-- Buttons -->
        <div class="flex-shrink-0 m-0">
            <button type="button" class="btn btn-outline-secondary me-2" [disabled]="saving" (click)="closeModal()" *ngIf="state !== 'done'">
                <fa-icon icon="times"></fa-icon>
                {{ "CANCEL" | translate }}
            </button>
            <button
                zmid="submit-confirmation"
                type="button"
                class="btn"
                [ngClass]="{
                    'btn-danger': action === 'DELETE',
                    'btn-success': action === 'ENABLE',
                    'btn-warning': action === 'DISABLE',
                    'btn-primary': action !== 'DISABLE' && action !== 'ENABLE' && action !== 'DELETE'
                }"
                ngbAutofocus
                [disabled]="saving || (type === 'TAG' && !checkboxIsChecked && tagIsDeletable === true)"
                zmid="form-submit"
                type="submit"
                *ngIf="type === 'TAG' ? tagIsDeletable !== false : state !== 'done'"
            >
                <fa-icon *ngIf="action === 'DELETE'" icon="trash-alt" size="sm"></fa-icon>
                <fa-icon *ngIf="action === 'DISABLE'" icon="ban" size="sm"></fa-icon>
                <fa-icon *ngIf="action === 'ENABLE'" icon="power-off" size="sm"></fa-icon>
                <fa-icon *ngIf="action === 'MUTE'" icon="bell-slash" size="sm"></fa-icon>
                <fa-icon *ngIf="action === 'UNMUTE'" icon="bell" size="sm"></fa-icon>
                {{ action | translate }} ({{ objectsStore.length }})<span
                    *ngIf="saving"
                    class="spinner-border spinner-border-sm ms-1"
                    role="status"
                    aria-hidden="true"
                ></span>
            </button>
            <button zmid="close-confirmation" type="button" class="btn btn-secondary" [disabled]="saving" (click)="closeModal()" *ngIf="state === 'done'">
                {{ "CLOSE" | translate }}
            </button>
        </div>
    </div>
</form>
