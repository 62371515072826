import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { ZecsService } from "../../../pages/zecs/zecs.service";
import { Feeder, Receiver, Zec } from "../../../pages/zecs/zecs/zec";
import { ZecTypes } from "src/app/pages/zecs/zec-list/zec-list.component";

export type ZECRole = "Feeder" | "Receiver" | "All";
@Component({
    selector: "zx-zec-select",
    templateUrl: "./zx-zec-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxZecSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() filterRole?: ZECRole;
    @Input() zecType? = "ZEC";
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    zecs: ZecTypes[];
    private zecsSubscription: Subscription;

    constructor(private zs: ZecsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.zecType = this.zecType.toUpperCase();
        switch (this.zecType) {
            case "FEEDER":
                this.getFeedersToSelect();
                break;
            case "RECEIVER":
                this.getReceiversToSelect();
                break;
            default:
                this.getZecsToSelect();
                break;
        }
        this.loading = false;
    }

    getFeedersToSelect() {
        this.zs.refreshZecs("FEEDER");
        this.zecsSubscription = this.zs.feeders$.subscribe(feeders => {
            this.zecs = [...feeders] as Feeder[];
        });
    }

    getReceiversToSelect() {
        this.zs.refreshZecs("RECEIVER");
        this.zecsSubscription = this.zs.receivers$.subscribe(receivers => {
            this.zecs = [...receivers] as Receiver[];
        });
    }

    getZecsToSelect() {
        this.zs.refreshZecs("ZEC");
        this.zecsSubscription = this.zs.zecs$.subscribe(zecs => {
            this.zecs = [...zecs] as Zec[];
            switch (this.filterRole) {
                case "Feeder":
                    this.zecs = this.zecs.filter(z => z.feeder);
                    break;
                case "Receiver":
                    this.zecs = this.zecs.filter(z => z.receiver);
                    break;
                default:
                    break;
            }
        });
    }

    ngOnDestroy() {
        this.zecsSubscription.unsubscribe();
    }
}
