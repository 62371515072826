import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "typeof"
})
export class TypeofPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any): any {
        return typeof value;
    }
}
