import { Directive, Input } from "@angular/core";
import { NG_VALIDATORS, Validator, ValidationErrors, UntypedFormGroup } from "@angular/forms";

import { MustMatch } from "./../validators/must-match.validator";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[mustMatch]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MustMatchDirective, multi: true }]
})
export class MustMatchDirective implements Validator {
    @Input() mustMatch: string[] = [];

    validate(formGroup: UntypedFormGroup): ValidationErrors {
        return MustMatch(this.mustMatch[0], this.mustMatch[1])(formGroup);
    }
}
