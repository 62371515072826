import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { firstValueFrom, Observable, of, ReplaySubject, Subscriber } from "rxjs";
import { share, map, catchError } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { SharedService } from "../../services/shared.service";
import { Feeder, Receiver, Zec } from "./zecs/zec";
import { AuthService } from "src/app/services/auth.service";
import { APIResponse, MediaConnectFlow, Source } from "src/app/models/shared";
import { AdaptiveChannel, DeliveryChannel, FailoverChannel, MediaLiveChannel } from "../channels/channel";

import * as _ from "lodash";
import moment from "moment";
import { ZecTypes } from "./zec-list/zec-list.component";
import { Router } from "@angular/router";

export type ZecType = "FEEDER" | "RECEIVER" | "ZEC";
@Injectable({
    providedIn: "root"
})
export class ZecsService {
    zecs$: Observable<ZecTypes[]>;
    private zecsSubject$: ReplaySubject<ZecTypes[]>;

    receivers$: Observable<ZecTypes[]>;
    private receiversSubject$: ReplaySubject<ZecTypes[]>;

    feeders$: Observable<ZecTypes[]>;
    private feedersSubject$: ReplaySubject<ZecTypes[]>;

    private dataStore: {
        zecs: ZecTypes[];
        receivers: ZecTypes[];
        feeders: ZecTypes[];
    };

    private lastRefresh = {
        ZEC: null,
        FEEDER: null,
        RECEIVER: null
    };

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private sharedServices: SharedService,
        private router: Router
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            zecs: [],
            receivers: [],
            feeders: []
        };

        this.lastRefresh = {
            ZEC: null,
            FEEDER: null,
            RECEIVER: null
        };

        this.zecsSubject$ = new ReplaySubject(1) as ReplaySubject<ZecTypes[]>;
        this.zecs$ = this.zecsSubject$.asObservable();

        this.receiversSubject$ = new ReplaySubject<ZecTypes[]>(1);
        this.receivers$ = this.receiversSubject$.asObservable();

        this.feedersSubject$ = new ReplaySubject(1) as ReplaySubject<ZecTypes[]>;
        this.feeders$ = this.feedersSubject$.asObservable();
    }

    private prepZec(zec: ZecTypes, zecType: ZecType) {
        zec._frontData = {
            sortableStatus: "",
            lastRefresh: moment().format()
        };

        this.sharedServices.prepStatusSortFields(zec);
        if (zec.resourceTags)
            zec.resourceTags.sort((a, b) => (a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1));
        let typedZec: ZecTypes = null;
        zecType === "RECEIVER"
            ? (typedZec = new Receiver())
            : zecType === "FEEDER"
            ? (typedZec = new Feeder())
            : (typedZec = new Zec());
        Object.assign(typedZec, zec);
        return typedZec;
    }

    private updateZecStore(newZec: ZecTypes, merge: boolean, zecType: ZecType): void {
        newZec = this.prepZec(newZec, zecType);
        const dataStoreItems = this.findDataStoreType(zecType);
        const currentZecIndex = dataStoreItems.findIndex(zec => zec.id === newZec.id);
        if (currentZecIndex === -1) {
            dataStoreItems.push(newZec);
            return;
        } else if (merge) {
            const currentZec = dataStoreItems[currentZecIndex];

            Object.assign(currentZec, newZec);
        } else {
            dataStoreItems[currentZecIndex] = newZec;
        }
        zecType === "ZEC"
            ? (this.dataStore.zecs = dataStoreItems)
            : zecType === "RECEIVER"
            ? (this.dataStore.receivers = dataStoreItems)
            : (this.dataStore.feeders = dataStoreItems);
    }

    refreshZecs(zecType: ZecType, force?: boolean): Observable<ZecTypes[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastRefresh[zecType] <= 60000)
            return zecType === "ZEC" ? this.zecs$ : zecType === "RECEIVER" ? this.receivers$ : this.feeders$;
        this.lastRefresh[zecType] = _.now();

        const zecsSubject$ = this.http
            .get<APIResponse<ZecTypes[]>>(Constants.apiUrl + this.findUrlType(zecType))
            .pipe(share());

        zecsSubject$.subscribe(
            data => {
                const zecs: ZecTypes[] = data.result;
                const dataStoreItems = this.findDataStoreType(zecType);
                dataStoreItems.forEach((existingZec, existingIndex) => {
                    const newIndex = zecs.findIndex(zec => zec.id === existingZec.id);
                    if (newIndex === -1) dataStoreItems.splice(existingIndex, 1);
                });
                zecType === "ZEC"
                    ? (this.dataStore.zecs = dataStoreItems)
                    : zecType === "RECEIVER"
                    ? (this.dataStore.receivers = dataStoreItems)
                    : (this.dataStore.feeders = dataStoreItems);

                zecs.forEach(refreshedZec => this.updateZecStore(refreshedZec, true, zecType));
                this.assignDataStoreByType(zecType);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ZECS"), error)
        );
        return zecsSubject$.pipe(map(r => r.result));
    }

    refreshZec(val: string | number, zecType: ZecType, force?: boolean) {
        const dataStoreItems = this.findDataStoreType(zecType);
        if (!force && dataStoreItems && dataStoreItems.length) {
            let zec: ZecTypes;
            if (typeof val === "number") zec = dataStoreItems.find(z => z.id === val);
            else zec = dataStoreItems.find(f => f.name === val);
            //
            if (zec) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(zec._frontData.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<ZecTypes>) => {
                        observe.next(zec);
                        observe.complete();
                    });
                }
            }
        }

        const id: number = typeof val === "number" ? val : dataStoreItems.find(zec => zec.name === val).id;

        const zec$ = this.http
            .get<APIResponse<ZecTypes>>(Constants.apiUrl + this.findUrlType(zecType) + "/" + id)
            .pipe(share());

        zec$.subscribe(
            data => {
                const zec: ZecTypes = data.result;
                zec.hasFullDetails = true;

                this.updateZecStore(zec, false, zecType);

                this.assignDataStoreByType(zecType);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ZEC"), error)
        );
        return zec$.pipe(map(r => r.result));
    }

    getCachedZec(zecType: ZecType, name?: string, id?: number) {
        const dataStoreItems = this.findDataStoreType(zecType);
        if (dataStoreItems && name) return dataStoreItems.find(zec => zec.name === name);
        if (dataStoreItems && id) return dataStoreItems.find(zec => zec.id === id);
        return undefined;
    }

    async deleteZec(zec: ZecTypes, zecType: ZecType) {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(Constants.apiUrl + this.findUrlType(zecType) + "/" + `${zec.id}`)
                .toPromise();
            const dataStoreItems = this.findDataStoreType(zecType);

            const deletedId: number = result.result;
            const zecIndex = dataStoreItems.findIndex(f => f.id === deletedId);
            if (zecIndex !== -1) dataStoreItems.splice(zecIndex, 1);

            zecType === "ZEC"
                ? (this.dataStore.zecs = dataStoreItems)
                : zecType === "RECEIVER"
                ? (this.dataStore.receivers = dataStoreItems)
                : (this.dataStore.feeders = dataStoreItems);

            this.assignDataStoreByType(zecType);

            return true;
        } catch (error) {
            return false;
        }
    }

    async addZec(model: Record<string, unknown>, zecType: ZecType) {
        try {
            const result = await this.http
                .post<APIResponse<ZecTypes>>(Constants.apiUrl + this.findUrlType(zecType), model)
                .toPromise();
            const zec: ZecTypes = result.result;

            this.updateZecStore(zec, true, zecType);

            this.assignDataStoreByType(zecType);

            return zec;
        } catch (error) {
            return false;
        }
    }

    async updateZec(id: number, model: Record<string, unknown>, zecType: ZecType) {
        try {
            const result = await this.http
                .put<APIResponse<ZecTypes>>(Constants.apiUrl + this.findUrlType(zecType) + "/" + id, model)
                .toPromise();
            const updatedZec: ZecTypes = result.result;

            this.updateZecStore(updatedZec, true, zecType);
            this.assignDataStoreByType(zecType);

            return updatedZec;
        } catch (error) {
            if (error.status === 428) return true;
            return false;
        }
    }

    private findUrlType(zecType: ZecType) {
        return zecType === "ZEC"
            ? Constants.apiUrls.zec
            : zecType === "RECEIVER"
            ? Constants.apiUrls.receiver
            : Constants.apiUrls.feeder;
    }

    private findDataStoreType(zecType: ZecType) {
        return zecType === "ZEC"
            ? this.dataStore.zecs
            : zecType === "RECEIVER"
            ? this.dataStore.receivers
            : this.dataStore.feeders;
    }

    private assignDataStoreByType(zecType: ZecType) {
        zecType === "ZEC"
            ? this.zecsSubject$.next(Object.assign({}, this.dataStore).zecs)
            : zecType === "RECEIVER"
            ? this.receiversSubject$.next(Object.assign({}, this.dataStore).receivers)
            : this.feedersSubject$.next(Object.assign({}, this.dataStore).feeders);
    }

    async getReceiverTargets(id: number) {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const result = await firstValueFrom(
                this.http.get<APIResponse<any[]>>(Constants.apiUrl + Constants.apiUrls.receiver + "/" + id + "/targets")
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const targets: any[] = result.result;
            return targets;
        } catch (error) {
            return false;
        }
    }

    async getZecTargets(id: number) {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const result = await firstValueFrom(
                this.http.get<APIResponse<any[]>>(Constants.apiUrl + Constants.apiUrls.receiver + "/" + id + "/targets")
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const targets: any[] = result.result;
            return targets;
        } catch (error) {
            return false;
        }
    }

    async getReceiverFeederChannels(id: number, zecType: ZecType) {
        try {
            const result = await this.http
                .get<
                    APIResponse<
                        (AdaptiveChannel | DeliveryChannel | MediaConnectFlow | FailoverChannel | MediaLiveChannel)[]
                    >
                >(Constants.apiUrl + this.findUrlType(zecType) + "/" + id + "/channels")
                .toPromise();
            const channels: (
                | AdaptiveChannel
                | DeliveryChannel
                | MediaConnectFlow
                | FailoverChannel
                | MediaLiveChannel
            )[] = result.result;
            return channels;
        } catch (error) {
            return false;
        }
    }

    async getFeederSources(id: number) {
        try {
            const result = await firstValueFrom(
                this.http.get<APIResponse<Source[]>>(
                    Constants.apiUrl + Constants.apiUrls.feeder + "/" + id + "/sources"
                )
            );
            const sources: Source[] = result.result;
            return sources;
        } catch (error) {
            return false;
        }
    }

    async getZecSources(id: number) {
        try {
            const result = await firstValueFrom(
                this.http.get<APIResponse<Source[]>>(Constants.apiUrl + Constants.apiUrls.zec + "/" + id + "/sources")
            );
            const sources: Source[] = result.result;
            return sources;
        } catch (error) {
            return false;
        }
    }

    prepParams(ids: number[] | string[]) {
        let filter = new HttpParams();
        if (ids) {
            ids.forEach(id => {
                filter = filter.append("id", id);
            });
        }
        return filter;
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half (relevant when providing specific ids).
     * @returns Observable resolve with an array of zec or in case of error it resolves with null and log the error to the console
     */
    getZecsNew(ids: number[] = null, isGetUpdatedInTheLast90Seconds = false): Observable<Zec[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }
        const req$ = this.http.get<APIResponse<Zec[]>>(Constants.apiUrl + Constants.apiUrls.zec, { params });

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ZEC"), error);
                return of(null);
            }),
            share()
        );
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half (relevant when providing specific ids).
     * @returns Observable resolve with an array of feeder or in case of error it resolves with null and log the error to the console
     */
    getFeedersNew(ids: number[] = null, isGetUpdatedInTheLast90Seconds = false): Observable<Feeder[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }
        const req$ = this.http.get<APIResponse<Feeder[]>>(Constants.apiUrl + Constants.apiUrls.feeder, { params });

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_FEEDER"), error);
                return of(null);
            }),
            share()
        );
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half (relevant when providing specific ids).
     * @returns Observable resolve with an array of receiver or in case of error it resolves with null and log the error to the console
     */
    getReceiversNew(ids: number[] = null, isGetUpdatedInTheLast90Seconds = false): Observable<Receiver[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }
        const req$ = this.http.get<APIResponse<Receiver[]>>(Constants.apiUrl + Constants.apiUrls.receiver, { params });

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_RECEIVER"), error);
                return of(null);
            }),
            share()
        );
    }
}
