import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";
import { Incident } from "../../../pages/incidents/incident";
import { IncidentsService } from "../../../pages/incidents/incidents.service";
import * as _ from "lodash";

@Component({
    selector: "zx-incident-select",
    templateUrl: "./zx-incident-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxIncidentSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    incidents: Incident[];
    private incidentsSubscription: Subscription;

    constructor(private is: IncidentsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.is.getIncidents();
        this.incidentsSubscription = this.is.incidents.subscribe(incidents => {
            this.incidents = _.chain(incidents)
                .filter(incident => {
                    const isNotNdGenerated =
                        incident.triggering_object_id !== null && incident.triggering_object_id !== 0;
                    return isNotNdGenerated;
                })
                .orderBy(incident => new Date(incident.created_at), "desc")
                .value();
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.incidentsSubscription.unsubscribe();
    }
}
