<ng-select
    name="{{ name }}"
    [items]="profiles"
    [clearable]="false"
    [loading]="loading"
    bindLabel="name"
    placeholder="{{ 'SELECT' | translate }} {{ 'ALERTING_PROFILE' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    [disabled]="disabled"
    required
>
</ng-select>
