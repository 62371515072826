import { Component, OnInit } from "@angular/core";
@Component({
    selector: "app-launch-page-footer",
    templateUrl: "./launch-page-footer.component.html",
    styleUrls: ["./launch-page-footer.component.scss"]
})
export class LaunchPageFooterComponent implements OnInit {
    current_year: number;

    ngOnInit(): void {
        this.setCurrentYear();
    }

    setCurrentYear() {
        this.current_year = new Date().getFullYear();
    }
}
