import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";

@Injectable()
export class ResizeService {
    private currentSize$ = new BehaviorSubject<number>(0);

    get getCurrentSize() {
        return this.currentSize$.asObservable();
    }
    setCurrentSize(val: number) {
        this.currentSize$.next(val);
    }
    isMobile$ = this.currentSize$.pipe(map(x => x < 8));
    isHuge$ = this.currentSize$.pipe(map(x => x >= 8));
}
