<div>
    <!-- Location -->
    <div class="input-group">
        <input
            type="text"
            id="location_text"
            name="location_text"
            class="form-control"
            title="{{ locationText }}"
            placeholder="{{ 'LOCATION' | translate }}"
            [(ngModel)]="locationText"
            disabled
        />
        <button class="btn btn-sm btn-outline-light" type="button" *ngIf="locationText.length > 0" (click)="clearLocation()">
            <fa-icon icon="times" size="md"></fa-icon>
        </button>
        <button class="btn btn-outline-primary" type="button" (click)="changeLocation = !changeLocation">
            <fa-icon icon="pencil" size="sm" *ngIf="!changeLocation"></fa-icon>
            <fa-layers *ngIf="changeLocation" [fixedWidth]="true">
                <fa-icon icon="slash"></fa-icon>
                <fa-icon icon="pencil" size="sm"></fa-icon>
            </fa-layers>
        </button>
    </div>
</div>
<div *ngIf="changeLocation">
    <!-- Search -->
    <div class="input-group mt-2 mb-1">
        <button
            class="btn btn-primary"
            type="button"
            id="search-button"
            [disabled]="!searchLocationVal || searchLocationVal.length <= 3"
            (click)="locationLookup(searchLocationVal); $event.stopPropagation()"
        >
            <fa-icon icon="search"></fa-icon>
        </button>
        <input
            type="text"
            class="form-control"
            placeholder="{{ 'SEARCH_LOCATION' | translate }}..."
            [(ngModel)]="searchLocationVal"
            (keyup)="$event.keyCode === 13 || locationLookup(searchLocationVal)"
            (click)="$event.stopPropagation()"
        />
        <button
            class="btn btn-sm btn-outline-light"
            type="button"
            (click)="searchLocationVal = null; searchLocationResults = null"
            *ngIf="searchLocationVal && searchLocationVal.length > 0"
        >
            <fa-icon icon="times" size="md"></fa-icon>
        </button>
    </div>
    <!-- Results -->
    <div class="location-results-list" *ngIf="searchLocationResults && searchLocationVal && searchLocationVal.length > 3">
        <h5 class="sr-only">{{ "RESULTS" | translate }}</h5>
        <ul *ngIf="searchLocationResults.length > 0 && !searchingLocation">
            <li class="ellipsis" *ngFor="let result of searchLocationResults" (click)="selectLocation(result); $event.stopPropagation()">
                <a href="javascript:void(0)" title="{{ result.displayName }}">{{ result.displayName }}</a>
            </li>
        </ul>
        <p class="mb-0" *ngIf="searchLocationResults.length === 0 && !searchingLocation">{{ "NO_SEARCH_RESULTS" | translate }}</p>
        <p class="mb-0" *ngIf="searchingLocation">{{ "SEARCHING" | translate }}...</p>
    </div>
</div>
