import { Component, Input } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";

export type AudioSourcesConfigurationControl = FormArray<FormControl<number[] | null>>;

@Component({
    selector: "app-zx-ndi-audio-sources",
    templateUrl: "./zx-ndi-audio-sources.component.html",
    styleUrls: ["./zx-ndi-audio-sources.component.scss"]
})
export class ZxNdiAudioSourcesComponent {
    @Input() audioSourcesConfigurationControl: AudioSourcesConfigurationControl;
    channels = [...Array(16).keys()];

    addToSourceConfig() {
        this.audioSourcesConfigurationControl.push(new FormControl([]));
    }
}
