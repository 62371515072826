import { Component, Input } from "@angular/core";
import { AlertingRule } from "../events-management";
@Component({
    selector: "app-view-alerting-rule",
    templateUrl: "./view-alerting-rule.component.html"
})
export class ViewAlertingRuleComponent {
    @Input() rule: AlertingRule;
    @Input() alertingProfileId: number;
}
