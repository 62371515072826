import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { SharedService } from "./../../../services/shared.service";
import { Tag } from "./../../../models/shared";

@Component({
    selector: "zx-access-tag-select",
    templateUrl: "./zx-access-tag-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxAccessTagSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: Tag;
    @Input() required?: boolean;
    @Input() type?: string;
    @Input() ro?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    tags: Tag[];
    mostRecentTags: string;
    private tagsSubscription: Subscription;

    constructor(private sharedService: SharedService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
        this.updateMostRecentTags();
    }

    updateMostRecentTags() {
        const currentTagID = this.model.id;
        const mrtArray: number[] = this.mostRecentTags.split(",").map(Number);
        if (!mrtArray.includes(currentTagID)) mrtArray.unshift(currentTagID);
        const finalArray = mrtArray.slice(0, 5);
        localStorage.setItem("recent.resourceTags", finalArray.toString());
    }

    moveToFirst(arr, key, value) {
        const arrayUpdated = [];
        arr.forEach(e => {
            if (e[key] === value) arrayUpdated.unshift(e);
            else arrayUpdated.push(e);
        });
        return arrayUpdated;
    }

    ngOnInit() {
        this.loading = true;
        //
        this.mostRecentTags = localStorage.getItem("recent.resourceTags")
            ? localStorage.getItem("recent.resourceTags")
            : "";
        //
        this.tagsSubscription = this.sharedService
            .getResourceTagsByType(this.type, this.ro)
            .subscribe((tags: Tag[]) => {
                this.tags = [...tags];
                this.sharedService.sort(this.tags, "name", "asc");
                //
                const array: number[] = this.mostRecentTags.split(",").map(Number).reverse();
                for (let i = 0, len = array.length; i < len; i++) {
                    this.tags = this.moveToFirst(this.tags, "id", array[i]);
                }
                //
                this.loading = false;
            });
    }

    ngOnDestroy() {
        this.tagsSubscription.unsubscribe();
    }
}
