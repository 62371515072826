import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
//
import { ClipboardService } from "ngx-clipboard";
import { Cluster } from "src/app/pages/clusters/cluster";
//
import * as _ from "lodash";
import {} from "@zixi/models";
import { SharedService } from "src/app/services/shared.service";
import { Broadcaster, Tag, UserPermissions } from "src/app/models/shared";
import { Router } from "@angular/router";
import { urlBuilder } from "@zixi/shared-utils";
import { NgbSortableHeader, SortDirection, SortEvent } from "src/app/directives/sortable.directive";
import { BehaviorSubject, Subscription } from "rxjs";
import { ClustersService } from "src/app/pages/clusters/clusters.service";
import { LocationDisplayNamePipe } from "src/app/pipes/location-display-name.pipe";
import { NetworkPipe } from "src/app/pipes/network.pipe";
import { Constants } from "src/app/constants/constants";
import { TranslateService } from "@ngx-translate/core";
import { VersionPipe } from "src/app/pipes/version.pipe";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";

interface State {
    sortColumn: string;
    sortDirection: SortDirection;
}
@Component({
    selector: "zx-cluster-broadcasters-table",
    templateUrl: "./zx-cluster-broadcasters-table.component.html",
    styleUrls: ["./zx-cluster-broadcasters-table.component.scss"]
})
export class ZxClusterBroadcastersTableComponent implements OnInit, OnDestroy {
    @Input() cluster: Cluster;
    @Input() clusterSelected: number;
    @Input() broadcasterSelected: number;
    @Input() forceCollapse = false;
    @Input()
    set searchTerm(value: string[]) {
        this.searchTermArray = value;
        this.prepTableData();
    }

    get searchTerm(): string[] {
        return this.searchTermArray;
    }

    @Input() resourceTags: Tag[];
    @Input() userPermissions: UserPermissions;

    searchTermArray: string[];

    get Math() {
        return Math;
    }

    broadcasters: Broadcaster[];

    private broadcastersBS$ = new BehaviorSubject<Broadcaster[]>([]);
    private totalBS$ = new BehaviorSubject<number>(0);

    sortedByText = "NONE";
    sortedByColumn: string;

    private state: State = {
        sortColumn: "name",
        sortDirection: "asc"
    };

    private clustersSubscription: Subscription;

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;

    constructor(
        private cbs: ClipboardService,
        public sharedService: SharedService,
        private router: Router,
        private cs: ClustersService,
        public bs: BroadcastersService,
        private locationPipe: LocationDisplayNamePipe,
        private networkPipe: NetworkPipe,
        public translate: TranslateService,
        public versionPipe: VersionPipe
    ) {}

    ngOnInit() {
        this.sortColumn = localStorage.getItem("nestedBroadcaster.sortColumn")
            ? localStorage.getItem("nestedBroadcaster.sortColumn")
            : "name";
        this.sortDirection = localStorage.getItem("nestedBroadcaster.sortDirection")
            ? localStorage.getItem("nestedBroadcaster.sortDirection")
            : "asc";

        this.clustersSubscription = this.cs.clusters.subscribe(clusters => {
            if (clusters) {
                const cluster = clusters.find(c => c.id === this.cluster.id);
                if (cluster) this.broadcasters = cluster.broadcasters;
                this.prepTableData();
            }
        });
    }

    ngOnDestroy() {
        this.clustersSubscription?.unsubscribe();
    }

    clusterHasGPUs(cluster: Cluster) {
        if (!cluster) return false;
        return _.reduce(
            cluster.broadcasters,
            (hasGPUs, bx) => {
                return (
                    hasGPUs ||
                    (bx.recentLoad && (bx.recentLoad.nvidia_utilization === 0 || bx.recentLoad.nvidia_utilization > 0))
                );
            },
            false
        );
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    canEditBroadcaster(broadcaster: Broadcaster) {
        if (!broadcaster) return false;
        if (!this.cluster) return false;
        return this.sharedService.canEditZixiObject(this.cluster, this.resourceTags, this.userPermissions);
    }

    selectBroadcasterRows(broadcaster: Broadcaster, cluster: Cluster) {
        this.router.navigate(urlBuilder.getRegularBroadcasterUrl(cluster.id, broadcaster.id, broadcaster.name));
    }

    openLink(broadcaster: Broadcaster) {
        window.open(broadcaster.configure_link, "_blank");
    }

    get broadcasters$() {
        return this.broadcastersBS$.asObservable();
    }
    get total$() {
        return this.totalBS$.asObservable();
    }
    get sortColumn() {
        return this.state.sortColumn;
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
        localStorage.setItem("nestedBroadcaster.sortColumn", sortColumn);
    }
    get sortDirection() {
        return this.state.sortDirection;
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
        localStorage.setItem("nestedBroadcaster.sortDirection", sortDirection);
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepTableData();
    }

    onSort({ column, direction }: SortEvent, name: string) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.sortedByColumn = column;
        this.sortedByText = name;
        this.sortColumn = column;
        this.sortDirection = direction;
    }

    private prepTableData() {
        if (this.broadcasters) {
            const { sortColumn, sortDirection } = this.state;
            // sort
            let broadcasters = this.sharedService.sort(this.broadcasters, sortColumn, sortDirection);

            // filter
            broadcasters = broadcasters.filter(note =>
                this.sharedService.matches(note, this.searchTermArray.toString(), this.broadcasterFilter)
            );
            const total = broadcasters.length;

            this.broadcastersBS$.next(broadcasters);
            this.totalBS$.next(total);
        }
    }

    broadcasterFilter = (broadcaster: Broadcaster, term: string) => {
        return (
            // Name
            broadcaster.name.toLowerCase().includes(term.toLowerCase()) ||
            // IP
            broadcaster.status?.source_ip?.toLowerCase().includes(term.toLowerCase()) ||
            // Version
            ((broadcaster.status?.about?.version ?? "-") + (broadcaster.can_transcode ? " Transcoder" : ""))
                .toLowerCase()
                .includes(term.toLowerCase()) ||
            // Location
            this.locationPipe.transform(broadcaster.location).toLowerCase().includes(term.toLowerCase()) ||
            // Network
            this.networkPipe.transform(broadcaster.location).toLowerCase().includes(term.toLowerCase())
        );
    };
}
