import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { ZecTypes } from "../../../zec-list/zec-list.component";
import { ClipboardService } from "ngx-clipboard";
import { Router } from "@angular/router";
import { TagsService } from "../../../../configuration/tags/tags.service";

@Component({
    selector: "app-zec-details",
    templateUrl: "./zec-details.component.html"
})
export class ZecDetailsComponent implements OnChanges {
    @Input() zec: ZecTypes;
    @Input() canEdit: boolean;
    pwVisible = false;
    zecType: string;

    constructor(private cbs: ClipboardService, private router: Router, private tagsService: TagsService) {
        this.zecType = this.router.url.replace("s/", "/").split("/")[2].toUpperCase();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.zec) {
            if (changes.zec.previousValue && changes.zec.currentValue) {
                if (changes.zec.previousValue.id !== changes.zec.currentValue.id) {
                    this.pwVisible = false;
                }
            }
        }
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    isVip() {
        return this.tagsService.isObjectVip(this.zec.resourceTags);
    }
}
