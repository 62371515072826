import { Component, Input, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardService } from "ngx-clipboard";
import { TranslateService } from "@ngx-translate/core";
import { MediaConnectFlow, MediaConnectSource } from "src/app/models/shared";
import { Subscription, filter } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { MediaLiveChannel } from "src/app/pages/channels/channel";

@Component({
    selector: "app-mc-source-config-dialog",
    templateUrl: "./mc-source-config-dialog.component.html"
})
export class MediaConnectSourceConfigDialogComponent implements OnDestroy {
    @Input()
    set source(s: MediaConnectSource) {
        this.mcSource = s;
        if (s.mediaconnect_flow_id) this.mediaConnectFlow = s.mediaconnectFlow;
        else this.mediaConnectFlow = undefined;
        if (s.medialive_channel_id) this.mediaLiveChannel = s.mediaLiveChannel;
        else this.mediaLiveChannel = undefined;
    }
    get source(): MediaConnectSource {
        return this.mcSource;
    }

    private mcSource: MediaConnectSource;
    mediaConnectFlow: MediaConnectFlow;
    mediaLiveChannel: MediaLiveChannel;

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private cbs: ClipboardService,
        private translate: TranslateService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    confirm() {
        this.activeModal.close();
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }
}
