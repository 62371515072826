<div class="d-flex justify-content-between mt-2">
    <div>
        <ngb-pagination
            [ngClass]="{ condensed: rowSelected }"
            [collectionSize]="total"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
            (pageChange)="pageChanged()"
        ></ngb-pagination>
    </div>
    <!-- Showing -->
    <div *ngIf="!rowSelected" class="d-none d-sm-block mt-2">
        <small>{{ "SHOWING" | translate }}: {{ startNum }} - {{ endNum }} of {{ total }}</small>
    </div>
    <!-- Page Size -->
    <div>
        <div class="form-inline">
            <div class="form-group mb-0 stay-inline">
                <label class="d-none d-xs-block" for="pageSize" [ngClass]="{ 'sr-only': rowSelected }">{{ "ROWS" | translate }}</label>
                <select
                    zmid="page-size-btn"
                    class="form-control minw-70px ms-2"
                    name="pageSize"
                    [(ngModel)]="selectedPageSize"
                    (ngModelChange)="pageSizeChanged($event)"
                >
                    <option ngValue="auto">{{ "AUTO" | translate }}</option>
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="mt-1 text-center" [ngClass]="{ 'd-block': rowSelected, 'd-sm-none': !rowSelected }">
    <div>
        <small>{{ "SHOWING" | translate }}: {{ startNum }} - {{ endNum }} of {{ total }}</small>
    </div>
</div>
