<div class="event-filters">
    <div class="form-inline align-items-baseline">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="showFilter = !showFilter"
            title="{{ 'SHOW_FILTERS' | translate }}"
            [ngClass]="{ showFilter: !showFilter }"
        >
            <fa-icon icon="chevron-down" size="sm" class="me-1" *ngIf="showFilter"></fa-icon>
            <span *ngIf="showFilter">{{ "SHOW_FILTERS" | translate }}</span>
            <fa-icon icon="chevron-up" size="sm" class="me-1" *ngIf="!showFilter"></fa-icon>
            <span *ngIf="!showFilter">{{ "HIDE_FILTERS" | translate }}</span>
        </button>
        <!-- Column Selection -->
        <div class="form-group ms-2 mb-0" [ngClass]="{ 'mb-3': showFilter }" *ngIf="columns && showColumnSelection">
            <label class="sr-only">{{ "COLUMN_SELECTION" | translate }}</label>
            <div ngbDropdown [placement]="['bottom-right', 'bottom-left']" title="{{ 'COLUMN_SELECTION' | translate }}">
                <button class="btn btn-outline-secondary" id="columnSelectionDropdown" ngbDropdownToggle>
                    <fa-icon icon="columns" size="md"></fa-icon>
                </button>
                <div ngbDropdownMenu aria-labelledby="columnSelectionDropdown" class="shadow-sm ms-4">
                    <button
                        ngbDropdownItem
                        *ngFor="let column of columns | keyvalue : sharedService.keepOrder"
                        (click)="toggleColumn(column)"
                        class="text-secondary"
                    >
                        <fa-icon class="me-1" [icon]="['far', 'check-square']" *ngIf="column.value"></fa-icon>
                        <fa-icon class="me-1" [icon]="['far', 'square']" *ngIf="!column.value"></fa-icon>
                        <span>{{ column.key | uppercase | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <form id="form" #form="ngForm">
        <div class="filter-container" *ngIf="!showFilter">
            <fa-icon icon="times" size="lg" class="d-lg-none closeFilter" (click)="showFilter = !showFilter" *ngIf="!showFilter"></fa-icon>
            <h3 class="sr-only" title="{{ 'FILTERS' | translate }}">{{ "FILTERS" | translate }}</h3>
            <div class="row">
                <div class="col-12 col-sm-6" [ngClass]="{ 'minw-100pc': !haveObjectTypes() }">
                    <!-- Search -->
                    <div class="form-group">
                        <label for="msgFilter">{{ "SEARCH" | translate }}</label>
                        <input
                            class="form-control minw-200px"
                            type="text"
                            id="msgFilter"
                            name="msgFilter"
                            [(ngModel)]="msgFilter"
                            placeholder="{{ 'SEARCH' | translate }}"
                            (keydown)="onFilterChange($event)"
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-6" *ngIf="haveObjectTypes()">
                    <!-- Object -->
                    <div class="form-group">
                        <label for="objectType">{{ "OBJECT" | translate }}</label>
                        <ng-select
                            class="form-control minw-200px"
                            id="objectType"
                            name="objectType"
                            [items]="objectTypes"
                            [clearable]="false"
                            bindLabel="name"
                            [(ngModel)]="objectType"
                            (change)="onFilterChange()"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                {{ item.name | translate }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                {{ item.name | translate }}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <!-- From -->
                    <div class="form-group position-relative">
                        <label for="fromDate" translate>FROM</label>
                        <div class="input-group">
                            <input
                                class="form-control minw-200px"
                                id="fromDate"
                                name="fromDate"
                                (click)="toggleFromPicker()"
                                [(ngModel)]="fromDate"
                                placeholder="{{ 'SELECT_DATE' | translate }}"
                                (keydown)="onlyDelete($event)"
                                autocomplete="off"
                            />
                            <button
                                class="btn btn-outline-primary"
                                (click)="clearFromDate()"
                                type="button"
                                title="{{ 'CLEAR_DATE' | translate }}"
                                *ngIf="fromDate"
                            >
                                <fa-icon icon="times"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="toggleFromPicker()"
                                type="button"
                                title="{{ 'SELECT_DATE' | translate }}"
                                *ngIf="!fromDate"
                            >
                                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                            </button>
                        </div>
                        <div class="picker-container absolute" *ngIf="showFromPicker" (clickOutside)="clickOutsideFromPicker()">
                            <div class="arrow"></div>
                            <div class="picker-content shadow-sm">
                                <fa-icon icon="times-circle" class="close-icon" (click)="closeFromPicker()"></fa-icon>
                                <zx-date-time-picker
                                    name="fromDatePicker"
                                    [(ngModel)]="fromDate"
                                    (ngModelChange)="fromDateChanged($event)"
                                    [onlyPastDates]="true"
                                ></zx-date-time-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <!-- To -->
                    <div class="form-group position-relative">
                        <label for="toDate" translate>TO</label>
                        <div class="input-group">
                            <input
                                class="form-control minw-200px"
                                id="toDate"
                                name="toDate"
                                (click)="toggleToPicker()"
                                [(ngModel)]="toDate"
                                placeholder="{{ 'SELECT_DATE' | translate }}"
                                (keydown)="onlyDelete($event)"
                                autocomplete="off"
                            />
                            <button class="btn btn-outline-primary" (click)="clearToDate()" type="button" title="{{ 'CLEAR_DATE' | translate }}" *ngIf="toDate">
                                <fa-icon icon="times"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="toggleToPicker()"
                                type="button"
                                title="{{ 'SELECT_DATE' | translate }}"
                                *ngIf="!toDate"
                            >
                                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                            </button>
                        </div>
                        <div class="picker-container absolute" *ngIf="showToPicker" (clickOutside)="clickOutsideToPicker()">
                            <div class="arrow"></div>
                            <div class="picker-content shadow-sm">
                                <fa-icon icon="times-circle" class="close-icon" (click)="closeToPicker()"></fa-icon>
                                <zx-date-time-picker
                                    name="toDatePicker"
                                    [(ngModel)]="toDate"
                                    (ngModelChange)="toDateChanged($event)"
                                    [onlyPastDates]="true"
                                ></zx-date-time-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <!-- Types -->
                    <div class="form-group">
                        <label for="types" class="w-100">{{ typeFilterLabel | translate }}</label>
                        <div class="btn-group w-100" role="group">
                            <ng-template ngFor let-type [ngForOf]="typeFilters">
                                <button
                                    type="button"
                                    class="btn btn-outline-{{ type.color }}"
                                    [ngClass]="{ active: type.enabled }"
                                    (click)="toggleTypeFilter(type)"
                                >
                                    <fa-icon [icon]="['far', 'check-square']" *ngIf="type.enabled"></fa-icon>
                                    <fa-icon [icon]="['far', 'square']" *ngIf="!type.enabled"></fa-icon>
                                    {{ type.text | translate }}
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <!-- Access Tags -->
                    <div class="form-group">
                        <label for="resource_tag_ids">{{ "TAGS" | translate }}</label>
                        <ng-select
                            class="form-control minw-200px"
                            id="resource_tag_ids"
                            name="resource_tag_ids"
                            [items]="resourceTags"
                            [multiple]="true"
                            [hideSelected]="true"
                            [closeOnSelect]="false"
                            [clearSearchOnAdd]="true"
                            bindLabel="name"
                            bindValue="id"
                            placeholder="{{ 'SELECT' | translate }} {{ 'TAGS' | translate }}"
                            [(ngModel)]="selectedResourceTags"
                            (change)="onFilterChange()"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
            <!-- Buttons -->
            <div class="form-group" *ngIf="{ userPermissions: userPermissions$ | async } as observes">
                <button type="button" class="btn btn-primary" (click)="apply()" title="{{ 'APPLY' | translate }}">
                    <fa-icon icon="check" size="sm"></fa-icon>
                    {{ "APPLY" | translate }}
                </button>
                <button type="button" class="btn btn-outline-secondary ms-2 me-2" (click)="reset()" title="{{ 'RESET' | translate }}">
                    <fa-icon icon="undo" size="sm"></fa-icon>
                    {{ "RESET" | translate }}
                </button>
                <!-- Download Report -->
                <a
                    id="quick-report"
                    href="/api/events/report?{{ getReportParameters() }}"
                    *ngIf="downloadEvents && observes.userPermissions && canReportEvents() && hasEvents && !eventsFilterChangesNotApplied()"
                    class="btn btn-outline-primary"
                >
                    <fa-icon icon="download" size="md" class="me-1"></fa-icon>{{ "DOWNLOAD" | translate }}
                </a>
                <a
                    id="quick-report"
                    href="javascript:void(0)"
                    *ngIf="downloadEvents && observes.userPermissions && canReportEvents() && eventsFilterChangesNotApplied()"
                    class="btn btn-outline-primary appear-disabled"
                    [title]="'You must apply filter changes before downloading'"
                >
                    <fa-icon icon="download" size="md" class="me-1"></fa-icon>{{ "DOWNLOAD" | translate }}
                </a>
                <a
                    id="quick-report"
                    href="javascript:void(0)"
                    *ngIf="downloadEvents && observes.userPermissions && !canReportEvents()"
                    class="btn btn-outline-primary appear-disabled"
                    [title]="'Select a date range and an Object type'"
                >
                    <fa-icon icon="download" size="md" class="me-1"></fa-icon>{{ "DOWNLOAD" | translate }}
                </a>
            </div>
        </div>
    </form>
</div>
