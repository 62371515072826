<spinner-animation *ngIf="loading"></spinner-animation>

<zx-history-graph
    [title]=""
    [object]="{ source: source }"
    [graphUrl]="sourceDashboardLink"
    [hideAddIncident]="false"
    (from)="setFrom($event)"
    (to)="setTo($event)"
></zx-history-graph>

<app-table-list
    [data]="scteLogs$ | async"
    [tableName]="'source-scte35'"
    [displayTableName]="'SCTE_35_LOGS' | translate"
    [(tableSchema)]="tableColumnsSchema"
    [showFilter]="false"
    [showPagination]="true"
    [showSelectionCheckbox]="false"
    [selectable]="false"
    [hoverable]="false"
    [showReport]="true"
    [infinitePages]="morePages"
    [autoRows]="autoRows"
    (currentPageInfo)="currentPageInfo($event)"
    [showColumnsSelection]="true"
    [refreshing]="refreshing"
    [hidden]="loading"
>
    <div class="flex-1 form-group mb-0">
        <label for="msgFilter" class="sr-only">{{ "FILTER_MESSAGE" | translate }}</label>
        <input
            class="form-control minw-200px"
            type="text"
            id="msgFilter"
            name="msgFilter"
            [(ngModel)]="msgFilter"
            placeholder="{{ 'FILTER_MESSAGE' | translate }}"
            (keyup)="$event.keyCode === 13 || msgChange()"
        />
    </div>
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="toggleLogsStyle()"
        title=" {{ isScte35ColorsVisible ? ('HIDE_COLORS' | translate) : ('SHOW_COLORS' | translate) }}"
    >
        <fa-icon *ngIf="!isScte35ColorsVisible" class="me-1" icon="droplet" size="sm"></fa-icon
        ><fa-icon *ngIf="isScte35ColorsVisible" class="me-1" [icon]="['far', 'droplet']" size="sm"></fa-icon
        >{{ isScte35ColorsVisible ? ("HIDE_COLORS" | translate) : ("SHOW_COLORS" | translate) }}
    </button>
</app-table-list>
