<form
    id="deleteDisableBroadcasterForm"
    #deleteDisableBroadcasterForm="ngForm"
    (ngSubmit)="deleteDisableBroadcasterForm.valid && onSubmit()"
    [ngClass]="{ loading: saving }"
>
    <div class="modal-header">
        <h3 class="modal-title">{{ action | translate }} {{ "BROADCASTER" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Standard Text -->
        <p class="mb-0">
            {{ "DO_YOU_WANT_TO" | translate }} {{ action | translate | lowercase }} {{ "THE" | translate | lowercase }} {{ "BROADCASTER" | translate }}
            <span *ngIf="name">{{ "NAMED" | translate | lowercase }} "{{ name }}"</span>?
        </p>

        <!-- Auto recover -->
        <div class="form-check lh-15">
            <input type="checkbox" class="form-check-input" id="autorecover_ctl" name="autorecover_ctl" [(ngModel)]="autorecover" />
            <label class="form-check-label" for="autorecover_ctl">{{ "AUTO_RECOVER" | translate }}</label>
            <p *ngIf="autorecover">
                <small
                    class="pt-2"
                    [ngStyle]="{
                        'padding-left.px': 20
                    }"
                    >{{ "AUTO_RECOVER_NOTE" | translate }}</small
                >
            </p>
            <!-- Target Broadcaster/s -->
            <div class="form-group" *ngIf="autorecover">
                <label for="target_broadcaster_id_ctl">{{ "TARGET_BROADCASTER/S" | translate }}</label>
                <ng-select
                    id="target_broadcaster_id_ctl"
                    name="target_broadcaster_id_ctl"
                    [items]="targetBXs"
                    [clearable]="true"
                    bindValue="id"
                    bindLabel="name"
                    groupBy="type"
                    placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                    [(ngModel)]="target_broadcaster_id"
                    #target_broadcaster_id_ctl="ngModel"
                >
                    <ng-template ng-optgroup-tmp let-item="item">
                        <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <span *ngIf="item.broadcaster_cluster_id > 0"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                        <span *ngIf="!(item.broadcaster_cluster_id > 0)">{{ item.name }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span *ngIf="item.broadcaster_cluster_id > 0"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                        <span *ngIf="!(item.broadcaster_cluster_id > 0)">{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>

        <!-- Error -->
        <app-error [marginBottom]="false" [marginTop]="true"></app-error>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()">
            <fa-icon icon="times"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button
            type="button"
            class="btn"
            [ngClass]="{
                'btn-danger': action === 'DELETE',
                'btn-warning': action === 'DISABLE'
            }"
            ngbAutofocus
            [disabled]="saving"
            zmid="form-submit"
            type="submit"
        >
            <fa-icon *ngIf="action === 'DELETE'" icon="trash-alt" size="sm"></fa-icon>
            <fa-icon *ngIf="action === 'DISABLE'" icon="ban" size="sm"></fa-icon>
            {{ action | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>
