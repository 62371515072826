import { Component, Input } from "@angular/core";

@Component({
    selector: "app-zx-ngb-highlight",
    template: `
        <span *ngIf="showTitle">
            <ngb-highlight title="{{ title }}" [result]="result" [term]="searchTerm"></ngb-highlight>
        </span>
        <span *ngIf="!showTitle">{{ elseTitle }}</span>
    `
})
export class ZxNgbHighlightComponent {
    @Input() title: string;
    @Input() result: string;
    @Input() searchTerm: string | string[];
    @Input() showTitle? = true;
    @Input() elseTitle? = "-";
}
