<a *ngIf="playRef" href="javascript:void(0)" (click)="play($event)" [class.disabled]="!canPlay" title="{{ 'PLAY' | translate }}"
    ><fa-icon icon="play" size="xs"></fa-icon><span class="sr-only">{{ "PLAY" | translate }}</span></a
>
<a *ngIf="detachRef" class="ms-3" href="javascript:void(0)" (click)="detach($event)" [class.disabled]="!canDetach" title="{{ 'DETACH' | translate }}"
    ><fa-icon icon="unlink" size="sm"></fa-icon><span class="sr-only">{{ "DETACH" | translate }}</span></a
>
<a
    *ngIf="muteRef"
    class="ms-3"
    href="javascript:void(0)"
    (click)="mute($event)"
    [class.disabled]="!canMute"
    title="{{ (isTargetMuted(row.target) ? 'UNMUTE' : 'MUTE') | translate }}"
>
    <fa-icon icon="bell" size="sm" [fixedWidth]="true" *ngIf="isTargetMuted(row.target)"></fa-icon
    ><fa-icon icon="bell-slash" size="sm" [fixedWidth]="true" *ngIf="!isTargetMuted(row.target)"></fa-icon
    ><span class="sr-only">{{ (isTargetMuted(row.target) ? "UNMUTE" : "MUTE") | translate }}</span>
</a>
<a
    *ngIf="enableRef"
    class="ms-3"
    href="javascript:void(0)"
    (click)="enable($event)"
    [class.disabled]="!canEnable"
    [ngClass]="row.target.is_enabled ? 'text-warning' : 'text-success'"
    title="{{ (row.target.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
>
    <fa-icon icon="power-off" size="sm" *ngIf="!row.target.is_enabled"></fa-icon><fa-icon icon="ban" size="sm" *ngIf="row.target.is_enabled"></fa-icon
    ><span class="sr-only">{{ (row.target.is_enabled ? "DISABLE" : "ENABLE") | translate }}</span>
</a>
