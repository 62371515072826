import { AspectRatio } from "@zixi/models";

export class TranscodingProfile {
    _frontData: {
        videoOrder: string;
        audioOrder: string;
        videoCodecOrder?: string;
        audioCodecOrder?: string;
        prettyAudioSampleRate: {
            value: number;
            name: string;
        };
    };
    id: number;
    name: string;
    profile: string;
    is_active: number;
    customer_id: number;
    encoding_profile: string;
    aspect_ratio: AspectRatio;
    width: number | null;
    height: number | null;
    fps: number;
    vbr: boolean;
    hardware_accel: boolean;
    bitrate_avg: number;
    bitrate_max: number;
    performance: number;
    gop: number | null;
    gop_closed: boolean;
    gop_fixed: boolean;
    b_frames: number;
    interlaced: boolean;
    ref_frames: number;
    hdr_buff_length: number;
    do_video: boolean;
    do_audio: boolean;
    audio_gain: number;
    audio_bitrate: number;
    audio_encoder_profile: string | null;
    mux_bitrate: number;
    mux_pass_all: number;
    keep_video: boolean;
    keep_audio: boolean;
    created_at: string;
    updated_at: string;
    keep_source_aspect_ratio: boolean;
    source_resolution: number;
    audio_sample_rate: number;
    video_codec: string;
    audio_codec: "aac" | "ac3" | "mpga1";
    chroma_subsampling?: 0 | 420 | 422 | 444;
    hasFullDetails?: boolean;
    bit_depth?: 0 | 8;
    encoding_width: number | null;
}
