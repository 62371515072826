<ng-select
    name="{{ name }}"
    [items]="tags"
    [multiple]="false"
    [clearable]="false"
    [loading]="loading"
    bindLabel="name"
    placeholder="{{ 'SELECT' | translate }} {{ 'TAG' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="required || false"
>
</ng-select>
