<tour-step-template>
    <!-- TODO: figure out why custom template functions don't work -->
    <!--<ng-template let-step="step">
        <mat-card (click)="$event.stopPropagation()">
            <mat-card-header>
                <div class="header-group">
                    <mat-card-title>
                        <h4>{{ step.title }}</h4>
                    </mat-card-title>
                    <fa-icon icon="times-circle" size="md" class="close-icon" (click)="tourService.end()"></fa-icon>
                </div>
            </mat-card-header>

            <mat-card-content class="mat-body" [innerHTML]="step.content"></mat-card-content>

            <mat-card-actions>
                <button mat-button class="prev" [disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
                    <mat-icon>chevron_left</mat-icon>
                    {{ step.prevBtnTitle }}
                </button>
                <button class="next" *ngIf="tourService.hasNext(step) && !step.nextOnAnchorClick" (click)="tourService.next()" mat-button>
                    {{ step.nextBtnTitle }}
                    <mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
                <button mat-button (click)="tourService.end()" *ngIf="!tourService.hasNext(step)">
                    {{ step.endBtnTitle }}
                </button>
            </mat-card-actions>
        </mat-card>
    </ng-template>-->
</tour-step-template>
