/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    KeyValueDiffer,
    KeyValueDiffers,
    DoCheck,
    OnChanges,
    SimpleChanges
} from "@angular/core";
import { DashboardConfigOptions, DashboardPanelConfig, GraphsService } from "src/app/services/graphs.service";
import { Subscription } from "rxjs";
//
import * as _ from "lodash";
import moment from "moment";
import { IncidentsService } from "src/app/pages/incidents/incidents.service";

type GraphObject = { type: string; object: { id: number } };

@Component({
    selector: "zx-dynamic-graph",
    templateUrl: "./zx-dynamic-graph.component.html"
})
export class ZxDynamicGraphComponent implements OnInit, OnDestroy, DoCheck, OnChanges {
    @Input() objects: GraphObject[];

    pickerToDate?: moment.Moment;
    pickerFromDate?: moment.Moment;

    graphLink: string;
    panelConfig: DashboardConfigOptions = [];

    loading = true;
    applying: boolean;
    changed: boolean;
    count = 0;

    from: moment.Moment;
    to: moment.Moment;

    openedGraphs: string[];
    closedGraphs: string[];
    noGraphs = false;
    showConfig = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    differ: KeyValueDiffer<string, any>;

    private pickerToDateSubscription: Subscription;
    private pickerFromDateSubscription: Subscription;

    constructor(private gs: GraphsService, private differs: KeyValueDiffers, private is: IncidentsService) {
        this.differ = this.differs.find({}).create();
    }

    toChanged() {
        this.is.setToDate(this.to);
        this.is.setPickerToDate(this.to);
    }

    fromChanged() {
        this.is.setFromDate(this.from);
        this.is.setPickerFromDate(this.from);
    }

    ngOnInit(): void {
        this.pickerToDateSubscription = this.is.getPickerToDate.pipe().subscribe(d => {
            if (d) {
                this.to = d;
                this.reloadGraphs();
            }
        });

        this.pickerFromDateSubscription = this.is.getPickerFromDate.pipe().subscribe(d => {
            if (d) {
                this.from = d;
                this.reloadGraphs();
            }
        });
    }

    ngDoCheck() {
        const change = this.differ.diff(this.panelConfig);
        this.count++;
        if (change && this.count > 1) {
            this.changed = true;
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.objects && !changes.objects.firstChange) {
            this.updateGraphs(changes.objects.currentValue ?? [], changes.objects.previousValue ?? []);
        }
    }

    ngOnDestroy() {
        this.pickerToDateSubscription.unsubscribe();
        this.pickerFromDateSubscription.unsubscribe();
    }

    getDefaultPanelConfig(type: string, object) {
        if (type === "zec") return this.gs.zec(object);
        else if (type === "feeder") return this.gs.feeder(object);
        else if (type === "receiver") return this.gs.receiver(object);
        else if (type === "broadcaster") return this.gs.broadcaster(object, true);
        else if (type === "source") return this.gs.source(object, true, true);
        else if (type === "channel") return [];
        else if (type === "target") return this.gs.target(object, true, true);
        else if (type === "mediaconnect_sources") return this.gs.mediaconnectSource(object);
        else return [];
    }

    private inPanelConfig(dashConfig: DashboardConfigOptions | undefined, pc: DashboardPanelConfig): boolean {
        return !!dashConfig?.find(
            pcg => pcg.objectId === pc.objectId && pcg.objectType === pc.objectType && pcg.graph === pc.graph
        );
    }

    updateGraphs(currentObjects: GraphObject[], previousObjects?: GraphObject[]) {
        this.loading = true;

        for (const obj of currentObjects ?? []) {
            if (previousObjects?.find(o => o.type === obj.type && o.object.id === obj.object.id)) continue;

            const pcfg = this.getDefaultPanelConfig(obj.type, obj.object);

            this.panelConfig = (this.panelConfig ?? []).concat(
                pcfg.filter(gc => gc.visible && !this.inPanelConfig(this.panelConfig, gc))
            );
        }

        for (const obj of previousObjects ?? []) {
            if (currentObjects?.find(o => o.type === obj.type && o.object.id === obj.object.id)) continue;
            this.panelConfig = (this.panelConfig ?? []).filter(
                gc => gc.pinned || gc.objectType !== obj.type || gc.objectId !== obj.object.id
            );
        }

        this.noGraphs = this.panelConfig.length === 0;
        this.reloadGraphs();
    }

    resetGraphs() {
        this.reloadGraphs();
        this.changed = false;
        this.count = 0;
    }

    reloadGraphs() {
        this.loading = true;
        this.applying = true;

        if (this.panelConfig && this.panelConfig.length > 0) {
            this.graphLink = this.gs.custom(this.panelConfig);
        } else this.graphLink = "";

        this.differ.diff(this.panelConfig);
        this.changed = false;
        this.loading = false;
        this.applying = false;
    }

    setFrom(from: moment.Moment) {
        let changed;
        if (this.from) {
            if (from.format() !== this.from.format()) changed = true;
        }
        this.from = from;
        if (changed) this.fromChanged();
    }

    setTo(to: moment.Moment) {
        let changed;
        if (this.to) {
            if (to.format() !== this.to.format()) changed = true;
        }
        this.to = to;
        if (changed) this.toChanged();
    }

    setOpenedGraphs(openedGraphs: string[]) {
        this.openedGraphs = openedGraphs;

        const graphs = _.map(this.openedGraphs, s => {
            if (s.split(" - ")[1])
                return _.find(this.panelConfig, {
                    name: s.split(" - ")[1],
                    objectType: s.split(" ", 2)[0].toLowerCase(),
                    objectName: s.split(" - ")[0].split(" ", 2)[1]
                });
            else return _.find(this.panelConfig, { name: s });
        });
        if (graphs) {
            for (const g of graphs as DashboardPanelConfig[]) {
                if (g) g.collapsed = false;
            }
        }
    }
}
