<span *ngIf="ioServer.status?.online && ioServer.status?.status === 'TUNNELER_OK'">
    <fa-icon icon="check-circle" class="status-good me-1"></fa-icon>
    <span>OK</span>
</span>

<span *ngIf="ioServer.status?.online && ioServer.status?.status !== 'TUNNELER_OK'">
    <fa-icon icon="check-circle" class="status-good me-1"></fa-icon>
    <span *ngIf="ioServer.status?.online">{{ ioServer.status?.status }}</span>
</span>

<span *ngIf="!ioServer.status?.online">
    <fa-icon icon="minus-circle" class="status-bad me-1"></fa-icon>
    <span>{{ "OFFLINE" | translate }}</span>
</span>
