import { Pipe, PipeTransform } from "@angular/core";
import { OutputNIC } from "./../models/shared";

@Pipe({
    name: "receiverOutputType"
})
export class ReceiverOutputTypePipe implements PipeTransform {
    transform(output: OutputNIC): string {
        if (output.type === "file") return "File";
        if (output.type === "net") return "UDP";
        else return output.type.toUpperCase();
    }
}
