import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Source } from "../../../../models/shared";
import { SourcesService } from "../../sources.service";

@Component({
    selector: "app-source-content-analysis",
    templateUrl: "./source-content-analysis.component.html"
})
export class SourceContentAnalysisComponent {
    @Input() source: Source;

    constructor(private ss: SourcesService) {}

    reset() {
        this.ss.resetTR101(this.source);
    }
}
