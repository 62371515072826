<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "WARNING_UNSAVED_CHANGES" | translate }}</h3>
    </div>
    <div class="modal-body">
        <p class="mb-0">{{ "UI_ERRORS.WARNING_UNSAVED_CHANGES" | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="action(false)">
            <fa-icon icon="times"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="btn btn-primary" ngbAutofocus (click)="action(true)">
            {{ "DISCARD" | translate }}
        </button>
    </div>
</div>
