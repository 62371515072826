<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "MUTE_UNTIL" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <zx-date-time-picker name="muteDate" [(ngModel)]="muteUntilString" [onlyFutureDates]="true"></zx-date-time-picker>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()">
            <fa-icon icon="times"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="!muteUntilString" (click)="confirm(muteUntilString)">
            {{ "MUTE" | translate }}
        </button>
    </div>
</div>
