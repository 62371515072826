import { Component, Input } from "@angular/core";
import { LocationInfo } from "../../pages/maps/map";

@Component({
    selector: "app-details-location",
    templateUrl: "./details-location.component.html",
    styleUrls: ["./details-location.component.scss"]
})
export class DetailsLocationComponent {
    @Input() location: { ip?: LocationInfo; address?: LocationInfo };

    get network() {
        return [this.location?.ip?.isp, this.location?.ip?.region_name, this.location?.ip?.country_name]
            .filter(e => !!e)
            .join(", ");
    }
}
