import { Component, Input } from "@angular/core";
import { SharedService } from "src/app/services/shared.service";
import { GridObject } from "../grid-detail.component";

@Component({
    selector: "app-grid-cluster-column",
    templateUrl: "./grid-cluster-column.component.html"
})
export class GridClusterColumnComponent {
    @Input() searchTerm: string[];
    @Input() object: GridObject;

    constructor(public sharedService: SharedService) {}
}
