import { Component, Input } from "@angular/core";

@Component({
    selector: "app-form-field-warper",
    templateUrl: "./form-field-warper.component.html"
})
export class FormFieldWarperComponent {
    @Input() infoCircle?: boolean = false;
    @Input() tooltipText: string;
    @Input() labelFor: string;
    @Input() labelText: string;
    @Input() isRestartTooltip: boolean;
    @Input() isInvalid: boolean;
    @Input() isRequired?: boolean;
}
