import { Component, Input } from "@angular/core";

@Component({
    selector: "app-generic-ngb-title",
    templateUrl: "./generic-ngb-title.component.html"
})
export class GenericNgbTitleComponent {
    @Input() title: string;
    @Input() inValid: boolean;
    @Input() isNotSaved: boolean;
    @Input() isModified: boolean;
    @Input() isChevrons = true;
}
