import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BroadcasterOutput } from "../models/shared";

@Pipe({
    name: "broadcasterOutput"
})
export class BroadcasterOutputPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(output: BroadcasterOutput): string {
        if (output.type === "UDP")
            return (
                output.name + " (" + this.translate.instant(output.type) + ": " + output.ip + ":" + output.port + ")"
            );
        else return output.name + " (" + this.translate.instant(output.type) + ": " + output.ip + ")";
    }
}
