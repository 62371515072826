import { Source, Tag } from "src/app/models/shared";
import { AnyTarget } from "../channels/channel";

export class PIDMappingProfile {
    _frontData: {
        sortOrder: string;
        ruleCount?: number;
    };
    id: number;
    name: string;
    resource_tag_ids: number[];
    resourceTags: Tag[];
    type: string;
    default_action: string;
    external_action: string;
    rules: Rules[];
    sources: Source[];
    created_at: string;
    updated_at: string;
    pcr_on_video: string | number;
    hasFullDetails?: boolean;
    targets: AnyTarget[];
}

export class Rules {
    action: string;
    id: number;
    pid_mapping_profile_id: number;
    program_number: number;
    source: string;
    target_pid: number;
    target_program_number: number;
}
