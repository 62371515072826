<div class="form-group">
    <label [for]="labelFor">
        {{ labelText }}<fa-icon *ngIf="isRequired" icon="asterisk" size="xs"></fa-icon
        ><fa-icon
            *ngIf="infoCircle"
            icon="info-circle"
            [ngbTooltip]="someContent"
            triggers="hover click"
            [closeDelay]="500"
            [ngClass]="{ 'is-invalid': isInvalid }"
        >
        </fa-icon>
        <ng-template #someContent>
            <p class="mb-0" *ngIf="tooltipText">
                {{ tooltipText }}
            </p>
        </ng-template>
        <fa-icon *ngIf="isRestartTooltip" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon>
    </label>
    <ng-content></ng-content>
</div>
