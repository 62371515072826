import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { Role, RolePermission } from "../../../models/shared";
import * as _ from "lodash";
import { share, map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class RolesService {
    // Role
    role: Observable<Role>;
    roles: Observable<Role[]>;
    private role$: ReplaySubject<Role>;
    private roles$: ReplaySubject<Role[]>;
    private dataStore: {
        role: Role;
        roles: Role[];
    };

    private lastRolesRefresh: number;

    constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    objectTypeToResource(objectType: string) {
        const resources = Object.keys(Constants.OBJECT_PERMISSIONS_MAP);
        for (const resource of resources) {
            if (Constants.OBJECT_PERMISSIONS_MAP[resource].includes(objectType)) {
                return resource;
            }
        }
    }

    private reset() {
        this.dataStore = {
            role: null,
            roles: []
        };

        this.lastRolesRefresh = null;

        this.role$ = new ReplaySubject<Role>(1);
        this.roles$ = new ReplaySubject<Role[]>(1);
        this.role = this.role$.asObservable();
        this.roles = this.roles$.asObservable();
    }

    prepRole(role: Role, fullData: boolean) {
        if (!role._frontData) role._frontData = {};

        // Set top-level permissions values for use in role table columns
        const permissionsData = [];
        for (const permission of role.permissions) {
            let resource = this.objectTypeToResource(permission.object_type);
            if (permissionsData.find(obj => obj.type === resource)) {
                const parent = permissionsData.find(obj => obj.type === resource);
                parent.objects.push(permission);
            } else {
                permissionsData.push({
                    type: resource,
                    read: false,
                    write: false,
                    notifications: false,
                    objects: [permission]
                });
            }
        }

        for (const section of permissionsData) {
            if (section.objects && section.objects.length) {
                const read = section.objects.filter(obj => obj.read);
                const write = section.objects.filter(obj => obj.write);
                const notify = section.objects.filter(obj => obj.notifications);
                // read
                if (read.length === section.objects.length) role._frontData[section.type + "_read"] = 2;
                else if (!read.length) role._frontData[section.type + "_read"] = 0;
                else role._frontData[section.type + "_read"] = 1;
                // write
                if (write.length === section.objects.length) role._frontData[section.type + "_write"] = 2;
                else if (!write.length) role._frontData[section.type + "_write"] = 0;
                else role._frontData[section.type + "_write"] = 1;
                // notify
                if (notify.length === section.objects.length) role._frontData[section.type + "_notify"] = 2;
                else if (!notify.length) role._frontData[section.type + "_notify"] = 0;
                else role._frontData[section.type + "_notify"] = 1;
            }
        }

        if (fullData) role._frontData.lastFullUpdate = _.now();
        return role;
    }

    private updateStore(role: Role, fullData: boolean): void {
        const currentIndex = this.dataStore.roles.findIndex(u => u.id === role.id);
        if (currentIndex === -1) {
            this.prepRole(role, fullData);
            this.dataStore.roles.push(role);
        } else if (!fullData) {
            const current = this.dataStore.roles[currentIndex];

            Object.assign(current, role);

            this.prepRole(current, fullData);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.prepRole(role, fullData);
            this.dataStore.roles[currentIndex] = role;
        }
    }

    refreshRoles(force?: boolean): Observable<Role[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastRolesRefresh <= 60000) return this.roles;
        this.lastRolesRefresh = _.now();

        const roles$ = this.http
            .get<{ success: boolean; result: Role[] }>(Constants.apiUrl + Constants.apiUrls.roles)
            .pipe(share());

        roles$.subscribe(
            data => {
                const roles = data.result;

                this.dataStore.roles.forEach((existing, existingIndex) => {
                    const newIndex = roles.findIndex(u => u.id === existing.id);
                    if (newIndex === -1) this.dataStore.roles.splice(existingIndex, 1);
                });

                roles.forEach(refreshedRole => this.updateStore(refreshedRole, false));

                this.roles$.next(Object.assign({}, this.dataStore).roles);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ROLES"), error)
        );

        return roles$.pipe(map(r => r.result));
    }

    refreshRole(id: number | Role, force?: boolean): Observable<Role> {
        const currentRole = this.dataStore.roles.find(u => u.id === (typeof id === "number" ? id : id.id));
        if (!force && currentRole && _.now() - currentRole._frontData.lastFullUpdate <= 60000) {
            return new Observable((observe: Subscriber<Role>) => {
                observe.next(currentRole);
                observe.complete();
            });
        }

        const role$ = this.http
            .get<{ success: boolean; result: Role }>(
                Constants.apiUrl + Constants.apiUrls.roles + "/" + (typeof id === "number" ? id : id.id)
            )
            .pipe(share());

        role$.subscribe(
            data => {
                const role = data.result;

                this.updateStore(role, true);

                this.roles$.next(Object.assign({}, this.dataStore).roles);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ROLE"), error)
        );

        return role$.pipe(map(r => r.result));
    }

    // Roles
    getRoles() {
        return this.http.get<Role[]>(Constants.apiUrl + Constants.apiUrls.roles);
    }

    async deleteRole(role: Role) {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl + Constants.apiUrls.roles + "/" + `${role.id}`
                )
                .toPromise();

            const deletedId: number = result.result;

            const roleIndex = this.dataStore.roles.findIndex(f => f.id === deletedId);
            if (roleIndex !== -1) this.dataStore.roles.splice(roleIndex, 1);

            this.roles$.next(Object.assign({}, this.dataStore).roles);
            return true;
        } catch (error) {
            return false;
        }
    }

    async addRole(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<{ result: Role; success: boolean }>(Constants.apiUrl + Constants.apiUrls.roles, model)
                .toPromise();

            const role = result.result;
            this.updateStore(role, false);

            this.roles$.next(Object.assign({}, this.dataStore).roles);
            return role;
        } catch (error) {
            return false;
        }
    }

    // Update Role
    async updateRole(id: number, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<{ success: boolean; result: Role }>(
                    Constants.apiUrl + Constants.apiUrls.roles + "/" + `${id}`,
                    model
                )
                .toPromise();

            const role = result.result;
            this.updateStore(role, false);

            this.roles$.next(Object.assign({}, this.dataStore).roles);
            return role;
        } catch (error) {
            return false;
        }
    }
}
