import { Pipe, PipeTransform } from "@angular/core";
import { OutputNIC } from "./../models/shared";

@Pipe({
    name: "receiverOutput"
})
export class ReceiverOutputPipe implements PipeTransform {
    transform(output: OutputNIC): string {
        if (output.type === "file") {
            return output.name + " (File: " + output.target + ")";
        } else if (output.type === "net") {
            return (
                output.name +
                " (UDP: " +
                output.target +
                (!output.nic || output.nic === "" || output.nic === "127.0.0.1" || output.nic === "0.0.0.0"
                    ? ""
                    : ["@", output.nic].join("")) +
                ")"
            );
        } else {
            return output.name + " (" + output.type.toUpperCase() + ": " + output.target + ")";
        }
    }
}
