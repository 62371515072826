import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "names"
})
export class NamesPipe implements PipeTransform {
    transform(input: { name: string }[]): string {
        input = input || [];
        return input.map(i => i.name).join(", ");
    }
}

@Pipe({
    name: "brackets"
})
export class BracketsPipe implements PipeTransform {
    transform(input: { [key: string]: string }[], plainKey: string, braketsKey?: string): string {
        input = input || [];
        return input
            .map(i => {
                return i[plainKey] + (braketsKey ? ` (${i[braketsKey]})` : "");
            })
            .join(", ");
    }
}
