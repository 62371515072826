<span *ngIf="!singleTargetModel && !channel.targetsSummary">-</span>
<div *ngIf="!singleTargetModel && channel.targetsSummary">
    <fa-icon icon="check-circle" class="status-good me-1" *ngIf="channel.targetsSummary.good > 0"></fa-icon
    ><span class="me-1" *ngIf="channel.targetsSummary.good > 0">{{ channel.targetsSummary.good }}</span>
    <fa-icon icon="minus-circle" class="status-bad me-1" *ngIf="channel.targetsSummary.bad > 0"></fa-icon
    ><span class="me-1" *ngIf="channel.targetsSummary.bad > 0">{{ channel.targetsSummary.bad }}</span>
    <fa-icon icon="circle-exclamation" class="status-warning me-1" *ngIf="channel.targetsSummary.warning > 0"></fa-icon>
    <span class="me-1" *ngIf="channel.targetsSummary.warning > 0">{{ channel.targetsSummary.warning }}</span>
    <fa-icon *ngIf="channel.targetsSummary.muted_bad > 0" class="me-1" icon="bell-slash"></fa-icon>
    <span class="me-1" *ngIf="channel.targetsSummary.muted_bad > 0">{{ channel.targetsSummary.muted_bad }}</span>
    <fa-icon icon="ban" class="status-disabled me-1" *ngIf="channel.targetsSummary.disabled > 0"></fa-icon>
    <span class="me-1" *ngIf="channel.targetsSummary.disabled > 0">{{ channel.targetsSummary.disabled }}</span>
    <fa-icon icon="dot-circle" class="status-disabled me-1" *ngIf="channel.targetsSummary.pending > 0"></fa-icon>
    <span *ngIf="channel.targetsSummary.pending > 0">{{ channel.targetsSummary.pending }}</span>
    <span
        *ngIf="
            !channel.targetsSummary.pending &&
            !channel.targetsSummary.disabled &&
            !channel.targetsSummary.muted_bad &&
            !channel.targetsSummary.warning &&
            !channel.targetsSummary.bad &&
            !channel.targetsSummary.good
        "
        >-</span
    >
</div>
<zx-target *ngIf="singleTargetModel" [model]="singleTargetModel" [showLink]="true"></zx-target>
