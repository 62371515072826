import { Pipe, PipeTransform } from "@angular/core";
import { FeederInput } from "./../models/shared";

@Pipe({
    name: "feederInputType"
})
export class FeederInputTypePipe implements PipeTransform {
    transform(input: FeederInput): string {
        if (input.rtmp) {
            return "RTMP";
        } else if (input.asi) {
            return "ASI";
        } else if (input.file) {
            return "FILE";
        } else if (input.udp) {
            return "UDP";
        } else {
            return "Unknown";
        }
    }
}
