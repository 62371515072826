import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { share, map } from "rxjs/operators";
import moment from "moment";
import { Constants } from "../constants/constants";
import { TargetApiType } from "../pages/channels/channel";
import _ from "lodash";

interface nDModel {
    series: {
        x: {
            points: { x: number; y: number }[];
        };
        y: {
            points: { x: number; y: number }[];
        };
        pointSizes: {
            points: { x: number; y: number }[];
        };
        pointColor: {
            points: { x: number; y: number }[];
        };
        style: {
            pointNames: {
                points: { x: number; y: string }[];
            };
        };
    }[];
}

interface nDResponse {
    success: boolean;
    result: nDModel;
}

@Injectable({
    providedIn: "root"
})
export class NDService {
    constructor(private http: HttpClient) {}

    async causalityChart(sourceId: number, from: moment.Moment, to: moment.Moment) {
        const causalityChart$ = this.http
            .get<nDResponse>(Constants.apiUrl + Constants.apiUrls.source + `/${sourceId}/nd_explain`, {
                params: {
                    from: from.toISOString(),
                    to: to.toISOString(),
                    type: "not_recovered"
                }
            })
            .pipe(share());

        return causalityChart$.pipe(map(r => r.result)).toPromise();
    }
    async targetCausalityChart(targetId: number, targetType: TargetApiType, from: moment.Moment, to: moment.Moment) {
        const causalityChart$ = this.http
            .get<nDResponse>(Constants.apiUrl + Constants.apiUrls.target + `/${targetType}/${targetId}/nd_explain`, {
                params: {
                    from: from.toISOString(),
                    to: to.toISOString(),
                    type: "not_recovered"
                }
            })
            .pipe(share());

        return causalityChart$.pipe(map(r => r.result)).toPromise();
    }
}
