<div class="modal-header">
    <h3 class="modal-title">{{ dialogTitle }}</h3>
    <button type="button" class="close" aria-label="Close" [disabled]="isSaving" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <!-- Description -->
        <app-input-generic-field
            [formControl]="form.controls.description"
            [textFieldName]="'DESCRIPTION' | translate"
            type="text"
            [isParentFormSubmitted]="isFormSubmitted"
        ></app-input-generic-field>
        <!-- From -->
        <app-radio-input
            label="{{ 'FROM' | translate }}"
            [inputKeyAndValues]="FROM_OPTIONS"
            [formControl]="form.controls.from"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-radio-input>
        <!-- From: Cluster -->
        <div *ngIf="form.value.from === FROM_OPTIONS.CLUSTER" class="form-group">
            <label [ngClass]="{ 'is-invalid': isClientClusterInvalid }" for="cluster"
                >{{ "CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <zx-cluster-select
                [ngClass]="{ 'is-invalid': isClientClusterInvalid }"
                name="cluster"
                [(ngModel)]="clientClusterId"
                [(model)]="clientClusterId"
                [required]="form.value.from === FROM_OPTIONS.CLUSTER"
            ></zx-cluster-select>
            <div *ngIf="isClientClusterInvalid" class="invalid-feedback">{{ "CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
        </div>
        <!-- From: CIDR -->
        <app-input-generic-field
            *ngIf="form.value.from === FROM_OPTIONS.CIDR"
            [formControl]="form.controls.client_cidr"
            [textFieldName]="'CIDR' | translate"
            type="text"
            placeHolder="e.g. 0.0.0.0/0"
            [patternErrorMessage]="'INVALID_CIDR' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        ></app-input-generic-field>
        <!-- TO -->
        <app-select-generic-field
            title="{{ 'TO' | translate }}"
            valueToBind="value"
            [formControl]="form.controls.to"
            placeHolder="{{ 'SELECT' | translate }} {{ 'TO' | translate }}"
            [isParentFormSubmitted]="isFormSubmitted"
            [items]="TO_OPTIONS"
        >
        </app-select-generic-field>
        <!-- TO: NIC CIDR -->
        <app-input-generic-field
            *ngIf="form.value.to === TO_OPTION_REDIRECT_TO_NIC"
            [formControl]="form.controls.nic_cidr"
            [textFieldName]="'NIC_CIDR' | translate"
            type="text"
            placeHolder="e.g. 0.0.0.0/0"
            [patternErrorMessage]="'INVALID_CIDR' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        ></app-input-generic-field>
        <!-- Latency Override [ms] -->
        <app-input-generic-field
            [formControl]="form.controls.latency"
            [textFieldName]="'LATENCY_OVERRIDE_MS' | translate"
            type="number"
            [isParentFormSubmitted]="isFormSubmitted"
        ></app-input-generic-field>
        <!-- Order -->
        <app-input-generic-field
            [formControl]="form.controls.order"
            [textFieldName]="'ORDER' | translate"
            type="number"
            [isParentFormSubmitted]="isFormSubmitted"
        ></app-input-generic-field>
        <small>{{ "REDIRECTION_RULES_ORDER_NOTE" | translate }}</small>
        <!-- Error -->
        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" [disabled]="isSaving" (click)="activeModal.close()">
            <fa-icon icon="times"></fa-icon>
            {{ "CLOSE" | translate }}
        </button>
        <button type="button" class="btn btn-primary" [disabled]="isSaving" zmid="form-submit" type="submit">
            <fa-icon icon="check" size="sm" class="me-1"></fa-icon>{{ "SAVE" | translate }}
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>
