<ng-select
    name="{{ name }}"
    [disabled]="disabled"
    [items]="grids"
    [loading]="loading"
    [multiple]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    bindLabel="name"
    placeholder="{{ 'SELECT' | translate }} {{ placeholder | translate }} {{ 'GRIDS' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="{{ required || false }}"
>
</ng-select>
