import { Pipe, PipeTransform } from "@angular/core";
import { ZixiObject } from "./../models/shared";

@Pipe({
    name: "state"
})
export class StatePipe implements PipeTransform {
    transform(model: ZixiObject): string {
        // Error
        if (model.objectState?.state === "error" || model.objectState?.state === "warning")
            return model.objectState.state;
        // Targets
        // 12 Types
        else if (
            ((model.type === "udp_rtp" ||
                model.type === "zixi_pull" ||
                model.type === "publishing_target" ||
                model.type === "rist" ||
                model.type === "rtmp_push" ||
                model.type === "zixi_push" ||
                model.type === "srt_targets" ||
                model.type === "ndi_targets" ||
                model.type === "cdi" ||
                model.type === "jpegxs" ||
                model.type === "mediaconnect") &&
                model.adaptiveChannel &&
                !model.adaptiveChannel.is_enabled) ||
            (model.deliveryChannel && !model.deliveryChannel.is_enabled) ||
            (model.mediaconnectFlow && !model.mediaconnectFlow.is_enabled) ||
            model.generalStatus === "no_channel"
        ) {
            return "disabled";
        } else if (model.type === "medialive_http" && model.medialiveChannel && !model.medialiveChannel.is_enabled) {
            return "disabled";
        } else if (model.type === "broadcaster_cluster" || model.is_enabled)
            return model.state === "pending" ? model.state : model.generalStatus;
        else return "disabled";
    }
}
