import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ObjectTrackingService } from "../object-tracking.service";
import { ObjectTrackingClient as ObjectTrackingChange } from "@zixi/models";
import { TableSchema } from "../../table-list/table-list.component";
import { KeyMap } from "../../../../models/shared";
import { assignNgbHighlightInputsFactory } from "../../zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxNgbHighlightComponent } from "../../zx-ngb-highlight/zx-ngb-highlight.component";
import { ZxTableActionsComponent } from "../../zx-table-actions/zx-table-actions.component";
import { assignGenericLinkTextInputsFactory } from "../../zx-table-actions/zx-table-actions.table-adapter";

@Component({
    selector: "zx-object-tracking-sub-list",
    templateUrl: "./zx-object-tracking-sub-list.component.html"
})
export class ZxObjectTrackingSubListComponent {
    @Input() changes: ObjectTrackingChange[];
    @Input() onRevertCallback: (change: ObjectTrackingChange) => void;

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translateService.instant("CHANGE"),
            columnDef: "change_title",
            width: 220,
            visible: true,
            sticky: 0,
            sortBy: (change: KeyMap<ObjectTrackingChange>): string => change.change_title
        },
        {
            header: this.translateService.instant("PREVIOUS_VALUE"),
            columnDef: "previous_value",
            width: 220,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<ObjectTrackingChange>(
                change => (change.is_hide_value ? "***" : change.revert_value_title),
                change => (change.is_hide_value ? "***" : change.revert_value_title),
                () => true
            ),
            sortBy: (change: KeyMap<ObjectTrackingChange>): string =>
                change.is_hide_value ? "***" : change.revert_value_title
        },
        {
            header: this.translateService.instant("UPDATED_VALUE"),
            columnDef: "updated_value",
            width: 220,
            visible: true,
            sticky: 2,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<ObjectTrackingChange>(
                change => (change.is_hide_value ? "***" : change.updated_value_title),
                change => (change.is_hide_value ? "***" : change.updated_value_title),
                () => true
            ),
            sortBy: (change: KeyMap<ObjectTrackingChange>): string =>
                change.is_hide_value ? "***" : change.updated_value_title
        },
        {
            header: this.translateService.instant("ACTIONS"),
            columnDef: "actions",
            hideColumnName: true,
            width: 100,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "clock-rotate-left",
                    translateTitle: "REVERT",
                    isTextVisible: true,
                    onClickHandler: this.onRevertClicked.bind(this),
                    isVisibleCallback: (change: ObjectTrackingChange) =>
                        this.objectTrackingService.isChangeRevertible(change)
                }
            ])
        }
    ];
    constructor(private translateService: TranslateService, private objectTrackingService: ObjectTrackingService) {}

    onRevertClicked(change: ObjectTrackingChange) {
        this.onRevertCallback(change);
    }
}
