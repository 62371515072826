import { Pipe, PipeTransform } from "@angular/core";
import { ZecInput } from "../models/shared";

@Pipe({
    name: "zecInputStatus"
})
export class ZecInputStatusPipe implements PipeTransform {
    transform(input: ZecInput): string {
        if (!input.error_code && input.status !== "Offline" && input.bitrate > 0) {
            return "good";
        } else if (!input.error_code && input.status === "Offline") {
            return "none";
        } else if (input.error_code) {
            return "error";
        } else if (!input.error_code && input.bitrate === 0) {
            return "warning";
        } else return "null";
    }
}
