import { Directive, ElementRef, Output, EventEmitter, HostListener } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[clickOutside]"
})
export class ClickOutsideDirective {
    constructor(private elementRef: ElementRef) {}

    @Output() clickOutside = new EventEmitter<void>();

    @HostListener("click", ["$event"])
    clickInside(event: MouseEvent) {
        event.stopPropagation();
    }

    @HostListener("document:click", ["$event"])
    public onClick(event: Event) {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside) {
            this.clickOutside.emit();
        }
    }
}
