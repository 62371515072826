import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

@Pipe({
    name: "bytes"
})
export class BytesPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}

    transform(bytes: number | string, precision: string): string {
        const bytesN: number = typeof bytes === "string" ? parseFloat(bytes) : bytes;

        if (bytes === 0 || isNaN(bytesN) || !isFinite(bytesN)) return "-";
        if (typeof precision === "undefined") precision = ".2";

        const units = ["bytes", "kB", "MB", "GB", "TB", "PB"];
        const magnitude = Math.floor(Math.log(bytesN) / Math.log(1000));

        return (
            this.decimalPipe.transform(bytesN / Math.pow(1000, Math.floor(magnitude)), precision) +
            " " +
            units[magnitude]
        );
    }
}
