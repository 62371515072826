import { Component, Input } from "@angular/core";
import { Cluster } from "src/app/pages/clusters/cluster";

@Component({
    selector: "zx-cluster-broadcasters-col",
    templateUrl: "./zx-cluster-broadcasters-col.component.html"
})
export class ZxClusterBroadcastersColComponent {
    @Input() cluster: Cluster;
}
