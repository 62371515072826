import { Tag } from "../../models/shared";

export class Report {
    _frontData?: {
        sortableStatus: string;
    };
    id: number;
    name: string;
    type: string;
    resourceTags: Tag[];
    options: {
        thresholds?: {
            bitrate?: number;
        };
        transcoding?: boolean;
        constants?: string[];
    };
    breakdown: string;
    timezone: string;
    from: string;
    to: string;
    period: string;
    period_param: string;
    feeders: number;
    clusters: number;
    broadcasters: number;
    receivers: number;
    sources: number;
    channels: number;
    targets: number;
    all_resource_tags: number;
    name_filter_include: string;
    name_filter_exclude: string;
    show_error: number;
    show_warning: number;
    show_ok: number;
    show_pending: number;
    show_disabled: number;
    created_at: string;
    updated_at: string;
    includeResourceTags: Tag[];
    excludeResourceTags: Tag[];
    hasFullDetails?: boolean;
}

export class Run {
    _frontData?: {
        sortableStatus: string;
    };
    id: number;
    report_template_id: number;
    status: string;
    name: string;
    path: string;
    from: string;
    to: string;
    timezone: string;
    created_at: string;
    updated_at: string;
}
