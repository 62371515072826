<spinner-animation *ngIf="loading"></spinner-animation>

<div class="table-responsive" *ngIf="!loading">
    <table class="table table-hover table-select table-sm bordered m-0" [ngClass]="ngClass" class="{{ class }}">
        <thead>
            <tr>
                <th scope="col" sortable="name" (sort)="onSort($event)" [sortIf]="sortColumn === 'name'" [sortDirection]="sortDirection">
                    {{ "FILE" | translate }}
                </th>
                <th
                    scope="col"
                    class="w-100px text-end"
                    sortable="size"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'size'"
                    [sortDirection]="sortDirection"
                >
                    {{ "SIZE" | translate }} [MB]
                </th>
                <th scope="col" class="w-100px" sortable="date" (sort)="onSort($event)" [sortIf]="sortColumn === 'date'" [sortDirection]="sortDirection">
                    {{ "DATE_MODIFIED" | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="selectedPath !== ''" (click)="closeFolder()">
                <td colspan="3"><fa-icon icon="level-up-alt" class="fa-flip-horizontal" size="sm"> </fa-icon></td>
            </tr>
            <tr *ngFor="let folder of lsData?.dirs" (click)="openFolder(folder.name)" [ngClass]="{ selected: selectedFolder(folder) }">
                <td colspan="3"><fa-icon icon="folder" size="sm"></fa-icon> {{ folder.name }}</td>
            </tr>
            <tr *ngFor="let file of videoFiles(lsData?.files)" (click)="selectFile(file)" [ngClass]="{ selected: selectedFile(file) }">
                <td><fa-icon icon="file-video" size="sm"></fa-icon> {{ file.name }}</td>
                <td class="text-end">{{ file.size ? (file.size / 1000 / 1000 | number : "1.2-2") : "-" }}</td>
                <td>{{ file.date | date : "MMM d, y, h:mm:ss a" }}</td>
            </tr>
            <tr *ngIf="videoFiles(lsData?.files).length < 1 && lsData?.dirs.length < 1">
                <td colspan="3" class="text-center">{{ "NO_FILES" | translate }}</td>
            </tr>
        </tbody>
    </table>
</div>
