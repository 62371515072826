import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { Subscription } from "rxjs";

import { TranscodingProfilesService } from "../../../pages/transcoding-profiles/transcoding-profiles.service";
import { TranscodingProfile } from "../../../pages/transcoding-profiles/transcoding-profile";
import { BitratePipe } from "./../../../pipes/bitrate.pipe";

@Component({
    selector: "zx-transcoding-profile-select",
    templateUrl: "./zx-transcoding-profile-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxTranscodingProfileSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: TranscodingProfile;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Output() modelChange = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() change = new EventEmitter();

    loading: boolean;
    transcodingProfiles: TranscodingProfile[];
    private transcodingProfilesSubscription: Subscription;

    constructor(private tps: TranscodingProfilesService, private bp: BitratePipe) {}

    modelChanged() {
        this.modelChange.emit(this.model);
        this.change.emit(this.model);
    }

    profileLabel(profile: TranscodingProfile) {
        const label = profile.name;
        let details = " (Video - ";

        if (profile.do_video && !profile.keep_video)
            details = details + (profile.video_codec === "h264" ? "H.264" : "H.265") + " - ";

        if (profile.do_video && !profile.source_resolution) details = details + profile.width + "x" + profile.height;
        else if (profile.keep_video || profile.source_resolution) details = details + "Original";
        else details = details + "Remove";

        if (profile.do_video && !profile.keep_video) details = details + " @ " + profile.bitrate_avg + " kbps";

        details = details + ", Audio - ";

        if (profile.do_audio && !profile.keep_audio)
            details =
                details +
                (profile.audio_codec === "aac" ? "AAC" : profile.audio_codec === "ac3" ? "AC3" : "MPGA1") +
                " - ";

        if (profile.do_audio) details = details + profile.audio_bitrate + " kbps";
        else if (profile.keep_audio) details = details + "Original";
        else details = details + "Remove";

        details = details + ")";

        return label + details;
    }

    ngOnInit() {
        this.loading = true;
        this.tps.refreshTranscodingProfiles();
        this.transcodingProfilesSubscription = this.tps.transcodingProfiles.subscribe(transcodingProfiles => {
            this.transcodingProfiles = [...transcodingProfiles];
            this.loading = false;
        });

        this.modelChanged();
    }

    ngOnDestroy() {
        this.transcodingProfilesSubscription.unsubscribe();
    }

    customSearchFn(term: string, item: TranscodingProfile) {
        term = term.toLowerCase();
        return item.name.toLowerCase().indexOf(term) > -1;
    }
}
