<ng-select
    name="{{ name }}"
    [items]="groups"
    [loading]="loading"
    [multiple]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    bindLabel="name"
    placeholder="{{ 'SELECT' | translate }} {{ 'GROUPS' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="{{ required || false }}"
>
</ng-select>
