<fieldset ngModelGroup="{{ 'fieldset_' + ruleUniqId }}" #group="ngModelGroup" [disabled]="disabled">
    <div class="rule-buttons" *ngIf="!disabled">
        <button
            *ngIf="!rule.alerting_profile_id && saveButton"
            type="button"
            class="btn btn-sm btn-outline-secondary"
            title="{{ 'RESET' | translate }}"
            (click)="reloadRule()"
            [disabled]="deletingRule"
        >
            <fa-icon icon="undo" size="sm"></fa-icon>
            {{ "RESET" | translate }}
            <span *ngIf="deletingRule" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
        <button
            *ngIf="rule.alerting_profile_id"
            type="button"
            class="btn btn-sm btn-outline-secondary"
            title="{{ 'RESET' | translate }}"
            (click)="resetRule()"
            [disabled]="deletingRule"
        >
            <fa-icon icon="undo" size="sm"></fa-icon>
            {{ "RESET" | translate }}
            <span *ngIf="deletingRule" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
        <button
            *ngIf="saveButton"
            type="button"
            class="btn btn-sm btn-primary ms-2"
            title="{{ 'SAVE' | translate }}"
            (click)="saveRule()"
            [disabled]="ruleErrors"
            [ngClass]="{ 'btn-danger': isFormSubmitted && ruleErrors }"
        >
            <fa-icon icon="check" size="sm"></fa-icon>
            {{ "SAVE" | translate }}
            <span *ngIf="savingRule" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </div>
    <!-- Stateful -->
    <div class="form-group" *ngIf="rule.can_modify && !rule.disabled" [tourAnchor]="firstRule ? 'thirdARFormAnchor' : ''">
        <div class="form-check">
            <input
                type="checkbox"
                class="form-check-input"
                [id]="'stateful_' + ruleUniqId"
                [name]="'stateful_' + ruleUniqId"
                invertChecked
                [(ngModel)]="rule.stateful"
            />
            <label class="form-check-label" for="{{ 'stateful_' + ruleUniqId }}">
                {{ "STATEFUL_DESCRIPTION" | translate }}
            </label>
        </div>
    </div>
    <!-- Disabled -->
    <app-checkbox-field
        [tourAnchor]="firstRule ? 'fourthARFormAnchor' : ''"
        *ngIf="rule.disabled || (rule.can_disable && rule.can_modify && rule.stateful)"
        [label]="'DISABLE' | translate"
        [(ngModel)]="rule.disabled"
        name="ruleDisabled"
        [id]="'disabled_' + ruleUniqId"
    ></app-checkbox-field>
    <!-- Ok Notify -->
    <app-checkbox-field
        [tourAnchor]="firstRule ? 'fifthARFormAnchor' : ''"
        *ngIf="!rule.disabled && rule.stateful"
        [label]="'OK_NOTIFY' | translate"
        [(ngModel)]="rule.ok_notify"
        name="ruleOkNotify"
        [id]="'ok_notify_' + ruleUniqId"
    ></app-checkbox-field>
    <!-- Error Notify -->
    <app-checkbox-field
        [tourAnchor]="firstRule ? 'sixthARFormAnchor' : ''"
        *ngIf="!rule.disabled && rule.stateful"
        [label]="'ERROR_NOTIFY' | translate"
        [(ngModel)]="rule.error_notify"
        name="ruleErrorNotify"
        [id]="'error_notify_' + ruleUniqId"
    ></app-checkbox-field>
    <!-- Force Notify -->
    <app-checkbox-field
        [tourAnchor]="firstRule ? 'seventhARFormAnchor' : ''"
        *ngIf="!rule.disabled && rule.stateful"
        [label]="'FORCE_NOTIFY' | translate"
        [(ngModel)]="rule.force_notify"
        name="ruleForceNotify"
        [id]="'force_notify_' + ruleUniqId"
    ></app-checkbox-field>

    <!-- Warning -->
    <div [tourAnchor]="firstRule ? 'eigthARFormAnchor' : ''">
        <fieldset class="form-group bordered" *ngIf="rule.can_modify && !rule.disabled">
            <legend class="form-check ms-neg-1rem mb-0">
                <input
                    type="checkbox"
                    class="form-check-input"
                    [id]="'warning_' + ruleUniqId"
                    [name]="'warning_' + ruleUniqId"
                    [(ngModel)]="warningCheck"
                    (change)="warningCheckChange(warningCheck)"
                />
                <label class="not-bold" for="{{ 'warning_' + ruleUniqId }}">
                    {{ "WARNING" | translate }}
                </label>
            </legend>
            <div class="row">
                <div class="col">
                    <!-- Newest -->
                    <div class="row mb-2">
                        <div class="col">
                            <div class="row row-cols-lg-auto g-3 align-items-top" [tourAnchor]="firstRule ? 'ninthARFormAnchor' : ''">
                                <div class="col-12 col-sm-6">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "THRESHOLD" | translate | titlecase }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                [id]="'warning_threshold_' + ruleUniqId"
                                                [name]="'warning_threshold_' + ruleUniqId"
                                                class="form-control form-control-sm"
                                                [(ngModel)]="rule.warning_threshold"
                                                [step]="thresholdConfigs().step"
                                                [min]="thresholdConfigs().min"
                                                [max]="thresholdConfigs().max"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && warningThreshold?.errors }"
                                                #warningThreshold="ngModel"
                                                [disabled]="!warningCheck"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0">{{ rule.threshold_units }}</label>
                                        </div>
                                        <div
                                            class="input-group-append ms-1"
                                            *ngIf="
                                                rule.escalation_type === 'simple' &&
                                                rule.warning_threshold !== null &&
                                                rule.state_warning_window !== null &&
                                                rule.warning_threshold >= 0 &&
                                                rule.state_warning_window >= 0
                                            "
                                        >
                                            ({{ (rule.state_warning_window * (rule.warning_threshold / 100) | minutesSeconds) + " mm:ss" }})
                                        </div>
                                    </div>
                                    <div *ngIf="warningThreshold?.invalid" class="invalid-feedback">
                                        <div *ngIf="warningThreshold?.errors.min || warningThreshold?.errors.max">
                                            {{ "WARNING_THRESHOLD" | translate }} {{ thresholdConfigs().error | translate }}.
                                        </div>
                                        <div *ngIf="warningThreshold?.errors.required">
                                            {{ "WARNING_THRESHOLD" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 ps-0" *ngIf="rule.escalation_type === 'simple'">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "OF_A" | translate }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm"
                                                [min]="rule.state_error_window"
                                                [max]="60"
                                                [name]="'warn_window_' + ruleUniqId"
                                                [(ngModel)]="rule.state_warning_window"
                                                [disabled]="!warningCheck"
                                                required
                                                pattern="^\d+$"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && warnWindow?.errors }"
                                                #warnWindow="ngModel"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0">{{ "MINUTE_WINDOW" | translate | lowercase }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="warnWindow?.invalid" class="invalid-feedback">
                                        <div *ngIf="warnWindow?.errors.required">{{ "WARNING_WINDOW" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                        <div *ngIf="warnWindow?.errors.pattern">
                                            {{ "WARNING_WINDOW" | translate }}
                                            {{ "MUST_BE_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="warnWindow?.errors.min || warnWindow?.errors.max">
                                            {{ "WARNING_WINDOW" | translate }} must be between {{ rule.state_error_window }} and 60.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row mb-2"
                        *ngIf="rule.can_delay_escalation && !(rule.threshold_units === 'days' && rule.reverse_thresholds)"
                        [tourAnchor]="firstRule ? 'tenthARFormAnchor' : ''"
                    >
                        <div class="col">
                            <div class="row row-cols-lg-auto g-3 align-items-top">
                                <div class="col-12">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "TRIGGER_WARNING_AFTER" | translate }}</label>
                                        </div>
                                        <div class="input-group-div w-25-sm">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm"
                                                [min]="0"
                                                [max]="30"
                                                [name]="'warn_delay_window_' + ruleUniqId"
                                                [(ngModel)]="rule.warning_escalation_delay"
                                                [disabled]="!warningCheck"
                                                required
                                                pattern="^\d+$"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && warnDelayWindow?.errors }"
                                                #warnDelayWindow="ngModel"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units !== 'db'">{{
                                                "MINUTES_ABOVE_THRESHOLD" | translate
                                            }}</label>
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units === 'db'">{{
                                                "MINUTES_BELOW_THRESHOLD" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="warnDelayWindow?.invalid" class="invalid-feedback">
                                        <div *ngIf="warnDelayWindow?.errors.required">
                                            {{ "TRIGGER_WARNING_AFTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="warnDelayWindow?.errors.pattern">
                                            {{ "TRIGGER_WARNING_AFTER" | translate }}
                                            {{ "MUST_BE_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="warnDelayWindow?.errors.min || warnDelayWindow?.errors.max">
                                            {{ "TRIGGER_WARNING_AFTER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_30" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="rule.can_delay_deescalation && !(rule.threshold_units === 'days' && rule.reverse_thresholds)">
                        <div class="col">
                            <div class="row row-cols-lg-auto g-3 align-items-top">
                                <div class="col-12">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "CLEAR_WARNING_AFTER" | translate }}</label>
                                        </div>
                                        <div class="input-group-div w-25-sm">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm"
                                                [min]="0"
                                                [max]="30"
                                                [name]="'warn_escalation_window_' + ruleUniqId"
                                                [(ngModel)]="rule.warning_deescalation_delay"
                                                [disabled]="!warningCheck"
                                                required
                                                pattern="^\d+$"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && warnEscalationWindow?.errors }"
                                                #warnEscalationWindow="ngModel"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units === 'db'">{{
                                                "MINUTES_ABOVE_THRESHOLD" | translate
                                            }}</label>
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units !== 'db'">{{
                                                "MINUTES_BELOW_THRESHOLD" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="warnEscalationWindow?.invalid" class="invalid-feedback">
                                        <div *ngIf="warnEscalationWindow?.errors.required">
                                            {{ "CLEAR_WARNING_AFTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="warnEscalationWindow?.errors.pattern">
                                            {{ "CLEAR_WARNING_AFTER" | translate }}
                                            {{ "MUST_BE_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="warnEscalationWindow?.errors.min || warnEscalationWindow?.errors.max">
                                            {{ "CLEAR_WARNING_AFTER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_30" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

        <!-- Error -->
        <fieldset class="form-group bordered" *ngIf="rule.can_modify && !rule.disabled">
            <legend class="form-check ms-neg-1rem mb-0">
                <input
                    type="checkbox"
                    class="form-check-input"
                    [id]="'error_' + ruleUniqId"
                    [name]="'error_' + ruleUniqId"
                    [(ngModel)]="errorCheck"
                    (change)="errorCheckChange(errorCheck)"
                />
                <label class="not-bold" for="{{ 'error_' + ruleUniqId }}">
                    {{ "ERROR" | translate }}
                </label>
            </legend>
            <div class="row">
                <div class="col">
                    <div class="row mb-2">
                        <div class="col">
                            <div class="row row-cols-lg-auto g-3 align-items-top">
                                <div class="col-12 col-sm-6">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "THRESHOLD" | translate | titlecase }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                [id]="'error_threshold_' + ruleUniqId"
                                                [name]="'error_threshold_' + ruleUniqId"
                                                class="form-control form-control-sm"
                                                [step]="thresholdConfigs(true).step"
                                                [(ngModel)]="rule.error_threshold"
                                                [min]="thresholdConfigs(true).min"
                                                [max]="thresholdConfigs(true).max"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && errorThreshold?.errors }"
                                                #errorThreshold="ngModel"
                                                [disabled]="!errorCheck"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0">{{ rule.threshold_units }}</label>
                                        </div>
                                        <div
                                            class="input-group-append ms-1"
                                            *ngIf="
                                                rule.escalation_type === 'simple' &&
                                                rule.error_threshold !== null &&
                                                rule.state_error_window !== null &&
                                                rule.error_threshold >= 0 &&
                                                rule.state_error_window >= 0
                                            "
                                        >
                                            ({{ (rule.state_error_window * (rule.error_threshold / 100) | minutesSeconds) + " mm:ss" }})
                                        </div>
                                    </div>
                                    <div *ngIf="errorThreshold?.invalid" class="invalid-feedback">
                                        <div *ngIf="errorThreshold?.errors.min || errorThreshold?.errors.max">
                                            {{ "ERROR_THRESHOLD" | translate }} {{ thresholdConfigs(true).error | translate }}.
                                        </div>
                                        <div *ngIf="errorThreshold?.errors.required">{{ "ERROR_THRESHOLD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 ps-0" *ngIf="rule.escalation_type === 'simple'">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "OF_A" | translate }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm"
                                                [min]="0"
                                                [max]="60"
                                                [name]="'err_window_' + ruleUniqId"
                                                [(ngModel)]="rule.state_error_window"
                                                [disabled]="!errorCheck"
                                                required
                                                pattern="^\d+$"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && errWindow?.errors }"
                                                #errWindow="ngModel"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0">{{ "MINUTE_WINDOW" | translate | lowercase }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="errWindow?.invalid" class="invalid-feedback">
                                        <div *ngIf="errWindow?.errors.required">{{ "ERROR_WINDOW" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                        <div *ngIf="errWindow?.errors.pattern">
                                            {{ "ERROR_WINDOW" | translate }}
                                            {{ "MUST_BE_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="errWindow?.errors.min || errWindow?.errors.max">
                                            {{ "ERROR_WINDOW" | translate }} {{ "MUST_BE_BETWEEN_0_AND_60" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2" *ngIf="rule.can_delay_escalation && !(rule.threshold_units === 'days' && rule.reverse_thresholds)">
                        <div class="col">
                            <div class="row row-cols-lg-auto g-3 align-items-top">
                                <div class="col-12">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "TRIGGER_ERROR_AFTER" | translate }}</label>
                                        </div>
                                        <div class="input-group-div w-25-sm">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm"
                                                [min]="0"
                                                [max]="30"
                                                [name]="'err_delay_window_' + ruleUniqId"
                                                [(ngModel)]="rule.error_escalation_delay"
                                                [disabled]="!errorCheck"
                                                required
                                                pattern="^\d+$"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && errDelayWindow?.errors }"
                                                #errDelayWindow="ngModel"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units !== 'db'">{{
                                                "MINUTES_ABOVE_THRESHOLD" | translate
                                            }}</label>
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units === 'db'">{{
                                                "MINUTES_BELOW_THRESHOLD" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="errDelayWindow?.invalid" class="invalid-feedback">
                                        <div *ngIf="errDelayWindow?.errors.required">
                                            {{ "TRIGGER_ERROR_AFTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="errDelayWindow?.errors.pattern">
                                            {{ "TRIGGER_ERROR_AFTER" | translate }}
                                            {{ "MUST_BE_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="errDelayWindow?.errors.min || errDelayWindow?.errors.max">
                                            {{ "TRIGGER_ERROR_AFTER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_30" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2" *ngIf="rule.can_delay_deescalation && !(rule.threshold_units === 'days' && rule.reverse_thresholds)">
                        <div class="col">
                            <div class="row row-cols-lg-auto g-3 align-items-top">
                                <div class="col-12">
                                    <div class="input-group align-items-center lh-10">
                                        <div class="input-group-append me-2">
                                            <label class="not-bold mb-0">{{ "CLEAR_ERROR_AFTER" | translate }}</label>
                                        </div>
                                        <div class="input-group-div w-25-sm">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm"
                                                [min]="0"
                                                [max]="30"
                                                [name]="'err_escalation_window_' + ruleUniqId"
                                                [(ngModel)]="rule.error_deescalation_delay"
                                                [disabled]="!errorCheck"
                                                required
                                                pattern="^\d+$"
                                                [ngClass]="{ 'is-invalid': isFormSubmitted && errEscalationWindow?.errors }"
                                                #errEscalationWindow="ngModel"
                                            />
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units === 'db'">{{
                                                "MINUTES_ABOVE_THRESHOLD" | translate
                                            }}</label>
                                            <label class="not-bold mb-0" *ngIf="rule.threshold_units !== 'db'">{{
                                                "MINUTES_BELOW_THRESHOLD" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="errEscalationWindow?.invalid" class="invalid-feedback">
                                        <div *ngIf="errEscalationWindow?.errors.required">
                                            {{ "CLEAR_ERROR_AFTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="errEscalationWindow?.errors.pattern">
                                            {{ "CLEAR_ERROR_AFTER" | translate }}
                                            {{ "MUST_BE_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="errEscalationWindow?.errors.min || errEscalationWindow?.errors.max">
                                            {{ "CLEAR_ERROR_AFTER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_30" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
    <!-- Error -->
    <div *ngIf="rule.error">
        <span class="status-error">{{ "ERROR" | translate }}: {{ rule.error }}</span>
    </div>
</fieldset>
