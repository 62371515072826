import { OnDestroy, Component } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Broadcaster } from "@zixi/models";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-delete-disable-broadcaster-dialog",
    templateUrl: "./delete-disable-broadcaster-dialog.component.html"
})
export class DeleteDisableBroadcasterDialogComponent implements OnDestroy {
    action: "DELETE" | "DISABLE";
    name: string;
    subjectBroadcasterId: number;
    clusterBroadcaters: Broadcaster[];
    confirmHandler: (revover: boolean, target_broadcaster_id: number | null) => Promise<unknown>;
    saving: boolean;

    target_broadcaster_id: number | null = null;
    autorecover: boolean;

    targetBXs: { name: string; id: number }[] = [];
    baseTargetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1 },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4 }
    ];

    private routeSubscription: Subscription;

    constructor(public activeModal: NgbActiveModal, private router: Router, private translate: TranslateService) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    fillBroadcasters(confirmedBxId: number, bxs: Broadcaster[]) {
        this.subjectBroadcasterId = confirmedBxId;
        this.targetBXs = this.baseTargetBXs.concat(bxs.filter(bx => bx.id !== confirmedBxId));
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        const result = await this.confirmHandler(this.autorecover, this.target_broadcaster_id);
        if (result === false) {
            this.saving = false;
        } else {
            this.saving = false;
            this.activeModal.close();
        }
    }
}
