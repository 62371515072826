<!-- Graph-->
<zx-history-graph
    (from)="setFrom($event)"
    (to)="setTo($event)"
    (opened)="setOpenedGraphs($event)"
    (closed)="setClosedGraphs($event)"
    [graphUrl]="graphLink"
    [parentFrom]="from"
    [parentTo]="to"
    [hideAddIncident]="true"
    *ngIf="!loading && !noGraphs && graphLink"
></zx-history-graph>
<div *ngIf="!loading && noGraphs">
    <p class="mb-0">{{ "NO_GRAPHS_AVAILABLE" | translate }}</p>
</div>
<div
    [ngStyle]="{
        'margin-top.px': 40
    }"
    *ngIf="!loading && !graphLink && !noGraphs"
>
    <p class="mb-0">{{ "NO_GRAPHS_AVAILABLE" | translate }}</p>
</div>
<!-- Loading -->
<div *ngIf="loading">
    <spinner-animation></spinner-animation>
</div>
