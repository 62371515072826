<div class="thumbnail" #thumbnailContainer [hidden]="!source" [ngClass]="{ 'border-0 mb-0': !bordered }">
    <div class="top-info" *ngIf="info">
        <div class="d-flex justify-content-between mb-1" [ngClass]="{ 'ms-2 me-2': !bordered && !grid }">
            <div class="ellipsis">
                <a
                    target="_blank"
                    href="https://console.aws.amazon.com/medialive/home?region={{ source.elemental_link_region }}#!/devices/{{ source.elemental_link_id }}"
                    ><strong>{{ source.elemental_link_id }}</strong></a
                >
            </div>
            <div class="whitespace-nowrap" *ngIf="source.awsData">
                <fa-icon
                    [icon]="source.awsData.ConnectionState | statusIcon"
                    class="status-{{ source.awsData.ConnectionState | statusClass }}"
                    class="me-1"
                ></fa-icon
                >{{ source.awsData.HdDeviceSettings.DeviceState }}
            </div>
        </div>
    </div>
    <div class="aspect-ratio-fix" [ngStyle]="{ 'background-color': initialized ? 'black' : 'transparent' }">
        <div class="img-container">
            <spinner-animation *ngIf="loading"></spinner-animation>
            <img src="../../../../../assets/images/thumb-stream.png" alt="{{ source.name }}-thumbnail" *ngIf="loading" />
            <img #thumbnail [src]="imgSource" alt="{{ source.name }}-thumbnail" (load)="onImgLoad()" *ngIf="!loading" [ngClass]="{ superwide: superWide }" />
        </div>
    </div>
    <div class="overlay-text" [hidden]="!overlay">
        <div class="alert-container">
            <div class="alert-container-inner">
                <div *ngFor="let activeState of source.activeStates" class="alert" [ngClass]="activeState.type === 'error' ? 'alert-danger' : 'alert-warning'">
                    <strong>{{ activeState.short_message }}</strong>
                </div>
                <div class="alert alert-light mb-0" role="alert" *ngIf="source.is_enabled && source.state === 'pending'">
                    <strong>{{ "OFFLINE" | translate }}</strong>
                </div>
                <div class="alert alert-light mb-0" role="alert" *ngIf="!source.is_enabled">
                    <strong>{{ "DISABLED" | translate }}</strong>
                </div>
            </div>
        </div>
    </div>
</div>
