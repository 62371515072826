import { Injectable } from "@angular/core";
import { grafanaDashboardEncoder as DashboardEncoder } from "@zixi/shared-utils";
import { AnyTarget } from "../pages/channels/channel";
import { Feeder } from "../pages/zecs/zecs/zec";
import { NavigationService } from "../components/navigation/navigation.service";
import { Receiver } from "../pages/zecs/zecs/zec";
import { TargetsService } from "../pages/targets/targets.service";
import { Zec } from "../pages/zecs/zecs/zec";
import { Broadcaster, MediaConnectSource, Source } from "../models/shared";

export type DashboardPanelConfig = DashboardEncoder.DashboardPanelConfig;
export type DashboardConfigOptions = (DashboardEncoder.DashboardPanelConfig & {
    name: string;
    visible: boolean;
    pinned?: boolean | false;
    objectName?: string;
})[];

@Injectable({
    providedIn: "root"
})
export class GraphsService {
    constructor(private ts: TargetsService, private navigationService: NavigationService) {}

    feeder(feeder: Feeder): DashboardConfigOptions {
        const panelsConfig: DashboardConfigOptions = [
            {
                graph: "feeder-system",
                objectId: feeder.id,
                objectName: feeder.name,
                objectType: "feeder",
                collapsed: false,
                name: "System",
                visible: true,
                pinned: false
            }
        ];
        return panelsConfig;
    }

    broadcaster(broadcaster: Broadcaster, minimal = false) {
        const panelsConfig: DashboardConfigOptions = [
            {
                graph: "broadcaster-system",
                objectId: broadcaster.id,
                objectName: broadcaster.name,
                objectType: "broadcaster",
                collapsed: false,
                name: "System",
                visible: true,
                pinned: false
            },
            {
                graph: "broadcaster-network",
                objectId: broadcaster.id,
                objectName: broadcaster.name,
                objectType: "broadcaster",
                collapsed: false,
                name: "Network",
                visible: true,
                pinned: false
            }
        ];

        if (
            broadcaster.recentLoad &&
            (broadcaster.recentLoad.nvidia_utilization === 0 || broadcaster.recentLoad.nvidia_utilization > 0)
        )
            panelsConfig.push({
                graph: "broadcaster-gpu",
                objectId: broadcaster.id,
                objectName: broadcaster.name,
                objectType: "broadcaster",
                collapsed: false,
                name: "GPU Load",
                visible: !minimal,
                pinned: false
            });

        panelsConfig.push({
            graph: "broadcaster-udp-stats",
            objectId: broadcaster.id,
            objectName: broadcaster.name,
            objectType: "broadcaster",
            collapsed: true,
            name: "UDP Statistics",
            visible: !minimal,
            pinned: false
        });

        return panelsConfig;
    }

    receiver(receiver: Receiver) {
        const panelsConfig: DashboardConfigOptions = [
            {
                graph: "receiver-system",
                objectId: receiver.id,
                objectName: receiver.name,
                objectType: "receiver",
                collapsed: false,
                name: "System",
                visible: true,
                pinned: false
            }
        ];
        return panelsConfig;
    }

    zec(zec: Zec) {
        const panelsConfig: DashboardConfigOptions = [
            {
                graph: "zec-system",
                objectId: zec.id,
                objectName: zec.name,
                objectType: "zec",
                collapsed: false,
                name: "System",
                visible: true,
                pinned: false
            }
        ];
        return panelsConfig;
    }

    target(target: AnyTarget, withHealth = false, minimal = false) {
        const panelsConfig: DashboardConfigOptions = [];
        // 9 Types
        switch (this.ts.getTargetApiType(target)) {
            case "http":
                panelsConfig.push({
                    graph: "target-http-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "publishing_target",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                break;
            case "rtmp":
                panelsConfig.push({
                    graph: "target-rtmp-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "rtmp_push",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                break;
            case "push":
                if (withHealth)
                    panelsConfig.push({
                        graph: "target-zixi-push-health",
                        objectId: target.target.id,
                        objectName: target.target.name,
                        objectType: "zixi_push",
                        collapsed: false,
                        name: "Network",
                        visible: !minimal,
                        pinned: false
                    });
                panelsConfig.push({
                    graph: "target-zixi-push-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "zixi_push",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                panelsConfig.push({
                    graph: "target-zixi-push-bitrate",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "zixi_push",
                    collapsed: false,
                    name: "Bitrate",
                    visible: !minimal,
                    pinned: false
                });
                break;
            case "pull":
                if (withHealth)
                    panelsConfig.push({
                        graph: "target-zixi-pull-health",
                        objectId: target.target.id,
                        objectName: target.target.name,
                        objectType: "zixi_pull",
                        collapsed: false,
                        name: "Network",
                        visible: !minimal,
                        pinned: false
                    });
                panelsConfig.push({
                    graph: "target-zixi-pull-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "zixi_pull",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                panelsConfig.push({
                    graph: "target-zixi-pull-bitrate",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "zixi_pull",
                    collapsed: false,
                    name: "Bitrate",
                    visible: !minimal,
                    pinned: false
                });
                break;
            case "udp_rtp":
                panelsConfig.push({
                    graph: "target-udp-rtp-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "udp_rtp",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                break;
            case "rist":
                panelsConfig.push({
                    graph: "target-rist-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "rist",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                break;
            case "srt":
                panelsConfig.push({
                    graph: "target-srt-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "srt_targets",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                break;
            case "ndi":
                panelsConfig.push({
                    graph: "target-ndi-network",
                    objectId: target.target.id,
                    objectName: target.target.name,
                    objectType: "ndi_targets",
                    collapsed: false,
                    name: "Network",
                    visible: true,
                    pinned: false
                });
                break;
            default:
                // eslint-disable-next-line no-console
                console.log("invalid target type");
        }

        return panelsConfig;
    }

    source(source: Source, withHealth = false, minimal = false) {
        const panelsConfig: DashboardConfigOptions = [];

        if (source.content_analysis)
            panelsConfig.push({
                graph: "source-cqa",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Content Analysis",
                visible: true,
                pinned: false
            });
        else
            panelsConfig.push({
                graph: "source-tr101",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "TR101",
                visible: true,
                pinned: false
            });

        if (source.process_scte_reports)
            panelsConfig.push({
                graph: "source-scte35",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "SCTE-35",
                visible: !minimal,
                pinned: false
            });

        const hasNetwork =
            source.protocol !== "ndi" &&
            source.type !== "file" &&
            source.type !== "rtmp" &&
            source.protocol !== "hls_pull";

        if (source.failoverSources?.length > 0)
            panelsConfig.push({
                graph: "source-failover",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Failover",
                visible: !minimal,
                pinned: false
            });
        else if (source.transcode_profile_id)
            panelsConfig.push({
                graph: "source-transcoding",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Transcoding",
                visible: !minimal,
                pinned: false
            });
        else if (source.status?.links?.length > 0)
            panelsConfig.push({
                graph: "source-bonding",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Bonding",
                visible: !minimal,
                pinned: false
            });
        else if (hasNetwork)
            panelsConfig.push({
                graph: "source-network",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Network",
                visible: true,
                pinned: false
            });

        panelsConfig.push({
            graph: "source-network-bitrate",
            objectId: source.id,
            objectName: source.name,
            objectType: "source",
            collapsed: false,
            name: "Network Bitrate",
            visible: !minimal,
            pinned: false
        });
        panelsConfig.push({
            graph: "source-es-bitrates",
            objectId: source.id,
            objectName: source.name,
            objectType: "source",
            collapsed: false,
            name: "Elementary Stream Bitrates",
            visible: !minimal,
            pinned: false
        });

        if (source.content_analysis) {
            panelsConfig.push({
                graph: "source-epsnr",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: true,
                name: "Encoding Quality ePSNR",
                visible: !minimal,
                pinned: false
            });
            if (source.status?.vmaf_histogram)
                panelsConfig.push({
                    graph: "source-evmaf",
                    objectId: source.id,
                    objectName: source.name,
                    objectType: "source",
                    collapsed: true,
                    name: "Encoding Quality eVMAF",
                    visible: !minimal,
                    pinned: false
                });
        }

        if (hasNetwork && source.status?.packets_timing_histogram)
            panelsConfig.push({
                graph: "source-packet-timing",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: true,
                name: "Packet Timing",
                visible: !minimal,
                pinned: false
            });

        if (withHealth && source.zixi)
            panelsConfig.push({
                graph: "source-health",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: true,
                name: "Health",
                visible: !minimal,
                pinned: false
            });

        return panelsConfig;
    }

    sourceSCTE(source: Source) {
        const panelsConfig: DashboardConfigOptions = [];

        if (source.process_scte_reports)
            panelsConfig.push({
                graph: "source-scte35",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "SCTE-35",
                visible: true,
                pinned: false
            });

        return panelsConfig;
    }

    mediaconnectSource(source: MediaConnectSource) {
        const panelsConfig: DashboardConfigOptions = [];

        if (source.protocol === "cdi") {
            panelsConfig.push({
                graph: "mediaconnect-cdi",
                objectId: source.id,
                objectName: source.name,
                objectType: "mediaconnect_sources",
                collapsed: false,
                name: "CDI",
                visible: true,
                pinned: false
            });
        } else if (source.protocol === "st2110-jpegxs") {
            panelsConfig.push({
                graph: "mediaconnect-jpegxs",
                objectId: source.id,
                objectName: source.name,
                objectType: "mediaconnect_sources",
                collapsed: false,
                name: "JPEG-XS",
                visible: true,
                pinned: false
            });
        } else {
            panelsConfig.push({
                graph: "mediaconnect-tr101",
                objectId: source.id,
                objectName: source.name,
                objectType: "mediaconnect_sources",
                collapsed: false,
                name: "TR101",
                visible: true,
                pinned: false
            });
            panelsConfig.push({
                graph: "mediaconnect-network",
                objectId: source.id,
                objectName: source.name,
                objectType: "mediaconnect_sources",
                collapsed: false,
                name: "Network",
                visible: true,
                pinned: false
            });
        }
        return panelsConfig;
    }

    sourceHealth(source: Source) {
        const panelsConfig: DashboardConfigOptions = [
            {
                graph: "source-health",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Health",
                visible: true,
                pinned: false
            },
            {
                graph: "source-health-network",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Network",
                visible: true,
                pinned: false
            },
            {
                graph: "source-health-connection",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: false,
                name: "Connection",
                visible: true,
                pinned: false
            }
        ];

        if (source.status?.packets_timing_histogram)
            panelsConfig.push({
                graph: "source-packet-timing",
                objectId: source.id,
                objectName: source.name,
                objectType: "source",
                collapsed: true,
                name: "Packet Timing",
                visible: true,
                pinned: false
            });

        return panelsConfig;
    }

    custom(panels: DashboardEncoder.DashboardConfig) {
        let darkMode: boolean;
        this.navigationService.isDarkMode.pipe().subscribe(b => {
            darkMode = b;
        });
        if (darkMode) return this.encodeDashboard(panels);
        else return this.encodeDashboard(panels);
    }

    private encodeDashboard(panels: DashboardEncoder.DashboardConfig) {
        return DashboardEncoder.encodeDashboard(panels);
    }
}
