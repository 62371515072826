import { Injectable } from "@angular/core";
import "regenerator-runtime/runtime";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { Constants } from "./../constants/constants";

@Injectable({
    providedIn: "root"
})
export class ExportExcelService {
    constants = Constants;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportClustersExcel(data: any[], headers: string[], title: string, filter: string) {
        // Create a workbook with a worksheet
        const date = new Date();
        const workbook = new Workbook();
        const worksheet = this.createDefaultWorkSheet(workbook, data.length, title, date, headers, filter);

        // Adding Data
        data.forEach(d => {
            const dataRow = worksheet.addRow(d);

            // Set broadcaster rows to outlineLevel 1
            // broadcaster rows have more cells than cluster rows
            if (dataRow.cellCount > 8) dataRow.outlineLevel = 1;

            dataRow.eachCell(cell => {
                cell.font = {
                    size: 11
                };
            });
        });

        // Set column widths
        const nameCol = worksheet.getColumn(1);
        nameCol.width = 35;

        const messageCol = worksheet.getColumn(4);
        messageCol.width = 40;

        const tagsCol = worksheet.getColumn(5);
        tagsCol.width = 40;

        // Auto Filter
        worksheet.autoFilter = {
            from: {
                row: 8,
                column: 1
            },
            to: {
                row: 8 + data.length,
                column: headers.length
            }
        };

        // Outline Properties
        // Typescript definition missing outlineProperties for some reason, known bug
        /* worksheet.properties.outlineProperties = {
            summaryBelow: true,
        }; */
        worksheet.properties["outlineProperties"] = {
            summaryBelow: false
        };

        // Unprotect worksheet
        worksheet.unprotect();

        // Generate & Save Excel File
        this.generateAndSaveFile(workbook, title + "-" + date.toISOString());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportExcel(data: any[], headers: string[], title: string, filter: string) {
        // Create a workbook with a worksheet
        const date = new Date();
        const workbook = new Workbook();
        const worksheet = this.createDefaultWorkSheet(workbook, data.length, title, date, headers, filter);

        // Adding Data
        data.forEach(d => {
            const dataRow = worksheet.addRow(d);
            dataRow.eachCell(cell => {
                cell.font = {
                    size: 11
                };
            });
        });

        // Set column widths
        const nameCol = worksheet.getColumn(1);
        nameCol.width = 35;

        const messageCol = worksheet.getColumn(4);
        messageCol.width = 40;

        const tagsCol = worksheet.getColumn(5);
        tagsCol.width = 40;

        // Auto Filter
        worksheet.autoFilter = {
            from: {
                row: 8,
                column: 1
            },
            to: {
                row: 8 + data.length,
                column: headers.length
            }
        };

        // Unprotect worksheet
        worksheet.unprotect();

        // Generate & Save Excel File
        this.generateAndSaveFile(workbook, title + "-" + date.toISOString());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportGridExcel(data: any[], headers: string[], title: string, gridTitle: string, filter: string) {
        // Create a workbook with a worksheet
        const date = new Date();
        const workbook = new Workbook();
        const worksheet = this.createDefaultWorkSheet(workbook, data.length, gridTitle, date, headers, filter);

        // Adding Data
        data.forEach(d => {
            const dataRow = worksheet.addRow(d);
            dataRow.eachCell(cell => {
                cell.font = {
                    size: 11
                };
            });
        });

        // Set column widths
        const nameCol = worksheet.getColumn(1);
        nameCol.width = 35;

        const messageCol = worksheet.getColumn(6);
        messageCol.width = 40;

        const tagsCol = worksheet.getColumn(7);
        tagsCol.width = 40;

        // Auto Filter
        worksheet.autoFilter = {
            from: {
                row: 8,
                column: 1
            },
            to: {
                row: 8 + data.length,
                column: headers.length
            }
        };

        // Unprotect worksheet
        worksheet.unprotect();

        // Generate & Save Excel File
        this.generateAndSaveFile(workbook, title + "-" + gridTitle + "-" + date.toISOString());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportMapExcel(data: any[], headers: string[], title: string, mapTitle: string) {
        // Create a workbook with a worksheet
        const date = new Date();
        const workbook = new Workbook();
        const worksheet = this.createDefaultWorkSheet(workbook, data.length, mapTitle, date, headers);

        // Adding Data
        data.forEach(d => {
            const dataRow = worksheet.addRow(d);
            dataRow.eachCell(cell => {
                cell.font = {
                    size: 11
                };
            });
        });

        // Set column widths
        const nameCol = worksheet.getColumn(1);
        nameCol.width = 35;

        const messageCol = worksheet.getColumn(6);
        messageCol.width = 40;

        const tagsCol = worksheet.getColumn(7);
        tagsCol.width = 40;

        // Auto Filter
        worksheet.autoFilter = {
            from: {
                row: 7,
                column: 1
            },
            to: {
                row: 7 + data.length,
                column: headers.length
            }
        };

        // Unprotect worksheet
        worksheet.unprotect();

        // Generate & Save Excel File
        this.generateAndSaveFile(workbook, title + "-" + mapTitle + "-" + date.toISOString());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportTranscodingProfileExcel(data: any[], headers: string[], title: string, filter: string) {
        // Create a workbook with a worksheet
        const date = new Date();
        const workbook = new Workbook();
        const worksheet = this.createDefaultWorkSheet(workbook, data.length, title, date, headers, filter);

        // Adding Data
        data.forEach(d => {
            const dataRow = worksheet.addRow(d);
            dataRow.eachCell(cell => {
                cell.font = {
                    size: 11
                };
            });
        });

        // Auto Filter
        worksheet.autoFilter = {
            from: {
                row: 8,
                column: 1
            },
            to: {
                row: 8 + data.length,
                column: headers.length
            }
        };

        // Unprotect worksheet
        worksheet.unprotect();

        // Generate & Save Excel File
        this.generateAndSaveFile(workbook, title + "-" + date.toISOString());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private createDefaultWorkSheet(
        workbook: any,
        total: number,
        title: string,
        date: Date,
        headers: string[],
        filter?: string
    ) {
        // Create a workbook with a worksheet
        const worksheet = workbook.addWorksheet(title);
        worksheet.properties.defaultColWidth = 25;

        // Add Image
        const zenMasterLogo = workbook.addImage({
            base64: this.constants.zmLogo,
            extension: "png"
        });
        worksheet.mergeCells("A1:B2");
        worksheet.addImage(zenMasterLogo, {
            tl: { col: 0, row: 0 },
            ext: { width: 219, height: 50 },
            editAs: "absolute"
        });

        // Blank Row
        worksheet.addRow([]);

        // Title Row
        const titleRow = worksheet.addRow([title]);
        titleRow.font = {
            size: 14,
            bold: true
        };

        // Time Row
        const timeRow = worksheet.addRow(["Time: " + date]);
        timeRow.font = {
            size: 12,
            bold: true
        };

        if (typeof filter === "string") {
            // Filter Row
            const filterRow = worksheet.addRow(["Filter: " + filter]);
            filterRow.font = {
                size: 12,
                bold: true
            };
        }

        // Total Row
        const totalRow = worksheet.addRow(["Total: " + total]);
        totalRow.font = {
            size: 12,
            bold: true
        };

        // Headers Row
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell(cell => {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "4052a3" }, // Zixi Blue
                bgColor: { argb: "FFFFFF" }
            };
            cell.font = {
                bold: true,
                color: { argb: "FFFFFF" },
                size: 11
            };
        });
        return worksheet;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private generateAndSaveFile(workbook: any, fileTitle: string) {
        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            fs.saveAs(blob, fileTitle + ".xlsx");
        });
    }
}
