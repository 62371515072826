<div [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">{{ "SWITCH_TARGET_CHANNEL" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
        <div class="modal-body">
            <!-- Targets -->
            <div class="form-group">
                <label for="targets">
                    {{ "TARGETS" | translate }}
                </label>
                <ul class="list-style-none mimic-tags">
                    <li *ngFor="let target of targets">
                        <zx-target *ngIf="target" [model]="target" [showLink]="true"></zx-target>
                        <span *ngIf="target._frontData.saving && target._frontData.processing === 'start'">
                            &nbsp;-&nbsp;<span class="spinner-border spinner-border-sm ms-1 me-1" role="status" aria-hidden="true"></span
                            >{{ "PROCESSING" | translate }}...
                        </span>
                        <span *ngIf="!target._frontData.saving && !target._frontData.hasError && target._frontData.processing === 'end'"
                            >&nbsp;-&nbsp;<span>{{ "UPDATED" | translate | lowercase }}</span>
                        </span>
                        <span
                            class="status-error"
                            title="{{ 'ERROR' | translate }}:&nbsp;{{ target._frontData.error.message }}"
                            *ngIf="!target._frontData.saving && target._frontData.hasError && target._frontData.processing === 'end'"
                            >&nbsp;-&nbsp;{{ "ERROR" | translate }}:&nbsp;{{ target._frontData.error.message }}</span
                        >
                    </li>
                </ul>
            </div>
            <!-- Channel Type -->
            <fieldset class="form-group" *ngIf="(canMediaconnect || canMedialive) && state !== 'done'">
                <legend for="type">{{ "CHANNEL_TYPE" | translate }}</legend>
                <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="type" (change)="selectedChannelID = null">
                    <mat-button-toggle [value]="'delivery'">{{ "ZM_CHANNEL" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="'mediaconnect'" *ngIf="canMediaconnect">{{ "AWS_MEDIACONNECT_FLOW" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="'medialive'" *ngIf="canMedialive">{{ "MEDIALIVE" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>
            <!-- Channel Select -->
            <div class="form-group mb-0" *ngIf="state !== 'done'">
                <label for="channel">
                    {{ "CHANNEL_OPTIONAL" | translate }}
                </label>
                <zx-adaptive-channel-select
                    id="adaptive_channel_select"
                    name="adaptive_channel_select"
                    [(model)]="adaptiveChannelID"
                    [clearable]="true"
                    *ngIf="type === 'adaptive'"
                ></zx-adaptive-channel-select>
                <zx-delivery-channel-select
                    id="delivery_channel_select"
                    name="delivery_channel_select"
                    [(model)]="deliveryChannelID"
                    [clearable]="true"
                    [failover]="true"
                    *ngIf="type === 'delivery'"
                ></zx-delivery-channel-select>
                <zx-mediaconnect-flow-select
                    id="mediaconnect_flow_select"
                    name="mediaconnect_flow_select"
                    [(model)]="mediaconnectFlowID"
                    [clearable]="true"
                    *ngIf="type === 'mediaconnect'"
                ></zx-mediaconnect-flow-select>
                <zx-medialive-channel-select
                    id="medialive_channel_select"
                    name="medialive_channel_select"
                    [(model)]="medialiveChannelID"
                    [clearable]="true"
                    *ngIf="type === 'medialive'"
                ></zx-medialive-channel-select>
                <!-- Source preference -->
                <div class="form-group mt-3" *ngIf="type === 'delivery'">
                    <label for="source_preference">
                        {{ "SOURCE_PREFERENCE" | translate }}
                    </label>
                    <zx-delivery-channel-source-select
                        id="preferred_source"
                        name="preferred_source"
                        [(channel_id)]="selectedChannelID"
                        [(model)]="preferredSourceID"
                        [disabled]="!deliveryChannelID"
                        [allowKeepSelecton]="targets.length > 1"
                        [allowPreferenceSelection]="havePullTargets"
                    >
                    </zx-delivery-channel-source-select>
                </div>
                <!-- Transcoding Profile -->
                <div class="form-group mt-2" *ngIf="type === 'medialive'">
                    <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                        >{{ "TRANSCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ><fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                    ></label>
                    <zx-transcoding-profile-select
                        name="transcodingProfile"
                        [(model)]="transcodeProfile"
                        required="medialiveChannelID"
                        [clearable]="true"
                        [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                    ></zx-transcoding-profile-select>
                    <div *ngIf="form.controls.transcodingProfile?.invalid" class="invalid-feedback">
                        <div *ngIf="form.controls.transcodingProfile?.errors.required">{{ "PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                    </div>
                </div>
            </div>
            <div *ngIf="canReuseBX && type === 'delivery' && state !== 'done' && haveAssignedTargets" class="form-check">
                <input type="checkbox" class="form-check-input" id="content_analysis" name="content_analysis" [(ngModel)]="forceSameBX" />
                <label class="form-check-label" for="content_analysis">{{ "FORCE_SAME_BX" | translate }} </label>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()" *ngIf="state !== 'done'">
                <fa-icon icon="times"></fa-icon>
                {{ "CANCEL" | translate }}
            </button>
            <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="saving" zmid="form-submit" type="submit" *ngIf="state !== 'done'">
                <fa-icon icon="random" size="sm"></fa-icon>
                {{ "SWITCH" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
            </button>
            <button type="button" class="btn btn-secondary" [disabled]="saving" (click)="activeModal.close()" *ngIf="state === 'done'">
                {{ "CLOSE" | translate }}
            </button>
        </div>
    </form>
</div>
