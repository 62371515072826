<div [ngClass]="{ 'is-invalid': inValid }">
    <h5 class="mb-0" translate [title]="title">
        <span *ngIf="isModified"><strong>*</strong></span>
        {{ title }}
        <span *ngIf="isNotSaved">
            <em>({{ "UNSAVED" | translate }})</em>
        </span>
    </h5>
    <ng-container *ngIf="isChevrons">
        <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
        <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
    </ng-container>
</div>
