import { Pipe, PipeTransform } from "@angular/core";
import { Constants } from "../constants/constants";

@Pipe({
    name: "chromaMapper"
})
export class ChromaMapperPipe implements PipeTransform {
    transform(val: number): string {
        return Constants.chromaSubsampling.find(i => i.value === (val || 0)).name;
    }
}
