<!-- Mobile -->
<span class="d-md-none" [ngClass]="{ 'd-block-important': mobile }">
    <button [disabled]="working" (click)="toggleMute()" class="text-secondary" mat-menu-item>
        <fa-icon icon="bell-slash" size="sm" class="me-1" [fixedWidth]="true" *ngIf="!model.active_mute"></fa-icon
        ><fa-icon icon="bell" size="sm" class="me-1" [fixedWidth]="true" *ngIf="model.active_mute"></fa-icon
        >{{ (!model.active_mute ? "MUTE" : "UNMUTE") | translate }}
        <span *ngIf="working" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
    </button>
    <button [disabled]="working" (click)="muteUntilMobile()" class="text-secondary" title="{{ 'MUTE_UNTIL_A_SPECIFIC' | translate }}" mat-menu-item>
        <fa-icon icon="clock" size="sm" class="me-1" [fixedWidth]="true"></fa-icon>{{ "MUTE_UNTIL" | translate }}...
    </button>
</span>

<!-- Not Mobile -->
<div class="btn-group d-none d-md-block" id="muteDropdown" [ngClass]="{ 'd-none-important': mobile }">
    <button [disabled]="working" type="button" class="btn btn-outline-secondary" (click)="toggleMute()">
        <fa-icon icon="bell-slash" size="sm" [fixedWidth]="true" *ngIf="!model.active_mute"></fa-icon
        ><fa-icon icon="bell" size="sm" [fixedWidth]="true" *ngIf="model.active_mute"></fa-icon
        ><span class="d-none d-xxl-inline-block ms-1">{{ (!model.active_mute ? "MUTE" : "UNMUTE") | translate }}</span
        ><span *ngIf="working" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
    </button>
    <div class="btn-group" role="group" ngbDropdown>
        <button [disabled]="working" class="dropdown-toggle btn btn-outline-secondary dropdown-toggle-split" ngbDropdownToggle></button>
        <div ngbDropdownMenu aria-labelledby="muteDropdown" class="shadow-sm">
            <button (click)="toggleMute()" class="text-secondary" ngbDropdownItem>
                <fa-icon icon="bell-slash" size="sm" [fixedWidth]="true" *ngIf="!model.active_mute" class="me-1"></fa-icon
                ><fa-icon icon="bell" size="sm" [fixedWidth]="true" *ngIf="model.active_mute" class="me-1"></fa-icon
                >{{ (!model.active_mute ? "MUTE" : "UNMUTE") | translate }}
            </button>
            <button (click)="muteUntil()" class="text-secondary" title="{{ 'MUTE_UNTIL_A_SPECIFIC' | translate }}" ngbDropdownItem>
                <fa-icon icon="clock" size="sm" [fixedWidth]="true" class="me-1"></fa-icon>{{ "MUTE_UNTIL" | translate }}...
            </button>
        </div>
    </div>
</div>

<div class="picker-container fixed" *ngIf="showPicker">
    <div class="arrow"></div>
    <div class="picker-content shadow-sm text-end muteUntil">
        <fa-icon icon="times-circle" class="close-icon" (click)="close()"></fa-icon>
        <zx-date-time-picker name="muteDate" [(ngModel)]="muteUntilString" (ngModelChange)="dateChanged($event)" [onlyFutureDates]="true"></zx-date-time-picker>
        <button type="button" class="btn btn-outline-secondary btn-sm mt-2 mb-2" (click)="close()">
            <fa-icon icon="times" size="xs"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="btn btn-primary btn-sm mt-2 ms-2 me-2 mb-2" [disabled]="!muteUntilVal" (click)="save(muteUntilVal)">
            <fa-icon icon="bell-slash" size="xs"></fa-icon>
            {{ "MUTE" | translate }}
        </button>
    </div>
</div>
