import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, Validators } from "@angular/forms";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[max]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MaximumValueDirective, multi: true }]
})
export class MaximumValueDirective implements Validator {
    @Input() max: number;
    validate(control: AbstractControl): { [key: string]: unknown } {
        return Validators.max(this.max)(control);
    }
}
