<div class="form-group flex-grow-1 flex-shrink-1 mb-0" [ngClass]="{ 'me-2': isMr2 }">
    <mat-form-field appearance="outline" [ngClass]="{ hasValue: userFilter.length }" [ngbTooltip]="userFilterContent" triggers="hover click" [closeDelay]="500">
        <mat-label *ngIf="rowsAmount"
            >{{ "FILTER" | translate }} <span *ngIf="userFilter.length">({{ filteredRowsAmount }} of {{ rowsAmount }})</span></mat-label
        >
        <input
            (keyup)="userFilterChange.emit($event.target.value)"
            matInput
            placeholder="{{ 'FILTER' | translate }}"
            [ngModel]="filterPlaceholder ?? userFilter"
        />
        <button *ngIf="filterPlaceholder || userFilter" matSuffix mat-icon-button aria-label="Clear" (click)="userFilterChange.emit('')">
            <fa-icon icon="times" size="sm"></fa-icon>
        </button>
    </mat-form-field>
    <ng-template #userFilterContent>{{ "BOOLEAN_SEARCH_SUPPORTED" | translate }}</ng-template>
</div>
