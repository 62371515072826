import { Component, Input } from "@angular/core";
import { AnyTarget } from "src/app/pages/channels/channel";

@Component({
    selector: "app-zx-target-targets-column",
    templateUrl: "./zx-target-targets-column.component.html"
})
export class ZxTargetTargetsColumnComponent {
    @Input() searchTermArray: string[];
    @Input() target: AnyTarget;
}
