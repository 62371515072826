import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-password-input",
    templateUrl: "./password-input.component.html"
})
export class PasswordInputComponent implements OnInit {
    @Input() passwordInput: string;
    @Input() passwordName = "API_PASSWORD";
    @Input() isFormSubmitted: boolean;
    @Input() isEdit: boolean;
    @Input() generate = true;
    @Input() required = true;

    @Output() passwordInputChange = new EventEmitter<string>();

    showPassword = false;

    constructor(private cbs: ClipboardService, private sharedService: SharedService) {}

    get password() {
        return this.passwordInput;
    }

    set password(pass: string) {
        this.passwordInputChange.emit(pass);
    }

    ngOnInit(): void {
        this.password = this.passwordInput;
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    copyPassword() {
        this.cbs.copy(this.passwordInput);
    }

    generatePassword() {
        this.password = this.sharedService.generateStrongPassword();
    }
}
