import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
    selector: "app-zx-form-bottom-buttons",
    templateUrl: "./zx-form-bottom-buttons.component.html"
})
export class ZxFormBottomButtonsComponent {
    @Input() cancelUrlNavigate: string;
    @Input() isSaving?: boolean;
    @Input() isEdit: boolean;
    @Input() isFormValid: boolean;
    @Input() isHorizontalRowNeeded = true;
    @Input() submitted: boolean;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() submit = new EventEmitter();
    @Output() submittedChange = new EventEmitter<boolean>();

    private location = inject(Location);
    private router = inject(Router);

    back() {
        this.location.back();
    }

    cancel() {
        if (this.isEdit) this.location.back();
        else this.router.navigate([this.cancelUrlNavigate]);
    }

    onSubmit() {
        this.submittedChange.emit(true);
        if (this.isFormValid) this.submit.emit();
    }
}
