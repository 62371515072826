import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { take } from "rxjs/operators";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";

@Component({
    selector: "zx-agentz-os-data",
    templateUrl: "./zx-agentz-os-data.component.html",
    styleUrls: ["./zx-agentz-os-data.component.scss"]
})
export class ZxAgentzOsDataComponent implements OnInit, OnChanges {
    @Input() broadcasterId: number;
    isZixiUser: boolean;
    agentZOSData: JSON = JSON.parse("{}");

    loading = true;

    constructor(private broadcastersService: BroadcastersService, private userService: UsersService) {
        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isZixiUser = !!(user.is_zixi_admin || user.is_zixi_support_write || user.is_zixi_support);
            });
    }

    async ngOnInit(): Promise<void> {
        if (this.isZixiUser) {
            this.agentZOSData = await this.broadcastersService.getBroadcasterAgentZOS(this.broadcasterId);
            this.loading = false;
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (
            changes.broadcasterId &&
            !changes.broadcasterId?.firstChange &&
            changes.broadcasterId?.currentValue !== changes.broadcasterId?.previousValue
        ) {
            if (this.isZixiUser) {
                this.loading = true;
                this.agentZOSData = await this.broadcastersService.getBroadcasterAgentZOS(this.broadcasterId);
                this.loading = false;
            }
        }
    }
}
