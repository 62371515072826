import { Component } from "@angular/core";
import { Subject } from "rxjs";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-confirm-navigation-dialog",
    templateUrl: "./confirm-navigation-dialog.component.html"
})
export class ConfirmNavigationDialogComponent {
    public subject: Subject<boolean>;
    constructor(public activeModal: NgbActiveModal) {}

    action(value: boolean) {
        this.subject.next(value);
        this.subject.complete();
        this.activeModal.close(value);
    }
}
