<div class="details-form">
    <div class="form-group row">
        <h5 class="col-12" translate>NAME</h5>
        <div class="col-12">
            <span>{{ zec.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="isVip()">
        <h5 class="col-12" translate>PRIORITY</h5>
        <div class="col-12">
            <fa-icon icon="check" size="lg"></fa-icon>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>TYPE</h5>
        <div class="col-12">
            <span translate>{{ zecType | translate }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.resourceTags && zec.resourceTags.length">
        <h5 class="col-12" translate>TAGS</h5>
        <div class="col-12">
            <span *ngFor="let tag of zec.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.alertingProfile">
        <h5 class="col-12" translate>ALERTING_PROFILE</h5>
        <div class="col-12">
            <span>{{ zec.alertingProfile.name }}</span>
        </div>
    </div>
    <app-details-location [location]="zec.location"></app-details-location>
    <div class="form-group row">
        <h5 class="col-12" translate>API_USER</h5>
        <div class="col-12">
            <span>{{ zec.api_user }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>API_CREDENTIALS</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a>
            <span *ngIf="pwVisible">
                {{ zec.api_user }} / {{ zec.api_password }}
                <app-password-strength-icon [password]="zec.api_password"></app-password-strength-icon>
            </span>
            <br />
            <a href="javascript:void(0)" (click)="copyPassword(zec.api_password)">
                {{ "COPY_PASSWORD" | translate }}
            </a>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.accessKey">
        <h5 class="col-12" translate>SSH_KEY</h5>
        <div class="col-12">
            <span *ngIf="zec.accessKey.readOnly">{{ zec.accessKey.name }}</span>
            <a *ngIf="!zec.accessKey.readOnly && zec.accessKey.hasRsaPrivate" href="/api/access_keys/{{ zec.accessKey.id }}/download">
                <fa-icon icon="download" size="sm"></fa-icon>
                {{ zec.accessKey.name }}
            </a>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>TERMINATION_PROTECTION</h5>
        <div class="col-12">
            <span>{{ zec.termination_protection ? "ON" : "OFF" }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.status && zec.status.about && zec.status.about.platform">
        <h5 class="col-12" translate>PLATFORM</h5>
        <div class="col-12">
            <span>{{ zec.status.about.platform }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.status && zec.status.source_ip">
        <h5 class="col-12" translate>SOURCE_IP</h5>
        <div class="col-12">
            <span>{{ zec.status.source_ip }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.status && zec.status.up_time_seconds">
        <h5 class="col-12">{{ "UP_TIME" | translate }} [{{ "HH:mm:ss" | translate }}]</h5>
        <div class="col-12">
            <span>{{ zec.status.up_time_seconds | uptime }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.status && zec.status.hostid">
        <h5 class="col-12" translate>HOST_ID</h5>
        <div class="col-12">
            <span>{{ zec.status.hostid }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.status && zec.status.activation_key_link">
        <h5 class="col-12" translate>ACTIVATION_KEY</h5>
        <div class="col-12">
            <span
                ><a [href]="zec.status?.activation_key_link" target="_blank">License Details <fa-icon icon="external-link-alt" size="sm"></fa-icon></a
            ></span>
        </div>
    </div>
    <div class="form-group row" *ngIf="zec.status && zec.status.about && zec.status.about.version">
        <h5 class="col-12" translate>VERSION</h5>
        <div class="col-12">
            <span>{{ zec.status.about.version }}</span>
        </div>
    </div>
    <div class="form-group mb-0 row" [ngClass]="{ 'd-none': zec.state === 'pending' || !canEdit }">
        <h5 class="col-12" translate>OPEN</h5>
        <div class="col-12">
            <a href="{{ zec.configure_link }}" target="_blank">
                {{ "LINK" | translate }}
                <fa-icon icon="external-link-alt" size="sm"></fa-icon>
            </a>
        </div>
    </div>
</div>
