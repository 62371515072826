import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
    MediaConnectFlow,
    MediaConnectMediaStream,
    MediaconnectMediaStreamEncodingProfile,
    MediaconnectMediaStreamAudioChannelOrder,
    MediaconnectMediaStreamType,
    MediaconenctMediaStreamVideoFormat,
    MediaconnectMediaStreamVideoColorimetry,
    MediaconnectMediaStreamVideoScanMode,
    MediaconnectMediaStreamVideoTCS,
    MediaconnectMediaStreamVideoRange,
    MediaConnectSource
} from "../../../models/shared";
import { Constants } from "../../../constants/constants";
import { ControlContainer, NgForm, NgControl } from "@angular/forms";
import { MediaconnectCDITarget, MediaconnectJPEGXSTarget } from "../../../pages/channels/channel";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

export type FullMediaconnectMediaStream = MediaConnectMediaStream & {
    inUse?: boolean;
    port?: number;
    encoding_profile?: MediaconnectMediaStreamEncodingProfile;
    exncoding_compression?: number;
};

@Component({
    selector: "zx-mediaconnect-mediastream",
    templateUrl: "./zx-mediaconnect-mediastream.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMediaConnectMediaStreamComponent
    extends CustomControlValueAccessor<FullMediaconnectMediaStream>
    implements OnInit
{
    disabled?: boolean;
    @Input() model: FullMediaconnectMediaStream;
    @Input() channel: MediaConnectFlow | null;
    @Input() source: MediaConnectSource | null;
    @Input() jpegXSOutput: boolean;
    @Input() sourceSelection: boolean;
    @Input() outputSelection: boolean;
    @Input() removeAllowed: boolean;
    @Input() isEdit: boolean;
    @Input() collapsed: boolean;
    @Input() isParentFormSubmitted: boolean;
    @Input() streamNames: string[];
    @Input() streamIDs: number[];
    @Input() streamPorts: number[];
    @Input() dataCount: number;
    @Input() videoCount: number;
    @Output() propertiesChange = new EventEmitter();
    @Output() streamRemove = new EventEmitter();

    constants = Constants;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    mediaStreamTypes: { id: MediaconnectMediaStreamType; name: string }[] = [
        { id: "video", name: "Video" },
        { id: "audio", name: "Audio" },
        { id: "ancillary-data", name: "Ancillary Data" }
    ];
    mediaStreamAudioChannelOrders: { id: MediaconnectMediaStreamAudioChannelOrder; name: string }[] = [
        { id: "SMPTE2110.(M)", name: "M - Mono" },
        { id: "SMPTE2110.(DM)", name: "DM - Dual mono" },
        { id: "SMPTE2110.(ST)", name: "ST - Standard stereo" },
        { id: "SMPTE2110.(LtRt)", name: "LtRt - Matrix stereo" },
        { id: "SMPTE2110.(51)", name: "51 - 5.1 surround" },
        { id: "SMPTE2110.(71)", name: "71 - 7.1 surround" },
        { id: "SMPTE2110.(222)", name: "22.2 surround" },
        { id: "SMPTE2110.(SGRP)", name: "One SDI audio group" }
    ];
    mediaStreamVideoFormats: MediaconenctMediaStreamVideoFormat[] = ["2160p", "1080p", "1080i", "720p", "480p"];
    mediaStreamVideoColorimetryOptions: MediaconnectMediaStreamVideoColorimetry[] = [
        "BT601",
        "BT709",
        "BT2020",
        "BT2100",
        "ST2065-1",
        "ST2065-3",
        "XYZ"
    ];
    mediaStreamScanModes: { id: MediaconnectMediaStreamVideoScanMode; name: string }[] = [
        { id: "progressive", name: "Progressive" },
        { id: "interlace", name: "Interlace" },
        { id: "progressive-segmented-frame", name: "Progressive segmented frame" }
    ];
    mediaStreamVideoTscOptions: MediaconnectMediaStreamVideoTCS[] = [
        "SDR",
        "PQ",
        "HLG",
        "LINEAR",
        "BT2100LINPQ",
        "BT2100LINHLG",
        "ST2065-1",
        "ST428-1",
        "DENSITY"
    ];
    mediaStreamVideoRanges: MediaconnectMediaStreamVideoRange[] = ["NARROW", "FULL", "FULLPROTECT"];
    mediaEncodingProfiles: MediaconnectMediaStreamEncodingProfile[] = ["main", "high"];

    nameChanged() {
        this.propertiesChange.emit(this.model);
    }

    removeMediaStream() {
        this.streamRemove.emit(this.model);
    }

    portChanged() {
        this.propertiesChange.emit(this.model);
    }

    streamIDChanged() {
        this.propertiesChange.emit(this.model);
    }

    mediaStreamSendSelectionChanged() {
        this.propertiesChange.emit(this.model);
    }

    ngOnInit() {
        if (!this.model) return;

        if (this.model.stream_id == null) this.model.stream_id = 0;
    }

    mediaStreamTypeChanged() {
        switch (this.model.stream_type) {
            case "audio":
                {
                    this.model.clock_rate = 48000;
                    this.model.encoding_name = "pcm";
                }
                break;
            case "video":
                {
                    this.model.clock_rate = 90000;
                    if (this.source && this.source.protocol === "st2110-jpegxs") this.model.encoding_name = "jxsv";
                    else this.model.encoding_name = "raw";
                }
                break;
            case "ancillary-data":
                {
                    this.model.encoding_name = "smpte291";
                }
                break;
        }
        this.propertiesChange.emit(this.model);
    }

    runMediaStreamTypeSelectionQuery() {
        return JSON.stringify({
            "ancillary-data": { max: 1, count: this.dataCount },
            video: { max: 1, count: this.videoCount }
        });
    }

    expandControls() {
        this.collapsed = false;
    }

    collapseControls() {
        this.collapsed = true;
    }
}

export function sourceUpdated(strm: MediaConnectMediaStream, source: MediaConnectSource): void {
    if (!strm.stream_type) return;
    switch (strm.stream_type) {
        case "audio":
            strm.encoding_name = "pcm";
            break;
        case "ancillary-data":
            strm.encoding_name = "smpte291";
            break;
        case "video": {
            if (source?.protocol === "st2110-jpegxs") strm.encoding_name = "jxsv";
            else strm.encoding_name = "raw";
            break;
        }
    }
}

export function targetUpdated(strm: MediaConnectMediaStream, target: MediaconnectCDITarget | MediaconnectJPEGXSTarget) {
    if (!strm.stream_type) return;
    switch (strm.stream_type) {
        case "audio":
            strm.encoding_name = "pcm";
            break;
        case "ancillary-data":
            strm.encoding_name = "smpte291";
            break;
        case "video": {
            if (target?.type === "jpegxs") strm.encoding_name = "jxsv";
            else strm.encoding_name = "raw";
            break;
        }
    }
}
