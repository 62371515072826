<div class="modal-header">
    <h3 class="modal-title">{{ dialogName }}</h3>
    <button type="button" class="close" aria-label="Close" [disabled]="isSaving" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <ng-container *ngIf="isCreateMode">
            <app-input-generic-field
                [formControl]="form.controls.name"
                [textFieldName]="'NAME' | translate"
                type="text"
                [isParentFormSubmitted]="isFormSubmitted"
            ></app-input-generic-field>
            <app-zx-date-picker
                [formControl]="form.controls.startTime"
                [label]="'START_TIME' | translate"
                [isParentFormSubmitted]="isFormSubmitted"
                [allDates]="true"
            ></app-zx-date-picker>
            <app-zx-date-picker
                [formControl]="form.controls.endTime"
                [label]="'END_TIME' | translate"
                [isParentFormSubmitted]="isFormSubmitted"
                [allDates]="true"
            ></app-zx-date-picker>
        </ng-container>
        <p *ngIf="isRegeneratePasswordMode">
            {{ "REGENERATE_PASSWORD_NOTE" | translate }}
        </p>
        <ng-container *ngIf="isActionDone">
            <div class="row">
                <div *ngIf="isCreateMode" class="col">
                    <label for="shared-page-url">{{ "LINK" | translate }}</label>
                    <div class="input-group">
                        <input type="text" id="shared-page-url" name="shared-page-url" class="form-control ellipsis" readonly [(ngModel)]="url" />
                        <button class="btn btn-outline-primary" (click)="clipboardService.copy(url)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="col">
                    <label for="shared-page-password">{{ "PASSWORD" | translate }}</label>
                    <div class="input-group">
                        <input
                            type="text"
                            id="shared-page-password"
                            name="shared-page-password"
                            class="form-control ellipsis"
                            readonly
                            [(ngModel)]="password"
                        />
                        <button class="btn btn-outline-primary" (click)="clipboardService.copy(password)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-2" *ngIf="isCreateMode">{{ "SHARE_NOTE" | translate }}</div>
        </ng-container>

        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" [disabled]="isSaving" (click)="activeModal.close()">
            <fa-icon icon="times"></fa-icon>
            {{ "CLOSE" | translate }}
        </button>
        <button type="button" *ngIf="!isActionDone" class="btn btn-primary" ngbAutofocus [disabled]="isSaving" type="submit">
            <fa-icon icon="check" size="sm"></fa-icon>
            <ng-container *ngIf="isCreateMode">
                {{ "CREATE_LINK" | translate }}
            </ng-container>
            <ng-container *ngIf="isRegeneratePasswordMode">
                {{ "REGENERATE_PASSWORD" | translate }}
            </ng-container>
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>
