import { Pipe, PipeTransform } from "@angular/core";
import { desnakeZM } from "@zixi/shared-utils";

@Pipe({
    name: "desnake"
})
export class DesnakePipe implements PipeTransform {
    transform(value: string): string {
        return desnakeZM(value);
    }
}
