<zx-status-icon
    *ngIf="failoverStatusIcon(source.source)"
    class="d-inline me-1"
    [model]="failoverStatusIcon(source.source)"
    [status]="failoverStatusIcon(source.source)"
></zx-status-icon
><zx-source
    class="d-inline"
    [model]="source.source"
    [showStatusIcon]="false"
    [showStatusText]="false"
    [showOtherIcons]="false"
    [showLink]="true"
    [showTag]="false"
></zx-source>
