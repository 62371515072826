import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { Customer } from "src/app/pages/accounts/customer";
import { AccountsService } from "src/app/pages/accounts/accounts.service";

@Component({
    selector: "zx-customer-select",
    templateUrl: "./zx-customer-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxCustomerSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: Customer;
    @Input() required: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    customers: Customer[];
    private customersSubscription: Subscription;

    constructor(private cs: AccountsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.refreshAccounts(false);
        this.customersSubscription = this.cs.accounts.subscribe(customers => {
            this.customers = [...customers];
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.customersSubscription.unsubscribe();
    }
}
