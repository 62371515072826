import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, ReplaySubject, Subscriber } from "rxjs";
import { share, map } from "rxjs/operators";

import * as _ from "lodash";
import moment from "moment";

import { Incident } from "./incident";
import { APIResponse } from "src/app/models/shared";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { AuthService } from "src/app/services/auth.service";
import { Constants } from "../../constants/constants";
import { ZmEvent } from "../events/event";
import { DashboardConfigOptions } from "src/app/services/graphs.service";

@Injectable({
    providedIn: "root"
})
export class IncidentsService {
    private toDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;
    private fromDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;

    private pickerToDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;
    private pickerFromDate = new ReplaySubject(1) as ReplaySubject<moment.Moment>;

    get getToDate() {
        return this.toDate.asObservable();
    }
    setToDate(d: moment.Moment) {
        this.toDate.next(d);
    }

    get getFromDate() {
        return this.fromDate.asObservable();
    }
    setFromDate(d: moment.Moment) {
        this.fromDate.next(d);
    }
    //
    get getPickerToDate() {
        return this.pickerToDate.asObservable();
    }
    setPickerToDate(d: moment.Moment) {
        this.pickerToDate.next(d);
    }

    get getPickerFromDate() {
        return this.pickerFromDate.asObservable();
    }
    setPickerFromDate(d: moment.Moment) {
        this.pickerFromDate.next(d);
    }

    public resetIncident: BehaviorSubject<boolean> = new BehaviorSubject(false);
    getResetIncident(): Observable<boolean> {
        return this.resetIncident.asObservable();
    }

    private pinnedGraphs = new ReplaySubject(1) as ReplaySubject<DashboardConfigOptions>;
    get getPinnedGraphs() {
        return this.pinnedGraphs.asObservable();
    }
    setPinnedGraphs(g: DashboardConfigOptions) {
        this.pinnedGraphs.next(g);
    }

    private initialPinnedGraphs = new ReplaySubject(1) as ReplaySubject<DashboardConfigOptions>;
    get getInitialPinnedGraphs() {
        return this.initialPinnedGraphs.asObservable();
    }
    setInitialPinnedGraphs(g: DashboardConfigOptions) {
        this.initialPinnedGraphs.next(g);
    }

    //
    incidents: Observable<Incident[]>;
    private incidents$: ReplaySubject<Incident[]>;

    private lastIncidentsRefresh: number;
    private lastGetIncidents: number;

    private dataStore: {
        incidents: Incident[];
    };

    private objectDataStore: {
        [object_id: number]: { incidents: Incident[] };
    };

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private stp: StatusTextPipe,
        private translate: TranslateService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    reset() {
        this.dataStore = {
            incidents: []
        };

        this.objectDataStore = {};

        this.lastIncidentsRefresh = null;
        this.lastGetIncidents = null;

        this.incidents$ = new ReplaySubject<Incident[]>(1);
        this.incidents = this.incidents$.asObservable();
    }

    private prepIncident(incident: Incident) {
        incident._frontData = {
            duration: "",
            durationSort: null,
            runtime_state: "",
            triggering_object_name: "",
            triggering_object_type: "",
            root_cause_rule: "",
            number_of_objects: null,
            start_time: moment(incident.start_time).subtract(5, "minutes"),
            end_time: moment(incident.end_time || undefined).add(5, "minutes")
        };
        // Duration
        if (incident.start_time) {
            const start = moment(incident.start_time);
            const end = moment(incident.end_time ? incident.end_time : moment());
            const duration = moment.duration(end.diff(start));
            //
            //Get Days and subtract from duration
            const days = Math.floor(duration.asDays());
            duration.subtract(moment.duration(days, "days"));
            //Get hours and subtract from duration
            const hours = duration.hours();
            duration.subtract(moment.duration(hours, "hours"));
            //Get Minutes and subtract from duration
            const minutes = duration.minutes();
            duration.subtract(moment.duration(minutes, "minutes"));
            //Get seconds
            const seconds = duration.seconds();
            //
            let dv = "";
            if (days) dv += days + "d, ";
            if (hours) dv += hours + "h, ";
            if (minutes) dv += minutes + "m, ";
            if (seconds) dv += seconds + "s";
            dv = dv.replace(/,\s*$/, "");
            if (!incident.end_time) dv = dv + " +";
            incident._frontData.duration = dv;
            //
            incident._frontData.durationSort = moment.duration(end.diff(start));
        } else {
            incident._frontData.duration = "-";
        }
        // Runtime state
        incident._frontData.runtime_state = this.translate.instant(incident.state.toUpperCase());

        // Triggering Object
        if (incident.triggering_object_type && incident.triggering_object_name) {
            incident._frontData.triggering_object_name = incident.triggering_object_name;
            incident._frontData.triggering_object_type = incident.triggering_object_type;
        } else {
            incident._frontData.triggering_object_name = "-";
            incident._frontData.triggering_object_type = "-";
        }

        return incident;
    }

    private updateIncidentStore(newIncident: Incident, merge: boolean, object?: { id: number; type: string }): void {
        const incidentsStore = object ? this.objectDataStore[object.id] : this.dataStore;

        this.prepIncident(newIncident);
        const currentIndex = incidentsStore.incidents.findIndex(i => i.id === newIncident.id);
        if (currentIndex === -1) {
            incidentsStore.incidents.push(newIncident);
            return;
        } else if (merge) {
            const currentIncident = incidentsStore.incidents[currentIndex];
            Object.assign(currentIncident, newIncident);
        } else {
            incidentsStore.incidents[currentIndex] = newIncident;
        }
    }

    getCachedIncident(id: number) {
        if (this.dataStore.incidents && id) return this.dataStore.incidents.find(i => i.id === id);
        else return undefined;
    }

    getIncidents(force?: boolean, object?: { id: number; type: string }): Observable<Incident[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGetIncidents <= 60000) return this.incidents;
        this.lastGetIncidents = _.now();

        const incidents$ = this.http
            .get<APIResponse<Incident[]>>(Constants.apiUrl + Constants.apiUrls.incidents, {
                params: object ? { object_type: object.type, object_id: object.id } : undefined
            })
            .pipe(share());

        incidents$.subscribe(
            data => {
                if (object && !this.objectDataStore[object.id]) this.objectDataStore[object.id] = { incidents: [] };
                const incidentsStore = object ? this.objectDataStore[object.id] : this.dataStore;

                const incidents = data.result;
                if (!incidents.length) incidentsStore.incidents = [];

                incidentsStore.incidents.forEach((existing, existingIndex) => {
                    const newIndex = incidents.findIndex(b => b.id === existing.id);

                    if (newIndex === -1) {
                        incidentsStore.incidents.splice(existingIndex, 1);
                    }
                });

                incidents.forEach(i => this.updateIncidentStore(i, true, object || undefined));

                this.incidents$.next(Object.assign({}, incidentsStore).incidents);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_INCIDENTS"), error)
        );

        return incidents$.pipe(map(r => r.result));
    }

    refreshIncident(id: number, force?: boolean): Observable<Incident> {
        if (!force && _.now() - this.lastIncidentsRefresh <= 60000) {
            return new Observable((observe: Subscriber<Incident>) => {
                observe.next(this.dataStore.incidents.find(c => c.id === id));
                observe.complete();
            });
        }

        this.lastGetIncidents = _.now();

        const incident$ = this.http
            .get<APIResponse<Incident>>(Constants.apiUrl + Constants.apiUrls.incidents + "/" + `${id}`)
            .pipe(share());
        incident$.subscribe(
            data => {
                const i = data.result;
                i.hasFullDetails = true;
                this.updateIncidentStore(i, false);

                this.incidents$.next(Object.assign({}, this.dataStore).incidents);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_INCIDENT"), error)
        );
        return incident$.pipe(map(r => r.result));
    }

    async addIncident(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<APIResponse<Incident>>(Constants.apiUrl + Constants.apiUrls.incidents, model)
                .toPromise();
            const incident: Incident = result.result;

            this.updateIncidentStore(incident, true);

            this.incidents$.next(Object.assign({}, this.dataStore).incidents);
            return incident;
        } catch (error) {
            return false;
        }
    }

    async updateIncident(id: number, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<Incident>>(Constants.apiUrl + Constants.apiUrls.incidents + "/" + id, model)
                .toPromise();
            const updatedIncident: Incident = result.result;

            this.updateIncidentStore(updatedIncident, true);

            this.incidents$.next(Object.assign({}, this.dataStore).incidents);
            return updatedIncident;
        } catch (error) {
            return false;
        }
    }

    async deleteIncident(incident: Incident) {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(Constants.apiUrl + Constants.apiUrls.incidents + "/" + `${incident.id}`)
                .toPromise();
            const deletedId: number = result.result;
            const index = this.dataStore.incidents.findIndex(f => f.id === deletedId);
            if (index !== -1) this.dataStore.incidents.splice(index, 1);

            this.incidents$.next(Object.assign({}, this.dataStore).incidents);
            return true;
        } catch (error) {
            return false;
        }
    }
}
