import { Component, Input } from "@angular/core";
import { IoServer } from "src/app/components/io-servers/io-servers.service";

@Component({
    selector: "app-io-server-tunnels-column",
    templateUrl: "./io-server-tunnels-column.component.html"
})
export class IoServerTunnelsColumnComponent {
    @Input() ioServer: IoServer;
}
