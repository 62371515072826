import { Component, Input } from "@angular/core";

@Component({
    selector: "app-zx-network-objects-status-column",
    template: `
        <div>
            <fa-icon icon="check-circle" class="status-good me-1" *ngIf="objectSummary?.good > 0"></fa-icon
            ><span class="me-1" *ngIf="objectSummary?.good > 0">{{ objectSummary.good }}</span>
            <fa-icon icon="minus-circle" class="status-bad me-1" *ngIf="objectSummary?.bad > 0"></fa-icon
            ><span class="me-1" *ngIf="objectSummary?.bad > 0">{{ objectSummary.bad }}</span>
            <fa-icon icon="circle-exclamation" class="status-warning me-1" *ngIf="objectSummary?.warning > 0"></fa-icon>
            <span class="me-1" *ngIf="objectSummary?.warning > 0">{{ objectSummary.warning }}</span>
            <fa-icon icon="circle-question" class="status-disabled me-1" *ngIf="objectSummary?.unknown > 0"></fa-icon>
            <span *ngIf="objectSummary?.unknown > 0">{{ objectSummary.unknown }}</span>
            <span
                *ngIf="
                    !objectSummary?.unknown && !objectSummary?.warning && !objectSummary?.bad && !objectSummary?.good
                "
                >-
            </span>
        </div>
    `
})
export class ZxNetworkObjectsStatusColumnComponent {
    @Input() objectSummary: { good: number; bad: number; warning: number; unknown: number };
}
