import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { AdaptiveChannel } from "../../../pages/channels/channel";

@Component({
    selector: "zx-adaptive-channels-select",
    templateUrl: "./zx-adaptive-channels-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxAdaptiveChannelsSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number[];
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    adaptiveChannels: AdaptiveChannel[];
    private adaptiveChannelsSubscription: Subscription;

    constructor(private cs: ChannelsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getAdaptiveChannels();
        this.adaptiveChannelsSubscription = this.cs.adaptiveChannels.subscribe(adaptiveChannels => {
            this.adaptiveChannels = [...adaptiveChannels];

            const modelIds = [];
            if (this.model && this.model.length)
                for (const id of this.model) {
                    if (this.adaptiveChannels.some(channel => channel.id === id)) {
                        modelIds.push(id);
                    }
                }

            this.model = modelIds;
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.adaptiveChannelsSubscription.unsubscribe();
    }
}
