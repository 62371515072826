import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "deescalate"
})
export class DeescalatePipe implements PipeTransform {
    transform(time: string): string {
        const date = new Date(time);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours}:${minutes} ${hours >= 12 ? "PM" : "AM"}`;
    }
}
