<form id="confirmForm" #confirmForm="ngForm" (ngSubmit)="confirmForm.valid && onSubmit()" [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">{{ action | translate }} {{ type | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Alternative Text -->
        <p class="mb-0" *ngIf="alternativeBodyText; else standardText">
            {{ alternativeBodyText }}
        </p>
        <!-- Standard Text -->
        <ng-template #standardText>
            <p class="mb-0" *ngIf="action !== 'SAVE_RESTART' && type === 'NOTE'">
                {{ "DO_YOU_WANT_TO" | translate }} {{ action | translate | lowercase }} {{ "THE" | translate | lowercase }} {{ type | translate }}
                <span *ngIf="name">{{ "CREATED_BY" | translate | lowercase }} "{{ name }}"</span>?
            </p>
            <p class="mb-0" *ngIf="action !== 'SAVE_RESTART' && type !== 'NOTE'">
                {{ "DO_YOU_WANT_TO" | translate }} {{ action | translate | lowercase }} {{ "THE" | translate | lowercase }} {{ type | translate }}
                <span *ngIf="name">{{ "NAMED" | translate | lowercase }} "{{ name }}"</span>?
            </p>
        </ng-template>
        <!-- Note -->
        <p class="mb-0" *ngIf="note">
            <span>{{ "NOTE" | translate }}: {{ note | translate }}</span>
        </p>
        <!-- Warning -->
        <p class="mb-0" *ngIf="warning">
            <span>
                <strong>{{ "WARNING" | translate }}:</strong> {{ warning | translate }}
            </span>
        </p>
        <!-- Update Requries Restart -->
        <p class="mb-0" *ngIf="action === 'SAVE_RESTART'">
            {{ "UPDATE_REQUIRES" | translate }} "{{ name }}" {{ type | translate }} {{ "TO_BE_RESTARTED" | translate }}.
        </p>
        <!-- Checkbox -->
        <div class="form-group mt-3 mb-0" *ngIf="checkbox">
            <div class="form-check lh-15">
                <input type="checkbox" class="form-check-input" id="checkbox" name="checkbox" [(ngModel)]="checkboxModel" />
                <label class="form-check-label" for="checkbox">{{ checkbox | translate }}</label>
            </div>
            <small
                *ngIf="checkboxNote"
                class="pt-2"
                [ngStyle]="{
                    'padding-left.px': 20
                }"
                >{{ checkboxNote | translate }}</small
            >
        </div>
        <!-- Delete Input -->
        <div class="form-group mt-3 mb-0" *ngIf="deleteInput">
            <label for="d" [ngClass]="{ 'is-invalid': confirmForm.submitted && d.errors }"
                >{{ "TO_CONFIRM_DELETION_TYPE" | translate }} <em>{{ "DELETE" | translate | lowercase }}</em> {{ "INTO_THE_FIELD" | translate
                }}<fa-icon icon="asterisk" size="xs"></fa-icon
            ></label>
            <input
                type="text"
                id="d"
                name="d"
                class="form-control"
                [ngClass]="{ 'is-invalid': confirmForm.submitted && d.errors }"
                [(ngModel)]="deleteInputModel"
                required
                pattern="^delete$"
                #d="ngModel"
            />
            <div *ngIf="d.invalid" class="invalid-feedback">
                <div *ngIf="d.errors.required">{{ "DELETE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                <div *ngIf="d.errors.pattern">{{ "DELETE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            </div>
        </div>
        <!-- Error -->
        <app-error [marginBottom]="false" [marginTop]="true"></app-error>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()">
            <fa-icon icon="times"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button
            type="button"
            class="btn"
            [ngClass]="{
                'btn-danger': action === 'DELETE' || action === 'STOP_SHARING',
                'btn-success': action === 'ENABLE',
                'btn-warning': action === 'DISABLE',
                'btn-primary': action !== 'DISABLE' && action !== 'ENABLE' && action !== 'DELETE'
            }"
            ngbAutofocus
            [disabled]="saving"
            zmid="form-submit"
            type="submit"
        >
            <fa-icon *ngIf="action === 'DELETE' || action === 'STOP_SHARING'" icon="trash-alt" size="sm"></fa-icon>
            <fa-icon *ngIf="action === 'DISABLE'" icon="ban" size="sm"></fa-icon>
            <fa-icon *ngIf="action === 'ENABLE'" icon="power-off" size="sm"></fa-icon>
            <fa-icon *ngIf="action === 'MUTE'" icon="bell-slash" size="sm"></fa-icon>
            <fa-icon *ngIf="action === 'UNMUTE'" icon="bell" size="sm"></fa-icon>
            {{ action | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>
