import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import * as _ from "lodash";
import moment from "moment";
import { TimezonePipe } from "src/app/pipes/timezone.pipe";

import { Constants, TimeZoneT } from "./../../../../constants/constants";
import { ReportsService } from "../../../../pages/reports/reports.service";
import { Report } from "../../../../pages/reports/report";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-generate-report-dialog",
    templateUrl: "./generate-report-dialog.component.html",
    providers: [TimezonePipe]
})
export class GenerateReportDialogComponent implements OnInit, OnDestroy {
    report: Report;
    loading: boolean;
    saving: boolean;

    minLength = 2;
    runName: string;
    setTimeBy: string;
    selectedMonth: number;
    selectedQuarter: number;
    selectedPeriod: string;
    selectedTimeZone: TimeZoneT;

    showFromPicker: boolean;
    showToPicker: boolean;
    fromCounter = 0;
    toCounter = 0;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    constants = Constants;

    fromDateString: string;
    toDateString: string;

    timeZones = Constants.timeZones;

    monthOptions = _.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], offset => {
        return {
            name: moment().subtract(offset, "months").format("MMMM YYYY"),
            value: offset
        };
    });

    quarterOptions = _.map([1, 2, 3, 4, 5], offset => {
        return {
            name: moment().subtract(offset, "Q").format("[Q]Q-Y"),
            value: offset
        };
    });

    periodOptions = [
        { name: "Previous 7 Days", value: "days-7" },
        { name: "Previous 4 Weeks", value: "weeks-4" },
        { name: "Previous 3 Months", value: "months-3" },
        { name: "Current Month", value: "months-0" },
        { name: "Previous Month", value: "months-1" },
        { name: "Current Quarter", value: "quarters-0" },
        { name: "Previous Quarter", value: "quarters-1" },
        { name: "Current Year", value: "years-0" },
        { name: "Previous Year", value: "years-1" }
    ];

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private rs: ReportsService,
        private sharedService: SharedService,
        private timeZonePipe: TimezonePipe
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnInit() {
        this.runName = this.report.name + "-" + moment().format("YYYYMMDD");
        this.selectedMonth = 1;
        this.selectedQuarter = 1;
        this.selectedPeriod = "months-0";

        const date = new Date();
        let offset = new Date().getTimezoneOffset() / -60;
        if (!this.sharedService.isDST(date)) offset = offset + 1;
        this.selectedTimeZone = _.find(this.timeZones, tz => {
            return tz.offset === offset;
        });

        if (this.report.timezone) {
            this.selectedTimeZone = this.timeZonePipe.transform(this.report.timezone, "utc");
        }

        if (this.report.period && this.report.period_param) {
            this.setTimeBy = "period";
            this.selectedPeriod = this.report.period + "-" + this.report.period_param;
        }

        if (this.report.to && this.report.from) {
            this.setTimeBy = "range";
            this.toDate = moment(this.report.to);
            this.toDateString = this.report.to;
            this.fromDate = moment(this.report.from);
            this.fromDateString = this.report.from;
        }
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        if (this.checkDate(this.fromDate, this.toDate) && this.setTimeBy === "range") return;

        this.saving = true;

        if (this.setTimeBy === "month") {
            this.fromDate = moment().subtract(this.selectedMonth, "M").startOf("month");
            this.toDate = moment().subtract(this.selectedMonth, "M").endOf("month");
        }

        if (this.setTimeBy === "quarter") {
            this.fromDate = moment().subtract(this.selectedQuarter, "Q").startOf("quarter");
            this.toDate = moment().subtract(this.selectedQuarter, "Q").endOf("quarter");
        }

        const model = {
            name: this.runName,
            period: this.setTimeBy === "period" ? this.selectedPeriod.split("-")[0] : null,
            period_param: this.setTimeBy === "period" ? this.selectedPeriod.split("-")[1] : null,
            from: this.fromDate,
            to: this.toDate,
            timezone: this.selectedTimeZone.utc[0]
        };

        const result = await this.rs.generateReport(this.report, model);
        if (result) {
            this.saving = false;
            this.activeModal.close(result);
        } else {
            this.saving = false;
        }
    }

    // check if endDate is after startDate
    checkDate(startDate?, endDate?) {
        if (startDate && endDate) {
            if (new Date(startDate) > new Date(endDate) || startDate === endDate) {
                return true;
            } else return false;
        } else return false;
    }

    // From
    toggleFromPicker() {
        this.showFromPicker = true;
    }

    closeFromPicker() {
        window.focus();
        this.fromCounter = 0;
        this.showFromPicker = false;
    }

    clickOutsideFromPicker() {
        this.fromCounter = this.fromCounter + 1;
        if (this.fromCounter > 1) {
            this.closeFromPicker();
        }
    }

    fromDateChanged(event: Date) {
        if (event !== null) {
            this.fromDate = moment(new Date(event));
        }
    }
    // To
    toggleToPicker() {
        this.showToPicker = true;
    }

    closeToPicker() {
        window.focus();
        this.toCounter = 0;
        this.showToPicker = false;
    }

    clickOutsideToPicker() {
        this.toCounter = this.toCounter + 1;
        if (this.toCounter > 1) {
            this.closeToPicker();
        }
    }

    toDateChanged(event: Date) {
        if (event !== null) {
            this.toDate = moment(new Date(event));
        }
    }

    // Prevent key events except delete and backspace
    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }
}
