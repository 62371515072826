import { Component } from "@angular/core";
import { TourService } from "ngx-ui-tour-core";

@Component({
    selector: "zx-ui-tour",
    templateUrl: "./zx-ui-tour.component.html",
    styleUrls: ["./zx-ui-tour.component.scss"]
})
export class ZxUITourComponent {
    constructor(public tourService: TourService) {}
}
