import { Pipe, PipeTransform } from "@angular/core";
import { ActiveState, ZixiObject } from "./../models/shared";

@Pipe({
    name: "statusText",
    pure: false
})
export class StatusTextPipe implements PipeTransform {
    statuses = {
        disabled: "DISABLED",
        good: "OK",
        warning: "WARNING",
        bad: "ERROR",
        failed: "FAILED",
        deleted: "TERMINATED",
        no_source: "NOT_ASSIGNED",
        no_flow: "NOT_ASSIGNED",
        no_channel: "NOT_ASSIGNED",
        flow_disabled: "FLOW_DISABLED",
        pending: "PENDING",
        channel_disabled: "CHANNEL_DISABLED"
    };

    transform(model: ZixiObject): string {
        const lastError = this.getLastError(model);
        if (Object.keys(this.statuses).includes(model.generalStatus)) return this.statuses[model.generalStatus];
        else if (model.state === "pending" && model.is_enabled) return "PENDING";
        else if (lastError) return lastError.short_message;
        else return this.statuses[model.generalStatus] || "";
    }

    getLastError(object: ZixiObject): ActiveState | undefined {
        if (object.objectState && object.objectState.state === "success") return undefined;
        const errorState = (object.activeStates || []).find(as => as.type === "error");
        const warningState = (object.activeStates || []).find(as => as.type === "warning");
        return errorState || warningState;
    }

    simpleTransform(model: ZixiObject): string {
        const val = this.transform(model);
        if (["OK", "WARNING", "ERROR"].includes(val)) return val;
        return "OTHER";
    }
}
