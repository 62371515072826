import { contract } from "@zixi/zen-rpc";
import { initClient, tsRestFetchApi } from "@ts-rest/core";
import { Injectable, inject } from "@angular/core";
import { ErrorInterceptor } from "../helpers/error.interceptor";
import { firstValueFrom } from "rxjs";

const BASE_HEADERS = {
    accept: "application/json, text/plain, */*",
    acceptLanguage: "en-US,en;q=0.9",
    contentType: "application/json"
};

@Injectable({
    providedIn: "root"
})
export class ZenApiService {
    private errorInterceptor = inject(ErrorInterceptor);
    client = initClient(contract, {
        baseUrl: "",
        baseHeaders: BASE_HEADERS,
        api: async args => {
            const allArgs = {
                ...args,
                headers: {
                    ...args.headers,
                    "X-XSRF-TOKEN": document.cookie.match(/XSRF-TOKEN=([^;]*)/)?.[1]
                }
            };
            const response = await tsRestFetchApi(allArgs);

            // Should cover all error codes
            if (response.status > 399)
                await firstValueFrom(this.errorInterceptor.handleServerError(response, allArgs.method));
            return response;
        }
    });
}
