<!-- splitter -->
<div
    id="splitter"
    #splitter
    class="splitter-container"
    (mousedown)="startResizeDrag()"
    [ngClass]="{ leftSideContracted: splitterPosition === 1, rightSideContracted: splitterPosition === 3 }"
    tourAnchor="twelfthCustomLayoutAnchor"
>
    <div class="move-left" [ngClass]="{ 'show-move-buttons': showMoveButtons }">
        <button
            type="button"
            (click)="contractLeftSide(); $event.stopPropagation()"
            *ngIf="splitterPosition !== 1"
            class="btn btn-sm btn-xs btn-secondary"
            container="body"
            [ngbTooltip]="splitterPosition === 3 ? ('EXPAND_DETAILS' | translate) : ('COLLAPSE_LIST' | translate)"
        >
            <fa-icon icon="arrow-from-right" size="xs"></fa-icon>
        </button>
    </div>
    <div class="move-right" [ngClass]="{ 'show-move-buttons': showMoveButtons }">
        <button
            type="button"
            (click)="contractRightSide(); $event.stopPropagation()"
            *ngIf="splitterPosition !== 3"
            class="btn btn-sm btn-xs btn-secondary"
            container="body"
            [ngbTooltip]="splitterPosition === 1 ? ('EXPAND_LIST' | translate) : ('COLLAPSE_DETAILS' | translate)"
        >
            <fa-icon icon="arrow-from-left" size="xs"></fa-icon>
        </button>
    </div>
    <div class="line"></div>
</div>
