import { AbstractControl, ValidationErrors } from "@angular/forms";
import { BillingCodeValidationService } from "../services/billing-code-validation.service";

// custom validator to check that billing code and billing password are valid
export async function validateBillingCodeControls(
    validationService: BillingCodeValidationService,
    codeControl: AbstractControl,
    passwordControl: AbstractControl
): Promise<null | ValidationErrors> {
    // return null if controls haven't initialised yet
    if (!codeControl || !passwordControl) {
        return null;
    }

    // There is no point in cheking blank values.
    if ((!codeControl.value && !passwordControl.value) || (codeControl.value === "" && passwordControl.value === "")) {
        return null;
    }

    //  Only code or password filled is obviously wrong. No point picking on backend.
    if (!codeControl.value || !passwordControl.value || codeControl.value === "" || passwordControl.value === "") {
        return { invalidCode: true };
    }

    try {
        const codeIsValid = await validationService.checkBillingCodesImpl(codeControl.value, passwordControl.value);
        //  In case page was moved or reloaded, just quit.
        if (!codeIsValid) {
            return { invalidCode: true };
        } else {
            return null;
        }
    } catch (error) {
        return { validationFailed: true };
    }
}
