import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import _ from "lodash";
import moment from "moment";

import { ScteService } from "../../../pages/scte/scte.service";
import { LogDetailModalService } from "../../../pages/scte/log-detail-modal.service";
import { SCTELog, SCTEFilter } from "../../../pages/scte/scte";
import { KeyMap, Source } from "src/app/models/shared";
import { UsersService } from "../../../pages/account-management/users/users.service";
import { TableSchema } from "../table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "../zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "../zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { DatePipe } from "@angular/common";
import { ZxTableActionsComponent } from "../zx-table-actions/zx-table-actions.component";
import { assignGenericLinkTextInputsFactory } from "../zx-table-actions/zx-table-actions.table-adapter";
import { TimestampPipe } from "src/app/pipes/timestamp.pipe";
import { scte35ColorsService } from "../../../helpers/scte-35-colors.service";
import { Constants } from "src/app/constants/constants";
import { GraphsService } from "src/app/services/graphs.service";

const IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY = "zxScte35.isScte35ColorsVisible";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "zx-scte",
    templateUrl: "./zx-scte.component.html",
    providers: [DatePipe]
})
export class ZxScteComponent implements OnInit, OnChanges, OnDestroy {
    @Input() source: Source;
    @Input() autoRows? = true;

    loading = true;
    refreshing = false;
    scteLogs: SCTELog[] = [];
    isAdmin: boolean;
    scteFilter: SCTEFilter;
    sourceDashboardLink: string;
    morePages = true;
    pageSize = 100;

    private scteLogsSubscription: Subscription;
    private isAdminSubscription: Subscription;
    private toDateSubscription: Subscription;
    private fromDateSubscription: Subscription;

    isScte35ColorsVisible = false;

    constants = Constants;
    private scteLogsBS$ = new BehaviorSubject<SCTELog[]>([]);
    msgFilter: string;

    showDatePicker = false;
    showFrom = true;
    pickerCounter = 0;
    toDate: moment.Moment;
    fromDate: moment.Moment;
    lastFromDate: moment.Moment;
    lastToDate: moment.Moment;
    toDateString: string;
    fromDateString: string;
    dateRange: string = null;
    datePresets = ["LAST_1_HOUR", "LAST_6_HOURS", "LAST_12_HOURS", "LAST_24_HOURS", "LAST_48_HOURS", "LAST_7_DAYS"];
    dateFormat = "MM/DD/YYYY HH:mm:ss";

    tableColumnsSchema: TableSchema<KeyMap<SCTELog>>[] = [
        {
            header: this.translate.instant("DATE/TIME"),
            columnDef: "date",
            width: 220,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => this.datePipe.transform(row.created_at, "MMM d, y, h:mm:ss a"),
                row => this.datePipe.transform(row.created_at, "MMM d, y, h:mm:ss a"),
                () => true
            ),
            style: this.getCellStyle.bind(this),
            valueToExport: row => this.datePipe.transform(row.created_at, "MMM d, y, h:mm:ss a")
        },
        {
            header: this.translate.instant("PID"),
            columnDef: "pid",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.pid.toString(),
                row => row.pid.toString(),
                () => true
            ),
            style: this.getCellStyle.bind(this),
            valueToExport: row => row.pid.toString()
        },
        {
            header: this.translate.instant("AUTO_RETURN"),
            columnDef: "auto_return",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.auto_return ? "Yes" : "No"),
                row => (row.auto_return ? "Yes" : "No"),
                () => true
            ),
            textValue: row => (row.auto_return ? "Yes" : "No"),
            valueToExport: row => (row.auto_return ? "Yes" : "No"),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("PRE_ROLL"),
            columnDef: "pre_roll",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => this.tsp.transform(row.pre_roll),
                row => this.tsp.transform(row.pre_roll),
                () => true
            ),
            textValue: row => this.tsp.transform(row.pre_roll),
            valueToExport: row => this.tsp.transform(row.pre_roll),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("PTS"),
            columnDef: "pts_time",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => this.time_90Khz(row.pts_time),
                row => this.time_90Khz(row.pts_time),
                () => true
            ),
            textValue: row => this.time_90Khz(row.pts_time),
            valueToExport: row => this.time_90Khz(row.pts_time),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_UPID_ASCII"),
            columnDef: "segmentation_upid_ascii",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.segmentation_upid_ascii,
                row => row.segmentation_upid_ascii,
                () => true
            ),
            textValue: row => row.segmentation_upid_ascii,
            valueToExport: row => row.segmentation_upid_ascii,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_UPID_HEX"),
            columnDef: "segmentation_upid_hex",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.segmentation_upid_hex,
                row => row.segmentation_upid_hex,
                () => true
            ),
            textValue: row => row.segmentation_upid_hex,
            valueToExport: row => row.segmentation_upid_hex,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_TYPE_HEX"),
            columnDef: "seg_type_code",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
                row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
                () => true
            ),
            textValue: row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
            valueToExport: row => (row.seg_type_code ? `0x${row.seg_type_code.toString(16)}` : ""),
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SEG_TYPE"),
            columnDef: "segmentation_type",
            width: 140,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.seg_type,
                row => row.seg_type,
                () => true
            ),
            textValue: row => row.seg_type,
            valueToExport: row => row.seg_type,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "message",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SCTELog>>(
                row => row.message,
                row => row.message,
                () => true
            ),
            valueToExport: row => row.message,
            style: this.getCellStyle.bind(this)
        },
        {
            header: this.translate.instant("SCTE35"),
            columnDef: "scte35",
            visible: false,
            hideFromColumnChooser: true,
            valueToExport: row => row.scte_msg
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "info-circle",
                    translateTitle: "DETAILS",
                    onClickHandler: (row: SCTELog) => this.logDetails(row),
                    isTextVisible: false
                }
            ]),
            style: this.getCellStyle.bind(this)
        }
    ];

    constructor(
        private ss: ScteService,
        private userService: UsersService,
        private ldms: LogDetailModalService,
        private translate: TranslateService,
        private tsp: TimestampPipe,
        private datePipe: DatePipe,
        private gs: GraphsService
    ) {
        // Debounce search address
        this.msgChange = _.debounce(this.msgChange, 500);
    }

    ngOnInit() {
        this.isAdminSubscription = this.userService.isAdmin.subscribe(bool => {
            this.isAdmin = bool;
        });

        this.scteLogsSubscription = this.ss.scteLogs.subscribe(scteLogs => {
            this.scteLogs = scteLogs;
            if (this.scteLogs) {
                this.prepTableData();
                this.loading = false;
            }
        });

        const isScte35ColorsVisible = localStorage.getItem(IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY);
        if (isScte35ColorsVisible && isScte35ColorsVisible === "true") {
            this.isScte35ColorsVisible = true;
        }

        this.sourceDashboardLink = this.gs.custom(this.gs.sourceSCTE(this.source));

        // Dates
        this.setDateRangeOnLoad();

        this.toDateSubscription = this.ss.getToDate.pipe().subscribe(d => {
            if (d) {
                this.toDate = d;
                this.lastToDate = d;
                this.setDateRangeFromChild();
            }
        });

        this.fromDateSubscription = this.ss.getFromDate.pipe().subscribe(d => {
            if (d) {
                this.fromDate = d;
                this.lastFromDate = d;
                this.setDateRangeFromChild();
            }
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.source) {
            if (changes.source.previousValue && changes.source.currentValue) {
                if (changes.source.previousValue.id !== changes.source.currentValue.id) {
                    this.loading = true;
                    this.scteLogs = [];
                    this.msgFilter = null;
                    if (!this.toDate && !this.fromDate) await this.setDateRangeFromPreset("LAST_6_HOURS");
                    else await this.ss.getSourceSCTELogs(this.source, this.prepFilter({ pageSize: 100 })).toPromise();
                    this.morePages = this.checkIfMorePages();
                }
            }
        }
    }

    ngOnDestroy() {
        this.isAdminSubscription.unsubscribe();
        this.scteLogsSubscription.unsubscribe();
        this.ss.clearSCTELogs();
    }

    get scteLogs$() {
        return this.scteLogsBS$.asObservable();
    }

    // Prep Table Data
    private prepTableData() {
        this.scteLogs = this.scteLogs
            .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
            .reverse();
        this.scteLogsBS$.next(this.scteLogs);
    }

    // new Prep Filter
    prepFilter(overrides: Partial<SCTEFilter> = {}, useOffset = false): SCTEFilter {
        this.scteFilter = Object.assign(
            {
                pageSize: this.pageSize,
                offset: useOffset
                    ? _.isEmpty(this.scteLogs)
                        ? undefined
                        : JSON.stringify(_.last(this.scteLogs).searchAfter)
                    : undefined,
                fromDate: this.fromDate ? moment(this.fromDate) : null,
                toDate: this.toDate ? moment(this.toDate) : null,
                search: this.msgFilter
            },
            overrides
        );
        return this.scteFilter;
    }

    // Msg Change
    async msgChange() {
        this.refreshing = true;
        await this.ss.getSourceSCTELogs(this.source, this.prepFilter({ pageSize: 100 })).toPromise();
        this.refreshing = false;
        this.morePages = this.checkIfMorePages();
    }

    // Reset Filter
    resetFilter() {
        this.scteLogs = [];
        this.fromDate = null;
        this.toDate = null;
        this.msgFilter = null;
    }

    async currentPageInfo(page: { pageSize: number; pageIndex: number }) {
        if (this.scteLogs.length) {
            this.pageSize = page.pageSize;
            if (this.pageSize * (page.pageIndex + 2) >= this.scteLogs.length) {
                this.refreshing = true;
                const pageSize = (this.scteLogs.length % this.pageSize) + this.pageSize;

                await this.ss.loadMoreSourceSCTELogs(
                    this.source,
                    this.prepFilter(
                        {
                            pageSize
                        },
                        true
                    )
                );

                this.morePages = this.checkIfMorePages();
                this.refreshing = false;
            }
        }
    }

    checkIfMorePages(): boolean {
        const prevCount = this.scteLogs.length;
        const pageSize = (this.scteLogs.length % this.pageSize) + this.pageSize;
        return this.scteLogs.length < prevCount + pageSize;
    }

    // Log Details
    async logDetails(log: SCTELog) {
        await this.ldms.logDetails(log);
    }

    private time_90Khz(time?: number): string {
        if (!time) return "";
        //  Work around the fact that moment.duration does not have format functionality.
        const timeMs = Math.floor((time + 45) / 90);
        return moment.utc(timeMs).format("HH:mm:ss.SSS");
    }

    toggleLogsStyle() {
        this.isScte35ColorsVisible = !this.isScte35ColorsVisible;
        localStorage.setItem(IS_SCTE35_COLORS_VISIBLE_LOCAL_STORAGE_KEY, this.isScte35ColorsVisible.toString());
    }

    getCellStyle(scteLog: SCTELog) {
        return this.isScte35ColorsVisible
            ? scte35ColorsService.getStyle(scteLog.segmentation_type_id, scteLog.seg_type)
            : "";
    }

    //
    async setDateRangeFromPreset(preset?: string) {
        this.refreshing = true;
        this.toDate = moment();
        this.lastToDate = moment();
        this.toDateString = moment().format();
        if (!preset) {
            this.dateRange = this.translate.instant("LAST_12_HOURS");
            this.fromDate = moment().subtract(12, "hours");
            this.lastFromDate = moment().subtract(12, "hours");
            this.fromDateString = moment().subtract(12, "hours").format();
        } else {
            this.dateRange = this.translate.instant(preset);
            if (preset === "LAST_1_HOUR") {
                this.fromDate = moment().subtract(1, "hours");
                this.lastFromDate = moment().subtract(1, "hours");
                this.fromDateString = moment().subtract(1, "hours").format();
            }
            if (preset === "LAST_6_HOURS") {
                this.fromDate = moment().subtract(6, "hours");
                this.lastFromDate = moment().subtract(6, "hours");
                this.fromDateString = moment().subtract(6, "hours").format();
            }
            if (preset === "LAST_12_HOURS") {
                this.fromDate = moment().subtract(12, "hours");
                this.lastFromDate = moment().subtract(12, "hours");
                this.fromDateString = moment().subtract(12, "hours").format();
            }
            if (preset === "LAST_24_HOURS") {
                this.fromDate = moment().subtract(24, "hours");
                this.lastFromDate = moment().subtract(24, "hours");
                this.fromDateString = moment().subtract(24, "hours").format();
            }
            if (preset === "LAST_48_HOURS") {
                this.fromDate = moment().subtract(48, "hours");
                this.lastFromDate = moment().subtract(48, "hours");
                this.fromDateString = moment().subtract(48, "hours").format();
            }
            if (preset === "LAST_7_DAYS") {
                this.fromDate = moment().subtract(7, "days");
                this.lastFromDate = moment().subtract(7, "days");
                this.fromDateString = moment().subtract(7, "days").format();
            }
            this.ss.setPickerFromDate(this.fromDate);
            this.ss.setPickerToDate(this.toDate);
            await this.ss.getSourceSCTELogs(this.source, this.prepFilter({ pageSize: 100 })).toPromise();
            this.refreshing = false;
        }
    }

    setDateRangeOnLoad() {
        this.setDateRangeFromPreset("LAST_6_HOURS");
    }

    async setDateRangeFromChild() {
        this.refreshing = true;
        const from: string = this.fromDate.format(this.dateFormat);
        const to: string = this.toDate.format(this.dateFormat);
        this.fromDateString = this.fromDate.format();
        this.toDateString = this.toDate.format();
        this.dateRange = from + " - " + to;
        await this.ss.getSourceSCTELogs(this.source, this.prepFilter({ pageSize: 100 })).toPromise();
        this.refreshing = false;
    }

    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }

    setFrom(from: moment.Moment) {
        let changed;
        if (this.fromDate) {
            if (from.format() !== this.fromDate.format()) changed = true;
        }
        this.fromDate = from;
        if (changed) this.fromChanged();
    }

    setTo(to: moment.Moment) {
        let changed;
        if (this.toDate) {
            if (to.format() !== this.toDate.format()) changed = true;
        }
        this.toDate = to;
        if (changed) this.toChanged();
    }

    toChanged() {
        this.ss.setToDate(this.toDate);
        this.ss.setPickerToDate(this.toDate);
    }

    fromChanged() {
        this.ss.setFromDate(this.fromDate);
        this.ss.setPickerFromDate(this.fromDate);
    }
}
