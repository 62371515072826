import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, Observable, ReplaySubject, Subscriber } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { UserGroup } from "../../../models/shared";
import * as _ from "lodash";
import { share, map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class UserGroupsService {
    // UserGroup
    userGroup: Observable<UserGroup>;
    userGroups: Observable<UserGroup[]>;
    private userGroup$: ReplaySubject<UserGroup>;
    private userGroups$: ReplaySubject<UserGroup[]>;
    private dataStore: {
        userGroup: UserGroup;
        userGroups: UserGroup[];
    };

    private lastUserGroupsRefresh: number;

    constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            userGroup: null,
            userGroups: []
        };

        this.lastUserGroupsRefresh = null;

        this.userGroup$ = new ReplaySubject<UserGroup>(1);
        this.userGroups$ = new ReplaySubject<UserGroup[]>(1);
        this.userGroup = this.userGroup$.asObservable();
        this.userGroups = this.userGroups$.asObservable();
    }
    private prepUserGroup(userGroup: UserGroup, fullData: boolean) {
        if (!userGroup._frontData) userGroup._frontData = {};

        if (fullData) userGroup._frontData.lastFullUpdate = _.now();
    }

    private updateStore(userGroup: UserGroup, fullData: boolean): void {
        const currentIndex = this.dataStore.userGroups.findIndex(u => u.id === userGroup.id);
        if (currentIndex === -1) {
            this.prepUserGroup(userGroup, fullData);
            this.dataStore.userGroups.push(userGroup);
        } else if (!fullData) {
            const current = this.dataStore.userGroups[currentIndex];

            Object.assign(current, userGroup);

            this.prepUserGroup(current, fullData);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.prepUserGroup(userGroup, fullData);
            this.dataStore.userGroups[currentIndex] = userGroup;
        }
    }

    refreshUserGroups(force?: boolean): Observable<UserGroup[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastUserGroupsRefresh <= 60000) return this.userGroups;
        this.lastUserGroupsRefresh = _.now();

        const userGroups$ = this.http
            .get<{ success: boolean; result: UserGroup[] }>(Constants.apiUrl + Constants.apiUrls.userGroups)
            .pipe(share());

        userGroups$.subscribe(
            data => {
                const userGroups = data.result;

                this.dataStore.userGroups.forEach((existing, existingIndex) => {
                    const newIndex = userGroups.findIndex(u => u.id === existing.id);
                    if (newIndex === -1) this.dataStore.userGroups.splice(existingIndex, 1);
                });

                userGroups.forEach(refreshedUserGroup => this.updateStore(refreshedUserGroup, false));
                this.userGroups$.next(_.cloneDeep(this.dataStore).userGroups);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_USER_GROUPS"), error)
        );

        return userGroups$.pipe(map(r => r.result));
    }

    refreshUserGroup(id: number | UserGroup, force?: boolean): Observable<UserGroup> {
        const currentUserGroup = this.dataStore.userGroups.find(u => u.id === (typeof id === "number" ? id : id.id));
        if (!force && currentUserGroup && _.now() - currentUserGroup._frontData.lastFullUpdate <= 60000) {
            return new Observable((observe: Subscriber<UserGroup>) => {
                observe.next(currentUserGroup);
                observe.complete();
            });
        }

        const userGroup$ = this.http
            .get<{ success: boolean; result: UserGroup }>(
                Constants.apiUrl + Constants.apiUrls.userGroups + "/" + (typeof id === "number" ? id : id.id)
            )
            .pipe(share());

        userGroup$.subscribe(
            data => {
                const userGroup = data.result;

                this.updateStore(userGroup, true);

                this.userGroups$.next(Object.assign({}, this.dataStore).userGroups);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_USER_GROUP"), error)
        );

        return userGroup$.pipe(map(r => r.result));
    }

    // UserGroups
    getUserGroups() {
        return this.http.get<UserGroup[]>(Constants.apiUrl + Constants.apiUrls.userGroups);
    }

    async deleteUserGroup(userGroup: UserGroup) {
        try {
            const result = await firstValueFrom(
                this.http.delete<{ success: boolean; result: number }>(
                    Constants.apiUrl + Constants.apiUrls.userGroups + "/" + `${userGroup.id}`
                )
            );

            const deletedId: number = result.result;

            const userGroupIndex = this.dataStore.userGroups.findIndex(f => f.id === deletedId);
            if (userGroupIndex !== -1) this.dataStore.userGroups.splice(userGroupIndex, 1);

            this.userGroups$.next(Object.assign({}, this.dataStore).userGroups);
            return true;
        } catch (error) {
            return false;
        }
    }

    async addUserGroup(model: Record<string, unknown>) {
        try {
            const result = await firstValueFrom(
                this.http.post<{ result: UserGroup; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.userGroups,
                    model
                )
            );

            const userGroup = result.result;
            this.updateStore(userGroup, false);

            this.userGroups$.next(Object.assign({}, this.dataStore).userGroups);
            return userGroup;
        } catch (error) {
            return false;
        }
    }

    // Update UserGroup
    async updateUserGroup(id: number, model: Record<string, unknown>): Promise<boolean> {
        try {
            const result = await firstValueFrom(
                this.http.put<{ success: boolean; result: UserGroup }>(
                    Constants.apiUrl + Constants.apiUrls.userGroups + "/" + `${id}`,
                    model
                )
            );

            const userGroup = result.result;
            this.updateStore(userGroup, false);
            this.userGroups$.next(_.cloneDeep(this.dataStore).userGroups);

            return result.success;
        } catch (error) {
            return false;
        }
    }
}
