import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Source, TraceRoute } from "src/app/models/shared";
import { Subscription, filter } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { SourcesService } from "../../sources.service";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-source-traceroute-dialog",
    templateUrl: "./source-traceroute-dialog.component.html"
})
export class SourceTracerouteDialogComponent implements OnDestroy, OnInit {
    source: Source;
    loading: boolean;
    traceroute: TraceRoute;
    tracerouteError: string = null;
    tracerouteEmpty = false;

    private tracerouteSubscription: Subscription;
    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private ss: SourcesService,
        private sharedService: SharedService,
        private translate: TranslateService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    async ngOnInit() {
        this.loading = true;
        const result = await this.ss.refreshTraceroute(this.source).toPromise();
        this.traceroute = result;
        // TODO: check traceroutes as they aren't working in some scenarios or showing errors correctly
        if (this.traceroute) {
            // Error
            if (this.traceroute.error) {
                if (typeof this.traceroute.error === "string") this.tracerouteError = this.traceroute.error;
                else this.tracerouteError = this.traceroute.error.message;
            } else this.tracerouteError = null;
            // Empty
            if (this.sharedService.isEmptyObject(this.traceroute)) {
                this.tracerouteEmpty = true;
            } else this.tracerouteEmpty = false;
        }

        this.loading = false;
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.tracerouteSubscription.unsubscribe();
    }

    confirm() {
        this.activeModal.close();
    }

    urlEncodedTraceroute(s: string) {
        return encodeURIComponent(s.replace(/^[^\n]+\n/, ""));
    }
}
