import { Component, OnInit, Input } from "@angular/core";
import { Constants } from "./../../../constants/constants";
import { ZixiObject } from "./../../../models/shared";

@Component({
    selector: "zx-remote-access",
    template: `
        <div class="ellipsis d-inline" title="{{ model | errorMessage }}">
            <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'REMOTE_ACCESS' | translate }}"
                >RA</span
            >
            <zx-status-full
                [icon]="showStatusIcon"
                [model]="model"
                [status]="status || (model.generalStatus | statusClass)"
                [showStatusText]="showStatusText"
            ></zx-status-full>
            <a *ngIf="showLink" [routerLink]="['/' + urls.remote_access, model.name]" (click)="$event.stopPropagation()"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight
            ></a>
            <span *ngIf="!showLink"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight
            ></span>
        </div>
    `
})
export class ZxRemoteAccessComponent implements OnInit {
    @Input() model: ZixiObject;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() searchTerm?: string | string[];

    urls = Constants.urls;

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
