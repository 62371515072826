<a href="/api/reports/{{ report.id }}/impls/{{ run.id }}" [class.disabled]="run.status !== 'ready'" title="{{ 'DOWNLOAD' | translate }}"
    ><fa-icon icon="download" size="sm"></fa-icon><span class="sr-only">{{ "DOWNLOAD" | translate }}</span></a
>
<a
    class="ms-3 text-danger"
    href="javascript:void(0)"
    (click)="deleteRun(run)"
    [class.disabled]="!canEdit || run.status === 'processing'"
    title="{{ 'DELETE' | translate }}"
    ><fa-icon icon="trash-alt" size="sm"></fa-icon><span class="sr-only">{{ "DELETE" | translate }}</span></a
>
