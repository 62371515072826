import { Directive, Input } from "@angular/core";
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from "@angular/forms";

export function minimumSelectedValidator(n: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
        if (control.value && control.value.length < n) {
            const tooFew = true;
            return tooFew ? { minimumSelected: { value: control.value } } : null;
        } else {
            const tooFew = false;
            return tooFew ? { minimumSelected: { value: control.value } } : null;
        }
    };
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[minimumSelected]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MinimumSelectedDirective, multi: true }]
})
export class MinimumSelectedDirective implements Validator {
    @Input() minimumSelected: number;

    validate(control: AbstractControl): { [key: string]: unknown } {
        return this.minimumSelected ? minimumSelectedValidator(this.minimumSelected)(control) : null;
    }
}
