import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-agentz-install-confirm-dialog",
    templateUrl: "./agentz-install-confirm-dialog.component.html"
})
export class AgentZInstallConfirmationComponent {
    broadcasterName: string;
    confirmHandler: () => Promise<boolean>;
    working: boolean;

    constructor(public activeModal: NgbActiveModal) {}

    async confirm() {
        this.working = true;
        const result = await this.confirmHandler();
        if (result === false) {
            this.working = false;
        } else {
            this.working = false;
            this.activeModal.close();
        }
    }
}
