import { Component, Input } from "@angular/core";
import { Broadcaster } from "src/app/models/shared";

@Component({
    selector: "zx-broadcaster-streams-col",
    template: ` <span *ngIf="broadcaster.status?.inputs_count" class="me-2" title="{{ 'INPUTS' | translate }}"
            ><fa-icon
                [ngStyle]="{
                    'margin-right.px': 1
                }"
                icon="video"
                size="sm"
            ></fa-icon
            >{{ broadcaster.status.inputs_count }}</span
        >
        <span *ngIf="broadcaster.status?.adaptives_count" class="me-2" title="{{ 'ADAPTIVE_GROUPS' | translate }}"
            ><fa-icon
                [ngStyle]="{
                    'margin-right.px': 1
                }"
                icon="cogs"
                size="sm"
            ></fa-icon
            >{{ broadcaster.status.adaptives_count }}</span
        >
        <span *ngIf="broadcaster.status?.outputs_count" title="{{ 'OUTPUTS' | translate }}"
            ><fa-icon
                [ngStyle]="{
                    'margin-right.px': 1
                }"
                icon="sign-out-alt"
                size="sm"
            ></fa-icon
            >{{ broadcaster.status.outputs_count }}</span
        >
        <span
            *ngIf="
                !broadcaster.status?.inputs_count &&
                !broadcaster.status?.adaptives_count &&
                !broadcaster.status?.outputs_count
            "
            >-</span
        >`
})
export class ZxBroadcasterStreamsColComponent {
    @Input() broadcaster: Broadcaster;
}
