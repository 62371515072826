import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { AlertingRule } from "../events-management";
import { EventsManagementService } from "../events-management.service";

export interface UpdateRuleData {
    success: boolean;
    rule: AlertingRule;
}
@Component({
    selector: "app-edit-alerting-rule",
    templateUrl: "./edit-alerting-rule.component.html",
    styleUrls: ["./edit-alerting-rule.component.scss"],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class EditAlertingRuleComponent implements OnInit {
    @Input() rule: AlertingRule;
    @Input() alertingProfileId: number;
    @Input() ruleErrors: boolean;
    @Input() saveButton: boolean;
    @Input() isFormSubmitted: boolean;
    // used to prevent ngx-ui-tour duplicate anchors error
    @Input() firstRule?: boolean = false;
    @Input() disabled?: boolean;

    @Output() private ruleUpdate = new EventEmitter<UpdateRuleData>();
    ruleUniqId: string;
    deletingRule: boolean;
    savingRule: boolean;

    originalRule: AlertingRule;
    warningCheck: boolean;
    errorCheck: boolean;

    constructor(private eventsManagementService: EventsManagementService) {}

    ngOnInit(): void {
        this.ruleUniqId = this.rule.group + this.rule.object_type + this.rule.code;
        this.originalRule = Object.assign({}, this.rule);
        this.updateTresholdCheckboxes();
    }

    updateTresholdCheckboxes() {
        if (!this.rule.warning_threshold && this.rule.warning_threshold !== 0) this.warningCheck = false;
        else this.warningCheck = true;
        if (!this.rule.error_threshold && this.rule.error_threshold !== 0) this.errorCheck = false;
        else this.errorCheck = true;
    }

    warningCheckChange(b: boolean) {
        if (!b) {
            this.rule.warning_threshold = null;
            this.rule.state_warning_window = this.rule.state_error_window;
        } else {
            this.rule.warning_threshold = this.originalRule.warning_threshold;
            this.rule.state_warning_window = this.originalRule.state_error_window;
        }
    }

    errorCheckChange(b: boolean) {
        if (!b) {
            this.rule.error_threshold = null;
        } else {
            this.rule.warning_threshold = this.originalRule.warning_threshold;
            this.rule.state_warning_window = this.originalRule.state_error_window;
        }
    }

    thresholdConfigs(error?: boolean) {
        if (
            this.rule.error_threshold &&
            this.rule.warning_threshold &&
            this.rule.threshold_units !== "db" &&
            !this.rule.reverse_thresholds
        ) {
            if (error) {
                return {
                    min: this.rule.warning_threshold,
                    max: 100,
                    error: "must be between " + this.rule.warning_threshold + " and 100",
                    step: 1
                };
            } else {
                return {
                    min: 0,
                    max: this.rule.error_threshold,
                    error: "must be between 0 and " + this.rule.error_threshold,
                    step: 1
                };
            }
        } else if (
            this.rule.error_threshold &&
            this.rule.warning_threshold &&
            this.rule.threshold_units === "days" &&
            this.rule.reverse_thresholds
        ) {
            if (error) {
                return {
                    min: 0,
                    max: this.rule.warning_threshold,
                    error: "must be between 0 and " + this.rule.warning_threshold,
                    step: 1
                };
            } else {
                return {
                    min: this.rule.error_threshold,
                    max: 30,
                    error: "must be between " + this.rule.error_threshold + " and 30",
                    step: 1
                };
            }
        } else {
            switch (this.rule.threshold_units) {
                case "days":
                    return { min: 0, max: 30, error: "MUST_BE_BETWEEN_0_AND_30", step: 1 };
                case "db":
                    return { min: -95, max: -5, error: "MUST_BE_BETWEEN_-95_AND_-5", step: 5 };
                default:
                    return { min: 0, max: 100, error: "MUST_BE_BETWEEN_0_AND_100", step: 1 };
            }
        }
    }

    async resetRule() {
        this.deletingRule = true;
        const result = await this.eventsManagementService.deleteAlertingRule(this.alertingProfileId, this.rule.id);
        this.deletingRule = false;
        this.ruleUpdate.emit({ success: !!result, rule: result || this.rule });
    }

    async reloadRule() {
        this.rule = Object.assign({}, this.originalRule);
        this.ruleUpdate.emit({ success: true, rule: this.rule });
    }

    async saveRule() {
        this.savingRule = true;
        const model = this.eventsManagementService.convertRuleToServer(this.rule);
        const result = await this.eventsManagementService.addAlertingRule(this.alertingProfileId, model);
        if (result.error) this.rule.error = result.error;
        else this.rule = result;

        this.savingRule = false;
        this.ruleUpdate.emit({ success: !!result, rule: this.rule });
    }
}
