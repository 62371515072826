import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filter"
})
export class FilterPipe implements PipeTransform {
    transform<T>(items: T[], property: string | string[], value: string): T[] {
        if (property === "*") {
            return items.filter(item => !!item);
        }
        if (!items || !property || !value) {
            return items;
        }
        if (!Array.isArray(property))
            return items.filter(
                item =>
                    item[property] !== null &&
                    item[property].toString().toLowerCase().indexOf(value.toString().toLowerCase()) !== -1
            );
        else if (Array.isArray(property)) {
            const res: T[] = [];
            items.filter(item => {
                property.forEach(key => {
                    if (
                        getDescendantProp(item, key) &&
                        getDescendantProp(item, key).toLowerCase().indexOf(value.toString().toLowerCase()) !== -1
                    ) {
                        res.push(item);
                    }
                });
            });
            return res;
        } else return [];
    }
}

function getDescendantProp(obj, desc) {
    const arr = desc.split(".");

    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
}
