<div *ngIf="cluster.broadcasters && cluster.broadcasters?.length" class="d-flex">
    <table class="table table-nested table-hover table-sm mb-0">
        <thead>
            <tr>
                <th
                    class="ellipsis w-240px"
                    scope="col"
                    [ngClass]="{ 'd-none': !clusterSelected && !forceCollapse }"
                    sortable="name"
                    (sort)="onSort($event, 'NAME')"
                    [sortIf]="sortColumn === 'name'"
                    [sortDirection]="sortDirection"
                >
                    {{ "BROADCASTER" | translate }}
                </th>
                <th
                    class="ellipsis w-240px"
                    scope="col"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="name"
                    (sort)="onSort($event, 'NAME')"
                    [sortIf]="sortColumn === 'name'"
                    [sortDirection]="sortDirection"
                >
                    {{ "BROADCASTER" | translate }}
                </th>
                <th
                    class="ellipsis w-50px"
                    scope="col"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="status.cpu"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'status.cpu'"
                    [sortDirection]="sortDirection"
                >
                    {{ "CPU" | translate }}
                </th>
                <th
                    class="ellipsis w-50px"
                    scope="col"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="status.ram"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'status.ram'"
                    [sortDirection]="sortDirection"
                >
                    {{ "RAM" | translate }}
                </th>
                <th class="ellipsis w-50px" scope="col" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    {{ "HDD" | translate }}
                </th>
                <th
                    class="ellipsis w-100px"
                    scope="col"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="status.input_kbps"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'status.input_kbps'"
                    [sortDirection]="sortDirection"
                >
                    {{ "IN_BITRATE" | translate }}
                </th>
                <th
                    class="ellipsis w-100px"
                    scope="col"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="status.input_kbps"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'status.output_kbps'"
                    [sortDirection]="sortDirection"
                >
                    {{ "OUT_BITRATE" | translate }}
                </th>
                <th
                    class="ellipsis w-50px"
                    scope="col"
                    *ngIf="clusterHasGPUs(cluster)"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="recentLoad.nvidia_utilization"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'recentLoad.nvidia_utilization'"
                    [sortDirection]="sortDirection"
                >
                    {{ "GPU" | translate }}
                </th>
                <th
                    class="ellipsis w-50px"
                    scope="col"
                    *ngIf="clusterHasGPUs(cluster)"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="recentLoad.nvidia_mem_utilization"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'recentLoad.nvidia_mem_utilization'"
                    [sortDirection]="sortDirection"
                >
                    {{ "GPU_MEM" | translate }}
                </th>
                <th
                    class="ellipsis w-50px"
                    scope="col"
                    *ngIf="clusterHasGPUs(cluster)"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="recentLoad.nvidia_decoder_utilization"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'recentLoad.nvidia_decoder_utilization'"
                    [sortDirection]="sortDirection"
                >
                    {{ "GPU_DEC" | translate }}
                </th>
                <th
                    class="ellipsis w-50px"
                    scope="col"
                    *ngIf="clusterHasGPUs(cluster)"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="recentLoad.nvidia_encoder_utilization"
                    (sort)="onSort($event)"
                    [sortIf]="sortColumn === 'recentLoad.nvidia_encoder_utilization'"
                    [sortDirection]="sortDirection"
                >
                    {{ "GPU_ENC" | translate }}
                </th>
                <th class="ellipsis" scope="col" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    {{ "STREAMS" | translate }}
                </th>
                <th
                    class="ellipsis w-100px"
                    scope="col"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="status.up_time_seconds"
                    (sort)="onSort($event, 'UP_TIME')"
                    [sortIf]="sortColumn === 'status.up_time_seconds'"
                    [sortDirection]="sortDirection"
                >
                    {{ "UP_TIME" | translate }}
                </th>
                <th
                    class="ellipsis w-80px"
                    scope="col"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="is_backup"
                    (sort)="onSort($event, 'PRIMARY')"
                    [sortIf]="sortColumn === 'is_backup'"
                    [sortDirection]="sortDirection"
                >
                    {{ "PRIMARY" | translate }}
                </th>
                <th
                    class="ellipsis w-120px"
                    scope="col"
                    translate
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    sortable="status.ip"
                    (sort)="onSort($event, 'PUBLIC_IP')"
                    [sortIf]="sortColumn === 'status.ip'"
                    [sortDirection]="sortDirection"
                    translate
                >
                    PUBLIC_IP
                </th>
                <th class="ellipsis w-80px" scope="col" translate [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">VERSION</th>
                <th class="ellipsis w-100px" scope="col" translate [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">AGENTZ</th>
                <th class="ellipsis w-120px" scope="col" translate [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">LOCATION</th>
                <th class="ellipsis w-120px" scope="col" translate [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">NETWORK</th>
                <th class="w-50px" scope="col" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"></th>
            </tr>
        </thead>
        <tbody *ngIf="broadcasters$ | async">
            <tr
                *ngFor="let broadcaster of broadcasters$ | async"
                (click)="selectBroadcasterRows(broadcaster, cluster)"
                [ngClass]="{
                    selected: cluster.id === clusterSelected && broadcaster.id === broadcasterSelected
                }"
            >
                <td class="ellipsis" [ngClass]="{ 'd-none': !clusterSelected && !forceCollapse }">
                    <zx-broadcaster
                        [model]="broadcaster"
                        [showTag]="false"
                        [showLink]="false"
                        [showCluster]="false"
                        [showStatusIcon]="true"
                        [clusterSelected]="cluster.dns_prefix"
                        [searchTerm]="searchTermArray"
                    ></zx-broadcaster>
                </td>
                <td class="ellipsis" title="{{ broadcaster.name }}" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <zx-broadcaster
                        [model]="broadcaster"
                        [showTag]="false"
                        [showLink]="false"
                        [showCluster]="false"
                        [showStatusIcon]="true"
                        [clusterSelected]="cluster.dns_prefix"
                        [searchTerm]="searchTermArray"
                    ></zx-broadcaster>
                    <a
                        *ngIf="broadcaster.name"
                        href="javascript:void(0)"
                        class="text-primary ms-1"
                        (click)="copyPassword(broadcaster.name); $event.stopPropagation()"
                        title="{{ 'COPY' | translate }}"
                    >
                        <fa-icon icon="clipboard" size="sm"></fa-icon>
                        <span class="sr-only">{{ "COPY" | translate }}</span>
                    </a>
                    <span *ngIf="!broadcaster.name">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span
                        *ngIf="broadcaster.status?.cpu || broadcaster.status?.cpu === 0"
                        [ngClass]="{
                            'status-good': broadcaster.status?.cpu < 50,
                            'status-warning': broadcaster.status?.cpu >= 50 && broadcaster.status?.cpu < 75,
                            'status-error': broadcaster.status?.cpu >= 75
                        }"
                        >{{ broadcaster.status?.cpu / 100 | percent : "1.0-1" }}</span
                    >
                    <span *ngIf="!broadcaster.status?.cpu && broadcaster.status?.cpu !== 0">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span
                        *ngIf="broadcaster.status?.ram || broadcaster.status?.ram === 0"
                        [ngClass]="{
                            'status-good': broadcaster.status?.ram < 50,
                            'status-warning': broadcaster.status?.ram >= 50 && broadcaster.status?.ram < 75,
                            'status-error': broadcaster.status?.ram >= 75
                        }"
                    >
                        {{ broadcaster.status?.ram / 100 | percent : "1.0-1" }}
                    </span>
                    <span *ngIf="!broadcaster.status?.ram && broadcaster.status?.ram !== 0">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span
                        *ngIf="broadcaster.status && broadcaster.status?.disk_space != null"
                        [ngClass]="{
                            'status-good': Math.max(broadcaster.status?.disk_space, broadcaster.status?.disk_space_install) < 90,
                            'status-warning':
                                Math.max(broadcaster.status?.disk_space, broadcaster.status?.disk_space_install) >= 90 &&
                                Math.max(broadcaster.status?.disk_space, broadcaster.status?.disk_space_install) < 95,
                            'status-error': Math.max(broadcaster.status?.disk_space, broadcaster.status?.disk_space_install) >= 95
                        }"
                    >
                        {{ Math.max(broadcaster.status?.disk_space, broadcaster.status?.disk_space_install) / 100 | percent : "1.0-1" }}
                    </span>
                    <span *ngIf="broadcaster.status?.disk_space == null">-</span>
                </td>
                <td class="ellipsis" *ngIf="clusterHasGPUs(cluster)" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span
                        *ngIf="broadcaster.recentLoad?.nvidia_utilization || broadcaster.recentLoad?.nvidia_utilization === 0"
                        [ngClass]="{
                            'status-good': broadcaster.recentLoad?.nvidia_utilization < 50,
                            'status-warning': broadcaster.recentLoad?.nvidia_utilization >= 50 && broadcaster.recentLoad?.nvidia_utilization < 75,
                            'status-error': broadcaster.recentLoad?.nvidia_utilization >= 75
                        }"
                        >{{ broadcaster.recentLoad?.nvidia_utilization / 100 | percent : "1.0-1" }}</span
                    >
                    <span *ngIf="!broadcaster.recentLoad?.nvidia_utilization && broadcaster.recentLoad?.nvidia_utilization !== 0">-</span>
                </td>
                <td class="ellipsis" *ngIf="clusterHasGPUs(cluster)" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span
                        *ngIf="broadcaster.recentLoad?.nvidia_mem_utilization || broadcaster.recentLoad?.nvidia_mem_utilization === 0"
                        [ngClass]="{
                            'status-good': broadcaster.recentLoad?.nvidia_mem_utilization < 50,
                            'status-warning': broadcaster.recentLoad?.nvidia_mem_utilization >= 50 && broadcaster.recentLoad?.nvidia_mem_utilization < 75,
                            'status-error': broadcaster.recentLoad?.nvidia_mem_utilization >= 75
                        }"
                        >{{ broadcaster.recentLoad?.nvidia_mem_utilization / 100 | percent : "1.0-1" }}</span
                    >
                    <span *ngIf="!broadcaster.recentLoad?.nvidia_mem_utilization && broadcaster.recentLoad?.nvidia_mem_utilization !== 0">-</span>
                </td>
                <td class="ellipsis" *ngIf="clusterHasGPUs(cluster)" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span
                        *ngIf="broadcaster.recentLoad?.nvidia_decoder_utilization || broadcaster.recentLoad?.nvidia_decoder_utilization === 0"
                        [ngClass]="{
                            'status-good': broadcaster.recentLoad?.nvidia_decoder_utilization < 50,
                            'status-warning':
                                broadcaster.recentLoad?.nvidia_decoder_utilization >= 50 && broadcaster.recentLoad?.nvidia_decoder_utilization < 75,
                            'status-error': broadcaster.recentLoad?.nvidia_decoder_utilization >= 75
                        }"
                        >{{ broadcaster.recentLoad?.nvidia_decoder_utilization / 100 | percent : "1.0-1" }}</span
                    >
                    <span *ngIf="!broadcaster.recentLoad?.nvidia_decoder_utilization && broadcaster.recentLoad?.nvidia_decoder_utilization !== 0">-</span>
                </td>
                <td class="ellipsis" *ngIf="clusterHasGPUs(cluster)" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span
                        *ngIf="broadcaster.recentLoad?.nvidia_encoder_utilization || broadcaster.recentLoad?.nvidia_encoder_utilization === 0"
                        [ngClass]="{
                            'status-good': broadcaster.recentLoad?.nvidia_encoder_utilization < 50,
                            'status-warning':
                                broadcaster.recentLoad?.nvidia_encoder_utilization >= 50 && broadcaster.recentLoad?.nvidia_encoder_utilization < 75,
                            'status-error': broadcaster.recentLoad?.nvidia_encoder_utilization >= 75
                        }"
                        >{{ broadcaster.recentLoad?.nvidia_encoder_utilization / 100 | percent : "1.0-1" }}</span
                    >
                    <span *ngIf="!broadcaster.recentLoad?.nvidia_encoder_utilization && broadcaster.recentLoad?.nvidia_encoder_utilization !== 0">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span *ngIf="broadcaster.status?.input_kbps || broadcaster.status?.input_kbps === 0">
                        {{ sharedService.getKBPSContent(broadcaster.status?.input_kbps) }}<span class="unit">kbps</span>
                    </span>
                    <span *ngIf="!broadcaster.status?.input_kbps && broadcaster.status?.input_kbps !== 0">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span *ngIf="broadcaster.status?.output_kbps || broadcaster.status?.output_kbps === 0">
                        {{ sharedService.getKBPSContent(broadcaster.status?.output_kbps) }}<span class="unit">kbps</span>
                    </span>
                    <span *ngIf="!broadcaster.status?.output_kbps && broadcaster.status?.output_kbps !== 0">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span *ngIf="broadcaster.status?.inputs_count" class="me-2" title="{{ 'INPUTS' | translate }}"
                        ><fa-icon
                            [ngStyle]="{
                                'margin-right.px': 1
                            }"
                            icon="video"
                            size="sm"
                        ></fa-icon
                        >{{ broadcaster.status.inputs_count }}</span
                    >
                    <span *ngIf="broadcaster.status?.adaptives_count" class="me-2" title="{{ 'ADAPTIVE_GROUPS' | translate }}"
                        ><fa-icon
                            [ngStyle]="{
                                'margin-right.px': 1
                            }"
                            icon="cogs"
                            size="sm"
                        ></fa-icon
                        >{{ broadcaster.status.adaptives_count }}</span
                    >
                    <span *ngIf="broadcaster.status?.outputs_count" title="{{ 'OUTPUTS' | translate }}"
                        ><fa-icon
                            [ngStyle]="{
                                'margin-right.px': 1
                            }"
                            icon="sign-out-alt"
                            size="sm"
                        ></fa-icon
                        >{{ broadcaster.status.outputs_count }}</span
                    >
                    <span *ngIf="!broadcaster.status?.inputs_count && !broadcaster.status?.adaptives_count && !broadcaster.status?.outputs_count">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span *ngIf="broadcaster.status && broadcaster.status?.up_time_seconds">{{ broadcaster.status?.up_time_seconds | uptime }}</span>
                    <span *ngIf="!broadcaster.status || !broadcaster.status?.up_time_seconds">-</span>
                </td>
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span *ngIf="broadcaster.is_backup">{{ "NO" | translate }}</span>
                    <span *ngIf="!broadcaster.is_backup">{{ "YES" | translate }}</span>
                </td>
                <td class="ellipsis" title="{{ broadcaster.status?.ip }}" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <span *ngIf="broadcaster.status?.ip"
                        ><ngb-highlight [result]="broadcaster.status?.ip" [term]="searchTermArray"></ngb-highlight>&nbsp;<a
                            href="javascript:void(0)"
                            class="text-primary"
                            (click)="copyPassword(broadcaster.status?.ip); $event.stopPropagation()"
                            title="{{ 'COPY' | translate }}"
                        >
                            <fa-icon icon="clipboard" size="sm"></fa-icon>
                            <span class="sr-only">{{ "COPY" | translate }}</span>
                        </a></span
                    ><span *ngIf="!broadcaster.status?.ip">-</span>
                </td>
                <!-- Version -->
                <td
                    class="ellipsis"
                    [ngClass]="{ 'd-none': clusterSelected || forceCollapse }"
                    title="{{ versionPipe.transform(broadcaster.status?.about?.version || '') }}{{ broadcaster.can_transcode ? ' Transcoder' : '' }}"
                >
                    <ngb-highlight
                        *ngIf="broadcaster.status?.about?.version"
                        result="{{ versionPipe.transform(broadcaster.status?.about?.version || '') }}{{ broadcaster.can_transcode ? ' Transcoder' : '' }}"
                        [term]="searchTermArray"
                    ></ngb-highlight>
                    <span *ngIf="!broadcaster.status?.about?.version">-</span>
                </td>

                <!-- AgentZ -->
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <ngb-highlight
                        [result]="
                            !broadcaster.is_enabled
                                ? '-'
                                : bs.checkAgentZHasRecentReport(broadcaster) && broadcaster.agentz_installed
                                ? translate.instant('WORKING')
                                : !bs.checkAgentZHasRecentReport(broadcaster) && broadcaster.agentz_installed
                                ? translate.instant('NOT_REPORTING')
                                : !broadcaster.agentz_installed
                                ? translate.instant('NOT_INSTALLED')
                                : '-'
                        "
                        [term]="searchTermArray"
                    ></ngb-highlight>
                </td>

                <!-- Location -->
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <ngb-highlight
                        [result]="broadcaster.location | locationDisplayName"
                        [title]="broadcaster.location | locationDisplayName"
                        [term]="searchTermArray"
                    ></ngb-highlight>
                </td>

                <!-- Network -->
                <td class="ellipsis" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <ngb-highlight [result]="broadcaster.location | network" [title]="broadcaster.location | network" [term]="searchTermArray"></ngb-highlight>
                </td>
                <td class="text-end" [ngClass]="{ 'd-none': clusterSelected || forceCollapse }">
                    <a
                        href="javascript:void(0)"
                        class="me-2"
                        *ngIf="broadcaster.api_password"
                        (click)="copyPassword(broadcaster.api_password); $event.stopPropagation()"
                        title="{{ 'COPY_PASSWORD' | translate }}"
                    >
                        <fa-layers>
                            <fa-icon [icon]="'clipboard'" transform="shrink-2 up-3 left-3"></fa-icon>
                            <fa-icon [icon]="['far', 'lock']" transform="shrink-3 down-2 right-3"></fa-icon>
                        </fa-layers>
                        <span class="sr-only">{{ "COPY_PASSWORD" | translate }}</span>
                    </a>
                    <a
                        href="javascript:void(0)"
                        title="{{ 'OPEN' | translate }}"
                        [class.disabled]="!(broadcaster.state !== 'pending' && canEditBroadcaster(broadcaster))"
                        (click)="openLink(broadcaster); $event.stopPropagation()"
                    >
                        <fa-icon icon="external-link-alt" size="sm"></fa-icon>
                        <span class="sr-only">{{ "OPEN" | translate }}</span>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
