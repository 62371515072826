import { Component, Input } from "@angular/core";
import { ZecInput } from "src/app/models/shared";

@Component({
    selector: "app-zec-input-name-column",
    templateUrl: "./zec-input-name-column.component.html"
})
export class ZecInputNameColumnComponent {
    @Input() input: ZecInput;
}
