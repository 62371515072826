import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { isEqual } from "lodash";

@Component({
    selector: "zx-events-types-filter",
    templateUrl: "./zx-events-types-filter.component.html"
})
export class ZxEventsTypesFilterComponent implements OnInit, OnChanges {
    _filter: Record<string, boolean>;

    @Input() filter: Record<string, boolean>;

    @Output() filterChange: EventEmitter<Record<string, boolean>> = new EventEmitter<Record<string, boolean>>();

    ngOnInit() {
        this._filter = { ...this.filter };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filter) {
            const { currentValue, previousValue } = changes.filter;

            if (!isEqual(currentValue, previousValue)) {
                this._filter = { ...currentValue };
            }
        }
    }

    onFilterChange(): void {
        this.filterChange.emit(this._filter);
    }
}
