import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "./../constants/constants";

interface BillingServiceAPIResponse {
    success: boolean;
    valid: boolean;
    error?: string;
}

@Injectable({
    providedIn: "root"
})
export class BillingCodeValidationService {
    constants = Constants;
    constructor(private http: HttpClient) {}

    async checkBillingCodesImpl(
        code: string,
        password: string,
        version?: string,
        deviceType?: string,
        appType?: string,
        brand?: string
    ): Promise<boolean> {
        const result: BillingServiceAPIResponse = (await this.http
            .get(Constants.apiUrl + Constants.apiUrls.check_billing_code, {
                params: {
                    code,
                    auth: password,
                    version,
                    device_type: deviceType,
                    app_type: appType,
                    brand
                }
            })
            .toPromise()) as BillingServiceAPIResponse;

        if (!result.success) {
            throw new Error(result.error);
        }

        return result.valid;
    }
}
