import { Component, Input } from "@angular/core";
import { ReceiverOutput } from "src/app/models/shared";

@Component({
    selector: "app-receiver-output-column",
    templateUrl: "./receiver-output-name-column.component.html"
})
export class ReceiverOutputNameColumnComponent {
    @Input() output: ReceiverOutput;
}
