<div class="ellipsis d-inline" *ngIf="model">
    <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'ADAPTIVE_CHANNEL' | translate }}">C</span>
    <zx-status-full
        [icon]="showStatusIcon"
        [model]="model"
        [status]="status || (model.generalStatus | statusClass)"
        [showStatusText]="showStatusText"
    ></zx-status-full>
    <a *ngIf="showLink" [routerLink]="['/' + urls.channels, 'mediaconnect', model.id | uId, model.name]" (click)="$event.stopPropagation()"
        ><ngb-highlight [result]="model.name" [term]="searchTerm"></ngb-highlight>&nbsp;@&nbsp;<ngb-highlight
            [result]="model.region"
            [term]="searchTerm"
        ></ngb-highlight
    ></a>
    <span *ngIf="!showLink"
        ><ngb-highlight [result]="model.name" [term]="searchTerm"></ngb-highlight>&nbsp;@&nbsp;<ngb-highlight
            [result]="model.region"
            [term]="searchTerm"
        ></ngb-highlight
    ></span>
</div>
