<form id="editForm" #editForm="ngForm" (ngSubmit)="editForm.valid && onSubmit()" [ngClass]="{ loading: saving }">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div class="title">
                    <h1 *ngIf="state !== 'done'">
                        {{ "EDIT" | translate }} {{ type | translate }}<span *ngIf="type !== 'REMOTE_ACCESS'">s</span
                        ><span *ngIf="type === 'REMOTE_ACCESS'">es</span>
                    </h1>
                    <h1 *ngIf="state === 'done'">
                        {{ "EDIT" | translate }} {{ type | translate }}<span *ngIf="type !== 'REMOTE_ACCESS'">s</span
                        ><span *ngIf="type === 'REMOTE_ACCESS'">es</span> - {{ "COMPLETE" | translate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6">
                    <!-- Question -->
                    <p *ngIf="state !== 'done'">
                        {{ "EDITING_THE_FOLLOWING" | translate }} {{ objectsStore.length }} {{ type | translate | lowercase
                        }}<span *ngIf="type !== 'REMOTE_ACCESS'">s</span><span *ngIf="type === 'REMOTE_ACCESS'">es</span>:
                    </p>
                    <!-- Done -->
                    <p *ngIf="state === 'done' && hasUpdated">
                        {{ "THESE" | translate }} {{ type | translate | lowercase }}s {{ "WERE" | translate | lowercase }}
                        {{ "EDITED" | translate | lowercase }}:
                    </p>
                    <!-- Object List -->
                    <ul class="list-style-none mimic-tags" *ngIf="state !== 'done' || hasUpdated">
                        <li *ngFor="let object of objectsStore" [ngClass]="{ hide: object.hasError || object.ignored }">
                            <div *ngIf="!object.hasError && !object.ignored" class="ellipsis">
                                <app-dialog-object [object]="object.object" [type]="type"></app-dialog-object
                                ><span *ngIf="object.saving && object.processing === 'start'">
                                    &nbsp;-&nbsp;<span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>&nbsp;{{
                                        "PROCESSING" | translate
                                    }}...
                                </span>
                                <span *ngIf="!object.saving && !object.hasError && !object.ignored && object.processing === 'end'"
                                    >&nbsp;-&nbsp;<span>{{ "UPDATED" | translate | titlecase }}</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                    <!-- FORM FIELDS -->
                    <!-- Tag/Profile/Both -->
                    <div *ngIf="state === 'default'">
                        <!-- Access Tags -->
                        <div *ngIf="type !== 'BROADCASTER'" class="form-group mb-0">
                            <label for="resource_tag_ids" [ngClass]="{ 'is-invalid': editForm.submitted && editForm.controls.resource_tag_ids?.errors }"
                                >{{ "TAGS" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="ATContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #ATContent>{{ "TOOLTIP.TAGS" | translate }}</ng-template></label
                            >
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group">
                                        <div class="form-check form-check-inline mt-1">
                                            <input type="checkbox" class="form-check-input" id="updateTag" name="updateTag" [(ngModel)]="updateTag" />
                                            <label class="form-check-label" for="updateTag">{{ "UPDATE" | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-prepend">
                                    <fieldset class="form-group mb-2 me-3">
                                        <mat-button-toggle-group name="toggleTag" aria-label="toggleTag" [(ngModel)]="overwriteTag">
                                            <mat-button-toggle value="overwrite" [disabled]="!updateTag">{{ "OVERWRITE" | translate }}</mat-button-toggle>
                                            <mat-button-toggle value="add" [disabled]="!updateTag">{{ "ADD" | translate }}</mat-button-toggle>
                                            <mat-button-toggle value="remove" [disabled]="!updateTag">{{ "REMOVE" | translate }}</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="input-group">
                                <div class="input-group-div">
                                    <zx-access-tags-select
                                        name="resource_tag_ids"
                                        [formControl]="tagsControl"
                                        [(model)]="resourceTags"
                                        [required]="updateTag"
                                        type="resource"
                                        [ngClass]="{ 'is-invalid': editForm.submitted && editForm.controls.resource_tag_ids?.errors }"
                                        [disabled]="!updateTag"
                                    ></zx-access-tags-select>
                                    <div *ngIf="editForm.controls.resource_tag_ids?.invalid" class="invalid-feedback">
                                        <div *ngIf="editForm.controls.resource_tag_ids?.errors.required">{{ "AT_LEAST_1_TAG_IS_REQUIRED" | translate }}.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Alerting Profile -->
                        <div
                            class="form-group"
                            *ngIf="
                                type !== 'ROLE' &&
                                type !== 'TASK_SET' &&
                                type !== 'SSH_KEY' &&
                                type !== 'MAP' &&
                                type !== 'PID_MAPPING_PROFILE' &&
                                type !== 'REPORT' &&
                                type !== 'LIVE_EVENT'
                            "
                        >
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': editForm.submitted && editForm.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <div class="input-group align-items-center">
                                <div class="input-group-prepend">
                                    <div class="input-group">
                                        <div class="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="updateAlertProfile"
                                                name="updateAlertProfile"
                                                [(ngModel)]="updateAlertProfile"
                                            />
                                            <label class="form-check-label" for="updateAlertProfile">{{ "UPDATE" | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-div">
                                    <zx-alerting-profile-select
                                        name="alertingProfile"
                                        [(model)]="alertingProfile"
                                        [ngClass]="{ 'is-invalid': editForm.submitted && editForm.controls.alertingProfile?.errors }"
                                        [disabled]="!updateAlertProfile"
                                    ></zx-alerting-profile-select>
                                </div>
                            </div>
                        </div>
                        <!-- PID Mapping Profile -->
                        <div class="form-group" *ngIf="type === 'TARGET'">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': pidProfileControl?.errors }">{{ "PID_MAPPING_PROFILE" | translate }}</label>
                            <div class="input-group align-items-center">
                                <div class="input-group-prepend">
                                    <div class="input-group">
                                        <div class="form-check form-check-inline ps-0">
                                            <app-checkbox-field
                                                class="mb-0"
                                                [label]="'UPDATE' | translate"
                                                [formControl]="updatePidProfileControl"
                                                [id]="'updatePidProfileControl'"
                                            ></app-checkbox-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-div">
                                    <app-select-generic-field
                                        elementId="pid-mapping"
                                        title="{{ '' | translate }}"
                                        valueToBind="id"
                                        [formControl]="pidProfileControl"
                                        placeHolder="{{ 'SELECT' | translate }} {{ 'PID_MAPPING_PROFILE' | translate }}"
                                        [items]="pidMappingProfiles$ | async"
                                        [clearable]="true"
                                    >
                                    </app-select-generic-field>
                                </div>
                            </div>
                            <small *ngIf="!allObjectsSupportPidProfile">
                                {{ "TARGETS_DONT_SUPPORT_PID_MAPPING" | translate }}
                            </small>
                        </div>
                    </div>
                    <!-- Error -->
                    <p *ngIf="hasError">
                        {{ "CANT" | translate }} {{ "EDIT" | translate | lowercase }} {{ "THESE" | translate | lowercase }} {{ type | translate }}s:
                    </p>
                    <!-- Error List -->
                    <ul *ngIf="hasError" class="mb-0 list-style-none mimic-tags">
                        <li *ngFor="let object of objectsStore" [ngClass]="{ hide: !object.hasError }">
                            <div *ngIf="object.hasError">
                                <app-dialog-object [object]="object.object" [type]="type"></app-dialog-object>-&nbsp;<span
                                    class="status-error"
                                    title="{{ 'ERROR' | translate }}:&nbsp;{{ object.error.message }}"
                                    >{{ "ERROR" | translate }}:&nbsp;{{ object.error.message }}</span
                                >
                            </div>
                        </li>
                    </ul>
                    <!-- Note -->
                    <p *ngIf="note">
                        <span>{{ "NOTE" | translate }}: {{ note | translate }}</span>
                    </p>
                    <!-- Warning -->
                    <p *ngIf="warning">
                        <span>
                            <strong>{{ "WARNING" | translate }}:</strong> {{ warning | translate }}
                        </span>
                    </p>
                    <hr />
                    <div class="text-end">
                        <button
                            type="button"
                            class="btn btn-outline-secondary me-2"
                            [disabled]="saving"
                            (click)="activeModal.close(true)"
                            *ngIf="state !== 'done'"
                        >
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            ngbAutofocus
                            [disabled]="saving || (!updateTag && !updateAlertProfile && !updatePidProfileControl.value)"
                            zmid="form-submit"
                            type="submit"
                            *ngIf="state !== 'done'"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }} ({{ objectsStore.length }})<span
                                *ngIf="saving"
                                class="spinner-border spinner-border-sm ms-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </button>
                        <button type="button" class="btn btn-secondary" [disabled]="saving" (click)="activeModal.close()" *ngIf="state === 'done'">
                            {{ "CLOSE" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
