import {
    Component,
    OnInit,
    Input,
    OnChanges,
    EventEmitter,
    Output,
    SimpleChanges,
    ViewChildren,
    QueryList
} from "@angular/core";
import { NgbSortableHeader, SortDirection, SortEvent } from "src/app/directives/sortable.directive";
import { BroadcasterLsResult, BroadcasterLsFile, BroadcastersService } from "./../../broadcasters/broadcasters.service";

interface State {
    sortColumn: string;
    sortDirection: SortDirection;
}

@Component({
    selector: "zx-broadcaster-files",
    templateUrl: "./broadcaster-files.component.html"
})
export class ZxBroadcasterFilesComponent implements OnInit, OnChanges {
    @Input() broadcasterId: number;
    @Input() ngClass?: unknown;
    @Input() class?: string;
    @Input() required?: boolean;

    @Input() filePath: string;
    @Output() filePathChange = new EventEmitter();

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;

    selectedPath = "";
    loading = false;

    lsData: BroadcasterLsResult;

    private state: State = {
        sortColumn: "name",
        sortDirection: "asc"
    };

    constructor(private bs: BroadcastersService) {}

    ngOnInit() {
        this.ls();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.broadcasterId) {
            this.ls();
        }
    }

    async ls() {
        if (this.broadcasterId == null) return;

        this.loading = true;
        this.lsData = await this.bs.ls(this.broadcasterId, this.selectedPath);

        this.loading = false;
    }

    videoFiles(files: BroadcasterLsFile[]) {
        return (files || []).filter(f => f.name.endsWith(".ts"));
    }

    openFolder(folder: string) {
        this.selectedPath = this.selectedPath !== "" ? `${this.selectedPath}/${folder}` : folder;
        this.ls();
    }

    closeFolder() {
        this.selectedPath = this.selectedPath.split("/").slice(0, -1).join("/");
        this.ls();
    }

    selectFile(file: BroadcasterLsFile) {
        const value = this.selectedPath !== "" ? `${this.selectedPath}/${file.name}` : file.name;
        this.filePathChange.emit(value);
    }

    selectedFolder(folder: { name: string }): boolean {
        const splitPath = this.selectedPath.split("/").filter(f => f !== "");
        const splitFilePath = this.filePath?.split("/") || [];

        const samePrefix = splitPath.reduce((selected: boolean, f, index) => {
            if (index > splitFilePath.length) return (selected = false);
            return (selected = f !== splitFilePath[index]);
        }, true);

        return samePrefix && splitFilePath.length > splitPath.length && splitFilePath[splitPath.length] === folder.name;
    }

    selectedFile(file: BroadcasterLsFile): boolean {
        return this.filePath === (this.selectedPath !== "" ? `${this.selectedPath}/${file.name}` : file.name);
    }

    onSort({ column, direction }: SortEvent) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.sortColumn = column;
        this.sortDirection = direction;
    }

    get sortColumn() {
        return this.state.sortColumn;
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
    }
    get sortDirection() {
        return this.state.sortDirection;
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepFileData();
    }

    // TODO
    private prepFileData() {
        if (this.lsData) {
            const { sortColumn, sortDirection } = this.state;
            // Name
            if (sortColumn === "name") {
                // Folders
                if (sortDirection === "asc")
                    this.lsData.dirs = this.lsData.dirs.sort((a, b) => (a.name > b.name ? 1 : -1));
                else this.lsData.dirs = this.lsData.dirs.sort((a, b) => (a.name > b.name ? -1 : 1));
                // Files
                if (sortDirection === "asc")
                    this.lsData.files = this.lsData.files.sort((a, b) => (a.name > b.name ? 1 : -1));
                else this.lsData.files = this.lsData.files.sort((a, b) => (a.name > b.name ? -1 : 1));
            }
            // Size
            if (sortColumn === "size") {
                // Files
                if (sortDirection === "asc")
                    this.lsData.files = this.lsData.files.sort((a, b) => (a.size > b.size ? 1 : -1));
                else this.lsData.files = this.lsData.files.sort((a, b) => (a.size > b.size ? -1 : 1));
            }
            // Date
            if (sortColumn === "date") {
                // Files
                if (sortDirection === "asc")
                    this.lsData.files = this.lsData.files.sort((a, b) =>
                        new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1
                    );
                else
                    this.lsData.files = this.lsData.files.sort((a, b) =>
                        new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1
                    );
            }
        }
    }
}
