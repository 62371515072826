import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { AlertingProfile, AlertingRule } from "./events-management";
import { AuthService } from "../../../services/auth.service";
import { APIResponse } from "../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class EventsManagementService {
    alertingProfiles: Observable<AlertingProfile[]>;
    private alertingProfiles$: ReplaySubject<AlertingProfile[]>;
    private dataStore: {
        alertingProfiles: AlertingProfile[];
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            alertingProfiles: []
        };

        this.alertingProfiles$ = new ReplaySubject(1) as ReplaySubject<AlertingProfile[]>;
        this.alertingProfiles = this.alertingProfiles$.asObservable();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private prepAlertingProfile(alertingProfile: AlertingProfile) {} // eslint-disable-line @typescript-eslint/no-empty-function

    private updateStore(alertingProfile: AlertingProfile, merge: boolean): void {
        this.prepAlertingProfile(alertingProfile);

        const currentIndex = this.dataStore.alertingProfiles.findIndex(p => p.id === alertingProfile.id);
        if (currentIndex === -1) {
            this.dataStore.alertingProfiles.push(alertingProfile);
            return;
        } else if (merge) {
            const current = this.dataStore.alertingProfiles[currentIndex];

            Object.assign(current, alertingProfile);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.alertingProfiles[currentIndex] = alertingProfile;
        }
    }

    refreshAlertingProfiles(): Observable<AlertingProfile[]> {
        const alertingProfiles$ = this.http
            .get<APIResponse<AlertingProfile[]>>(Constants.apiUrl + Constants.apiUrls.alerting_profiles)
            .pipe(share());

        alertingProfiles$.subscribe({
            next: data => {
                const alertingProfiles: AlertingProfile[] = data.result;

                this.dataStore.alertingProfiles.forEach((existing, existingIndex) => {
                    const newIndex = alertingProfiles.findIndex(p => p.id === existing.id);
                    if (newIndex === -1) this.dataStore.alertingProfiles.splice(existingIndex, 1);
                });

                alertingProfiles.forEach(p => this.updateStore(p, true));

                this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);
            },
            // eslint-disable-next-line no-console
            error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_PID_MAPPING_PROFILES"), error)
        });
        return alertingProfiles$.pipe(map(r => r.result));
    }

    getAlertingProfile(id: number): Observable<AlertingProfile> {
        const alertingProfile$ = this.http
            .get<APIResponse<AlertingProfile>>(Constants.apiUrl + Constants.apiUrls.alerting_profiles + "/" + id)
            .pipe(share());
        return alertingProfile$.pipe(map(r => r.result));
    }

    setProfileWithOrderedRules(profile: AlertingProfile): AlertingProfile {
        //add property "name" to be sort by
        profile.alertingRules = this.sortRules(profile.alertingRules);
        return profile;
    }

    sortRules(rules: AlertingRule[]): AlertingRule[] {
        return rules
            .map(r => {
                r.name =
                    "" +
                    this.translate.instant(r.object_type.toUpperCase()) +
                    this.translate.instant(r.group.toUpperCase()) +
                    this.translate.instant(r.code.toUpperCase());

                return r;
            })
            .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
            });
    }

    async addAlertingProfile(model: Partial<AlertingProfile>) {
        try {
            const result = await this.http
                .post<APIResponse<AlertingProfile>>(Constants.apiUrl + Constants.apiUrls.alerting_profiles, model)
                .toPromise();
            const alertingProfile: AlertingProfile = result.result;

            this.updateStore(alertingProfile, false);

            this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);
            return alertingProfile;
        } catch (error) {
            return false;
        }
    }

    async deleteAlertingProfile(alertingProfile: AlertingProfile | number) {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(
                    Constants.apiUrl +
                        Constants.apiUrls.alerting_profiles +
                        "/" +
                        `${typeof alertingProfile === "number" ? alertingProfile : alertingProfile.id}`
                )
                .toPromise();

            const deletedId = result.result;
            const index = this.dataStore.alertingProfiles.findIndex(p => p.id === deletedId);
            if (index !== -1) this.dataStore.alertingProfiles.splice(index, 1);
            this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateAlertingProfile(alertingProfile: AlertingProfile | number, model: Partial<AlertingProfile>) {
        try {
            const result = await this.http
                .put<APIResponse<AlertingProfile>>(
                    Constants.apiUrl +
                        Constants.apiUrls.alerting_profiles +
                        "/" +
                        `${typeof alertingProfile === "number" ? alertingProfile : alertingProfile.id}`,
                    model
                )
                .toPromise();

            const updatedAlertingProfile: AlertingProfile = result.result;
            this.updateStore(updatedAlertingProfile, true);

            this.alertingProfiles$.next(Object.assign({}, this.dataStore).alertingProfiles);
            return result.success;
        } catch (error) {
            return false;
        }
    }

    // GET /api/alerting_profiles/:id/rules
    async getAlertingRules(profileID: number) {
        try {
            const result = await this.http
                .get<APIResponse<AlertingRule[]>>(
                    Constants.apiUrl + Constants.apiUrls.alerting_profiles + "/" + `${profileID}` + "/rules"
                )
                .toPromise();
            const rules: AlertingRule[] = result.result;
            return rules;
        } catch (error) {
            return false;
        }
    }

    // POST /api/alerting_profiles/:id/rules
    async addAlertingRule(profileID: number, model: Record<string, unknown>): Promise<AlertingRule> {
        try {
            const result = await this.http
                .post<APIResponse<AlertingRule>>(
                    Constants.apiUrl + Constants.apiUrls.alerting_profiles + "/" + `${profileID}` + "/rules",
                    model
                )
                .toPromise();
            const rules: AlertingRule = result.result;
            return rules;
        } catch (error) {
            return error.error;
        }
    }

    // DELETE /api/alerting_profiles/:id/rules/:rule_id
    async deleteAlertingRule(profileID: number, ruleID: number): Promise<AlertingRule | false> {
        try {
            const result = await this.http
                .delete<APIResponse<AlertingRule>>(
                    Constants.apiUrl +
                        Constants.apiUrls.alerting_profiles +
                        "/" +
                        `${profileID}` +
                        "/rules/" +
                        `${ruleID}`
                )
                .toPromise();
            return result.result;
        } catch (error) {
            return false;
        }
    }

    // /api/alerting_profiles?object_id=ID&object_type=TYPE
    async getEventAlertingProfile(id: number, type: string) {
        try {
            const result = await this.http
                .get<{ success: boolean; result: AlertingProfile[] }>(
                    Constants.apiUrl +
                        Constants.apiUrls.alerting_profiles +
                        "?object_id=" +
                        `${id}` +
                        "&object_type=" +
                        `${type}`
                )
                .toPromise();

            const alertingProfiles: AlertingProfile[] = result.result;
            return alertingProfiles[0];
        } catch (error) {
            return false;
        }
    }
    // /api/alerting_profiles/rules
    getDefaultRules() {
        const alertingRules$ = this.http
            .get<APIResponse<AlertingRule[]>>(Constants.apiUrl + Constants.apiUrls.alerting_profiles + "/rules/default")
            .pipe(share());
        return alertingRules$.pipe(map(r => r.result));
    }

    convertRuleToServer(rule: AlertingRule): Partial<AlertingRule> {
        return {
            code: rule.code,
            disabled: rule.disabled,
            error_notify: rule.error_notify,
            error_threshold: rule.error_threshold,
            group: rule.group,
            object_type: rule.object_type,
            ok_notify: rule.ok_notify,
            stateful: rule.stateful,
            state_error_window: rule.state_error_window,
            state_warning_window: rule.state_warning_window,
            warning_threshold: rule.warning_threshold,
            force_notify: rule.force_notify,
            error_escalation_delay: rule.error_escalation_delay,
            warning_escalation_delay: rule.warning_escalation_delay,
            error_deescalation_delay: rule.error_deescalation_delay,
            warning_deescalation_delay: rule.warning_deescalation_delay
        };
    }
}
