import { Component, Input } from "@angular/core";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "spinner-animation-inline",
    templateUrl: "./spinner-animation-inline.component.html"
})
export class SpinnerAnimationInlineComponent {
    @Input() spinner = true;
    @Input() placeholderText = "";
}
