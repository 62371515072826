import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    // isLoggedIn
    private loggedIn = new BehaviorSubject<boolean>(false);

    public loginEvent = this.loggedIn.pipe(filter(v => v));
    public logoutEvent = this.loggedIn.pipe(filter(v => !v));

    isLoggedIn$ = this.loggedIn.asObservable();

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }
    setLogin(val: boolean) {
        this.loggedIn.next(val);
    }
}
