<div class="form-group">
    <label *ngIf="!!label" [ngClass]="{ 'is-invalid': invalid }">
        {{ label }}
        <fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon>
        <fa-icon icon="info-circle" [ngbTooltip]="infoTooltipRef" triggers="hover click" [closeDelay]="500"></fa-icon>
        <ng-template #infoTooltipRef>{{ infoTooltip || "TOOLTIP.TAGS" | translate }}</ng-template></label
    >
    <ng-select
        placeholder="{{ 'SELECT' | translate }} {{ 'TAGS' | translate }}"
        bindLabel="name"
        [items]="tags$ | async"
        [loading]="loading"
        [multiple]="true"
        [clearable]="true"
        [hideSelected]="true"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        (change)="modelChanged($event)"
        (focus)="_onTouched()"
        [ngClass]="{ 'form-control is-invalid': invalid }"
    >
    </ng-select>
    <app-input-errors-indicator
        zmid="validation-error"
        [errors]="ngControl?.errors"
        [fieldName]="'TAGS' | translate"
        [invalid]="invalid"
        [patternErrorMessage]="patternErrorMessage"
    ></app-input-errors-indicator>
</div>
