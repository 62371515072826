<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "CONFIGURATION_HELP.TITLE" | translate }}&nbsp;-&nbsp;{{ source.name }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="details-form">
            <div class="form-group row">
                <h5 class="col-12" translate>PROTOCOL</h5>
                <div class="col-12">
                    <span>{{ source.protocol | translate }}</span>
                </div>
            </div>
            <div *ngIf="source.protocol !== 'st2110-jpegxs'" class="form-group row">
                <h5 class="col-12" translate>HOST</h5>
                <div class="col-12">
                    <span *ngIf="mediaConnectFlow"
                        >{{ mediaConnectFlow.ingest_ip
                        }}<button
                            class="btn btn-sm btn-xs btn-copy"
                            (click)="copyString(mediaConnectFlow.ingest_ip)"
                            type="button"
                            title="{{ 'COPY' | translate }}"
                        >
                            <fa-icon icon="clipboard" size="md"></fa-icon></button
                    ></span>
                    <span *ngIf="mediaLiveChannel"
                        >{{ mediaLiveChannel.mediaconnect_flow_egress_ip
                        }}<button
                            class="btn btn-sm btn-xs btn-copy"
                            (click)="copyString(mediaLiveChannel.mediaconnect_flow_egress_ip)"
                            type="button"
                            title="{{ 'COPY' | translate }}"
                        >
                            <fa-icon icon="clipboard" size="md"></fa-icon></button
                    ></span>
                    <span *ngIf="!mediaConnectFlow && !mediaLiveChannel">-</span>
                </div>
            </div>
            <div *ngIf="source.protocol !== 'st2110-jpegxs'" class="form-group row">
                <h5 class="col-12" translate>PORT</h5>
                <div class="col-12">
                    <span
                        >{{ source.ingest_port
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copyString(source.ingest_port)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="md"></fa-icon></button
                    ></span>
                </div>
            </div>
            <div *ngIf="source.protocol === 'st2110-jpegxs'" class="form-group row">
                <h5 class="col-12" translate>VPC</h5>
                <div class="col-12" *ngFor="let v of source.vpc">
                    <span
                        >{{ v.name }}@{{ v.ip
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copyString(v.ip)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="md"></fa-icon></button
                    ></span>
                </div>
            </div>
            <div *ngIf="source.protocol === 'st2110-jpegxs'" class="form-group row">
                <h5 class="col-12" translate>STREAMS</h5>
                <div class="col-12" *ngFor="let s of source.mediaStreams">
                    <span
                        >{{ s.name }}({{ s.stream_type }}):{{ s.port
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copyString(s.port)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="md"></fa-icon></button
                    ></span>
                </div>
            </div>
            <div class="form-group row" *ngIf="source.protocol === 'zixi-push'">
                <h5 class="col-12" translate>STREAM_ID</h5>
                <div class="col-12">
                    <span
                        >{{ source.stream_id
                        }}<button class="btn btn-sm btn-xs btn-copy" (click)="copyString(source.stream_id)" type="button" title="{{ 'COPY' | translate }}">
                            <fa-icon icon="clipboard" size="md"></fa-icon></button
                    ></span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
