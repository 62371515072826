import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Base } from "@zixi/models";

@Component({
    selector: "app-zx-link-text",
    template: `
        <a href="{{ link }}" target="{{ target }}" [ngClass]="{ 'd-none': hideLink }">
            <fa-icon icon="external-link-alt" size="sm" [ngClass]="{ 'd-none': hideIcon }"></fa-icon>
            {{ text }}
        </a>
    `
})
export class ZxLinkTextComponent {
    @Input() row: Base;
    @Input() link: string;
    @Input() text? = this.translate.instant("OPEN");
    @Input() target? = "_blank";
    @Input() hideLink? = false;
    @Input() hideIcon? = false;

    constructor(private translate: TranslateService) {}
}
