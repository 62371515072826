<!-- Breadcrumbs -->
<div class="breadcrumb-section" *ngIf="shouldShowBreadcrumbs && breadcrumbsService.breadcrumbs$ | async as breadcrumbs">
    <div class="breadcrumb-container">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let i = index" [ngClass]="{ active: i === breadcrumbs.length - 1 }">
                <a *ngIf="breadcrumb.url && !(i === breadcrumbs.length - 1)" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
                <span *ngIf="!breadcrumb.url || i === breadcrumbs.length - 1">{{ breadcrumb.label }}</span>
            </li>
        </ol>
    </div>
</div>
