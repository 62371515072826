import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    toggle$ = new Subject<void>();
    //
    private isNavVisible$ = new BehaviorSubject<boolean>(true);
    get isNavVisible() {
        return this.isNavVisible$.asObservable();
    }
    setNavVisbility(val: boolean) {
        this.isNavVisible$.next(val);
    }

    private isNavExpanded$ = new BehaviorSubject<boolean>(true);
    get isNavExpanded() {
        return this.isNavExpanded$.asObservable();
    }
    setNavExpanded(val: boolean) {
        this.isNavExpanded$.next(val);
    }

    private isDarkMode$ = new BehaviorSubject<boolean>(false);
    get isDarkMode() {
        return this.isDarkMode$.asObservable();
    }
    setDarkMode(val: boolean) {
        const bodyTag = document.body;
        if (val) bodyTag.classList.add("dark");
        else bodyTag.classList.remove("dark");
        //
        localStorage.setItem("dark-mode", val.toString());
        //
        this.isDarkMode$.next(val);
    }
}
