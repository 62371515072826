import { Component, Input } from "@angular/core";

@Component({
    selector: "app-generic-detail",
    templateUrl: "./generic-detail.component.html"
})
export class GenericDetailComponent {
    @Input() header: string;
    @Input() content: string;
}
