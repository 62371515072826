import { Injectable } from "@angular/core";
import { MediaConnectFlow, Source } from "src/app/models/shared";
import {
    AdaptiveChannel,
    DeliveryChannel,
    SrtTarget,
    FailoverChannel,
    MediaLiveChannel,
    MediaLiveHttpTarget,
    NdiTarget,
    PublishingTarget,
    RistTarget,
    RtmpPushTarget,
    UdpRtpTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    MediaconnectCDITarget,
    MediaconnectJPEGXSTarget
} from "src/app/pages/channels/channel";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { Cluster } from "src/app/pages/clusters/cluster";
import { ClustersService } from "src/app/pages/clusters/clusters.service";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { ZecsService, ZecType } from "src/app/pages/zecs/zecs.service";
import { firstValueFrom, Observable } from "rxjs";
import { MediaConnectSourcesService } from "src/app/pages/sources/mediaconnect-sources.service";
import { LiveEventsService } from "src/app/modules/live-events/live-events.service";
import { IncidentsService } from "src/app/pages/incidents/incidents.service";
import { RemoteAccessService } from "src/app/pages/remote-access/remote-access.service";
import { EditRuleDialogData } from "./edit-rule-dialog.component";
import { NetworksService } from "src/app/pages/networks/networks.service";

export enum OBJECT_TYPE_NAME {
    SOURCE = "source", //
    ADAPTIVE_CHANNEL = "adaptive_channel",
    DELIVERY_CHANNEL = "delivery_channel",
    ZIXI_PULL_TARGET = "zixi_pull",
    ZIXI_PUSH_TARGET = "zixi_push",
    HTTP_PUBLISHING_TARGET = "publishing_target",
    RIST_TARGET = "rist",
    UDP_RTP_TARGET = "udp_rtp",
    RTMP_PUSH_TARGET = "rtmp_push",
    SRT_TARGET = "srt_targets",
    NDI_TARGET = "ndi_targets",
    MEDIALIVE_HTTP_TARGET = "medialive_http_targets",
    MEDIA_LIVE_CHANNEL = "medialive_channel",
    FAIL_OVER_CHANNEL = "failover_channel",
    MEDIA_CONNECT_FLOWS = "mediaconnect_flows",
    MEDIA_CONNECT_ENTITLEMENT = "mediaconnect_entitlement_targets",
    MEDIA_CONNECT_CDI = "mediaconnect_cdi_targets",
    MEDIA_CONNECT_JPEGXS = "mediaconnect_jpegxs_targets"
}

export type OBJECT_TYPE =
    | Source
    | AdaptiveChannel
    | DeliveryChannel
    | ZixiPullTarget
    | ZixiPushTarget
    | PublishingTarget
    | RistTarget
    | UdpRtpTarget
    | RtmpPushTarget
    | SrtTarget
    | NdiTarget
    | MediaConnectFlow
    | FailoverChannel
    | MediaLiveChannel
    | MediaLiveHttpTarget
    | Cluster
    | MediaconnectJPEGXSTarget
    | MediaconnectCDITarget;

type UpdateObjectFunction = (object: OBJECT_TYPE, params: Record<string, unknown>) => Promise<OBJECT_TYPE | boolean>;

type GetObjectFunction = (ids: number[]) => Observable<OBJECT_TYPE[]>;

type FunctionMAP<T = UpdateObjectFunction | GetObjectFunction> = { [Key in OBJECT_TYPE_NAME]: T };

@Injectable({
    providedIn: "root"
})
export class EditRuleDialogService {
    constructor(
        private channelsService: ChannelsService,
        private targetsService: TargetsService,
        private clustersService: ClustersService,
        private sourcesService: SourcesService,
        private zecsService: ZecsService,
        private broadcastersService: BroadcastersService,
        private mediaConnectSourcesService: MediaConnectSourcesService,
        private liveEventsService: LiveEventsService,
        private networksService: NetworksService,
        private incidentsService: IncidentsService,
        private remoteAccessService: RemoteAccessService
    ) {}

    private readonly TYPE_TO_GET_FUNCTION_MAP: FunctionMAP<GetObjectFunction> = {
        [OBJECT_TYPE_NAME.SOURCE]: this.sourcesService.getSourcesNew.bind(this.sourcesService),
        [OBJECT_TYPE_NAME.ADAPTIVE_CHANNEL]: this.channelsService.getAdaptiveChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE_NAME.DELIVERY_CHANNEL]: this.channelsService.getDeliveryChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE_NAME.ZIXI_PULL_TARGET]: this.targetsService.getPullTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.ZIXI_PUSH_TARGET]: this.targetsService.getPushTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.HTTP_PUBLISHING_TARGET]: this.targetsService.getHttpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.RIST_TARGET]: this.targetsService.getRistTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.UDP_RTP_TARGET]: this.targetsService.getUdpRtpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.RTMP_PUSH_TARGET]: this.targetsService.getRtmpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.SRT_TARGET]: this.targetsService.getSrtTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.NDI_TARGET]: this.targetsService.getNdiTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_FLOWS]: this.channelsService.getMediaConnectFlowChannelsNew.bind(
            this.channelsService
        ),
        [OBJECT_TYPE_NAME.FAIL_OVER_CHANNEL]: this.channelsService.getFailoverChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE_NAME.MEDIA_LIVE_CHANNEL]: this.channelsService.getMediaLiveChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE_NAME.MEDIALIVE_HTTP_TARGET]: this.targetsService.getMediaLiveHTTPTargetsNew.bind(
            this.targetsService
        ),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_CDI]: this.targetsService.getCDITargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_JPEGXS]: this.targetsService.getJPEGXSTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_ENTITLEMENT]: this.targetsService.getEntitlementTargetsNew.bind(
            this.targetsService
        )
    };

    private readonly TYPE_TO_UPDATE_FUNCTION_MAP: FunctionMAP<UpdateObjectFunction> = {
        [OBJECT_TYPE_NAME.SOURCE]: this.sourcesService.updateSource.bind(this.sourcesService),
        [OBJECT_TYPE_NAME.ADAPTIVE_CHANNEL]: this.channelsService.updateChannel.bind(this.channelsService),
        [OBJECT_TYPE_NAME.DELIVERY_CHANNEL]: this.channelsService.updateChannel.bind(this.channelsService),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_FLOWS]: this.channelsService.updateChannel.bind(this.channelsService),
        [OBJECT_TYPE_NAME.FAIL_OVER_CHANNEL]: this.channelsService.updateChannel.bind(this.channelsService),
        [OBJECT_TYPE_NAME.MEDIA_LIVE_CHANNEL]: this.channelsService.updateChannel.bind(this.channelsService),
        [OBJECT_TYPE_NAME.ZIXI_PULL_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.ZIXI_PUSH_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.HTTP_PUBLISHING_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.RIST_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.UDP_RTP_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.RTMP_PUSH_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.SRT_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.NDI_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.MEDIALIVE_HTTP_TARGET]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_CDI]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_JPEGXS]: this.targetsService.updateTarget.bind(this.targetsService),
        [OBJECT_TYPE_NAME.MEDIA_CONNECT_ENTITLEMENT]: this.targetsService.updateTarget.bind(this.targetsService)
    };

    async updateObjectAlertingProfile(
        { object_id, object_name, object_type }: EditRuleDialogData,
        params: Record<string, unknown>
    ) {
        if (["receiver", "zec", "feeder"].includes(object_type))
            return this.updateZecObject(object_type.toUpperCase() as ZecType, object_id, params);
        if (object_type === "broadcaster") return await this.updateBroadcastersObject(object_id, params);
        if (object_type === "mediaconnect_sources") return await this.updateMediaConnect(object_id, params);
        if (object_type === "live_events") return await this.updateLiveEvent(object_id, params);
        if (object_type === "incidents") return await this.updateIncident(object_id, params);
        if (object_type === "remote_access") return await this.updateRemoteAccesses(object_name, params);
        if (object_type === "networks") return await this.networksService.updateNetwork(object_id, params);

        const funcToGetObjects: GetObjectFunction = this.TYPE_TO_GET_FUNCTION_MAP[object_type];
        const objects$ = funcToGetObjects([object_id]);
        const objectToUpdate = (await firstValueFrom(objects$))[0];
        const updateFunction: UpdateObjectFunction = this.TYPE_TO_UPDATE_FUNCTION_MAP[object_type];
        await updateFunction(objectToUpdate, params);
    }

    private async updateZecObject(zecType: ZecType, id: number, params: Record<string, unknown>) {
        const zec = this.zecsService.getCachedZec(zecType, "", id);
        await this.zecsService.updateZec(zec.id, params, zecType);
    }

    private async updateBroadcastersObject(id: number, params: Record<string, unknown>) {
        const cluster = this.broadcastersService.getCachedBroadcaster(id).broadcaster_cluster;
        this.clustersService.updateCluster(cluster, params);
    }

    private async updateMediaConnect(id: number, params: Record<string, unknown>) {
        const mediaConnectSource = this.mediaConnectSourcesService.getCachedMediaConnectSource(null, id);
        await this.mediaConnectSourcesService.updateMediaConnectSource(mediaConnectSource, params);
    }

    private async updateLiveEvent(id: number, params: Record<string, unknown>) {
        await this.liveEventsService.updateLiveEvent(id, params);
    }

    private async updateIncident(id: number, params: Record<string, unknown>) {
        await this.incidentsService.updateIncident(id, params);
    }

    private async updateRemoteAccesses(name: string, params: Record<string, unknown>) {
        const remoteAccess = this.remoteAccessService.getCachedRemoteAccess(name);
        await this.remoteAccessService.updateRemoteAccess(remoteAccess, params);
    }
}
