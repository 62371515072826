import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIResponse } from "../../../models/shared";
import { ObjectTrackingClient as ObjectTrackingChange } from "@zixi/models";
import { Constants } from "../../../constants/constants";
import { of, Observable, firstValueFrom } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as _ from "lodash";

export type ObjectTrackingChangesGroup = {
    id: string;
    modifier: string;
    date: string;
    changes: ObjectTrackingChange[];
};

@Injectable({
    providedIn: "root"
})
export class ObjectTrackingService {
    constructor(private httpClient: HttpClient) {}

    getObjectChanges(objectType: string, objectId: number): Observable<ObjectTrackingChangesGroup[]> {
        const response$ = this.httpClient.get<APIResponse<ObjectTrackingChange[]>>(
            `${Constants.apiUrl}${Constants.apiUrls.object_tracking}/${objectType}/${objectId}`
        );
        return response$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(response.error);
                }
                return _.chain(response.result)
                    .groupBy("change_group_id")
                    .map(changes => ({
                        id: changes[0].change_group_id,
                        modifier: changes[0].user_name,
                        date: changes[0].created_at,
                        changes
                    }))
                    .orderBy(changeGroup => changeGroup.date, ["desc"])
                    .value();
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.error(error);
                return of([]);
            })
        );
    }

    isChangeRevertible(change: ObjectTrackingChange): boolean {
        return !_.isNil(change.revert_params);
    }

    async revertChanges(
        putEndpointUrl: string,
        changes: ObjectTrackingChange[],
        restart_confirmed: boolean | undefined
    ) {
        const requestBody = _.reduce(
            changes,
            (requestBody, change) => {
                if (!this.isChangeRevertible(change)) {
                    return requestBody;
                }
                return {
                    ...requestBody,
                    ...change.revert_params,
                    ...(typeof restart_confirmed === "boolean" && { restart_confirmed })
                };
            },
            {}
        );

        return firstValueFrom(
            this.httpClient.put<APIResponse<any>>(putEndpointUrl, requestBody).pipe(
                catchError(error => {
                    if (error.status === 428) {
                        return of(true);
                    }
                    throw new Error(error);
                })
            )
        );
    }
}
