import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import moment from "moment";
import * as momentTimezone from "moment-timezone";
import { Constants } from "src/app/constants/constants";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";
@Component({
    selector: "app-zx-date-picker",
    templateUrl: "./zx-date-picker.component.html",
    styleUrls: ["./zx-date-picker.component.scss"]
})
export class ZxDatePickerComponent extends CustomControlValueAccessor<string> {
    @Input() label: string;
    @Input() isParentFormSubmitted: boolean;
    @Input() allDates? = false;
    @Input() onlyFutureDates? = false;
    @Input() onlyPastDates? = false;
    @Input() maxView = "year";
    @Input() minView = "hour";
    @Input() minuteStep = 5;
    disabled = this.ngControl.disabled;
    private datePickCounter = 0;

    localTimezone = momentTimezone.tz.guess();
    showPicker: boolean;
    datePicked: string;

    private constants = Constants;

    constructor(public ngControl: NgControl, private cdr: ChangeDetectorRef) {
        super(ngControl);
    }

    togglePicker() {
        this.showPicker = true;
        this.cdr.detectChanges();
    }

    clearDate() {
        this.datePicked = null;
        this._onChange(null);
    }

    // Prevent key events except delete and backspace
    onlyDelete(event: KeyboardEvent) {
        event.preventDefault();
    }

    closePicker() {
        this.datePickCounter = 0;
        this.showPicker = false;
    }

    clickOutsidePicker() {
        this.datePickCounter = this.datePickCounter + 1;
        if (this.datePickCounter > 1) {
            this.closePicker();
        }
    }

    dateChanged(event: string) {
        if (event !== null) {
            const date = moment(event);
            this._onChange(date.toISOString());
            this.datePicked = date.format(this.constants.DATETIME_SELECTOR_FORMAT);
        }
    }

    // Accepts value from the form control
    // eslint-disable-next-line
    writeValue(dateISO: string) {
        const dateFormattedInLocalTime = dateISO
            ? moment(dateISO).format(this.constants.DATETIME_SELECTOR_FORMAT)
            : null;
        this.datePicked = dateFormattedInLocalTime;
    }
}
