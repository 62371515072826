import { DragDropModule } from "@angular/cdk/drag-drop";
import { EmptyExpr } from "@angular/compiler";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import _ from "lodash";
import { Subscription } from "rxjs";
import { Source } from "src/app/models/shared";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { SharedService } from "src/app/services/shared.service";

export type SelectedSourceType = {
    source: Source;
    base_pid?: number;
    program_number?: number;
    program_name?: string;
};

@Component({
    selector: "app-multiplex-source-selection",
    templateUrl: "./multiplex-source-select.component.html",
    styleUrls: ["./multiplex-source-select.component.scss"]
})
export class MultiplexSourceSelectionComponent implements OnInit, OnDestroy {
    @Input() formSubmitted: boolean;
    @Input() selectedSources: SelectedSourceType[] = [];
    @Input() targetBitrate: number;
    @Output() targetBitrateEvent = new EventEmitter<number>();

    sources: Source[] = [];

    availableSources: SelectedSourceType[] = [];

    sourceFilter: string;
    sourcesLoading = false;
    isEdit = false;
    page = 1;
    pageSize = 10;
    autoNumbering = false;
    basePid = false;
    increment = false;

    private sourcesSubscription: Subscription;

    constructor(private ss: SourcesService) {}

    ngOnInit(): void {
        // Sources
        this.sourcesLoading = true;
        this.ss.refreshSources(true);
        this.sourcesSubscription = this.ss.sources.subscribe((sources: Source[]) => {
            this.sources = sources?.filter(s => !s.is_hidden) ?? [];
            this.setAvailableSources();
            this.sourcesLoading = false;
        });
    }

    ngOnDestroy(): void {
        this.sourcesSubscription.unsubscribe();
    }

    selectSource(source: SelectedSourceType) {
        this.selectedSources.push({ ...source });

        const index = this.availableSources.findIndex(s => s.source.id === source.source.id);
        if (index >= 0) {
            this.availableSources.splice(index, 1);
        }

        this.setAvailableSources();
    }

    deselectSource(source: SelectedSourceType) {
        const index = this.selectedSources.findIndex(s => s.source.id === source.source.id);
        if (index >= 0) {
            this.selectedSources.splice(index, 1);
            this.setAvailableSources();
        }
    }

    targetBitrateChanged() {
        this.targetBitrateEvent.emit(this.targetBitrate);
    }

    private setAvailableSources() {
        this.availableSources = this.sources
            .filter(s => !this.selected(s))
            .map(s => ({ source: s, program_number: null, program_name: "", base_pid: null }));
    }

    private selected(source: Source) {
        return !!this.selectedSources.find(ss => ss.source.id === source.id);
    }
}
