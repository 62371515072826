import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { APIResponse } from "src/app/models/shared";
import { Constants } from "../../constants/constants";
import { MultiViewer } from "./multi-viewer.model";
import { firstValueFrom, ReplaySubject } from "rxjs";
import { AmazonAWS } from "../configuration/amazon-aws/amazon-aws";
import { AmazonAwsService } from "../configuration/amazon-aws/amazon-aws.service";
import { FilterPipe } from "../../pipes/filter.pipe";
import _ from "lodash";
import { TagLayoutType, TagOutputConfig } from "@zixi/tag-vs";

@Injectable({
    providedIn: "root"
})
export class MultiViewerService {
    private multiViewersSubject: ReplaySubject<MultiViewer[]> = new ReplaySubject(1);
    private multiViewers: MultiViewer[] = [];
    private awsAccounts: AmazonAWS[];
    get multiViewers$() {
        return this.multiViewersSubject.asObservable();
    }

    constructor(
        private httpClient: HttpClient,
        private translate: TranslateService,
        private awsService: AmazonAwsService,
        private filterPipe: FilterPipe
    ) {}

    public async refreshMultiViewers(): Promise<MultiViewer[]> {
        let multiViewers = [];
        try {
            const response = await firstValueFrom(
                this.httpClient.get<APIResponse<MultiViewer[]>>(Constants.apiUrl + Constants.apiUrls.multi_viewer)
            );
            if (!response.success) {
                throw new Error();
            }
            multiViewers = response.result;
            for (const multiViewer of multiViewers) {
                await this.addFrontData(multiViewer);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MULTI_VIEWERS"), error);
        }
        this.setMultiViewers(multiViewers);
        return multiViewers;
    }

    public async refreshMultiViewer(id: number): Promise<MultiViewer | null> {
        try {
            const response = await firstValueFrom(
                this.httpClient.get<APIResponse<MultiViewer>>(
                    Constants.apiUrl + Constants.apiUrls.multi_viewer + `/${id}`
                )
            );
            if (!response.success) {
                throw new Error();
            }
            await this.addFrontData(response.result);
            const multiViewers = this.multiViewers.map(multiViewer => {
                return multiViewer.id === id ? _.cloneDeep(response.result) : _.cloneDeep(multiViewer);
            });
            this.setMultiViewers(multiViewers);
            return response.result;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MULTI_VIEWERS"), error);
            return null;
        }
    }

    public async createMultiViewer(body: Partial<MultiViewer>): Promise<MultiViewer | null> {
        try {
            const response = await firstValueFrom(
                this.httpClient.post<APIResponse<MultiViewer>>(Constants.apiUrl + Constants.apiUrls.multi_viewer, body)
            );
            if (!response.success) {
                throw new Error(response.error);
            }
            await this.addFrontData(response.result);
            const multiViewers = _.cloneDeep(this.multiViewers);
            multiViewers.push(response.result);
            this.setMultiViewers(multiViewers);
            return response.result;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return null;
        }
    }

    public async updateMultiViewer(id: number, body: Partial<MultiViewer>): Promise<MultiViewer | null> {
        try {
            const response = await firstValueFrom(
                this.httpClient.put<APIResponse<MultiViewer>>(
                    Constants.apiUrl + Constants.apiUrls.multi_viewer + `/${id}`,
                    body
                )
            );
            if (!response.success) {
                throw new Error(response.error);
            }
            await this.addFrontData(response.result);
            const multiViewers = this.multiViewers.map(multiViewer => {
                return multiViewer.id === id ? _.cloneDeep(response.result) : _.cloneDeep(multiViewer);
            });
            this.setMultiViewers(multiViewers);
            return response.result;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return null;
        }
    }

    public async deleteMultiViewer(multiViewerId: number): Promise<boolean> {
        try {
            const response = await firstValueFrom(
                this.httpClient.delete<APIResponse<number>>(
                    Constants.apiUrl + Constants.apiUrls.multi_viewer + "/" + `${multiViewerId}`
                )
            );
            if (!response.success) {
                throw new Error(response.error);
            }
            const multiViewers = _.cloneDeep(this.multiViewers);
            _.remove(multiViewers, multiViewer => multiViewer.id === multiViewerId);
            this.setMultiViewers(multiViewers);
            return true;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return false;
        }
    }

    public async listLayouts(multiViewerId: number): Promise<TagLayoutType[]> {
        const response = await firstValueFrom(
            this.httpClient.get<APIResponse<TagLayoutType[]>>(
                Constants.apiUrl + Constants.apiUrls.multi_viewer + "/" + `${multiViewerId}/layouts`
            )
        );
        if (!response.success) {
            throw new Error(response.error);
        }

        return response.result;
    }

    public async listEncoders(multiViewerId: number): Promise<TagOutputConfig[]> {
        const response = await firstValueFrom(
            this.httpClient.get<APIResponse<TagOutputConfig[]>>(
                Constants.apiUrl + Constants.apiUrls.multi_viewer + "/" + `${multiViewerId}/encoders`
            )
        );
        if (!response.success) {
            throw new Error(response.error);
        }

        return response.result;
    }

    private async addFrontData(multiViewer: MultiViewer) {
        if (!_.isArray(this.awsAccounts)) {
            this.awsAccounts = await firstValueFrom(this.awsService.refreshAWSAccounts(true));
        }
        const accountName = _.isArray(this.awsAccounts)
            ? this.awsAccounts.find(awsAccount => awsAccount.id === multiViewer.aws_account_id)?.name
            : null;
        const prettyRegionName = this.filterPipe.transform(Constants.awsRegions, "value", multiViewer.region)[0]?.name;

        multiViewer._frontData = {
            accountName,
            prettyRegionName
        };
    }

    private setMultiViewers(multiViewers: MultiViewer[]) {
        this.multiViewers = multiViewers;
        this.multiViewersSubject.next(multiViewers);
    }
}
