import { Component, Input } from "@angular/core";

@Component({
    selector: "app-roles-permissions-column",
    templateUrl: "./roles-permissions-column.component.html"
})
export class RolesPermissionsColumnComponent {
    @Input() view: number;
    @Input() edit: number;
    @Input() notify: number;
}
