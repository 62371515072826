import { Component, Input } from "@angular/core";
import { Source } from "../../../models/shared";
import { Constants } from "../../../constants/constants";

@Component({
    selector: "zx-source-health",
    providers: [],
    templateUrl: "./zx-source-health.component.html"
})
export class ZxSourceHealthComponent {
    @Input() source?: Source;

    constants = Constants;
}
