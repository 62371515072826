<div [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">{{ "GENERATE_REPORT" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
        <div class="modal-body">
            <!-- Name -->
            <div class="form-group">
                <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                    >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                ></label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="{{ 'NAME' | translate }}"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                    [(ngModel)]="runName"
                    required
                    minlength="{{ minLength }}"
                    pattern="{{ constants.validators.name }}"
                    #name="ngModel"
                />
                <div *ngIf="name.invalid" class="invalid-feedback">
                    <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                    <div *ngIf="name.errors.minlength">
                        {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                    </div>
                    <div *ngIf="name.errors.pattern">{{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `</div>
                </div>
            </div>
            <!-- Time -->
            <fieldset class="form-group">
                <legend for="time">{{ "SELECT_TIME_PERIOD_BY" | translate }}</legend>
                <mat-button-toggle-group name="time" aria-label="time" [(ngModel)]="setTimeBy">
                    <mat-button-toggle value="period">{{ "PERIOD" | translate }}</mat-button-toggle>
                    <mat-button-toggle value="month">{{ "MONTH" | translate }}</mat-button-toggle>
                    <mat-button-toggle value="quarter">{{ "QUARTER" | translate }}</mat-button-toggle>
                    <mat-button-toggle value="range">{{ "CUSTOM_RANGE" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>
            <!-- Month -->
            <div class="form-group" *ngIf="setTimeBy === 'month'">
                <label for="month" [ngClass]="{ 'is-invalid': form.submitted && month.errors }">
                    {{ "MONTH" | translate }}
                </label>
                <ng-select
                    id="month"
                    name="month"
                    required
                    [items]="monthOptions"
                    [clearable]="false"
                    bindValue="value"
                    bindLabel="name"
                    placeholder="{{ 'SELECT_MONTH' | translate }}"
                    [(ngModel)]="selectedMonth"
                    #month="ngModel"
                    [ngClass]="{ 'form-control is-invalid': form.submitted && month.errors }"
                >
                </ng-select>
                <div *ngIf="month.invalid" class="invalid-feedback">
                    <div *ngIf="month.errors.required">{{ "MONTH" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
            </div>
            <!-- Quarter -->
            <div class="form-group" *ngIf="setTimeBy === 'quarter'">
                <label for="quarter" [ngClass]="{ 'is-invalid': form.submitted && quarter.errors }">
                    {{ "QUARTER" | translate }}
                </label>
                <ng-select
                    id="quarter"
                    name="quarter"
                    required
                    [items]="quarterOptions"
                    [clearable]="false"
                    bindValue="value"
                    bindLabel="name"
                    placeholder="{{ 'SELECT_QUARTER' | translate }}"
                    [(ngModel)]="selectedQuarter"
                    #quarter="ngModel"
                    [ngClass]="{ 'form-control is-invalid': form.submitted && quarter.errors }"
                >
                </ng-select>
                <div *ngIf="quarter.invalid" class="invalid-feedback">
                    <div *ngIf="quarter.errors.required">{{ "QUARTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
            </div>
            <!-- Period -->
            <div class="form-group" *ngIf="setTimeBy === 'period'">
                <label for="period" [ngClass]="{ 'is-invalid': form.submitted && period.errors }">
                    {{ "PERIOD" | translate }}
                </label>
                <ng-select
                    id="period"
                    name="period"
                    required
                    [items]="periodOptions"
                    [clearable]="false"
                    bindValue="value"
                    bindLabel="name"
                    placeholder="{{ 'SELECT_PERIOD' | translate }}"
                    [(ngModel)]="selectedPeriod"
                    #period="ngModel"
                    [ngClass]="{ 'form-control is-invalid': form.submitted && period.errors }"
                >
                </ng-select>
                <div *ngIf="period.invalid" class="invalid-feedback">
                    <div *ngIf="period.errors.required">{{ "PERIOD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
            </div>
            <!-- Custom Range -->
            <!-- From -->
            <div class="form-group position-relative" *ngIf="setTimeBy === 'range'">
                <label for="from" [ngClass]="{ 'is-invalid': form.submitted && from.errors }"
                    >{{ "FROM_DATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                ></label>
                <div class="input-group" [ngClass]="{ 'is-invalid': form.submitted && from.errors }">
                    <input
                        class="form-control"
                        id="from"
                        name="from"
                        (click)="toggleFromPicker()"
                        [(ngModel)]="fromDate"
                        #from="ngModel"
                        placeholder="{{ 'SELECT_FROM_DATE' | translate }}"
                        required
                        (keydown)="onlyDelete($event)"
                        autocomplete="off"
                        [ngClass]="{ 'is-invalid': form.submitted && from.errors }"
                    />
                    <button class="btn btn-outline-primary" (click)="toggleFromPicker()" type="button" title="{{ 'SELECT_FROM_DATE' | translate }}">
                        <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                    </button>
                </div>
                <div *ngIf="from.invalid" class="invalid-feedback">
                    <div *ngIf="from.errors.required">{{ "FROM_DATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
                <div class="picker-container absolute" *ngIf="showFromPicker" (clickOutside)="clickOutsideFromPicker()">
                    <div class="arrow"></div>
                    <div class="picker-content shadow-sm">
                        <fa-icon icon="times-circle" class="close-icon" (click)="closeFromPicker()"></fa-icon>
                        <zx-date-time-picker
                            name="fromDate"
                            [(ngModel)]="fromDateString"
                            (ngModelChange)="fromDateChanged($event)"
                            [allDates]="true"
                            [hideTimePicker]="true"
                        ></zx-date-time-picker>
                    </div>
                </div>
            </div>
            <!-- To -->
            <div class="form-group position-relative" *ngIf="setTimeBy === 'range'">
                <label for="to" [ngClass]="{ 'is-invalid': form.submitted && (to.errors || checkDate(fromDate, toDate)) }"
                    >{{ "TO_DATE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                ></label>
                <div class="input-group" [ngClass]="{ 'is-invalid': form.submitted && (to.errors || checkDate(fromDate, toDate)) }">
                    <input
                        class="form-control"
                        id="to"
                        name="to"
                        (click)="toggleToPicker()"
                        [(ngModel)]="toDate"
                        #to="ngModel"
                        placeholder="{{ 'SELECT_TO_DATE' | translate }}"
                        (keydown)="onlyDelete($event)"
                        autocomplete="off"
                        [ngClass]="{ 'is-invalid': form.submitted && (to.errors || checkDate(fromDate, toDate)) }"
                        required
                    />
                    <button class="btn btn-outline-primary" (click)="toggleToPicker()" type="button" title="{{ 'SELECT_TO_DATE' | translate }}">
                        <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                    </button>
                </div>
                <div *ngIf="form.submitted && checkDate(fromDate, toDate)" class="invalid-feedback">
                    <div>{{ "TO_DATE" | translate }} {{ "MUST_BE_AFTER" | translate }} {{ "FROM_DATE" | translate }}.</div>
                </div>
                <div *ngIf="to.invalid" class="invalid-feedback">
                    <div *ngIf="to.errors.required">{{ "TO_DATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
                <div class="picker-container absolute" *ngIf="showToPicker" (clickOutside)="clickOutsideToPicker()">
                    <div class="arrow"></div>
                    <div class="picker-content shadow-sm">
                        <fa-icon icon="times-circle" class="close-icon" (click)="closeToPicker()"></fa-icon>
                        <zx-date-time-picker
                            name="toDate"
                            [(ngModel)]="toDateString"
                            (ngModelChange)="toDateChanged($event)"
                            [allDates]="true"
                            [hideTimePicker]="true"
                        ></zx-date-time-picker>
                    </div>
                </div>
            </div>
            <!-- Timezone -->
            <div class="form-group mb-0">
                <label for="time_zone">
                    {{ "TIME_ZONE" | translate }}
                </label>
                <ng-select
                    id="time_zone"
                    name="time_zone"
                    bindLabel="text"
                    [items]="timeZones"
                    [clearable]="false"
                    placeholder="{{ 'SELECT' | translate }} {{ 'TIME_ZONE' | translate }}"
                    [(ngModel)]="selectedTimeZone"
                >
                </ng-select>
            </div>
            <app-error [marginBottom]="false" [marginTop]="true"></app-error>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()">
                <fa-icon icon="times"></fa-icon>
                {{ "CANCEL" | translate }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                ngbAutofocus
                [disabled]="saving"
                zmid="form-submit"
                type="submit"
                [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
            >
                <fa-icon icon="check" size="sm"></fa-icon>
                {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
            </button>
        </div>
    </form>
</div>
