import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as _ from "lodash";

import { LocationInfo } from "../../../pages/maps/map";
import { NominatimResponse } from "../../../pages/maps/map";
import { MapService } from "../../../pages/maps/map.service";
import { take } from "rxjs/operators";

@Component({
    selector: "zx-location-search",
    templateUrl: "./location-search.component.html"
})
export class LocationSearchComponent implements OnInit {
    @Input() model: LocationInfo;
    locationText = "";
    changeLocation = false;
    @Output() modelChange = new EventEmitter();
    //
    searchingLocation = false;
    searchLocationResults: NominatimResponse[];
    searchLocationVal: string = null;

    constructor(private mapService: MapService) {
        this.locationLookup = _.debounce(this.locationLookup, 500);
    }

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit(): void {
        if (this.model && this.model.display_name) this.locationText = this.model.display_name;
    }

    locationLookup(location: string) {
        this.searchingLocation = true;
        if (location.length > 3) {
            this.mapService
                .addressLookup(location)
                .pipe(take(1))
                .subscribe(results => {
                    this.searchLocationResults = results;
                    this.searchingLocation = false;
                });
        }
    }

    selectLocation(result: NominatimResponse) {
        this.model = new LocationInfo();
        this.locationText = result.displayName;
        this.model.latitude = result.latitude;
        this.model.longitude = result.longitude;
        this.model.display_name = result.displayName;
        this.model.source = "address";
        if (result.address.hamlet) this.model.city = result.address.hamlet;
        if (result.address.village) this.model.city = result.address.village;
        if (result.address.town) this.model.city = result.address.town;
        if (result.address.city) this.model.city = result.address.city;
        if (result.address.state) this.model.region_name = result.address.state;
        if (result.address.country) this.model.country_name = result.address.country;
        //
        this.modelChanged();
    }

    clearLocation() {
        this.locationText = "";
        this.model = null;
        this.modelChanged();
    }
}
