<ng-container *ngIf="sharedService.isTarget(object) && (object.adaptiveChannel || object.deliveryChannel || object.mediaconnectFlow)">
    <zx-adaptive
        *ngIf="object.adaptiveChannel"
        [model]="object.adaptiveChannel"
        [showLink]="true"
        [showStatusIcon]="true"
        [searchTerm]="searchTerm"
    ></zx-adaptive>
    <zx-delivery
        *ngIf="object.deliveryChannel"
        [model]="object.deliveryChannel"
        [showLink]="true"
        [showStatusIcon]="true"
        [searchTerm]="searchTerm"
    ></zx-delivery>
    <zx-mediaconnect-flow
        *ngIf="object.mediaconnectFlow"
        [model]="object.mediaconnectFlow"
        [showLink]="true"
        [showStatusIcon]="true"
        [searchTerm]="searchTerm"
    ></zx-mediaconnect-flow>
</ng-container>

<ng-container *ngIf="!sharedService.isTarget(object) || !(object.adaptiveChannel || object.deliveryChannel || object.mediaconnectFlow)">-</ng-container>
