import { ActiveState, ObjectState, ZixiPlus } from "../../models/shared";

export class Network extends ZixiPlus {
    _frontData?: {
        sortableStatus: string;
        lastRefresh: string;
    };
    id: number;
    network_id: string;
    isp: string;
    asn: number;
    country_code: string;
    country_name: string;
    region_code: string;
    region_name: string;
    seven_days_incidents: number;
    muted: number;
    muted_until: string;
    objectSummary: { good: number; bad: number; warning: number; unknown: number };
    activeStates: ActiveState[];
    objectState: ObjectState;
    active_mute: boolean;
    alerting_profile_id: number;
    created_at: string;
    updated_at: string;
}
