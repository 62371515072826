import { Component, Output, EventEmitter, HostBinding } from "@angular/core";

import { fromEvent } from "rxjs";
import { takeUntil, take, delay } from "rxjs/operators";

@Component({
    selector: "zx-list-resize",
    templateUrl: "./zx-list-resize.component.html"
})
export class ZxListResizeComponent {
    private minWidth = 380;

    @Output() resizing = new EventEmitter<boolean>();
    @HostBinding("class") class = "d-none d-lg-block";

    constructor() {
        this.resizing.emit(false);
    }

    startResizeDrag() {
        const mouseUp = fromEvent(window, "mouseup");
        let lastListPanelWidth = null;
        this.resizing.emit(true);

        fromEvent(window, "mousemove")
            .pipe(takeUntil(mouseUp.pipe(take(1))))
            .subscribe((event: MouseEvent) => {
                const leftContainer = document.getElementById("left-container");
                const outletContainer = document.getElementById("outlet-container");
                // Get offset
                const containerOffsetLeft = outletContainer.offsetLeft;
                // Get x-coordinate of pointer relative to container
                const pointerRelativeXpos = event.clientX - containerOffsetLeft;
                // Get window inner width
                const innerWidth = window.innerWidth;
                // Resize list
                lastListPanelWidth = leftContainer.style.flexBasis = this.listPanelWidth(
                    innerWidth,
                    pointerRelativeXpos
                );
            });

        mouseUp.pipe(take(1), delay(50)).subscribe(() => {
            localStorage.setItem("list-panel-width", lastListPanelWidth);
            this.resizing.emit(false);
        });
    }

    private listPanelWidth(innerWidth: number, pointerRelativeXpos: number) {
        const maxWidth = innerWidth / 2.5;
        return Math.max(this.minWidth, Math.min(maxWidth, pointerRelativeXpos)) + "px";
    }
}
