import { Component, Input } from "@angular/core";
import { Broadcaster } from "src/app/models/shared";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "zx-broadcaster-ip-col",
    template: `
        <span *ngIf="ip"
            ><ngb-highlight title="{{ ip }}" [result]="ip" [term]="searchTerm"></ngb-highlight
            ><a
                href="javascript:void(0)"
                class="text-primary ms-1"
                (click)="copyPassword(ip); $event.stopPropagation()"
                title="{{ 'COPY' | translate }}"
            >
                <fa-icon icon="clipboard" size="sm"></fa-icon>
                <span class="sr-only">{{ "COPY" | translate }}</span>
            </a></span
        ><span *ngIf="!ip">-</span>
    `
})
export class ZxBroadcasterIPColComponent {
    @Input() broadcaster: Broadcaster;
    @Input() ip: string;
    @Input() searchTerm: string | string[];

    constructor(private cbs: ClipboardService) {}

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }
}
