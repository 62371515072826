import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { share, map } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import {
    AmazonAWS,
    AmazonAWSRegions,
    AmazonAWSMediaConnectFlow,
    AmazonAWSRegionDetails,
    AmazonAWSIAMRole
} from "./amazon-aws";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class AmazonAwsService {
    awsAccounts: Observable<AmazonAWS[]>;
    private awsAccounts$: ReplaySubject<AmazonAWS[]>;
    private dataStore: {
        awsAccounts: AmazonAWS[];
    };

    private lastAWSAccountsRefresh: number;

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            awsAccounts: []
        };

        this.lastAWSAccountsRefresh = null;

        this.awsAccounts$ = new ReplaySubject<AmazonAWS[]>(1);
        this.awsAccounts = this.awsAccounts$.asObservable();
    }

    refreshAWSAccounts(force?: boolean): Observable<AmazonAWS[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastAWSAccountsRefresh <= 60000) return this.awsAccounts;
        this.lastAWSAccountsRefresh = _.now();

        const awsAccounts$ = this.http
            .get<{ succes: boolean; result: AmazonAWS[] }>(Constants.apiUrl + Constants.apiUrls.aws_accounts)
            .pipe(share());

        awsAccounts$.subscribe(
            data => {
                const awsAccounts = data.result;

                awsAccounts.forEach(refreshedAWSAccount => {
                    const currentAWSAccount = this.dataStore.awsAccounts.find(
                        feeder => feeder.id === refreshedAWSAccount.id
                    );
                    if (!currentAWSAccount) this.dataStore.awsAccounts.push(refreshedAWSAccount);
                    else Object.assign(currentAWSAccount, refreshedAWSAccount);
                });

                this.awsAccounts$.next(Object.assign({}, this.dataStore).awsAccounts);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_AWS_ACCOUNTS"), error)
        );
        return awsAccounts$.pipe(map(r => r.result));
    }

    async getAWSAccountRegions(accountId: number) {
        const result = await this.http
            .get<{ success: boolean; result: AmazonAWSRegions }>(
                Constants.apiUrl + Constants.apiUrls.aws_accounts + "/" + accountId + Constants.apiUrls.regions
            )
            .toPromise();
        return result.result;
    }

    async getAWSRegionDetails(accountId: number, region: string) {
        const result = await this.http
            .get<{ success: boolean; result: AmazonAWSRegionDetails }>(
                Constants.apiUrl +
                    Constants.apiUrls.aws_accounts +
                    "/" +
                    accountId +
                    Constants.apiUrls.regions +
                    "/" +
                    region
            )
            .toPromise();
        return result.result;
    }

    async getAWSAccountMediaConnectFlows(accountId: number, region: string) {
        const result = await this.http
            .get<{ success: boolean; result: AmazonAWSMediaConnectFlow[] }>(
                Constants.apiUrl +
                    Constants.apiUrls.aws_accounts +
                    "/" +
                    accountId +
                    Constants.apiUrls.mediaconnect +
                    "/" +
                    region +
                    Constants.apiUrls.flows
            )
            .toPromise();
        return result.result;
    }

    async getAWSAccountIAMRoles(accountId: number) {
        const result = await this.http
            .get<{ success: boolean; result: AmazonAWSIAMRole[] }>(
                Constants.apiUrl + Constants.apiUrls.aws_accounts + "/" + accountId + Constants.apiUrls.roles
            )
            .toPromise();
        return result.result;
    }
}
