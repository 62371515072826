import { Directive, OnInit, Renderer2, Input, ElementRef, Output, EventEmitter, NgZone } from "@angular/core";

@Directive({
    selector: "[appResizeColumn]"
})
export class ResizeColumnDirective implements OnInit {
    @Input("appResizeColumn") resizable: boolean;
    @Input() index: number;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() resize = new EventEmitter<number>();

    private startX: number;
    private startWidth: number;
    private column: HTMLElement;
    private table: HTMLElement;
    private pressed: boolean;
    private mouseUpWidth: number;
    listenerMouseMove: () => void;

    constructor(private renderer: Renderer2, private el: ElementRef, private ngZone: NgZone) {
        this.column = this.el.nativeElement;
    }

    ngOnInit() {
        if (this.resizable) {
            const row = this.renderer.parentNode(this.column);
            const thead = this.renderer.parentNode(row);
            this.table = this.renderer.parentNode(thead);

            const resizer = this.renderer.createElement("span");

            this.renderer.addClass(resizer, "resize-holder");
            this.renderer.appendChild(this.column, resizer);

            this.ngZone.runOutsideAngular(() => {
                this.renderer.listen(resizer, "mousedown", this.onMouseDown);
                this.renderer.listen("document", "mouseup", this.onMouseUp);
            });
        }
    }

    onMouseDown = (event: MouseEvent) => {
        event.preventDefault();
        this.pressed = true;
        this.startX = event.pageX;
        const cssWidth = parseInt(this.column.style.width.replace("px", ""));
        this.startWidth = cssWidth || this.column.offsetWidth;
        this.listenerMouseMove = this.renderer.listen(this.table, "mousemove", this.onMouseMove);
    };

    onMouseMove = (event: MouseEvent) => {
        const offset = 0;
        if (this.pressed && event.buttons) {
            this.renderer.addClass(this.table, "resizing");

            // Calculate width of column
            const width = this.startWidth + (event.pageX - this.startX - offset);

            // Set table header width
            this.renderer.setStyle(this.column, "width", `${width}px`);
            this.mouseUpWidth = width;
        }
    };

    onMouseUp = () => {
        if (this.pressed) {
            this.pressed = false;
            this.renderer.removeClass(this.table, "resizing");
            this.resize.emit(this.mouseUpWidth);
        }
        if (this.listenerMouseMove) {
            this.listenerMouseMove(); //un-listen
        }
    };
}
