import { Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CustomControlValueAccessor } from "../shared/custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-checkbox-field",
    templateUrl: "./checkbox-field.component.html"
})
export class CheckboxFieldComponent extends CustomControlValueAccessor<boolean> {
    @Input() label: string;
    @Input() id: string;
    @Input() isRestartRequiredTip: boolean;
    @Input() isParentFormSubmitted: boolean;
    @Input() underLabel?: string;
    @Input() marginBottomZero? = false;
    @Input() hasInfoCircle? = "";
    disabled = false;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }
}
