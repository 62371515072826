import { Pipe, PipeTransform } from "@angular/core";
import { BroadcasterInput } from "./../models/shared";

@Pipe({
    name: "broadcasterInputType"
})
export class BroadcasterInputTypePipe implements PipeTransform {
    transform(input: BroadcasterInput): string {
        if (input.type_string === "rtmp_pull" || input.type_string === "rtmp_push") return "RTMP";
        else return input.type_string;
    }
}
