<fa-icon icon="check-circle" class="status-good me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.good > 0"></fa-icon
><span class="me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.good > 0">{{ cluster.broadcastersSummary.good }}</span>
<fa-icon icon="exclamation-circle" class="status-warning me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.warning > 0"></fa-icon
><span class="me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.warning > 0">{{ cluster.broadcastersSummary.warning }}</span>
<fa-icon icon="minus-circle" class="status-bad me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.bad > 0"></fa-icon
><span class="me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.bad > 0">{{ cluster.broadcastersSummary.bad }}</span>
<fa-icon *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.acknowledged > 0" class="me-1" icon="check"></fa-icon>
<span class="me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.acknowledged > 0">{{
    cluster.broadcastersSummary.acknowledged
}}</span>
<fa-icon icon="ban" class="status-disabled me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.disabled > 0"></fa-icon>
<span class="me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.disabled > 0">{{ cluster.broadcastersSummary.disabled }}</span>
<fa-icon icon="dot-circle" class="status-disabled me-1" *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.pending > 0"></fa-icon>
<span *ngIf="cluster.broadcastersSummary != null && cluster.broadcastersSummary.pending > 0">{{ cluster.broadcastersSummary.pending }}</span>
<span
    *ngIf="
        !cluster.broadcastersSummary ||
        (!cluster.broadcastersSummary.good &&
            !cluster.broadcastersSummary.warning &&
            !cluster.broadcastersSummary.bad &&
            !cluster.broadcastersSummary.acknowledged &&
            !cluster.broadcastersSummary.disabled &&
            !cluster.broadcastersSummary.pending)
    "
    >-</span
>
