const isZodType = obj => {
  return typeof (obj === null || obj === void 0 ? void 0 : obj.safeParse) === 'function';
};
const isZodObject = obj => {
  if (typeof (obj === null || obj === void 0 ? void 0 : obj.passthrough) === 'function') {
    return true;
  }
  if (typeof (obj === null || obj === void 0 ? void 0 : obj.innerType) === 'function') {
    return isZodObject(obj === null || obj === void 0 ? void 0 : obj.innerType());
  }
  return false;
};
const isZodObjectStrict = obj => {
  return typeof (obj === null || obj === void 0 ? void 0 : obj.passthrough) === 'function';
};
const extractZodObjectShape = obj => {
  if (!isZodObject(obj)) {
    throw new Error('Unknown zod object type');
  }
  if ('innerType' in obj) {
    return extractZodObjectShape(obj.innerType());
  }
  return obj.shape;
};
const zodMerge = (objectA, objectB) => {
  if (isZodObjectStrict(objectA)) {
    if (isZodObjectStrict(objectB)) {
      return objectA.merge(objectB);
    }
    return objectA;
  }
  if (isZodObjectStrict(objectB)) {
    return objectB;
  }
  return Object.assign({}, objectA, objectB);
};
const checkZodSchema = (data, schema, {
  passThroughExtraKeys = false
} = {}) => {
  if (isZodType(schema)) {
    const result = schema.safeParse(data);
    if (result.success) {
      return {
        success: true,
        data: passThroughExtraKeys && typeof data === 'object' ? {
          ...data,
          ...result.data
        } : result.data
      };
    }
    return {
      success: false,
      error: result.error
    };
  }
  return {
    success: true,
    data: data
  };
};
const zodErrorResponse = error => {
  return {
    name: error.name,
    issues: error.issues
  };
};
const ContractNoBody = Symbol('ContractNoBody');
const isAppRoute = obj => {
  return 'method' in obj && 'path' in obj;
};
const initTsRest = () => initContract();
const recursivelyApplyOptions = (router, options) => {
  return Object.fromEntries(Object.entries(router).map(([key, value]) => {
    var _a, _b, _c;
    if (isAppRoute(value)) {
      return [key, {
        ...value,
        path: (options === null || options === void 0 ? void 0 : options.pathPrefix) ? options.pathPrefix + value.path : value.path,
        headers: zodMerge(options === null || options === void 0 ? void 0 : options.baseHeaders, value.headers),
        strictStatusCodes: (_a = value.strictStatusCodes) !== null && _a !== void 0 ? _a : options === null || options === void 0 ? void 0 : options.strictStatusCodes,
        validateResponseOnClient: (_b = value.validateResponseOnClient) !== null && _b !== void 0 ? _b : options === null || options === void 0 ? void 0 : options.validateResponseOnClient,
        responses: {
          ...(options === null || options === void 0 ? void 0 : options.commonResponses),
          ...value.responses
        },
        metadata: (options === null || options === void 0 ? void 0 : options.metadata) ? {
          ...(options === null || options === void 0 ? void 0 : options.metadata),
          ...((_c = value.metadata) !== null && _c !== void 0 ? _c : {})
        } : value.metadata
      }];
    } else {
      return [key, recursivelyApplyOptions(value, options)];
    }
  }));
};
const ContractPlainTypeRuntimeSymbol = Symbol('ContractPlainType');
const initContract = () => {
  return {
    router: (endpoints, options) => recursivelyApplyOptions(endpoints, options),
    query: args => args,
    mutation: args => args,
    responses: args => args,
    response: () => ContractPlainTypeRuntimeSymbol,
    body: () => ContractPlainTypeRuntimeSymbol,
    type: () => ContractPlainTypeRuntimeSymbol,
    otherResponse: ({
      contentType,
      body
    }) => ({
      contentType,
      body
    }),
    noBody: () => ContractNoBody
  };
};
const insertParamsIntoPath = ({
  path,
  params
}) => {
  return path.replace(/:([^/]+)/g, (_, p) => {
    return params[p] || '';
  }).replace(/\/\//g, '/');
};
const convertQueryParamsToUrlString = (query, json = false) => {
  const queryString = json ? encodeQueryParamsJson(query) : encodeQueryParams(query);
  return (queryString === null || queryString === void 0 ? void 0 : queryString.length) > 0 ? '?' + queryString : '';
};
const encodeQueryParamsJson = query => {
  if (!query) {
    return '';
  }
  return Object.entries(query).filter(([, value]) => value !== undefined).map(([key, value]) => {
    let encodedValue;
    if (typeof value === 'string' && !['true', 'false', 'null'].includes(value.trim()) && isNaN(Number(value))) {
      encodedValue = value;
    } else {
      encodedValue = JSON.stringify(value);
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(encodedValue)}`;
  }).join('&');
};
const encodeQueryParams = query => {
  if (!query) {
    return '';
  }
  return Object.keys(query).flatMap(key => tokeniseValue(key, query[key])).map(([key, value]) => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }).join('&');
};
const tokeniseValue = (key, value) => {
  if (Array.isArray(value)) {
    return value.flatMap((v, idx) => tokeniseValue(`${key}[${idx}]`, v));
  }
  if (value instanceof Date) {
    return [[`${key}`, value.toISOString()]];
  }
  if (value === null) {
    return [[`${key}`, '']];
  }
  if (value === undefined) {
    return [];
  }
  if (typeof value === 'object') {
    return Object.keys(value).flatMap(k => tokeniseValue(`${key}[${k}]`, value[k]));
  }
  return [[`${key}`, `${value}`]];
};
const parseJsonQueryObject = query => {
  return Object.fromEntries(Object.entries(query).map(([key, value]) => {
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch {
      parsedValue = value;
    }
    return [key, parsedValue];
  }));
};
class UnknownStatusError extends Error {
  constructor(response, knownResponseStatuses) {
    const expectedStatuses = knownResponseStatuses.join(',');
    super(`Server returned unexpected response. Expected one of: ${expectedStatuses} got: ${response.status}`);
    this.response = response;
  }
}
function getRouteResponses(router) {
  return {};
}
const tsRestFetchApi = async ({
  route,
  path,
  method,
  headers,
  body,
  validateResponse,
  fetchOptions
}) => {
  const result = await fetch(path, {
    ...fetchOptions,
    method,
    headers,
    body
  });
  const contentType = result.headers.get('content-type');
  if ((contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/')) && (contentType === null || contentType === void 0 ? void 0 : contentType.includes('json'))) {
    const response = {
      status: result.status,
      body: await result.json(),
      headers: result.headers
    };
    const responseSchema = route.responses[response.status];
    if ((validateResponse !== null && validateResponse !== void 0 ? validateResponse : route.validateResponseOnClient) && isZodType(responseSchema)) {
      return {
        ...response,
        body: responseSchema.parse(response.body)
      };
    }
    return response;
  }
  if (contentType === null || contentType === void 0 ? void 0 : contentType.includes('text/')) {
    return {
      status: result.status,
      body: await result.text(),
      headers: result.headers
    };
  }
  return {
    status: result.status,
    body: await result.blob(),
    headers: result.headers
  };
};
const createFormData = body => {
  const formData = new FormData();
  const appendToFormData = (key, value) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else {
      formData.append(key, JSON.stringify(value));
    }
  };
  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      for (const item of value) {
        appendToFormData(key, item);
      }
    } else {
      appendToFormData(key, value);
    }
  });
  return formData;
};
const normalizeHeaders = headers => {
  return Object.fromEntries(Object.entries(headers).map(([k, v]) => [k.toLowerCase(), v]));
};
const fetchApi = ({
  path,
  clientArgs,
  route,
  body,
  query,
  extraInputArgs,
  headers,
  fetchOptions
}) => {
  const apiFetcher = clientArgs.api || tsRestFetchApi;
  const combinedHeaders = {
    ...(clientArgs.baseHeaders && normalizeHeaders(clientArgs.baseHeaders)),
    ...normalizeHeaders(headers)
  };
  Object.keys(combinedHeaders).forEach(key => {
    if (combinedHeaders[key] === undefined) {
      delete combinedHeaders[key];
    }
  });
  let fetcherArgs = {
    route,
    path,
    method: route.method,
    headers: combinedHeaders,
    body: undefined,
    rawBody: body,
    rawQuery: query,
    contentType: undefined,
    validateResponse: clientArgs.validateResponse,
    fetchOptions: {
      ...(clientArgs.credentials && {
        credentials: clientArgs.credentials
      }),
      ...fetchOptions
    },
    ...((fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.signal) && {
      signal: fetchOptions.signal
    }),
    ...((fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.cache) && {
      cache: fetchOptions.cache
    }),
    ...(fetchOptions && 'next' in fetchOptions && !!(fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.next) && {
      next: fetchOptions.next
    })
  };
  if (route.method !== 'GET') {
    if (route.contentType === 'multipart/form-data') {
      fetcherArgs = {
        ...fetcherArgs,
        contentType: 'multipart/form-data',
        body: body instanceof FormData ? body : createFormData(body)
      };
    } else if (route.contentType === 'application/x-www-form-urlencoded') {
      fetcherArgs = {
        ...fetcherArgs,
        contentType: 'application/x-www-form-urlencoded',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          ...fetcherArgs.headers
        },
        body: typeof body === 'string' ? body : new URLSearchParams(body)
      };
    } else if (body !== null && body !== undefined) {
      fetcherArgs = {
        ...fetcherArgs,
        contentType: 'application/json',
        headers: {
          'content-type': 'application/json',
          ...fetcherArgs.headers
        },
        body: JSON.stringify(body)
      };
    }
  }
  return apiFetcher({
    ...fetcherArgs,
    ...extraInputArgs
  });
};
const evaluateFetchApiArgs = (route, clientArgs, inputArgs) => {
  const {
    query,
    params,
    body,
    headers,
    extraHeaders,
    overrideClientOptions,
    fetchOptions,
    cache,
    next,
    ...extraInputArgs
  } = inputArgs || {};
  const overriddenClientArgs = {
    ...clientArgs,
    ...overrideClientOptions
  };
  const completeUrl = getCompleteUrl(query, overriddenClientArgs.baseUrl, params, route, !!overriddenClientArgs.jsonQuery);
  return {
    path: completeUrl,
    clientArgs: overriddenClientArgs,
    route,
    body,
    query,
    extraInputArgs,
    fetchOptions: {
      ...(cache && {
        cache
      }),
      ...(next && {
        next
      }),
      ...fetchOptions
    },
    headers: {
      ...extraHeaders,
      ...headers
    }
  };
};
const getCompleteUrl = (query, baseUrl, params, route, jsonQuery) => {
  const path = insertParamsIntoPath({
    path: route.path,
    params: params
  });
  const queryComponent = convertQueryParamsToUrlString(query, jsonQuery);
  return `${baseUrl}${path}${queryComponent}`;
};
const getRouteQuery = (route, clientArgs) => {
  const knownResponseStatuses = Object.keys(route.responses);
  return async inputArgs => {
    const fetchApiArgs = evaluateFetchApiArgs(route, clientArgs, inputArgs);
    const response = await fetchApi(fetchApiArgs);
    if (!clientArgs.throwOnUnknownStatus) {
      return response;
    }
    if (knownResponseStatuses.includes(response.status.toString())) {
      return response;
    }
    throw new UnknownStatusError(response, knownResponseStatuses);
  };
};
const initClient = (router, args) => {
  return Object.fromEntries(Object.entries(router).map(([key, subRouter]) => {
    if (isAppRoute(subRouter)) {
      return [key, getRouteQuery(subRouter, args)];
    } else {
      return [key, initClient(subRouter, args)];
    }
  }));
};
class ResponseValidationError extends Error {
  constructor(appRoute, cause) {
    super(`[ts-rest] Response validation failed for ${appRoute.method} ${appRoute.path}: ${cause.message}`);
    this.appRoute = appRoute;
    this.cause = cause;
  }
}
const isAppRouteResponse = value => {
  return value != null && typeof value === 'object' && 'status' in value && typeof value.status === 'number';
};
const isAppRouteOtherResponse = response => {
  return response != null && typeof response === 'object' && 'contentType' in response;
};
const isAppRouteNoBody = response => {
  return response === ContractNoBody;
};
const validateResponse = ({
  appRoute,
  response
}) => {
  if (isAppRouteResponse(response)) {
    const responseType = appRoute.responses[response.status];
    const responseSchema = isAppRouteOtherResponse(responseType) ? responseType.body : responseType;
    const responseValidation = checkZodSchema(response.body, responseSchema);
    if (!responseValidation.success) {
      throw new ResponseValidationError(appRoute, responseValidation.error);
    }
    return {
      status: response.status,
      body: responseValidation.data
    };
  }
  return response;
};
class TsRestResponseError extends Error {
  constructor(route, response) {
    super();
    this.statusCode = response.status;
    this.body = response.body;
    this.name = this.constructor.name;
    if (typeof response.body === 'string') {
      this.message = response.body;
    } else if (typeof response.body === 'object' && response.body !== null && 'message' in response.body && typeof response.body.message === 'string') {
      this.message = response.body['message'];
    } else {
      this.message = 'Error';
    }
  }
}
export { ContractNoBody, ContractPlainTypeRuntimeSymbol, ResponseValidationError, TsRestResponseError, UnknownStatusError, checkZodSchema, convertQueryParamsToUrlString, encodeQueryParams, encodeQueryParamsJson, evaluateFetchApiArgs, extractZodObjectShape, fetchApi, getCompleteUrl, getRouteQuery, getRouteResponses, initClient, initContract, initTsRest, insertParamsIntoPath, isAppRoute, isAppRouteNoBody, isAppRouteOtherResponse, isAppRouteResponse, isZodObject, isZodObjectStrict, isZodType, parseJsonQueryObject, tsRestFetchApi, validateResponse, zodErrorResponse, zodMerge };