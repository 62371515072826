<ng-container *ngFor="let actionConfigs of actionsConfigs">
    <a
        [href]="actionConfigs.trustedUrl"
        class="{{ 'ms-3 ' + actionConfigs.cssClasses }}"
        [ngClass]="{ 'd-none': !(actionConfigs.isVisibleCallback ? actionConfigs.isVisibleCallback(row) : actionConfigs.isVisible) }"
        (click)="onClickHandler($event, actionConfigs)"
        [title]="actionConfigs.translateTitle | translate"
    >
        <fa-icon *ngIf="actionConfigs.isIconVisible" [icon]="actionConfigs.icon" [size]="actionConfigs.iconSize"></fa-icon
        ><span class="sr-only">{{ actionConfigs.translateTitle | translate }}</span
        ><span *ngIf="actionConfigs.isTextVisible" class="ms-1">{{ actionConfigs.translateTitle | translate }}</span>
    </a>
</ng-container>
