import { Injectable } from "@angular/core";
import { LoginService } from "../../login/login.service";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/constants/constants";
@Injectable({
    providedIn: "root"
})
export class SharedLoginService {
    private _isLoggedIn = false;
    private _sharedPageRoute: string;
    private _token: string;
    private readonly TOKEN_QUERY_PARAM = "token";

    constructor(private loginService: LoginService) {}

    get isInitiated(): boolean {
        return Boolean(this._token && this._sharedPageRoute);
    }

    get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    init(sharedPageURL: string) {
        const url = new URL(sharedPageURL);
        this._token = url.searchParams.get(this.TOKEN_QUERY_PARAM);
        this._sharedPageRoute = this.getRelativeURLWithoutToken(url);
    }

    isSharedRoute(url: string): boolean {
        const sharedRoutes = Object.values(Constants.urls.shared);
        return Boolean(sharedRoutes.find(sharedRoute => url.includes(sharedRoute)));
    }

    async login(password: string): Promise<string | null> {
        try {
            if (!this._token || !this._sharedPageRoute) throw new Error();
            const res = await this.loginService.login("jwtToken", password, this._token);
            if (!res.valid_user) throw new Error();
            this._isLoggedIn = true;
            return this._sharedPageRoute;
        } catch (error) {
            this._isLoggedIn = false;
            return null;
        }
    }

    setLogout() {
        this._isLoggedIn = false;
    }

    private getRelativeURLWithoutToken(url: URL) {
        url.searchParams.delete(this.TOKEN_QUERY_PARAM);
        return url.pathname + url.search;
    }
}
