import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

import { firstValueFrom, Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { Constants } from "../../constants/constants";
import { AuthService } from "../../services/auth.service";

import { SSO } from "./sso";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { AccountSettings } from "src/app/services/settings.service";
import { ReCaptchaV3Service } from "ng-recaptcha";

export interface LoginResponse {
    csrf: string;
    dns_prefix: string;
    message: string;
    must_reset: number;
    valid_user: boolean;
    redirect?: string;
}

@Injectable({
    providedIn: "root"
})
export class LoginService {
    redirectUrl: string;
    ssos: Observable<SSO[]>;
    settings: Observable<AccountSettings>;

    private recaptchaV3Service = inject(ReCaptchaV3Service);
    private ssos$: ReplaySubject<SSO[]>;
    private dataStore: {
        ssos: SSO[];
    };

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService
    ) {
        this.reset();
    }

    private reset() {
        this.dataStore = {
            ssos: []
        };

        this.ssos$ = new ReplaySubject(1) as ReplaySubject<SSO[]>;
        this.ssos = this.ssos$.asObservable();
    }

    async login(username: string, password: string, token: string = null) {
        const recaptchaKey = await firstValueFrom(this.recaptchaV3Service.execute("login"));
        let headers = new HttpHeaders({});
        headers = headers.set(
            "dns_prefix",
            location.hostname === "localhost" ? "zixi" : location.hostname.split(".")[0]
        );
        if (token) {
            headers = headers.set("Authorization", token);
        }

        const postData = {
            username,
            password,
            recaptchaKey
        };

        return await firstValueFrom(
            this.http.post<LoginResponse>(Constants.apiUrl + Constants.apiUrls.login, postData, { headers })
        );
    }

    logout(clearRedirect?: boolean) {
        this.mixpanelService.logout();
        if (clearRedirect) this.redirectUrl = null;
        this.authService.setLogin(false);
        return this.http.get(Constants.apiUrl + Constants.apiUrls.logout).subscribe();
    }

    listSSOs(): Observable<SSO[]> {
        const ssos$ = this.http
            .get<{ result: SSO[]; success: boolean }>(Constants.apiUrl + Constants.apiUrls.ssos)
            .pipe(
                map(data => data.result),
                share()
            );

        ssos$.subscribe(
            ssos => {
                this.dataStore.ssos = ssos;
                this.ssos$.next(Object.assign({}, this.dataStore).ssos);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SSOS"), error)
        );
        return ssos$;
    }

    allowNonSSOLoginSetting(): Promise<AccountSettings> {
        const dns_prefix = window.location.hostname === "localhost" ? "zixi" : window.location.hostname.split(".")[0];

        const options = {
            headers: {
                dns_prefix
            }
        };

        return firstValueFrom(
            this.http
                .get<{ result: AccountSettings; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.customers + "/" + dns_prefix + "/settings/non-sso-login",
                    options
                )
                .pipe(map(data => data.result))
        );
    }
}
