import { Component, Input } from "@angular/core";
import { MediaConnectSource, Source } from "src/app/models/shared";

@Component({
    selector: "app-zx-tr101-col-source",
    template: `
        <span *ngIf="ngIf">
            <div *ngIf="source.status && source.status?.tr101 && source.status?.tr101?.status">
                <div class="d-inline-block">
                    {{ "P1" | translate }}:
                    <fa-icon
                        *ngIf="source.status.tr101.status.p1_ok"
                        icon="check-circle"
                        size="md"
                        class="status-good me-1"
                    ></fa-icon>
                    <fa-icon
                        *ngIf="!source.status.tr101.status.p1_ok"
                        icon="minus-circle"
                        size="md"
                        class="status-bad me-1"
                    ></fa-icon>
                </div>
                <div class="d-inline-block">
                    {{ "P2" | translate }}:
                    <fa-icon
                        *ngIf="source.status.tr101.status.p2_ok"
                        icon="check-circle"
                        size="md"
                        class="status-good"
                    ></fa-icon>
                    <fa-icon
                        *ngIf="!source.status.tr101.status.p2_ok"
                        icon="minus-circle"
                        size="md"
                        class="status-bad"
                    ></fa-icon>
                </div>
            </div>
            <span *ngIf="!source.status || !source.status?.tr101 || !source.status?.tr101?.status">-</span>
        </span>
    `
})
export class ZxTr101ColSourceComponent {
    @Input() source: MediaConnectSource | Source;
    @Input() ngIf? = true;
}
