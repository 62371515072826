<div class="history-graph">
    <button
        *ngIf="!hideAddIncident && selectedRangeFrom && selectedRangeTo"
        type="button"
        class="btn btn-outline-primary add-incident-button"
        (click)="createIncident()"
        title="{{ 'OPEN_INCIDENT' | translate }}"
    >
        <fa-icon icon="file-exclamation" size="sm"></fa-icon>
        <span class="ms-1 d-none d-xs-inline-block">{{ "OPEN_INCIDENT" | translate }}</span>
    </button>
    <iframe
        #iframe
        [src]="safeGraphUrl"
        frameborder="no"
        scrolling="no"
        marginwidth="0"
        marginheight="0"
        hspace="0"
        vspace="0"
        class="iframe-load-animation"
        height="{{ iframeHeight }}px"
        (load)="iframeOnLoad()"
    >
    </iframe>
</div>
