import { Component, OnInit, Input } from "@angular/core";
import { Constants } from "./../../../constants/constants";
import { DeliveryChannel } from "../../../pages/channels/channel";

@Component({
    selector: "zx-delivery",
    templateUrl: "./zx-delivery.component.html"
})
export class ZxDeliveryComponent implements OnInit {
    @Input() model: DeliveryChannel;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() searchTerm?: string | string[];

    urls = Constants.urls;

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
