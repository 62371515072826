import { Pipe, PipeTransform } from "@angular/core";
import { OutputNIC } from "./../models/shared";

@Pipe({
    name: "receiverOutputStatus"
})
export class ReceiverOutputStatusPipe implements PipeTransform {
    transform(output: OutputNIC): string {
        if (!output.error) {
            return "good";
        } else if (output.error) {
            return "error";
        } else return null;
    }
}
