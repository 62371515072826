import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ErrorService {
    lastHttpErrorResponse = new Subject<HttpErrorResponse>();
    currentHttpErrorResponse = new BehaviorSubject<HttpErrorResponse>(null);

    get lastError() {
        return this.lastHttpErrorResponse.asObservable();
    }
    setLastError(val: HttpErrorResponse) {
        this.lastHttpErrorResponse.next(val);
    }

    get currentError() {
        return this.currentHttpErrorResponse.asObservable();
    }
    setCurrentError(val: HttpErrorResponse) {
        this.currentHttpErrorResponse.next(val);
    }
}
