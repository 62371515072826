import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { firstValueFrom, Observable, ReplaySubject, Subscriber } from "rxjs";
import { share, map } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { StatusTextPipe } from "../../pipes/status-text.pipe";
import { UsersService } from "../account-management/users/users.service";
import { APIResponse, MediaConnectSource, MediaLiveInputDevice, TraceRoute } from "../../models/shared";
import { AuthService } from "src/app/services/auth.service";
import { SharedService } from "../../services/shared.service";

import * as _ from "lodash";
import moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MediaConnectSourceConfigDialogComponent } from "./mc-source/mc-source-config-dialog/mc-source-config-dialog.component";
@Injectable({
    providedIn: "root"
})
export class MediaConnectSourcesService {
    mediaconnectSources: Observable<MediaConnectSource[]>;
    traceroute: Observable<TraceRoute>;
    private mediaconnectSources$: ReplaySubject<MediaConnectSource[]>;
    private traceroute$: ReplaySubject<TraceRoute>;
    private dataStore: {
        mediaconnectsources: MediaConnectSource[];
        traceroute: TraceRoute;
    };

    private lastMediaConnectSourcesRefresh: number;
    private lastMediaConnectSourcesPaginatedRefresh: number;

    isContentAnalysis: boolean;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private stp: StatusTextPipe,
        private translate: TranslateService,
        private userService: UsersService,
        private sharedSerivces: SharedService,
        private ngbModal: NgbModal
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });

        // isContentAnalysis
        this.userService.isContentAnalysis.subscribe(bool => {
            this.isContentAnalysis = bool;
        });
    }

    private reset() {
        this.dataStore = {
            mediaconnectsources: [],
            traceroute: {}
        };

        this.lastMediaConnectSourcesRefresh = null;
        this.lastMediaConnectSourcesPaginatedRefresh = null;

        this.mediaconnectSources$ = new ReplaySubject<MediaConnectSource[]>(1);
        this.traceroute$ = new ReplaySubject<TraceRoute>(1);
        this.mediaconnectSources = this.mediaconnectSources$.asObservable();
        this.traceroute = this.traceroute$.asObservable();
    }

    prepMediaConnectSource(rawMediaConnectSource: any, override: boolean) {
        const mediaconnectsource = new MediaConnectSource();
        Object.assign(mediaconnectsource, rawMediaConnectSource as MediaConnectSource);

        const existingMediaConnectSource = _.find(this.dataStore.mediaconnectsources, s => {
            return s.id === mediaconnectsource.id;
        });

        if (existingMediaConnectSource && existingMediaConnectSource._frontData) {
            mediaconnectsource._frontData = existingMediaConnectSource._frontData;
        } else {
            mediaconnectsource._frontData = {
                sortableStatus: "",
                sortableCluster: ""
            };
        }

        mediaconnectsource.lastRefresh = moment().format();
        mediaconnectsource.zixi = false;
        mediaconnectsource.mediaconnect = true;

        // Override
        if (
            !override &&
            existingMediaConnectSource &&
            existingMediaConnectSource.status &&
            existingMediaConnectSource.generalStatus === mediaconnectsource.generalStatus
        ) {
            mediaconnectsource.status = _.merge(existingMediaConnectSource.status, mediaconnectsource.status);
        }

        // Sortable Cluster
        if (mediaconnectsource.mediaconnectFlow) {
            mediaconnectsource._frontData.sortableCluster = mediaconnectsource.mediaconnectFlow.name;
        }

        this.sharedSerivces.prepStatusSortFields(mediaconnectsource);

        // isConnected
        const offlineError = (mediaconnectsource.activeStates || []).find(
            as => as.type === "error" && as.group === "offline"
        );
        mediaconnectsource._frontData.isConnected = mediaconnectsource && !offlineError;

        // Type
        if (mediaconnectsource.mediaconnect) mediaconnectsource._frontData.typeColumn = "AWS Media";

        return mediaconnectsource;
    }

    private updateStore(rawMediaConnectSource: any, merge: boolean): void {
        const mediaconnectsource = this.prepMediaConnectSource(rawMediaConnectSource, !merge);

        const currentIndex = this.dataStore.mediaconnectsources.findIndex(g => g.id === mediaconnectsource.id);
        if (currentIndex === -1) {
            this.dataStore.mediaconnectsources.push(mediaconnectsource);
            return;
        } else if (merge) {
            const current = this.dataStore.mediaconnectsources[currentIndex];
            Object.assign(current, mediaconnectsource);
        } else {
            this.dataStore.mediaconnectsources[currentIndex] = mediaconnectsource;
        }
    }

    prepParams(ids: number[] | string[]) {
        let filter = new HttpParams();
        if (ids) {
            ids.forEach(id => {
                filter = filter.append("id", id);
            });
        }
        return filter;
    }

    refreshMediaConnectSources(summary: boolean, force?: boolean, ids?: number[]): Observable<MediaConnectSource[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && this.lastMediaConnectSourcesRefresh && _.now() - this.lastMediaConnectSourcesRefresh <= 60000)
            return this.mediaconnectSources;
        this.lastMediaConnectSourcesRefresh = _.now();

        const mediaconnectsources$ = this.http
            .get<{ result: MediaConnectSource[]; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.mediaconnectSources + (summary ? "?summary" : "?update"),
                {
                    params: this.prepParams(ids)
                }
            )
            .pipe(share());

        mediaconnectsources$.subscribe(
            data => {
                const mediaconnectsources = data.result;

                if (!ids) ids = this.dataStore.mediaconnectsources.map(s => s.id);

                ids.forEach(existingId => {
                    const newIndex = mediaconnectsources.findIndex(s => s.id === existingId);
                    if (newIndex === -1) {
                        const existingIndex = this.dataStore.mediaconnectsources.findIndex(s => s.id === existingId);
                        this.dataStore.mediaconnectsources.splice(existingIndex, 1);
                    }
                });

                mediaconnectsources.forEach(s => this.updateStore(s, true));

                this.mediaconnectSources$.next(Object.assign({}, this.dataStore).mediaconnectsources);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIACONNECTSOURCES"), error)
        );

        return mediaconnectsources$.pipe(map(r => r.result));
    }

    loadMediaConnectSourcesPaginated(force?: boolean): Observable<MediaConnectSource[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (
            !force &&
            this.lastMediaConnectSourcesPaginatedRefresh &&
            _.now() - this.lastMediaConnectSourcesPaginatedRefresh <= 60000
        )
            return this.mediaconnectSources;

        this.lastMediaConnectSourcesPaginatedRefresh = _.now();

        return this.loadMediaConnectSourcesPage(1);
    }

    private loadMediaConnectSourcesPage(page: number): Observable<MediaConnectSource[]> {
        const mediaconnectsources$ = this.http
            .get<{ result: MediaConnectSource[]; success: boolean; pages: number }>(
                Constants.apiUrl + Constants.apiUrls.mediaconnectSources + "?fast=1" + "&page=" + page
            )
            .pipe(share());

        mediaconnectsources$.subscribe(
            data => {
                const mediaconnectsources = data.result;
                const pages = data.pages;

                mediaconnectsources.forEach(s => this.updateStore(s, true));

                if (pages > page) this.loadMediaConnectSourcesPage(page + 1);

                this.mediaconnectSources$.next(Object.assign({}, this.dataStore).mediaconnectsources);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIACONNECTSOURCES"), error)
        );
        return mediaconnectsources$.pipe(map(r => r.result));
    }

    refreshMediaConnectSource(s: MediaConnectSource | number, force?: boolean): Observable<MediaConnectSource> {
        const id: number = typeof s === "number" ? s : s.id;

        if (!force && this.dataStore.mediaconnectsources && this.dataStore.mediaconnectsources.length) {
            const mcs: MediaConnectSource = this.dataStore.mediaconnectsources.find(f => f.id === id);
            //
            if (mcs && mcs.hasFullDetails) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(mcs.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<MediaConnectSource>) => {
                        observe.next(mcs);
                        observe.complete();
                    });
                }
            }
        }

        const mediaconnectsource$ = this.http
            .get<{ result: MediaConnectSource; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.mediaconnectSources + "/" + `${id}`
            )
            .pipe(share());
        mediaconnectsource$.subscribe(
            data => {
                const mediaconnectsource = data.result;
                mediaconnectsource.hasFullDetails = true;

                this.updateStore(mediaconnectsource, false);

                this.mediaconnectSources$.next(Object.assign({}, this.dataStore).mediaconnectsources);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIACONNECTSOURCE"), error)
        );
        return mediaconnectsource$.pipe(map(r => r.result));
    }

    getCachedMediaConnectSource(name?: string, id?: number) {
        if (this.dataStore.mediaconnectsources && name)
            return this.dataStore.mediaconnectsources.find(mcs => mcs.name === name);
        if (this.dataStore.mediaconnectsources && id)
            return this.dataStore.mediaconnectsources.find(mcs => mcs.id === id);
        return undefined;
    }

    async addMediaConnectSource(model: Record<string, unknown>): Promise<MediaConnectSource> {
        try {
            const result = await this.http
                .post<{ result: MediaConnectSource; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.mediaconnectSources,
                    model
                )
                .toPromise();
            const mediaconnectsource: MediaConnectSource = result.result;

            this.updateStore(mediaconnectsource, false);

            this.mediaconnectSources$.next(Object.assign({}, this.dataStore).mediaconnectsources);
            return mediaconnectsource;
        } catch (error) {
            throw new Error((error.error && error.error.error) || (error.error && error.error.message) || "API Error");
        }
    }

    async updateMediaConnectSource(
        mediaconnectsource: MediaConnectSource,
        model: Record<string, unknown>
    ): Promise<MediaConnectSource | boolean> {
        try {
            const result = await firstValueFrom(
                this.http.put<{ result: MediaConnectSource; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.mediaconnectSources + "/" + `${mediaconnectsource.id}`,
                    model
                )
            );
            const updatedMediaConnectSource = result.result;

            this.updateStore(updatedMediaConnectSource, true);

            this.mediaconnectSources$.next(Object.assign({}, this.dataStore).mediaconnectsources);
            return updatedMediaConnectSource;
        } catch (error) {
            if (error.status === 428) return true;
            else return false;
        }
    }

    async deleteMediaConnectSource(mediaconnectsource: MediaConnectSource) {
        try {
            const result = await firstValueFrom(
                this.http.delete<APIResponse<number>>(
                    Constants.apiUrl + Constants.apiUrls.mediaconnectSources + "/" + `${mediaconnectsource.id}`
                )
            );

            const deletedId: number = result.result;
            const mediaconnectsourceIndex = this.dataStore.mediaconnectsources.findIndex(s => s.id === deletedId);
            if (mediaconnectsourceIndex !== -1) this.dataStore.mediaconnectsources.splice(mediaconnectsourceIndex, 1);

            this.mediaconnectSources$.next(Object.assign({}, this.dataStore).mediaconnectsources);
            return true;
        } catch (error) {
            return false;
        }
    }

    async listElementalLinks(awsAccountId: number, region: string): Promise<MediaLiveInputDevice[]> {
        const inputs = await this.http
            .get<{ result: MediaLiveInputDevice[]; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.aws_accounts + `/${awsAccountId}/medialive/${region}/input_devices`
            )
            .toPromise();

        return inputs.result;
    }

    async getSourceThumbnail(sourceId: number) {
        const thumbnail = await this.http
            .get(Constants.apiUrl + Constants.apiUrls.mediaconnectSources + `/${sourceId}/thumbnail`, {
                headers: new HttpHeaders().append("accept", "image/jpeg"),
                observe: "response",
                responseType: "arraybuffer"
            })
            .toPromise();

        return thumbnail;
    }

    mediaConnectSourceConfigHelp(source: MediaConnectSource) {
        const modal = this.ngbModal.open(MediaConnectSourceConfigDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.source = source;
        return modal.result;
    }
}
