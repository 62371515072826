import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { firstValueFrom, Observable, of, ReplaySubject, Subscriber } from "rxjs";
import { share, map, catchError, takeWhile } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { AdaptiveChannel, ChannelTypes, DeliveryChannel, FailoverChannel, MediaLiveChannel } from "./channel";
import { Tag, MediaConnectFlow, APIResponse, Source, RecoveryState } from "../../models/shared";
import { AuthService } from "src/app/services/auth.service";
import { SharedService } from "../../services/shared.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";

import moment from "moment";
import _ from "lodash";
import { AdMarkEvent } from "@zixi/models/lib/ad-mark-event";
import { PageInfo } from "src/app/components/shared/table-list/table-list.component";
import { UrlBuilderService } from "src/app/services/url-builder.service";

@Injectable({
    providedIn: "root"
})
export class ChannelsService {
    adaptiveChannels: Observable<AdaptiveChannel[]>;
    private adaptiveChannels$: ReplaySubject<AdaptiveChannel[]>;
    deliveryChannels: Observable<DeliveryChannel[]>;
    private deliveryChannels$: ReplaySubject<DeliveryChannel[]>;
    mediaconnectFlows: Observable<MediaConnectFlow[]>;
    private mediaconnectFlows$: ReplaySubject<MediaConnectFlow[]>;
    medialiveChannels: Observable<MediaLiveChannel[]>;
    private medialiveChannels$: ReplaySubject<MediaLiveChannel[]>;
    failoverChannels: Observable<FailoverChannel[]>;
    private failoverChannels$: ReplaySubject<FailoverChannel[]>;

    channels: Observable<ChannelTypes[]>;
    private channels$: ReplaySubject<ChannelTypes[]>;
    private adMarks$ = new ReplaySubject<AdMarkEvent[]>(1);
    adMarks = this.adMarks$.asObservable();

    private dataStore: {
        adaptiveChannels: AdaptiveChannel[];
        deliveryChannels: DeliveryChannel[];
        mediaconnectFlows: MediaConnectFlow[];
        medialiveChannels: MediaLiveChannel[];
        failoverChannels: FailoverChannel[];
        channels: ChannelTypes[];
    };

    private lastMediaConnectFlowsRefresh: number;
    private lastMediaLiveChannelsRefresh: number;
    private lastFailoverChannelsRefresh: number;
    private lastGetMediaConnectFlows: number;
    private lastGetMediaLiveChannels: number;
    private lastAdaptiveChannelsRefresh: number;
    private lastGetAdaptiveChannels: number;
    private lastDeliveryChannelsRefresh: number;
    private lastGetDeliveryChannels: number;
    private lastGetFailoverChannels: number;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private sharedServices: SharedService,
        private urlBuilderService: UrlBuilderService,
        private broadcastersService: BroadcastersService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            adaptiveChannels: [],
            deliveryChannels: [],
            mediaconnectFlows: [],
            medialiveChannels: [],
            failoverChannels: [],
            channels: []
        };

        this.lastMediaConnectFlowsRefresh = null;
        this.lastMediaLiveChannelsRefresh = null;
        this.lastFailoverChannelsRefresh = null;
        this.lastGetMediaConnectFlows = null;
        this.lastGetMediaLiveChannels = null;
        this.lastAdaptiveChannelsRefresh = null;
        this.lastGetAdaptiveChannels = null;
        this.lastDeliveryChannelsRefresh = null;
        this.lastGetDeliveryChannels = null;

        this.mediaconnectFlows$ = new ReplaySubject<MediaConnectFlow[]>(1);
        this.mediaconnectFlows = this.mediaconnectFlows$.asObservable();
        this.medialiveChannels$ = new ReplaySubject<MediaLiveChannel[]>(1);
        this.medialiveChannels = this.medialiveChannels$.asObservable();
        this.adaptiveChannels$ = new ReplaySubject<AdaptiveChannel[]>(1);
        this.adaptiveChannels = this.adaptiveChannels$.asObservable();
        this.deliveryChannels$ = new ReplaySubject<DeliveryChannel[]>(1);
        this.deliveryChannels = this.deliveryChannels$.asObservable();
        this.failoverChannels$ = new ReplaySubject<FailoverChannel[]>(1);
        this.failoverChannels = this.failoverChannels$.asObservable();
        this.channels$ = new ReplaySubject<ChannelTypes[]>(1);
        this.channels = this.channels$.asObservable();
    }

    prepAdaptiveChannel(channel: AdaptiveChannel) {
        channel.adaptive = true;
        channel._frontData = {
            sortableStatus: "",
            sortableCluster: "",
            good_sources: 0,
            bad_sources: 0,
            disabled_sources: 0,
            sortableSources: "",
            sortableTargets: 0,
            mode: channel.adaptive ? (channel.is_transcoding ? "Transcoded" : "Adaptive") : "Pass-Through",
            transcoded: channel.adaptive && channel.is_transcoding,
            eventsObjects: {
                adaptive_channel: [],
                publishing_target: []
            },
            active_broadcaster: null,
            lastRefresh: moment().format()
        };

        channel.type = channel.adaptive ? "adaptive" : "delivery";

        if (channel.processingCluster) {
            channel._frontData.sortableCluster = channel.processingCluster.name;
        }
        if (!channel.bitrates) channel.bitrates = [];

        if (channel.is_transcoding) {
            const source = channel.bitrates[0]?.source;
            if (!source) {
                channel._frontData.disabled_sources = 0;
                channel._frontData.good_sources = 0;
                channel._frontData.bad_sources = 1;
            } else {
                channel._frontData.disabled_sources = !source.is_enabled ? 1 : 0;
                channel._frontData.good_sources = source.is_enabled && source.generalStatus === "good" ? 1 : 0;
                channel._frontData.bad_sources = source.is_enabled && source.generalStatus !== "good" ? 1 : 0;
            }
        } else {
            channel._frontData.disabled_sources = channel.bitrates.reduce(
                (acc, bitrate) => (bitrate.source.is_enabled !== 1 ? ++acc : acc),
                0
            );
            channel._frontData.good_sources = channel.bitrates.reduce(
                (acc, bitrate) =>
                    bitrate.source.is_enabled === 1 && bitrate.source.generalStatus === "good" ? ++acc : acc,
                0
            );
            channel._frontData.bad_sources = channel.bitrates.reduce(
                (acc, bitrate) =>
                    bitrate.source.is_enabled === 1 && bitrate.source.generalStatus !== "good" ? ++acc : acc,
                0
            );
        }

        // Source order
        const total =
            channel._frontData.bad_sources + channel._frontData.good_sources + channel._frontData.disabled_sources;
        channel._frontData.sortableSources = total.toString();
        if (channel._frontData.bad_sources)
            channel._frontData.sortableSources += channel._frontData.bad_sources.toString();
        else channel._frontData.sortableSources += "0";
        if (channel._frontData.good_sources)
            channel._frontData.sortableSources += channel._frontData.good_sources.toString();
        else channel._frontData.sortableSources += "0";
        if (channel._frontData.disabled_sources)
            channel._frontData.sortableSources += channel._frontData.disabled_sources.toString();
        else channel._frontData.sortableSources += "0";

        channel._frontData.sortableSources += channel.bitrates[0]?.source?.name.toLowerCase();

        // Target order
        let t = 0;
        if (channel.targetsSummary?.good) t = channel.targetsSummary.good;
        if (channel.targetsSummary?.bad) t = t + channel.targetsSummary.bad;
        if (channel.targetsSummary?.warning) t = t + channel.targetsSummary.warning;
        if (channel.targetsSummary?.muted_bad) t = t + channel.targetsSummary.muted_bad;
        if (channel.targetsSummary?.disabled) t = t + channel.targetsSummary.disabled;
        if (channel.targetsSummary?.pending) t = t + channel.targetsSummary.pending;
        channel._frontData.sortableTargets = t;

        channel._frontData.eventsObjects = {
            adaptive_channel: [channel.id],
            publishing_target: _.map(channel.publishingTarget, "id") || []
        };

        // Order
        this.sharedServices.prepStatusSortFields(channel);

        // sort resource tags
        if (channel.resourceTags)
            channel.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );

        // active broadcaster
        if (channel.status?.active_broadcaster && channel.status?.active_broadcaster.name) {
            channel._frontData.active_broadcaster = channel.status.active_broadcaster;
        }

        const typedChannel = new AdaptiveChannel();
        Object.assign(typedChannel, channel as AdaptiveChannel);
        return typedChannel;
    }

    prepDeliveryChannel(channel: DeliveryChannel) {
        channel.delivery = true;
        channel._frontData = {
            sortableStatus: "",
            sortableCluster: "",
            good_sources: 0,
            bad_sources: 0,
            disabled_sources: 0,
            sortableSources: "",
            sortableTargets: 0,
            mode: "Pass-Through",
            eventsObjects: {
                delivery_channel: [],
                zixi_pull: [],
                zixi_push: [],
                rtmp_push: []
            },
            active_broadcaster: null,
            lastRefresh: moment().format(),
            targetBroadcaster: null
        };

        channel.type = channel.adaptive ? "adaptive" : "delivery";

        if (channel.processingCluster) {
            channel._frontData.sortableCluster = channel.processingCluster.name;
        }

        //Order
        this.sharedServices.prepStatusSortFields(channel);

        for (const src of channel.sources ?? []) {
            switch (src.source.generalStatus) {
                case "good":
                    channel._frontData.good_sources++;
                    break;
                case "bad":
                case "warning":
                    channel._frontData.bad_sources++;
                    break;
                case "disabled":
                case "pending":
                default:
                    channel._frontData.disabled_sources++;
                    break;
            }
        }

        // Source order
        const total =
            channel._frontData.bad_sources + channel._frontData.good_sources + channel._frontData.disabled_sources;
        channel._frontData.sortableSources = total.toString();
        if (channel._frontData.bad_sources)
            channel._frontData.sortableSources += channel._frontData.bad_sources.toString();
        else channel._frontData.sortableSources += "0";
        if (channel._frontData.good_sources)
            channel._frontData.sortableSources += channel._frontData.good_sources.toString();
        else channel._frontData.sortableSources += "0";
        if (channel._frontData.disabled_sources)
            channel._frontData.sortableSources += channel._frontData.disabled_sources.toString();
        else channel._frontData.sortableSources += "0";

        if (!channel.adaptive) {
            for (const src of channel.sources ?? []) {
                channel._frontData.sortableSources += src.source.name.toLowerCase();
            }
        }

        // Target order
        const t = channel.targetsSummary
            ? channel.targetsSummary.good +
              channel.targetsSummary.bad +
              channel.targetsSummary.warning +
              channel.targetsSummary.muted_bad +
              channel.targetsSummary.disabled +
              channel.targetsSummary.pending
            : 0;
        channel._frontData.sortableTargets = t;

        channel._frontData.eventsObjects = {
            delivery_channel: [channel.id],
            zixi_pull: _.map(channel.zixiPull, "id"),
            zixi_push: _.map(channel.zixiPush, "id"),
            rtmp_push: _.map(channel.rtmpPush, "id")
        };

        this.sharedServices.prepStatusSortFields(channel);

        // sort resource tags
        if (channel.resourceTags)
            channel.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );

        // active broadcasters
        if (channel.status?.active_broadcasters) {
            if (channel.status?.active_broadcasters.length === 1)
                channel._frontData.active_broadcaster = channel.status.active_broadcasters[0];
            else if (channel.status?.active_broadcasters.length > 1) {
                // remove duplicates
                const result = channel.status?.active_broadcasters.reduce((unique, o) => {
                    if (!unique.some(obj => obj.id === o.id)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);
                if (result.length >= 1) channel._frontData.active_broadcaster = result[0];
            }
        }

        if (channel.target_broadcaster_id > 0) {
            channel._frontData.targetBroadcaster = this.broadcastersService.getCachedBroadcaster(
                channel.target_broadcaster_id
            );
        }
        const typedChannel = new DeliveryChannel();
        Object.assign(typedChannel, channel as DeliveryChannel);
        return typedChannel;
    }

    prepMediaConnectFlow(flow: MediaConnectFlow) {
        flow.mediaconnect = true;

        flow._frontData = {
            sortableStatus: "",
            sortableCluster: "",
            good_sources: 0,
            bad_sources: 0,
            disabled_sources: 0,
            sortableSources: "",
            sortableTargets: 0,
            mode: "MediaConnect Flow",
            active_broadcaster: null,
            lastRefresh: moment().format()
        };

        flow.type = "mediaconnect";

        // Source order
        if (flow.source) {
            if (flow.source.generalStatus) {
                if (flow.source.generalStatus === "bad") flow._frontData.sortableSources += "1100";
                if (flow.source.generalStatus === "good") flow._frontData.sortableSources += "1010";
                if (flow.source.generalStatus === "disabled") flow._frontData.sortableSources += "1001";
                if (flow.source.generalStatus === "pending") flow._frontData.sortableSources += "1001";
                if (flow.source.generalStatus === "no_source") flow._frontData.sortableSources += "1001";
                if (flow.source.generalStatus === "flow_disabled") flow._frontData.sortableSources += "1001";
            }
            if (flow.source.name) {
                flow._frontData.sortableSources += flow.source.name;
            }
        }

        // Target order
        const t =
            flow.targetsSummary.good +
            flow.targetsSummary.bad +
            flow.targetsSummary.warning +
            flow.targetsSummary.muted_bad +
            flow.targetsSummary.disabled +
            flow.targetsSummary.pending;
        flow._frontData.sortableTargets = t;

        flow._frontData.eventsObjects = {
            mediaconnect_flows: [flow.id],
            zixi_pull: _.map(flow.zixiPull, "id"),
            zixi_push: _.map(flow.zixiPush, "id"),
            udp_rtp: _.map(flow.udpRtp, "id"),
            srt_targets: _.map(flow.srt, "id")
        };

        if (flow.region) {
            flow._frontData.sortableCluster = flow.region;
        }

        //Order
        this.sharedServices.prepStatusSortFields(flow);
        if (flow.resourceTags)
            flow.resourceTags.sort((a, b) => (a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1));

        const typedChannel = new MediaConnectFlow();
        Object.assign(typedChannel, flow as MediaConnectFlow);
        return typedChannel;
    }

    prepMediaLiveChannel(channel: MediaLiveChannel) {
        channel.medialive = true;

        channel._frontData = {
            sortableStatus: "",
            sortableCluster: "",
            good_sources: 0,
            bad_sources: 0,
            disabled_sources: 0,
            sortableSources: "",
            sortableTargets: 0,
            mode: "MediaLive Channel",
            active_broadcaster: null,
            lastRefresh: moment().format()
        };

        channel.type = "medialive";

        // Source order
        if (channel.source) {
            if (channel.source.generalStatus) {
                if (channel.source.generalStatus === "bad") channel._frontData.sortableSources += "1100";
                if (channel.source.generalStatus === "good") channel._frontData.sortableSources += "1010";
                if (channel.source.generalStatus === "disabled") channel._frontData.sortableSources += "1001";
                if (channel.source.generalStatus === "pending") channel._frontData.sortableSources += "1001";
                if (channel.source.generalStatus === "flow_disabled") channel._frontData.sortableSources += "1001";
                if (channel.source.generalStatus === "no_source") channel._frontData.sortableSources += "1001";
            }
            if (channel.source.name) channel._frontData.sortableSources += channel.source.name;
        } else if (channel.flow) {
            if (channel.flow.generalStatus) {
                if (channel.flow.generalStatus === "bad") channel._frontData.sortableSources += "1100";
                if (channel.flow.generalStatus === "good") channel._frontData.sortableSources += "1010";
                if (channel.flow.generalStatus === "disabled") channel._frontData.sortableSources += "1001";
                if (channel.flow.generalStatus === "pending") channel._frontData.sortableSources += "1001";
                if (channel.flow.generalStatus === "flow_disabled") channel._frontData.sortableSources += "1001";
                if (channel.flow.generalStatus === "no_source") channel._frontData.sortableSources += "1001";
            }
            if (channel.flow.name) channel._frontData.sortableSources += channel.flow.name;
        }

        // Target order
        const t = channel.targetsSummary
            ? channel.targetsSummary.good +
              channel.targetsSummary.bad +
              channel.targetsSummary.warning +
              channel.targetsSummary.muted_bad +
              channel.targetsSummary.disabled +
              channel.targetsSummary.pending
            : 0;
        channel._frontData.sortableTargets = t;

        channel._frontData.eventsObjects = {
            medialive_channels: [channel.id],
            medialive_http_targets: _.map(channel.mediaLiveHttp, "id")
        };

        if (channel.region) {
            channel._frontData.sortableCluster = channel.region;
        }
        this.sharedServices.prepStatusSortFields(channel);

        if (channel.resourceTags)
            channel.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );

        const typedChannel = new MediaLiveChannel();
        Object.assign(typedChannel, channel as MediaLiveChannel);
        return typedChannel;
    }

    prepFailoverChannel(channel: FailoverChannel) {
        channel.failover = true;
        channel.type = "failover";
        channel.active_mute = channel.muted ? true : false;
        channel._frontData = {
            sortableStatus: "",
            sortableCluster: "",
            mode: "Failover",
            active_broadcaster: channel.processingCluster?.broadcasters?.find(
                b => b.id === channel.activeBroadcasterObjects?.bx_id
            ),
            lastRefresh: moment().format(),
            eventsObjects: {
                failover_channel: [channel.id],
                source: [channel.failover_source_id],
                zixi_pull: [],
                zixi_push: [],
                rtmp_push: []
            },
            good_sources: 0,
            bad_sources: 0,
            disabled_sources: 0,
            sortableSources: "",
            sortableTargets: 0
        };

        if (!channel.is_enabled) {
            channel.generalStatus = "disabled";
        }

        if (channel.processingCluster) {
            channel._frontData.sortableCluster = channel.processingCluster.name;
        }

        this.sharedServices.prepStatusSortFields(channel);

        if (channel.resourceTags) {
            channel.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );
        } else {
            channel.resourceTags = [];
        }

        for (const src of channel.failoverSource.failoverSources ?? []) {
            switch ((src.source as unknown as Source).generalStatus) {
                case "good":
                    channel._frontData.good_sources++;
                    break;
                case "bad":
                case "warning":
                    channel._frontData.bad_sources++;
                    break;
                case "disabled":
                case "pending":
                default:
                    channel._frontData.disabled_sources++;
                    break;
            }
        }

        // Target order
        const t = channel.targetsSummary
            ? channel.targetsSummary.good +
              channel.targetsSummary.bad +
              channel.targetsSummary.warning +
              channel.targetsSummary.muted_bad +
              channel.targetsSummary.disabled +
              channel.targetsSummary.pending
            : 0;
        channel._frontData.sortableTargets = t;

        // Source order
        const total =
            channel._frontData.bad_sources + channel._frontData.good_sources + channel._frontData.disabled_sources;
        channel._frontData.sortableSources = total.toString();
        if (channel._frontData.bad_sources)
            channel._frontData.sortableSources += channel._frontData.bad_sources.toString();
        else channel._frontData.sortableSources += "0";
        if (channel._frontData.good_sources)
            channel._frontData.sortableSources += channel._frontData.good_sources.toString();
        else channel._frontData.sortableSources += "0";
        if (channel._frontData.disabled_sources)
            channel._frontData.sortableSources += channel._frontData.disabled_sources.toString();
        else channel._frontData.sortableSources += "0";
        channel._frontData.sortableSources += channel.failoverSource?.failoverSources?.[0]?.source?.name.toLowerCase();

        channel.deliveryChannel = this.prepDeliveryChannel(channel.deliveryChannel);

        channel.error_concealment = channel.failoverSource.error_concealment;
        channel.error_concealment_continuous_timeline = channel.failoverSource.error_concealment_continuous_timeline;
        channel.error_concealment_replace_frames = channel.failoverSource.error_concealment_replace_frames;
        channel.error_concealment_delay_ms = channel.failoverSource.error_concealment_delay_ms;
        channel.error_concealment_cbr_padding_kbps = channel.failoverSource.error_concealment_cbr_padding_kbps;
        channel.error_concealment_cbr_padding_pcr_interval_ms =
            channel.failoverSource.error_concealment_cbr_padding_pcr_interval_ms;
        channel.error_concealment_fix_cc = channel.failoverSource.error_concealment_fix_cc;

        const typedChannel = new FailoverChannel();
        Object.assign(typedChannel, channel as FailoverChannel);
        return typedChannel;
    }

    prepChannel(channel: ChannelTypes) {
        switch (channel.type) {
            case "adaptive":
                return this.prepAdaptiveChannel(channel as AdaptiveChannel);
            case "delivery":
                return this.prepDeliveryChannel(channel as DeliveryChannel);
            case "failover":
                return this.prepFailoverChannel(channel as FailoverChannel);
            case "mediaconnect":
                return this.prepMediaConnectFlow(channel as MediaConnectFlow);
            case "medialive":
                return this.prepMediaLiveChannel(channel as MediaLiveChannel);
        }
    }

    typeChannel(channel: any): ChannelTypes {
        switch (channel.type) {
            case "adaptive":
                return Object.assign(new AdaptiveChannel(), { adaptive: true }, channel as AdaptiveChannel);
            case "delivery":
                return Object.assign(new DeliveryChannel(), { delivery: true }, channel as DeliveryChannel);
            case "failover":
                return Object.assign(new FailoverChannel(), { failover: true }, channel as FailoverChannel);
            case "mediaconnect":
                return Object.assign(new MediaConnectFlow(), { mediaconnect: true }, channel as MediaConnectFlow);
            case "medialive":
                return Object.assign(new MediaLiveChannel(), { medialive: true }, channel as MediaLiveChannel);
        }
    }

    private updateMediaConnectStore(flow: MediaConnectFlow, merge: boolean): MediaConnectFlow {
        flow = this.prepMediaConnectFlow(flow);

        const currentIndex = this.dataStore.mediaconnectFlows.findIndex(u => u.id === flow.id);
        if (currentIndex === -1) {
            this.dataStore.mediaconnectFlows.push(flow);
        } else if (merge) {
            const current = this.dataStore.mediaconnectFlows[currentIndex];
            Object.assign(current, flow);
        } else {
            this.dataStore.mediaconnectFlows[currentIndex] = flow;
        }

        this.updateChannelStore(flow, merge);
        return flow;
    }

    private updateMediaLiveStore(channel: MediaLiveChannel, merge: boolean): MediaLiveChannel {
        channel = this.prepMediaLiveChannel(channel);

        const currentIndex = this.dataStore.medialiveChannels.findIndex(u => u.id === channel.id);
        if (currentIndex === -1) {
            this.dataStore.medialiveChannels.push(channel);
        } else if (merge) {
            const current = this.dataStore.medialiveChannels[currentIndex];
            Object.assign(current, channel);
        } else {
            this.dataStore.medialiveChannels[currentIndex] = channel;
        }

        this.updateChannelStore(channel, merge);
        return channel;
    }

    private updateAdaptiveStore(channel: AdaptiveChannel, merge: boolean): AdaptiveChannel {
        channel = this.prepAdaptiveChannel(channel);

        const currentIndex = this.dataStore.adaptiveChannels.findIndex(u => u.id === channel.id);
        if (currentIndex === -1) {
            this.dataStore.adaptiveChannels.push(channel);
        } else if (merge) {
            const current = this.dataStore.adaptiveChannels[currentIndex];
            Object.assign(current, channel);
        } else {
            this.dataStore.adaptiveChannels[currentIndex] = channel;
        }

        this.updateChannelStore(channel, merge);
        return channel;
    }

    private updateDeliveryStore(channel: DeliveryChannel, merge: boolean): DeliveryChannel {
        channel = this.prepDeliveryChannel(channel);

        const currentIndex = this.dataStore.deliveryChannels.findIndex(u => u.id === channel.id);
        if (currentIndex === -1) {
            this.dataStore.deliveryChannels.push(channel);
        } else if (merge) {
            const current = this.dataStore.deliveryChannels[currentIndex];
            Object.assign(current, channel);
        } else {
            this.dataStore.deliveryChannels[currentIndex] = channel;
        }

        this.updateChannelStore(channel, merge);
        return channel;
    }

    private updateFailoverStore(channel: FailoverChannel, merge: boolean): FailoverChannel {
        channel = this.prepFailoverChannel(channel);

        const currentIndex = this.dataStore.failoverChannels.findIndex(u => u.id === channel.id);
        if (currentIndex === -1) {
            this.dataStore.failoverChannels.push(channel);
        } else if (merge) {
            const current = this.dataStore.failoverChannels[currentIndex];
            Object.assign(current, channel);
        } else {
            this.dataStore.failoverChannels[currentIndex] = channel;
        }

        this.updateChannelStore(channel, merge);
        return channel;
    }

    private updateChannelStore(channel: ChannelTypes, merge: boolean): void {
        const currentIndex = this.dataStore.channels.findIndex(u => u.id === channel.id && u.type === channel.type);
        if (currentIndex === -1) {
            this.dataStore.channels.push(channel);
        } else if (merge) {
            const current = this.dataStore.channels[currentIndex];
            Object.assign(current, channel);
        } else {
            this.dataStore.channels[currentIndex] = channel;
        }
    }

    getMediaConnectFlows(force?: boolean): Observable<MediaConnectFlow[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGetMediaConnectFlows <= 60000)
            return new Observable((observe: Subscriber<MediaConnectFlow[]>) => {
                observe.next(this.dataStore.mediaconnectFlows);
                observe.complete();
            });
        this.lastGetMediaConnectFlows = _.now();

        const mediaconnectFlows$ = this.http
            .get<APIResponse<MediaConnectFlow[]>>(Constants.apiUrl + Constants.apiUrls.channel + "/mediaconnect?fast=1")
            .pipe(share());

        mediaconnectFlows$.subscribe(
            data => {
                const mediaconnectFlows: MediaConnectFlow[] = data.result;

                this.dataStore.mediaconnectFlows.forEach((existing, existingIndex) => {
                    const newIndex = mediaconnectFlows.findIndex(b => b.id === existing.id);
                    if (newIndex === -1) {
                        this.dataStore.mediaconnectFlows.splice(existingIndex, 1);

                        const channelIndex = this.dataStore.channels.findIndex(
                            b => b.id === existing.id && b.type === existing.type
                        );
                        if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                    }
                });

                mediaconnectFlows.forEach(ac => this.updateMediaConnectStore(ac, true));

                this.mediaconnectFlows$.next(Object.assign({}, this.dataStore).mediaconnectFlows);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIACONNECT_FLOWS"), error)
        );

        return mediaconnectFlows$.pipe(map(r => r.result));
    }

    getMediaLiveChannels(force?: boolean): Observable<MediaLiveChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGetMediaLiveChannels <= 60000)
            return new Observable((observe: Subscriber<MediaLiveChannel[]>) => {
                observe.next(this.dataStore.medialiveChannels);
                observe.complete();
            });
        this.lastGetMediaLiveChannels = _.now();

        const medialiveChannels$ = this.http
            .get<APIResponse<MediaLiveChannel[]>>(Constants.apiUrl + Constants.apiUrls.channel + "/medialive")
            .pipe(share());

        medialiveChannels$.subscribe(
            data => {
                const medialiveChannels: MediaLiveChannel[] = data.result;
                this.dataStore.medialiveChannels.forEach((existing, existingIndex) => {
                    const newIndex = medialiveChannels.findIndex(b => b.id === existing.id);
                    if (newIndex === -1) {
                        this.dataStore.medialiveChannels.splice(existingIndex, 1);

                        const channelIndex = this.dataStore.channels.findIndex(
                            b => b.id === existing.id && b.type === existing.type
                        );
                        if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                    }
                });

                medialiveChannels.forEach(ml => this.updateMediaLiveStore(ml, true));

                this.medialiveChannels$.next(Object.assign({}, this.dataStore).medialiveChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIALIVE_CHANNELS"), error)
        );

        return medialiveChannels$.pipe(map(r => r.result));
    }

    getAdaptiveChannels(force?: boolean): Observable<AdaptiveChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGetAdaptiveChannels <= 60000) return this.adaptiveChannels;
        this.lastGetAdaptiveChannels = _.now();

        const adaptiveChannels$ = this.getAdaptiveChannelsNew().pipe(takeWhile(adaptiveChannels => !!adaptiveChannels));

        adaptiveChannels$.subscribe(adaptiveChannels => {
            this.dataStore.adaptiveChannels.forEach((existing, existingIndex) => {
                const newIndex = adaptiveChannels.findIndex(b => b.id === existing.id);
                if (newIndex === -1) {
                    this.dataStore.adaptiveChannels.splice(existingIndex, 1);

                    const channelIndex = this.dataStore.channels.findIndex(
                        b => b.id === existing.id && b.type === existing.type
                    );
                    if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                }
            });

            adaptiveChannels.forEach(ac => this.updateAdaptiveStore(ac, true));

            this.adaptiveChannels$.next(Object.assign({}, this.dataStore).adaptiveChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
        });

        return adaptiveChannels$;
    }

    getDeliveryChannels(force?: boolean): Observable<DeliveryChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGetDeliveryChannels <= 60000) return this.deliveryChannels;
        this.lastGetDeliveryChannels = _.now();

        const deliveryChannels$ = this.getDeliveryChannelsNew().pipe(takeWhile(deliveryChannels => !!deliveryChannels));

        deliveryChannels$.subscribe(deliveryChannels => {
            this.dataStore.deliveryChannels.forEach((existing, existingIndex) => {
                const newIndex = deliveryChannels.findIndex(b => b.id === existing.id);
                if (newIndex === -1) {
                    this.dataStore.deliveryChannels.splice(existingIndex, 1);

                    const channelIndex = this.dataStore.channels.findIndex(
                        b => b.id === existing.id && b.type === existing.type
                    );
                    if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                }
            });

            deliveryChannels.forEach(dc => this.updateDeliveryStore(dc, true));

            this.deliveryChannels$.next(Object.assign({}, this.dataStore).deliveryChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
        });
        return deliveryChannels$;
    }

    getFailoverChannels(force?: boolean): Observable<FailoverChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGetFailoverChannels <= 60000) return this.failoverChannels;
        this.lastGetFailoverChannels = _.now();

        const failoverChannels$ = this.http
            .get<APIResponse<FailoverChannel[]>>(Constants.apiUrl + Constants.apiUrls.channel + "/failover?fast=1")
            .pipe(share());

        failoverChannels$.subscribe(
            data => {
                const failoverChannels: FailoverChannel[] = data.result;

                this.dataStore.failoverChannels.forEach((existing, existingIndex) => {
                    const newIndex = failoverChannels.findIndex(b => b.id === existing.id);
                    if (newIndex === -1) {
                        this.dataStore.failoverChannels.splice(existingIndex, 1);

                        const channelIndex = this.dataStore.channels.findIndex(
                            b => b.id === existing.id && b.type === existing.type
                        );
                        if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                    }
                });

                failoverChannels.forEach(oc => this.updateFailoverStore(oc, true));

                this.failoverChannels$.next(Object.assign({}, this.dataStore).failoverChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_FAILOVER_CHANNELS"), error)
        );
        return failoverChannels$.pipe(map(r => r.result));
    }

    refreshFailoverChannels(ids: number[], force?: boolean): Observable<FailoverChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastFailoverChannelsRefresh <= 60000) return this.failoverChannels;
        this.lastFailoverChannelsRefresh = _.now();

        const failoverChannels$ = this.http
            .get<APIResponse<FailoverChannel[]>>(Constants.apiUrl + Constants.apiUrls.channel + "/failover", {
                params: this.prepParams(ids)
            })
            .pipe(share());

        failoverChannels$.subscribe(
            data => {
                const failoverChannels: FailoverChannel[] = data.result;

                (ids || []).forEach(id => {
                    const newIndex = failoverChannels.findIndex(b => b.id === id);
                    if (newIndex === -1) {
                        const existingIndex = this.dataStore.failoverChannels.findIndex(b => b.id === id);
                        if (existingIndex !== -1) this.dataStore.failoverChannels.splice(existingIndex, 1);

                        const channelIndex = this.dataStore.channels.findIndex(
                            b => b.id === id && b.type === "failover"
                        );
                        if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                    }
                });

                failoverChannels.forEach(fc => this.updateFailoverStore(fc, true));

                this.failoverChannels$.next(Object.assign({}, this.dataStore).failoverChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_FAILOVER_CHANNELS"), error)
        );
        return failoverChannels$.pipe(map(f => f.result));
    }

    prepParams(ids: number[] | string[]) {
        let filter = new HttpParams();
        if (ids) {
            ids.forEach(id => {
                filter = filter.append("id", id);
            });
        }
        return filter;
    }

    refreshMediaLiveChannels(ids: number[], force?: boolean): Observable<MediaLiveChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastMediaLiveChannelsRefresh <= 60000) return this.medialiveChannels;
        this.lastMediaLiveChannelsRefresh = _.now();

        const medialiveChannels$ = this.http
            .get<APIResponse<MediaLiveChannel[]>>(Constants.apiUrl + Constants.apiUrls.channel + "/medialive", {
                params: this.prepParams(ids)
            })
            .pipe(share());

        medialiveChannels$.subscribe(
            data => {
                const medialiveChannels: MediaLiveChannel[] = data.result;

                (ids || []).forEach(id => {
                    const newIndex = medialiveChannels.findIndex(b => b.id === id);
                    if (newIndex === -1) {
                        const existingIndex = this.dataStore.medialiveChannels.findIndex(b => b.id === id);
                        if (existingIndex !== -1) this.dataStore.medialiveChannels.splice(existingIndex, 1);

                        const channelIndex = this.dataStore.channels.findIndex(
                            b => b.id === id && b.type === "medialive"
                        );
                        if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                    }
                });

                medialiveChannels.forEach(ac => this.updateMediaLiveStore(ac, true));

                this.medialiveChannels$.next(Object.assign({}, this.dataStore).medialiveChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIALIVE_CHANNELS"), error)
        );
        return medialiveChannels$.pipe(map(r => r.result));
    }

    refreshMediaLiveChannel(id: number, force?: boolean): Observable<MediaLiveChannel> {
        return this.refreshChannel(
            { id, type: "medialive", medialive: true },
            force,
            false
        ) as Observable<MediaLiveChannel>;
    }

    refreshMediaConnectFlows(ids: number[], force?: boolean): Observable<MediaConnectFlow[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastMediaConnectFlowsRefresh <= 60000) return this.mediaconnectFlows;
        this.lastMediaConnectFlowsRefresh = _.now();

        const mediaconnectFlows$ = this.http
            .get<APIResponse<MediaConnectFlow[]>>(
                Constants.apiUrl + Constants.apiUrls.channel + "/mediaconnect?update",
                {
                    params: this.prepParams(ids)
                }
            )
            .pipe(share());

        mediaconnectFlows$.subscribe(
            data => {
                const mediaconnectFlows: MediaConnectFlow[] = data.result;

                (ids || []).forEach(id => {
                    const newIndex = mediaconnectFlows.findIndex(b => b.id === id);
                    if (newIndex === -1) {
                        const existingIndex = this.dataStore.mediaconnectFlows.findIndex(b => b.id === id);
                        if (existingIndex !== -1) this.dataStore.mediaconnectFlows.splice(existingIndex, 1);

                        const channelIndex = this.dataStore.channels.findIndex(
                            b => b.id === id && b.type === "mediaconnect"
                        );
                        if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                    }
                });

                mediaconnectFlows.forEach(ac => this.updateMediaConnectStore(ac, true));

                this.mediaconnectFlows$.next(Object.assign({}, this.dataStore).mediaconnectFlows);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIACONNECT_FLOWS"), error)
        );
        return mediaconnectFlows$.pipe(map(r => r.result));
    }

    refreshAdaptiveChannels(ids: number[], force?: boolean): Observable<AdaptiveChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastAdaptiveChannelsRefresh <= 60000) return this.adaptiveChannels;
        this.lastAdaptiveChannelsRefresh = _.now();

        const adaptiveChannels$ = this.getAdaptiveChannelsNew(ids, true).pipe(
            takeWhile(adaptiveChannels => !!adaptiveChannels)
        );

        adaptiveChannels$.subscribe(adaptiveChannels => {
            (ids || []).forEach(id => {
                const newIndex = adaptiveChannels.findIndex(b => b.id === id);
                if (newIndex === -1) {
                    const existingIndex = this.dataStore.adaptiveChannels.findIndex(b => b.id === id);
                    if (existingIndex !== -1) this.dataStore.adaptiveChannels.splice(existingIndex, 1);

                    const channelIndex = this.dataStore.channels.findIndex(b => b.id === id && b.type === "adaptive");
                    if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                }
            });

            adaptiveChannels.forEach(ac => this.updateAdaptiveStore(ac, true));

            this.adaptiveChannels$.next(Object.assign({}, this.dataStore).adaptiveChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
        });
        return adaptiveChannels$;
    }

    refreshDeliveryChannels(ids: number[], force?: boolean): Observable<DeliveryChannel[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastDeliveryChannelsRefresh <= 60000) return this.deliveryChannels;
        this.lastDeliveryChannelsRefresh = _.now();

        const deliveryChannels$ = this.getDeliveryChannelsNew(ids, true).pipe(
            takeWhile(deliveryChannels => !!deliveryChannels)
        );

        deliveryChannels$.subscribe(deliveryChannels => {
            (ids || []).forEach(id => {
                const newIndex = deliveryChannels.findIndex(b => b.id === id);
                if (newIndex === -1) {
                    const existingIndex = this.dataStore.deliveryChannels.findIndex(b => b.id === id);
                    if (existingIndex !== -1) this.dataStore.deliveryChannels.splice(existingIndex, 1);

                    const channelIndex = this.dataStore.channels.findIndex(b => b.id === id && b.type === "delivery");
                    if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
                }
            });

            deliveryChannels.forEach(dc => this.updateDeliveryStore(dc, true));

            this.deliveryChannels$.next(Object.assign({}, this.dataStore).deliveryChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
        });
        return deliveryChannels$;
    }

    getPassThroughCachedChannelById(id: number): DeliveryChannel {
        if (!this.dataStore.channels) return undefined;

        return this.dataStore.deliveryChannels.find(channel => {
            return channel.id === id;
        });
    }

    getCachedAdaptiveChannel(id: number) {
        if (!this.dataStore.adaptiveChannels) return undefined;
        return this.dataStore.adaptiveChannels.find(channel => channel.id === id);
    }

    getCachedDeliveryChannel(id: number): DeliveryChannel {
        if (!this.dataStore.deliveryChannels) return undefined;
        return this.dataStore.deliveryChannels.find(channel => channel.id === id);
    }

    getCachedMediaConnectFlow(id: number): MediaConnectFlow {
        if (!this.dataStore.mediaconnectFlows) return undefined;
        return this.dataStore.mediaconnectFlows.find(channel => channel.id === id);
    }

    getCachedMediaLiveChannel(id: number): MediaLiveChannel {
        if (!this.dataStore.medialiveChannels) return undefined;
        return this.dataStore.medialiveChannels.find(channel => channel.id === id);
    }

    getCachedFailoverChannel(id: number): FailoverChannel {
        if (!this.dataStore.failoverChannels) return undefined;
        return this.dataStore.failoverChannels.find(channel => channel.id === id);
    }

    getCachedChannel(channel: ChannelTypes): ChannelTypes {
        if (!this.dataStore.channels) return undefined;
        return this.dataStore.channels.find(c => c.id === channel.id && c.type === channel.type);
    }

    refreshChannel(
        c: {
            id: number;
            type: string;
            adaptive?: boolean;
            delivery?: boolean;
            failover?: boolean;
            mediaconnect?: boolean;
            medialive?: boolean;
        },
        force?: boolean,
        fast?: boolean
    ): Observable<ChannelTypes> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && this.dataStore.channels?.length > 0) {
            const cachedChannel: ChannelTypes = this.dataStore.channels.find(
                sc => c.id === sc.id && sc.type === c.type
            );
            if (cachedChannel && cachedChannel.hasFullDetails) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(cachedChannel._frontData.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<ChannelTypes>) => {
                        observe.next(cachedChannel);
                        observe.complete();
                    });
                }
            }
        }

        const subject = new ReplaySubject<ChannelTypes>(1);
        const channel$ = this.http.get<APIResponse<any>>(
            Constants.apiUrl + Constants.apiUrls.channel + `/${c.type}/${c.id}` + (fast ? "?fast=1" : "")
        );

        if (c.adaptive) {
            channel$.subscribe({
                next: data => {
                    data.result.hasFullDetails = true;
                    const ac = this.updateAdaptiveStore(data.result, false);

                    this.adaptiveChannels$.next(Object.assign({}, this.dataStore).adaptiveChannels);
                    this.channels$.next(Object.assign({}, this.dataStore).channels);

                    subject.next(ac);
                    subject.complete();
                },
                // eslint-disable-next-line no-console
                error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"), error)
            });
        } else if (c.delivery) {
            channel$.subscribe({
                next: data => {
                    data.result.hasFullDetails = true;
                    const dc = this.updateDeliveryStore(data.result, false);

                    this.deliveryChannels$.next(Object.assign({}, this.dataStore).deliveryChannels);
                    this.channels$.next(Object.assign({}, this.dataStore).channels);

                    subject.next(dc);
                    subject.complete();
                },
                // eslint-disable-next-line no-console
                error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"), error)
            });
        } else if (c.failover) {
            channel$.subscribe({
                next: data => {
                    data.result.hasFullDetails = true;
                    data.result.deliveryChannel.hasFullDetails = true;
                    const fc = this.updateFailoverStore(data.result, false);

                    this.failoverChannels$.next(Object.assign({}, this.dataStore).failoverChannels);
                    this.channels$.next(Object.assign({}, this.dataStore).channels);

                    subject.next(fc);
                    subject.complete();
                },
                // eslint-disable-next-line no-console
                error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"), error)
            });
        } else if (c.mediaconnect) {
            channel$.subscribe({
                next: data => {
                    data.result.hasFullDetails = true;
                    const mcf = this.updateMediaConnectStore(data.result, false);

                    this.mediaconnectFlows$.next(Object.assign({}, this.dataStore).mediaconnectFlows);
                    this.channels$.next(Object.assign({}, this.dataStore).channels);

                    subject.next(mcf);
                    subject.complete();
                },
                // eslint-disable-next-line no-console
                error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"), error)
            });
        } else if (c.medialive) {
            channel$.subscribe({
                next: data => {
                    data.result.hasFullDetails = true;
                    const mlc = this.updateMediaLiveStore(data.result, false);

                    this.medialiveChannels$.next(Object.assign({}, this.dataStore).medialiveChannels);
                    this.channels$.next(Object.assign({}, this.dataStore).channels);

                    subject.next(mlc);
                    subject.complete();
                },
                // eslint-disable-next-line no-console
                error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"), error)
            });
        } else {
            subject.error("Unknown channel type");
            subject.complete();
        }

        return subject;
    }

    refreshAdaptiveChannel(id: number, force?: boolean, fast?: boolean): Observable<AdaptiveChannel> {
        return this.refreshChannel(
            { id, type: "adaptive", adaptive: true },
            force,
            fast
        ) as Observable<AdaptiveChannel>;
    }

    async getAdaptiveChannel(id: number, fast?: boolean) {
        try {
            const data = await this.http
                .get<APIResponse<AdaptiveChannel>>(
                    Constants.apiUrl + Constants.apiUrls.channel + "/adaptive/" + `${id}` + (fast ? "?fast=1" : "")
                )
                .toPromise();
            const ac = data.result;
            if (!fast) {
                ac.hasFullDetails = true;
                this.updateAdaptiveStore(ac, false);
            } else {
                this.updateAdaptiveStore(ac, true);
            }

            this.adaptiveChannels$.next(Object.assign({}, this.dataStore).adaptiveChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
            return ac;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"));
            return false;
        }
    }

    refreshFailoverChannel(id: number, force?: boolean, fast?: boolean): Observable<FailoverChannel> {
        return this.refreshChannel(
            { id, type: "failover", failover: true },
            force,
            fast
        ) as Observable<FailoverChannel>;
    }

    refreshDeliveryChannel(id: number, force?: boolean, fast?: boolean): Observable<DeliveryChannel> {
        return this.refreshChannel(
            { id, type: "delivery", delivery: true },
            force,
            fast
        ) as Observable<DeliveryChannel>;
    }

    async getDeliveryChannel(id: number, fast?: boolean) {
        try {
            const data = await this.http
                .get<APIResponse<DeliveryChannel>>(
                    Constants.apiUrl + Constants.apiUrls.channel + "/delivery/" + `${id}` + (fast ? "?fast=1" : "")
                )
                .toPromise();
            const dc = data.result;
            if (!fast) {
                dc.hasFullDetails = true;
                this.updateDeliveryStore(dc, false);
            } else {
                this.updateDeliveryStore(dc, true);
            }

            this.deliveryChannels$.next(Object.assign({}, this.dataStore).deliveryChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
            return dc;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"));
            return false;
        }
    }

    refreshMediaConnectFlow(id: number, force?: boolean): Observable<MediaConnectFlow> {
        return this.refreshChannel(
            { id, type: "mediaconnect", mediaconnect: true },
            force,
            false
        ) as Observable<MediaConnectFlow>;
    }

    async getMediaConnectFlow(id: number) {
        try {
            const data = await this.http
                .get<APIResponse<MediaConnectFlow>>(
                    Constants.apiUrl + Constants.apiUrls.channel + "/mediaconnect/" + `${id}`
                )
                .toPromise();
            const mcf: MediaConnectFlow = data.result;
            mcf.hasFullDetails = true;
            this.updateMediaConnectStore(mcf, false);
            this.mediaconnectFlows$.next(Object.assign({}, this.dataStore).mediaconnectFlows);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
            return mcf;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"));
            return false;
        }
    }

    async getMediaLiveChannel(id: number) {
        try {
            const data = await this.http
                .get<APIResponse<MediaLiveChannel>>(
                    Constants.apiUrl + Constants.apiUrls.channel + "/medialive/" + `${id}`
                )
                .toPromise();
            const mcf: MediaLiveChannel = data.result;
            mcf.hasFullDetails = true;
            this.updateMediaLiveStore(mcf, false);
            this.medialiveChannels$.next(Object.assign({}, this.dataStore).medialiveChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
            return mcf;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"));
            return false;
        }
    }

    async getFailoverChannel(id: number) {
        try {
            const data = await this.http
                .get<APIResponse<FailoverChannel>>(
                    Constants.apiUrl + Constants.apiUrls.channel + "/failover/" + `${id}`
                )
                .toPromise();
            const c: FailoverChannel = data.result;
            c.hasFullDetails = true;
            this.updateFailoverStore(c, false);
            this.failoverChannels$.next(Object.assign({}, this.dataStore).failoverChannels);
            this.channels$.next(Object.assign({}, this.dataStore).channels);
            return c;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_CHANNEL"));
            return false;
        }
    }

    async deleteChannel(channel: ChannelTypes, awsRelease?: boolean) {
        try {
            let result;
            if (awsRelease) {
                // Amit check this
                const options = {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json"
                    }),
                    body: {
                        aws_release: awsRelease
                    }
                };

                result = await this.http
                    .delete<APIResponse<number>>(
                        Constants.apiUrl + Constants.apiUrls.channel + "/" + channel.type + "/" + `${channel.id}`,
                        options
                    )
                    .toPromise();
            } else {
                result = await this.http
                    .delete<APIResponse<number>>(
                        Constants.apiUrl + Constants.apiUrls.channel + "/" + channel.type + "/" + `${channel.id}`
                    )
                    .toPromise();
            }

            const deletedId = result.result;

            if (channel.adaptive) {
                const adaptiveChannelIndex = this.dataStore.adaptiveChannels.findIndex(c => c.id === deletedId);
                if (adaptiveChannelIndex !== -1) this.dataStore.adaptiveChannels.splice(adaptiveChannelIndex, 1);
                this.adaptiveChannels$.next(Object.assign({}, this.dataStore).adaptiveChannels);
            } else if (channel.delivery) {
                const deliveryChannelIndex = this.dataStore.deliveryChannels.findIndex(c => c.id === deletedId);
                if (deliveryChannelIndex !== -1) this.dataStore.deliveryChannels.splice(deliveryChannelIndex, 1);
                this.deliveryChannels$.next(Object.assign({}, this.dataStore).deliveryChannels);
            } else if (channel.mediaconnect) {
                const index = this.dataStore.mediaconnectFlows.findIndex(c => c.id === deletedId);
                if (index !== -1) this.dataStore.mediaconnectFlows.splice(index, 1);
                this.mediaconnectFlows$.next(Object.assign({}, this.dataStore).mediaconnectFlows);
            } else if (channel.medialive) {
                const index = this.dataStore.medialiveChannels.findIndex(c => c.id === deletedId);
                if (index !== -1) this.dataStore.medialiveChannels.splice(index, 1);
                this.medialiveChannels$.next(Object.assign({}, this.dataStore).medialiveChannels);
            }

            const channelIndex = this.dataStore.channels.findIndex(c => c.id === deletedId && c.type === channel.type);
            if (channelIndex !== -1) this.dataStore.channels.splice(channelIndex, 1);
            this.channels$.next(Object.assign({}, this.dataStore).channels);

            return true;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_DELETE_CHANNEL"));
            // eslint-disable-next-line no-console
            console.error("deleteChannel ERROR", error);
            return false;
        }
    }

    async addChannel(model: Record<string, unknown>, type: string): Promise<false | ChannelTypes> {
        try {
            const result = await this.http
                .post<APIResponse<ChannelTypes>>(Constants.apiUrl + Constants.apiUrls.channel + "/" + type, model)
                .toPromise();

            if (result.result.type === "adaptive") result.result.adaptive = true;
            else if (result.result.type === "delivery") result.result.delivery = true;
            else if (result.result.type === "mediaconnect") result.result.mediaconnect = true;
            else if (result.result.type === "medialive") result.result.medialive = true;
            else if (result.result.type === "failover") result.result.failover = true;

            if (result.result.adaptive) {
                const channel: AdaptiveChannel = result.result;

                this.updateAdaptiveStore(channel, false);

                this.adaptiveChannels$.next(Object.assign({}, this.dataStore).adaptiveChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            } else if (result.result.delivery) {
                const channel: DeliveryChannel = result.result;

                this.updateDeliveryStore(channel, false);

                this.deliveryChannels$.next(Object.assign({}, this.dataStore).deliveryChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            } else if (result.result.mediaconnect) {
                const channel: MediaConnectFlow = result.result;

                this.updateMediaConnectStore(channel, false);

                this.mediaconnectFlows$.next(Object.assign({}, this.dataStore).mediaconnectFlows);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            } else if (result.result.medialive) {
                const channel: MediaLiveChannel = result.result;

                this.updateMediaLiveStore(channel, false);

                this.medialiveChannels$.next(Object.assign({}, this.dataStore).medialiveChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            } else if (result.result.failover) {
                const channel: FailoverChannel = result.result;

                this.updateFailoverStore(channel, false);

                this.failoverChannels$.next(Object.assign({}, this.dataStore).failoverChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);
            }

            return result.result;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`addChannel ${type} ERROR`, error);
            return false;
        }
    }

    async updateChannel(channel: ChannelTypes, model: Record<string, unknown>) {
        try {
            if (channel.adaptive) {
                const result = await firstValueFrom(
                    this.http.put<APIResponse<AdaptiveChannel>>(
                        Constants.apiUrl + Constants.apiUrls.channel + "/adaptive/" + channel.id,
                        model
                    )
                );

                const updatedChannel: AdaptiveChannel = result.result;

                this.updateAdaptiveStore(updatedChannel, true);

                this.adaptiveChannels$.next(Object.assign({}, this.dataStore).adaptiveChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);

                return result.result;
            } else if (channel.delivery) {
                const result = await firstValueFrom(
                    this.http.put<APIResponse<DeliveryChannel>>(
                        Constants.apiUrl + Constants.apiUrls.channel + "/delivery/" + channel.id,
                        model
                    )
                );

                const updatedChannel: DeliveryChannel = result.result;

                this.updateDeliveryStore(updatedChannel, true);

                this.deliveryChannels$.next(Object.assign({}, this.dataStore).deliveryChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);

                return result.result;
            } else if (channel.mediaconnect && !channel.medialive) {
                const result = await firstValueFrom(
                    this.http.put<APIResponse<MediaConnectFlow>>(
                        Constants.apiUrl + Constants.apiUrls.channel + "/mediaconnect/" + channel.id,
                        model
                    )
                );

                const updatedChannel: MediaConnectFlow = result.result;

                this.updateMediaConnectStore(updatedChannel, true);

                this.mediaconnectFlows$.next(Object.assign({}, this.dataStore).mediaconnectFlows);
                this.channels$.next(Object.assign({}, this.dataStore).channels);

                return result.result;
            } else if (channel.medialive) {
                const result = await firstValueFrom(
                    this.http.put<APIResponse<MediaLiveChannel>>(
                        Constants.apiUrl + Constants.apiUrls.channel + "/medialive/" + channel.id,
                        model
                    )
                );

                const updatedChannel: MediaLiveChannel = result.result;

                this.updateMediaLiveStore(updatedChannel, true);

                this.medialiveChannels$.next(Object.assign({}, this.dataStore).medialiveChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);

                return result.result;
            } else if (channel.failover) {
                const result = await firstValueFrom(
                    this.http.put<APIResponse<FailoverChannel>>(
                        Constants.apiUrl + Constants.apiUrls.channel + "/failover/" + channel.id,
                        model
                    )
                );

                const updatedChannel: FailoverChannel = result.result;

                this.updateFailoverStore(updatedChannel, true);

                this.failoverChannels$.next(Object.assign({}, this.dataStore).failoverChannels);
                this.channels$.next(Object.assign({}, this.dataStore).channels);

                return result.result;
            }
        } catch (error) {
            if (error.status === 428) return true;
            else return false;
        }
    }

    // getResourceTags
    getResourceTags(type: string): Observable<Tag[]> {
        const tags$ = this.http.get<APIResponse<Tag[]>>(
            Constants.apiUrl + Constants.apiUrls.resourceTags + "/" + (type === "adaptive" ? "adaptive" : "delivery")
        );
        return tags$.pipe(map(r => r.result));
    }

    // Open Channel Preview
    openChannelPreview(channel: AdaptiveChannel | DeliveryChannel) {
        const host = window.location.origin;
        window.open(
            host + "/play?url=" + this.urlBuilderService.encodeRFC3986URIComponent(channel.view_url),
            "zenplayer",
            "width=640,height=470"
        );
    }

    async toggleFailoverComponentSourceLock(
        failoverChannel: FailoverChannel,
        component_source_id: number,
        lock: boolean
    ) {
        try {
            const result = await this.http
                .put<{ success: boolean }>(
                    Constants.apiUrl +
                        Constants.apiUrls.channel +
                        "/failover/" +
                        failoverChannel.id +
                        "/lock/" +
                        component_source_id,
                    {
                        locked: lock ? 1 : 0
                    }
                )
                .toPromise();

            if (!result.success) return false;
            return true;
        } catch (error) {
            return false;
        }
    }

    getMediaConnectFlowChannelsNew(ids: number[]) {
        const params = ids ? this.prepParams(ids) : new HttpParams();
        const req$ = this.http.get<APIResponse<MediaConnectFlow[]>>(
            Constants.apiUrl + Constants.apiUrls.channel + "/mediaconnect",
            { params }
        );
        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIA_CONNECT_CHANNELS"), error);
                return of(null);
            }),
            share()
        );
    }

    getMediaLiveChannelsNew(ids: number[]): Observable<MediaLiveChannel[]> {
        const params = ids ? this.prepParams(ids) : new HttpParams();
        const req$ = this.http.get<APIResponse<MediaLiveChannel[]>>(
            Constants.apiUrl + Constants.apiUrls.channel + "/medialive",
            { params }
        );
        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_MEDIA_LIVE_CHANNELS"), error);
                return of(null);
            }),
            share()
        );
    }

    getFailoverChannelsNew(ids: number[]) {
        const params = ids ? this.prepParams(ids) : new HttpParams();
        const req$ = this.http.get<APIResponse<FailoverChannel[]>>(
            Constants.apiUrl + Constants.apiUrls.channel + "/failover",
            { params }
        );
        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_FAILOVER_CHANNELS"), error);
                return of(null);
            }),
            share()
        );
    }

    getDisasterRecoveryState(channel: ChannelTypes): RecoveryState {
        if (channel.mediaconnect || channel.medialive) return RecoveryState.none;
        let channelOnly: AdaptiveChannel | DeliveryChannel;

        if (channel.failover) {
            if (!channel.deliveryChannel) return RecoveryState.none;
            channelOnly = channel.deliveryChannel;
        } else {
            channelOnly = channel as AdaptiveChannel | DeliveryChannel;
        }

        if (channelOnly.primary_broadcaster_cluster_id === undefined) return RecoveryState.none;

        if (channelOnly.primary_broadcaster_cluster_id === channelOnly.broadcaster_cluster_id) {
            if (channelOnly.processingCluster.alt_cluster_id) return RecoveryState.primary;
            else return RecoveryState.none;
        }
        return RecoveryState.alternative;
    }

    toggleDisasterRecoveryState(
        channel: DeliveryChannel | AdaptiveChannel | FailoverChannel,
        targetBroadcasterId: number | null
    ) {
        const state = this.getDisasterRecoveryState(channel);
        if (state === RecoveryState.none) return;

        const channelOnly = channel.deliveryChannel
            ? channel.deliveryChannel
            : (channel as AdaptiveChannel | DeliveryChannel);

        const model: any = {};

        // in db, adaptive channel calls it "broadcaster_id" and delivery channel calls it "target_broadcaster_id"
        if (state === RecoveryState.primary) {
            model.broadcaster_cluster_id = channelOnly.processingCluster.alt_cluster_id;
            if (!targetBroadcasterId)
                targetBroadcasterId = channelOnly.adaptive
                    ? channelOnly.broadcaster_id
                    : (channelOnly as DeliveryChannel).target_broadcaster_id;
            if (channelOnly.adaptive) model.broadcaster_id = targetBroadcasterId;
            else model.target_broadcaster_id = targetBroadcasterId;
        } else {
            model.broadcaster_cluster_id = channelOnly.primary_broadcaster_cluster_id;
            if (channelOnly.adaptive) model.broadcaster_id = channelOnly.primary_target_broadcaster_id;
            else model.target_broadcaster_id = channelOnly.primary_target_broadcaster_id;
        }

        return this.updateChannel(channel, model);
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half  (relevant when providing specific ids).
     * @returns Observable resolve with an array of delivery channels or in case of error it resolves with null and log the error to the console
     */
    getDeliveryChannelsNew(
        ids: number[] = null,
        isGetUpdatedInTheLast90Seconds = false
    ): Observable<DeliveryChannel[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }
        const req$ = this.http.get<APIResponse<DeliveryChannel[]>>(
            Constants.apiUrl + Constants.apiUrls.channel + "/delivery",
            { params }
        );

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_DELIVERY_CHANNELS"), error);
                return of(null);
            }),
            share()
        );
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half (relevant when providing specific ids).
     * @returns Observable resolve with an array of adaptive channels or in case of error it resolves with null and log the error to the console
     */
    getAdaptiveChannelsNew(
        ids: number[] = null,
        isGetUpdatedInTheLast90Seconds = false
    ): Observable<AdaptiveChannel[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }
        const req$ = this.http.get<APIResponse<AdaptiveChannel[]>>(
            Constants.apiUrl + Constants.apiUrls.channel + "/adaptive",
            { params }
        );

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ADAPTIVE_CHANNELS"), error);
                return of(null);
            }),
            share()
        );
    }

    async refreshAdMarksEvents(channelId: number, { pageSize }: PageInfo, offset = 0) {
        const params = { pageSize, offset };
        const req$ = this.http
            .get<APIResponse<AdMarkEvent[]>>(
                Constants.apiUrl + Constants.apiUrls.adaptiveChannel + "/" + +channelId + Constants.apiUrls.adMarks,
                { params }
            )
            .pipe(
                map(response => {
                    if (!response.success) {
                        throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                    }
                    return response.result;
                }),
                catchError(error => {
                    // eslint-disable-next-line no-console
                    console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ADAPTIVE_CHANNELS"), error);
                    return of([]);
                })
            );

        const newAdMarks = await firstValueFrom(req$);
        if (offset) {
            const adMarks = await firstValueFrom(this.adMarks$);
            this.adMarks$.next(adMarks.concat(newAdMarks));
        } else {
            this.adMarks$.next(newAdMarks);
        }
    }

    getAWSRegionName(region: string) {
        return Constants.awsRegions.find(r => r.value === region).name;
    }

    processChannelType(channel) {
        if (channel.is_transcoding) return "transcoding";
        if (channel.type === "delivery") return "pass-through";
        return channel.type;
    }

    getFlowLink(channel) {
        return `https://console.aws.amazon.com/mediaconnect/home?region=${
            channel.region
        }#/flows/${this.urlBuilderService.encodeRFC3986URIComponent(channel.arn)}`;
    }

    getFlowName(channel) {
        const split = channel.arn.split(":");
        return split[split.length - 1];
    }

    getChannelLink(channel) {
        return `https://console.aws.amazon.com/medialive/home?region=${channel.region}#!/channels/${this.getChannelId(
            channel
        )}`;
    }

    getChannelId(channel) {
        const split = channel.arn.split(":");
        return split[split.length - 1];
    }
}
