import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateService } from "@ngx-translate/core";
import { TourService } from "ngx-ui-tour-md-menu";
import { Constants } from "src/app/constants/constants";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-form-title",
    templateUrl: "./form-title.component.html",
    styleUrls: ["./form-title.component.scss"]
})
export class FormTitleComponent implements OnChanges {
    @Input() isEdit = false;
    @Input() isClone = false;
    @Input() titles: string[];
    @Input() classForTitle = Constants.FORM_COL_CLASS_BREAKPOINTS;
    @Input() hasTourStartButton? = false;
    @Input() openTitleToolTip? = false;
    @Input() tourStartTooltipString?: string;
    @Input() mixpanelString?: string;
    @ViewChild("tooltip", { static: false }) tourTooltip: MatTooltip;

    constructor(
        private translate: TranslateService,
        public tourService: TourService,
        public mixpanelService: MixpanelService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.openTitleToolTip?.currentValue) {
            setTimeout(() => {
                this.tourTooltip?.toggle();
            });
        }
    }

    get translatedTitles() {
        return this.titles.map(t => this.translate.instant(t)).join(" ");
    }
}
