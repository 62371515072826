import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { Observable, Subscription } from "rxjs";

import { MediaConnectSourcesService } from "../../../pages/sources/mediaconnect-sources.service";
import { MediaConnectSource } from "./../../../models/shared";

@Component({
    selector: "zx-mediaconnect-source-select",
    templateUrl: "./zx-mediaconnect-source-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMediaConnectSourceSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: MediaConnectSource;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Input() filter?: (s: MediaConnectSource) => boolean;
    @Input() rescanSourcesEvent?: Observable<void>;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    allSources: MediaConnectSource[] = [];
    sources: MediaConnectSource[];
    private sourcesSubscription: Subscription;
    private rescanSubscription: Subscription;

    constructor(private ss: MediaConnectSourcesService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.ss.refreshMediaConnectSources(true, true);
        this.sourcesSubscription = this.ss.mediaconnectSources.subscribe(sources => {
            this.allSources = [...sources];
            this.loading = false;
            this.rescan();
        });

        if (this.rescanSourcesEvent) {
            this.rescanSubscription = this.rescanSourcesEvent.subscribe(() => {
                this.rescan();
            });
        }
    }

    ngOnDestroy() {
        this.sourcesSubscription.unsubscribe();
        if (this.rescanSubscription) this.rescanSubscription.unsubscribe();
    }

    rescan() {
        this.sources = this.allSources;
        if (this.filter) this.sources = this.sources.filter(this.filter);
    }
}
