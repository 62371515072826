import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription, firstValueFrom } from "rxjs";

import { SourcesService } from "../../../pages/sources/sources.service";
import { Source } from "./../../../models/shared";

@Component({
    selector: "zx-source-select",
    templateUrl: "./zx-source-select.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ZxSourceSelectComponent),
            multi: true
        }
    ]
})
export class ZxSourceSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() filter?: (s: Source) => boolean;
    @Input() clearable?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    sources: Source[];
    unfilteredSources: Source[];
    private sourcesSubscription: Subscription;

    constructor(private ss: SourcesService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    clicked() {
        if (this.filter) {
            this.sources = this.unfilteredSources.filter(this.filter);
        }
    }

    async ngOnInit() {
        this.loading = true;

        if (!this.clearable) this.clearable = false;

        await firstValueFrom(this.ss.refreshSources(true, true));
        this.sourcesSubscription = this.ss.sources.subscribe(sources => {
            this.unfilteredSources = sources?.filter(s => !s.is_hidden) || [];
            if (this.filter && !this.model) {
                this.sources = this.unfilteredSources.filter(this.filter);
            } else {
                this.sources = this.unfilteredSources;
            }
            this.loading = false;
        });
    }

    _onChange: () => void;
    _onTouched: () => void;

    writeValue(): void {
        return;
    }

    registerOnChange(onChange: () => void): void {
        this._onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this._onTouched = onTouched;
    }

    ngOnDestroy() {
        this.sourcesSubscription?.unsubscribe();
    }
}
