import { Component, Input, OnInit } from "@angular/core";
import { MultiviewSource } from "@zixi/models";
import { MultiplexSource, ObjectStates } from "src/app/models/shared";

type SourcesSummery = {
    [key in ObjectStates]: number;
};
@Component({
    selector: "app-summary-sources-col",
    templateUrl: "./summary-sources-col.component.html"
})
export class SummarySourcesColComponent implements OnInit {
    @Input() sources: MultiviewSource[] | MultiplexSource[];
    sourcesSummery: SourcesSummery = { success: 0, error: 0, warning: 0 };

    ngOnInit(): void {
        for (const source of this.sources) {
            this.sourcesSummery[source.source?.objectState?.state]++;
        }
    }
}
