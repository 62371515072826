import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { MultiViewer } from "../../../pages/multi-viewers/multi-viewer.model";
import { MultiViewerService } from "../../../pages/multi-viewers/multi-viewer.service";

@Component({
    selector: "zx-multiviewer-select",
    templateUrl: "./zx-multiviewer-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMultiviewerSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number;
    @Input() set multiViewersType(type: string) {
        this.selectedMultiViewersType = type;
        this.updateMultiViewersByType();
    }
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Output() modelChange = new EventEmitter();
    @Output() multiViewerSelectedChange = new EventEmitter();

    loading: boolean;
    filteredMultiViewers: MultiViewer[];
    private multiViewersSubscription: Subscription;
    private selectedMultiViewersType = "zixi";

    constructor(private multiViewersService: MultiViewerService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
        if (this.model > 0) {
            this.multiViewerSelectedChange.emit();
        }
    }

    updateMultiViewersByType() {
        this.multiViewersService.refreshMultiViewers();
    }

    ngOnInit() {
        this.loading = true;
        this.multiViewersSubscription = this.multiViewersService.multiViewers$.subscribe(multiViewers => {
            this.filteredMultiViewers = multiViewers.filter(mv => mv.type === this.selectedMultiViewersType);
            this.loading = false;
        });
        this.loading = false;
    }

    ngOnDestroy() {
        this.multiViewersSubscription.unsubscribe();
    }
}
