import { Component, Input } from "@angular/core";
import { ChannelsService } from "../../channels.service";
import { AdaptiveChannel, DeliveryChannel } from "../../channel";

@Component({
    selector: "app-channel-alerts",
    templateUrl: "./channel-alerts.component.html"
})
export class ChannelAlertsComponent {
    @Input() channel: AdaptiveChannel | DeliveryChannel;
    @Input() canEdit: boolean;

    constructor(private cs: ChannelsService) {}

    public refreshChannel = () => {
        this.cs.refreshChannel(this.channel, true);
    };
}
