import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Constants } from "src/app/constants/constants";
//
import { Incident } from "src/app/pages/incidents/incident";
import { IncidentsService } from "src/app/pages/incidents/incidents.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { UserPermissions } from "src/app/models/shared";

@Component({
    selector: "app-root-cause-attribution-dialog",
    templateUrl: "./root-cause-attribution-dialog.component.html"
})
export class RootCauseAttributionDialogComponent implements OnInit, OnDestroy {
    incident: Incident;
    incidentCopy: Incident;

    userPermissions: UserPermissions;
    isZixiUser: boolean;
    correctCause = true;
    possibleCauses = ["Broadcaster", "Content Analysis", "Client Network", "Server Network", "Performance", "Other"];
    likelyCause: string = null;
    describeRootCause: string = null;
    name: string = null;
    state: string = null;

    loading: boolean;
    saving = false;
    constants = Constants;
    minLength = 3;

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private is: IncidentsService,
        private mixpanelService: MixpanelService,
        private userService: UsersService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });
    }

    async ngOnInit() {
        this.incidentCopy = Object.assign({}, this.incident);
        //
        if (this.incidentCopy.configuration?.rca && this.incidentCopy.configuration?.rca?.correctCause === false)
            this.correctCause = this.incidentCopy.configuration.rca.correctCause;
        if (this.incidentCopy.configuration?.rca?.likelyCause)
            this.likelyCause = this.incidentCopy.configuration?.rca?.likelyCause;
        if (this.incidentCopy.configuration?.rca?.describeRootCause)
            this.describeRootCause = this.incidentCopy.configuration?.rca?.describeRootCause;

        this.isZixiUser = !!(
            this.userPermissions.is_zixi_admin ||
            this.userPermissions.is_zixi_support_write ||
            this.userPermissions.is_zixi_support
        );
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        //
        const model = {
            name: this.incidentCopy.name,
            likely_cause: this.incidentCopy.likely_cause,
            state: this.incidentCopy.state,
            start_time: this.incidentCopy.start_time,
            end_time: this.incidentCopy.end_time,
            configuration: this.incidentCopy.configuration,
            is_public: !!this.incidentCopy.is_public,
            is_zixi: !!this.incidentCopy.is_zixi
        };

        if (this.incidentCopy.state === "closed") {
            model.configuration.rca = {
                correctCause: this.correctCause,
                likelyCause: this.correctCause ? this.incidentCopy.likely_cause : this.likelyCause,
                describeRootCause: this.likelyCause === "Other" ? this.describeRootCause : null
            };
        } else {
            model.configuration.rca = {};
        }

        const result = await this.is.updateIncident(this.incident.id, model);
        if (result !== false) {
            this.mixpanelService.sendEvent("edit incident", {
                id: this.incident.id
            });
            this.saving = false;
            this.activeModal.close(result);
        } else {
            this.saving = false;
        }
    }
}
