import { Pipe, PipeTransform } from "@angular/core";
import { OutputNIC } from "./../models/shared";

@Pipe({
    name: "receiverOutputTarget"
})
export class ReceiverOutputTargetPipe implements PipeTransform {
    transform(output: OutputNIC): string {
        return output.target;
    }
}
