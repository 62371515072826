import { Component, Output, EventEmitter, HostBinding, OnInit, OnDestroy } from "@angular/core";
import { TourService } from "ngx-ui-tour-md-menu";
import { Subscription, fromEvent } from "rxjs";
import { takeUntil, take, delay } from "rxjs/operators";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "zx-splitter",
    templateUrl: "./zx-splitter.component.html",
    styleUrls: ["./zx-splitter.component.scss"]
})
export class ZxSplitterComponent implements OnInit, OnDestroy {
    minWidth = 360;
    splitterPosition = null;

    @Output() resizing = new EventEmitter<boolean>();
    @HostBinding("class") class = "d-none d-lg-flex";
    showMoveButtons = false;

    private tourSubscription: Subscription;
    private tourEndSubscription: Subscription;

    constructor(private sharedService: SharedService, public tourService: TourService) {
        this.resizing.emit(false);
        this.sharedService.getSplitterPosition.pipe(take(1)).subscribe(n => {
            if (n) {
                this.splitterPosition = n;
                const width: HTMLElement = document.getElementById("panel-container");
                const container: HTMLElement = document.getElementById("left-container");
                let lastPanelWidth = null;
                if (this.splitterPosition === 1) {
                    lastPanelWidth = container.style.flexBasis = 16 + "px";
                } else if (this.splitterPosition === 2) {
                    lastPanelWidth = container.style.flexBasis = 50 + "%";
                } else if (this.splitterPosition === 3) {
                    lastPanelWidth = container.style.flexBasis = width.clientWidth - 16 + "px";
                }
            }
        });
    }

    ngOnInit() {
        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.anchorId === "eleventhCustomLayoutAnchor") this.showMoveButtons = false;
            if (step.step.anchorId === "twelfthCustomLayoutAnchor") this.showMoveButtons = true;
        });

        this.tourEndSubscription = this.tourService.end$.subscribe(end => {
            this.showMoveButtons = false;
        });
    }

    ngOnDestroy() {
        this.tourSubscription.unsubscribe();
        this.tourEndSubscription.unsubscribe();
    }

    contractLeftSide() {
        this.resizing.emit(false);
        const container: HTMLElement = document.getElementById("left-container");
        let lastPanelWidth = null;
        if (this.splitterPosition === 2) {
            this.splitterPosition = 1;
            this.sharedService.setSplitterPosition(1);
            lastPanelWidth = container.style.flexBasis = 16 + "px";
        } else if (this.splitterPosition === 3) {
            this.splitterPosition = 2;
            this.sharedService.setSplitterPosition(2);
            lastPanelWidth = container.style.flexBasis = 50 + "%";
        } else {
            this.splitterPosition = 1;
            this.sharedService.setSplitterPosition(1);
            lastPanelWidth = container.style.flexBasis = 16 + "px";
        }
        localStorage.setItem("splitter-width", lastPanelWidth);
    }

    contractRightSide() {
        this.resizing.emit(false);
        const width: HTMLElement = document.getElementById("panel-container");
        const container: HTMLElement = document.getElementById("left-container");
        let lastPanelWidth = null;
        if (this.splitterPosition === 1) {
            this.splitterPosition = 2;
            this.sharedService.setSplitterPosition(2);
            lastPanelWidth = container.style.flexBasis = 50 + "%";
        } else if (this.splitterPosition === 2) {
            this.splitterPosition = 3;
            this.sharedService.setSplitterPosition(3);
            lastPanelWidth = container.style.flexBasis = width.clientWidth - 16 + "px";
        } else {
            this.splitterPosition = 3;
            this.sharedService.setSplitterPosition(3);
            lastPanelWidth = container.style.flexBasis = width.clientWidth - 16 + "px";
        }
        localStorage.setItem("splitter-width", lastPanelWidth);
    }

    // page spliter functions
    startResizeDrag() {
        const mouseUp = fromEvent(window, "mouseup");
        let lastPanelWidth = null;
        this.resizing.emit(true);
        //
        fromEvent(window, "mousemove")
            .pipe(takeUntil(mouseUp.pipe(take(1))))
            .subscribe((event: MouseEvent) => {
                this.splitterPosition = null;
                this.sharedService.setSplitterPosition(null);
                const leftContainer = document.getElementById("left-container");
                const outletContainer = document.getElementById("outlet-container");
                // Get offset
                const containerOffsetLeft = outletContainer.offsetLeft;
                // Get x-coordinate of pointer relative to container
                const pointerRelativeXpos = event.clientX - containerOffsetLeft;
                // Get window inner width
                const innerWidth = window.innerWidth;
                // Resize list
                lastPanelWidth = leftContainer.style.flexBasis = this.listWidth(innerWidth, pointerRelativeXpos);
            });

        mouseUp.pipe(take(1), delay(50)).subscribe(() => {
            if (lastPanelWidth) {
                localStorage.setItem("splitter-width", lastPanelWidth);
            }
            this.resizing.emit(false);
            this.sharedService.splitterResized$.next();
        });
    }

    private listWidth(innerWidth: number, pointerRelativeXpos: number) {
        const maxWidth = innerWidth / 2;
        return Math.max(this.minWidth, Math.min(maxWidth, pointerRelativeXpos)) + "px";
    }
}
