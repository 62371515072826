import { Component, Input } from "@angular/core";

@Component({
    selector: "zx-channel-type",
    templateUrl: "./zx-channel-type.component.html"
})
export class ZxChannelTypeComponent {
    tagsString = "";

    @Input() searchTerm?: string[] | string;
    @Input() channelType: string;
}
