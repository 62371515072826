<div class="ellipsis d-inline" *ngIf="model">
    <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'NETWORK' | translate }}">N</span>
    <zx-status-full
        [icon]="showStatusIcon"
        [model]="model"
        [status]="status || (model.generalStatus | statusClass)"
        [showStatusText]="showStatusText"
        [showOtherIcons]="showOtherIcons"
    ></zx-status-full>
    <a *ngIf="showLink" [routerLink]="['/' + urls.networks, model.id]" (click)="$event.stopPropagation()"
        ><ngb-highlight [result]="prettyName" [term]="searchTerm"></ngb-highlight
    ></a>
    <span *ngIf="!showLink"><ngb-highlight [result]="prettyName" [term]="searchTerm"></ngb-highlight></span>
</div>
