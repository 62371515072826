import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-notifying-message",
    templateUrl: "./notifying-message.component.html"
})
export class NotifyingMessageComponent implements OnInit {
    @Input() message: string;
    @ViewChild(NgbAlert, { static: false }) alert: NgbAlert;

    ngOnInit(): void {
        setTimeout(() => this.alert.close(), 5000);
    }
}
