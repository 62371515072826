import React from "react";
import { NodeProps, NodeResizer } from "reactflow";

export type GroupNodeData = {
    label: string;
};

export default function GroupNode({ data, selected }: NodeProps<GroupNodeData>) {
    return (
        <>
            <NodeResizer isVisible={selected} minWidth={100} minHeight={100} />
            <div className="label">{data.label}</div>
        </>
    );
}
