import { Pipe, PipeTransform } from "@angular/core";
import { FeederInput } from "./../models/shared";

@Pipe({
    name: "feederInputSource"
})
export class FeederInputSourcePipe implements PipeTransform {
    transform(input: FeederInput): string {
        if (input.rtmp) {
            return input.source;
        } else if (input.asi) {
            return input.asi_port;
        } else if (input.file) {
            return input.source;
        } else if (input.udp) {
            return [
                input.multicast_ip === "0.0.0.0" || input.multicast_ip === "" ? "" : [input.multicast_ip, ":"].join(""),
                input.port,
                input.ip === "" || input.ip === "127.0.0.1" || input.ip === "0.0.0.0" ? "" : ["@", input.ip].join("")
            ].join("");
        } else {
            return "";
        }
    }
}
