import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { ZixiObject } from "src/app/models/shared";
import { FlexGraphsService } from "src/app/services/flex-graphs.service";
import { grafanaFlexEncoder as FlexDashboardEncoder } from "@zixi/shared-utils";
import { Subscription } from "rxjs";
import { NavigationService } from "../navigation/navigation.service";
import { AnyTarget, TargetApiType } from "src/app/pages/channels/channel";

@Component({
    selector: "app-list-insights",
    templateUrl: "./list-insights.component.html"
})
export class ListInsightsComponent implements OnChanges, OnDestroy {
    @Input() objects?: ZixiObject[];
    @Input() targets?: AnyTarget[];
    @Input() panels: FlexDashboardEncoder.FlexPanelID[] = [];
    @Input() objectsType?: FlexDashboardEncoder.FlexObjectTypeID;

    insightsUrls: string[] = [];

    darkMode: boolean;
    darkModeSubscription: Subscription;

    constructor(private flexGraphs: FlexGraphsService, private navigationService: NavigationService) {
        this.darkModeSubscription = this.navigationService.isDarkMode.subscribe(b => {
            if (this.darkMode !== b) {
                this.darkMode = b;
                this.reloadGraphs();
            }
        });
    }

    ngOnChanges() {
        this.reloadGraphs();
    }

    reloadGraphs() {
        const panelConfig: FlexDashboardEncoder.FlexDashboardConfig = {
            graph: "source-insights",
            graphId: 0,
            columns: 3,
            duration: "7d",
            legend: false,
            annotations: false,
            dark: this.darkMode,
            panels: []
        };

        for (const panel of this.panels) {
            if (this.objectsType && this.objects)
                panelConfig.panels.push({
                    panel: panel,
                    objectType: this.objectsType,
                    objectIds: this.objects.slice(0, 10).map(s => s.id)
                });
            else if (this.targets) {
                const panelObjectType = panel.slice(0, panel.indexOf("-")) as FlexDashboardEncoder.FlexObjectTypeID;
                const relevantTargets = this.targets
                    .filter(t => {
                        switch (t.apiType) {
                            case TargetApiType.Pull:
                                return panelObjectType === "zixi_pull";
                            case TargetApiType.Push:
                                return panelObjectType === "zixi_push";
                            case TargetApiType.Rtmp:
                                return panelObjectType === "rtmp_push";
                            case TargetApiType.Rist:
                                return panelObjectType === "rist";
                            case TargetApiType.UdpRtp:
                                return panelObjectType === "udp_rtp";
                            case TargetApiType.Srt:
                                return panelObjectType === "srt_targets";
                            default:
                                return false;
                        }
                    })
                    .map(t => t.target);
                if (relevantTargets.length > 0)
                    panelConfig.panels.push({
                        panel: panel,
                        objectType: panelObjectType,
                        objectIds: relevantTargets.slice(0, 10).map(s => s.id)
                    });
            }
        }

        const url = this.flexGraphs.custom(panelConfig);
        this.insightsUrls = [url];
    }

    ngOnDestroy() {
        if (this.darkModeSubscription) this.darkModeSubscription.unsubscribe();
    }

    displayingCount() {
        return Math.min(10, this.objects?.length ?? 0);
    }
}
