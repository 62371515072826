import { Injectable } from "@angular/core";
import { urlBuilder } from "@zixi/shared-utils";
import { Constants } from "../constants/constants";
import { OpenIssue } from "../models/status";
import { BroadcastersService } from "../components/broadcasters/broadcasters.service";
import { Router } from "@angular/router";
import { ZmEvent } from "../pages/events/event";

@Injectable({
    providedIn: "root"
})
export class UrlBuilderService {
    constructor(private bs: BroadcastersService, private router: Router) {}

    async objectToUrl(model: OpenIssue | ZmEvent): Promise<string[] | void> {
        let url = [];

        const modelAsAny = model as any;
        const id = <number>modelAsAny.object_id ?? <number>modelAsAny.id;
        const name = <string>modelAsAny.object_name ?? <string>modelAsAny.name;
        const type = <string>modelAsAny.object_type ?? <string>modelAsAny.type;

        switch (type) {
            case "feeder":
                url = urlBuilder.getRegularZecUrl(id, Constants.urls.feeders, name);
                break;
            case "receiver":
                url = urlBuilder.getRegularZecUrl(id, Constants.urls.receivers, name);
                break;
            case "zec":
                url = urlBuilder.getRegularZecUrl(id, Constants.urls.zecs, name);
                break;
            case "source":
                url = urlBuilder.getRegularSourceUrl(id, name);
                break;
            case "broadcaster":
                let clusterId: number;
                const bx = this.bs.getCachedBroadcaster(id);
                if (bx) {
                    clusterId = bx.broadcaster_cluster_id;
                } else {
                    const bx = await this.bs.refreshBroadcaster(id).toPromise();
                    clusterId = bx.broadcaster_cluster_id;
                }
                url = urlBuilder.getRegularBroadcasterUrl(clusterId, id, name);

                break;
            case "adaptive_channel":
                url = urlBuilder.getRegularChannelUrl(id, Constants.urls.channelTypes.adaptive, name);

                break;
            case "failover_channel":
            case "medialive_channels":
            case "delivery_channel":
                url = urlBuilder.getRegularChannelUrl(id, Constants.urls.channelTypes.delivery, name);

                break;
            case "mediaconnect_flows":
                url = urlBuilder.getRegularChannelUrl(id, Constants.urls.channelTypes.mediaconnect, name);

                break;
            case "mediaconnect_sources":
                url = [Constants.urls.sources, "mediaconnect", name];
                break;
            case "http":
            case "publishing_target":
            case "s3":
            case "gcp":
            case "azure":
            case "mediastore":
            case "youtube":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.http, name);

                break;
            case "zixi_pull":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.pull, name);

                break;
            case "zixi_push":
            case "push":
            case "mediaconnect":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.push, name);

                break;
            case "rtmp_push":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.rtmp, name);

                break;
            case "udp_rtp":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.udp_rtp, name);

                break;
            case "srt_targets":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.srt, name);

                break;
            case "ndi_targets":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.ndi, name);

                break;
            case "rist":
                url = urlBuilder.getRegularTargetUrl(id, Constants.urls.targetTypes.rist, name);

                break;
            case "remote_access":
                url = [Constants.urls.remote_access, name];
                break;
        }

        // eslint-disable-next-line no-console
        if (!url.length) return console.error(`Couldn't find ${type}'s URL`);
        return url;
    }

    async navigateToObject(model: ZmEvent | OpenIssue) {
        const url = await this.objectToUrl(model);
        if (url) this.router.navigate(url);
    }

    encodeRFC3986URIComponent(str: string) {
        return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
    }
}
