import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from "@angular/core";
import { TourService } from "ngx-ui-tour-md-menu";
import { Subscription } from "rxjs";
import { TourSteps } from "src/app/constants/tour-steps";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-layout-buttons",
    templateUrl: "./layout-buttons.component.html",
    styleUrls: ["./layout-buttons.component.scss"]
})
export class LayoutButtonsComponent implements OnInit, OnDestroy {
    @Output() saveLayout = new EventEmitter<void>();
    @Output() resetLayout = new EventEmitter<void>();
    @Output() revertLayout = new EventEmitter<void>();
    @Output() lockChanged = new EventEmitter<boolean>();
    @Input() savingLayout: boolean;
    @Input() layoutChanged: boolean;
    @Input() isLocked: boolean;
    @Input() showRevertButton = true;
    @Input() showSaveButton = true;
    @Input() unlockText = "UNLOCK";
    private tourSteps = TourSteps.customLayoutFeatures;
    private tourSubscription: Subscription;

    constructor(public tourService: TourService, public mixpanelService: MixpanelService) {
        this.tourService.initialize(this.tourSteps);
    }

    ngOnInit() {
        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.anchorId === "secondCustomLayoutAnchor") {
                this.isLocked = false;
                this.lockChanged.emit(this.isLocked);
            }
        });
    }

    ngOnDestroy() {
        this.tourSubscription.unsubscribe();
    }
}
