import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";
import * as _ from "lodash";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { AuthService } from "src/app/services/auth.service";
import { Customer } from "./customer";

@Injectable({
    providedIn: "root"
})
export class AccountsService {
    accounts: Observable<Customer[]>;
    private accountsRS$: ReplaySubject<Customer[]>;
    private dataStore: {
        accounts: Customer[];
    };

    private lastAccountsRefresh: number;

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            accounts: []
        };

        this.lastAccountsRefresh = null;

        this.accountsRS$ = new ReplaySubject(1) as ReplaySubject<Customer[]>;
        this.accounts = this.accountsRS$.asObservable();
    }

    private prepAccount(account: Customer) {
        return account;
    }

    private updateAccountStore(newAccount: Customer, merge: boolean): void {
        this.prepAccount(newAccount);
        const currentAccountIndex = this.dataStore.accounts.findIndex(account => account.id === newAccount.id);
        if (currentAccountIndex === -1) {
            this.dataStore.accounts.push(newAccount);
            return;
        } else if (merge) {
            const currentAccount = this.dataStore.accounts[currentAccountIndex];

            Object.assign(currentAccount, newAccount);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (currentAccount[overwrite.id] == null) currentAccount[overwrite.obj] = null;
            });
        } else {
            this.dataStore.accounts[currentAccountIndex] = newAccount;
        }
    }

    refreshAccounts(force?: boolean): Observable<Customer[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastAccountsRefresh <= 60000) return this.accounts;
        this.lastAccountsRefresh = _.now();

        const accounts$ = this.http
            .get<{ success: boolean; result: Customer[] }>(Constants.apiUrl + Constants.apiUrls.customers)
            .pipe(share());

        accounts$.subscribe(
            data => {
                const accounts = data.result;

                this.dataStore.accounts.forEach((existingAccount, existingIndex) => {
                    const newIndex = accounts.findIndex(account => account.id === existingAccount.id);
                    if (newIndex === -1) this.dataStore.accounts.splice(existingIndex, 1);
                });

                accounts.forEach(refreshedAccount => this.updateAccountStore(refreshedAccount, true));

                this.accountsRS$.next(Object.assign({}, this.dataStore).accounts);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ACCOUNTS"), error)
        );

        return accounts$.pipe(map(r => r.result));
    }

    getCachedAccount(name?: string, id?: number) {
        if (this.dataStore.accounts && name) return this.dataStore.accounts.find(account => account.name === name);
        if (this.dataStore.accounts && id) return this.dataStore.accounts.find(account => account.id === id);
        return undefined;
    }

    async deleteAccount(account: Customer) {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl + Constants.apiUrls.customers + "/" + `${account.id}`
                )
                .toPromise();

            const deletedId: number = result.result;
            const accountIndex = this.dataStore.accounts.findIndex(a => a.id === deletedId);
            if (accountIndex !== -1) this.dataStore.accounts.splice(accountIndex, 1);

            this.accountsRS$.next(Object.assign({}, this.dataStore).accounts);
            return true;
        } catch (error) {
            return false;
        }
    }

    async addAccount(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<{ success: boolean; result: Customer }>(Constants.apiUrl + Constants.apiUrls.customers, model)
                .toPromise();
            const account = result.result;

            this.updateAccountStore(account, true);

            this.accountsRS$.next(Object.assign({}, this.dataStore).accounts);
            return account;
        } catch (error) {
            return false;
        }
    }

    async updateAccount(id: number, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<{ success: boolean; result: Customer }>(
                    Constants.apiUrl + Constants.apiUrls.customers + "/" + id,
                    model
                )
                .toPromise();
            const updatedAccount = result.result;

            this.updateAccountStore(updatedAccount, true);

            this.accountsRS$.next(Object.assign({}, this.dataStore).accounts);
            return updatedAccount;
        } catch (error) {
            return false;
        }
    }
}
