import { Directive, forwardRef, HostListener, ElementRef, Renderer2 } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "input[type=checkbox][invertChecked]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InvertCheckedDirective),
            multi: true
        }
    ]
})
export class InvertCheckedDirective implements ControlValueAccessor {
    private propagateChange;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    @HostListener("change", ["$event"])
    onHostChange(e) {
        this.propagateChange(!e.target.checked);
    }

    writeValue(obj): void {
        this.renderer.setProperty(this.elementRef.nativeElement, "checked", !obj);
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    registerOnTouched() {}
}
