import { Component, OnInit, Input } from "@angular/core";
import { Constants } from "./../../../constants/constants";
import { Cluster } from "src/app/pages/clusters/cluster";

@Component({
    selector: "zx-cluster",
    template: `
        <div class="ellipsis d-inline" title="{{ model | errorMessage }}" *ngIf="model?.name">
            <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'CLUSTER' | translate }}">BC</span>
            <zx-status-full
                [icon]="showStatusIcon"
                [model]="model"
                [status]="status || (model.generalStatus | statusClass)"
                [showStatusText]="showStatusText"
                [showOtherIcons]="showOtherIcons"
            ></zx-status-full>
            <a
                *ngIf="showLink"
                [routerLink]="['/' + urls.clusters, model.id | uId, model.name]"
                (click)="$event.stopPropagation()"
            >
                <ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
            </a>
            <span *ngIf="!showLink"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight
            ></span>
        </div>
        <ngb-highlight
            *ngIf="!model?.name && region"
            title="{{ region }}"
            [result]="region"
            [term]="searchTerm"
        ></ngb-highlight>
    `
})
export class ZxClusterComponent implements OnInit {
    @Input() model: Cluster;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() searchTerm?: string | string[];
    @Input() region?: string;

    urls = Constants.urls;

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
