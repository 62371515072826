<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <div class="form-group" *ngIf="source.health?.healthScore != null && isPushOrPull">
            <h5>
                {{ "HEALTH" | translate }}&nbsp;<fa-icon icon="info-circle" [ngbTooltip]="HealthContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                <ng-template #HealthContent>
                    <p class="mb-0">
                        {{ "TOOLTIP.HEALTH_SCORE" | translate }}
                    </p>
                </ng-template>
            </h5>
            <div>
                <zx-source-health [source]="source"></zx-source-health>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>TYPE</h5>
            <div>
                {{ source._frontData.typeColumn }}
            </div>
        </div>
        <div class="form-group" *ngIf="source.feeder_id">
            <h5 translate>MAX_BITRATE</h5>
            <div>
                <span>{{ source.max_bitrate | bitrate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.feeder_id">
            <h5>{{ "LATENCY" | translate }} [ms]</h5>
            <div>
                <span>{{ source.latency }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.multiplexSources && source.multiplexSources.length > 0">
            <h5 translate>SOURCES</h5>
            <div>
                <div class="ellipsis" *ngFor="let mpx of source.multiplexSources">
                    <zx-source [model]="mpx.source" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="false"></zx-source>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="multiviewSources?.length > 0">
            <h5>{{ "SOURCES" | translate }} (x,y)</h5>
            <div>
                <div class="ellipsis" *ngFor="let mv of multiviewSources">
                    <span>{{ mv.grid_x }},{{ mv.grid_y }}</span
                    >&nbsp;
                    <zx-source [model]="mv.source" [showLink]="true" [showTag]="false"></zx-source>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="primaryFailoverSources.length > 0">
            <h5>{{ "PRIMARY" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of primaryFailoverSources">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="fs.is_active" class="ms-1">(Active)</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="secondaryFailoverSources.length > 0">
            <h5>{{ "SECONDARY" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of secondaryFailoverSources">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="fs.is_active" class="ms-1">(Active)</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="slateFailoverSources.length > 0">
            <h5>{{ "SLATE" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of slateFailoverSources">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                    <span *ngIf="fs.is_active" class="ms-1">(Active)</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="source.transcodeProfile">
            <h5 translate>TRANSCODING_PROFILE</h5>
            <div>
                <a [routerLink]="['/' + urls.transformation.transcoding_profiles, source.transcodeProfile.name]">
                    {{ source.transcodeProfile.name }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="source.pid_mapping && source.pid_mapping.name">
            <h5 translate>PID_MAPPING</h5>
            <div>
                <a [routerLink]="['/' + urls.transformation.pid_mapping_profiles, source.pid_mapping.name]">
                    {{ source.pid_mapping.name }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="source.type === 'file' && source.protocol === 'http_file'">
            <h5 translate>URL</h5>
            <div>
                <span
                    >{{ source.url
                    }}<button class="btn btn-sm btn-xs btn-copy" (click)="copyPassword(source.url)" type="button" title="{{ 'COPY' | translate }}">
                        <fa-icon icon="clipboard" size="md"></fa-icon></button
                ></span>
            </div>
        </div>

        <div
            class="form-group"
            *ngIf="
                source.feeder_id ||
                source.broadcaster_id ||
                source.input_id ||
                (source.transcode_profile_id && source.type !== 'multiview') ||
                source.Source ||
                source._frontData.input_description
            "
        >
            <h5 translate>INPUT</h5>
            <div>
                <zx-feeder *ngIf="source.feeder_id" [model]="source.feeder" [showTag]="true" [showLink]="true" [showStatusIcon]="true"></zx-feeder>
                <zx-broadcaster
                    *ngIf="source.broadcaster_id"
                    [model]="source.broadcaster"
                    [showTag]="true"
                    [showLink]="true"
                    [showCluster]="false"
                    [showStatusIcon]="true"
                ></zx-broadcaster>
                <span *ngIf="source.input_id && source.type !== 'zixi_pull' && source.type !== 'rtmp'"
                    ><span *ngIf="source.broadcaster_id || source.feeder_id">&nbsp;/&nbsp;</span>{{ source.input_id
                    }}<span *ngIf="source.Source">&nbsp;/&nbsp;</span></span
                >
                <span *ngFor="let failoverSource of source.failoverSources; let isLast = last"
                    >{{ failoverSource.source.name }}{{ isLast ? "" : ",&nbsp;" }}</span
                >
                <span *ngIf="source.transcode_profile_id && source.transcodeSource && source.transcodeSource.name"> {{ source.transcodeSource.name }} </span>
                <zx-source *ngIf="source.Source" [model]="source.Source" [showLink]="true" [showTag]="false"></zx-source>
                <span *ngIf="source._frontData.input_description">
                    {{ source._frontData.input_description }}
                </span>
            </div>
        </div>
        <app-details-location [location]="source.location"></app-details-location>
        <div class="form-group" *ngIf="source.status && source.status.source_ip && source.type !== 'file'">
            <h5 translate>SOURCE_IP</h5>
            <div>
                <span>{{ source.status.source_ip }}</span>
            </div>
        </div>
        <div
            class="form-group"
            *ngIf="
                source &&
                source.type !== 'file' &&
                source.type !== 'rtmp' &&
                source.type !== 'hls_pull' &&
                ((source._frontData.cluster?.auth_param && source._frontData.cluster?.auth_mode === 'password') ||
                    (source.password && source._frontData.cluster?.auth_mode !== 'password')) &&
                (!source._frontData.cluster?.auth_mode ||
                    source._frontData.cluster?.auth_mode === 'zen' ||
                    source._frontData.cluster?.auth_mode === 'password' ||
                    source._frontData.cluster?.auth_mode === 'custom')
            "
        >
            <h5 translate>SOURCE_PASSWORD</h5>
            <div *ngIf="source._frontData.cluster?.auth_mode === 'password'">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="pwVisible">{{ source._frontData.cluster?.auth_param }}</span> /
                <a href="javascript:void(0)" (click)="copyPassword(source._frontData.cluster?.auth_param)">
                    {{ "COPY" | translate }}
                </a>
            </div>
            <div *ngIf="source._frontData.cluster?.auth_mode !== 'password'">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="pwVisible">{{ source.password }}</span> /
                <a href="javascript:void(0)" (click)="copyPassword(source.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="source.html_overlay_url">
            <h5 translate>HTML_OVERLAY_URL</h5>
            <div>
                <span>{{ source.html_overlay_url }}</span>
            </div>
        </div>
        <app-generic-detail header="APPLICATION" [content]="source?.status?.app"></app-generic-detail>
        <app-generic-detail header="DEVICE" [content]="source?.status?.device"></app-generic-detail>
        <app-generic-detail header="BUILD" [content]="source?.status?.build"></app-generic-detail>
        <app-generic-detail header="ENCRYPTED" [content]="source?.status?.encryption_description || 'No'"></app-generic-detail>
        <app-generic-detail header="REMOTE_ID" [content]="source?.status?.remote_id"></app-generic-detail>
        <app-generic-detail header="LIVE_RECORDING" [content]="source?.status?.live_recording_status"></app-generic-detail>
        <app-generic-detail header="LIVE_RECORDING_ERROR" [content]="source?.status?.live_recording_status_last_error"></app-generic-detail>
        <app-generic-detail header="CERTIFICATE" [content]="source?.status?.cert"></app-generic-detail>
        <app-generic-detail header="RTP_PAYLOAD" [content]="source?.status?.rtp_payload ? 'RTP Payload' : ''"></app-generic-detail>
    </div>
    <div class="flex-1 flex-basis-min">
        <div class="form-group">
            <h5 translate>INGEST_CLUSTER</h5>
            <div>
                <div class="ellipsis">
                    <div class="d-inline" *ngIf="source.target_broadcaster_id > 0 || source.activeBroadcasterObjects?.bx_id">
                        <zx-broadcaster
                            [broadcasterId]="source.target_broadcaster_id > 0 ? source.target_broadcaster_id : source.activeBroadcasterObjects?.bx_id"
                            [clusterId]="source.broadcaster_cluster_id"
                            [showTag]="false"
                            [showLink]="true"
                            [showCluster]="false"
                            [showStatusIcon]="true"
                        ></zx-broadcaster>
                        @
                    </div>
                    <zx-cluster
                        class="d-inline-block"
                        [model]="source.inputCluster"
                        [showOtherIcons]="false"
                        [showStatusIcon]="false"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-cluster>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="source.target_broadcaster_id">
            <h5 translate>TARGET_BROADCASTER/S</h5>
            <div>
                <span *ngIf="source.target_broadcaster_id === -1">{{ "PREFER_PRIMARY_BROADCASTERS" | translate }}</span>
                <span *ngIf="source.target_broadcaster_id === -2">{{ "PRIMARY_BROADCASTERS_ONLY" | translate }}</span>
                <span *ngIf="source.target_broadcaster_id === -3">{{ "BACKUP_BROADCASTERS_ONLY" | translate }}</span>
                <div *ngIf="source.target_broadcaster_id !== -3 && source.target_broadcaster_id !== -2 && source.target_broadcaster_id !== -1">
                    <zx-broadcaster
                        *ngIf="targetBroadcaster | async as bxModel; else loading"
                        [model]="bxModel"
                        [showTag]="false"
                        [showLink]="true"
                        [showCluster]="false"
                        [showStatusIcon]="true"
                        [clusterId]="source.broadcaster_cluster_id"
                    ></zx-broadcaster>
                    <ng-template #loading>
                        <spinner-animation-inline></spinner-animation-inline>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="source.alertingProfile">
            <h5 translate>ALERTING_PROFILE</h5>
            <div>
                <a [routerLink]="['/' + urls.configuration.eventsManagement, source.alertingProfile.id]">
                    {{ source.alertingProfile.name }}
                </a>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>PUBLIC_OUTPUT</h5>
            <div>
                <span *ngIf="!source.allow_outputs">{{ "DISABLED" | translate }}</span>
                <span *ngIf="source.allow_outputs">{{ source.public_url }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5>{{ "CLOSED_CAPTION_PRESERVE" | translate }}</h5>
            <div>
                <span *ngIf="source.copy_closed_captions">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.copy_closed_captions">{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.type !== 'file'">
            <h5 translate>ALERT_ON_PID_CHANGES</h5>
            <div>
                <span *ngIf="source.monitor_pids_change">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.monitor_pids_change">{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>CONTENT_ANALYSIS</h5>
            <div>
                <span *ngIf="contentAnalysis === CONTENT_ANALYSIS.FULL">{{ "FULL" | translate }}</span>
                <span *ngIf="contentAnalysis === CONTENT_ANALYSIS.TR101_ONLY">{{ "TR101_ONLY" | translate }}</span>
                <span *ngIf="contentAnalysis === CONTENT_ANALYSIS.NONE">{{ "NONE" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.type !== 'file'">
            <h5 translate>TRACEROUTE_HISTORY</h5>
            <div>
                <span *ngIf="source.traceroute_history">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.traceroute_history">{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.process_scte_reports && source.type !== 'file'">
            <h5 translate>LOG_SCTE_35</h5>
            <div>
                <span *ngIf="source.inputCluster.configure_scte_reports">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.inputCluster.configure_scte_reports">{{ "DISABLED" | translate }} {{ "ON" | translate }} {{ "CLUSTER" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.type !== 'file'">
            <h5 translate>SCTE_35_ALERTS</h5>
            <div>
                <span *ngIf="source.report_scte_warnings">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.report_scte_warnings">{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.disable_autopull">
            <h5 translate>AUTO_PULL</h5>
            <div>
                <span>{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <div class="form-group mb-0" *ngIf="!source.disable_autopull && source.autopull_latency">
            <h5>{{ "AUTO_PULL" | translate }} {{ "LATENCY" | translate }} [ms]</h5>
            <div>
                <span>{{ source.autopull_latency }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="source.type === 'multiplex'">
            <h5 translate>DROP_INACTIVE_MULTIPLEX</h5>
            <div>
                <span *ngIf="source.drop_inactive_multiplex">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!source.drop_inactive_multiplex">{{ "DISABLED" | translate }}</span>
            </div>
        </div>
    </div>
</div>
