import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { Constants } from "./../constants/constants";
import { LoginService } from "../pages/login/login.service";
import { ErrorService } from "./../components/error/error.service";
import { MixpanelService } from "../services/mixpanel.service";
import { AuthService } from "../services/auth.service";
import { SharedLoginService } from "../pages/shared/login/shared-login.service";

@Injectable({ providedIn: "root" })
export class ErrorInterceptor implements HttpInterceptor {
    private isLoggedIn = false;

    constructor(
        private router: Router,
        private loginService: LoginService,
        private errorService: ErrorService,
        private mixpanelService: MixpanelService,
        private authService: AuthService,
        private sharedLoginService: SharedLoginService
    ) {
        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => this.handleServerError(err, request.method)));
    }

    handleServerError(err: any, requestMethod: string) {
        if ([401, 403].indexOf(err.status) !== -1) {
            const isSharedRoute = this.sharedLoginService.isSharedRoute(this.router.routerState.snapshot.url);
            if (isSharedRoute && this.sharedLoginService.isInitiated) {
                this.sharedLoginService.setLogout();
                this.router.navigate([Constants.urls.shared.login]);
            } else {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                // only send Event to mixpanel if 403, if we send 401s we get too many events and add to much to MTUs
                if ([403].indexOf(err.status) !== -1) {
                    this.mixpanelService.sendEvent(err.status, {
                        status: err.status,
                        name: err.name,
                        statusText: err.statusText,
                        message: err.message,
                        url: err.url,
                        error: err.error ? err.error.error : "Error",
                        stack: err.error && err.error.stack
                    });
                }
                this.loginService.logout(this.isLoggedIn);
                this.router.navigate([Constants.urls.login]);
            }
        } else {
            if (requestMethod === "PUT" || requestMethod === "POST" || requestMethod === "DELETE") {
                this.errorService.setLastError(err);
                this.errorService.setCurrentError(err);
                this.mixpanelService.sendEvent("error", {
                    status: err.status,
                    name: err.name,
                    statusText: err.statusText,
                    message: err.message,
                    url: err.url,
                    error: err.error ? err.error.error : "Error",
                    stack: err.error && err.error.stack
                });
            }
            // Get 404 don't throwError
            if (requestMethod === "GET" && [404].indexOf(err.status) !== -1) {
                // return observable of null
                return of(null);
            }
        }
        return throwError(err);
    }
}
