import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from "@angular/forms";

export function duplicateNameValidator(names: string[] | string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
        // Check if value exists in string of names
        const namesArr = typeof names === "string" ? names.split(",") : names;
        if (namesArr.indexOf(control.value) > -1) {
            const duplicate = true;
            return duplicate ? { duplicateName: { value: control.value.toLowerCase() } } : null;
        } else {
            const duplicate = false;
            return duplicate ? { duplicateName: { value: control.value.toLowerCase() } } : null;
        }
    };
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[duplicateName]",
    providers: [{ provide: NG_VALIDATORS, useExisting: DuplicateNameDirective, multi: true }]
})
export class DuplicateNameDirective implements Validator {
    @Input() duplicateName: string;

    validate(control: AbstractControl): { [key: string]: unknown } | null {
        return this.duplicateName ? duplicateNameValidator(this.duplicateName)(control) : null;
    }
}
