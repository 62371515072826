import { Component, Input } from "@angular/core";
import { FailoverSource } from "../../../../../models/shared";

@Component({
    selector: "app-source-sources-name-column",
    templateUrl: "./source-sources-name-column.component.html"
})
export class SourceSourcesNameColumnComponent {
    @Input() source: FailoverSource;

    failoverStatusIcon(failoverSource) {
        if (failoverSource.readOnly) return "no";
        else if (
            !failoverSource.readOnly &&
            failoverSource.status &&
            failoverSource.status.input_status === "Connected"
        ) {
            switch (failoverSource.status.status) {
                case 0:
                case 1:
                    return "good";
                case 2:
                    return "warning";
                case 3:
                case 4:
                    return "bad";
                default:
                    return "no";
            }
        } else if (
            !failoverSource.readOnly &&
            failoverSource.status &&
            failoverSource.status.input_status !== "Connected"
        ) {
            return "bad";
        } else {
            return "bad";
        }
    }
}
