import { DragDropModule } from "@angular/cdk/drag-drop";
import { EmptyExpr } from "@angular/compiler";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import _ from "lodash";
import { Subscription } from "rxjs";
import { Source } from "src/app/models/shared";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { SharedService } from "src/app/services/shared.service";

export type MultiviewSelectedSource = {
    source: Source;
    label: string;
    grid_x: number | null;
    grid_y: number | null;
};

@Component({
    selector: "app-multiview-source-selection",
    templateUrl: "./multiview-source-select.component.html",
    styleUrls: ["./multiview-source-select.component.scss"]
})
export class MultiviewSourceSelectionComponent implements OnInit, OnDestroy {
    @Input() formSubmitted: boolean;
    @Input() selectedSources: MultiviewSelectedSource[] = [];
    @Input() maxX: number;
    @Input() maxY: number;
    @Input() maxTotal?: number;

    sources: Source[] = [];

    availableSources: MultiviewSelectedSource[] = [];

    multiviewSourceFilter: string;
    sourcesLoading = false;
    isEdit = false;
    page = 1;
    pageSize = 10;
    haveDuplicateGrids = false;
    private sourcesSubscription: Subscription;

    constructor(private ss: SourcesService) {}

    ngOnInit(): void {
        // Sources
        this.sourcesLoading = true;
        this.ss.refreshSources(true);
        this.sourcesSubscription = this.ss.sources.subscribe((sources: Source[]) => {
            this.sources = sources?.filter(s => !s.is_hidden) ?? [];
            this.setAvailableSources();
            this.sourcesLoading = false;
        });
    }

    ngOnDestroy(): void {
        this.sourcesSubscription.unsubscribe();
    }

    selectMultiviewSource(source: MultiviewSelectedSource, x?: number, y?: number) {
        this.deselectMultiviewSource(source);

        const currentSource = this.selectedSource(x, y);
        if (currentSource) {
            this.deselectMultiviewSource(currentSource);

            if (source.grid_x != null && source.grid_y != null)
                this.selectedSources.push({ ...currentSource, grid_x: source.grid_x, grid_y: source.grid_y });
        }

        if (x == null || y == null) {
            const { x: emptyX, y: emptyY } = this.findAvailableGrid();
            if (emptyX == null || emptyY == null) return;
            x = emptyX;
            y = emptyY;
        }

        this.selectedSources.push({ ...source, grid_x: x, grid_y: y });

        const index = this.availableSources.findIndex(s => s.source.id === source.source.id);
        if (index >= 0) {
            this.availableSources.splice(index, 1);
        }
    }

    deselectMultiviewSource(source: MultiviewSelectedSource) {
        const index = this.selectedSources.findIndex(s => s.source.id === source.source.id);
        if (index >= 0) {
            this.selectedSources.splice(index, 1);
            this.setAvailableSources();
        }
    }

    validLocation(x: number, y: number) {
        return !this.maxTotal || y * this.maxX + x < this.maxTotal;
    }

    selectedSource(x: number, y: number) {
        return this.selectedSources.find(ss => ss.grid_x === x && ss.grid_y === y);
    }

    gridIndexes(max: number) {
        return Array(max)
            .fill(0)
            .map((v, i) => i);
    }

    private setAvailableSources() {
        this.availableSources = this.sources
            .filter(s => !this.selected(s))
            .map(s => ({ source: s, grid_x: null, grid_y: null, label: s.name }));
    }

    private selected(source: Source) {
        return !!this.selectedSources.find(ss => ss.source.id === source.id);
    }

    private findAvailableGrid() {
        for (let j = 0; j < this.maxX; ++j)
            for (let i = 0; i < this.maxX; ++i) {
                if (!this.selectedSource(i, j)) return { x: i, y: j };
            }
        return { x: null, y: null };
    }
}
