import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { DeliveryChannel } from "../../../pages/channels/channel";

@Component({
    selector: "zx-delivery-channels-select",
    templateUrl: "./zx-delivery-channels-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxDeliveryChannelsSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number[];
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    deliveryChannels: DeliveryChannel[];
    private deliveryChannelsSubscription: Subscription;

    constructor(private cs: ChannelsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getDeliveryChannels();
        this.deliveryChannelsSubscription = this.cs.deliveryChannels.subscribe(deliveryChannels => {
            this.deliveryChannels = [...deliveryChannels];

            const modelIds = [];
            if (this.model && this.model.length)
                for (const id of this.model) {
                    if (this.deliveryChannels.some(channel => channel.id === id)) {
                        modelIds.push(id);
                    }
                }

            this.model = modelIds;
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.deliveryChannelsSubscription.unsubscribe();
    }
}
