import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIResponse } from "../../models/shared";
import { TranslateService } from "@ngx-translate/core";
//
import { Constants } from "../../constants/constants";
import { ActiveState } from "../../models/shared";

@Injectable({
    providedIn: "root"
})
export class ActiveStatesService {
    constructor(private http: HttpClient, private translate: TranslateService) {}

    // PUT /api/states/active/:id
    async updateActiveState(activeState: Partial<ActiveState>) {
        try {
            const result = await this.http
                .put<APIResponse<ActiveState>>(
                    Constants.apiUrl + Constants.apiUrls.active_states + "/" + `${activeState.id}`,
                    activeState
                )
                .toPromise();
            //
            const updatedActiveState: ActiveState = result.result;
            return updatedActiveState;
        } catch (error) {
            return false;
        }
    }

    // DELETE /api/states/active/:id
    async deleteActiveState(id: number) {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl + Constants.apiUrls.active_states + "/" + `${id}`
                )
                .toPromise();
            const deletedId = result.result;
            return deletedId;
        } catch (error) {
            return false;
        }
    }
}
