import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import * as _ from "lodash";

import { ErrorService } from "src/app/components/error/error.service";
import { Tag } from "src/app/models/shared";
import { AlertingProfile } from "src/app/pages/configuration/events-management/events-management";
import { FormControl, UntypedFormControl, Validators } from "@angular/forms";
import { PidMappingsService } from "src/app/pages/pid-mappings/pid-mappings.service";

@Component({
    selector: "app-multiple-edit-dialog",
    templateUrl: "./multiple-edit-dialog.component.html"
})
export class MultipleEditDialogComponent<T> implements OnInit, OnDestroy {
    type: string;
    name: string;
    note?: string;
    warning?: string;
    confirmHandler: (object: T, model) => Promise<unknown>;
    set objects(objects: T[]) {
        this.objectsStore = objects.map(o => {
            return {
                saving: false,
                processing: "",
                hasError: false,
                ignored: false,
                actionTaken: false,
                error: null,
                object: o
            };
        });
    }

    objectsStore: {
        saving?: boolean;
        processing?: string;
        hasError?: boolean;
        ignored?: boolean;
        actionTaken: boolean;
        error?: {
            status: number;
            statusText: string;
            message: string;
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        object?: any;
    }[];

    saving = false;
    state = "default";
    hasError = false;
    hasIgnored = false;
    hasUpdated = false;

    //
    resourceTags: Tag[] = [];
    alertingProfile: AlertingProfile;
    updateTag = false;
    updateAlertProfile = false;
    allObjectsSupportPidProfile = false;
    overwriteTag = "overwrite";
    pidProfileControl = new FormControl<number>({ value: null, disabled: true });
    updatePidProfileControl = new FormControl<boolean>(false);
    pidMappingProfiles$ = this.pidMappingService.pidMappingProfiles;
    private routeSubscription: Subscription;
    private listOfTargetsSupportPidMappingProfile = ["pull", "push", "rist", "udp_rtp", "rtmp", "srt"];

    tagsControl = new UntypedFormControl([], [Validators.required]);

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private errorService: ErrorService,
        private pidMappingService: PidMappingsService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    removeDuplicates(data, key) {
        return [...new Map(data.map(item => [key(item), item])).values()];
    }

    getDuplicates(arr) {
        const seen = new Set();

        const duplicates = [];
        arr.filter(el => {
            const duplicate = seen.has(el.id);
            if (duplicate) duplicates.push(el);
            seen.add(el.id);
        });

        return duplicates;
    }

    ngOnInit(): void {
        // Setup initial profile
        const profiles: AlertingProfile[] = [];
        for (const o of this.objectsStore) {
            if (o.object && o.object.alertingProfile) {
                profiles.push(o.object.alertingProfile);
            }
        }
        this.pidMappingService.refreshPIDMappingProfiles();
        const allObjectsSupportPidProfile = this.checkIfAllObjectsSupportPidProfile();

        if (!allObjectsSupportPidProfile) this.updatePidProfileControl.disable();

        this.updatePidProfileControl.valueChanges.subscribe(value =>
            value ? this.pidProfileControl.enable() : this.pidProfileControl.disable()
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const noProfileDupes: any[] = this.removeDuplicates(profiles, p => p.id);
        if (noProfileDupes && noProfileDupes.length === 1) this.alertingProfile = noProfileDupes[0];
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        this.state = "";

        for (const object of this.objectsStore) {
            object.saving = true;
            object.processing = "start";
            //
            const model: {
                resource_tag_ids?: number[];
                alerting_profile_id?: number;
                pid_mapping_profile_id?: number;
            } = {};
            if (this.updateTag) {
                // add
                if (this.overwriteTag === "add") {
                    const existingTags = [];

                    // Check if target
                    if (object.object.target) {
                        for (const tag of object.object.target.resourceTags) {
                            existingTags.push(tag);
                        }
                    } else {
                        for (const tag of object.object.resourceTags) {
                            existingTags.push(tag);
                        }
                    }

                    for (const tag of this.resourceTags) {
                        existingTags.push(tag);
                    }
                    const noTagDupes = this.removeDuplicates(existingTags, t => t.id);
                    model.resource_tag_ids = _.map(noTagDupes, "id");
                }
                // overwrite
                else if (this.overwriteTag === "overwrite") {
                    model.resource_tag_ids = _.map(this.resourceTags, "id");
                }
                // remove
                else if (this.overwriteTag === "remove") {
                    const existingTags = [];

                    // Check if target
                    if (object.object.target) {
                        for (const tag of object.object.target.resourceTags) {
                            existingTags.push(tag);
                        }
                    } else {
                        for (const tag of object.object.resourceTags) {
                            existingTags.push(tag);
                        }
                    }

                    for (const tag of this.resourceTags) {
                        existingTags.push(tag);
                    }
                    const duplicates = this.getDuplicates(existingTags);

                    let removedArray;
                    // Check if target
                    if (object.object.target) {
                        removedArray = object.object.target.resourceTags.filter(
                            ar => !duplicates.find(rm => rm.id === ar.id)
                        );
                    } else {
                        removedArray = object.object.resourceTags.filter(ar => !duplicates.find(rm => rm.id === ar.id));
                    }

                    model.resource_tag_ids = _.map(removedArray, "id");
                }
            }
            if (this.updateAlertProfile) {
                model.alerting_profile_id = this.alertingProfile.id;
            }
            if (this.updatePidProfileControl.value) {
                model.pid_mapping_profile_id = this.pidProfileControl.value;
            }

            if (this.type === "SSH_KEY") {
                model["name"] = object.object.name;
            }

            const result = await this.confirmHandler(object.object, model);

            if (result) {
                object.hasError = false;
                if (result === "ignored") {
                    object.ignored = true;
                    this.hasIgnored = true;
                } else {
                    object.actionTaken = true;
                    this.hasUpdated = true;
                }
            } else {
                this.hasError = true;
                this.errorService.currentHttpErrorResponse.pipe(take(1)).subscribe(errorResponse => {
                    if (errorResponse) {
                        object.error = {
                            status: errorResponse.status,
                            statusText: errorResponse.statusText,
                            message: errorResponse.error.error
                        };
                    } else {
                        object.error = {
                            status: 400,
                            statusText: "Error",
                            message: "Error"
                        };
                    }
                    object.hasError = true;
                });
            }
            object.saving = false;
            object.processing = "end";
        }

        this.state = "done";
        this.saving = false;
    }

    private checkIfAllObjectsSupportPidProfile() {
        return (
            this.type === "TARGET" &&
            this.objectsStore.every(o => this.listOfTargetsSupportPidMappingProfile.includes(o.object.target.type))
        );
    }
}
