import { Component, Input, OnInit } from "@angular/core";
import { MediaConnectFlow, MediaConnectSource, Source } from "src/app/models/shared";
import {
    ChannelTypes,
    isAdaptiveChannel,
    isDeliveryChannel,
    isFailoverChannel,
    isMediaConnect,
    isMediaLive
} from "src/app/pages/channels/channel";

@Component({
    selector: "app-zx-channels-source",
    templateUrl: "./zx-channels-source.component.html"
})
export class ZxChannelsSourceComponent implements OnInit {
    @Input() channel: ChannelTypes;
    @Input() searchTerm: string;
    singleSource?: Source;
    singleMcSource?: MediaConnectSource;
    singleMcFlow?: MediaConnectFlow;

    ngOnInit(): void {
        if (isAdaptiveChannel(this.channel) && (this.channel.is_transcoding || this.channel.bitrates?.length === 1))
            this.singleSource = this.channel.bitrates[0].source;
        if (isDeliveryChannel(this.channel) && this.channel.sources?.length === 1)
            this.singleSource = this.channel.sources[0].source;
        if (isFailoverChannel(this.channel) && this.channel.failoverSource?.failoverSources?.length === 1)
            this.singleSource = (this.channel.failoverSource.failoverSources[0].source as unknown as Source) || null;
        if (isMediaConnect(this.channel) && this.channel.source) this.singleMcSource = this.channel.source;
        if (isMediaLive(this.channel) && this.channel.source) this.singleMcSource = this.channel.source;
        if (isMediaLive(this.channel) && this.channel.flow) this.singleMcFlow = this.channel.flow;
    }
}
