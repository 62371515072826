<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "INSTALL_AGENTZ" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ "INSTALL_AGENTZ" | translate }} on {{ "BROADCASTER" | translate }} {{ broadcasterName }}
        <br />
        {{ "BROADCASTER_RESTART_IS_REQUIRED" | translate }}
        <!-- Error -->
        <app-error [marginBottom]="false" [marginTop]="true"></app-error>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="working" (click)="confirm()">
            {{ "INSTALL_AGENTZ" | translate }}<span *ngIf="working" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()">
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
