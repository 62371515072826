import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";
import * as _ from "lodash";

import { MediaConnectSourcesService } from "../../../pages/sources/mediaconnect-sources.service";
import { MediaConnectSource } from "./../../../models/shared";

@Component({
    selector: "zx-mediaconnect-sources-select",
    template: `
        <!-- ID Model -->
        <ng-select
            *ngIf="useIDs"
            class="form-control minw-300px"
            [ngClass]="{ 'form-control-sm': size === 'small' }"
            name="{{ name }}"
            [items]="sourcesList"
            [loading]="loading"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            bindLabel="name"
            bindValue="id"
            placeholder="{{ 'SELECT' | translate }} {{ 'SOURCES' | translate }}"
            (change)="modelChanged()"
            [(ngModel)]="model"
            [disabled]="disabled"
            minimumSelected="{{ minimumSelected }}"
            required="{{ required || false }}"
            [searchFn]="customSearchFn"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
                <span *ngIf="item.name" class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span *ngIf="item.name" class="ng-value-label"
                    ><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
                    }}<span *ngIf="item.mediaconnect_flow_id !== null"> @ {{ item.mediaconnectFlow.name }}</span></span
                >
            </ng-template>
            <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
                }}<span *ngIf="item.mediaconnect_flow_id !== null"> @ {{ item.mediaconnectFlow.name }}</span>
            </ng-template>
        </ng-select>
        <!-- Source Model -->
        <ng-select
            *ngIf="!useIDs"
            class="form-control minw-300px"
            [ngClass]="{ 'form-control-sm': size === 'small' }"
            name="{{ name }}"
            [items]="sourcesList"
            [loading]="loading"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            bindLabel="id"
            placeholder="{{ 'SELECT' | translate }} {{ 'SOURCES' | translate }}"
            (change)="modelChanged()"
            [(ngModel)]="model"
            [disabled]="disabled"
            minimumSelected="{{ minimumSelected }}"
            required="{{ required || false }}"
            [searchFn]="customSearchFn"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label"
                    ><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
                    }}<span *ngIf="item.mediaconnect_flow_id !== null"> @ {{ item.mediaconnectFlow.name }}</span></span
                >
            </ng-template>
            <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
                }}<span *ngIf="item.mediaconnect_flow_id !== null"> @ {{ item.mediaconnectFlow.name }}</span>
            </ng-template>
        </ng-select>
    `,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMediaConnectSourcesSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: MediaConnectSource[] | number[];
    @Input() useIDs?: boolean;
    sources: MediaConnectSource[] = [];
    @Input() size?: string;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() minimumSelected?: number;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    sourcesList: MediaConnectSource[];
    private sourcesSubscription: Subscription;

    constructor(private ss: MediaConnectSourcesService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    async ngOnInit() {
        this.loading = true;
        if (!this.minimumSelected) this.minimumSelected = 0;

        this.ss.refreshMediaConnectSources(true, true);

        this.sourcesSubscription = this.ss.mediaconnectSources.subscribe(sources => {
            this.sourcesList = [...sources];
            if (this.model && typeof this.model !== "number" && !this.useIDs) {
                const modelIds = _.map(this.model as MediaConnectSource[], "id");
                this.model = this.sourcesList.filter(source => {
                    return modelIds.indexOf(source.id) !== -1;
                });
            } else if (this.useIDs && this.model) {
                // Remove ids from model when source does not exist
                const modelIds = [];
                for (const id of this.model as number[]) {
                    if (this.sourcesList.some(source => source.id === id)) {
                        modelIds.push(id);
                    }
                }
                this.model = modelIds;
            }
            this.loading = false;
        });
    }

    ngOnDestroy() {
        if (this.sourcesSubscription) this.sourcesSubscription.unsubscribe();
    }

    customSearchFn(term: string, item: MediaConnectSource) {
        term = term.toLowerCase();
        return (
            item.name.toLowerCase().indexOf(term) > -1 ||
            (item.mediaconnectFlow && item.mediaconnectFlow.name.toLowerCase().indexOf(term) > -1)
        );
    }
}
