import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "map"
})
export class MapPipe implements PipeTransform {
    transform<T>(object: { [key: string]: T }[], property: string): T[] {
        if (Array.isArray(object)) {
            return object.map(obj => obj[property]);
        } else {
            return object[property];
        }
    }
}
