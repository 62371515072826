/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import mixpanel from "mixpanel-browser";
import { AuthService } from "./auth.service";
import { User } from "../models/shared";
import { environment } from "../../environments/environment";

// eslint-disable @typescript-eslint/no-explicit-any

@Injectable({
    providedIn: "root"
})
export class MixpanelService {
    private mixpanelStarted = false;
    get isMixpanelStarted() {
        return this.mixpanelStarted;
    }
    setMixpanelStarted(val: boolean) {
        this.mixpanelStarted = val;
    }

    constructor(private authService: AuthService) {}

    /**
     * Initialize mixpanel.
     *
     * @param {Object} user
     * @memberof MixpanelService
     */
    identify(user: User): void {
        if (!environment.production) {
            return;
        }

        // cross_subdomain_cookie: false
        mixpanel.init("53b12606bf051a76c53f333a89051236");
        mixpanel.identify(user.hash_id);
        this.setMixpanelStarted(true);
    }

    /**
     * setPeople
     * Store user profiles in Mixpanel's People Analytics product
     * @param {Object} properties
     */
    setPeople(properties: any = {}): void {
        if (!this.isMixpanelStarted) return;
        mixpanel.people.set(properties);
    }

    /**
     * Push new action to mixpanel.
     *
     * @param {string} id Name of the action to track.
     * @param {*} [action={}] Actions object with custom properties.
     * @memberof MixpanelService
     */
    track(id: string, action: any = {}): void {
        if (!this.isMixpanelStarted) return;
        mixpanel.track(id, action);
    }

    /**
     * sendEvent
     *
     * @param {string} event
     * @param {object} properties
     */
    sendEvent(event: string, properties?) {
        if (!this.isMixpanelStarted) return;

        if (properties) {
            mixpanel.track(event, properties);
        } else {
            this.trackEvent(event);
        }
    }

    /**
     * trackEvent
     * @param {string} event
     */
    trackEvent(event: string) {
        if (!this.isMixpanelStarted) return;
        mixpanel.track(event);
    }

    /**
     * timeEvent
     * @param {string} event
     */
    timeEvent(event: string) {
        if (!this.isMixpanelStarted) return;
        mixpanel.time_event(event);
    }

    /**
     * trackForm
     *
     */
    trackForm(id: string, name: string) {
        if (!this.isMixpanelStarted) return;
        mixpanel.track_forms(id, name);
    }

    /**
     * Reset Mixpanel
     */
    logout() {
        if (!this.isMixpanelStarted) return;
        // Don't send logout event
        // This should lower our MTU count a lot
        // this.trackEvent("logout");
        mixpanel.reset();
    }
}
