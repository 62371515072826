import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

export type SelectionDescription = Record<
    string,
    {
        max: number;
        count: number;
    }
>;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[maxSelection]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxSelectionDirective, multi: true }]
})
export class MaxSelectionDirective implements Validator {
    //  It seems directives can only accept strings. Expecting JSON encoded type SelectionDescription
    @Input() maxSelection: string;

    validate(control: AbstractControl): { [key: string]: unknown } | null {
        if (!this.maxSelection) return null;

        const stateOfAffairs: SelectionDescription = JSON.parse(this.maxSelection);
        const record = stateOfAffairs[control.value];
        if (!record) return null;

        if (record.count >= record.max) return { overLimit: control.value };
        return null;
    }
}
