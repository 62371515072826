import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "statusClass",
    pure: false
})
export class StatusClassPipe implements PipeTransform {
    transform(status: string | number | boolean): string {
        switch (status) {
            case 1:
            case true:
            case "good":
            case "success":
            case "green":
            case "ok":
            case "CONNECTED":
                return "good";
            case "warning":
                return "warning";
            case 0:
            case false:
            case "bad":
            case "error":
            case "red":
            case "no":
            case "failed":
            case "deleted":
            case "DISCONNECTED":
                return "bad";
            case "pending":
            case "disabled":
            case "no_source":
            case "no_flow":
            case "no_channel":
            case "flow_disabled":
            case "channel_disabled":
                return "disabled";
            case "user_action":
            case "info":
                return "info";
            case "gray":
            case "none":
            default:
                return "none";
        }
    }
}
