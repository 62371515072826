import { Component, Input } from "@angular/core";
import { Report, Run } from "../../../report";
import { ReportsService } from "../../../reports.service";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-report-history-actions-column",
    templateUrl: "./report-history-actions-column.component.html"
})
export class ReportHistoryActionsColumnComponent {
    @Input() run: Run;
    @Input() report: Report;
    @Input() canEdit: boolean;

    constructor(
        private rs: ReportsService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService
    ) {}

    async deleteRun(run: Run) {
        await this.modalService.confirm(
            "DELETE",
            "RUN",
            async () => {
                const id = run.id;
                const result = await this.rs.deleteRun(this.report, run);
                if (result) {
                    this.mixpanelService.sendEvent("delete run", { id });
                    return true;
                } else {
                    return false;
                }
            },
            run.name
        );
        // Refresh after delete
        this.rs.getReportHistory(this.report);
    }
}
