import { Pipe, PipeTransform } from "@angular/core";
import { BroadcasterInput } from "./../models/shared";

@Pipe({
    name: "broadcasterInputStatus"
})
export class BroadcasterInputStatusPipe implements PipeTransform {
    transform(input: BroadcasterInput): string {
        if (!input.error_code && input.status !== "Offline") {
            return "good";
        } else if (!input.error_code && input.status === "Offline") {
            return "none";
        } else if (input.error_code) {
            return "error";
        } else return "null";
    }
}
