import { Pipe, PipeTransform } from "@angular/core";
import { urlBuilder } from "@zixi/shared-utils";

@Pipe({
    name: "uId"
})
export class UIdPipe implements PipeTransform {
    transform(val: number): string {
        if (val != null) {
            return urlBuilder.encode(val);
        } else {
            return "";
        }
    }
}
