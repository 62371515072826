import { Component, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorService } from "src/app/components/error/error.service";
import { TranslateService } from "@ngx-translate/core";
import { MultipleConfirmDialogResult } from "src/app/models/shared";

@Component({
    selector: "app-multiple-confirm-dialog",
    templateUrl: "./multiple-confirm-dialog.component.html",
    styleUrls: ["./multiple-confirm-dialog.component.scss"]
})
export class MultipleConfirmDialogComponent<T> implements OnDestroy {
    action: string;
    type: string;
    name: string;
    note?: string;
    warning?: string;
    checkbox?: string;
    checkboxNote?: string;
    deleteInput?: boolean;
    checkboxModel: boolean;
    deleteInputModel: boolean;
    confirmHandler: (object: T, b?: boolean) => Promise<unknown>;
    tagIsDeletable: boolean | null = null;
    checkboxIsChecked: boolean;
    statuses = { hasError: false, hasIgnored: false, hasActionTaken: false }; //multi flag support (ACCESS_TAG requirement)
    set objects(objects: T[]) {
        this.objectsStore = objects.map(o => {
            return {
                saving: false,
                processing: "",
                hasError: false,
                ignored: false,
                actionTaken: false,
                error: null,
                object: o
            };
        });
    }

    objectsStore: {
        saving?: boolean;
        processing?: string;
        hasError?: boolean;
        ignored?: boolean;
        actionTaken: boolean;
        error?: {
            status: number;
            statusText: string;
            message: string;
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        object?: any;
    }[];

    saving = false;
    state = "default";
    keepSelected = true;

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private errorService: ErrorService,
        private translate: TranslateService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });

        const keepSelected = localStorage.getItem("multipleConfirmDialog.userKeepSelected");
        if (keepSelected === "false") this.keepSelected = false;
        else this.keepSelected = true;
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }
    async onSubmitIgnoreDeleted() {
        this.objectsStore = this.objectsStore.filter(o => !o.actionTaken); //remove all objects that have already been deleted
        this.onSubmit();
    }
    async onSubmit() {
        this.statuses["hasError"] = false;
        this.statuses["hasIgnored"] = false;
        this.statuses["hasActionTaken"] = false;

        this.tagIsDeletable = false;
        this.saving = true;
        this.state = "default"; //loop done

        for (const object of this.objectsStore) {
            object.saving = true;
            object.processing = "start";
            let result, customError;
            try {
                result = await this.confirmHandler(object.object, this.checkboxModel);
            } catch (err) {
                if (err.customError) {
                    customError = err.customError;
                    result = false;
                }
            }

            if (result) {
                object.hasError = false;
                if (result === "ignored") {
                    object.ignored = true;
                    this.statuses.hasIgnored = true;
                } else {
                    object.actionTaken = true;
                    this.statuses.hasActionTaken = true;
                }
            } else {
                this.statuses.hasError = true;
                if (!customError) {
                    this.errorService.currentHttpErrorResponse.pipe(take(1)).subscribe(errorResponse => {
                        if (!errorResponse) return;
                        object.error = {
                            status: errorResponse.status,
                            statusText: errorResponse.statusText,
                            message: errorResponse.error.error
                        };
                        if (object.error.message?.includes(this.translate.instant("TAG_REFERENCES_ERROR"))) {
                            this.tagIsDeletable = true;
                        }
                        object.hasError = true;
                    });
                } else {
                    object.error = {
                        status: customError,
                        statusText: customError,
                        message: customError
                    };
                    object.hasError = true;
                }
            }
            this.state = "done";
            object.saving = false;
            object.processing = "end";
        }
        this.saving = false;
    }

    toggleChecked(event) {
        if (event.target.checked) this.checkboxIsChecked = true;
        else this.checkboxIsChecked = false;
    }

    closeModal() {
        localStorage.setItem("multipleConfirmDialog.userKeepSelected", this.keepSelected ? "true" : "false");

        const obj: MultipleConfirmDialogResult = {
            keepSelected: this.keepSelected,
            actionTaken: this.state === "done" ? true : false
        };

        this.activeModal.close(obj);
    }
}
