import { Component, Input } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-input-errors-indicator",
    templateUrl: "./input-errors-indicator.component.html",
    styleUrls: ["./input-errors-indicator.component.scss"]
})
export class InputErrorsIndicatorComponent {
    @Input() errors: ValidationErrors;
    @Input() fieldName: string;
    @Input() invalid: boolean;
    @Input() patternErrorMessage: string;
    constants = Constants;

    get minLength(): number {
        return this.errors?.minlength.requiredLength;
    }

    get min(): number {
        return this.errors?.min.min;
    }

    get max(): number {
        return this.errors?.max.max;
    }
}
