import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";

import { AlertingProfile } from "../../../pages/configuration/events-management/events-management";
import { EventsManagementService } from "../../../pages/configuration/events-management/events-management.service";

@Component({
    selector: "zx-alerting-profile-select",
    templateUrl: "./zx-alerting-profile-select.component.html"
})
export class ZxAlertingProfileSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: AlertingProfile;
    @Input() disabled?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    profiles: AlertingProfile[];
    private profilesSubscription: Subscription;

    constructor(private ems: EventsManagementService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;

        this.profilesSubscription = this.ems.alertingProfiles.subscribe((profiles: AlertingProfile[]) => {
            this.profiles = [...profiles];

            if (!this.model) {
                this.model = this.profiles.find(p => p.default);
                this.modelChanged();
            }

            this.loading = false;
        });

        this.ems.refreshAlertingProfiles();
    }

    ngOnDestroy() {
        this.profilesSubscription.unsubscribe();
    }
}
