<!-- Processing Cluster -->
<div class="form-group">
    <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': isParentFormSubmitted && invalid }"
        >{{ "PROCESSING_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
        ><fa-icon icon="info-circle" [ngbTooltip]="PCContent" triggers="hover click" [closeDelay]="500"></fa-icon
        ><ng-template #PCContent
            ><p class="mb-0">
                {{ "TOOLTIP.CHANNEL_PROCESSING_CLUSTER" | translate }}
            </p>
        </ng-template>
    </label>
    <zx-cluster-select
        ngDefaultControl
        [(ngModel)]="controlledValue"
        [(model)]="controlledValue"
        required="true"
        [disabled]="disabled"
        [ngClass]="{ 'is-invalid': isParentFormSubmitted && invalid }"
        (modelChange)="clusterSelected($event)"
    ></zx-cluster-select>
    <div *ngIf="invalid" class="invalid-feedback">
        <span>{{ "PROCESSING_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.</span>
    </div>
</div>
