import { Component, Input, OnChanges } from "@angular/core";

export const regexPasswordLevels = {
    low: /^.{6,}$/,
    fair: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
    good: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|[!@#$%^&*]).{8,}$/
};

@Component({
    selector: "app-password-strength-icon",
    templateUrl: "./password-strength-icon.component.html"
})
export class PasswordStrengthIconComponent implements OnChanges {
    @Input() password: string;
    passwordLevel: "none" | "good" | "fair" | "low" = "none";

    ngOnChanges() {
        if (!this.password) this.passwordLevel = "none";
        else if (regexPasswordLevels.good.test(this.password)) this.passwordLevel = "good";
        else if (regexPasswordLevels.fair.test(this.password)) this.passwordLevel = "fair";
        else this.passwordLevel = "low";
    }
}
