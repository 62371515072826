import { Component, Input } from "@angular/core";
import { FeederInput } from "src/app/models/shared";

@Component({
    selector: "app-feeder-input-name-column",
    templateUrl: "./feeder-input-name-column.component.html"
})
export class FeederInputNameColumnComponent {
    @Input() input: FeederInput;
}
