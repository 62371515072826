import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import * as _ from "lodash";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { ZmEvent, EventsFilter } from "./event";
import { APIResponse } from "src/app/models/shared";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class EventsService {
    events: Observable<ZmEvent[]>;
    private events$: ReplaySubject<ZmEvent[]>;
    private dataStore: {
        events: ZmEvent[];
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            events: []
        };

        this.events$ = new ReplaySubject(1) as ReplaySubject<ZmEvent[]>;
        this.events = this.events$.asObservable();
    }

    getEventsParameters(eventsFilter?, report = false) {
        let filter = new HttpParams();
        if (eventsFilter) {
            if (eventsFilter.offset) filter = filter.append("offset", eventsFilter.offset);
            if (eventsFilter.pageSize) {
                filter = filter.append("pageSize", eventsFilter.pageSize);
            } else if (!report) {
                filter = filter.append("pageSize", "20");
            }
            if (eventsFilter.fromDate) filter = filter.append("fromDate", eventsFilter.fromDate.toISOString());
            if (eventsFilter.toDate) filter = filter.append("toDate", eventsFilter.toDate.toISOString());

            if (eventsFilter.objectType && eventsFilter.objectType.name === "USER_EVENTS") {
                ["", "user_action", "user_auth"].forEach(obj => {
                    filter = filter.append("eventType", obj);
                });
            } else if (eventsFilter.objectType?.name === "ACCOUNT_MANAGEMENT") {
                filter = filter.append("eventType", "account_management");
            } else if (eventsFilter.objectType && eventsFilter.objectType.name !== "ANY") {
                eventsFilter.objectType.type.forEach(obj => {
                    filter = filter.append("objectType", obj);
                });
            }
            if (eventsFilter.resourceTags) {
                eventsFilter.resourceTags.forEach(tag => {
                    filter = filter.append("resourceTag", tag);
                });
            }
            if (eventsFilter.msgFilter) filter = filter.append("msgFilter", eventsFilter.msgFilter);
            if (eventsFilter.msgTypes) {
                const msgTypesArray = _.keys(_.pickBy(eventsFilter.msgTypes));
                msgTypesArray.forEach(type => {
                    filter = filter.append("msgType", type);
                });
            } else {
                filter = filter.append("msgType", "error");
                filter = filter.append("msgType", "warning");
                filter = filter.append("msgType", "info");
                filter = filter.append("msgType", "success");
            }
            if (eventsFilter.objects) {
                for (const key in eventsFilter.objects) {
                    if (eventsFilter.objects[key]) {
                        for (const id of eventsFilter.objects[key]) {
                            if (eventsFilter.objects[key].length > 0) filter = filter.append(key, id);
                        }
                    }
                }
            }
        }
        return filter;
    }

    getEvents(eventsFilter?: EventsFilter): Observable<ZmEvent[]> {
        this.http
            .get<APIResponse<ZmEvent[]>>(Constants.apiUrl + Constants.apiUrls.events, {
                params: this.getEventsParameters(eventsFilter)
            })
            .subscribe(
                data => {
                    this.dataStore.events = data.result;
                    this.events$.next(Object.assign({}, this.dataStore).events);
                },
                // eslint-disable-next-line no-console
                error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_EVENTS"), error)
            );
        return this.events;
    }

    async getEventsDontStore(eventsFilter?: EventsFilter) {
        try {
            const result = await this.http
                .get<APIResponse<ZmEvent[]>>(Constants.apiUrl + Constants.apiUrls.events, {
                    params: this.getEventsParameters(eventsFilter)
                })
                .toPromise();
            const events: ZmEvent[] = result.result;
            return events;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_EVENTS"), error);
            return false;
        }
    }

    async refreshEvents(eventsFilter?: EventsFilter) {
        const result = await this.http
            .get<APIResponse<ZmEvent[]>>(Constants.apiUrl + Constants.apiUrls.events, {
                params: this.getEventsParameters(eventsFilter)
            })
            .toPromise();
        this.dataStore.events = result.result;
        this.events$.next(Object.assign({}, this.dataStore).events);
    }

    async loadMoreEvents(eventsFilter: EventsFilter) {
        const result = await this.http
            .get<APIResponse<ZmEvent[]>>(Constants.apiUrl + Constants.apiUrls.events, {
                params: this.getEventsParameters(eventsFilter)
            })
            .toPromise();

        if (eventsFilter.offset) this.dataStore.events = this.dataStore.events.concat(result.result);
        else this.dataStore.events = result.result;
        this.events$.next(Object.assign({}, this.dataStore).events);
    }

    async loadNewEvents(eventsFilter: EventsFilter) {
        const result = await this.http
            .get<APIResponse<ZmEvent[]>>(Constants.apiUrl + Constants.apiUrls.events, {
                params: this.getEventsParameters(eventsFilter)
            })
            .toPromise();

        this.dataStore.events = result.result.concat(this.dataStore.events);
        this.events$.next(Object.assign({}, this.dataStore).events);
    }

    clearEvents() {
        this.dataStore.events = [];
        this.events$.next(Object.assign({}, this.dataStore).events);
    }
}
