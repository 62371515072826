{{ bitrate.profile?.name }}
<fa-icon icon="info-circle" [ngbTooltip]="profile" triggers="hover click" [closeDelay]="500" container="body"></fa-icon>
<ng-template #profile>
    <div>
        <div *ngIf="bitrate.profile.do_video">{{ "ENCODING_PROFILE" | translate }}: {{ bitrate.profile.encoding_profile | titlecase }}</div>
        <div *ngIf="bitrate.profile.do_video">{{ "RESOLUTION" | translate }}: {{ bitrate.profile.width }}x{{ bitrate.profile.height }}</div>
        <div *ngIf="bitrate.profile.do_video && bitrate.profile.fps">{{ "FPS" | translate }}: {{ bitrate.profile.fps }}</div>
        <div *ngIf="bitrate.profile.do_video && !bitrate.profile.fps">{{ "FPS" | translate }}: {{ "ORIGINAL" | translate }}</div>
        <div *ngIf="bitrate.profile.do_video">{{ "AVG_VIDEO_BITRATE" | translate }}: {{ bitrate.profile.bitrate_avg | bitrate }}</div>
        <div *ngIf="bitrate.profile.do_video">{{ "MAX_VIDEO_BITRATE" | translate }}: {{ bitrate.profile.bitrate_max | bitrate }}</div>
        <div *ngIf="bitrate.profile.do_video">{{ "PERFORMANCE" | translate }}: {{ constants.videoPerformances[bitrate.profile.performance].name }}</div>
        <div *ngIf="!bitrate.profile.do_video">
            {{ "VIDEO" | translate }}: {{ bitrate.profile.keep_video ? ("ORIGINAL" | translate) : ("REMOVE" | translate) }}
        </div>
        <hr class="mb-1 mt-1" />
        <div *ngIf="bitrate.profile.do_audio">{{ "AUDIO_PROFILE" | translate }}: {{ constants.audioProfiles[bitrate.profile.audio_encoder_profile] }}</div>
        <div *ngIf="bitrate.profile.do_audio">{{ "AUDIO_BITRATE" | translate }}: {{ bitrate.profile.audio_bitrate | bitrate }}</div>
        <div *ngIf="bitrate.profile.do_audio">{{ "AUDIO_SAMPLE_RATE" | translate }}: {{ bitrate.profile.audio_sample_rate }}</div>
        <div *ngIf="!bitrate.profile.do_audio">
            {{ "AUDIO" | translate }}: {{ bitrate.profile.keep_audio ? ("ORIGINAL" | translate) : ("REMOVE" | translate) }}
        </div>
    </div>
</ng-template>
