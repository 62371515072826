<fa-icon
    *ngIf="source?.health?.healthScore != null"
    [icon]="
        source.health?.healthScore <= constants.healthScoreThresholds.error
            ? ('bad' | statusIcon)
            : source.health?.healthScore > constants.healthScoreThresholds.error && source.health?.healthScore <= constants.healthScoreThresholds.good
            ? ('warning' | statusIcon)
            : ('good' | statusIcon)
    "
    [ngClass]="{
        'status-error': source.health?.healthScore <= constants.healthScoreThresholds.error,
        'status-warning':
            source.health?.healthScore > constants.healthScoreThresholds.error && source.health?.healthScore <= constants.healthScoreThresholds.good,
        'status-good': source.health?.healthScore > constants.healthScoreThresholds.good
    }"
></fa-icon>
<span *ngIf="source?.health"> {{ source.health?.healthScore | number : "2.0-0" }}</span>
<span *ngIf="!source?.health">-</span>
