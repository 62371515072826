import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { map } from "lodash";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";

@Component({
    selector: "zx-target-broadcaster-selection",
    templateUrl: "./target-broadcaster-selection.component.html",
    styleUrls: ["./target-broadcaster-selection.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TargetBroadcasterSelectionComponent implements OnInit {
    @Input() clusterId: number;
    @Input() specificBroadcasterOnly = false;
    @Input() disable = false;
    @Input() targetBroadcasterId: number | null;
    @Output() targetBroadcasterIdChange = new EventEmitter<number>();

    targetBXLoading = false;
    targetBXs;
    targetBXsBase = [
        { name: this.translate.instant("ANY"), id: 0 },
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1 },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4 }
    ];

    constructor(private translate: TranslateService, private broadcastersService: BroadcastersService) {}

    async ngOnInit() {
        await this.getTargetBroadcasters(this.clusterId);
        this.targetBroadcasterIdChange.emit(this.targetBroadcasterId);
    }

    async getTargetBroadcasters(id: number) {
        this.targetBXLoading = true;
        if (this.specificBroadcasterOnly) {
            this.targetBXs = this.targetBXsBase;
        }

        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (broadcasters && broadcasters.length > 0) {
            this.targetBXs = (this.specificBroadcasterOnly ? [] : this.targetBXsBase).concat(
                map(broadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }
        this.targetBXLoading = false;
    }

    onTargetBroadcasterChange($event) {
        this.targetBroadcasterId = $event.id;
        this.targetBroadcasterIdChange.emit(this.targetBroadcasterId);
    }
}
