import { Component, OnInit, Input } from "@angular/core";
import { Constants } from "./../../../constants/constants";
import { MediaConnectSource } from "src/app/models/shared";

@Component({
    selector: "zx-mediaconnect-source",
    templateUrl: "./zx-mediaconnect-source.component.html"
})
export class ZxMediaConnectSourceComponent implements OnInit {
    @Input() model: MediaConnectSource;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() showCluster?: boolean = true;
    @Input() showClusterLink?: boolean = false;
    @Input() searchTerm?: string | string[];

    urls = Constants.urls;

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
