<!-- Table -->
<app-table-list
    [tableName]="'object-incident'"
    [ngClass]="{ 'mt-2': bordered }"
    [displayTableName]="'INCIDENTS' | translate"
    [data]="incidents$ | async"
    [(tableSchema)]="tableColumnsSchema"
    (rowSelected)="selectRow($event)"
    [showFilter]="true"
    [showPagination]="true"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [refreshing]="refreshing"
    [autoRows]="autoRows"
>
    <!-- Calendar -->
    <div class="form-group calendar-group position-relative mb-0 d-flex">
        <label for="dateRange" class="d-none d-xs-flex">{{ "DATE_RANGE" | translate }}&nbsp;&nbsp;</label>
        <div class="input-group">
            <input
                class="form-control dateRange"
                id="dateRange"
                name="dateRange"
                (click)="toggleDatePicker()"
                [(ngModel)]="dateRange"
                placeholder="{{ 'SELECT_DATE/TIME_RANGE' | translate }}"
                (keydown)="onlyDelete($event)"
                autocomplete="off"
                title="{{ dateRange }}"
            />
            <button class="btn btn-outline-primary d-print-none" (click)="toggleDatePicker()" type="button" title="{{ 'SELECT_DATE/TIME' | translate }}">
                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
            </button>
        </div>
        <div class="datepicker-container absolute" *ngIf="showDatePicker" (clickOutside)="clickOutsidePicker()">
            <div class="arrow"></div>
            <div class="datepicker-inner-container shadow-sm">
                <fa-icon icon="times-circle" class="close-icon" (click)="closeDatePicker()"></fa-icon>
                <div class="date-navigation">
                    <ul class="nav nav-pills nav-fill">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" (click)="showFrom = true" [ngClass]="{ active: showFrom }">{{
                                "FROM" | translate
                            }}</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" (click)="showFrom = false" [ngClass]="{ active: !showFrom }">{{
                                "TO" | translate
                            }}</a>
                        </li>
                    </ul>
                    <div class="date-container">
                        <div class="datepicker" *ngIf="showFrom">
                            <zx-date-time-picker name="fromDate" [(ngModel)]="fromDateString" (ngModelChange)="fromDateChanged($event)"></zx-date-time-picker>
                        </div>
                        <div class="datepicker" *ngIf="!showFrom">
                            <zx-date-time-picker name="toDate" [(ngModel)]="toDateString" (ngModelChange)="toDateChanged($event)"></zx-date-time-picker>
                        </div>
                    </div>
                </div>
                <div class="date-presets">
                    <h5>{{ "RANGE" | translate }}</h5>
                    <ul class="list-group">
                        <li
                            *ngFor="let preset of datePresets"
                            href="javascript:void(0)"
                            (click)="setDateRangeFromPreset(preset)"
                            class="list-group-item list-group-item-action"
                            [ngClass]="{ active: (preset | translate) === dateRange }"
                        >
                            {{ preset | translate }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-table-list>
