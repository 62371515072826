import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-collapsed-form-section",
    templateUrl: "./collapsed-form-section.component.html"
})
export class CollapsedFormSectionComponent implements OnInit {
    @Input() title: string;
    @Input() defaultShowSection? = false;
    @Output() updateVisibility = new EventEmitter<boolean>();
    showSection: boolean;

    ngOnInit(): void {
        this.showSection = this.defaultShowSection;
    }

    toggleShowDisplay() {
        this.showSection = !this.showSection;
        this.updateVisibility.emit(this.showSection);
    }
}
