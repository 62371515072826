import React, { useEffect } from "react";
import { NodeProps, Handle, Position } from "reactflow";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { NodeField, nodeFieldProps } from "./content-components/nodeField";
import { Feeder, Receiver, Zec } from "src/app/pages/zecs/zecs/zec";

export type ZecNodeData = {
    name: string;
    sourceHandles: { id: string; properties: { side: string } }[];
    targetHandles: { id: string; properties: { side: string } }[];
    direction: string;
    object: Feeder | Receiver | Zec;
    type: string;
    fields: nodeFieldProps[];
    openUrl: string;
    editUrl: string;
    canEdit: boolean;
    displayType: string;
    link: string;
    status: string;
    statusIcon: string;
    typeIcon: string;
};

export default function ZecNode({ data }: NodeProps<ZecNodeData>) {
    useEffect(() => {
        // call api or anything
        // data = { ...data };
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openLink = () => {
        window.open(data.openUrl, "_blank");
        setAnchorEl(null);
    };

    const edit = () => {
        window.open(data.editUrl, "_blank");
        setAnchorEl(null);
    };

    return (
        <>
            <div className={"nodeBackground " + data.status}>
                <div className="nodeHeader">
                    <div className="nodeIcon">
                        <i className={"fa " + data.typeIcon + " fa-sm"}></i>
                    </div>
                    <div className="nodeType">{data.displayType}</div>
                </div>
                <div className="nodeStatus">
                    <div className="nodeName" title={data.name}>
                        {data.object.readOnly && (
                            <div title={data.name}>
                                <i className={data.statusIcon}></i>
                                {data.name}
                            </div>
                        )}
                        {!data.object.readOnly && (
                            <div>
                                <i className={data.statusIcon}></i>
                                <a href={data.link} title={data.name} target="_blank">
                                    {data.name}
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="nodeActions">
                        <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                        >
                            <i className="fa fa-ellipsis-vertical fa-sm"></i>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button"
                            }}
                        >
                            <MenuItem disabled={!(data.status !== "pending" && data.canEdit)} className="text-primary" onClick={openLink}>
                                <i className="fa fa-external-link-alt fa-sm fa-fw me-1"></i>Open
                            </MenuItem>
                            <MenuItem disabled={!data.canEdit} className="text-primary" onClick={edit}>
                                <i className="fa fa-pencil fa-sm fa-fw me-1"></i>Edit
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className="nodeFields">
                    {data.fields.map(field => (
                        <NodeField
                            noLabel={field.noLabel}
                            key={field.label}
                            label={field.label}
                            value={field.value}
                            unit={field.unit}
                            link={field.link}
                            tooltip={field.tooltip}
                            icons={field.icons}
                            isError={field.isError}
                        />
                    ))}
                </div>
            </div>
            <div className={"handles targets " + data.direction}>
                {data.targetHandles?.map(handle => (
                    <Handle key={handle.id} id={handle.id} type="target" position={handle.properties.side === "NORTH" ? Position.Top : Position.Left} />
                ))}
            </div>
            <div className={"handles sources " + data.direction}>
                {data.sourceHandles?.map(handle => (
                    <Handle key={handle.id} id={handle.id} type="source" position={handle.properties.side === "SOUTH" ? Position.Bottom : Position.Right} />
                ))}
            </div>
        </>
    );
}
