import { Pipe, PipeTransform } from "@angular/core";
import { FeederInput } from "./../models/shared";

@Pipe({
    name: "feederInputStatus"
})
export class FeederInputStatusPipe implements PipeTransform {
    transform(input: FeederInput): string {
        if (!input.error && input.bitrate > 0) {
            return "good";
        } else if (!input.error && input.bitrate === 0) {
            return "warning";
        } else if (input.error) {
            return "error";
        } else return "null";
    }
}
