import { Directive, Input, inject, ElementRef, OnChanges, OnDestroy } from "@angular/core";
import * as React from "react";
import * as ReactDom from "react-dom/client";

@Directive({
    selector: "[appReactComponent]"
    // standalone: true
})
export class ReactComponentWrapperDirective<Comp extends React.ElementType> implements OnChanges, OnDestroy {
    @Input("appReactComponent") reactComponent: Comp;
    @Input() componentProps: React.ComponentProps<Comp>;
    private reactComponentRoot: ReactDom.Root;

    constructor() {
        this.reactComponentRoot = ReactDom.createRoot(inject(ElementRef).nativeElement);
    }

    ngOnChanges() {
        const reactElement = React.createElement(this.reactComponent, this.componentProps);
        this.reactComponentRoot.render(reactElement);
    }

    ngOnDestroy() {
        this.reactComponentRoot.unmount();
    }
}
