<div class="title-bar">
    <div class="row justify-content-center">
        <div class="{{ classForTitle }}">
            <div class="title">
                <h1>
                    {{ (!isClone && !isEdit ? "CREATE_NEW" : isClone ? "CLONE" : isEdit ? "EDIT" : "") | translate }} {{ translatedTitles
                    }}<fa-icon
                        *ngIf="hasTourStartButton"
                        class="ms-2 tourStartButton"
                        icon="question-circle"
                        id="tooltip"
                        #tooltip="matTooltip"
                        matTooltip="{{ tourStartTooltipString | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="xs"
                        (click)="tourService.start(); mixpanelService.sendEvent(mixpanelString)"
                    ></fa-icon
                    ><ng-content></ng-content>
                </h1>
            </div>
        </div>
    </div>
</div>
