<button
    type="button"
    class="d-none d-md-inline-block btn border-0 btn-outline-secondary"
    title="{{ 'MUTE' | translate }}"
    (click)="toggleMute.emit()"
    [disabled]="!canEdit"
>
    <fa-icon [icon]="muted ? 'bell' : 'bell-slash'" size="sm" [fixedWidth]="true"></fa-icon
    ><span class="d-none d-xxl-inline-block ms-1">{{ (muted ? "UNMUTE" : "MUTE") | translate }}</span>
</button>
<button
    *ngIf="canToggle"
    [disabled]="!canEdit || !canEnable"
    type="button"
    class="d-none d-md-inline-block btn border-0"
    [attr.title]="(isEnabled ? 'DISABLE' : 'ENABLE') | translate"
    [ngClass]="isEnabled ? 'btn-outline-warning' : 'btn-outline-success'"
    (click)="toggle.emit()"
>
    <fa-icon [icon]="isEnabled ? 'ban' : 'power-off'" size="sm"></fa-icon
    ><span class="d-none d-xxl-inline-block ms-1">{{ (isEnabled ? "DISABLE" : "ENABLE") | translate }}</span>
</button>
<button
    [disabled]="!canEdit"
    type="button"
    class="d-none d-md-inline-block btn border-0 btn-outline-primary"
    [attr.title]="'EDIT' | translate"
    (click)="edit()"
>
    <fa-icon icon="pencil" size="xs"></fa-icon><span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
</button>
<button [disabled]="refreshing" type="button" class="btn border-0 btn-outline-secondary" [attr.title]="'REFRESH' | translate" (click)="refresh.emit()">
    <fa-icon icon="sync-alt" [spin]="refreshing" size="sm"></fa-icon><span class="d-none d-xxl-inline-block ms-1">{{ "REFRESH" | translate }}</span>
</button>
