import { Component } from "@angular/core";
import { BreadcrumbsService } from "src/app/services/breadcrumbs.service";

@Component({
    selector: "zx-breadcrumbs",
    templateUrl: "./zx-breadcrumbs.component.html",
    styleUrls: ["./zx-breadcrumbs.component.scss"]
})
export class ZxBreadcrumbsComponent {
    shouldShowBreadcrumbs = true;

    constructor(public breadcrumbsService: BreadcrumbsService) {}
}
