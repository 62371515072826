import { ComponentRef } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap, SomeZixiObject } from "../../../models/shared";
import { ZxStatusFullComponent } from "./zx-status-full.component";

type StringCallback<T> = (row: T) => string;
type ModelCallback<T> = (row: T) => Partial<SomeZixiObject>;
type BooleanCallback<T> = (row: T) => boolean;

export const assignComponentsStatusInputsFactory =
    <T = KeyMap<Base>>(
        params?: Partial<{
            lockIconTextCallBack: StringCallback<T>;
            modelCallBack: ModelCallback<T>;
            statusCallback: StringCallback<T>;
            textCallback: StringCallback<T>;
            standby: BooleanCallback<T> | boolean;
            showOtherIcons: BooleanCallback<T> | boolean;
            navigateOnClick?: boolean;
        }>
    ) =>
    (statusComponentRef: ComponentRef<ZxStatusFullComponent>, row: T, searchTerm: string | string[]) => {
        const statusCompRef = statusComponentRef.instance;
        statusCompRef.model = params?.modelCallBack ? params.modelCallBack(row) : row;
        statusCompRef.lockIconText = params?.lockIconTextCallBack ? params.lockIconTextCallBack(row) : null;
        statusCompRef.searchTerm = searchTerm;
        statusCompRef.status = params?.statusCallback ? params.statusCallback(row) : null;
        statusCompRef.text = params?.textCallback ? params.textCallback(row) : null;
        statusCompRef.standby = params?.standby
            ? typeof params.standby === "boolean"
                ? params.standby
                : params.standby(row)
            : undefined;
        statusCompRef.showOtherIcons = params?.showOtherIcons
            ? typeof params.showOtherIcons === "boolean"
                ? params.showOtherIcons
                : params.showOtherIcons(row)
            : undefined;
        statusCompRef.navigateOnClick = params?.navigateOnClick || false;
    };
