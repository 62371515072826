<fieldset
    ngModelGroup="vpc_{{ model.id }}"
    #vpc="ngModelGroup"
    class="form-group bordered mb-2"
    [ngClass]="{
        collapsed: collapsed && !((outputSelection && jpegXSOutput) || (sourceSelection && source && source.protocol === 'st2110-jpegxs'))
    }"
>
    <legend>
        <fa-icon class="me-1" icon="plus" size="xs" [fixedWidth]="true" *ngIf="collapsed" (click)="expandControls()"></fa-icon
        ><fa-icon class="me-1" icon="minus" size="xs" [fixedWidth]="true" *ngIf="!collapsed" (click)="collapseControls()"></fa-icon
        >{{ model && model.name ? model.name : "New" }}
    </legend>
    <div *ngIf="(outputSelection && jpegXSOutput) || (sourceSelection && source && source.protocol === 'st2110-jpegxs')" class="form-group mb-1">
        <div class="form-check">
            <input
                type="checkbox"
                id="vpc_stream_select_checkbox_{{ model.id }}"
                name="vpc_stream_select_checkbox"
                class="form-check-input"
                [(ngModel)]="model.inUse"
                (change)="VPCSelectionChange()"
            />
            <label class="form-check-label" for="vpc_stream_select_checkbox_{{ model.id }}">{{ "USE_VPC_INTERFACE" | translate }}</label>
        </div>
    </div>
    <div [ngClass]="{ 'd-none': collapsed }">
        <!-- Name -->
        <div class="form-group mb-2">
            <label for="vpcInterfaceName" [ngClass]="{ 'is-invalid': isParentFormSubmitted && vpcInterfaceName.errors }"
                >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon><fa-icon icon="lock" size="sm"></fa-icon
            ></label>
            <input
                type="text"
                id="vpcInterfaceName"
                name="vpcInterfaceName"
                placeholder="{{ 'NAME' | translate }}"
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && vpcInterfaceName.errors }"
                [(ngModel)]="model.name"
                [disabled]="!removeAllowed || readOnly"
                (change)="nameChanged()"
                #vpcInterfaceName="ngModel"
                required
                minlength="1"
                pattern="{{ constants.validators.name }}"
                duplicateName="{{ vpcNames ? vpcNames.join(',') : '' }}"
            />
            <div *ngIf="vpcInterfaceName.invalid" class="invalid-feedback">
                <div *ngIf="vpcInterfaceName.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                <div *ngIf="vpcInterfaceName.errors.minlength">
                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                </div>
                <div *ngIf="vpcInterfaceName.errors.pattern">
                    {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                </div>
                <div *ngIf="vpcInterfaceName.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
            </div>
        </div>
        <!-- Type -->
        <div class="form-group mb-2">
            <label for="vpcInterfaceType" [ngClass]="{ 'is-invalid': isParentFormSubmitted && vpcInterfaceType.errors }">
                {{ "TYPE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon><fa-icon icon="lock" size="sm"></fa-icon>
            </label>
            <ng-select
                id="vpcInterfaceType"
                name="vpcInterfaceType"
                class="form-control form-control-sm"
                [items]="vpcInterfaceTypes"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'TYPE' | translate }}"
                [(ngModel)]="model.network_interface_type"
                [disabled]="!removeAllowed || readOnly || !model.name || model.name === ''"
                bindLabel="name"
                bindValue="id"
                #vpcInterfaceType="ngModel"
                (change)="typeChanged()"
                maxSelection="{{ runNetworkTypesStateQuery() }}"
                [ngClass]="{
                    'is-invalid': isParentFormSubmitted && vpcInterfaceType.errors
                }"
            >
            </ng-select>
            <div *ngIf="vpcInterfaceType.invalid" class="invalid-feedback">
                <div *ngIf="vpcInterfaceType.errors.overLimit === 'ena'">{{ "AT_MOST_2_ENA_VPCS_SUPPORTED" | translate }}.</div>
                <div *ngIf="vpcInterfaceType.errors.overLimit === 'efa'">{{ "AT_MOST_1_EFA_VPCS_SUPPORTED" | translate }}.</div>
            </div>
        </div>
        <!-- Role ARN -->
        <div class="form-group mb-2">
            <label for="awsIAMRole" [ngClass]="{ 'is-invalid': isParentFormSubmitted && awsIAMRole.errors }">
                {{ "AWS_ROLE_ARN" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon><fa-icon icon="lock" size="sm"></fa-icon>
            </label>
            <ng-select
                id="awsIAMRole"
                name="awsIAMRole"
                class="form-control form-control-sm"
                required
                [items]="awsIAMRoles"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ROLE_ARN' | translate }}"
                [(ngModel)]="model.role_arn"
                bindLabel="name"
                bindValue="arn"
                #awsIAMRole="ngModel"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && awsIAMRole.errors }"
                [disabled]="!channel || !channel.aws_account_id || !removeAllowed || readOnly || !model.name || model.name === ''"
                [loading]="awsIAMRolesLoading"
            >
            </ng-select>
            <div *ngIf="awsIAMRole.invalid" class="invalid-feedback">
                <div *ngIf="awsIAMRole.errors.required">{{ "AWS_ROLE_ARN" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            </div>
        </div>
        <!-- Subnet -->
        <div class="form-group mb-2">
            <label for="subnet" [ngClass]="{ 'is-invalid': isParentFormSubmitted && subnet.errors }">
                {{ "SUBNET" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon><fa-icon icon="lock" size="sm"></fa-icon>
            </label>
            <ng-select
                id="subnet"
                name="subnet"
                class="form-control form-control-sm"
                required
                [items]="awsSubnetOptions"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'SUBNET' | translate }}"
                [(ngModel)]="model.subnet_id"
                bindLabel="name"
                bindValue="id"
                #subnet="ngModel"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && subnet.errors }"
                [disabled]="!channel || !channel.aws_account_id || !removeAllowed || readOnly || !model.name || model.name === ''"
                [loading]="awsRegionDetailsLoading"
                (change)="updateVpcInterfaceSubnet()"
            >
            </ng-select>
            <div *ngIf="subnet.invalid" class="invalid-feedback">
                <div *ngIf="subnet.errors.required">{{ "SUBNET" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            </div>
        </div>
        <!-- Security Groups -->
        <div class="form-group mb-2">
            <label for="securityGroups" [ngClass]="{ 'is-invalid': isParentFormSubmitted && securityGroups.errors }">
                {{ "SECURITY_GROUPS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon><fa-icon icon="lock" size="sm"></fa-icon>
            </label>
            <ng-select
                id="securityGroups"
                name="securityGroups"
                class="form-control form-control-sm"
                required
                [multiple]="true"
                [maxSelectedItems]="5"
                [closeOnSelect]="false"
                [clearSearchOnAdd]="true"
                [items]="awsSecurityGroupOptions"
                [clearable]="false"
                placeholder="{{ 'SELECT' | translate }} {{ 'SECURITY_GROUPS' | translate }}"
                [(ngModel)]="selectedSecurityGroups"
                bindLabel="name"
                bindValue="id"
                #securityGroups="ngModel"
                [ngClass]="{ 'is-invalid': isParentFormSubmitted && securityGroups.errors }"
                [disabled]="!channel || !channel.aws_account_id || !removeAllowed || readOnly || !model.name || model.name === ''"
                [loading]="awsRegionDetailsLoading"
                (change)="updateVpcInterfaceSecurityGroups()"
            >
            </ng-select>
            <div *ngIf="securityGroups.invalid" class="invalid-feedback">
                <div *ngIf="securityGroups.errors.required">{{ "SECURITY_GROUPS" | translate }} {{ "ARE_REQUIRED" | translate }}.</div>
            </div>
        </div>
    </div>

    <!-- Bound IP (read only)-->
    <div *ngIf="model.ip && model.ip.length > 0" class="form-group">
        <legend>
            <span *ngIf="sourceSelection">{{ IN_BOUND_IP | translate }}</span>
            <span *ngIf="outputSelection">{{ OUT_BOUND_IP | translate }}</span>
            &nbsp;
            {{ model.ip }}
        </legend>
    </div>

    <!-- Button -->
    <div [ngClass]="{ 'd-none': collapsed }">
        <button *ngIf="removeAllowed" type="button" class="btn btn-sm btn-outline-primary float-start clear-both" (click)="reportRemoveVPC()">
            <fa-icon icon="minus" size="sm" class="me-1"></fa-icon>{{ "REMOVE_VPC_INTERFACE" | translate }}
        </button>
    </div>

    <div *ngIf="jpegXSOutput" class="form-group mb-2">
        <label for="vpcInterfaceDestination" [ngClass]="{ 'is-invalid': isParentFormSubmitted && vpcInterfaceDestination.errors }">{{
            "TARGET_IP" | translate
        }}</label>
        <input
            type="text"
            id="vpcInterfaceDestination"
            name="vpcInterfaceDestination"
            placeholder="{{ 'IP' | translate }}"
            class="form-control form-control-sm"
            [ngClass]="{ 'is-invalid': isParentFormSubmitted && vpcInterfaceDestination.errors }"
            [(ngModel)]="model.destination_ip"
            #vpcInterfaceDestination="ngModel"
            required
            minlength="1"
        />
        <div *ngIf="vpcInterfaceDestination.invalid" class="invalid-feedback">
            <div *ngIf="vpcInterfaceDestination.errors.required">{{ "TARGET_IP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
            <div *ngIf="vpcInterfaceDestination.errors.minlength">
                {{ "TARGET_IP" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
            </div>
        </div>
    </div>
</fieldset>
