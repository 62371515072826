import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "minutesSeconds"
})
export class MinuteSecondsPipe implements PipeTransform {
    transform(value: number): string {
        const minutes = Math.floor(value % 60);
        const seconds = Math.floor(((value * 60) % 60) % 60);

        let displaySeconds = seconds.toString();
        let displayMinutes = minutes.toString();

        if (minutes < 10) displayMinutes = "0" + displayMinutes;
        if (seconds < 10) displaySeconds = "0" + displaySeconds;

        return `${displayMinutes}:${displaySeconds}`;
    }
}
