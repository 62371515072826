import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "bitrate"
})
export class BitratePipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value)) return "";
        const transformedValue: number = value / 1000;
        return Math.trunc(transformedValue) + " kbps";
    }
}
