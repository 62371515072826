import { Broadcaster, CustomImage, ZixiPlus } from "../../models/shared";
import { AWSInstanceDetailsList } from "@zixi/models";
import { RedirectionRouting } from "@zixi/models";

export class Cluster extends ZixiPlus {
    _frontData?: {
        sortableStatus: string;
        is_aws: boolean;
        is_azure: boolean;
        is_gcp: boolean;
        prettyGCPZones: string;
        prettyGCPSubnet: string;
        prettyRegionName: string | null;
        vpcName: string;
        subnetName: string;
        securityGroupName: string;
        scaling: string;
        types: string[];
        sortableName: string;
        is_auto_scaling: boolean;
        lastRefresh: string;
    };
    acknowledged: number;
    activation_key: string;
    active_flapping: boolean;
    active_mute: boolean;
    allow_ignore_dtls_cert: number;
    allow_unmanaged_inputs: number;
    allow_unmanaged_outputs: number;
    api_password: string;
    api_user: string;
    auth_mode: string;
    auth_param: string;
    aws_account_id: number;
    azure_account_id: number;
    broadcasters: Broadcaster[];
    broadcastersSummary: {
        good: number;
        bad: number;
        acknowledged: number;
        warning: number;
        disabled: number;
        pending: number;
    };
    bx_input_bw_limit: number;
    bx_output_bw_limit: number;
    created_at: string;
    dns_prefix: string;
    dtls: number;
    eips: string;
    flapping: string;
    gcp_account_id: number;
    generalStatus: string;
    http_streaming_port: number;
    id: number;
    inputs_port: number;
    instance_type: string;
    nvidia_type: string;
    key_pair: string;
    load_balance: number;
    transcode_mem_limit: number;
    transcode_cpu_limit: number;
    transcode_hdd_limit: number;
    transcode_gpu_limit: number;
    transcode_gpu_dec_limit: number;
    transcode_gpu_enc_limit: number;
    transcode_gpu_mem_limit: number;
    muted: number;
    muted_until: string;
    name: string;
    outputs_port: number;
    region: string;
    remote_access_key_id: number;
    root_device_size: number;
    scaling: string;
    security_group: string;
    subnet: string;
    updated_at: string;
    version_id: number;
    zcp_version_id: number;
    zcp_version_name: string;
    vpc: string;
    zones: string;
    configure_scte_reports: boolean;
    hasFullDetails?: boolean;
    broadcaster_version: string;
    realtime_bx_priority: number; // 0/1
    rtmp_input_port: number | null;
    inputs_billing_code: string | null;
    inputs_billing_password: string | null;
    outputs_billing_code: string | null;
    outputs_billing_password: string | null;
    termination_protection: boolean;
    auto_agentz: boolean;
    customImage?: CustomImage;
    alt_cluster_id: number | null;

    external_auth_url: string | null;
    external_auth_type: string | null;
    external_auth_credentials: string | null;

    detailed_monitoring: boolean;
    require_eip: boolean;
    user_data: string | null;
    redirectionRoutings?: RedirectionRouting[];
    altCluster: Cluster | null;
}

export class VersionsResponse {
    zmVersions: BroadcasterVersion[];
    zcpVersions: ZcpBroadcasterVersion[];
}

export class BroadcasterVersion {
    id: number;
    application: string;
    version: string;
    url: string;
    name: string;
    hidden: number;
    customer_id: number;
}

export class ZcpBroadcasterVersion {
    file_id: number;
    version: string;
    retired?: boolean; // front end param to mark fake zcp versions that has been retired
    platform_name: "AWS Graviton2 (ARM 64-bit)" | "Linux (X86 64-bit)";
    is_private: boolean;
}

/////////////////////////
export class AWSAccount {
    id: number;
    customer_id: number;
    name: string;
    iam_role_arn: string;
    iam_role_tag: string;
    created_at: string;
    updated_at: string;
}

export class AzureAccount {
    id: number;
    name: string;
    subscription_id: string;
}

export class GCPAccount {
    id: number;
    customer_id: number;
    name: string;
    client_email: string;
    created_at: string;
    updated_at: string;
}

export class AWSVPC {
    name: string;
    subnets: {
        [key: string]: string;
    };
    security_groups: AWSSecurityGroup[];
}

export class AWSSecurityGroup {
    id: string;
    name: string;
    permissions: {
        IpProtocol: string;
        FromPort: number;
        ToPort: number;
        IpRanges: {
            CidrIp: string;
        }[];
    }[];
}

export type AWSRegions = Record<string, string>;

export interface AWSVPCs {
    [key: string]: AWSVPC;
}

export class AWSRegionOption {
    keys: string[];
    pricing: AWSInstanceDetailsList;
    vpcs: AWSVPCs;
}

// Azure
export class AzureNetwork {
    id: string;
    name: string;
    subnets: {
        id: string;
        name: string;
        addressPrefix: string;
    }[];
}

export class VirtualMachineSize {
    id: string;
    name: string;
    numberOfCores: number;
    osDiskSizeInMB: number;
    resourceDiskSizeInMB: number;
    memoryInMB: number;
    maxDataDiskCount: number;
}

export type AzureRegions = Record<string, string>;

export class AzureRegionOption {
    networks: AzureNetwork[];
    securityGroups: {
        id: string;
        name: string;
    }[];
    virtualMachineSizes: VirtualMachineSize[];
}

// GCP
export class GCPMachine {
    id: string;
    name: string;
    numberOfCores: number;
    memoryInMB: number;
}

export type GCPRegions = string[];

export interface GCPRegionZone {
    name: string;
    id: string;
}

export class GCPRegionOption {
    zones: GCPRegionZone[];
    networks: {
        name: string;
        id: string;
    }[];
    machines: GCPMachine[];
}
