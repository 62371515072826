import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import moment from "moment";
import * as _ from "lodash";
import * as d3 from "d3";
import { Pid, Bitrate } from "../../../pages/sources/sources.service";

@Component({
    selector: "app-bitrates-chart",
    templateUrl: "./bitrates-chart.component.html"
})
export class BitratesChartComponent implements OnChanges, OnDestroy {
    @Input() pids: Pid[];
    private yDomain: number[];
    private xDomain: Date[];
    private chart: d3.Selection<SVGSVGElement, undefined, null, undefined>;
    private resizeObserver: ResizeObserver;
    readonly CONFIGS = {
        COLORS: ["#7eb26d", "#eab839", "#6ed0e0", "#ef843c", "#e24d42", "#1f78c1", "#ba43a9", "#705da0"],
        POSITION_LINE_COLOR: "#BA0F30",
        MARGIN: {
            TOP: 30,
            BOTTOM: 30,
            RIGHT: 1,
            LEFT: 65
        },
        HEIGHT: 220,
        TICKS: {
            PADDING: 15,
            SPACING: {
                X: 90,
                Y: 45
            }
        }
    };

    runOutsideAngularHandler(mainContainer: d3.Selection<HTMLDivElement, undefined, null, undefined>) {
        const chartContainer = mainContainer.append("div");
        const containerWidth = chartContainer.node().clientWidth;
        this.chart = chartContainer.append("svg");
        this.setChartSize(this.chart, containerWidth);
        this.drawLegend(mainContainer);
        this.setYDomain();
        this.setXDomain();
        const xAxisSelection = this.drawXAxis(this.chart);
        const yAxisSelection = this.drawYAxis(this.chart);
        const graphLineSelections = this.drawGraphLine(this.chart);
        const graphAreaSelections = this.drawGraphArea(this.chart);
        const closestRecordSelections = this.drawClosestRecord(this.chart);
        const positionLineSelection = this.drawPositionLine(this.chart);
        const tooltipSelection = this.drawTooltip(chartContainer);
        this.registerPointerEventHandlers(this.chart, tooltipSelection, closestRecordSelections, positionLineSelection);
        this.registerResizeHandler(
            mainContainer,
            xAxisSelection,
            yAxisSelection,
            graphLineSelections,
            graphAreaSelections
        );
    }

    async ngOnChanges() {
        if (this.chart) {
            this.setYDomain();
            this.setXDomain();

            const xAxisSelection: d3.Selection<SVGGElement, unknown, null, undefined> = this.chart.select(".x-axis");
            const yAxisSelection: d3.Selection<SVGGElement, unknown, null, undefined> = this.chart.select(".y-axis");

            const graphLineSelections: d3.Selection<SVGPathElement, unknown, null, undefined>[] = [];
            const graphAreaSelections: d3.Selection<SVGPathElement, unknown, null, undefined>[] = [];
            for (let i = 0; i < this.pids?.length ?? 0; ++i) {
                graphLineSelections.push(this.chart.select(`.graph-line-index-${i}`));
                graphAreaSelections.push(this.chart.select(`.graph-area-index-${i}`));
            }

            this.startDataTransition(xAxisSelection, yAxisSelection, graphLineSelections, graphAreaSelections);
        }
    }

    ngOnDestroy(): void {
        this.resizeObserver.disconnect();
    }

    private drawXAxis(chart: d3.Selection<SVGSVGElement, undefined, null, undefined>) {
        const xAxisYCoordinate = this.getChartHeight() - this.CONFIGS.MARGIN.BOTTOM;
        return chart
            .append("g")
            .attr("transform", `translate(0,${xAxisYCoordinate})`)
            .attr("class", "x-axis")
            .call(this.getXAxisGeneratorFunction())
            .call(this.setGridLinesOpacity)
            .call(this.addStyleToAxesLabels);
    }

    private drawYAxis(chart: d3.Selection<SVGSVGElement, undefined, null, undefined>) {
        const yAxisXCoordinate = this.CONFIGS.MARGIN.LEFT;
        return chart
            .append("g")
            .attr("transform", `translate(${yAxisXCoordinate},0)`)
            .attr("class", "y-axis")
            .call(this.getYAxisGeneratorFunction())
            .call(this.setGridLinesOpacity)
            .call(this.addStyleToAxesLabels);
    }

    private drawGraphLine(chart: d3.Selection<SVGSVGElement, undefined, null, undefined>) {
        return this.pids.map((pid, i) =>
            chart
                .append("path")
                .attr("class", "graph-line")
                .attr("class", `graph-line-index-${i}`)
                .attr("fill", "none")
                .attr("stroke-width", 1)
                .attr("stroke-opacity", 1)
                .attr("d", this.getGraphLine(pid))
                .attr("stroke", this.CONFIGS.COLORS[i % this.CONFIGS.COLORS.length])
        );
    }

    private drawGraphArea(chart: d3.Selection<SVGSVGElement, undefined, null, undefined>) {
        return this.pids.map((pid, i) =>
            chart
                .append("path")
                .attr("class", "graph-area")
                .attr("class", `graph-area-index-${i}`)
                .attr("opacity", 0.1)
                .attr("d", this.getGraphArea(pid))
                .attr("fill", this.CONFIGS.COLORS[i % this.CONFIGS.COLORS.length])
        );
    }

    private drawClosestRecord(chart: d3.Selection<SVGSVGElement, undefined, null, undefined>) {
        return this.pids.map((pid, i) => {
            const closestRecordSelection = chart
                .append("circle")
                .attr("r", 2)
                .attr("stroke", this.CONFIGS.COLORS[i])
                .attr("fill", this.CONFIGS.COLORS[i])
                .attr("fill-opacity", 0.1);
            this.setVisibility(closestRecordSelection, false);
            return closestRecordSelection;
        });
    }

    private drawPositionLine(chart: d3.Selection<SVGSVGElement, undefined, null, undefined>) {
        const positionLineSelection = chart
            .append("rect")
            .attr("width", 1)
            .attr("x", -1)
            .attr("height", this.getGraphHeight())
            .attr("fill", this.CONFIGS.POSITION_LINE_COLOR);
        this.setVisibility(positionLineSelection, false);
        return positionLineSelection;
    }

    private drawTooltip(chartContainer: d3.Selection<HTMLDivElement, undefined, null, undefined>) {
        chartContainer.style("position", "relative");
        const tooltipSelection = chartContainer.append("div").classed("chart-tooltip", true);
        this.setVisibility(tooltipSelection, false);
        tooltipSelection.append("span").classed("date", true);
        for (let i = 0; i < this.pids?.length ?? 0; ++i) {
            const tooltipBitrateContainer = tooltipSelection.append("div").classed("data", true);
            tooltipBitrateContainer.node().appendChild(this.getPidLegend(i));
            tooltipBitrateContainer.append("span").classed("value", true).classed(`value-index-${i}`, true);
        }
        return tooltipSelection;
    }

    private drawLegend(mainContainer: d3.Selection<HTMLDivElement, undefined, null, undefined>) {
        const legendContainer = mainContainer.append("div").classed("legend-container", true);
        for (let i = 0; i < this.pids?.length ?? 0; ++i) legendContainer.node().appendChild(this.getPidLegend(i));
    }

    private registerPointerEventHandlers(
        chart: d3.Selection<SVGSVGElement, undefined, null, undefined>,
        tooltipSelection: d3.Selection<HTMLDivElement, undefined, null, undefined>,
        closestRecordSelections: d3.Selection<SVGCircleElement, unknown, null, undefined>[],
        positionLineSelection: d3.Selection<SVGRectElement, unknown, null, undefined>
    ) {
        const hidePositionIndicators = () => {
            this.setVisibility(tooltipSelection, false);
            for (const closestRecordSelection of closestRecordSelections)
                this.setVisibility(closestRecordSelection, false);
            this.setVisibility(positionLineSelection, false);
            chart.style("cursor", "default");
        };
        const onPointerMove = event => {
            const [xCoordinate, yCoordinate] = d3.pointer(event);
            if (this.isCoordinateOutOfGraphArea(xCoordinate, yCoordinate)) {
                return hidePositionIndicators();
            }
            this.showPositionLine(xCoordinate, positionLineSelection);
            chart.style("cursor", "crosshair");
            if (this.isValidData()) {
                const closestRecords = this.getClosestRecords(xCoordinate);
                this.showTooltip(xCoordinate, yCoordinate, closestRecords, tooltipSelection);

                for (let i = 0; i < closestRecordSelections.length; ++i) {
                    this.isValidRecord(closestRecords[i])
                        ? this.showClosestRecordIndicator(closestRecords[i], closestRecordSelections[i])
                        : this.setVisibility(closestRecordSelections[i], false);
                }
            }
        };
        const throttleOnPointerMove = _.throttle(onPointerMove, 5);
        chart
            .style("cursor", "crosshair")
            .on("pointerenter", onPointerMove)
            .on("pointermove", throttleOnPointerMove)
            .on("pointerleave", hidePositionIndicators);
    }

    private registerResizeHandler(
        mainContainer: d3.Selection<HTMLDivElement, undefined, null, undefined>,
        xAxisSelection: d3.Selection<SVGGElement, unknown, null, undefined>,
        yAxisSelection: d3.Selection<SVGGElement, unknown, null, undefined>,
        graphLineSelections: d3.Selection<SVGPathElement, unknown, null, undefined>[],
        graphAreaSelections: d3.Selection<SVGPathElement, unknown, null, undefined>[]
    ) {
        const onResize = () => {
            const newWidth = this.chart.node().parentElement.clientWidth;
            this.setChartSize(this.chart, newWidth);
            xAxisSelection
                .call(this.getXAxisGeneratorFunction())
                .call(this.setGridLinesOpacity)
                .call(this.addStyleToAxesLabels);
            yAxisSelection
                .call(this.getYAxisGeneratorFunction())
                .call(this.setGridLinesOpacity)
                .call(this.addStyleToAxesLabels);
            for (let i = 0; i < this.pids?.length ?? 0; ++i) {
                graphLineSelections[i].attr("d", this.getGraphLine(this.pids[i]));
                graphAreaSelections[i].attr("d", this.getGraphArea(this.pids[i]));
            }
        };
        const debouncedResize = _.debounce(onResize, 25);
        this.resizeObserver = new ResizeObserver(debouncedResize);
        this.resizeObserver.observe(mainContainer.node());
    }

    private setXDomain() {
        this.xDomain = this.isValidData()
            ? d3.extent(
                  this.mapDates(this.pids.reduce((bitrates: Bitrate[], pid: Pid) => bitrates.concat(pid.bitrates), []))
              )
            : [moment().subtract(60, "minutes").toDate(), new Date()];
    }

    private setYDomain() {
        const customizeDomainToGenerateEvenlySpacedTicks = ([initialDomainMin, initialDomainMax]: number[]) => {
            this.yDomain = [initialDomainMin, initialDomainMax];
            const initialTicks = this.getYScaleFunction().ticks(this.getApproximateYTicksCount());
            const initialFirstTick = initialTicks[0];
            const initialLastTick = initialTicks[initialTicks.length - 1];
            const initialTickIncrement = d3.tickIncrement(initialDomainMin, initialDomainMax, initialTicks.length);
            const [customizedDomainMin, customizedDomainMax] = [
                initialFirstTick > initialDomainMin ? initialFirstTick - initialTickIncrement : initialDomainMin,
                initialLastTick < initialDomainMax ? initialLastTick + initialTickIncrement : initialDomainMax
            ];
            this.yDomain = [customizedDomainMin, customizedDomainMax];
            const customizedTicks = this.getYScaleFunction().ticks(this.getApproximateYTicksCount());
            const customizedFirstTick = customizedTicks[0];
            const customizedLastTick = customizedTicks[customizedTicks.length - 1];
            const isMissingTicks =
                customizedFirstTick > customizedDomainMin || customizedLastTick < customizedDomainMax;
            return isMissingTicks
                ? customizeDomainToGenerateEvenlySpacedTicks([customizedDomainMin, customizedDomainMax])
                : [customizedDomainMin, customizedDomainMax];
        };
        this.yDomain = this.isValidData()
            ? customizeDomainToGenerateEvenlySpacedTicks(
                  d3.extent(
                      this.mapBitrateKbs(
                          this.pids.reduce((bitrates: Bitrate[], pid: Pid) => bitrates.concat(pid.bitrates), [])
                      )
                  )
              )
            : [0, 1000];
    }

    private getYScaleFunction(): d3.ScaleLinear<number, number, never> {
        const yRange = [this.getChartHeight() - this.CONFIGS.MARGIN.BOTTOM, this.CONFIGS.MARGIN.BOTTOM];
        return d3.scaleLinear().range(yRange).domain(this.yDomain);
    }

    private getXScaleFunction(): d3.ScaleTime<number, number, never> {
        const xRange = [this.CONFIGS.MARGIN.LEFT, this.getChartWidth() - this.CONFIGS.MARGIN.RIGHT];
        return d3.scaleTime().range(xRange).domain(this.xDomain);
    }

    private getXAxisGeneratorFunction(): d3.Axis<Date | d3.NumberValue> {
        const graphHeight = this.getGraphHeight();
        return d3
            .axisBottom(this.getXScaleFunction())
            .tickSizeOuter(-graphHeight)
            .tickSizeInner(-graphHeight)
            .tickPadding(this.CONFIGS.TICKS.PADDING)
            .ticks(this.getApproximateXTicksCount())
            .tickFormat(d3.timeFormat("%H:%M"));
    }

    private getYAxisGeneratorFunction(): d3.Axis<d3.NumberValue> {
        const graphWidth = this.getChartWidth() - this.CONFIGS.MARGIN.LEFT - this.CONFIGS.MARGIN.RIGHT;
        return d3
            .axisLeft(this.getYScaleFunction())
            .tickSizeOuter(-graphWidth)
            .tickSizeInner(-graphWidth)
            .tickPadding(this.CONFIGS.TICKS.PADDING)
            .tickFormat(this.getBitrateFormatter())
            .ticks(this.getApproximateYTicksCount());
    }

    private setChartSize(chart: d3.Selection<SVGSVGElement, undefined, null, undefined>, width: number) {
        chart.attr("width", width).attr("height", 220);
    }

    private getChartWidth() {
        return this.chart.node().clientWidth;
    }

    private getChartHeight() {
        return this.chart.node().clientHeight;
    }

    private getGraphHeight() {
        return this.getChartHeight() - this.CONFIGS.MARGIN.TOP - this.CONFIGS.MARGIN.BOTTOM;
    }

    private isValidData(): boolean {
        return this.pids?.length > 0 && this.pids[0].bitrates && this.pids[0].bitrates.length >= 2;
    }

    private isValidRecord(bitrate: Bitrate): boolean {
        return Boolean(bitrate.date && !_.isNil(bitrate.kbs));
    }

    private setGridLinesOpacity(axisSelection: d3.Selection<SVGGElement, unknown, null, undefined>) {
        axisSelection.select(".domain").attr("stroke-opacity", 0.1);
        axisSelection.selectAll(".tick line").attr("stroke-opacity", 0.1);
    }

    private getGraphLine(pid: Pid): string {
        const yScaleFunction = this.getYScaleFunction();
        const xScaleFunction = this.getXScaleFunction();
        return this.isValidData()
            ? d3
                  .line<Bitrate>()
                  .defined(this.isValidRecord)
                  .x(bitrate => xScaleFunction(bitrate.date))
                  .y(bitrate => yScaleFunction(bitrate.kbs))(pid.bitrates)
            : "";
    }

    private getGraphArea(pid: Pid): string {
        const [yDomainMin] = this.yDomain;
        const yScaleFunction = this.getYScaleFunction();
        const xScaleFunction = this.getXScaleFunction();
        return this.isValidData()
            ? d3
                  .area<Bitrate>()
                  .defined(this.isValidRecord)
                  .x(bitrate => xScaleFunction(bitrate.date))
                  .y(bitrate => yScaleFunction(bitrate.kbs))
                  .y1(() => yScaleFunction(yDomainMin))(pid.bitrates)
            : "";
    }

    private isCoordinateOutOfGraphArea(x: number, y: number): boolean {
        const xRightBoundary = this.getChartWidth() - this.CONFIGS.MARGIN.RIGHT;
        const xLeftBoundary = this.CONFIGS.MARGIN.LEFT;
        const isXOutOfGraphArea = x > xRightBoundary || x < xLeftBoundary;

        const yTopBoundary = this.CONFIGS.MARGIN.TOP;
        const yBottomBoundary = this.getChartHeight() - this.CONFIGS.MARGIN.BOTTOM;
        const isYOutOfGraphArea = y > yBottomBoundary || y < yTopBoundary;
        return isXOutOfGraphArea || isYOutOfGraphArea;
    }

    private getClosestRecords(x: number): Bitrate[] {
        const xDate = this.getXScaleFunction().invert(x);
        return this.pids.map(pid => {
            const closestRecordIndex = d3.bisectCenter(this.mapDates(pid.bitrates), xDate);
            return pid.bitrates[closestRecordIndex];
        });
    }

    private showClosestRecordIndicator(
        bitrate: Bitrate,
        closestRecordSelection: d3.Selection<SVGCircleElement, unknown, null, undefined>
    ) {
        const xCoordinate = this.getXScaleFunction()(bitrate.date);
        const yCoordinate = this.getYScaleFunction()(bitrate.kbs);
        closestRecordSelection.attr("transform", `translate(${xCoordinate})`).attr("cy", yCoordinate);
        this.setVisibility(closestRecordSelection, true);
    }

    private showPositionLine(
        xCoordinate: number,
        positionLineSelection: d3.Selection<SVGRectElement, unknown, null, undefined>
    ) {
        positionLineSelection.attr("transform", `translate(${xCoordinate},${this.CONFIGS.MARGIN.BOTTOM})`);
        this.setVisibility(positionLineSelection, true);
    }

    private showTooltip(
        pointerXCoordinate: number,
        pointerYCoordinate: number,
        closestRecords: Bitrate[],
        tooltipSelection: d3.Selection<HTMLDivElement, undefined, null, undefined>
    ) {
        const tooltipDateSelection = tooltipSelection.select(".date");
        tooltipDateSelection.html(moment(closestRecords[0].date).format("YYYY-MM-DD HH:mm:ss"));

        for (let i = 0; i < this.pids?.length ?? 0; ++i) {
            const closestRecord = closestRecords[i];
            const tooltipBitrateSelection = tooltipSelection.select(`.data .value.value-index-${i}`);

            tooltipBitrateSelection.html(
                this.isValidRecord(closestRecord) ? this.getBitrateFormatter(true)(closestRecord.kbs) : ""
            );
        }
        const POINTER_OFFSET = 10;
        const width = this.getChartWidth();
        const height = this.getChartHeight();
        tooltipSelection
            .style("top", pointerYCoordinate < height / 2 ? `${pointerYCoordinate + POINTER_OFFSET}px` : "initial")
            .style(
                "right",
                pointerXCoordinate > width / 2 ? `${width - pointerXCoordinate + POINTER_OFFSET}px` : "initial"
            )
            .style(
                "bottom",
                pointerYCoordinate > height / 2 ? `${height - pointerYCoordinate + POINTER_OFFSET}px` : "initial"
            )
            .style("left", pointerXCoordinate < width / 2 ? `${pointerXCoordinate + POINTER_OFFSET}px` : "initial");

        this.setVisibility(tooltipSelection, true);
    }

    private setVisibility(d3Selection: d3.Selection<d3.BaseType, unknown, null, undefined>, isVisible: boolean) {
        d3Selection.style("visibility", isVisible ? "visible" : "hidden");
    }

    private getApproximateYTicksCount(): number {
        return Math.round(this.getChartHeight() / this.CONFIGS.TICKS.SPACING.Y);
    }

    private getApproximateXTicksCount(): number {
        const ticksCount = Math.round(this.getChartWidth() / this.CONFIGS.TICKS.SPACING.X);
        const maxTicksCount = 8;
        return ticksCount < maxTicksCount ? ticksCount : maxTicksCount;
    }

    private addStyleToAxesLabels(axisSelection: d3.Selection<SVGGElement, unknown, null, undefined>) {
        axisSelection.selectAll("text").attr("class", "axis-labels-text");
    }

    private getPidLegend(pidIndex: number): HTMLDivElement {
        const legend = d3.create("div").classed("legend-item", true);
        legend
            .append("div")
            .classed("icon", true)
            .style("background-color", this.CONFIGS.COLORS[pidIndex % this.CONFIGS.COLORS.length]);
        legend.append("span").html(this.pids[pidIndex].name);
        return legend.node();
    }

    private getBitrateFormatter(isForceKbs = false) {
        return (bitrateKbs: number) =>
            bitrateKbs === 0
                ? "0 b/s"
                : bitrateKbs < 1000 || isForceKbs
                ? `${bitrateKbs} kb/s`
                : `${(bitrateKbs / 1000).toFixed(1)} mb/s`;
    }

    private startDataTransition(
        xAxisSelection: d3.Selection<SVGGElement, unknown, null, undefined>,
        yAxisSelection: d3.Selection<SVGGElement, unknown, null, undefined>,
        graphLineSelections: d3.Selection<SVGPathElement, unknown, null, undefined>[],
        graphAreaSelections: d3.Selection<SVGPathElement, unknown, null, undefined>[]
    ) {
        const duration = 500;
        xAxisSelection
            .transition()
            .duration(duration)
            .call(this.getXAxisGeneratorFunction())
            .on("start", () => {
                this.addStyleToAxesLabels(xAxisSelection);
                this.setGridLinesOpacity(xAxisSelection);
            });
        yAxisSelection
            .transition()
            .duration(duration)
            .call(this.getYAxisGeneratorFunction())
            .on("start", () => {
                this.addStyleToAxesLabels(yAxisSelection);
                this.setGridLinesOpacity(yAxisSelection);
            });

        for (let i = 0; i < this.pids?.length ?? 0; ++i) {
            graphLineSelections[i].transition().duration(duration).attr("d", this.getGraphLine(this.pids[i]));
            graphAreaSelections[i].transition().duration(duration).attr("d", this.getGraphArea(this.pids[i]));
        }
    }

    private mapDates(bitrates: Bitrate[]): Date[] {
        return bitrates.map(bitrate => bitrate.date);
    }

    private mapBitrateKbs(bitrates: Bitrate[]): number[] {
        return bitrates.map(bitrate => bitrate.kbs);
    }
}
