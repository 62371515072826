<hr class="mt-0" *ngIf="isHorizontalRowNeeded" />
<div class="text-end">
    <button type="button" class="btn btn-outline-secondary float-start" *ngIf="!isEdit" (click)="back()" [disabled]="isSaving">
        <fa-icon icon="chevron-left" size="sm"></fa-icon>
        {{ "BACK" | translate }}
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="isSaving">
        <fa-icon icon="times"></fa-icon>
        {{ "CANCEL" | translate }}
    </button>
    <button
        zmid="form-submit"
        type="submit"
        (click)="onSubmit()"
        class="btn btn-primary ms-2"
        [disabled]="isSaving"
        [ngClass]="{ 'btn-danger': !isFormValid && submitted }"
    >
        <fa-icon icon="check" size="sm"></fa-icon>
        {{ "SAVE" | translate }}<span *ngIf="isSaving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
    </button>
</div>
