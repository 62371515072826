<button
    type="button"
    class="btn btn-sm btn-xs pin-button"
    container="body"
    [attr.title]="(isPinned ? 'UNPIN' : 'PIN') | translate"
    triggers="hover"
    placement="left"
    [disabled]="isLocked"
    (click)="isPinnedChange.emit(!isPinned)"
>
    <fa-layers [fixedWidth]="true" size="sm">
        <fa-icon icon="thumbtack"></fa-icon>
        <fa-icon *ngIf="isPinned" icon="slash"></fa-icon>
    </fa-layers>
</button>
