import { Component, Input } from "@angular/core";
import { MediaConnectSource } from "../../../../models/shared";

@Component({
    selector: "app-mc-source-details",
    templateUrl: "./mc-source-details.component.html"
})
export class MediaConnectSourceDetailsComponent {
    @Input() source: MediaConnectSource;
    @Input() canEdit: boolean;
}
