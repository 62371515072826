<label *ngIf="!!label" [ngClass]="{ 'is-invalid': invalid }">
    {{ label }}
    <fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon>
</label>
<div class="form-group">
    <ng-select
        placeholder="{{ 'SELECT' | translate }} {{ channelType.toUpperCase() | translate }} {{ 'CHANNEL' | translate }}"
        bindLabel="name"
        [items]="channels$ | async"
        [loading]="loading"
        [multiple]="true"
        [hideSelected]="true"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        (change)="_onChange($event)"
        (focus)="_onTouched()"
        [ngClass]="{ 'form-control is-invalid': invalid }"
        [formControl]="ngControl.control"
    >
    </ng-select>
    <app-input-errors-indicator [errors]="ngControl.errors" [fieldName]="'CHANNEL' | translate" [invalid]="invalid"></app-input-errors-indicator>
</div>
