import { Component, Input } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "../../../models/shared";

export type FunctionRef<T = Base, R = void> = (row: T) => R;

@Component({
    selector: "app-zx-events-table-row-actions",
    templateUrl: "./zx-events-table-row-actions.component.html"
})
export class ZxEventsTableRowActionsComponent {
    @Input() row: KeyMap<Base>;
    @Input() canCreateIncident: boolean;
    @Input() canManageEvent: boolean;
    @Input() createIncidentRef: FunctionRef;
    @Input() manageEventRef: FunctionRef;

    onCreateIncident(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.createIncidentRef(this.row);
    }

    onManageEvent(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.manageEventRef(this.row);
    }
}
