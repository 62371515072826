import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { BroadcastersService } from "./../../broadcasters/broadcasters.service";
import { Broadcaster } from "./../../../models/shared";

@Component({
    selector: "zx-broadcaster-select",
    templateUrl: "./zx-broadcaster-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxBroadcasterSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    broadcasters: Broadcaster[];
    private broadcastersSubscription: Subscription;

    constructor(private bs: BroadcastersService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.bs.refreshAllBroadcasters();
        this.broadcastersSubscription = this.bs.broadcasters.subscribe(broadcasters => {
            this.broadcasters = [...broadcasters];
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.broadcastersSubscription.unsubscribe();
    }
}
