import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { Report, Run } from "./report";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";
import { APIResponse, Tag } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class ReportsService {
    reports: Observable<Report[]>;
    runs: Observable<Run[]>;
    private reports$: ReplaySubject<Report[]>;
    private runs$: ReplaySubject<Run[]>;
    private dataStore: {
        reports: Report[];
        runs: Run[];
    };

    private lastReportsRefresh: number;
    private lastReportRefresh: number;

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            reports: [],
            runs: []
        };

        this.lastReportsRefresh = null;
        this.lastReportRefresh = null;

        this.reports$ = new ReplaySubject(1) as ReplaySubject<Report[]>;
        this.reports = this.reports$.asObservable();
        this.runs$ = new ReplaySubject(1) as ReplaySubject<Run[]>;
        this.runs = this.runs$.asObservable();
    }

    private prepReport(report: Report) {
        report._frontData = {
            sortableStatus: ""
        };

        if (report.resourceTags)
            report.resourceTags.sort((a: Tag, b: Tag) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );
    }

    private prepRun(run: Run) {
        run._frontData = {
            sortableStatus: ""
        };
    }

    private updateReportStore(newReport: Report, merge: boolean): void {
        this.prepReport(newReport);
        const currentReportIndex = this.dataStore.reports.findIndex(report => report.id === newReport.id);
        if (currentReportIndex === -1) {
            this.dataStore.reports.push(newReport);
            return;
        } else if (merge) {
            const currentReport = this.dataStore.reports[currentReportIndex];

            Object.assign(currentReport, newReport);
        } else {
            this.dataStore.reports[currentReportIndex] = newReport;
        }
    }

    refreshReports(force?: boolean): Observable<Report[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastReportsRefresh <= 60000) return this.reports;
        this.lastReportsRefresh = _.now();

        const reports$ = this.http
            .get<APIResponse<Report[]>>(Constants.apiUrl + Constants.apiUrls.reports)
            .pipe(share());

        reports$.subscribe(
            data => {
                const reports: Report[] = data.result;

                this.dataStore.reports.forEach((existingReport, existingIndex) => {
                    const newIndex = reports.findIndex(report => report.id === existingReport.id);
                    if (newIndex === -1) this.dataStore.reports.splice(existingIndex, 1);
                });

                reports.forEach(refreshedReport => this.updateReportStore(refreshedReport, true));

                this.reports$.next(Object.assign({}, this.dataStore).reports);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_REPORTS"), error)
        );
        return reports$.pipe(map(r => r.result));
    }

    refreshReport(id: number, force?: boolean): Observable<Report> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastReportRefresh <= 60000) {
            return new Observable((observe: Subscriber<Report>) => {
                observe.next(this.dataStore.reports.find(r => r.id === id));
                observe.complete();
            });
        }
        this.lastReportRefresh = _.now();

        const report$ = this.http
            .get<APIResponse<Report>>(Constants.apiUrl + Constants.apiUrls.reports + "/" + id)
            .pipe(share());

        report$.subscribe(
            data => {
                const report: Report = data.result;
                report.hasFullDetails = true;

                this.updateReportStore(report, false);

                this.reports$.next(Object.assign({}, this.dataStore).reports);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_REPORT"), error)
        );
        return report$.pipe(map(r => r.result));
    }

    getCachedReport(id: number) {
        if (this.dataStore.reports && id) return this.dataStore.reports.find(report => report.id === id);
        return undefined;
    }

    async addReport(model: Record<string, unknown>): Promise<Report | false> {
        try {
            const result = await this.http
                .post<APIResponse<Report>>(Constants.apiUrl + Constants.apiUrls.reports, model)
                .toPromise();
            const report: Report = result.result;

            this.updateReportStore(report, true);

            this.reports$.next(Object.assign({}, this.dataStore).reports);
            return report;
        } catch (error) {
            return false;
        }
    }

    async updateReport(report: Report, model: Record<string, unknown>): Promise<Report | false> {
        try {
            const result = await this.http
                .put<APIResponse<Report>>(Constants.apiUrl + Constants.apiUrls.reports + "/" + `${report.id}`, model)
                .toPromise();
            const updatedReport: Report = result.result;

            this.updateReportStore(updatedReport, true);

            this.reports$.next(Object.assign({}, this.dataStore).reports);
            return updatedReport;
        } catch (error) {
            return false;
        }
    }

    async deleteReport(report: Report): Promise<boolean> {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(Constants.apiUrl + Constants.apiUrls.reports + "/" + `${report.id}`)
                .toPromise();

            const deletedId: number = result.result;
            const reportIndex = this.dataStore.reports.findIndex(r => r.id === deletedId);
            if (reportIndex !== -1) this.dataStore.reports.splice(reportIndex, 1);

            this.reports$.next(Object.assign({}, this.dataStore).reports);
            return true;
        } catch (error) {
            return false;
        }
    }

    // Runs
    getReportHistory(report: Report): Observable<Run[]> {
        const runs$ = this.http
            .get<APIResponse<Run[]>>(Constants.apiUrl + Constants.apiUrls.reports + "/" + `${report.id}` + "/impls")
            .pipe(share());

        runs$.subscribe(
            data => {
                const runs: Run[] = data.result;

                this.dataStore.runs = runs;

                this.runs$.next(Object.assign({}, this.dataStore).runs);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_RUN_HISTORY"), error)
        );
        return runs$.pipe(map(r => r.result));
    }

    async deleteRun(report: Report, run: Run): Promise<boolean> {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(
                    Constants.apiUrl + Constants.apiUrls.reports + "/" + `${report.id}` + "/impls/" + `${run.id}`
                )
                .toPromise();

            const deletedId: number = result.result;
            const runIndex = this.dataStore.runs.findIndex(r => r.id === deletedId);
            if (runIndex !== -1) this.dataStore.runs.splice(runIndex, 1);

            this.runs$.next(Object.assign({}, this.dataStore).runs);
            return true;
        } catch (error) {
            return false;
        }
    }

    async generateReport(report: Report, model: Record<string, unknown>): Promise<Run | false> {
        try {
            const result = await this.http
                .post<APIResponse<Run>>(
                    Constants.apiUrl + Constants.apiUrls.reports + "/" + `${report.id}` + "/impls",
                    model
                )
                .toPromise();
            const run: Run = result.result;

            this.dataStore.runs = [...this.dataStore.runs, run];

            this.runs$.next(Object.assign({}, this.dataStore).runs);
            return run;
        } catch (error) {
            return false;
        }
    }
}
