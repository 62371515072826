<span *ngIf="ngIf">
    <zx-feeder
        *ngIf="source.feeder_id"
        [model]="source.feeder"
        [showTag]="true"
        [showLink]="true"
        [showStatusIcon]="false"
        [searchTerm]="searchTerm"
    ></zx-feeder>

    <zx-broadcaster
        *ngIf="source.broadcaster_id"
        [model]="source.broadcaster"
        [showTag]="true"
        [showLink]="true"
        [showCluster]="false"
        [showStatusIcon]="false"
        [searchTerm]="searchTerm"
    ></zx-broadcaster>

    <span *ngIf="source.input_id && source.type !== 'zixi_pull' && source.type !== 'rtmp'"
        ><span *ngIf="source.broadcaster_id || source.feeder_id">&nbsp;/&nbsp;</span
        ><ngb-highlight title="{{ source.input_id }}" [result]="source.input_id" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="source.Source">&nbsp;/&nbsp;</span></span
    >
    <span *ngFor="let failoverSource of source.failoverSources; let isLast = last">{{ failoverSource.source.name }}{{ isLast ? "" : ",&nbsp;" }}</span>
    <span *ngIf="source.transcode_profile_id && source.transcodeSource && source.transcodeSource.name">
        <ngb-highlight title="{{ source.transcodeSource.name }}" [result]="source.transcodeSource.name" [term]="searchTerm"></ngb-highlight>
    </span>

    <zx-source *ngIf="source.Source" [model]="source.Source" [showLink]="true" [showTag]="false" [searchTerm]="searchTerm"></zx-source>
    <span *ngIf="source._frontData.input_description">
        <ngb-highlight title="{{ source._frontData.input_description }}" [result]="source._frontData.input_description" [term]="searchTerm"></ngb-highlight>
    </span>
    <span
        *ngIf="
            !source.feeder_id &&
            !source.broadcaster_id &&
            (!source.input_id || source.input_id.length === 0) &&
            !source.transcode_profile_id &&
            !source.Source &&
            (!source._frontData.input_description || source._frontData.input_description.length === 0) &&
            (!source.failoverSources || source.failoverSources.length === 0) &&
            !['multiplex', 'multiview'].includes(source.type)
        "
        >-</span
    >
    <app-summary-sources-col *ngIf="source.type === 'multiplex' && source.multiplexSources" [sources]="source.multiplexSources"></app-summary-sources-col>
    <app-summary-sources-col *ngIf="source.type === 'multiview' && source.multiviewSources" [sources]="source.multiviewSources"></app-summary-sources-col>
</span>
