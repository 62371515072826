import { Component, OnInit, Input } from "@angular/core";
import { SomeZixiObject } from "src/app/models/shared";

import { StatusClassPipe } from "./../../../pipes/status-class.pipe";
import { StatusTextPipe } from "./../../../pipes/status-text.pipe";
import { UrlBuilderService } from "../../../services/url-builder.service";
import { ZmEvent } from "../../../pages/events/event";

@Component({
    selector: "zx-status-full",
    providers: [StatusClassPipe, StatusTextPipe],
    templateUrl: "./zx-status-full.component.html"
})
export class ZxStatusFullComponent implements OnInit {
    @Input() model: Partial<SomeZixiObject>;
    @Input() size?: string;
    @Input() status?: string;
    @Input() text?: string;
    @Input() icon?: boolean;
    @Input() standby?: boolean;
    @Input() showStatusText?: boolean;
    @Input() showOtherIcons?: boolean;
    @Input() searchTerm?: string | string[];
    @Input() channelDisabled?: boolean;
    @Input() lockIconText?: string;
    @Input() navigateOnClick?: boolean;

    constructor(private urlBuildService: UrlBuilderService) {}

    ngOnInit() {
        if (!this.model) return;
        // Chech for size
        if (!this.size) {
            this.size = "md";
        }
        if (this.standby === undefined) this.standby = this.model.acknowledged;
        if (this.icon === undefined) this.icon = true;
        if (this.showStatusText === undefined) this.showStatusText = true;
        if (this.showOtherIcons === undefined) this.showOtherIcons = true;
        if (this.navigateOnClick === undefined) this.navigateOnClick = false;
    }

    navigateTo() {
        if (this.navigateOnClick) {
            this.urlBuildService.navigateToObject(this.model as unknown as ZmEvent);
        }
    }
}
