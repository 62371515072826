import {
    Component,
    OnInit,
    OnDestroy,
    OnChanges,
    Input,
    ViewChild,
    ElementRef,
    SimpleChanges,
    HostListener
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
//
import { Constants } from "../../../../constants/constants";
import { MediaConnectSourcesService } from "../../mediaconnect-sources.service";
import { MediaConnectSource } from "../../../../models/shared";
import { interval, Subscription } from "rxjs";
//
import * as _ from "lodash";

@Component({
    selector: "app-mc-source-thumbnail",
    templateUrl: "./mc-source-thumbnail.component.html"
})
export class MediaConnectSourceThumbnailComponent implements OnInit, OnDestroy, OnChanges {
    @Input() source: MediaConnectSource;
    @Input() bordered?: boolean;
    @Input() info?: boolean;
    @Input() overlay?: boolean;
    @Input() grid?: boolean;
    @Input() searchTerm?: string | string[];
    @Input() refreshInterval?: number;

    loading = true;
    initialized = false;
    superWide: boolean;
    isHorizontal = false;
    imgSource: string | SafeResourceUrl = "assets/images/thumb-stream.png";
    urls = Constants.urls;

    @ViewChild("thumbnailContainer", { static: true }) thumbnailContainer: ElementRef;
    @ViewChild("thumbnail") thumbnail: ElementRef;

    timer$: Subscription;

    constructor(
        private ss: MediaConnectSourcesService,
        private sanitizer: DomSanitizer,
        private elementRef: ElementRef
    ) {
        this.updateRefreshInterval = _.debounce(this.updateRefreshInterval, 500);
    }

    @HostListener("window:resize", [])
    private onResize() {
        this.rotateAudio();
    }

    ngOnInit() {
        this.setThumbnail();

        // Start Auto Refresh
        this.startPreviewRefresh();
    }

    ngOnDestroy() {
        this.stopPreviewRefresh();
    }

    startPreviewRefresh() {
        let int = 5000;
        if (this.refreshInterval) int = this.refreshInterval;
        // 3 seconds for now
        this.timer$ = interval(int).subscribe(async () => {
            await this.setThumbnail();
        });
    }

    stopPreviewRefresh() {
        this.timer$.unsubscribe();
    }

    rotateAudio() {
        setTimeout(() => {
            this.isHorizontal = this.thumbnailContainer.nativeElement.offsetHeight < 100;
        }, 0);
    }

    async setThumbnail() {
        await this.loadPreview();
    }

    async loadPreview() {
        try {
            const result = await this.ss.getSourceThumbnail(this.source.id);
            this.rotateAudio();

            if (result.body.byteLength < 100) throw new Error("Invalid thumbnail response");

            const blob = new Blob([result.body], { type: "Content-Type" });
            this.imgSource = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            this.initialized = true;

            this.loading = false;
        } catch (e) {
            this.imgSource = "assets/images/no_preview.png";
            this.initialized = true;
            this.loading = false;
            return;
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        // Check for source changed
        let sourceChanged = false;
        if (
            changes.source &&
            changes.source.previousValue &&
            changes.source.currentValue &&
            changes.source.previousValue.id !== changes.source.currentValue.id
        ) {
            sourceChanged = true;
        }

        if (sourceChanged) {
            this.stopPreviewRefresh();

            this.loading = true;
            this.initialized = false;

            this.setThumbnail();
            this.startPreviewRefresh();
        }

        // Check for refresh interval change
        let intervalChanged = false;
        if (
            changes.refreshInterval &&
            changes.refreshInterval.previousValue &&
            changes.refreshInterval.currentValue &&
            changes.refreshInterval.previousValue !== changes.refreshInterval.currentValue
        ) {
            intervalChanged = true;
        }

        if (intervalChanged) {
            this.updateRefreshInterval();
        }
    }

    updateRefreshInterval() {
        this.stopPreviewRefresh();
        this.startPreviewRefresh();
    }

    onImgLoad() {
        const height = this.thumbnail.nativeElement.height;
        const width = this.thumbnail.nativeElement.width;
        this.superWide = width / height > 16 / 9;
    }
}
