import { Pipe, PipeTransform } from "@angular/core";
import { LocationInfo } from "../pages/maps/map";
import { SharedService } from "../services/shared.service";

@Pipe({
    name: "network"
})
export class NetworkPipe implements PipeTransform {
    constructor(private sharedService: SharedService) {}

    transform(location: { ip?: LocationInfo; address?: LocationInfo }): string {
        if (!location || location === undefined || this.sharedService.isEmptyObject(location) || !location.ip)
            return "-";
        else {
            if (location.ip.isp || location.ip.region_name || location.ip.country_name) {
                return [location.ip.isp, location.ip.region_name, location.ip.country_name].filter(e => !!e).join(", ");
            } else if (location.ip.display_name) {
                return location.ip.display_name;
            } else return "-";
        }
    }
}
