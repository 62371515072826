import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, ReplaySubject, BehaviorSubject, Subscriber, firstValueFrom } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { User, Menu, NavigationPermissions, UserPermissions } from "../../../models/shared";
import * as _ from "lodash";
import { share, map, tap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import * as Sentry from "@sentry/angular";

@Injectable({
    providedIn: "root"
})
export class UsersService {
    // User
    user: Observable<User>;
    users: Observable<User[]>;
    user$: ReplaySubject<User>;
    private _errorInRequest$ = new ReplaySubject<string>();
    private users$: ReplaySubject<User[]>;
    private dataStore: {
        user: User;
        users: User[];
    };

    private lastUsersRefresh: number;

    // isAdmin
    private isAdmin$ = new BehaviorSubject<boolean>(false);
    private isZixiAdmin$ = new BehaviorSubject<boolean>(false);
    private isZixiSupport$ = new BehaviorSubject<boolean>(false);
    private isZixiSupportWrite$ = new BehaviorSubject<boolean>(false);
    private userPermissions$ = new BehaviorSubject<UserPermissions>({
        is_admin: false,
        is_enabled: false,
        is_zixi_admin: false,
        is_zixi_support: false,
        is_zixi_support_write: false,
        is_incident_manager: false
    });

    private automation$ = new BehaviorSubject<boolean>(false);
    private contentAnalysis$ = new BehaviorSubject<boolean>(false);
    private tracerouteHistory$ = new BehaviorSubject<boolean>(false);
    private userID$ = new BehaviorSubject<number>(0);
    private tunnelersHost$ = new BehaviorSubject<string>("");
    private menuPermissions$ = new BehaviorSubject<Menu>({
        reports: false,
        feeders: false,
        zecs: false,
        clusters: false,
        receivers: false,
        remote_access: false,
        sources: false,
        adaptive_channels: false,
        delivery_channels: false,
        targets: false,
        transcoding_profiles: false,
        user_management: false,
        settings: false,
        domains: false,
        live_events: false,
        multi_viewer: false
    });
    private navigationPermissions$ = new BehaviorSubject<NavigationPermissions>({
        resources: false,
        reports: false,
        sources: false,
        adaptives: false,
        deliveries: false
    });

    get userPermissions() {
        return this.userPermissions$.asObservable();
    }
    setUserPermissions(perm: UserPermissions) {
        this.userPermissions$.next(perm);
    }

    get isAdmin() {
        return this.isAdmin$.asObservable();
    }
    setAdmin(val: boolean) {
        this.isAdmin$.next(val);
    }

    get isZixiAdmin() {
        return this.isZixiAdmin$.asObservable();
    }
    setZixiAdmin(val: boolean) {
        this.isZixiAdmin$.next(val);
    }

    get isZixiSupport() {
        return this.isZixiSupport$.asObservable();
    }
    setZixiSupport(val: boolean) {
        this.isZixiSupport$.next(val);
    }

    get isZixiSupportWrite() {
        return this.isZixiSupportWrite$.asObservable();
    }
    setZixiSupportWrite(val: boolean) {
        this.isZixiSupportWrite$.next(val);
    }

    get isAutomation() {
        return this.automation$.asObservable();
    }
    setAutomation(val: boolean) {
        this.automation$.next(val);
    }

    get isContentAnalysis() {
        return this.contentAnalysis$.asObservable();
    }
    setContentAnalysis(val: boolean) {
        this.contentAnalysis$.next(val);
    }

    get isTracerouteHistory() {
        return this.tracerouteHistory$.asObservable();
    }
    setTracerouteHistory(val: boolean) {
        this.tracerouteHistory$.next(val);
    }

    get getUserID() {
        return this.userID$.asObservable();
    }
    setUserID(val: number) {
        this.userID$.next(val);
    }

    get getTunnelersHost() {
        return this.tunnelersHost$.asObservable();
    }
    private setTunnelersHost(val: string) {
        this.tunnelersHost$.next(val);
    }

    get getMenuPermissions() {
        return this.menuPermissions$.asObservable();
    }
    private setMenuPermissions(menu: Menu) {
        this.menuPermissions$.next(menu);
    }

    get getNavigationPermissions() {
        return this.navigationPermissions$.asObservable();
    }
    private setNavigationPermissions(permissions: NavigationPermissions) {
        this.navigationPermissions$.next(permissions);
    }

    get getErrorInRequest() {
        return this._errorInRequest$.asObservable();
    }

    constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        Sentry.setUser(null);
        this.dataStore = {
            user: null,
            users: []
        };

        this.lastUsersRefresh = null;

        this.user$ = new ReplaySubject<User>(1);
        this.users$ = new ReplaySubject<User[]>(1);
        this.user = this.user$.asObservable();
        this.users = this.users$.asObservable();
    }

    private prepUser(user: User, fullData: boolean) {
        if (!user._frontData) user._frontData = {};

        if (fullData) user._frontData.lastFullUpdate = _.now();

        if (!user.name) user.name = user.email;
    }

    private updateStore(user: User, fullData: boolean): void {
        const currentIndex = this.dataStore.users.findIndex(u => u.id === user.id);
        if (currentIndex === -1) {
            this.prepUser(user, fullData);
            this.dataStore.users.push(user);
        } else if (!fullData) {
            const current = this.dataStore.users[currentIndex];

            Object.assign(current, user);

            this.prepUser(current, fullData);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.prepUser(user, fullData);
            this.dataStore.users[currentIndex] = user;
        }
    }

    refreshUsers(force?: boolean): Observable<User[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastUsersRefresh <= 60000) return this.users;
        this.lastUsersRefresh = _.now();

        const users$ = this.http
            .get<{ success: boolean; result: User[] }>(Constants.apiUrl + Constants.apiUrls.user)
            .pipe(share());

        users$.subscribe(
            data => {
                const users = data.result;

                this.dataStore.users.forEach((existing, existingIndex) => {
                    const newIndex = users.findIndex(u => u.id === existing.id);
                    if (newIndex === -1) this.dataStore.users.splice(existingIndex, 1);
                });

                users.forEach(refreshedUser => this.updateStore(refreshedUser, false));

                this.users$.next(Object.assign({}, this.dataStore).users);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_USERS"), error)
        );

        return users$.pipe(map(r => r.result));
    }

    refreshUser(id: number | User, force?: boolean): Observable<User> {
        const currentUser = this.dataStore.users.find(u => u.id === (typeof id === "number" ? id : id.id));
        if (!force && currentUser && _.now() - currentUser._frontData.lastFullUpdate <= 60000) {
            return new Observable((observe: Subscriber<User>) => {
                observe.next(currentUser);
                observe.complete();
            });
        }

        const user$ = this.http
            .get<{ success: boolean; result: User }>(
                Constants.apiUrl + Constants.apiUrls.user + "/" + (typeof id === "number" ? id : id.id)
            )
            .pipe(share());

        user$.subscribe(
            data => {
                const user = data.result;

                this.updateStore(user, true);

                this.users$.next(Object.assign({}, this.dataStore).users);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_USER"), error)
        );

        return user$.pipe(map(r => r.result));
    }

    // Users
    getUsers() {
        return this.http.get<User[]>(Constants.apiUrl + Constants.apiUrls.user);
    }

    // Self
    getCurrentUser(): Observable<User> {
        const result = this.http
            .get<{ result: User; success: boolean }>(Constants.apiUrl + Constants.apiUrls.self)
            .pipe(
                tap(data => {
                    Sentry.setUser({ email: data.result.email, id: data.result.id + "" });
                })
            );

        result.subscribe(
            response => {
                const user = response.result;

                this.setAdmin(!!user.is_admin);
                this.setZixiAdmin(!!user.is_zixi_admin);
                this.setZixiSupport(!!user.is_zixi_support);
                this.setZixiSupportWrite(!!user.is_zixi_support_write);
                //
                this.setUserPermissions({
                    is_admin: !!user.is_admin,
                    is_enabled: !!user.is_enabled,
                    is_zixi_admin: !!user.is_zixi_admin,
                    is_zixi_support: !!user.is_zixi_support,
                    is_zixi_support_write: !!user.is_zixi_support_write,
                    is_incident_manager: !!user.is_incident_manager
                });
                //
                this.setAutomation(!!user.automation);
                this.setContentAnalysis(!!user.content_analysis);
                this.setTracerouteHistory(!!user.traceroute_history);
                this.setUserID(user.id);
                this.setTunnelersHost(user.tunnelers_host);

                if (user.menu) this.setMenuPermissions(user.menu);
                if (user.permissions) this.setNavigationPermissions(user.permissions);

                this.dataStore.user = user;
                this.user$.next(Object.assign({}, this.dataStore).user);
            },
            (error: HttpErrorResponse) => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_USER"));
                //In case of server error, create another request and inform the user
                if (error.status === 504) {
                    this._errorInRequest$.next(this.translate.instant("UI_ERRORS.COULD_NOT_LOAD_USER"));
                    setTimeout(() => this.getCurrentUser(), 10000);
                }
            }
        );
        return this.user;
    }

    async deleteUser(user: User) {
        try {
            await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl + Constants.apiUrls.user + "/" + `${user.id}`
                )
                .toPromise();

            const userIndex = this.dataStore.users.findIndex(f => f.id === user.id);
            if (userIndex !== -1) this.dataStore.users.splice(userIndex, 1);

            this.users$.next(Object.assign({}, this.dataStore).users);
            return true;
        } catch (error) {
            return false;
        }
    }

    async addUser(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<{ result: User; success: boolean }>(Constants.apiUrl + Constants.apiUrls.user, model)
                .toPromise();

            const user = result.result;
            this.updateStore(user, false);

            this.users$.next(Object.assign({}, this.dataStore).users);
            return user;
        } catch (error) {
            return false;
        }
    }

    // Update User
    async updateUser(id: number, model: Record<string, unknown>): Promise<boolean> {
        const result = await this.http
            .put<{ success: boolean }>(Constants.apiUrl + Constants.apiUrls.user + "/" + `${id}`, model)
            .toPromise();
        return result.success;
    }

    setAccountManagementNotifications(hasNotifications: boolean) {
        return this.http.put<{ success: boolean; result: User }>(
            Constants.apiUrl + Constants.apiUrls.self + "/set_account_management_notifications",
            {
                has_account_management_notifications: hasNotifications
            }
        );
    }

    async getLayout(scope: string): Promise<any> {
        const result = await firstValueFrom(
            this.http.get<{ success: boolean; result: Record<string, unknown> }>(
                Constants.apiUrl + Constants.apiUrls.self + Constants.urls.settings + "/" + `${scope}`
            )
        );
        return result.result;
    }

    async updateLayout(scope: string, layout: Record<string, unknown>): Promise<any> {
        const result = await firstValueFrom(
            this.http.put<{ success: boolean; result: Record<string, unknown> }>(
                Constants.apiUrl + Constants.apiUrls.self + Constants.urls.settings + "/" + `${scope}`,
                layout
            )
        );
        return result.result;
    }
}
