import { Component, Input, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";

@Component({
    selector: "app-zx-date-time-display",
    template: `
        <span *ngIf="showDate">
            <ngb-highlight title="{{ this.date }}" [result]="this.date" [term]="searchTerm"></ngb-highlight>
        </span>
        <span *ngIf="!showDate">-</span>
    `
})
export class ZxDateTimeDisplayComponent implements OnInit {
    @Input() dateTimeString: string;
    @Input() searchTerm: string;
    @Input() format? = "medium";

    date: string;
    showDate = false;
    constructor(private datePipe: DatePipe) {}

    ngOnInit(): void {
        if (
            this.dateTimeString !== null &&
            this.dateTimeString !== "0000-00-00 00:00:00" &&
            this.dateTimeString?.length !== 0
        ) {
            this.showDate = true;

            const dateISOString = new Date(this.dateTimeString).toISOString();
            this.date = this.datePipe.transform(dateISOString, this.format);
        }
    }
}
