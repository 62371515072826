import { Pipe, PipeTransform } from "@angular/core";
import { LocationInfo } from "../pages/maps/map";

@Pipe({
    name: "locationDisplayName"
})
export class LocationDisplayNamePipe implements PipeTransform {
    transform(location: { ip?: LocationInfo; address?: LocationInfo }): string {
        if (!location?.ip && !location?.address) return "-";

        return (location.address || location.ip).display_name;
    }
}
