import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { BroadcastersService } from "./../../broadcasters/broadcasters.service";
import { Broadcaster } from "./../../../models/shared";

@Component({
    selector: "zx-broadcasters-select",
    template: `
        <ng-select
            class="form-control minw-300px"
            [ngClass]="{ 'form-control-sm': size === 'small' }"
            name="{{ name }}"
            [disabled]="disabled"
            [items]="broadcasters"
            [clearable]="false"
            [loading]="loading"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            bindLabel="name"
            bindValue="id"
            placeholder="{{ 'SELECT' | translate }} {{ 'BROADCASTERS' | translate }}"
            (change)="modelChanged()"
            [(ngModel)]="model"
            required="{{ required || false }}"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
                <span *ngIf="item.name" class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>

                <span *ngIf="item.name" class="ng-value-label"
                    ><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }} @
                    {{ item.broadcaster_cluster.name }}</span
                >
            </ng-template>
            <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }} @
                {{ item.broadcaster_cluster.name }}
            </ng-template>
        </ng-select>
    `,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxBroadcastersSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number[];
    @Input() size?: string;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    broadcasters: Broadcaster[];
    private broadcastersSubscription: Subscription;

    constructor(private bs: BroadcastersService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.bs.refreshAllBroadcasters();
        this.broadcastersSubscription = this.bs.broadcasters.subscribe(broadcasters => {
            this.broadcasters = [...broadcasters];

            const modelIds = [];
            if (this.model && this.model.length)
                for (const id of this.model) {
                    if (this.broadcasters.some(broadcaster => broadcaster.id === id)) {
                        modelIds.push(id);
                    }
                }

            this.model = modelIds;
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.broadcastersSubscription.unsubscribe();
    }
}
