<div class="form-group mb-1">
    <div class="table-responsive overflow-visible">
        <table class="table table-sm bordered mb-2">
            <thead>
                <tr>
                    <th scope="col" class="w-50px">{{ "AUDIO_PID" | translate }}</th>
                    <th scope="col" class="text-start">{{ "CHANNELS" | translate }}</th>
                    <th scope="col" class="w-40px text-end">
                        <fa-icon icon="info-circle" [ngbTooltip]="TPIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                        <ng-template #TPIContent>
                            <p class="mb-0">{{ "TOOLTIP.NDI_AUDIO_SOURCE_INSTRUCTIONS" | translate }}</p>
                        </ng-template>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let audioSourceConfig of audioSourcesConfigurationControl.controls; let i = index">
                    <td title="{{ 1002 + i }}" class="w-50px">
                        <span class="d-block mt-1">{{ 1002 + i }}</span>
                    </td>
                    <td>
                        <div class="d-flex">
                            <ng-select
                                class="form-control form-control-sm form-control-xs flex-grow-1"
                                [items]="channels"
                                [formControl]="audioSourceConfig"
                                [multiple]="true"
                                [clearable]="true"
                                [hideSelected]="true"
                                [closeOnSelect]="false"
                            >
                            </ng-select>
                        </div>
                    </td>
                    <td class="text-end w-50px">
                        <button
                            type="button"
                            class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary mt-1"
                            (click)="audioSourcesConfigurationControl.removeAt(i)"
                        >
                            <fa-icon icon="minus" size="sm"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="audioSourcesConfigurationControl.controls.length <= 8">
                <tr>
                    <td></td>
                    <td colspan="2" class="text-end">
                        <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addToSourceConfig()">
                            {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
