import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[scrollSpy]"
})
export class ScrollSpyDirective {
    @Input() public spiedTags = [];
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;

    constructor(private el: ElementRef) {}

    @HostListener("scroll", ["$event"])
    public onScroll(event: Event) {
        let currentSection: string;
        let children = this.el.nativeElement.children;

        if (this.el.nativeElement.querySelector("#scrollParent")) {
            children = this.el.nativeElement.querySelector("#scrollParent").children;
        }

        const scrollTop = (event.target as HTMLElement).scrollTop;
        const parentOffset = (event.target as HTMLElement).offsetTop;
        for (const element of children) {
            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                if (element.offsetTop - parentOffset <= scrollTop) {
                    currentSection = element.id;
                }
            }
        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }
}
