import { Component, Input } from "@angular/core";

@Component({
    selector: "zx-icon",
    template: `<fa-icon [icon]="icon" *ngIf="showIcon" [size]="iconSize"></fa-icon>`
})
export class ZxIconComponent {
    @Input() icon: string;
    @Input() showIcon = true;
    @Input() iconSize = "md";
}
