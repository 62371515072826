import { Component, Input, OnChanges, SimpleChanges, inject } from "@angular/core";
import { NgControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ChannelTargetBroadcasterPreference } from "src/app/models/shared";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";
import { firstValueFrom } from "rxjs";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-broadcaster-field-selection",
    templateUrl: "./broadcaster-field-selection.component.html"
})
export class BroadcasterFieldSelectionComponent
    extends CustomControlValueAccessor<string | number>
    implements OnChanges
{
    @Input() clusterId: number;
    @Input() isFormSubmitted: boolean;
    @Input() isRestartRequiredTip: boolean;
    private translate = inject(TranslateService);
    private broadcastersService = inject(BroadcastersService);
    private targetBXsBase = [
        { name: this.translate.instant("ANY"), id: ChannelTargetBroadcasterPreference.AnyBroadcaster },
        {
            name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"),
            id: ChannelTargetBroadcasterPreference.PrimaryOnly
        },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: ChannelTargetBroadcasterPreference.BackupOnly }
    ];
    isParentFormSubmitted = false;
    targetBXs = [...this.targetBXsBase];
    targetBXsLoading = true;
    disabled = this.ngControl.disabled;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.clusterId) {
            this.ngControl.control.disable({ emitEvent: false });
            await this.getTargetBroadcasters();
        }
    }

    async getTargetBroadcasters() {
        this.targetBXsLoading = true;
        this.ngControl.control.disable({ emitEvent: false });
        this.targetBXs = [...this.targetBXsBase];
        if (this.clusterId) {
            const broadcasters = await firstValueFrom(
                this.broadcastersService.refreshBroadcasters(this.clusterId, true)
            );
            if (broadcasters.length)
                this.targetBXs = this.targetBXsBase.concat(
                    broadcasters.map(broadcaster => ({
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    }))
                );
            if (!this.targetBXs.find(broadcaster => broadcaster.id === this.ngControl.control.value))
                this.ngControl.control.reset();
            this.ngControl.control.enable({ emitEvent: false });
        }
        this.targetBXsLoading = false;
    }

    // Registers a function to update the form control
    registerOnChange(_) {
        this._onChange = item => (item as any).id;
    }
}
