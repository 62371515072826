import {
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    SimpleChange,
    Optional,
    ViewEncapsulation
} from "@angular/core";
import { ControlContainer, FormsModule, NgForm } from "@angular/forms";
import { TitleCasePipe } from "@angular/common";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { DeliveryChannel } from "../../../pages/channels/channel";
import { SourcesService } from "../../../pages/sources/sources.service";
import { Source, ChannelTargetBroadcasterPreference } from "../../../models/shared";
import { TranslateService } from "@ngx-translate/core";

export enum SourcePreferenceSelectionValues {
    anySource = ChannelTargetBroadcasterPreference.AnyBroadcaster,
    preferPrimary = -1,
    primaryOnly = ChannelTargetBroadcasterPreference.PrimaryOnly,
    backupOnly = ChannelTargetBroadcasterPreference.BackupOnly,
    preferBackup = -4,
    previousSelection = -5,
    invalid = Number.MIN_SAFE_INTEGER
}

@Component({
    selector: "zx-delivery-channel-source-select",
    templateUrl: "./zx-delivery-channel-source-select.component.html",
    styleUrls: ["./zx-delivery-channel-source-select.component.scss"],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: NgForm,
            deps: [[new Optional(), NgForm]],
            useFactory: (form: NgForm) => form
        }
    ],
    providers: [TitleCasePipe],
    encapsulation: ViewEncapsulation.None
})
export class ZxDeliveryChannelSourceSelectComponent implements OnInit, OnChanges {
    @Input() id: string;
    @Input() name: string;
    @Input() model = SourcePreferenceSelectionValues.anySource;
    @Input() disabled?: boolean;
    @Input() channel_id: number;
    @Input() allowKeepSelecton = false;
    @Input() allowPreferenceSelection = false;
    @Input() appendToBody = false;
    @Output() modelChange = new EventEmitter();

    deliveryChannelId: number;
    sourcesLoading: boolean;

    sources: Partial<Source>[] = [];
    sourcesBase: Partial<Source>[] = [];

    constructor(
        private channelService: ChannelsService,
        private sourcesService: SourcesService,
        private translate: TranslateService
    ) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loadSourcesBase();
        if (this.allowKeepSelecton) {
            this.model = SourcePreferenceSelectionValues.previousSelection;
        }
        this.sources = this.sourcesBase;

        this.sourcesService.refreshSources(false);
        this.channelService.getDeliveryChannels();
        if (this.channel_id > 0) {
            this.ngOnChanges({ channel_id: new SimpleChange(null, this.channel_id, true) });
        }
    }

    async reloadSources() {
        this.sourcesLoading = true;
        if (!this.deliveryChannelId) {
            this.sources = this.sourcesBase;
            this.sourcesLoading = false;
            return;
        }

        await this.channelService.refreshDeliveryChannel(this.deliveryChannelId, false).toPromise();
        const selectedChannel: DeliveryChannel = this.channelService.getPassThroughCachedChannelById(
            this.deliveryChannelId
        );
        if (!selectedChannel) {
            this.sources = this.sourcesBase;
            this.sourcesLoading = false;
            if (this.model > 0) {
                this.model = SourcePreferenceSelectionValues.anySource;
                this.modelChanged();
            }
            return;
        }

        const channelSources = Object.values(selectedChannel.sources).map(channelSource => channelSource.source);
        this.sources = this.sourcesBase.concat(channelSources);
        if (!this.model || (this.model > 0 && !channelSources.find(s => s.id === this.model))) {
            this.model = SourcePreferenceSelectionValues.anySource;
            this.modelChanged();
        }
        this.sourcesLoading = false;
    }

    loadSourcesBase() {
        this.sourcesBase =
            this.allowPreferenceSelection ||
            [SourcePreferenceSelectionValues.preferPrimary, SourcePreferenceSelectionValues.preferBackup].includes(
                this.model
            )
                ? [
                      {
                          name: this.translate.instant("PREFER_ANY_SOURCE"),
                          id: SourcePreferenceSelectionValues.anySource
                      },
                      {
                          name: this.translate.instant("PRIMARY_ONLY_SOURCE"),
                          id: SourcePreferenceSelectionValues.primaryOnly
                      },
                      {
                          name: this.translate.instant("BACKUP_ONLY_SOURCE"),
                          id: SourcePreferenceSelectionValues.backupOnly
                      },
                      {
                          name: this.translate.instant("PREFER_PRIMARY_SOURCE"),
                          id: SourcePreferenceSelectionValues.preferPrimary
                      },
                      {
                          name: this.translate.instant("PREFER_BACKUP_SOURCE"),
                          id: SourcePreferenceSelectionValues.preferBackup
                      }
                  ]
                : [
                      {
                          name: this.translate.instant("PREFER_ANY_SOURCE"),
                          id: SourcePreferenceSelectionValues.anySource
                      },
                      {
                          name: this.translate.instant("PRIMARY_ONLY_SOURCE"),
                          id: SourcePreferenceSelectionValues.primaryOnly
                      },
                      {
                          name: this.translate.instant("BACKUP_ONLY_SOURCE"),
                          id: SourcePreferenceSelectionValues.backupOnly
                      }
                  ];

        if (this.allowKeepSelecton) {
            this.sourcesBase.push({
                name: this.translate.instant("KEEP_PREVIOUS_SOURCE"),
                id: SourcePreferenceSelectionValues.previousSelection
            });
        }
        if (this.model === SourcePreferenceSelectionValues.invalid) {
            this.sourcesBase.push({
                name: "-",
                id: SourcePreferenceSelectionValues.invalid
            });
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        let sourceReloadRequired = false;
        if (changes.allowPreferenceSelection) {
            this.loadSourcesBase();
            sourceReloadRequired = true;
        }

        if (changes.channel_id) {
            this.deliveryChannelId = changes.channel_id.currentValue;
            sourceReloadRequired = true;
        }

        if (sourceReloadRequired) this.reloadSources();
    }
}
