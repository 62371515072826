<ng-select
    class="form-control minw-300px"
    name="{{ name }}"
    [disabled]="disabled"
    [items]="incidents"
    [clearable]="false"
    [loading]="loading"
    bindLabel="name"
    bindValue="id"
    placeholder="{{ 'SELECT' | translate }} {{ 'INCIDENT' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="{{ required || false }}"
>
</ng-select>
