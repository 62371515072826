import { Pipe, PipeTransform } from "@angular/core";
import { SomeZixiObject } from "../models/shared";
import { TargetsService } from "../pages/targets/targets.service";

@Pipe({
    name: "targetStatusText",
    pure: false
})
export class TargetStatusTextPipe implements PipeTransform {
    constructor(private targetsService: TargetsService) {}

    statuses = {
        disabled: "DISABLED",
        good: "OK",
        warning: "WARNING",
        bad: "ERROR",
        no_source: "NOT_ASSIGNED",
        no_flow: "NOT_ASSIGNED",
        flow_disabled: "FLOW_DISABLED"
    };

    transform(model: SomeZixiObject): string {
        return this.targetsService.getTargetStatus(model);
    }
}
