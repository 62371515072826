import { Component, OnInit, Input } from "@angular/core";
import { Constants } from "../../../constants/constants";
import { Network } from "src/app/pages/networks/network";

@Component({
    selector: "zx-network",
    templateUrl: "./zx-network.component.html"
})
export class ZxNetworkComponent implements OnInit {
    @Input() model: Network;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() searchTerm?: string | string[];

    urls = Constants.urls;

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }

    get prettyName() {
        return `${this.model.isp} (${this.model.region_code}/${this.model.country_code})`;
    }
}
