import { Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-zx-string-selection",
    templateUrl: "./zx-string-selection.component.html"
})
export class ZxStringSelectionComponent extends CustomControlValueAccessor<string | string[]> {
    @Input() selectionOptions: string[];
    @Input() label?: string;
    @Input() isParentFormSubmitted: boolean;
    @Input() disabled = this.ngControl.disabled;
    constructor(public ngControl: NgControl) {
        super(ngControl);
    }
}
