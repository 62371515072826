<form id="form" #form="ngForm" (ngSubmit)="form.valid && save()">
    <div class="modal-header">
        <h3 class="modal-title">{{ "EDIT_RULE" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="dialogRef.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div *ngIf="loading" class="float-animation">
        <spinner-animation></spinner-animation>
    </div>

    <div class="modal-content p-3 pb-0" *ngIf="!loading && rule">
        <ngb-alert class="mb-3" type="danger" [dismissible]="false">
            <strong>{{ "ERROR" | translate }}</strong> - {{ event.message }}
        </ngb-alert>
        <div class="details-form">
            <div class="form-group">
                <h5 translate>ALERT_PROFILE</h5>
                <div>
                    <a class="fw-normal" href="/configuration/events-management/{{ alertingProfile.id }}">{{ alertingProfile.name }}</a>
                </div>
            </div>
            <div class="form-group">
                <h5 translate>RULE</h5>
                <div>{{ rule.object_type | uppercase | translate }} - {{ rule.group | uppercase | translate }} - {{ rule.code | uppercase | translate }}</div>
            </div>
            <div class="form-group mb-0">
                <h5 translate>OBJECT</h5>
                <div>{{ event.object_name }}</div>
            </div>
        </div>
        <hr />
        <div class="modal-body">
            <app-edit-alerting-rule
                class="position-relative"
                [rule]="rule"
                [isFormSubmitted]="form.submitted"
                [saveButton]="false"
                [alertingProfileId]="alertingProfile.id"
                (ruleUpdate)="onRuleUpdate($event)"
            ></app-edit-alerting-rule>

            <!-- Toggle-->
            <fieldset class="form-group" *ngIf="!alertingProfile.default">
                <legend class="not-bold" for="profile">
                    {{ "UPDATE_PROFILE" | translate }}
                </legend>
                <mat-button-toggle-group name="profile" [(ngModel)]="savingNewProfile">
                    <mat-button-toggle [value]="false">{{ "OVERWRITE" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="true">{{ "CREATE_NEW" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>

            <ng-container *ngIf="savingNewProfile">
                <app-input-generic-field
                    [formControl]="nameControl"
                    [textFieldName]="'NAME' | translate"
                    [isParentFormSubmitted]="form.submitted"
                    labelText="{{ 'NEW_PROFILE_NAME' | translate }}"
                    type="text"
                ></app-input-generic-field>
            </ng-container>
            <app-error [marginBottom]="true" [marginTop]="false"></app-error>
        </div>
    </div>

    <div class="modal-footer" *ngIf="!loading && rule">
        <button type="button" class="btn btn-outline-secondary me-2" [disabled]="saving" (click)="dialogRef.close()">
            <fa-icon icon="times" size="sm"></fa-icon>
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="btn btn-primary" [disabled]="saving || (savingNewProfile ? nameControl.invalid : false)" zmid="form-submit" type="submit">
            <fa-icon icon="check" size="sm" class="me-1"></fa-icon
            ><ng-container *ngIf="!savingNewProfile">{{ "UPDATE_CURRENT_PROFILE" | translate }}</ng-container
            ><ng-container *ngIf="savingNewProfile">{{ "CREATE_NEW_PROFILE" | translate }}</ng-container
            ><span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>
