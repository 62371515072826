<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "ERROR" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">{{ errorText }}</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
