import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { LogDetailDialogComponent } from "./log-detail-dialog/log-detail-dialog.component";
import { SCTELog } from "./scte";

@Injectable({
    providedIn: "root"
})
export class LogDetailModalService {
    constructor(private ngbModal: NgbModal) {}

    async logDetails(log: SCTELog | null) {
        const modal = this.ngbModal.open(LogDetailDialogComponent, { backdrop: "static", centered: true, size: "lg" });

        modal.componentInstance.log = log;
        await modal.result;
        return modal.result;
    }
}
