import { Injectable } from "@angular/core";
import { grafanaFlexEncoder as FlexDashboardEncoder } from "@zixi/shared-utils";
import { NavigationService } from "../components/navigation/navigation.service";

@Injectable({
    providedIn: "root"
})
export class FlexGraphsService {
    constructor(private navigationService: NavigationService) {}

    custom(config: FlexDashboardEncoder.FlexDashboardConfig) {
        let darkMode: boolean;
        this.navigationService.isDarkMode.pipe().subscribe(b => {
            darkMode = b;
        });
        if (darkMode) return this.encodeDashboard(config);
        else return this.encodeDashboard(config);
    }

    private encodeDashboard(config: FlexDashboardEncoder.FlexDashboardConfig) {
        return FlexDashboardEncoder.encodeFlexDashboard(config);
    }
}
