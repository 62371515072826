<!-- Diagram -->
<div id="diagram" #diagram class="diagram">
    <!-- Zoomer -->
    <button
        class="btn btn-outline-primary mermaid-zoomer d-print-none"
        (click)="zoomIn()"
        *ngIf="!graphZoomed && !loadingMermaid && !noModel && mermaidData?.length <= maxTextSize - 1000"
    >
        <fa-icon icon="plus" size="sm"></fa-icon><span class="sr-only">{{ "ZOOM_IN" | translate }}</span>
    </button>
    <button
        class="btn btn-outline-primary mermaid-zoomer d-print-none"
        (click)="zoomOut()"
        *ngIf="graphZoomed && !loadingMermaid && !noModel && mermaidData?.length <= maxTextSize - 1000"
    >
        <fa-icon icon="minus" size="sm"></fa-icon><span class="sr-only">{{ "ZOOM_OUT" | translate }}</span>
    </button>
    <!-- Settings -->
    <div class="mermaid-settings d-print-none" ngbDropdown [placement]="['bottom-left']" *ngIf="!loadingMermaid && !noModel">
        <button class="btn btn-outline-secondary" id="settingsDropdown" ngbDropdownToggle title="{{ 'SETTINGS' | translate }}">
            <fa-icon icon="cog"></fa-icon>
            <span class="sr-only">{{ "SETTINGS" | translate }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="settingsDropdown" class="shadow-sm">
            <!-- Display -->
            <div class="px-3 py-1">
                <div class="form-group mb-0">
                    <label class="mb-0">{{ "DISPLAY" | translate }}</label>
                </div>
            </div>
            <button class="text-secondary" title="{{ 'DETAILS' | translate }}" ngbDropdownItem (click)="toggleDetails(); $event.stopPropagation()">
                <fa-icon [icon]="['far', 'check-square']" *ngIf="!hideDetails"></fa-icon>
                <fa-icon [icon]="['far', 'square']" *ngIf="hideDetails"></fa-icon>
                {{ "DETAILS" | translate }}
            </button>
            <button class="text-secondary" title="{{ 'BROADCASTERS' | translate }}" ngbDropdownItem (click)="toggleBroadcasters(); $event.stopPropagation()">
                <fa-icon [icon]="['far', 'check-square']" *ngIf="!hideBroadcasters"></fa-icon>
                <fa-icon [icon]="['far', 'square']" *ngIf="hideBroadcasters"></fa-icon>
                {{ "BROADCASTERS" | translate }}
            </button>
            <!-- Orientation -->
            <div class="px-3 py-1">
                <div class="form-group mb-0">
                    <label class="mb-0">{{ "ORIENTATION" | translate }}</label>
                </div>
            </div>
            <button class="text-secondary" title="{{ 'LEFT-TO-RIGHT' | translate }}" ngbDropdownItem (click)="toggleOrientation('flowchart LR')">
                <div class="form-check form-check-inline">
                    <input
                        type="radio"
                        id="leftToRight"
                        name="leftToRight"
                        class="form-check-input"
                        (click)="$event.stopPropagation()"
                        value="flowchart LR"
                        [(ngModel)]="mermaidDirection"
                    />
                    <label class="form-check-label" for="leftToRight">{{ "LEFT-TO-RIGHT" | translate }}</label
                    ><fa-icon icon="arrow-alt-from-left" class="ms-1" [fixedWidth]="true"></fa-icon>
                </div>
            </button>
            <button class="text-secondary" title="{{ 'TOP-TO-BOTTOM' | translate }}" ngbDropdownItem (click)="toggleOrientation('flowchart TB')">
                <div class="form-check form-check-inline">
                    <input
                        type="radio"
                        id="topToBottom"
                        name="topToBottom"
                        class="form-check-input"
                        (click)="$event.stopPropagation()"
                        value="flowchart TB"
                        [(ngModel)]="mermaidDirection"
                    />
                    <label class="form-check-label" for="topToBottom">{{ "TOP-TO-BOTTOM" | translate }}</label
                    ><fa-icon icon="arrow-alt-from-top" class="ms-1" size="sm" [fixedWidth]="true"></fa-icon>
                </div>
            </button>
        </div>
    </div>
    <!-- Diagram -->
    <div id="mermaid-container" class="mermaid-container" [ngClass]="{ loading: loadingMermaid }" (mousedown)="startDiagramDrag($event)">
        <div class="visualizationLoading" *ngIf="loadingMermaid && !updatingMermaid">
            <spinner-animation></spinner-animation>
        </div>
        <div class="visualizationUpdating" *ngIf="updatingMermaid">
            <spinner-animation></spinner-animation>
        </div>
        <div id="noModel" *ngIf="noModel">
            <p class="mb-0 text-center">
                {{ "OBJECT_NO_LONGER_EXISTS" | translate }}
            </p>
        </div>
        <div *ngIf="!loadingMermaid && !noModel && mermaidData?.length > maxTextSize - 1000">{{ "MERMAID_DIAGRAM_DISABLED_LONG" | translate }}</div>
        <div
            id="visualization"
            #visualization
            class="mermaid"
            [ngStyle]="{
                opacity: loadingMermaid ? 0 : 1,
                height: noModel ? 0 : '100%'
            }"
        ></div>
    </div>
</div>
