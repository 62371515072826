import _ from "lodash";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { TourService } from "ngx-ui-tour-md-menu";

export type WidgetHeaderWithoutLayout = {
    title: string;
    fa_icon: string | string[];
};

export type WidgetHeaderLayout<T extends readonly string[] = string[]> = {
    title: string;
    key?: T[number]; //TODO Remove The ?
    isSelected: boolean;
    isHidden: boolean;
    index: number;
};

export type WidgetHeader<T extends readonly string[] = string[]> = WidgetHeaderWithoutLayout & WidgetHeaderLayout<T>;

@Component({
    selector: "app-widget-section-header",
    templateUrl: "./widget-section-header.component.html",
    styleUrls: ["./widget-section-header.component.scss"]
})
export class WidgetSectionHeaderComponent implements OnInit, OnDestroy, OnChanges {
    @Input() widgetHeaders: WidgetHeader[];
    @Output() widgetHeadersChange = new EventEmitter<WidgetHeader[]>();
    @Input() isSectionPinned = false;
    @Output() isSectionPinnedChange = new EventEmitter<boolean>();
    @Input() isMultiSelect: boolean;
    @Output() isMultiSelectChange = new EventEmitter<boolean>();
    @Output() widgetSelectedChange = new EventEmitter<WidgetHeader[]>();
    @Input() isLocked = false;
    @Input() isMobile = false;

    nonHiddenWidgets: WidgetHeader[] = [];
    hiddenWidgets: WidgetHeader[] = [];
    lastSelected: string = null;

    @Input() multiWidgetHeaders: WidgetHeader[] = [];
    private tourSubscription: Subscription;

    constructor(public tourService: TourService) {}

    ngOnInit() {
        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.anchorId === "eigthCustomLayoutAnchor") this.toggleMultiSelect(true);
        });
    }

    ngOnDestroy() {
        this.tourSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.widgetHeaders) return;
        this.nonHiddenWidgets = this.widgetHeaders.filter(w => !w.isHidden);
        this.hiddenWidgets = this.widgetHeaders.filter(w => w.isHidden);
    }

    toggleMultiSelect(isMultiSelect: boolean) {
        this.isMultiSelectChange.emit(isMultiSelect);
        if (!isMultiSelect) {
            let updatedWidgetHeaders;
            if (this.lastSelected) {
                updatedWidgetHeaders = this.widgetHeaders.map((widget, i) => ({ ...widget, isSelected: false }));
                updatedWidgetHeaders.find(widget => widget.title === this.lastSelected).isSelected = true;
            } else updatedWidgetHeaders = this.widgetHeaders.map((widget, i) => ({ ...widget, isSelected: i == 0 }));
            this.widgetSelectedChange.emit(updatedWidgetHeaders);
        } else {
            this.lastSelected = this.widgetHeaders.find(widget => widget.isSelected).title;
            this.widgetSelectedChange.emit(this.multiWidgetHeaders);
        }
    }

    onWidgetMenuClick(widgetTitle: string) {
        if (this.isLocked && this.isMultiSelect) return;
        const index = this.widgetHeaders.findIndex(w => w.title === widgetTitle);
        if (this.isMultiSelect) {
            this.widgetHeaders[index].isSelected = !this.widgetHeaders[index].isSelected;
        } else {
            this.widgetHeaders.forEach((widget, i) => {
                widget.isSelected = i === index;
            });
        }
        this.widgetSelectedChange.emit(this.widgetHeaders);
    }
}
