import { Component, Input } from "@angular/core";
import { ZixiObject } from "../../../models/shared";

@Component({
    selector: "zx-tags-list",
    templateUrl: "./zx-tags-list.component.html"
})
export class ZxTagsListComponent {
    tagsString = "";

    @Input() searchTerm: string;
    @Input() set model(value: ZixiObject) {
        this.tagsString = value?.resourceTags?.map(t => t.name).join(", ");
        if (!this.tagsString) this.tagsString = "-";
    }
}
