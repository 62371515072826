import { Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-cluster-field-selection",
    templateUrl: "./cluster-field-selection.component.html"
})
export class ClusterFieldSelectionComponent extends CustomControlValueAccessor<number> {
    @Input() isParentFormSubmitted: boolean;
    @Input() disabled = this.ngControl.disabled;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    clusterSelected(clusterSelectedID: number) {
        this.controlledValue = clusterSelectedID;
        this.ngControl.control.setValue(this.controlledValue);
    }
}
