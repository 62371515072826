import { Component, Input } from "@angular/core";

@Component({
    selector: "zx-numeric-col",
    template: `
        <span *ngIf="number !== '-'">
            <ngb-highlight title="{{ number }}" [result]="number" [term]="searchTerm"></ngb-highlight
            ><span class="unit">{{ unit }}</span>
        </span>
        <span *ngIf="number === '-'">-</span>
    `
})
export class ZxNumericColComponent {
    @Input() number: number | string;
    @Input() unit: string;
    @Input() searchTerm: string | string[];
}
