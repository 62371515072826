import { Component, Input } from "@angular/core";
import { IoServer } from "src/app/components/io-servers/io-servers.service";

@Component({
    selector: "app-io-server-status-column",
    templateUrl: "./io-server-status-column.component.html"
})
export class IoServerStatusColumnComponent {
    @Input() ioServer: IoServer;
}
