<div class="form-group mb-0">
    <label for="types" class="sr-only">{{ "TYPES" | translate }}</label>
    <div class="btn-group" role="group">
        <button type="button" class="btn btn-outline-danger" (click)="_filter.error = !_filter.error; onFilterChange()" [ngClass]="{ active: filter.error }">
            <fa-icon [icon]="['far', 'check-square']" *ngIf="filter.error"></fa-icon>
            <fa-icon [icon]="['far', 'square']" *ngIf="!filter.error"></fa-icon>
            {{ "ERROR" | translate }}
        </button>
        <button
            type="button"
            class="btn btn-outline-warning"
            (click)="_filter.warning = !_filter.warning; onFilterChange()"
            [ngClass]="{ active: filter.warning }"
        >
            <fa-icon [icon]="['far', 'check-square']" *ngIf="filter.warning"></fa-icon>
            <fa-icon [icon]="['far', 'square']" *ngIf="!filter.warning"></fa-icon>
            {{ "WARNING" | translate }}
        </button>
        <button type="button" class="btn btn-outline-info" (click)="_filter.info = !_filter.info; onFilterChange()" [ngClass]="{ active: filter.info }">
            <fa-icon [icon]="['far', 'check-square']" *ngIf="filter.info"></fa-icon>
            <fa-icon [icon]="['far', 'square']" *ngIf="!filter.info"></fa-icon>
            {{ "INFO" | translate }}
        </button>
        <button
            type="button"
            class="btn btn-outline-success"
            (click)="_filter.success = !_filter.success; onFilterChange()"
            [ngClass]="{ active: filter.success }"
        >
            <fa-icon [icon]="['far', 'check-square']" *ngIf="filter.success"></fa-icon>
            <fa-icon [icon]="['far', 'square']" *ngIf="!filter.success"></fa-icon>
            {{ "OK" | translate }}
        </button>
    </div>
</div>
