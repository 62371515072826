import { Component, Input, OnInit, ViewChild, inject } from "@angular/core";
import { ControlContainer, NgControl, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ChannelTypes } from "src/app/pages/channels/channel";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-zx-channel-select",
    templateUrl: "./zx-channel-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxChannelSelectComponent extends CustomControlValueAccessor<ChannelTypes[]> implements OnInit {
    @ViewChild(NgSelectComponent, { read: NgSelectComponent }) selectComponentRef!: NgSelectComponent;
    @Input() channelType: string;
    @Input() isParentFormSubmitted: boolean;
    @Input() label?: string;
    @Input() disabled = this.ngControl.disabled;

    loading = true;
    channels$: Observable<ChannelTypes[]>;
    private channelService = inject(ChannelsService);

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    ngOnInit(): void {
        this.channels$ = this.chooseChannelServiceType(this.channelType).pipe(tap(() => (this.loading = false)));
    }

    private chooseChannelServiceType(type: string) {
        let returnObservable: Observable<ChannelTypes[]>;
        switch (type) {
            case "failover":
                returnObservable = this.channelService.failoverChannels;
                this.channelService.getFailoverChannels();
                break;
            case "adaptive":
                returnObservable = this.channelService.adaptiveChannels;
                this.channelService.getAdaptiveChannels();
                break;
            case "delivery":
                returnObservable = this.channelService.deliveryChannels;
                this.channelService.getDeliveryChannels();
                break;
            case "mediaconnect":
                returnObservable = this.channelService.mediaconnectFlows;
                this.channelService.getMediaConnectFlows();
                break;
            case "medialive":
                returnObservable = this.channelService.medialiveChannels;
                this.channelService.getMediaLiveChannels();
                break;
        }
        return returnObservable;
    }
}
