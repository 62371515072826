import { Pipe, PipeTransform } from "@angular/core";
import { BroadcasterOutput } from "../models/shared";

@Pipe({
    name: "broadcasterOutputStatus"
})
export class BroadcasterOutputStatusPipe implements PipeTransform {
    transform(output: BroadcasterOutput): string {
        if (output.error_code || output.status === "Offline") {
            return "error";
        } else return "good";
    }
}
