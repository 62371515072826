import { Component, EventEmitter, Input, OnInit, Optional, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { DeliveryChannel } from "../../..//pages/channels/channel";
import { map, tap } from "rxjs/operators";
import { Observable, combineLatest } from "rxjs";

@Component({
    selector: "zx-delivery-channel-select",
    templateUrl: "./zx-delivery-channel-select.component.html",
    viewProviders: [{ provide: ControlContainer, deps: [[Optional, NgForm]], useFactory: (ngForm: NgForm) => ngForm }]
})
export class ZxDeliveryChannelSelectComponent implements OnInit {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Input() failover?: boolean;
    @Input() filterFunction?: (channel: DeliveryChannel) => boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;

    channels$: Observable<DeliveryChannel[]>;

    constructor(private cs: ChannelsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getDeliveryChannels();
        if (this.failover) {
            this.cs.getFailoverChannels();
            this.channels$ = combineLatest([
                this.cs.deliveryChannels,
                this.cs.failoverChannels.pipe(map(channels => channels.map(ch => ch.deliveryChannel)))
            ]).pipe(
                map(([deliveryChannels, failoverDeliveryChannels]) =>
                    (deliveryChannels ?? [])
                        .filter(ch => !ch.is_hidden)
                        .filter(this.filterFunction ?? (() => true))
                        .concat(failoverDeliveryChannels ?? [])
                ),
                tap(() => {
                    this.loading = false;
                })
            );
        } else {
            this.channels$ = this.cs.deliveryChannels.pipe(
                map(channels => channels.filter(ch => !ch.is_hidden).filter(this.filterFunction ?? (() => true))),
                tap(() => {
                    this.loading = false;
                })
            );
        }
    }
}
