<!-- Target Broadcaster/s -->
<div class="p-2" (click)="$event.stopPropagation()">
    <ng-select
        appendTo="body"
        id="target_broadcaster_id"
        name="target_broadcaster_id"
        [items]="targetBXs"
        [clearable]="false"
        bindValue="id"
        bindLabel="name"
        groupBy="type"
        placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
        [ngModel]="targetBroadcasterId"
        (change)="onTargetBroadcasterChange($event)"
        [ngClass]="{ 'form-control is-invalid': targetBroadcasters.errors }"
        class="target-bx"
        #targetBroadcasters="ngModel"
        [disabled]="disable"
        [loading]="targetBXLoading"
        required
    >
        <ng-template ng-optgroup-tmp let-item="item">
            <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
            <span *ngIf="!item.type">{{ item.name }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
            <span *ngIf="!item.type">{{ item.name }}</span>
        </ng-template>
    </ng-select>
</div>
