import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormControl, NgModel } from "@angular/forms";
import { BillingCodeValidationService } from "../../../services/billing-code-validation.service";
import { validateBillingCodeControls } from "../../../validators/billing-codes.validator";

@Component({
    selector: "zx-billing-codes-check",
    templateUrl: "./zx-billing-codes-check.component.html"
})
export class ZxBillingCodesCheckComponent implements OnInit {
    @Input() codeControl: NgModel | UntypedFormControl;
    @Input() passControl: NgModel | UntypedFormControl;
    @Input() disabled: boolean;

    checkInProgress = false;
    checkComplete = false;
    codeValidated = false;
    constructor(private checkService: BillingCodeValidationService) {}
    ngOnInit() {
        this.codeControl.valueChanges.subscribe(() => {
            this.checkComplete = false;
        });
        this.passControl.valueChanges.subscribe(() => {
            this.checkComplete = false;
        });
    }

    onClick() {
        const codeControl = this.getControl(this.codeControl);
        const passControl = this.getControl(this.passControl);

        if (!this.codeControl || !this.passControl) return;

        this.checkInProgress = true;
        return validateBillingCodeControls(this.checkService, codeControl, passControl).then(errors => {
            if (!this.passControl || !passControl) return;

            passControl.setErrors(errors);
            this.checkInProgress = false;
            this.codeValidated = errors === null;
            this.checkComplete = true;
        });
    }

    getControl(control: NgModel | UntypedFormControl): UntypedFormControl {
        return this.isFormControl(control) ? control : control.control;
    }

    isFormControl(control: NgModel | UntypedFormControl): control is UntypedFormControl {
        return !(control as NgModel).control;
    }
}
