<div>
    <label>{{ label }}</label>
    <div class="number-container">
        <div
            class="bigNumber"
            [ngClass]="{
                'status-good': value < 50,
                'status-warning': value >= 50 && value < 75,
                'status-error': value >= 75
            }"
        >
            {{ value }}
        </div>
        <div class="append">
            {{ append }}
        </div>
    </div>
</div>
