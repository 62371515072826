<!-- Custom Messages-->
<ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="error && !this.accessTagError()" class="mb-0" [ngClass]="{ 'mt-3': marginTop, 'mb-3': marginBottom }">
    <strong>{{ "ERROR" | translate }}</strong
    >:&nbsp;{{ error.message }}
</ngb-alert>

<!-- Access Tags -->
<ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="error && this.accessTagError()" class="mb-0" [ngClass]="{ 'mt-3': marginTop, 'mb-3': marginBottom }">
    <strong>{{ "ERROR" | translate }}</strong
    >:&nbsp;{{ "TAG_IS_IN_USE" | translate }}.
</ngb-alert>
