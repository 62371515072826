<div [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">{{ "EDIT_INCIDENT" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
        <div class="modal-body">
            <!-- Name -->
            <div class="form-group">
                <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }">{{ "NAME" | translate }}</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="{{ 'NAME' | translate }}"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': name.errors }"
                    [(ngModel)]="incidentCopy.name"
                    required
                    minlength="{{ minLength }}"
                    pattern="{{ constants.validators.name }}"
                    #name="ngModel"
                />
                <div *ngIf="name.invalid" class="invalid-feedback">
                    <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                    <div *ngIf="name.errors.minlength">
                        {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                    </div>
                    <div *ngIf="name.errors.pattern">
                        {{ "NAME" | translate }}
                        {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                    </div>
                </div>
            </div>
            <!-- Make Zixi Only -->
            <fieldset class="form-group mb-0" *ngIf="isZixiUser">
                <legend for="zixionly">{{ "ZIXI_ONLY" | translate }}</legend>
                <mat-button-toggle-group name="zixionly" aria-label="zixi only" [(ngModel)]="incidentCopy.is_zixi">
                    <mat-button-toggle [value]="1">{{ "YES" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="0">{{ "NO" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>
            <!-- Make public incident -->
            <fieldset class="form-group mb-0 mt-3">
                <legend for="public">{{ "PUBLIC_INCIDENT" | translate }}</legend>
                <mat-button-toggle-group name="public" aria-label="public incident" [(ngModel)]="incidentCopy.is_public">
                    <mat-button-toggle [value]="1">{{ "YES" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="0">{{ "NO" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>
            <!-- State -->
            <fieldset class="form-group mb-0 mt-3">
                <legend for="state">{{ "STATE" | translate }}</legend>
                <mat-button-toggle-group name="state" aria-label="incident state" [(ngModel)]="incidentCopy.state">
                    <mat-button-toggle value="open">{{ "OPEN" | translate }}</mat-button-toggle>
                    <mat-button-toggle value="closed">{{ "CLOSED" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>
            <!-- Question -->
            <fieldset class="form-group mb-0 mt-3" *ngIf="incidentCopy.state === 'closed'">
                <legend for="type">{{ "WAS" | translate }} "{{ incident.likely_cause }}" {{ "THE_CORRECT_ROOT_CAUSE" | translate }}?</legend>
                <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="correctCause">
                    <mat-button-toggle [value]="true">{{ "YES" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="false">{{ "NO" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </fieldset>
            <!-- Root Cause Dropdown -->
            <div class="form-group mt-3 mb-0" *ngIf="!correctCause && incidentCopy.state === 'closed'">
                <label for="likely_cause" [ngClass]="{ 'is-invalid': form.submitted && likely_cause.errors }"> {{ "ROOT_CAUSE" | translate }}</label>
                <ng-select
                    id="likely_cause"
                    name="likely_cause"
                    required
                    [items]="possibleCauses"
                    [clearable]="false"
                    placeholder="{{ 'SELECT' | translate }} {{ 'ROOT_CAUSE' | translate }}"
                    [(ngModel)]="likelyCause"
                    #likely_cause="ngModel"
                    [ngClass]="{ 'form-control is-invalid': form.submitted && likely_cause.errors }"
                >
                </ng-select>
                <div *ngIf="likely_cause.invalid" class="invalid-feedback">
                    <div *ngIf="likely_cause.errors.required">{{ "ROOT_CAUSE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                </div>
            </div>
            <!-- Describe Root Cause Textarea -->
            <div class="form-group mb-0 mt-3" *ngIf="!correctCause && likelyCause === 'Other' && incidentCopy.state === 'closed'">
                <label for="describe"> {{ "PLEASE_DESCRIBE_THE_ROOT_CAUSE" | translate }}</label>
                <textarea
                    id="describe"
                    name="describe"
                    class="form-control"
                    [(ngModel)]="describeRootCause"
                    #describe
                    placeholder="{{ 'DESCRIBE_ROOT_CAUSE' | translate }}"
                    rows="3"
                ></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()">
                <fa-icon icon="times"></fa-icon>
                {{ "CANCEL" | translate }}
            </button>
            <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="saving" zmid="form-submit" type="submit">
                <fa-icon icon="check" size="sm"></fa-icon>
                {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
            </button>
        </div>
    </form>
</div>
