import { Component, Input } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "../../../models/shared";

export type FunctionRef<T = Base, R = void> = (row: T) => R;

@Component({
    selector: "app-zx-ssh-keys-table-row-actions",
    templateUrl: "./zx-ssh-keys-table-row-actions.component.html"
})
export class ZxSshKeysTableRowActionsComponent {
    @Input() row: KeyMap<Base>;
    @Input() cantDownload: boolean;
    @Input() canEdit: boolean;
    @Input() canDelete: boolean;
    @Input() downloadLink: FunctionRef;
    @Input() editRef: FunctionRef;
    @Input() deleteRef: FunctionRef;

    onEdit(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.editRef(this.row);
    }

    onDelete(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.deleteRef(this.row);
    }
}
