import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Announcements } from "@zixi/models";

@Component({
    selector: "app-announcement",
    templateUrl: "./announcement.component.html"
})
export class AnnouncementComponent {
    severityToTypeMapping = {
        warning: "warning",
        info: "info",
        error: "danger"
    };

    @Input() announcement: Announcements;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onDismiss: EventEmitter<Announcements> = new EventEmitter();
}
