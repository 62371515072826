<section id="collapsed-section">
    <div class="toggle-fieldset-title" [ngClass]="{ closed: !showSection }">
        <h3 class="toggle-fieldset" (click)="toggleShowDisplay()" [ngClass]="{ closed: !showSection }">
            {{ title }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showSection"></fa-icon
            ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showSection"></fa-icon>
        </h3>
    </div>
    <fieldset *ngIf="showSection" class="mb-3 bordered no-bg">
        <ng-content> </ng-content>
    </fieldset>
</section>
