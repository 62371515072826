<div class="details-form">
    <!-- Parent -->
    <div class="form-group row" *ngIf="target.target.parentZixiPull">
        <h5 class="col-12" translate>PARENT_TARGET</h5>
        <div class="col-12">
            <span>{{ target.target.parentZixiPull.name }}</span>
        </div>
    </div>
    <!-- Name -->
    <div class="form-group row">
        <h5 class="col-12" translate>NAME</h5>
        <div class="col-12">
            <span>{{ target.target.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="isVip()">
        <h5 class="col-12" translate>PRIORITY</h5>
        <div class="col-12">
            <fa-icon icon="check" size="lg"></fa-icon>
        </div>
    </div>
    <!-- Access Tags -->
    <div class="form-group row" *ngIf="target.target.resourceTags && target.target.resourceTags.length">
        <h5 class="col-12" translate>TAGS</h5>
        <div class="col-12">
            <span *ngFor="let tag of target.target.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="target.target.alertingProfile">
        <h5 class="col-12" translate>ALERTING_PROFILE</h5>
        <div class="col-12">
            <span>{{ target.target.alertingProfile.name }}</span>
        </div>
    </div>
    <app-details-location [location]="target.target.location"></app-details-location>
    <!-- Type -->
    <div class="form-group row">
        <h5 class="col-12" translate>TYPE</h5>
        <div class="col-12">
            <span>{{ target.type_name | uppercase | translate }}</span>
        </div>
    </div>
    <!-- Active Broadcaster -->
    <div class="form-group row" *ngIf="target._frontData?.active_broadcaster && target._frontData?.active_broadcaster.id">
        <h5 class="col-12" translate>ACTIVE_BROADCASTER</h5>
        <div class="col-12 ellipsis">
            <zx-broadcaster
                [model]="target._frontData?.active_broadcaster"
                [showTag]="false"
                [showLink]="true"
                [showCluster]="false"
                [showStatusIcon]="false"
                [clusterId]="target.target.deliveryChannel?.broadcaster_cluster_id || target.target.activeBroadcasterObjects.cluster_id"
                [searchTerm]="searchTermArray"
            ></zx-broadcaster>
        </div>
    </div>
    <!-- Active Broadcasters -->
    <div class="form-group row" *ngIf="target._frontData?.active_broadcaster && !target._frontData?.active_broadcaster.id">
        <h5 class="col-12" translate>ACTIVE_BROADCASTERS</h5>
        <div class="col-12">
            <div class="ellipsis" *ngFor="let broadcaster of target.target.status?.active_broadcasters">
                <zx-broadcaster
                    [model]="broadcaster"
                    [showTag]="false"
                    [showLink]="true"
                    [showCluster]="false"
                    [showStatusIcon]="false"
                    [clusterId]="target.target.deliveryChannel?.broadcaster_cluster_id"
                    [searchTerm]="searchTermArray"
                ></zx-broadcaster>
            </div>
        </div>
    </div>
    <!-- PID Mapping -->
    <div class="form-group row" *ngIf="target.target['pid_mapping']">
        <h5 class="col-12" translate>PID_MAPPING</h5>
        <div class="col-12">
            <a [routerLink]="['/' + urls.transformation.pid_mapping_profiles, target.target.pid_mapping.name]">
                {{ target.target.pid_mapping.name }}
            </a>
        </div>
    </div>
    <!-- HTTP -->
    <div *ngIf="target.adaptive">
        <!-- Channel -->
        <div class="form-group row">
            <h5 class="col-12" translate>CHANNEL</h5>
            <div class="col-12 ellipsis">
                <zx-adaptive
                    [model]="target.target.adaptiveChannel"
                    [showLink]="true"
                    [searchTerm]="searchTerm"
                    *ngIf="target.target.adaptive_channel_id != null"
                ></zx-adaptive>
                <span *ngIf="target.target.adaptive_channel_id == null">-</span>
            </div>
        </div>
        <!-- s3/mediastore URI -->
        <div class="form-group row" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
            <h5 class="col-12" translate *ngIf="target.target.type === 's3'">S3_URI</h5>
            <h5 class="col-12" translate *ngIf="target.target.type === 'mediastore'">MEDIASTORE_URI</h5>
            <div class="col-12">
                <span>{{ target.target.ingest_url }}</span>
            </div>
        </div>
        <!-- Encapsulation -->
        <div class="form-group row">
            <h5 class="col-12" translate>ENCAPSULATION</h5>
            <div class="col-12">
                <span>{{ target.target.encapsulation | uppercase | translate }}</span>
            </div>
        </div>
        <!-- s3/mediastore Region -->
        <div class="form-group row" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
            <h5 class="col-12" translate>REGION</h5>
            <div class="col-12">
                <span>{{ target.target.aws_region }}</span>
            </div>
        </div>
        <!-- s3/mediastore Access Key ID -->
        <div class="form-group row" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
            <h5 class="col-12" translate>ACCESS_KEY_ID</h5>
            <div class="col-12">
                <span>{{ target.target.aws_access_key_id }}</span>
            </div>
        </div>
        <!-- Ingest URL -->
        <div class="form-group row" *ngIf="target.target.type !== 's3' && target.target.type !== 'mediastore'">
            <h5 class="col-12" translate>INGEST_URL</h5>
            <div class="col-12">
                <span>{{ target.target.ingest_url }}</span>
            </div>
        </div>
        <!-- Playback URL -->
        <div class="form-group row" *ngIf="target.target.playback_url && target.target.playback_url !== ''">
            <h5 class="col-12" translate>PLAYBACK_URL</h5>
            <div class="col-12">
                <span>{{ target.target.playback_url }}</span>
            </div>
        </div>
        <!-- Play HLS -->
        <div
            class="form-group row"
            *ngIf="!target.target.playback_url && (target.target.type === 's3' || target.target.type === 'mediastore' || target.target.type === 'azure')"
        >
            <h5 class="col-12" translate>PLAY_HLS</h5>
            <div class="col-12">
                <a href="javascript:void(0)" (click)="ts.openTargetHLSPreview(target.target)" class="btn btn-play btn-primary"
                    ><fa-icon icon="play" size="xs"></fa-icon
                ></a>
            </div>
        </div>
        <!-- Play Target-->
        <div class="form-group row" *ngIf="target.target.playback_url">
            <h5 class="col-12" translate>PLAY_TARGET</h5>
            <div class="col-12">
                <a href="javascript:void(0)" (click)="ts.openTargetPreview(target.target)" class="btn btn-play btn-primary"
                    ><fa-icon icon="play" size="xs"></fa-icon
                ></a>
            </div>
        </div>
        <!-- Upload DVR playlist -->
        <div class="form-group row">
            <h5 class="col-12" translate>UPLOAD_DVR_PLAYLIST</h5>
            <div class="col-12">
                <span>{{ (target.target.record_dvr ? "YES" : "NO") | translate }}</span>
            </div>
        </div>
        <!-- Propagate Tags -->
        <div class="form-group row">
            <h5 class="col-12" translate>PROPAGATE_TAGS_TO_AWS</h5>
            <div class="col-12">
                <span>{{ (target.target.propagate_tags ? "YES" : "NO") | translate }}</span>
            </div>
        </div>
        <!-- Delete Outdated -->
        <div class="form-group row">
            <h5 class="col-12" translate>DELETE_OUTDATED</h5>
            <div class="col-12">
                <span>{{ (target.target.delete_outdated ? "YES" : "NO") | translate }}</span>
            </div>
        </div>
        <!-- Ignore Certificate -->
        <div class="form-group row">
            <h5 class="col-12" translate>IGNORE_CERTIFICATE</h5>
            <div class="col-12">
                <span> {{ (target.target.ignore_certificate ? "YES" : "NO") | translate }}</span>
            </div>
        </div>
    </div>
    <!-- Pull -->
    <div *ngIf="target.pull">
        <!-- Channel -->
        <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id == null">
            <h5 class="col-12" translate>CHANNEL</h5>
            <div class="col-12 ellipsis">
                <zx-delivery
                    [model]="target.target.deliveryChannel"
                    [showLink]="true"
                    [searchTerm]="searchTerm"
                    *ngIf="target.target.delivery_channel_id != null"
                ></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="col-12 ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true" [searchTerm]="searchTerm"></zx-mediaconnect-flow>
            </div>
        </div>
        <!-- ZEC -->
        <div class="form-group row" *ngIf="target.target.zec_id">
            <h5 class="col-12" translate>ZEC</h5>
            <div class="col-12">
                <div class="ellipsis">
                    <zx-zec [model]="target.target.zec" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="false"></zx-zec>
                </div>
            </div>
        </div>
        <!-- Receiver -->
        <div class="form-group row" *ngIf="target.target.receiver_id">
            <h5 class="col-12" translate>RECEIVER</h5>
            <div class="col-12">
                <div class="ellipsis">
                    <zx-receiver
                        [model]="target.target.receiver"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-receiver>
                    <span *ngIf="target.target.output_id">&nbsp;/&nbsp;{{ target.target.output_name }}</span>
                </div>
            </div>
        </div>
        <!-- Broadcaster -->
        <div class="form-group row" *ngIf="target.target.broadcaster_id">
            <h5 class="col-12" translate>BROADCASTER</h5>
            <div class="col-12">
                <div class="ellipsis">
                    <zx-broadcaster
                        [model]="target.target.broadcaster"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                        [showCluster]="false"
                    ></zx-broadcaster>
                    <span *ngIf="target.target.output_id">&nbsp;/&nbsp;{{ target.target.output_name }}</span>
                </div>
            </div>
        </div>
        <!-- Receiver Name/ID -->
        <div class="form-group row" *ngIf="target.target.receiver_name">
            <h5 class="col-12" translate>RECEIVER_NAME_ID</h5>
            <div class="col-12">
                <span>{{ target.target.receiver_name }}</span>
            </div>
        </div>
        <!-- Remote IP -->
        <div class="form-group row" *ngIf="target.target.status?.ip">
            <h5 class="col-12" translate>CLIENT_IP</h5>
            <div class="col-12">
                <span>{{ target.target.status.ip }}</span>
            </div>
        </div>
        <!-- Password -->
        <div class="form-group row" *ngIf="target.target.password">
            <h5 class="col-12" translate>PASSWORD</h5>
            <div class="col-12">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Encryption Key -->
        <div class="form-group row" *ngIf="target.target.zixi_encryption_key">
            <h5 class="col-12" translate>ENCRYPTION_KEY</h5>
            <div class="col-12">
                <a href="javascript:void(0)" (click)="keyVisible = true" *ngIf="!keyVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="keyVisible">{{ target.target.zixi_encryption_key }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.zixi_encryption_key)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Latency -->
        <div class="form-group row">
            <h5 class="col-12" translate>LATENCY_MS</h5>
            <div class="col-12">
                <span>{{ target.target.latency || ("REMOTE_CONFIGURATION" | translate) }}</span>
            </div>
        </div>
        <!-- Build number -->
        <app-generic-detail header="BUILD" [content]="target.target?.status?.build"></app-generic-detail>
        <!-- APPLICATION -->
        <app-generic-detail header="APPLICATION" [content]="target.target?.status?.app"></app-generic-detail>
        <!-- DEVICE -->
        <app-generic-detail header="DEVICE" [content]="target.target?.status?.device"></app-generic-detail>
    </div>
    <!-- SRT -->
    <div *ngIf="target.srt">
        <!-- Channel -->
        <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id == null">
            <h5 class="col-12" translate>CHANNEL</h5>
            <div class="col-12 ellipsis">
                <zx-delivery
                    [model]="target.target.deliveryChannel"
                    [showLink]="true"
                    [searchTerm]="searchTerm"
                    *ngIf="target.target.delivery_channel_id != null"
                ></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="col-12 ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true" [searchTerm]="searchTerm"></zx-mediaconnect-flow>
            </div>
        </div>
        <!-- Port -->
        <div class="form-group row" *ngIf="target.target.pull_mode !== 0">
            <h5 class="col-12" translate>PORT</h5>
            <div class="col-12">
                <span>{{ target.target.port }}</span>
            </div>
        </div>
        <!-- Bind CIDR -->
        <div class="form-group row" *ngIf="target.target.bind_cidr">
            <h5 class="col-12" translate>BIND_TO_LOCAL_NIC_BY_CIDR</h5>
            <div class="col-12">
                <span>{{ target.target.bind_cidr }} </span>
            </div>
        </div>
        <!-- Target -->
        <div class="form-group row" *ngIf="target.target.pull_mode === 0">
            <h5 class="col-12" translate>TARGET</h5>
            <div class="col-12">
                <span>{{ target.target.host }}:{{ target.target.port }}</span>
            </div>
        </div>
        <!-- Stream ID -->
        <div class="form-group row" *ngIf="target.target.pull_mode === 0 && target.target.srt_stream_id">
            <h5 class="col-12" translate>STREAM_ID</h5>
            <div class="col-12">
                <span>{{ target.target.srt_stream_id }}</span>
            </div>
        </div>
        <!-- Latency -->
        <div class="form-group row">
            <h5 class="col-12" translate>LATENCY_MS</h5>
            <div class="col-12">
                <span>{{ target.target.latency }} </span>
            </div>
        </div>
        <!-- Password -->
        <div class="form-group row">
            <h5 class="col-12" translate>PASSWORD</h5>
            <div class="col-12">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Encryption -->
        <div class="form-group row">
            <h5 class="col-12" translate>ENCRYPTION</h5>
            <div class="col-12">
                <span>{{ (target.target.encryption ? target.target.encryption : "DISABLED") | translate }}</span>
            </div>
        </div>
    </div>
    <!-- Push -->
    <div *ngIf="target.push">
        <!-- Channel -->
        <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id == null">
            <h5 class="col-12" translate>CHANNEL</h5>
            <div class="col-12 ellipsis">
                <zx-delivery
                    [model]="target.target.deliveryChannel"
                    [showLink]="true"
                    [searchTerm]="searchTerm"
                    *ngIf="target.target.delivery_channel_id != null"
                ></zx-delivery>
                <span *ngIf="target.target.delivery_channel_id == null">-</span>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="col-12 ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true" [searchTerm]="searchTerm"></zx-mediaconnect-flow>
            </div>
        </div>
        <!-- Target Host -->
        <div class="form-group row">
            <h5 class="col-12" translate>TARGET_HOST</h5>
            <div class="col-12">
                <span>{{ target.target.target }}</span>
            </div>
        </div>
        <!-- Alt Target Host -->
        <div class="form-group row" *ngIf="target.target.alt_target">
            <h5 class="col-12" translate>ALT_TARGET_HOST</h5>
            <div class="col-12">
                <span>{{ target.target.alt_target }}</span>
            </div>
        </div>
        <!-- Stream ID -->
        <div class="form-group row">
            <h5 class="col-12" translate>STREAM_ID</h5>
            <div class="col-12">
                <span>{{ target.target.stream_id }}</span>
            </div>
        </div>
        <!-- Password -->
        <div class="form-group row">
            <h5 class="col-12" translate>PASSWORD</h5>
            <div class="col-12">
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Encryption Key -->
        <div class="form-group row" *ngIf="target.target.zixi_encryption_key">
            <h5 class="col-12" translate>ENCRYPTION_KEY</h5>
            <div class="col-12">
                <a href="javascript:void(0)" (click)="keyVisible = true" *ngIf="!keyVisible">
                    {{ "SHOW" | translate }}
                </a>
                <span *ngIf="keyVisible">{{ target.target.zixi_encryption_key }}</span> /
                <a href="javascript:void(0)" (click)="copyString(target.target.zixi_encryption_key)">
                    {{ "COPY" | translate }}
                </a>
            </div>
        </div>
        <!-- Latency -->
        <div class="form-group row">
            <h5 class="col-12" translate>LATENCY_MS</h5>
            <div class="col-12">
                <span>{{ target.target.latency }}</span>
            </div>
        </div>
        <!-- Ignore DTLS certificate -->
        <div class="form-group row">
            <h5 class="col-12" translate>IGNORE_DTLS_CERTIFICATE</h5>
            <div class="col-12">
                <span>{{ (target.target.ignore_dtls_cert ? "YES" : "NO") | translate }}</span>
            </div>
        </div>
        <!-- Build number -->
        <app-generic-detail header="BUILD" [content]="target.target?.status?.build"></app-generic-detail>
        <!-- APPLICATION -->
        <app-generic-detail header="APPLICATION" [content]="target.target?.status?.app"></app-generic-detail>
        <!-- DEVICE -->
        <app-generic-detail header="DEVICE" [content]="target.target?.status?.device"></app-generic-detail>
    </div>
    <!-- UDP/RTP/RIST -->
    <div *ngIf="target.udp_rtp || target.rist">
        <!-- Channel -->
        <div class="form-group row" *ngIf="target.target.delivery_channel_id != null">
            <h5 class="col-12" translate>CHANNEL</h5>
            <div class="col-12 ellipsis">
                <zx-delivery
                    [model]="target.target.deliveryChannel"
                    [showLink]="true"
                    [searchTerm]="searchTerm"
                    *ngIf="target.target.delivery_channel_id != null"
                ></zx-delivery>
            </div>
        </div>
        <!-- MediaConnect Flow -->
        <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
            <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
            <div class="col-12 ellipsis">
                <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true" [searchTerm]="searchTerm"></zx-mediaconnect-flow>
            </div>
        </div>
        <!-- MediaLive Channel -->
        <div class="form-group row" *ngIf="target.target.medialive_channel_id != null">
            <h5 class="col-12" translate>MEDIALIVE</h5>
            <div class="col-12 ellipsis">
                <zx-medialive-channel [model]="target.target.mediaLiveChannel" [showLink]="true" [searchTerm]="searchTerm"></zx-medialive-channel>
            </div>
        </div>
        <!-- No Channel-->
        <div
            class="form-group row"
            *ngIf="target.target.mediaconnect_flow_id == null && target.target.medialive_channel_id == null && target.target.delivery_channel_id == null"
        >
            <h5 class="col-12" translate>CHANNEL</h5>
            <div class="col-12">
                <span>-</span>
            </div>
        </div>

        <!-- Target -->
        <div class="form-group row">
            <h5 class="col-12" translate>TARGET</h5>
            <div class="col-12">
                <span>{{ target.target.host }}:{{ target.target.port }}</span>
            </div>
        </div>
        <!-- Local NIC CIDR -->
        <div class="form-group row" *ngIf="target.target.bind_cidr">
            <h5 class="col-12" translate>LOCAL_NIC_CIDR</h5>
            <div class="col-12">
                <span>{{ target.target.bind_cidr }}</span>
            </div>
        </div>
        <!-- SMPTE 2022 FEC -->
        <div class="form-group row" *ngIf="target.target.smpte_2022_fec > 0">
            <h5 class="col-12" translate>SMPTE_2022_FEC</h5>
            <div class="col-12">
                <span> {{ target.target.smpte_2022_fec }}D ({{ target.target.smpte_2022_fec_rows }}x{{ target.target.smpte_2022_fec_cols }})</span>
            </div>
        </div>
        <!-- Target -->
        <div class="form-group row" *ngIf="target.target.remux_kbps">
            <h5 class="col-12" translate>REMUX</h5>
            <div class="col-12">
                <span>{{ target.target.remux_kbps | number : "1.0-0" }} kbps</span>
            </div>
        </div>
        <!-- Smoothing -->
        <div class="form-group row" *ngIf="target.target.smoothing">
            <h5 class="col-12" translate>SMOOTHING</h5>
            <div class="col-12">
                <span>{{ target.target.smoothing }}ms</span>
            </div>
        </div>
        <!-- RTMP -->
        <div *ngIf="target.rtmp">
            <!-- Channel -->
            <div class="form-group row" *ngIf="target.target.medialive_channel_id == null">
                <h5 class="col-12" translate>CHANNEL</h5>
                <div class="col-12 ellipsis">
                    <zx-delivery
                        [model]="target.target.deliveryChannel"
                        [showLink]="true"
                        [searchTerm]="searchTerm"
                        *ngIf="target.target.delivery_channel_id != null"
                    ></zx-delivery>
                    <span *ngIf="target.target.delivery_channel_id == null">-</span>
                </div>
            </div>
            <!-- MediaLive Channel -->
            <div class="form-group row" *ngIf="target.target.medialive_channel_id != null">
                <h5 class="col-12" translate>AWS_MEDIALIVE_CHANNEL</h5>
                <div class="col-12 ellipsis">
                    <zx-medialive-channel [model]="target.target.mediaLiveChannel" [showLink]="true" [searchTerm]="searchTerm"></zx-medialive-channel>
                </div>
            </div>
            <div class="form-group row" *ngIf="target.target.transcodingProfile">
                <h5 class="col-12" translate>TRANSCODING_PROFILE</h5>
                <div class="col-12">
                    <a [routerLink]="['/' + urls.transformation.transcoding_profiles, target.target.transcodingProfile.name]">
                        {{ target.target.transcodingProfile.name }}
                    </a>
                </div>
            </div>
            <!-- PID Mapping Profile -->
            <div class="form-group row" *ngIf="target.target?.pid_mapping && target.target?.pid_mapping?.name">
                <h5 class="col-12" translate>PID_MAPPING</h5>
                <div class="col-12">
                    <a [routerLink]="['/' + urls.transformation.pid_mapping_profiles, target.target.pid_mapping.name]">
                        {{ target.target.pid_mapping.name }}
                    </a>
                </div>
            </div>
            <!-- Target -->
            <div class="form-group row">
                <h5 class="col-12" translate>TARGET</h5>
                <div class="col-12">
                    <span>{{ target.target.target }}</span>
                </div>
            </div>
            <!-- Alt Target -->
            <div class="form-group row" *ngIf="target.target.alt_target">
                <h5 class="col-12" translate>ALT_TARGET</h5>
                <div class="col-12">
                    <span>{{ target.target.alt_target }}</span>
                </div>
            </div>
            <!-- Stream Name -->
            <div class="form-group row" *ngIf="target.target.stream_name">
                <h5 class="col-12" translate>STREAM_NAME</h5>
                <div class="col-12">
                    <span>{{ target.target.stream_name }}</span>
                </div>
            </div>
            <!-- Username -->
            <div class="form-group row" *ngIf="target.target.username">
                <h5 class="col-12" translate>USERNAME</h5>
                <div class="col-12">
                    <span>{{ target.target.username }}</span>
                </div>
            </div>
            <!-- Password -->
            <div class="form-group row" *ngIf="target.target.password">
                <h5 class="col-12" translate>PASSWORD</h5>
                <div class="col-12">
                    <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
                    ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
                    <a href="javascript:void(0)" (click)="copyString(target.target.password)">
                        {{ "COPY" | translate }}
                    </a>
                </div>
            </div>
            <!-- Bitrate -->
            <div class="form-group row">
                <h5 class="col-12" translate>BITRATE_KBPS</h5>
                <div class="col-12">
                    <span>{{ target.target.kbps || "Auto Detect" }}</span>
                </div>
            </div>
        </div>
        <!-- CDI -->
        <div *ngIf="target.cdi">
            <!-- MediaConnect Flow -->
            <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
                <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
                <div class="col-12 ellipsis">
                    <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true" [searchTerm]="searchTerm"></zx-mediaconnect-flow>
                </div>
            </div>
            <!-- MediaStreams -->
            <div class="form-group row" *ngIf="target.target.mediaStreams && target.target.mediaStreams.length > 0">
                <h5 class="col-12" translate>MEDIACONNECT_MEDIA_STREAMS</h5>
                <div class="col-12">
                    <span *ngFor="let strm of target.target.mediaStreams"> {{ strm.name }} ({{ strm.stream_type }})<br /> </span>
                </div>
            </div>
            <!-- Target -->
            <div class="form-group row">
                <h5 class="col-12" translate>TARGET</h5>
                <div class="col-12">
                    <span>{{ target.target.destination_ip }}:{{ target.target.destination_port }}</span>
                </div>
            </div>
        </div>
        <!-- JPEGXS -->
        <div *ngIf="target.jpegxs">
            <!-- MediaConnect Flow -->
            <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
                <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
                <div class="col-12 ellipsis">
                    <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true" [searchTerm]="searchTerm"></zx-mediaconnect-flow>
                </div>
            </div>
            <!-- MediaStreams -->
            <div class="form-group row" *ngIf="target.target.mediaStreams && target.target.mediaStreams.length > 0">
                <h5 class="col-12" translate>MEDIACONNECT_MEDIA_STREAMS</h5>
                <div class="col-12">
                    <span *ngFor="let strm of target.target.mediaStreams"> {{ strm.name }} ({{ strm.stream_type }}) :{{ strm.port }}<br /> </span>
                </div>
            </div>
            <!-- VPCs -->
            <div class="form-group row" *ngIf="target.target.vpc && target.target.vpc.length > 0">
                <h5 class="col-12" translate>VPC</h5>
                <div class="col-12">
                    <span *ngFor="let v of target.target.vpc"> {{ v.name }} - {{ v.destination_ip }}<br /></span>
                </div>
            </div>
        </div>
        <!-- MEDIALIVEHTTP -->
        <div *ngIf="target.medialive_http">
            <!-- Channel -->
            <div class="form-group row" *ngIf="target.target.medialive_channel_id == null">
                <h5 class="col-12" translate>CHANNEL</h5>
                <div class="col-12 ellipsis">
                    <zx-delivery
                        [model]="target.target.mediaLiveChannel"
                        [showLink]="true"
                        [searchTerm]="searchTerm"
                        *ngIf="target.target.medialive_channel_id != null"
                    ></zx-delivery>
                    <span *ngIf="target.target.medialive_channel_id == null">-</span>
                </div>
            </div>
            <!-- MediaLive Channel -->
            <div class="form-group row" *ngIf="target.target.medialive_channel_id != null">
                <h5 class="col-12" translate>AWS_MEDIALIVE_CHANNEL</h5>
                <div class="col-12 ellipsis">
                    <zx-medialive-channel [model]="target.target.mediaLiveChannel" [showLink]="true" [searchTerm]="searchTerm"></zx-medialive-channel>
                </div>
            </div>
            <!-- s3/mediastore URI -->
            <div
                class="form-group row"
                *ngIf="target.target.destination === 's3' || target.target.destination === 'mediastore' || target.target.destination === 'http'"
            >
                <h5 class="col-12" translate *ngIf="target.target.destination === 's3'">S3_URI</h5>
                <h5 class="col-12" translate *ngIf="target.target.destination === 'mediastore'">MEDIASTORE_URI</h5>
                <h5 class="col-12" translate *ngIf="target.target.destination === 'http'">HTTP_URI</h5>
                <div class="col-12">
                    <span>{{ target.target.ingest_url }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="target.type === 'entitlement'">
            <!-- Entitlement Arn -->
            <div class="form-group row" *ngIf="target.target.entitlement_arn">
                <h5 class="col-12" translate>ENTITLEMENT_ARN</h5>
                <div class="col-12">
                    <span>{{ target.target.entitlement_arn }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
