import { OnDestroy, Component } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-confirm-dialog",
    templateUrl: "./confirm-dialog.component.html"
})
export class ConfirmDialogComponent implements OnDestroy {
    action: string;
    type: string;
    name?: string;
    note?: string;
    warning?: string;
    checkbox?: string;
    checkboxNote?: string;
    deleteInput?: boolean;
    alternativeBodyText?: string;
    confirmHandler: (b?: boolean) => Promise<unknown>;
    saving: boolean;
    checkboxModel: boolean;
    deleteInputModel: boolean;

    private routeSubscription: Subscription;

    constructor(public activeModal: NgbActiveModal, private router: Router) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        const result = await this.confirmHandler(this.checkboxModel);
        if (result === false) {
            this.saving = false;
        } else {
            this.saving = false;
            this.activeModal.close();
        }
    }
}
