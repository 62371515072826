<app-table-list
    [data]="changes"
    tableName="object-tacking-sub"
    [displayTableName]="'CHANGES' | translate"
    [(tableSchema)]="tableColumnsSchema"
    [selectable]="false"
    [showFilter]="false"
    [showReport]="false"
    [showPagination]="false"
    [showExpandButton]="false"
    [showColumnsSelection]="false"
    [showSelectionCheckbox]="false"
    [hoverable]="false"
>
</app-table-list>
