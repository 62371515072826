<app-form-field-warper
    labelFor="targetBroadcasters"
    [isInvalid]="invalid"
    [labelText]="'TARGET_BROADCASTER/S' | translate"
    [isRestartTooltip]="isRestartRequiredTip"
    [isRequired]="true"
>
    <ng-select
        id="targetBroadcasters"
        name="targetBroadcasters"
        bindValue="id"
        bindLabel="name"
        placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
        [ngClass]="{ 'form-control is-invalid': invalid }"
        [items]="targetBXs"
        [clearable]="false"
        [loading]="targetBXsLoading"
        (change)="_onChange($event)"
        (focus)="markAsTouched()"
        [formControl]="ngControl.control"
        ><ng-container *ngIf="targetBXsLoading">
            <ng-template ng-label-tmp let-item="item"> {{ "LOADING" | translate }}... </ng-template>
        </ng-container>
        <ng-container *ngIf="!targetBXsLoading">
            <ng-template ng-label-tmp let-item="item">
                <span><zx-status-icon *ngIf="item.type" [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span><zx-status-icon *ngIf="item.type" [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
            </ng-template>
        </ng-container>
    </ng-select>
    <app-input-errors-indicator [errors]="ngControl.errors" [fieldName]="'TARGET_BROADCASTER/S' | translate" [invalid]="invalid"> </app-input-errors-indicator>
</app-form-field-warper>
