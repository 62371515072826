import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "version"
})
export class VersionPipe implements PipeTransform {
    transform(value: string): string {
        if (value !== "-") return value.substring(value.indexOf(".") + 1);
        else return value;
    }
}
