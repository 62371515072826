<ng-select
    name="{{ name }}"
    [items]="users"
    [loading]="loading"
    [multiple]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    bindLabel="name"
    placeholder="{{ 'SELECT' | translate }} {{ 'USERS' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="{{ required || false }}"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span *ngIf="item.name" class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span *ngIf="item.name" class="ng-value-label">{{ item.name }} ({{ item.email }})</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm"> {{ item.name }} ({{ item.email }}) </ng-template>
</ng-select>
