import { Component, Input } from "@angular/core";
import { Broadcaster } from "src/app/models/shared";

@Component({
    selector: "zx-broadcaster-status-col",
    template: `
        <zx-status-full
            [model]="broadcaster"
            [standby]="broadcaster.is_backup && !broadcaster.active"
            [showOtherIcons]="!broadcaster.is_deleted"
            [searchTerm]="searchTerm"
        ></zx-status-full>
        <fa-icon
            icon="wrench"
            size="sm"
            *ngIf="broadcaster.maintenance"
            title="{{ 'MAINTENANCE' | translate }}"
        ></fa-icon>
    `
})
export class ZxBroadcasterStatusColComponent {
    @Input() broadcaster: Broadcaster;
    @Input() searchTerm: string | string[];
}
