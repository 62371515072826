export class TypeStatusSummary {
    ok: number;
    error: number;
    warning: number;
    muted_error: number;
    muted_warning: number;
    acknowledged: number;
    other: number;
}

export class StatusSummary {
    feeders: TypeStatusSummary;
    broadcasters: TypeStatusSummary;
    receivers: TypeStatusSummary;
    sources: TypeStatusSummary;
    adaptive_channels: TypeStatusSummary;
    delivery_channels: TypeStatusSummary;
    publishing_targets: TypeStatusSummary;
    zixi_pull: TypeStatusSummary;
    udp_rtp: TypeStatusSummary;
    zixi_push: TypeStatusSummary;
    rtmp_push: TypeStatusSummary;
    remote_access: TypeStatusSummary;
}

export class OpenIssue {
    object_type: string;
    object_id: number;
    object_name: string;
    message: string;
    short_message: string;
    updated_at: string;
}
