import { ComponentRef } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "../../../models/shared";
import { ZxNgbHighlightComponent } from "./zx-ngb-highlight.component";

type stringCallback<T> = (row: T) => string;
type booleanCallback<T> = (row: T) => boolean;

export const assignNgbHighlightInputsFactory =
    <T = KeyMap<Base>>(
        titleCallback: stringCallback<T>,
        resultCallback: stringCallback<T>,
        showCallback: booleanCallback<T>
    ) =>
    (ComponentRef: ComponentRef<ZxNgbHighlightComponent>, row: T, searchTerm: string | string[]) => {
        const comp = ComponentRef.instance;
        comp.title = titleCallback(row);
        comp.result = resultCallback(row);
        comp.showTitle = showCallback(row);
        comp.searchTerm = searchTerm?.toString();
    };
