import { Component, Input } from "@angular/core";
import { ZixiObject } from "src/app/models/shared";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { Grid } from "../../grid";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-grid-content",
    templateUrl: "./grid-content.component.html"
})
export class GridContentComponent {
    @Input() grid: Grid;
    @Input() object: ZixiObject;
    @Input() popover?: boolean;
    @Input() overlay?: boolean;
    @Input() infoBtm?: boolean;
    @Input() searchTerm?: string | string[];

    constructor(private targetsService: TargetsService, private sharedService: SharedService) {}
}
