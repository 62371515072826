<div class="sub-content d-flex flex-column justify-content-center position-relative">
    <div class="h-100 d-flex flex-column justify-content-between">
        <div class="d-flex flex-column">
            <div class="thumbnail" #thumbnailContainer [ngClass]="{ 'border-0 mb-0': !bordered }">
                <div class="aspect-ratio-fix" [ngStyle]="{ 'background-color': initialized && !livePlayer ? 'black' : 'transparent' }">
                    <div class="img-container">
                        <spinner-animation *ngIf="loading"></spinner-animation>
                        <img src="../../../../../assets/images/thumb-stream.png" alt="{{ source ? source.name : 'preview' }}-thumbnail" *ngIf="loading" />
                        <img
                            #thumbnail
                            [src]="imgSource"
                            alt="{{ source ? source.name : 'preview' }}-thumbnail"
                            (load)="onImgLoad()"
                            *ngIf="!loading && !livePlayer"
                            [ngClass]="{ superwide: superWide }"
                        />
                        <video id="videoElement" [ngClass]="{ superwide: superWide }" *ngIf="livePlayer"></video>
                    </div>
                </div>
                <div *ngIf="source && !livePlayer" class="overlay-text" [hidden]="!overlay && !isSmall" [ngClass]="{ 'top-0': !info, isSmall: isSmall }">
                    <div class="alert-container" [ngClass]="{ 'mb-0': !info }">
                        <div class="alert-container-inner">
                            <div
                                [hidden]="!overlay"
                                *ngFor="let activeState of source.activeStates"
                                class="alert"
                                [ngClass]="activeState.type === 'error' ? 'alert-danger' : 'alert-warning'"
                            >
                                <strong>{{ activeState.short_message }}</strong>
                            </div>
                            <div [hidden]="!overlay" class="alert alert-light mb-0" role="alert" *ngIf="source.is_enabled && source.state === 'pending'">
                                <strong>{{ "OFFLINE" | translate }}</strong>
                            </div>
                            <div class="alert alert-light mb-0" role="alert" *ngIf="!source.is_enabled">
                                <strong>{{ "DISABLED" | translate }}</strong>
                            </div>
                            <div [hidden]="!overlay" class="alert alert-warning mb-0" role="alert" *ngIf="hasDecodeErrorMsg">
                                <strong>{{ hasDecodeErrorMsg }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay-audio" [hidden]="loading || !hasAudioLevels || !overlay || livePlayer" [ngClass]="{ horizontal: isHorizontal }">
                    <canvas #overlayAudio></canvas>
                </div>
                <div class="overlay-timestamp" *ngIf="!loading && pts" [hidden]="!overlay || livePlayer">
                    {{ pts / 27000 | timestamp }}
                </div>
                <div class="overlay-bitrate" *ngIf="source && !loading && source.status && source.status?.bitrate" [hidden]="!bitrateOverlay || livePlayer">
                    {{ source.status?.bitrate | number : "1.0-0" }} kbps
                </div>
            </div>
            <div class="playButtonsOverlay" *ngIf="(canAccountLivePlay$ | async) && canLivePlay && allowLivePlay && !source.hide_thumbnail">
                <div class="btn-group btn-group-sm">
                    <button
                        *ngIf="!livePlayer"
                        type="button"
                        class="btn btn-sm btn-outline-secondary w-auto"
                        title="{{ 'PLAY_INLINE' | translate }}"
                        (click)="playStream()"
                    >
                        <fa-icon icon="play" size="sm" class="me-1"></fa-icon>{{ "PLAY_INLINE" | translate }}
                    </button>
                    <button
                        *ngIf="livePlayer"
                        type="button"
                        class="btn btn-sm btn-outline-secondary w-auto"
                        title="{{ 'STOP' | translate }}"
                        (click)="stopStream()"
                    >
                        <fa-icon icon="stop" size="sm" class="me-1"></fa-icon>{{ "STOP" | translate }}
                    </button>
                </div>
            </div>
            <div class="videoErrorOverlay" *ngIf="videoErrorMsg && allowLivePlay && canLivePlay">
                <ngb-alert [dismissible]="false" [type]="'info'" class="mb-0">
                    {{ videoErrorMsg }}
                </ngb-alert>
            </div>
        </div>
    </div>
</div>
