import { Component, Input } from "@angular/core";

@Component({
    selector: "zx-threshold-number",
    templateUrl: "./zx-threshold-number.component.html",
    styleUrls: ["./zx-threshold-number.component.scss"]
})
export class ZxThresholdNumberComponent {
    @Input() value: number;
    @Input() append: string;
    @Input() label: string;
}
