<ng-container>
    <h6 class="title" *ngIf="details.title !== ('THUMBNAIL' | translate)">
        {{ details.title }}&nbsp;<fa-icon
            *ngIf="details.title === ('HEALTH' | translate)"
            icon="info-circle"
            [ngbTooltip]="HealthContent"
            triggers="hover"
        ></fa-icon>
        <ng-template #HealthContent>
            <p class="mb-0">
                {{ "TOOLTIP.HEALTH_SCORE" | translate }}
            </p>
        </ng-template>
    </h6>

    <ng-container *ngIf="!specialCase">
        <a [href]="details.link" *ngIf="details.link" target="_blank">
            <fa-icon *ngIf="details.fa_icon" [icon]="details.fa_icon" size="sm" class="me-1"></fa-icon>
            <span [class]="details.statusClass" [ngClass]="{ boldContent: isBoldContent }" title="{{ details.content }}">{{ details.content }}</span
            ><a *ngIf="details.canCopy" class="ms-1" href="javascript:void(0)" title="{{ 'COPY' | translate }}" (click)="copyPassword()"
                ><fa-icon icon="clipboard" size="sm"></fa-icon
            ></a>
        </a>

        <ng-container *ngIf="details.password">
            <a href="javascript:void(0)" (click)="showAuthPassword = true" *ngIf="!showAuthPassword">{{ "SHOW" | translate }}</a>
            <span *ngIf="showAuthPassword" [ngClass]="{ boldContent: isBoldContent }">{{ details.content }}</span
            ><a class="ms-1" *ngIf="showAuthPassword" href="javascript:void(0)" title="{{ 'COPY_PASSWORD' | translate }}" (click)="copyPassword()"
                ><fa-icon icon="clipboard" size="sm"></fa-icon></a
            ><a class="ms-1" href="javascript:void(0)" (click)="showAuthPassword = false" *ngIf="showAuthPassword">{{ "HIDE" | translate }}</a>
        </ng-container>

        <ng-container *ngIf="!details.link && !details.password">
            <fa-icon *ngIf="details.fa_icon" [icon]="details.fa_icon" size="sm" class="me-1"></fa-icon>
            <zx-status-icon *ngIf="details.status" [model]="details.status" [status]="details.status" class="me-1"></zx-status-icon>
            <span [class]="details.statusClass" [ngClass]="{ boldContent: isBoldContent }" title="{{ details.content }}">{{ details.content }}</span
            ><span *ngIf="details.unit && details.content !== '-'" class="unit">{{ details.unit }}</span
            ><a *ngIf="details.canCopy" class="ms-1" href="javascript:void(0)" title="{{ 'COPY' | translate }}" (click)="copyPassword()"
                ><fa-icon icon="clipboard" size="sm"></fa-icon
            ></a>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="details.title === ('THUMBNAIL' | translate)">
        <app-source-thumbnail-from-service
            [ngbTooltip]="ThumbnailContent"
            tooltipClass="hasThumbnail"
            triggers="hover click"
            container="body"
            [autoClose]="'outside'"
            [source]="details.object"
            [bordered]="false"
            [info]="false"
            [overlay]="false"
            [isSmall]="true"
        ></app-source-thumbnail-from-service>
        <ng-template #ThumbnailContent>
            <app-source-thumbnail-from-service
                [source]="details.object"
                [bordered]="false"
                [info]="false"
                [overlay]="false"
            ></app-source-thumbnail-from-service>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="details.title === ('HEALTH' | translate)">
        <zx-source-health [source]="details.object"></zx-source-health>
    </ng-container>

    <ng-container *ngIf="details.title === ('PLAY' | translate)">
        <div class="d-flex flex-row">
            <!-- Play -->
            <div class="form-group mb-0 me-2" *ngIf="details.object.webrtc_mode !== ''">
                <div>
                    <a
                        [routerLink]="['/' + urls.sources, details.object.id | uId, details.object?.name, 'webrtc']"
                        target="_blank"
                        class="btn btn-sm btn-xs btn-play btn-primary"
                        ><fa-icon icon="play" size="xs" class="me-1"></fa-icon>{{ "WEBRTC" | translate }}</a
                    >
                </div>
            </div>
            <div class="form-group mb-0" *ngIf="details.object.view_url && canVLC">
                <div>
                    <a href="/play/source/{{ details.object.playId }}" target="_blank" class="btn btn-sm btn-xs btn-play btn-primary"
                        ><fa-icon icon="play" size="xs" class="me-1"></fa-icon>{{ "VLC" | translate }}</a
                    >
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('API_CREDENTIALS' | translate)">
        <a href="javascript:void(0)" (click)="showPassword = true" *ngIf="!showPassword">{{ "SHOW" | translate }}</a>
        <span *ngIf="!showPassword">&nbsp;/&nbsp;</span>
        <a href="javascript:void(0)" (click)="copyPassword()" *ngIf="!showPassword">{{ "COPY_PASSWORD" | translate }}</a>
        <span *ngIf="showPassword" [ngClass]="{ boldContent: isBoldContent }"
            >{{ details.object.api_user }}
            <a href="javascript:void(0)" title="{{ 'COPY_USER' | translate }}" (click)="copy(details.object.api_user)"
                ><fa-icon icon="clipboard" size="sm"></fa-icon
            ></a>
            / {{ details.object.api_password }}
            <a href="javascript:void(0)" title="{{ 'COPY_PASSWORD' | translate }}" (click)="copyPassword()"><fa-icon icon="clipboard" size="sm"></fa-icon></a
            ><app-password-strength-icon class="d-inline-block ms-1" [password]="details.object.api_password"></app-password-strength-icon></span
        ><a class="ms-1" href="javascript:void(0)" (click)="showPassword = false" *ngIf="showPassword">{{ "HIDE" | translate }}</a>
    </ng-container>

    <ng-container *ngIf="details.title === ('SSH_KEY' | translate)">
        <span *ngIf="details.object.accessKey.readOnly">{{ details.object.accessKey.name }}</span>
        <span *ngIf="!details.object.accessKey.readOnly">{{ details.object.accessKey.name }}</span>
        <a
            *ngIf="!details.object.accessKey.readOnly && details.object.accessKey.hasRsaPrivate"
            href="/api/access_keys/{{ details.object.accessKey.id }}/download"
        >
            <fa-icon icon="download" size="sm"></fa-icon>
            {{ details.object.accessKey.name }}
        </a>
    </ng-container>

    <ng-container *ngIf="details.title === ('ELASTIC_IPS' | translate)">
        <span>{{ details.content }}</span
        ><span class="ms-1" *ngIf="details.showTooltip">
            <fa-icon icon="exclamation-circle" class="status-warning" [ngbTooltip]="infoTooltipRef" triggers="hover click" [closeDelay]="500"></fa-icon>
            <ng-template #infoTooltipRef>{{ "TOOLTIP.NO_MORE_ELASTIC_IP" | translate }}</ng-template>
        </span>
    </ng-container>

    <ng-container *ngIf="details.title === ('LOCATION' | translate)">
        <ng-container *ngIf="(details.object?.location.address || details.object?.location.ip)?.display_name">
            <div class="d-flex align-items-center">
                <img
                    *ngIf="(details.object?.location.address || details.object?.location.ip)?.country_flag"
                    src="{{ (details.object?.location.address || details.object?.location.ip)?.country_flag }}"
                    class="svg-country-icon"
                />
                <span class="ellipsis maxw-240px" title="{{ (details.object?.location.address || details.object?.location.ip)?.display_name }}">{{
                    (details.object?.location.address || details.object?.location.ip)?.display_name
                }}</span>
            </div>
        </ng-container>
        <ngcontainer *ngIf="!(details.object?.location.address || details.object?.location.ip)?.display_name">-</ngcontainer>
    </ng-container>

    <ng-container *ngIf="details.title === ('INGEST_CLUSTER' | translate)">
        <div class="ellipsis" [ngClass]="{ boldContent: isBoldContent }">
            <div class="d-inline" *ngIf="details.object?.target_broadcaster_id > 0 || details.object?.activeBroadcasterObjects?.bx_id">
                <zx-broadcaster
                    [broadcasterId]="
                        details.object?.target_broadcaster_id > 0 ? details.object?.target_broadcaster_id : details.object?.activeBroadcasterObjects?.bx_id
                    "
                    [clusterId]="details.object?.broadcaster_cluster_id"
                    [showTag]="false"
                    [showLink]="true"
                    [showCluster]="false"
                    [showStatusIcon]="true"
                ></zx-broadcaster>
                @
            </div>
            <zx-cluster
                class="d-inline-block"
                [model]="details.object?.inputCluster"
                [showOtherIcons]="false"
                [showStatusIcon]="false"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
            ></zx-cluster>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('PROCESSING_CLUSTER' | translate)">
        <div class="ellipsis" [ngClass]="{ boldContent: isBoldContent }">
            <zx-cluster
                [model]="details.object"
                [showOtherIcons]="true"
                [showStatusIcon]="true"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
            ></zx-cluster>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('ACTIVE_BROADCASTER' | translate)">
        <div class="ellipsis" [ngClass]="{ boldContent: isBoldContent }">
            <zx-broadcaster
                [model]="details.object.status?.active_broadcaster"
                [showTag]="false"
                [showLink]="true"
                [showCluster]="false"
                [showStatusIcon]="false"
                [clusterId]="details.object.broadcaster_cluster_id"
            ></zx-broadcaster>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('TARGET_BROADCASTER_S' | translate)">
        <div class="ellipsis" [ngClass]="{ boldContent: isBoldContent }">
            <span *ngIf="details.object.target_broadcaster_id === 0">{{ "ANY" | translate }}</span>
            <span *ngIf="details.object.target_broadcaster_id === -1">{{ "PREFER_PRIMARY_BROADCASTERS" | translate }}</span>
            <span *ngIf="details.object.target_broadcaster_id === -2">{{ "PRIMARY_BROADCASTERS_ONLY" | translate }}</span>
            <span *ngIf="details.object.target_broadcaster_id === -3">{{ "BACKUP_BROADCASTERS_ONLY" | translate }}</span>
            <div *ngIf="details.object.target_broadcaster_id > 0">
                <zx-broadcaster
                    *ngIf="details.broadcaster | async as bxModel; else loading"
                    [model]="bxModel"
                    [showTag]="false"
                    [showLink]="true"
                    [showCluster]="false"
                    [showStatusIcon]="true"
                    [clusterId]="details.object.broadcaster_cluster_id"
                ></zx-broadcaster>
                <ng-template #loading>
                    <spinner-animation-inline></spinner-animation-inline>
                </ng-template>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('ALTERNATIVE_CHANNEL' | translate)">
        <div class="ellipsis" [ngClass]="{ boldContent: isBoldContent }">
            <zx-adaptive *ngIf="details.object.type === 'adaptive'" [model]="details.object.altChannel" [showLink]="true" [showTag]="false"></zx-adaptive>
            <zx-delivery *ngIf="details.object.type === 'delivery'" [model]="details.object.altChannel" [showLink]="true" [showTag]="false"></zx-delivery>
            <zx-failover-channel
                *ngIf="details.object.type === 'failover'"
                [model]="details.object.altChannel"
                [showLink]="true"
                [showTag]="false"
            ></zx-failover-channel>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('ALTERNATIVE_CLUSTER' | translate)">
        <div class="ellipsis" [ngClass]="{ boldContent: isBoldContent }">
            <zx-cluster
                [model]="details.object"
                [showOtherIcons]="true"
                [showStatusIcon]="true"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
            ></zx-cluster>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('AWS_MEDIACONNECT_FLOW' | translate)">
        <div class="ellipsis" [ngClass]="{ boldContent: isBoldContent }">
            <zx-mediaconnect-flow
                *ngIf="!details.link"
                [model]="details.object"
                [showStatusIcon]="true"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
            ></zx-mediaconnect-flow>
            <a [href]="details.link" *ngIf="details.link" target="_blank">
                <fa-icon *ngIf="details.fa_icon" [icon]="details.fa_icon" size="sm" class="me-1"></fa-icon>
                <span [class]="details.statusClass" [ngClass]="{ boldContent: isBoldContent }" title="{{ details.content }}">{{ details.content }}</span>
            </a>
        </div>
    </ng-container>

    <ng-container *ngIf="details.title === ('INPUT' | translate)">
        <zx-feeder *ngIf="details.object?.feeder_id" [model]="details.object?.feeder" [showTag]="true" [showLink]="true" [showStatusIcon]="false"></zx-feeder>
        <zx-broadcaster
            *ngIf="details.object?.broadcaster_id"
            [model]="details.object?.broadcaster"
            [showTag]="true"
            [showLink]="true"
            [showCluster]="false"
            [showStatusIcon]="false"
        ></zx-broadcaster>
        <span *ngIf="details.object?.input_id && details.object?.type !== 'zixi_pull' && details.object?.type !== 'rtmp'"
            ><span *ngIf="details.object?.broadcaster_id || details.object?.feeder_id">&nbsp;/&nbsp;</span>{{ details.object?.input_id
            }}<span *ngIf="details.object?.Source">&nbsp;/&nbsp;</span></span
        >
        <span *ngIf="details.object?.elemental_link_id">{{ "ELEMENTAL_LINK" | translate }}&nbsp;/&nbsp;{{ details.object?.elemental_link_id }}</span>
        <span *ngFor="let failoverSource of details.object?.failoverSources; let isLast = last"
            >{{ failoverSource.source.name }}{{ isLast ? "" : ",&nbsp;" }}</span
        >
        <span *ngIf="details.object?.transcode_profile_id && details.object?.transcodeSource && details.object?.transcodeSource.name">
            {{ details.object?.transcodeSource.name }}
        </span>
        <zx-source *ngIf="details.object?.Source" [model]="details.object?.Source" [showLink]="true" [showTag]="false"></zx-source>
        <span *ngIf="details.object?._frontData.input_description">
            {{ details.object?._frontData.input_description }}
        </span>
    </ng-container>

    <ng-container *ngIf="details.title === ('TAGS' | translate) || details.title === ('CLUSTER_TAGS' | translate)">
        <span class="tags-container">
            <span *ngFor="let tag of details.tags; index as i">
                <span *ngIf="i < 3" class="badge badge-primary me-1" title="{{ tag.name }}">{{ tag.name }}</span>
            </span>
            <span *ngIf="details.tags.length > 3" class="text-primary position-relative top-1px">
                <a href="javascript:void(0)" title="{{ 'VIEW_TAGS' | translate }}" (click)="openTags(details)">+{{ details.tags.length - 3 }}</a>
            </span>
            <span *ngIf="details.tags.length <= 3 && details.canEdit" class="text-primary position-relative top-1px">
                <a href="javascript:void(0)" title="{{ 'EDIT_TAGS' | translate }}" (click)="openTags(details)">+<fa-icon icon="pencil" size="xs"></fa-icon></a>
            </span>
            <span *ngIf="details.tags.length === 3 && !details.canEdit" class="text-primary position-relative top-1px">
                <a href="javascript:void(0)" title="{{ 'VIEW_TAGS' | translate }}" (click)="openTags(details)">+<fa-icon icon="eye" size="xs"></fa-icon></a>
            </span>
        </span>
    </ng-container>
</ng-container>
