<ng-container>
    <zx-adaptive [model]="model.adaptiveChannel" [showLink]="true" [searchTerm]="searchTerm" *ngIf="model.adaptiveChannel"></zx-adaptive>
    <zx-delivery
        [model]="model.deliveryChannel"
        [showLink]="true"
        [searchTerm]="searchTerm"
        *ngIf="model.mediaconnect_flow_id == null && model.deliveryChannel"
    ></zx-delivery>
    <zx-mediaconnect-flow
        [model]="model.mediaconnectFlow"
        [showLink]="true"
        [searchTerm]="searchTerm"
        *ngIf="model.mediaconnect_flow_id != null"
    ></zx-mediaconnect-flow>
    <zx-medialive-channel
        [model]="model.mediaLiveChannel"
        [showLink]="true"
        [searchTerm]="searchTerm"
        *ngIf="model.medialive_channel_id != null"
    ></zx-medialive-channel>
    <span *ngIf="!model.medialive_channel_id && !model.mediaconnect_flow_id && !model.deliveryChannel && !model.adaptiveChannel">-</span>
</ng-container>
