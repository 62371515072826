<div class="ellipsis d-inline" *ngIf="model && model.name">
    <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'SOURCE' | translate }}">S</span>
    <zx-status-full
        [icon]="showStatusIcon"
        [model]="model"
        [status]="status || (model.generalStatus | statusClass)"
        [showStatusText]="showStatusText"
        [showOtherIcons]="showOtherIcons"
    ></zx-status-full>
    <a *ngIf="showLink" [routerLink]="['/' + urls.sources, 'mediaconnect', model.name]" (click)="$event.stopPropagation()"
        ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
        <span *ngIf="model.mediaconnectFlow && model.mediaconnectFlow.name && showCluster">
            @ <ngb-highlight title="{{ model.mediaconnectFlow.name }}" [result]="model.mediaconnectFlow.name" [term]="searchTerm"></ngb-highlight></span
    ></a>
    <span *ngIf="!showLink && !showClusterLink"
        ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="model.mediaconnectFlow && model.mediaconnectFlow.name && showCluster">
            @ <ngb-highlight title="{{ model.mediaconnectFlow.name }}" [result]="model.mediaconnectFlow.name" [term]="searchTerm"></ngb-highlight></span
    ></span>
    <span *ngIf="!showLink && showClusterLink"
        ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="model.mediaconnectFlow && model.mediaconnectFlow.name && showCluster">
            @
            <a [routerLink]="['/' + urls.channels, 'mediaconnect', model.id | uId, model.mediaconnectFlow.name]" (click)="$event.stopPropagation()"
                ><ngb-highlight title="{{ model.mediaconnectFlow.name }}" [result]="model.mediaconnectFlow.name" [term]="searchTerm"></ngb-highlight></a></span
    ></span>
</div>
<div *ngIf="!model || !model.name">-</div>
