import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { firstValueFrom, Subscription } from "rxjs";
import { ClustersService } from "../../../pages/clusters/clusters.service";
import { Cluster } from "../../../pages/clusters/cluster";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "zx-cluster-select",
    templateUrl: "./zx-cluster-select.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ZxClusterSelectComponent),
            multi: true
        }
    ]
})
export class ZxClusterSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clusterList?: Cluster[];
    @Input() clearable?: boolean;
    @Input() exclude?: number[];
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    clusters: Cluster[];
    private clustersSubscription: Subscription;

    constructor(private cs: ClustersService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    async ngOnInit() {
        this.loading = true;
        if (!this.clearable) this.clearable = false;

        if (!this.clusterList) {
            await firstValueFrom(this.cs.refreshClusters());
            this.clustersSubscription = this.cs.clusters.subscribe(clusters => {
                if (this.exclude) {
                    this.clusters = clusters.filter(c => !this.exclude.includes(c.id));
                } else {
                    this.clusters = clusters;
                }
                this.loading = false;
            });
        } else {
            this.clusters = [...this.clusterList];
            this.loading = false;
        }
    }

    _onChange: () => void;
    _onTouched: () => void;

    writeValue(): void {
        return;
    }

    registerOnChange(onChange: () => void): void {
        this._onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this._onTouched = onTouched;
    }

    ngOnDestroy() {
        this.clustersSubscription?.unsubscribe();
    }
}
