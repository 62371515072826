<app-table-list
    [tableName]="'source-sources'"
    [displayTableName]="'SOURCES' | translate"
    [data]="sources$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    (currentSortDirection)="onSort($event)"
    [showReport]="false"
    [showPagination]="true"
    [showFilter]="false"
    [selectable]="false"
    [hoverable]="false"
    [showColumnsSelection]="false"
    [autoRows]="autoRows"
>
</app-table-list>
