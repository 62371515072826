import { Directive, Input, ViewContainerRef } from "@angular/core";
import { KeyMap } from "src/app/models/shared";
import { TableSchema } from "./table-list.component";

/**
 * Used in conjunction with ng-container to create a ref for injecting components that accept the appContainerData input
 * Without it, we couldn't find a suitable way to share data with a viewRef from an ng-form
 */
@Directive({
    selector: "[appContainerData]"
})
export class ContainerDataDirective {
    @Input() appContainerData: {
        column: TableSchema;
        row: KeyMap<unknown>;
    };

    constructor(public viewContainerRef: ViewContainerRef) {}
}
