import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { TranscodingProfile } from "./transcoding-profile";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";
import { APIResponse, KeyMap } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class TranscodingProfilesService {
    transcodingProfiles: Observable<TranscodingProfile[]>;
    private transcodingProfiles$: ReplaySubject<TranscodingProfile[]>;
    private dataStore: {
        transcodingProfiles: TranscodingProfile[];
    };

    constants = Constants;

    private lastTranscodingProfilesRefresh: number;
    private lastTranscodingProfileRefresh: number;

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            transcodingProfiles: []
        };

        this.lastTranscodingProfilesRefresh = null;
        this.lastTranscodingProfileRefresh = null;

        this.transcodingProfiles$ = new ReplaySubject(1) as ReplaySubject<TranscodingProfile[]>;
        this.transcodingProfiles = this.transcodingProfiles$.asObservable();
    }

    prepTranscodingProfile(transcodingProfile: TranscodingProfile) {
        transcodingProfile._frontData = {
            videoOrder: "",
            audioOrder: "",
            prettyAudioSampleRate: null
        };

        // Video
        transcodingProfile._frontData.videoOrder += transcodingProfile.do_video ? "1" : "0";
        transcodingProfile._frontData.videoOrder += transcodingProfile.keep_video ? "1" : "0";
        transcodingProfile._frontData.videoOrder += transcodingProfile.width ? transcodingProfile.width : "0";
        transcodingProfile._frontData.videoOrder += transcodingProfile.height ? transcodingProfile.height : "0";
        transcodingProfile._frontData.videoOrder += transcodingProfile.bitrate_avg
            ? transcodingProfile.bitrate_avg
            : "0";

        if (transcodingProfile.do_video) transcodingProfile._frontData.videoCodecOrder = transcodingProfile.video_codec;

        // Audio
        transcodingProfile._frontData.audioOrder += transcodingProfile.do_audio ? "1" : "0";
        transcodingProfile._frontData.audioOrder += transcodingProfile.keep_audio ? "1" : "0";
        transcodingProfile._frontData.audioOrder += transcodingProfile.audio_bitrate
            ? transcodingProfile.audio_bitrate
            : "0";

        if (transcodingProfile.do_audio) transcodingProfile._frontData.audioCodecOrder = transcodingProfile.audio_codec;

        // Audio Sample Rate
        if (transcodingProfile.audio_sample_rate)
            transcodingProfile._frontData.prettyAudioSampleRate = _.find(Constants.audioSampleRates, {
                value: transcodingProfile.audio_sample_rate
            });

        return transcodingProfile;
    }

    private updateStore(transcodingProfile: TranscodingProfile, merge: boolean): void {
        this.prepTranscodingProfile(transcodingProfile);

        const currentIndex = this.dataStore.transcodingProfiles.findIndex(g => g.id === transcodingProfile.id);
        if (currentIndex === -1) {
            this.dataStore.transcodingProfiles.push(transcodingProfile);
            return;
        } else if (merge) {
            const current = this.dataStore.transcodingProfiles[currentIndex];

            Object.assign(current, transcodingProfile);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.transcodingProfiles[currentIndex] = transcodingProfile;
        }
    }

    refreshTranscodingProfiles(force?: boolean): Observable<TranscodingProfile[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastTranscodingProfilesRefresh <= 60000) return this.transcodingProfiles;
        this.lastTranscodingProfilesRefresh = _.now();

        const transcodingProfiles$ = this.http
            .get<APIResponse<TranscodingProfile[]>>(Constants.apiUrl + Constants.apiUrls.profiles)
            .pipe(share());

        transcodingProfiles$.subscribe(
            data => {
                const transcodingProfiles: TranscodingProfile[] = data.result;

                this.dataStore.transcodingProfiles.forEach((existing, existingIndex) => {
                    const newIndex = transcodingProfiles.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.transcodingProfiles.splice(existingIndex, 1);
                });

                transcodingProfiles.forEach(tp => this.updateStore(tp, true));

                this.transcodingProfiles$.next(Object.assign({}, this.dataStore).transcodingProfiles);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );
        return transcodingProfiles$.pipe(map(r => r.result));
    }

    refreshTranscodingProfile(val: string | number, force?: boolean): Observable<TranscodingProfile> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastTranscodingProfileRefresh <= 60000) {
            return new Observable((observe: Subscriber<TranscodingProfile>) => {
                if (typeof val === "number") observe.next(this.dataStore.transcodingProfiles.find(tp => tp.id === val));
                else observe.next(this.dataStore.transcodingProfiles.find(tp => tp.name === val));
                observe.complete();
            });
        }
        this.lastTranscodingProfileRefresh = _.now();

        const id: number =
            typeof val === "number"
                ? val
                : this.dataStore.transcodingProfiles.find(transcodingProfile => transcodingProfile.name === val).id;
        const transcodingProfile$ = this.http
            .get<APIResponse<TranscodingProfile>>(Constants.apiUrl + Constants.apiUrls.profiles + "/" + id)
            .pipe(share());

        transcodingProfile$.subscribe(
            data => {
                const transcodingProfile: TranscodingProfile = data.result;
                transcodingProfile.hasFullDetails = true;

                this.updateStore(transcodingProfile, false);

                this.transcodingProfiles$.next(Object.assign({}, this.dataStore).transcodingProfiles);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILE"), error)
        );
        return transcodingProfile$.pipe(map(r => r.result));
    }

    getCachedTranscodingProfile(profile?: string, id?: number) {
        if (this.dataStore.transcodingProfiles && profile)
            return this.dataStore.transcodingProfiles.find(tp => tp.name === profile);
        if (this.dataStore.transcodingProfiles && id)
            return this.dataStore.transcodingProfiles.find(tp => tp.id === id);
        return undefined;
    }

    async addTranscodingProfile(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<APIResponse<TranscodingProfile>>(Constants.apiUrl + Constants.apiUrls.profiles, model)
                .toPromise();
            const transcodingProfile: TranscodingProfile = result.result;

            this.updateStore(transcodingProfile, false);

            this.transcodingProfiles$.next(Object.assign({}, this.dataStore).transcodingProfiles);
            return transcodingProfile;
        } catch (error) {
            return false;
        }
    }

    async deleteTranscodingProfile(transcodingProfile: TranscodingProfile) {
        try {
            const result = await this.http
                .delete<APIResponse<number>>(
                    Constants.apiUrl + Constants.apiUrls.profiles + "/" + `${transcodingProfile.id}`
                )
                .toPromise();

            const deletedId = result.result;
            const index = this.dataStore.transcodingProfiles.findIndex(tp => tp.id === deletedId);
            if (index !== -1) this.dataStore.transcodingProfiles.splice(index, 1);
            this.transcodingProfiles$.next(Object.assign({}, this.dataStore).transcodingProfiles);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateTranscodingProfile(transcodingProfile: TranscodingProfile, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<APIResponse<TranscodingProfile>>(
                    Constants.apiUrl + Constants.apiUrls.profiles + "/" + `${transcodingProfile.id}`,
                    model
                )
                .toPromise();

            this.updateStore(transcodingProfile, true);

            this.transcodingProfiles$.next(Object.assign({}, this.dataStore).transcodingProfiles);
            return result.success;
        } catch (error) {
            return false;
        }
    }

    getVideoColumnTextByRow(transcodingProfile: KeyMap<TranscodingProfile>): string {
        let input = "";
        if (transcodingProfile.do_video && !transcodingProfile.source_resolution) {
            input += `${transcodingProfile.width}x${transcodingProfile.height}`;
        }
        if (!transcodingProfile.do_video && (transcodingProfile.keep_video || transcodingProfile.source_resolution)) {
            input += this.translate.instant("ORIGINAL");
        }

        if (!transcodingProfile.do_video && !transcodingProfile.keep_video && !transcodingProfile.source_resolution) {
            input += this.translate.instant("REMOVE");
        }
        if (transcodingProfile.do_video && !transcodingProfile.keep_video) {
            if (!transcodingProfile.width && transcodingProfile.width !== 0) {
                input += this.translate.instant("SOURCE");
            }
            input += ` @ ${transcodingProfile.bitrate_avg} kbps`;
        }
        return input;
    }

    getAudioColumnTextByRow(transcodingProfile: KeyMap<TranscodingProfile>): string {
        if (transcodingProfile.do_audio) return `${transcodingProfile.audio_bitrate} kbps`;
        if (transcodingProfile.keep_audio) return this.translate.instant("ORIGINAL");
        return this.translate.instant("REMOVE");
    }

    getAudioCodecColumnTextByRow(transcodingProfile: KeyMap<TranscodingProfile>): string {
        return this.translate.instant(transcodingProfile.audio_codec === "aac" ? "AAC" : "DOLBY_DIGITAL_PLUS");
    }

    getVideoCodecColumnTextByRow(transcodingProfile: KeyMap<TranscodingProfile>): string {
        return this.translate.instant(transcodingProfile.video_codec === "h264" ? "H.264" : "H.265");
    }

    getEncodingProfileColumnTextByRow(transcodingProfile: KeyMap<TranscodingProfile>): string {
        return transcodingProfile.video_codec === "h264"
            ? this.constants.videoProfiles[transcodingProfile.encoding_profile]
            : transcodingProfile.video_codec === "h265"
            ? this.constants.videoProfilesH265[transcodingProfile.encoding_profile]
            : "";
    }

    getResolutionColumnTextByRow(transcodingProfile: KeyMap<TranscodingProfile>): string {
        return transcodingProfile.width
            ? transcodingProfile.width + "x" + transcodingProfile.height
            : !transcodingProfile.width && transcodingProfile.width !== 0
            ? this.translate.instant("USE_SOURCE_RESOLUTION")
            : transcodingProfile.width === 0 && transcodingProfile.source_resolution === 0
            ? "N/A"
            : "";
    }
}
