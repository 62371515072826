import { Component, OnChanges, OnInit, Input, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { AdaptiveChannel, ChannelTypes, DeliveryChannel, FailoverChannel, MediaLiveChannel } from "../../channel";
import { Constants } from "src/app/constants/constants";
import { Broadcaster, MediaConnectFlow, Source } from "src/app/models/shared";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { TagsService } from "src/app/pages/configuration/tags/tags.service";
import { UrlBuilderService } from "src/app/services/url-builder.service";
import { ChannelsService } from "../../channels.service";

@Component({
    selector: "app-channel-details",
    templateUrl: "./channel-details.component.html"
})
export class ChannelDetailsComponent implements OnChanges, OnInit {
    @Input() channel: ChannelTypes;

    fileUrl: SafeResourceUrl;
    adaptiveChannel: AdaptiveChannel;
    passThroughChannel: DeliveryChannel;
    failoverChannel: FailoverChannel;
    mediaConnectFlow: MediaConnectFlow;
    primarySources = 0;
    backupSources = 0;
    mediaLiveChannel: MediaLiveChannel;
    urls = Constants.urls;

    targetBroadcaster: Observable<Broadcaster>;

    protocols: string[];

    constructor(
        private urlBuilderService: UrlBuilderService,
        private sanitizer: DomSanitizer,
        public translate: TranslateService,
        private bx: BroadcastersService,
        private tagsService: TagsService,
        public cs: ChannelsService
    ) {}

    ngOnInit() {
        if (this.channel.type === "delivery") {
            this.primarySources = 0;
            this.backupSources = 0;
            for (const src of (this.channel as DeliveryChannel).sources) {
                if (src.primary) this.primarySources++;
                else this.backupSources++;
            }
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.channel) {
            this.adaptiveChannel = changes.channel.currentValue;
            this.passThroughChannel = changes.channel.currentValue;
            this.failoverChannel = changes.channel.currentValue;
            this.mediaConnectFlow = changes.channel.currentValue;
            this.mediaLiveChannel = changes.channel.currentValue;

            if (this.channel.adaptive) {
                this.protocols = [];

                if (!this.channel.disable_hls) this.protocols.push(this.translate.instant("HLS"));
                if (!this.channel.disable_cmaf) this.protocols.push(this.translate.instant("DASH"));
            } else if (this.channel.delivery) {
                this.targetBroadcaster = this.bx.getTargetBroadcaster(this.channel.target_broadcaster_id);
                this.primarySources = 0;
                this.backupSources = 0;
                for (const src of (this.channel as DeliveryChannel).sources) {
                    if (src.primary) this.primarySources++;
                    else this.backupSources++;
                }
            }
        }

        if (changes.channel && this.adaptiveChannel.view_url) {
            const data = this.adaptiveChannel.view_url;
            const blob = new Blob([data], { type: "application/videolan" });
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        }
    }

    getAWSRegionName(region: string) {
        return this.cs.getAWSRegionName(region);
    }

    getActiveBroadcaster(source: Source) {
        if (!(this.passThroughChannel?.status?.active_broadcasters?.length > 0)) return undefined;

        return this.passThroughChannel.status.active_broadcasters.find(
            bx => bx.source_stream_id === (source.monitor_only ? source.name : source.stream_id)
        );
    }

    isVip() {
        return this.tagsService.isObjectVip(this.channel.resourceTags);
    }
}
