import { Component, Input, SimpleChanges, OnChanges, ComponentRef } from "@angular/core";
import * as _ from "lodash";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { ObjectTrackingClient as ObjectTrackingChange } from "@zixi/models";
import { ObjectTrackingService, ObjectTrackingChangesGroup } from "../object-tracking.service";
import { ZxObjectTrackingSubListComponent } from "../sub-list/zx-object-tracking-sub-list.component";
import { Constants } from "../../../../constants/constants";
import { TableSchema } from "../../table-list/table-list.component";
import { KeyMap } from "../../../../models/shared";
import { ModalService } from "../../../shared/modals/modal.service";
import { ZxDateTimeDisplayComponent } from "../../zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "../../zx-date-time-display/zx-date-time-display.table-adapter";
import { ZxTableActionsComponent } from "../../../../components/shared/zx-table-actions/zx-table-actions.component";
import { assignGenericLinkTextInputsFactory } from "../../../../components/shared/zx-table-actions/zx-table-actions.table-adapter";

@Component({
    selector: "zx-object-tracking-main-list",
    templateUrl: "./zx-object-tracking-main-list.component.html",
    providers: [DatePipe]
})
export class ZxObjectTrackingMainListComponent implements OnChanges {
    @Input() objectType: string;
    @Input() objectName: string;
    @Input() objectApiType?: string;
    @Input() objectId: number;
    @Input() refreshObjectDataFunction: () => Promise<void>;
    @Input() autoRows? = true;
    @Input() includeRestartConfirmation = true;

    putEndpointUrl: string;
    objectTracking: ObjectTrackingChangesGroup[] = null;
    tableColumnsSchema: TableSchema[] = [
        {
            columnDef: "expand",
            header: "Expand",
            visible: true,
            sticky: 0,
            width: 32,
            expandDisabled: (changesGroup: KeyMap<ObjectTrackingChangesGroup>) => !changesGroup.changes.length,
            component: ZxObjectTrackingSubListComponent,
            assignComponentsInputs: (
                zxObjectTrackingSubListComponent: ComponentRef<ZxObjectTrackingSubListComponent>,
                changesGroup: ObjectTrackingChangesGroup
            ) => {
                zxObjectTrackingSubListComponent.instance.changes = changesGroup.changes;
                zxObjectTrackingSubListComponent.instance.onRevertCallback = change => this.revertChanges([change]);
            }
        },
        {
            header: this.translateService.instant("MODIFIED_BY"),
            columnDef: "modifier",
            width: 220,
            visible: true,
            sticky: 1
        },
        {
            header: this.translateService.instant("DATE/TIME"),
            columnDef: "date",
            width: 160,
            visible: true,
            sticky: 2,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<ObjectTrackingChangesGroup>(
                changesGroup => changesGroup.date
            )
        },
        {
            header: this.translateService.instant("ACTIONS"),
            columnDef: "actions",
            hideColumnName: true,
            width: 120,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "clock-rotate-left",
                    translateTitle: "REVERT_ALL",
                    isTextVisible: true,
                    onClickHandler: (changesGroup: ObjectTrackingChangesGroup) =>
                        this.revertChanges(changesGroup.changes),
                    isVisibleCallback: (changesGroup: ObjectTrackingChangesGroup) =>
                        this.isChangesGroupRevertible(changesGroup.changes)
                }
            ])
        }
    ];

    constructor(
        private objectTrackingService: ObjectTrackingService,
        private translateService: TranslateService,
        private modalService: ModalService
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        const isObjectIdChanged = changes?.objectId?.currentValue !== changes?.objectId?.previousValue;
        const isObjectTypeChanged = changes?.objectType?.previousValue !== changes?.objectType?.currentValue;
        if (isObjectIdChanged || isObjectTypeChanged) {
            this.objectTracking = null;
            this.putEndpointUrl = `${Constants.apiUrl}/${this.objectApiType ?? this.objectType}/${
                this.objectId
            }`.replace("//", "/"); // ugly hack incase objectApiType starts with a '/'
            await this.refreshTrackingData();
        }
    }

    async refreshTrackingData() {
        this.objectTracking = await firstValueFrom(
            this.objectTrackingService.getObjectChanges(this.objectType, this.objectId)
        );
    }

    isChangesGroupRevertible(changes: ObjectTrackingChange[]): boolean {
        const revertibleChange = _.find(changes, change => this.objectTrackingService.isChangeRevertible(change));
        return Boolean(revertibleChange);
    }

    async revertChanges(changes: ObjectTrackingChange[]) {
        const action = changes.length === 1 ? "REVERT_CHANGE" : "REVERT_CHANGES";
        this.modalService.confirm(
            action,
            "",
            async () => {
                try {
                    const response = await this.objectTrackingService.revertChanges(
                        this.putEndpointUrl,
                        changes,
                        this.includeRestartConfirmation ? false : undefined
                    );
                    if (response === true) {
                        await this.modalService.confirm(
                            "SAVE_RESTART",
                            null,
                            async () => {
                                await this.objectTrackingService.revertChanges(this.putEndpointUrl, changes, true);
                                await Promise.all([this.refreshTrackingData(), this.refreshObjectDataFunction()]);
                            },
                            this.objectName
                        );
                    } else {
                        await Promise.all([this.refreshTrackingData(), this.refreshObjectDataFunction()]);
                    }
                } catch (error) {
                    this.modalService.error(this.translateService.instant("API_ERRORS.COULD_NOT_REVERT_CHANGES"));
                    // eslint-disable-next-line no-console
                    console.error(error);
                    return false;
                }
            },
            null,
            {
                alternativeBodyText: `${this.translateService.instant(action)} on ${changes
                    .map(change => change.change_title)
                    .join(", ")}`
            }
        );
    }
}
