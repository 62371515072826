<spinner-animation *ngIf="loading"></spinner-animation>
<!-- New Note -->
<div class="form-inline" *ngIf="!loading">
    <!-- Search -->
    <div class="form-group mb-3 searchBox">
        <label class="sr-only" for="searchTerm">{{ "FILTER" | translate }}</label>
        <div class="input-group">
            <input
                class="form-control"
                type="text"
                id="searchTerm"
                name="searchTerm"
                [(ngModel)]="searchTerm"
                placeholder="{{ 'FILTER' | translate }}"
                title="{{ 'BOOLEAN_SEARCH_SUPPORTED' | translate }}"
            />
            <button class="btn btn-outline-light" type="button" (click)="resetSearch()" *ngIf="searchTerm && searchTerm.length > 0">
                <fa-icon icon="times" size="sm"></fa-icon>
            </button>
        </div>
    </div>
    <!-- Column Sort -->
    <div class="form-group mb-3">
        <label class="sr-only">{{ "SORT_BY" | translate }}</label>
        <div ngbDropdown placement="bottom-right" title="{{ 'SORT_BY' | translate }}">
            <button class="btn btn-outline-secondary" id="sortByDropdown" ngbDropdownToggle>
                <fa-icon *ngIf="sortDirection === 'desc'" icon="sort-amount-down" size="sm"></fa-icon>
                <fa-icon *ngIf="sortDirection === 'asc' || !sortDirection" icon="sort-amount-up" size="sm"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="sortByDropdown" class="shadow-sm">
                <button sortable="created_at" (sort)="onSort($event, 'CREATED_AT')" ngbDropdownItem class="text-secondary">
                    {{ "CREATED_AT" | translate }}
                </button>
                <button sortable="user" (sort)="onSort($event, 'USER')" ngbDropdownItem class="text-secondary">
                    {{ "USER" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Notes -->
<div class="notes-container" *ngIf="!loading">
    <!-- Add -->
    <div class="d-flex" *ngIf="canEdit">
        <div class="note flex-grow-1 mb-2">
            <div>
                <div class="circle">
                    {{ userName$ | async | firstLetter }}
                </div>
                <div class="d-inline-block">
                    <h5 class="mt-2">
                        {{ userName$ | async }}
                    </h5>
                </div>
            </div>
            <div class="readonly-container" *ngIf="!newNote" (click)="startNewNote()">
                <!--<textarea id="note" name="note" class="form-control clickable" placeholder="{{ 'ADD_NEW_NOTE' | translate }}" rows="1" readonly></textarea>--->
                <mat-form-field appearance="outline" class="clickable">
                    <textarea matInput id="note" name="note" class="clickable" cdkTextareaAutosize placeholder="{{ 'ADD_NEW_NOTE' | translate }}"></textarea>
                </mat-form-field>
            </div>
            <form id="addform" #addform="ngForm" (ngSubmit)="addform.valid && onSubmitNew()" *ngIf="newNote">
                <div class="form-group mb-0 mt-2">
                    <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': addform.controls.add?.errors }">
                        <textarea
                            matInput
                            id="add"
                            name="add"
                            cdkTextareaAutosize
                            #add
                            [(ngModel)]="noteText"
                            placeholder="{{ 'ADD_NEW_NOTE' | translate }}"
                            pattern="{{ constants.validators.name }}"
                            [ngClass]="{ 'is-invalid': addform.controls.add?.errors }"
                        ></textarea>
                    </mat-form-field>
                    <div *ngIf="addform.controls.add?.errors" class="invalid-feedback">
                        {{ "NOTE" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                    </div>
                    <small class="text-muted">
                        {{ status | translate }}
                    </small>
                </div>
                <div class="form-group mb-0">
                    <button
                        zmid="form-submit"
                        type="submit"
                        class="btn btn-sm btn-primary mt-2"
                        [disabled]="saving || noteText.length === 0"
                        [ngClass]="{ 'btn-danger': addform.submitted && addform.invalid }"
                    >
                        <fa-icon icon="check" size="sm"></fa-icon>
                        {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-secondary mt-2 ms-2" (click)="cancelNewNote()" [disabled]="saving">
                        <fa-icon icon="times"></fa-icon>
                        {{ "CANCEL" | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>

    <!-- Existing -->
    <ul>
        <li *ngFor="let note of notes$ | async">
            <div class="d-flex">
                <!-- View/Edit -->
                <div class="note flex-grow-1">
                    <div class="d-flex">
                        <div
                            class="circle"
                            [ngStyle]="{
                                background: note.userColor
                            }"
                        >
                            {{ note.User?.name || note.user || "N/A" | firstLetter }}
                        </div>
                        <div class="flex-grow-1">
                            <h5>
                                <ngb-highlight [result]="note.User?.name || note.user || 'N/A'" [term]="searchTermArray"></ngb-highlight>
                            </h5>
                            <h6>{{ note.created_at | date : "MMM d, y, h:mm:ss a" }}</h6>
                        </div>
                    </div>
                    <div class="readonly-container" *ngIf="!note.editingNote">
                        <mat-form-field appearance="outline" class="readonly">
                            <textarea matInput id="note" name="note" cdkTextareaAutosize [(ngModel)]="note.note" [readonly]="true"></textarea>
                        </mat-form-field>
                    </div>
                    <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit(note)">
                        <div class="form-group mb-0 mt-2" *ngIf="note.editingNote">
                            <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': form.controls.note?.errors }">
                                <textarea
                                    matInput
                                    id="note"
                                    name="note"
                                    cdkTextareaAutosize
                                    [(ngModel)]="note.note"
                                    placeholder="{{ 'NOTE' | translate }}"
                                    pattern="{{ constants.validators.name }}"
                                    [ngClass]="{ 'is-invalid': form.controls.note?.errors }"
                                ></textarea>
                            </mat-form-field>
                            <div *ngIf="form.controls.note?.errors" class="invalid-feedback">
                                {{ "NOTE" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                            <small class="text-muted">
                                {{ status | translate }}
                            </small>
                        </div>
                        <div class="form-group mb-0">
                            <button
                                class="btn btn-sm btn-outline-secondary mt-2"
                                *ngIf="!note.editingNote && (isSameUser(note) || isAdmin) && canEdit"
                                (click)="editNote(note)"
                                title="{{ 'EDIT' | translate }}"
                            >
                                <fa-icon icon="pencil" size="sm"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                zmid="form-submit"
                                type="submit"
                                class="btn btn-sm btn-primary mt-2"
                                *ngIf="note.editingNote"
                                [disabled]="saving || note.note === note.originalNote || note.note.length === 0"
                                [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                            >
                                <fa-icon icon="check" size="sm"></fa-icon>
                                {{ "SAVE" | translate
                                }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-outline-secondary mt-2 ms-2"
                                *ngIf="note.editingNote"
                                (click)="cancelEditNote(note)"
                                [disabled]="saving"
                            >
                                <fa-icon icon="times"></fa-icon>
                                {{ "CANCEL" | translate }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-outline-danger mt-2 ms-2"
                                *ngIf="note.editingNote && (isSameUser(note) || isAdmin) && canEdit"
                                (click)="deleteNote(note)"
                                title="{{ 'DELETE' | translate }}"
                            >
                                <fa-icon icon="trash-alt" size="sm"></fa-icon>
                                {{ "DELETE" | translate
                                }}<span *ngIf="deleting" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </li>
    </ul>
    <!-- No Notes -->
    <p class="text-center" *ngIf="(total$ | async) === 0 && notes?.length && !canEdit">{{ "NO_RESULTS" | translate }}</p>
    <p class="text-center" *ngIf="!notes?.length && !loading && !canEdit">{{ "NO_NOTES" | translate }}</p>
</div>
