<app-table-list
    [data]="activeSharing"
    [tableName]="'ACTIVE_SHARING' | translate"
    [displayTableName]="'SHARED_SOURCES' | translate"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    [showFilter]="false"
    [showColumnsSelection]="false"
    [isColumnSelectionNeeded]="false"
    [showReport]="false"
    [autoRows]="false"
    [selectable]="false"
    [hoverable]="false"
>
</app-table-list>
