import {
    Component,
    OnInit,
    OnDestroy,
    OnChanges,
    Input,
    HostListener,
    SimpleChanges,
    ViewChild,
    ElementRef,
    AfterContentInit,
    Output,
    EventEmitter
} from "@angular/core";
import { PercentPipe } from "@angular/common";
import { SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { fromEvent, lastValueFrom, Subscription } from "rxjs";
import { takeUntil, take, delay } from "rxjs/operators";
import _ from "lodash";
import moment from "moment";
import mermaid, { MermaidConfig } from "mermaid";
//
import { Constants } from "src/app/constants/constants";
import {
    ActiveBroadcaster,
    Broadcaster,
    MediaConnectFlow,
    MediaConnectSource,
    SomeZixiObject,
    Source,
    ZixiObject
} from "src/app/models/shared";
import { BroadcasterInputPipe } from "src/app/pipes/broadcaster-input.pipe";
import { FeederInputPipe } from "src/app/pipes/feeder-input.pipe";
import { ResizeService } from "src/app/services/resize.service";
import { SharedService } from "src/app/services/shared.service";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";
import { SourcesService } from "../../../pages/sources/sources.service";
import { ZecsService } from "../../../pages/zecs/zecs.service";
import { TargetsService } from "../../../pages/targets/targets.service";
import { ChannelsService } from "../../../pages/channels/channels.service";
import { Feeder, Zec } from "../../../pages/zecs/zecs/zec";
import { Receiver } from "../../../pages/zecs/zecs/zec";
import {
    AdaptiveChannel,
    AnyTarget,
    ChannelTypes,
    DeliveryChannel,
    FailoverChannel,
    MediaLiveChannel,
    TargetObjectType,
    ZixiPullTarget
} from "../../../pages/channels/channel";
import { ClustersService } from "../../../pages/clusters/clusters.service";
import { ZmEvent } from "../../../pages/events/event";
import { urlBuilder } from "@zixi/shared-utils";
import { UrlBuilderService } from "src/app/services/url-builder.service";
//
type EventsObjects = ZixiObject | ZixiObject[] | number[];

type EventsTypedObjects = {
    feeder?: EventsObjects;
    receiver?: EventsObjects;
    zec?: EventsObjects;
    broadcaster?: EventsObjects;
    source?: EventsObjects;
    mediaconnect_sources?: EventsObjects;
    //
    adaptive_channel?: EventsObjects;
    delivery_channel?: EventsObjects;
    mediaconnect_flows?: EventsObjects;
    medialive_channels?: EventsObjects;
    failover_channel?: EventsObjects;
    //
    publishing_target?: EventsObjects;
    zixi_pull?: EventsObjects;
    zixi_push?: EventsObjects;
    rtmp_push?: EventsObjects;
    udp_rtp?: EventsObjects;
    rist?: EventsObjects;
    srt_targets?: EventsObjects;
    ndi_targets?: EventsObjects;
    mediaconnect_cdi_targets?: EventsObjects;
    mediaconnect_jpegxs_targets?: EventsObjects;
    medialive_http_targets?: EventsObjects;
};

type MermaidObject = {
    objects: {
        [key: string]: {
            type: string;
            stream: boolean;
            server: boolean;
            value: string;
        };
    };
    paths: {
        [from: string]: {
            [to: string]: { tags?: string[] };
        };
    };
    status: {
        [key: string]: string;
    };
    clusters: {
        [clusterKey: string]: {
            [broadcasterKey: string]: string[];
        };
    };
    groups: {
        [key: string]: string[];
    };
    clicks: string[];
};

@Component({
    selector: "app-diagram",
    templateUrl: "./diagram.component.html",
    providers: [PercentPipe, { provide: Window, useValue: window }]
})
export class DiagramComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit {
    @Input() model: SomeZixiObject;
    @Input() type: string;
    //
    @Input() selectedObjectIDs?: string[];
    @Input() loadingAnimation?: boolean;
    @Output() selectedObjectsChange? = new EventEmitter();

    toDate: moment.Moment = moment();
    fromDate: moment.Moment = moment().subtract(12, "hours");

    loading: boolean;
    loadingMermaid = true;
    updatingMermaid = false;
    noModel = false;

    mermaidData: string;
    mermaidClasses: string;
    mermaidBase = "%%{ init: { 'flowchart': { 'defaultRenderer': 'elk' } } }%%";
    mermaidDirection = "flowchart LR";
    constants = Constants;

    pos = { top: 0, left: 0, x: 0, y: 0 };

    healthMode = 0;

    allEvents: ZmEvent[] = [];

    hideEverything = false;
    hideDetails = false;
    hideBroadcasters = false;
    maxTextSize = 200000;
    count = 0;

    size: number;
    resizeTimer: number;
    visData: SafeHtml;

    graphZoomed = false;
    origWidth: number = null;
    origHeight: number = null;
    private resizeSubscription: Subscription;
    //
    showPanel = false;
    loadingDetails = false;
    drawingMermaid = false;

    //
    selectedIDs: string[] = [];
    allObjectsEventsObjects: EventsTypedObjects = {};
    //
    ctrlPressed = false;
    dragging: boolean;
    minWidth = 380;
    //
    @ViewChild("visualization", { static: true }) mermaidRef: ElementRef;
    @ViewChild("title", { static: false }) title: ElementRef;
    //

    @HostListener("window:keydown", ["$event"])
    private ctrlDown(event: KeyboardEvent) {
        if (event.key === "Control") this.ctrlPressed = true;
    }

    @HostListener("window:keyup", ["$event"])
    private ctrlUp(event: KeyboardEvent) {
        if (event.key === "Control") this.ctrlPressed = false;
    }

    @HostListener("window:blur", ["$event"])
    private focus() {
        focus();
        setTimeout(focus, 20);
    }

    @HostListener("window:resize", [])
    private onResize() {
        if (this.resizeTimer) clearTimeout(this.resizeTimer);
        this.resizeTimer = window.setTimeout(() => {
            this.handleResize();
        }, 100);
    }

    constructor(
        private percentPipe: PercentPipe,
        private feederInputPipe: FeederInputPipe,
        private broadcasterInputPipe: BroadcasterInputPipe,
        private resizeService: ResizeService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private broadcastersService: BroadcastersService,
        private sourcesService: SourcesService,
        private zecsService: ZecsService,
        private targetsService: TargetsService,
        private channelsService: ChannelsService,
        private clustersService: ClustersService,
        private urlBuildService: UrlBuilderService
    ) {}

    async ngOnInit() {
        if (localStorage.getItem("diagramDetails")) {
            if (localStorage.getItem("diagramDetails") === "true") this.hideDetails = false;
            else this.hideDetails = true;
        }
        if (localStorage.getItem("diagramBroadcasters")) {
            if (localStorage.getItem("diagramBroadcasters") === "true") this.hideBroadcasters = false;
            else this.hideBroadcasters = true;
        }

        if (localStorage.getItem("diagramOrientation"))
            this.mermaidDirection = localStorage.getItem("diagramOrientation");

        // Screen size
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.size = x;
        });

        // Define window clickEvent function
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).clickEvent = async id => await this.objectClickEvent(id);

        // Preselect objects
        if (this.selectedObjectIDs) {
            for (const id of this.selectedObjectIDs) {
                await this.objectClickEvent(id);
            }
        }
    }

    ngAfterContentInit() {
        mermaid.initialize({
            startOnLoad: true,
            maxTextSize: this.maxTextSize,
            securityLevel: "loose",
            flowchart: {
                htmlLabels: true
            },
            theme: "default",
            dompurifyConfig: { ADD_ATTR: ["target"] }
        } as MermaidConfig);
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.loadingAnimation) {
            this.loadingMermaid = true;
        }

        if (changes.model) {
            if (changes.model.previousValue && changes.model.currentValue) {
                if (changes.model.previousValue.id === changes.model.currentValue.id) {
                    if (this.model.hasFullDetails) {
                        this.drawMermaid();
                    }
                }

                if (changes.model.previousValue.id !== changes.model.currentValue.id) {
                    this.count = 0;
                    this.hideEverything = false;
                    this.origWidth = null;
                    this.origHeight = null;
                    this.graphZoomed = null;
                    this.loadingMermaid = true;
                    if (this.model.hasFullDetails) {
                        this.drawMermaid();
                    }
                }
            }

            if (changes.model.previousValue === undefined && changes.model.currentValue) {
                this.loadingMermaid = true;
                if (this.model.hasFullDetails) {
                    this.drawMermaid();
                }
            }
        }

        if (changes.selectedObjectIDs && !changes.selectedObjectIDs.firstChange) {
            if (changes.selectedObjectIDs.previousValue && changes.selectedObjectIDs.currentValue) {
                this.selectedIDs = changes.selectedObjectIDs.currentValue;
                if (this.mermaidData === undefined) await this.drawMermaid(true);
                else await this.drawMermaid(true, true);
            }
        }
    }

    private selectedObjectsChanged() {
        this.selectedObjectsChange.emit(this.selectedIDs);
    }

    private async objectClickEvent(id: string) {
        if (!this.canAddSelectedID(id) && this.ctrlPressed) return;
        if (!this.ctrlPressed) this.resetSelectedObjects();
        // Update SVG selected class
        this.addSelectedClassToNode(id);
        this.selectedObjectsChanged();
    }

    private async addDiagramEventsObjects(mermaidObject: MermaidObject) {
        const objects = mermaidObject.objects;
        const keys = Object.keys(objects);

        if (this.sharedService.isEmptyObject(this.allObjectsEventsObjects))
            for (const key of keys) {
                const fields = key.split("-");
                const type = fields[0];
                const n = parseInt(fields[1], 10);
                const subtype = fields[2];
                //
                if (type === "f") this.addDiagramEventsObject("feeder", n);
                else if (type === "r") this.addDiagramEventsObject("receiver", n);
                else if (type === "z") this.addDiagramEventsObject("zec", n);
                else if (type === "b") this.addDiagramEventsObject("broadcaster", n);
                else if (type === "s") this.addDiagramEventsObject("source", n);
                else if (type === "mc") this.addDiagramEventsObject("mediaconnect_sources", n);
                else if (type === "c") {
                    if (subtype === "mcc") this.addDiagramEventsObject("mediaconnect_flows", n);
                    else if (subtype === "ac") this.addDiagramEventsObject("adaptive_channel", n);
                    else if (subtype === "dc") this.addDiagramEventsObject("delivery_channel", n);
                    else if (subtype === "fc") this.addDiagramEventsObject("failover_channel", n);
                    else if (subtype === "ml") this.addDiagramEventsObject("medialive_channels", n);
                } else if (type === "t") {
                    // 11 Types
                    if (subtype === "pull") this.addDiagramEventsObject("zixi_pull", n);
                    if (subtype === "push") this.addDiagramEventsObject("zixi_push", n);
                    if (subtype === "rtmp") this.addDiagramEventsObject("rtmp_push", n);
                    if (subtype === "http") this.addDiagramEventsObject("publishing_target", n);
                    if (subtype === "udp_rtp") this.addDiagramEventsObject("udp_rtp", n);
                    if (subtype === "rist") this.addDiagramEventsObject("rist", n);
                    if (subtype === "srt") this.addDiagramEventsObject("srt_targets", n);
                    if (subtype === "ndi") this.addDiagramEventsObject("ndi_targets", n);
                    if (subtype === "medialive_http") this.addDiagramEventsObject("medialive_http_targets", n);
                    if (subtype === "cdi") this.addDiagramEventsObject("mediaconnect_cdi_targets", n);
                    if (subtype === "jpegxs") this.addDiagramEventsObject("mediaconnect_jpegxs_targets", n);
                }
            }
    }

    private addDiagramEventsObject(type: string, id: number) {
        if (this.allObjectsEventsObjects[type]) this.allObjectsEventsObjects[type].push(id);
        else this.allObjectsEventsObjects[type] = [id];
    }

    private canAddSelectedID(id: string) {
        if (this.selectedIDs.indexOf(id) > -1) return false;
        else return true;
    }

    private addSelectedClassToNode(id: string) {
        document.querySelector('[id^="flowchart-' + id + '"]');
    }

    private removeClassFromNode(id: string) {
        const node = document.querySelector('[id^="flowchart-' + id + '"]');
        if (node) node.classList.remove("selected");
        //
        const f = this.selectedIDs.find(i => i === id);
        const index = this.selectedIDs.indexOf(f);
        if (index > -1) this.selectedIDs.splice(index, 1);
    }

    private resetSelectedObjects() {
        this.clearSelectedClass();
    }

    private clearSelectedClass() {
        if (this.selectedIDs.length > 0) {
            this.selectedIDs.forEach(id => {
                const node = document.querySelector('[id^="flowchart-' + id + '"]');
                if (node) node.classList.remove("selected");
            });
        }
        this.selectedIDs = [];
    }

    toggleDetails() {
        this.hideDetails = !this.hideDetails;
        //
        let bool;
        if (this.hideDetails) bool = "false";
        else bool = "true";
        //
        localStorage.setItem("diagramDetails", bool);
        this.drawMermaid(true);
    }

    toggleBroadcasters() {
        this.hideBroadcasters = !this.hideBroadcasters;
        //
        let bool;
        if (this.hideBroadcasters) bool = "false";
        else bool = "true";
        //
        localStorage.setItem("diagramBroadcasters", bool);
        this.drawMermaid(true);
    }

    toggleOrientation(s: string) {
        this.mermaidDirection = s;
        localStorage.setItem("diagramOrientation", s);
        this.drawMermaid(true, true);
    }

    startDiagramDrag(e: MouseEvent) {
        const diagram = document.getElementById("mermaid-container");
        this.pos = {
            // The current scroll
            left: diagram.scrollLeft,
            top: diagram.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY
        };

        const mouseUp = fromEvent(window, "mouseup");
        this.dragging = true;

        // Change the cursor and prevent user from selecting the text
        diagram.style.cursor = "grabbing";
        diagram.style.userSelect = "none";

        fromEvent(window, "mousemove")
            .pipe(takeUntil(mouseUp.pipe(take(1))))
            .subscribe((event: MouseEvent) => {
                // How far the mouse has been moved
                const dx = event.clientX - this.pos.x;
                const dy = event.clientY - this.pos.y;

                // Scroll the element
                diagram.scrollTop = this.pos.top - dy;
                diagram.scrollLeft = this.pos.left - dx;
            });

        mouseUp.pipe(take(1), delay(50)).subscribe(() => {
            diagram.style.cursor = "grab";
            diagram.style.removeProperty("user-select");

            this.dragging = false;
        });
    }

    private handleResize() {
        const prevMermaidDirection = this.mermaidDirection;
        if (this.size <= 4) {
            this.mermaidDirection = "flowchart TB";
        } else this.mermaidDirection = "flowchart LR";
        //
        if (prevMermaidDirection !== this.mermaidDirection) {
            this.origWidth = null;
            this.origHeight = null;
            this.drawMermaid(true, true);
        }
    }

    zoomOut() {
        const diagrams = document.getElementsByClassName("mermaid-container");
        if (diagrams && diagrams.length) {
            for (let i = 0; i < diagrams.length; i++) {
                if (diagrams[i].clientWidth > this.origWidth) {
                    /* document
                            .getElementById("visualization")
                            .getElementsByTagName("svg")[0]
                            .setAttribute("style", "width:" + this.origWidth + "px;"); */
                    diagrams[i].firstElementChild
                        .getElementsByTagName("svg")[0]
                        .setAttribute("style", "width:" + this.origWidth + "px;");
                } else {
                    // document.getElementById("visualization").getElementsByTagName("svg")[0].removeAttribute("style");
                    diagrams[i].firstElementChild.getElementsByTagName("svg")[0].removeAttribute("style");
                }
                this.graphZoomed = false;
            }
        }
    }

    zoomIn() {
        const diagrams = document.getElementsByClassName("mermaid-container");
        if (diagrams && diagrams.length) {
            for (let i = 0; i < diagrams.length; i++) {
                if (diagrams[i].clientWidth > this.origWidth) {
                    diagrams[i].firstElementChild
                        .getElementsByTagName("svg")[0]
                        .setAttribute("style", "width:100%; height:auto;");
                } else {
                    diagrams[i].firstElementChild
                        .getElementsByTagName("svg")[0]
                        .setAttribute("style", "width:" + this.origWidth + "px; height:auto;");
                }
                this.graphZoomed = true;
            }
        }
    }

    private async drawMermaid(updating?: boolean, dontUpdateData?: boolean) {
        // eslint-disable-next-line no-console
        // No model
        if (!this.model || this.sharedService.isEmptyObject(this.model)) {
            this.loadingMermaid = false;
            this.updatingMermaid = false;
            this.noModel = true;
            return;
        }

        this.drawingMermaid = true;

        //
        if (updating) this.updatingMermaid = true;
        this.count++;

        // mermaid process
        if (!dontUpdateData) await this.mermaidProcess(this.model);

        // mermaid def
        const mermaidDef =
            this.mermaidBase + "\n" + this.mermaidDirection + "\n" + this.mermaidClasses + "\n" + this.mermaidData;

        // Check diagram max text size
        if (this.mermaidData && this.mermaidData.length > this.maxTextSize - 1000) {
            this.hideDetails = true;
            if (this.count > 1) this.hideEverything = true;
            if (this.count > 2 && this.mermaidData.length > this.maxTextSize - 1000) {
                this.loadingMermaid = false;
                return;
            }
            await this.drawMermaid();
        }

        // render chart
        mermaid.render("chart" + Date.now(), mermaidDef).then(async renderResult => {
            this.mermaidRef.nativeElement.innerHTML = renderResult.svg;
            renderResult.bindFunctions(this.mermaidRef.nativeElement);
            //
            let availableWidth;
            if (document.getElementById("mermaid-container"))
                availableWidth = document.getElementById("mermaid-container").clientWidth;
            //
            if (this.origWidth === null && this.origHeight === null) {
                const style = renderResult.svg.match(/style="(.*?)"/)[1];
                this.origWidth = parseInt(style.match(this.constants.NUMERIC_REGEXP)[0], 10);
                this.origHeight = parseInt(renderResult.svg.match(/height="(.*?)"/)[1], 10);
                this.graphZoomed = false;
            } else {
                if (this.graphZoomed && availableWidth > this.origWidth) {
                    document
                        .getElementById("visualization")
                        .getElementsByTagName("svg")[0]
                        .setAttribute("style", "width:100%; height:auto;");
                } else if (this.graphZoomed && availableWidth < this.origWidth) {
                    document
                        .getElementById("visualization")
                        .getElementsByTagName("svg")[0]
                        .setAttribute("style", "width:" + this.origWidth + "px; height:auto;");
                }
            }
            //
            this.updatingMermaid = false;
            this.loadingMermaid = false;
            this.drawingMermaid = false;
            //
            if (this.selectedIDs && this.selectedIDs.length > 0) {
                this.selectedIDs.forEach(id => {
                    this.addSelectedClassToNode(id);
                });
            }
        });
        this.count = 0;
    }

    private async addBroadcaster(mermaidObject: MermaidObject, broadcaster: Broadcaster) {
        // Broadcaster Object
        this.addBroadcasterObject(mermaidObject, broadcaster);
        // Add Broadcaster to processingCluster Group
        this.addObjectToBroadcaster(
            mermaidObject,
            broadcaster.broadcaster_cluster.name,
            broadcaster,
            "broadcaster",
            broadcaster.id
        );
    }

    private async addFeeder(mermaidObject: MermaidObject, feeder: Feeder) {
        // Feeder
        this.addFeederObject(mermaidObject, feeder);
        // Feeder Sources
        const sources: Source[] | false = await this.zecsService.getFeederSources(feeder.id);
        if (sources) {
            // Refresh Feeder Broadcasters
            const broadcastersIDs: number[] = _.uniq(
                _.map(sources, source => {
                    if (source.status && source.status?.active_broadcaster && source.status?.active_broadcaster?.id)
                        return source.status.active_broadcaster.id;
                }).filter(id => id !== undefined)
            );

            if (broadcastersIDs && broadcastersIDs.length)
                await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

            for (const source of sources) {
                if (
                    !source.readOnly &&
                    source.broadcaster_cluster_id &&
                    (!source.inputCluster || !source.inputCluster?.dns_prefix)
                ) {
                    await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                    const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);
                    source.inputCluster = bc;
                }

                // Feeder Source
                this.addSourceObject(mermaidObject, source);
                this.addPath(mermaidObject, "feeder", feeder.id, "source", source.id);

                if (source.readOnly) continue;

                // Feeder Source Broadcaster
                if (source.status && source.status.active_broadcaster) {
                    const b = Object.assign(
                        {},
                        this.broadcastersService.getCachedBroadcaster(source.status.active_broadcaster.id)
                    );
                    this.addBroadcasterObject(mermaidObject, b);
                    this.addPath(mermaidObject, "source", source.id, "broadcaster", b.id);
                    // Feeder Source Broadcaster Cluster
                    this.addObjectToBroadcaster(mermaidObject, b.broadcaster_cluster.name, b.name, "source", source.id);
                }
                // No Broadcaster
                else {
                    if (source.broadcaster_cluster_id) {
                        await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                        const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);

                        this.addObjectToBroadcaster(mermaidObject, bc.name, undefined, "source", source.id);
                    }
                }
            }
        }
    }

    private async addReceiver(mermaidObject: MermaidObject, receiver: Receiver) {
        // Receiver
        this.addReceiverObject(mermaidObject, receiver);
        // Receiver Targets
        const targets = await this.zecsService.getReceiverTargets(receiver.id);
        if (targets) {
            for (const target of targets) {
                // Receiver Target
                // TODO: Channel name
                this.addTargetObject(mermaidObject, target, this.targetsService.getTargetApiType(target));
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "receiver",
                    target.receiver_id,
                    this.targetsService.getTargetApiType(target)
                );

                if (target.readOnly) continue;

                // Receiver Target Channel
                let channel = null;
                if (
                    target.delivery_channel_id ||
                    target.mediaconnect_flow_id ||
                    target.adaptive_channel_id ||
                    target.medialive_channel_id
                ) {
                    if (target.delivery_channel_id) {
                        await this.channelsService
                            .refreshDeliveryChannel(target.delivery_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedDeliveryChannel(target.delivery_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.adaptive_channel_id) {
                        await this.channelsService
                            .refreshAdaptiveChannel(target.adaptive_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedAdaptiveChannel(target.adaptive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.mediaconnect_flow_id) {
                        await this.channelsService
                            .refreshMediaConnectFlow(target.mediaconnect_flow_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaConnectFlow(target.mediaconnect_flow_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.medialive_channel_id) {
                        await this.channelsService
                            .refreshMediaLiveChannel(target.medialive_channel_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaLiveChannel(target.medialive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                }
                // Receiver Target Broadcasters
                if (target.status && target.status.active_broadcasters) {
                    // Refresh Feeder Broadcasters
                    const broadcastersIDs: number[] = _.uniq(
                        _.map(target.status.active_broadcasters, broadcaster => {
                            if (broadcaster && broadcaster.id) return broadcaster.id;
                        }).filter(id => id !== undefined)
                    );
                    if (broadcastersIDs && broadcastersIDs.length)
                        await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

                    for (const broadcaster of target.status.active_broadcasters) {
                        // Receiver Target Broadcaster
                        const b = Object.assign({}, this.broadcastersService.getCachedBroadcaster(broadcaster.id));
                        this.addBroadcasterObject(mermaidObject, b);
                        this.addPath(
                            mermaidObject,
                            "broadcaster",
                            b.id,
                            "target",
                            target.id,
                            null,
                            this.targetsService.getTargetApiType(target)
                        );
                        // Receiver Target Broadcaster Cluster
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            b.broadcaster_cluster.name,
                            b.name,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                } else {
                    if (channel) {
                        // No Broadcaster
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            channel.processingCluster.name,
                            undefined,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                }
            }
        }
    }

    private async addZec(mermaidObject: MermaidObject, zec: Zec) {
        // ZEC
        this.addZecObject(mermaidObject, zec);
        // ZEC Sources
        const sources: Source[] | false = await this.zecsService.getZecSources(zec.id);
        if (sources) {
            // Refresh ZEC Broadcasters
            const broadcastersIDs: number[] = _.uniq(
                _.map(sources, source => {
                    if (source.status && source.status?.active_broadcaster && source.status?.active_broadcaster?.id)
                        return source.status.active_broadcaster.id;
                }).filter(id => id !== undefined)
            );

            if (broadcastersIDs && broadcastersIDs.length)
                await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

            for (const source of sources) {
                if (
                    !source.readOnly &&
                    source.broadcaster_cluster_id &&
                    (!source.inputCluster || !source.inputCluster?.dns_prefix)
                ) {
                    await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                    const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);
                    source.inputCluster = bc;
                }

                // ZEC Source
                this.addSourceObject(mermaidObject, source);
                this.addPath(mermaidObject, "zec", zec.id, "source", source.id);

                if (source.readOnly) continue;

                // ZEC Source Broadcaster
                if (source.status && source.status.active_broadcaster) {
                    const b = Object.assign(
                        {},
                        this.broadcastersService.getCachedBroadcaster(source.status.active_broadcaster.id)
                    );
                    this.addBroadcasterObject(mermaidObject, b);
                    this.addPath(mermaidObject, "source", source.id, "broadcaster", b.id);
                    // ZEC Source Broadcaster Cluster
                    this.addObjectToBroadcaster(mermaidObject, b.broadcaster_cluster.name, b.name, "source", source.id);
                }
                // No Broadcaster
                else {
                    if (source.broadcaster_cluster_id) {
                        await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                        const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);

                        this.addObjectToBroadcaster(mermaidObject, bc.name, undefined, "source", source.id);
                    }
                }
            }
        }
        // ZEC Targets
        const targets = await this.zecsService.getZecTargets(zec.id);
        if (targets) {
            for (const target of targets) {
                // ZEC Target
                // TODO: Channel name
                this.addTargetObject(mermaidObject, target, this.targetsService.getTargetApiType(target));
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "zec",
                    target.zec_id,
                    this.targetsService.getTargetApiType(target)
                );

                if (target.readOnly) continue;

                // ZEC Target Channel
                let channel = null;
                if (
                    target.delivery_channel_id ||
                    target.mediaconnect_flow_id ||
                    target.adaptive_channel_id ||
                    target.medialive_channel_id
                ) {
                    if (target.delivery_channel_id) {
                        await this.channelsService
                            .refreshDeliveryChannel(target.delivery_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedDeliveryChannel(target.delivery_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.adaptive_channel_id) {
                        await this.channelsService
                            .refreshAdaptiveChannel(target.adaptive_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedAdaptiveChannel(target.adaptive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.mediaconnect_flow_id) {
                        await this.channelsService
                            .refreshMediaConnectFlow(target.mediaconnect_flow_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaConnectFlow(target.mediaconnect_flow_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.medialive_channel_id) {
                        await this.channelsService
                            .refreshMediaLiveChannel(target.medialive_channel_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaLiveChannel(target.medialive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                }
                // ZEC Target Broadcasters
                if (target.status && target.status.active_broadcasters) {
                    // Refresh Feeder Broadcasters
                    const broadcastersIDs: number[] = _.uniq(
                        _.map(target.status.active_broadcasters, broadcaster => {
                            if (broadcaster && broadcaster.id) return broadcaster.id;
                        }).filter(id => id !== undefined)
                    );
                    if (broadcastersIDs && broadcastersIDs.length)
                        await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

                    for (const broadcaster of target.status.active_broadcasters) {
                        // ZEC Target Broadcaster
                        const b = Object.assign({}, this.broadcastersService.getCachedBroadcaster(broadcaster.id));
                        this.addBroadcasterObject(mermaidObject, b, true);
                        this.addPath(
                            mermaidObject,
                            "broadcaster",
                            b.id,
                            "target",
                            target.id,
                            null,
                            this.targetsService.getTargetApiType(target)
                        );
                        // ZEC Target Broadcaster Cluster
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            b.broadcaster_cluster.name,
                            b.name,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                } else {
                    if (channel) {
                        // No Broadcaster
                        // TODO: INVALID!
                        this.addBroadcasterObject(mermaidObject, channel.broadcaster_cluster_id);
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            channel.processingCluster.name,
                            undefined,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                }
            }
        }
    }

    private async addSource(mermaidObject: MermaidObject, source: Source | MediaConnectSource) {
        const mediaconnect = source instanceof MediaConnectSource;

        let sourceChannels: false | ChannelTypes[] = [];
        if (!mediaconnect) {
            sourceChannels = (await this.sourcesService.getSourceChannels(source.id)) || [];
        } else {
            if (source.mediaconnect_flow_id && source.mediaconnectFlow) sourceChannels = [source.mediaconnectFlow];
        }

        // Channels
        if (sourceChannels && sourceChannels.length) {
            for (const sourceChannel of sourceChannels) {
                const channel = await lastValueFrom(this.channelsService.refreshChannel(sourceChannel, false));

                await this.addChannel(mermaidObject, channel, true, mediaconnect ? undefined : source);
            }
        } else {
            if (mediaconnect) await this.addMediaConnectSourceFromObject(mermaidObject, null, source);
            else await this.addSourceFromObject(mermaidObject, source, false);
        }
    }

    private async addChannel(
        mermaidObject: MermaidObject,
        channel: ChannelTypes,
        noTargets: boolean,
        channelSource?: Source
    ): Promise<Broadcaster | undefined> {
        if (!channel) {
            throw new Error("WTF");
        }
        const awsMediaChannel = channel instanceof MediaConnectFlow || channel instanceof MediaLiveChannel;
        let channelBroadcaster: Broadcaster | undefined = undefined;

        if (awsMediaChannel) await this.addAWSMediaChannelFromObject(mermaidObject, channel);
        else channelBroadcaster = await this.addChannelFromObject(mermaidObject, channel);

        // Setup Sources
        if (channel instanceof MediaConnectFlow) {
            if (channel.source && channel.source.id != null) {
                await this.addMediaConnectSourceFromObject(mermaidObject, channel, channel.source);
                this.addPath(
                    mermaidObject,
                    "mediaconnect_source",
                    channel.source.id,
                    "channel",
                    channel.id,
                    undefined,
                    this.channelSubType(channel)
                );
            }
        } else if (channel instanceof MediaLiveChannel) {
            if (channel.flow && channel.flow.id != null) {
                await lastValueFrom(this.channelsService.refreshMediaConnectFlow(channel.flow.id, false));
                const sourceFlow = this.channelsService.getCachedMediaConnectFlow(channel.flow.id);

                await this.addChannel(mermaidObject, sourceFlow, true);

                this.addPath(
                    mermaidObject,
                    "channel",
                    sourceFlow.id,
                    "channel",
                    channel.id,
                    this.channelSubType(sourceFlow),
                    this.channelSubType(channel)
                );
            }
        } else {
            const sources: { source: Source; type?: string }[] = channelSource ? [{ source: channelSource }] : [];
            if (!channelSource) {
                if (channel instanceof DeliveryChannel) {
                    for (const src of channel.sources ?? []) {
                        if (src.source?.id !== null) sources.push({ source: src.source });
                    }
                }
                if (channel instanceof FailoverChannel) {
                    if (channel.failoverSource && channel.failover_source_id) {
                        sources.push({ source: channel.failoverSource });
                    }
                }
                if (channel instanceof AdaptiveChannel) {
                    if (channel.slateSource) sources.push({ source: channel.slateSource, type: "Slate" });
                    for (const bitrate of channel.bitrates ?? []) {
                        sources.push({
                            source: bitrate.source,
                            type: !bitrate.profile_id
                                ? `${bitrate.kbps} kbps`
                                : channel.slateSource
                                ? "Primary"
                                : undefined
                        });
                    }
                }
            }

            for (const s of sources) {
                await this.addSourceFromObject(
                    mermaidObject,
                    s.source,
                    false,
                    channel instanceof FailoverChannel ? channel : undefined
                );
                if (channel instanceof AdaptiveChannel)
                    this.addPath(
                        mermaidObject,
                        "source",
                        s.source.id,
                        "channel",
                        channel.id,
                        undefined,
                        this.channelSubType(channel),
                        s.type ? [s.type] : undefined
                    );
            }
        }

        if (noTargets) return channelBroadcaster;

        const targetsChannel = channel instanceof FailoverChannel ? channel.deliveryChannel : channel;

        // Publishing Target
        if (targetsChannel instanceof AdaptiveChannel)
            for (const t of targetsChannel.publishingTarget ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "http" }, t),
                    false
                );
            }

        // Zixi Push
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.zixiPush ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "push" }, t),
                    false
                );
            }

        // rtmp Push
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.rtmpPush ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "rtmp" }, t),
                    false
                );
            }

        // udpRtp
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.udpRtp ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "udp_rtp" }, t),
                    false
                );
            }

        // RIST
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.rist ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "rist" }, t),
                    false
                );
            }

        // SRT
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.srt ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "srt" }, t),
                    false
                );
            }

        // NDI
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.ndi ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "ndi" }, t),
                    false
                );
            }

        // Zixi Pull
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const target of targetsChannel.zixiPull ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "pull" }, target),
                    false
                );
            }

        if (targetsChannel instanceof MediaLiveChannel)
            for (const t of targetsChannel.mediaLiveHttp ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "medialive_http" }, t),
                    false
                );
            }

        return channelBroadcaster;
    }

    private async addTarget(mermaidObject: MermaidObject, target: TargetObjectType) {
        let channel: ChannelTypes | undefined = undefined;
        if (target.mediaconnectFlow) channel = target.mediaconnectFlow;
        if (target.adaptiveChannel) channel = target.adaptiveChannel;
        if (target.deliveryChannel) {
            channel = target.deliveryChannel;
            if (target.deliveryChannel.failover_channel_id && target.deliveryChannel.is_hidden) {
                channel = await lastValueFrom(
                    this.channelsService.refreshFailoverChannel(target.deliveryChannel.failover_channel_id, false)
                );
            }
        }
        if (target.mediaLiveChannel) channel = target.mediaLiveChannel;

        let channelData = null;
        let channelBroadcaster: Broadcaster | undefined = undefined;
        // TODO: fast=1?
        if (channel) {
            if (!channel.readOnly) {
                if (channel.type === "adaptive" || channel.type === "transcode") {
                    await lastValueFrom(this.channelsService.refreshAdaptiveChannel(channel.id, false));
                    channelData = this.channelsService.getCachedAdaptiveChannel(channel.id);
                } else if (channel.type === "delivery" || channel.type === "pass-through") {
                    await lastValueFrom(this.channelsService.refreshDeliveryChannel(channel.id, false));
                    channelData = this.channelsService.getCachedDeliveryChannel(channel.id);
                } else if (channel.type === "failover" || channel.type === "hitless") {
                    await lastValueFrom(this.channelsService.refreshFailoverChannel(channel.id, false));
                    channelData = this.channelsService.getCachedFailoverChannel(channel.id);
                } else if (channel.type === "medialive") {
                    await lastValueFrom(this.channelsService.refreshMediaLiveChannel(channel.id, false));
                    channelData = this.channelsService.getCachedMediaLiveChannel(channel.id);
                } else {
                    await lastValueFrom(
                        this.channelsService.refreshMediaConnectFlow(target.mediaconnect_flow_id, false)
                    );
                    channelData = this.channelsService.getCachedMediaConnectFlow(target.mediaconnect_flow_id);
                }
            }

            // Channel
            channelBroadcaster = await this.addChannel(mermaidObject, channelData || channel, true);
        }
        await this.addTargetFromObject(mermaidObject, channelData || channel, channelBroadcaster, target, true);
    }

    private channelSubType(channel: ChannelTypes) {
        if (!channel) return;
        if (
            channel.mediaconnect ||
            channel.medialive ||
            channel.aws_account_id ||
            channel.region ||
            channel.type === "medialive" ||
            channel.type === "mediaconnect"
        ) {
            if (channel.mediaconnect || channel.type === "mediaconnect") {
                return "mcc";
            } else if (channel.medialive || channel.type === "medialive") {
                return "ml";
            }
            return "mcc";
        } else if (channel.failover || channel.type === "failover" || channel.type === "hitless") {
            return "fc";
        } else if (channel.delivery || channel.type === "delivery" || channel.type === "pass-through") {
            return "dc";
        } else if (channel.adaptive || channel.type === "adaptive" || channel.type === "transcode") {
            return "ac";
        }
    }

    private async addAWSMediaChannelFromObject(
        mermaidObject: MermaidObject,
        channel: MediaConnectFlow | MediaLiveChannel
    ): Promise<Broadcaster | undefined> {
        if (!channel || !channel.id) return;
        this.addChannelObject(mermaidObject, channel, this.channelSubType(channel));
    }

    private async addChannelFromObject(
        mermaidObject: MermaidObject,
        channel: AdaptiveChannel | DeliveryChannel | FailoverChannel
    ): Promise<Broadcaster | undefined> {
        if (!channel || !channel.id) return;

        const hasChannelNode = channel instanceof AdaptiveChannel;
        const channelSubtype = this.channelSubType(channel);
        if (hasChannelNode) this.addChannelObject(mermaidObject, channel, channelSubtype);

        let actualBroadcaster: Broadcaster | undefined = undefined;
        const bxId =
            channel instanceof DeliveryChannel
                ? channel.target_broadcaster_id
                : channel.activeBroadcasterObjects?.bx_id ??
                  (channel instanceof FailoverChannel
                      ? channel.deliveryChannel?.target_broadcaster_id
                      : channel.broadcaster_id);

        if (bxId > 0) {
            await this.broadcastersService.refreshBroadcaster(bxId, false).toPromise();
            actualBroadcaster = this.broadcastersService.getCachedBroadcaster(bxId);
        }

        // Add Channel Object to processingCluster Group
        if (hasChannelNode)
            this.addObjectToBroadcaster(
                mermaidObject,
                channel.processingCluster.name,
                actualBroadcaster?.name,
                "channel",
                channel.id,
                channelSubtype
            );

        if (actualBroadcaster) {
            this.addBroadcasterObject(mermaidObject, actualBroadcaster);
            // Add Broadcaster to processingCluster Group
            this.addObjectToBroadcaster(
                mermaidObject,
                channel.processingCluster.name,
                actualBroadcaster.name,
                "broadcaster",
                actualBroadcaster.id
            );
        }

        return actualBroadcaster;
    }

    private async addTargetFromObject(
        mermaidObject: MermaidObject,
        channel: AdaptiveChannel | DeliveryChannel | FailoverChannel | MediaConnectFlow | MediaLiveChannel,
        channelBroadcaster: Broadcaster | undefined,
        target: TargetObjectType,
        withChannel: boolean
    ) {
        if (target.readOnly) return;

        const awsMediaChannel = channel instanceof MediaConnectFlow || channel instanceof MediaLiveChannel;
        const targetApiType = this.targetsService.getTargetApiType(target);
        const channelSubtype = this.channelSubType(channel);

        let anyT = this.targetsService.getCachedTarget(target.id, targetApiType);
        if (!anyT || !anyT.target.status)
            await this.targetsService.refreshTarget(targetApiType, target.id, false).toPromise();
        anyT = this.targetsService.getCachedTarget(target.id, targetApiType);
        if (anyT) target = anyT.target;

        // voodoo error from production
        if (!anyT || !anyT.target) return;

        this.addTargetObject(mermaidObject, anyT, targetApiType, withChannel ? channel : undefined);

        // add source/channel to target paths
        // and put target in relevant broadcaster box
        if (channel instanceof DeliveryChannel) {
            let activeBroadcasters: ActiveBroadcaster[] = [];
            if (target.status && target.status.active_broadcasters)
                activeBroadcasters = target.status.active_broadcasters;
            else if (target.status && target.status.active_broadcaster)
                activeBroadcasters = [target.status.active_broadcaster];

            let actualBroadcaster: Broadcaster | null = null;
            if (activeBroadcasters.length > 0) {
                await this.broadcastersService.refreshBroadcaster(activeBroadcasters[0].id, false).toPromise();
                actualBroadcaster = this.broadcastersService.getCachedBroadcaster(activeBroadcasters[0].id);
            } else {
                actualBroadcaster = channelBroadcaster;
            }

            // Add Target Object to processingCluster Group
            this.addObjectToBroadcaster(
                mermaidObject,
                channel.processingCluster.name,
                actualBroadcaster?.name,
                "target",
                target.id,
                targetApiType
            );

            // Add Broadcaster to diagram
            if (actualBroadcaster) {
                this.addBroadcasterObject(mermaidObject, actualBroadcaster);
                this.addObjectToBroadcaster(
                    mermaidObject,
                    channel.processingCluster.name,
                    actualBroadcaster.name,
                    "broadcaster",
                    actualBroadcaster.id
                );
            }

            if (activeBroadcasters[0]?.source_id)
                this.addPath(
                    mermaidObject,
                    "source",
                    activeBroadcasters[0].source_id,
                    "target",
                    target.id,
                    undefined,
                    targetApiType
                );
            else if (target.preferred_source > 0) {
                this.addPath(
                    mermaidObject,
                    "source",
                    target.preferred_source,
                    "target",
                    target.id,
                    undefined,
                    targetApiType
                );
            }
        } else {
            if (!awsMediaChannel && channel) {
                this.addObjectToBroadcaster(
                    mermaidObject,
                    channel.processingCluster.name,
                    channelBroadcaster?.name,
                    "target",
                    target.id,
                    targetApiType
                );
            }

            if (channel instanceof AdaptiveChannel || awsMediaChannel) {
                this.addPath(mermaidObject, "channel", channel.id, "target", target.id, channelSubtype, targetApiType);
            } else if (channel instanceof FailoverChannel) {
                this.addPath(
                    mermaidObject,
                    "source",
                    channel.failover_source_id,
                    "target",
                    target.id,
                    undefined,
                    targetApiType
                );
            }
        }

        if (target instanceof ZixiPullTarget) {
            if (target.receiver_id && target.receiver) {
                let receiver = target.receiver;
                if (!receiver.status) {
                    await this.zecsService.refreshZec(receiver.id, "RECEIVER", false).toPromise();
                    receiver = this.zecsService.getCachedZec("RECEIVER", null, receiver.id) as Receiver;
                }
                // Receiver Object & Status
                this.addReceiverObject(mermaidObject, receiver);
                // zixiPull to Receiver Path
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "receiver",
                    target.receiver_id,
                    this.targetsService.getTargetApiType(target)
                );
            } else if (target.broadcaster_id && target.broadcaster) {
                let broadcaster = target.broadcaster;
                if (!broadcaster.status) {
                    await this.broadcastersService.refreshBroadcaster(broadcaster.id, false).toPromise();
                    broadcaster = this.broadcastersService.getCachedBroadcaster(broadcaster.id);
                }
                // Broadcaster Object & Status
                this.addBroadcasterObject(mermaidObject, broadcaster, true);
                // zixiPull to Broadcaster Path
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "broadcaster",
                    target.broadcaster_id,
                    this.targetsService.getTargetApiType(target)
                );
            } else if (target.zec_id && target.zec) {
                let zec = target.zec;
                if (!zec.status) {
                    await lastValueFrom(this.zecsService.refreshZec(zec.id, "ZEC", false));
                    zec = this.zecsService.getCachedZec("ZEC", null, zec.id) as Zec;
                }
                // ZEC Object & Status
                this.addZecObject(mermaidObject, zec);
                // zixiPull to Receiver Path
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "zec",
                    target.zec_id,
                    this.targetsService.getTargetApiType(target)
                );
            }
        }
    }

    private async addSourceFromObject(
        mermaidObject: MermaidObject,
        source: Source,
        standaloneSource?: boolean,
        failoverChannel?: FailoverChannel
    ) {
        // Refresh source data
        if (
            !source.readOnly &&
            (standaloneSource ||
                !source.status ||
                this.sharedService.isEmptyObject(source.status) ||
                !source._frontData)
        ) {
            await this.sourcesService.refreshSource(source.id, false).toPromise();
            source = Object.assign({}, this.sourcesService.getCachedSource(null, null, source.id));
        }

        const sourceType = source.zixi ? "source" : "mediaconnect_source";
        // Source Object & Status
        source.zixi
            ? this.addSourceObject(mermaidObject, source, failoverChannel)
            : this.addMediaConnectSourceObject(mermaidObject, source as unknown as MediaConnectSource); // TODO: fix this garbage cast

        if (source.hitless_failover_source_ids?.length > 0 && source.failoverSources.length > 0) {
            for (const failover of source.failoverSources) {
                let failoverSource = failover.source;

                await this.sourcesService.refreshSource(failoverSource.id, false).toPromise();
                failoverSource = Object.assign({}, this.sourcesService.getCachedSource(null, null, failoverSource.id));

                if (failoverSource?.id) {
                    const tags = failover.is_active ? ["Active"] : [];
                    tags.push(failover.priority === 2 ? "Primary" : failover.priority === 1 ? "Secondary" : "Slate");

                    await this.addSourceFromObject(mermaidObject, failoverSource, true);
                    this.addPath(
                        mermaidObject,
                        sourceType,
                        failoverSource.id,
                        sourceType,
                        source.id,
                        undefined,
                        undefined,
                        tags
                    );
                }
            }
        }

        if (source.multiplexSources) {
            for (const multiplexComponent of source.multiplexSources) {
                let multiplexSource = multiplexComponent.source;

                await this.sourcesService.refreshSource(multiplexSource.id, false).toPromise();
                multiplexSource = Object.assign(
                    {},
                    this.sourcesService.getCachedSource(null, null, multiplexSource.id)
                );

                if (multiplexSource?.id) {
                    await this.addSourceFromObject(mermaidObject, multiplexSource, true);
                    this.addPath(mermaidObject, sourceType, multiplexSource.id, sourceType, source.id);
                }
            }
        }

        // Intercluster/Chained/Transcoded Sources
        if (source.transcode_source_id ?? source.source_id) {
            let sourceSource = source.transcodeSource ?? source.Source;

            await this.sourcesService.refreshSource(source.transcode_source_id ?? source.source_id, false).toPromise();
            sourceSource = Object.assign(
                {},
                this.sourcesService.getCachedSource(null, null, source.transcode_source_id ?? source.source_id)
            );

            await this.addSourceFromObject(mermaidObject, sourceSource, true);
            this.addPath(mermaidObject, sourceType, sourceSource.id, sourceType, source.id);
        }

        if (source.type === "multiview" && source.multiviewSources?.length > 0) {
            await Promise.all(
                source.multiviewSources.map(async mv => {
                    await this.sourcesService.refreshSource(mv.source_id, false).toPromise();
                    const mvSource = Object.assign({}, this.sourcesService.getCachedSource(null, null, mv.source_id));

                    await this.addSourceFromObject(mermaidObject, mvSource, true);
                    this.addPath(mermaidObject, sourceType, mvSource.id, sourceType, source.id);
                })
            );
        }

        let sourceActiveBroadcaster: Broadcaster | null = null;

        let inputCluster = source.inputCluster;
        if (inputCluster && !inputCluster.readOnly) {
            await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
            inputCluster = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);
        }

        const sourceActiveBroadcasterId =
            source.target_broadcaster_id > 0 ? source.target_broadcaster_id : source.activeBroadcasterObjects?.bx_id;

        if (sourceActiveBroadcasterId) {
            sourceActiveBroadcaster = inputCluster.broadcasters?.find(({ id }) => id === sourceActiveBroadcasterId);
        }

        if (source.inputCluster) {
            this.addObjectToBroadcaster(
                mermaidObject,
                source.inputCluster.name,
                sourceActiveBroadcaster?.name,
                sourceType,
                source.id
            );
            if (sourceActiveBroadcaster) {
                this.addBroadcasterObject(mermaidObject, sourceActiveBroadcaster);
                this.addObjectToBroadcaster(
                    mermaidObject,
                    inputCluster.name,
                    sourceActiveBroadcaster.name,
                    "broadcaster",
                    sourceActiveBroadcaster.id
                );
            }
        }

        // Source Feeder
        if (source.feeder_id && source.feeder) {
            let f = source.feeder;
            if (!source.feeder.readOnly) {
                await this.zecsService.refreshZec(source.feeder_id, "FEEDER", false).toPromise();
                f = Object.assign({}, this.zecsService.getCachedZec("FEEDER", null, source.feeder_id)) as Feeder;
            }
            this.addFeederObject(mermaidObject, f);
            this.addPath(mermaidObject, "feeder", source.feeder_id, sourceType, source.id);
        }

        // Source Broadcaster
        if (source.broadcaster_id && source.broadcaster) {
            let b = source.broadcaster;
            if (!source.broadcaster.readOnly) {
                await this.broadcastersService.refreshBroadcaster(source.broadcaster_id, false).toPromise();
                b = this.broadcastersService.getCachedBroadcaster(source.broadcaster_id);
            }
            this.addBroadcasterObject(mermaidObject, b, true);
            this.addPath(mermaidObject, "broadcaster", source.broadcaster_id, sourceType, source.id);
        }

        // Source ZEC
        if (source.zec_id && source.zec) {
            let z = source.zec;
            if (!source.zec.readOnly) {
                await lastValueFrom(this.zecsService.refreshZec(source.zec_id, "ZEC", false));
                z = Object.assign({}, this.zecsService.getCachedZec("ZEC", null, source.zec_id)) as Zec;
            }
            this.addZecObject(mermaidObject, z);
            this.addPath(mermaidObject, "zec", source.zec_id, sourceType, source.id);
        }
    }

    private async addMediaConnectSourceFromObject(
        mermaidObject: MermaidObject,
        channel: MediaConnectFlow | MediaLiveChannel,
        source: MediaConnectSource
    ) {
        // Source Object & Status
        this.addMediaConnectSourceObject(mermaidObject, source);
        if (channel)
            this.addPath(
                mermaidObject,
                "mediaconnect_source",
                source.id,
                "channel",
                channel.id,
                null,
                channel.medialive ? "ml" : "mcc"
            );

        // Source Feeder
        if (source.feeder_id && source.feeder) {
            let f = source.feeder;
            if (!source.feeder.readOnly) {
                await this.zecsService.refreshZec(source.feeder_id, "FEEDER", false).toPromise();
                f = Object.assign({}, this.zecsService.getCachedZec("FEEDER", null, source.feeder_id)) as Feeder;
            }
            this.addFeederObject(mermaidObject, f);
            this.addPath(mermaidObject, "feeder", f.id, "mediaconnect_source", source.id);
        }

        // Source Broadcaster
        if (source.broadcaster_id && source.broadcaster) {
            let b = source.broadcaster;
            if (!source.broadcaster.readOnly) {
                await this.broadcastersService.refreshBroadcaster(source.broadcaster_id, false).toPromise();
                b = this.broadcastersService.getCachedBroadcaster(source.broadcaster_id);
            }

            this.addBroadcasterObject(mermaidObject, b, true);
            this.addPath(mermaidObject, "broadcaster", b.id, "mediaconnect_source", source.id);
        }

        // Source ZEC
        if (source.zec_id && source.zec) {
            let z = source.zec;
            if (!source.zec.readOnly) {
                await lastValueFrom(this.zecsService.refreshZec(source.zec_id, "ZEC", false));
                z = Object.assign({}, this.zecsService.getCachedZec("ZEC", null, source.zec_id)) as Zec;
            }
            this.addZecObject(mermaidObject, z);
            this.addPath(mermaidObject, "zec", z.id, "mediaconnect_source", source.id);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async mermaidProcess(selectedObject: any) {
        this.mermaidClasses = "";
        const mermaidObject: MermaidObject = {
            objects: {},
            paths: {},
            status: {},
            clusters: {},
            groups: {},
            clicks: []
        };

        if (!selectedObject) return;

        // Target
        if (this.type === "target") {
            await this.addTarget(mermaidObject, selectedObject);
        }

        // Feeder
        if (this.type === "feeder") {
            await this.addFeeder(mermaidObject, selectedObject);
        }

        // Receiver
        if (this.type === "receiver") {
            await this.addReceiver(mermaidObject, selectedObject);
        }

        // ZEC
        if (this.type === "zec") {
            await this.addZec(mermaidObject, selectedObject);
        }

        // Source
        if (this.type === "source") {
            await this.addSource(mermaidObject, selectedObject);
        }

        // Channel
        if (this.type === "channel") {
            await this.addChannel(mermaidObject, selectedObject as unknown as ChannelTypes, false);
        }

        // Broadcaster
        if (this.type === "broadcaster") {
            await this.addBroadcaster(mermaidObject, selectedObject);
        }

        await this.addDiagramEventsObjects(mermaidObject);

        this.mermaidData =
            _.reduce(
                mermaidObject.objects,
                (mermaidString, objectValue, objectKey) => {
                    if (this.hideBroadcasters && objectValue.server) return mermaidString;
                    return mermaidString + "\n" + objectKey + '("' + objectValue.value + '");';
                },
                ""
            ) +
            _.reduce(
                mermaidObject.paths,
                (mermaidString, pathToConfigs, pathFrom) => {
                    if (this.hideBroadcasters && mermaidObject.objects[pathFrom]?.server) return mermaidString;

                    _.each(pathToConfigs, (pathConfig, pathTo: string) => {
                        if (this.hideBroadcasters && mermaidObject.objects[pathTo]?.server) return mermaidString;

                        const text = (pathConfig.tags?.length ?? 0) > 0 ? `| ${pathConfig.tags.join(", ")} |` : "";

                        mermaidString = mermaidString + "\n" + pathFrom + "-->" + text + pathTo;
                    });
                    return mermaidString;
                },
                ""
            ) +
            _.reduce(
                mermaidObject.status,
                (mermaidString, objectValue, objectKey) => {
                    return mermaidString + "\n" + "class " + objectKey + " " + objectValue;
                },
                ""
            ) +
            _.reduce(
                _.uniq(mermaidObject.clicks),
                (mermaidString, click) => {
                    return mermaidString + "\n" + "click " + click + " clickEvent";
                },
                ""
            ) +
            Object.entries(mermaidObject.clusters).reduce((mermaidString, [clusterKey, clusterBroadcasters]) => {
                if (Object.entries(clusterBroadcasters).length === 0) return mermaidString;

                const clusterContent = Object.entries(clusterBroadcasters).reduce(
                    (mermaidString, [broadcasterKey, broadcasterObjectKeys]) => {
                        const broadcasterContent = _.uniq(broadcasterObjectKeys).reduce(
                            (broadcasterContent, objectKey) => {
                                if (this.hideBroadcasters && mermaidObject.objects[objectKey]?.server)
                                    return broadcasterContent;
                                return broadcasterContent + "\n" + objectKey;
                            },
                            ""
                        );

                        if (broadcasterContent?.length > 0)
                            mermaidString +=
                                "\n" + 'subgraph "' + broadcasterKey + '"' + broadcasterContent + "\n" + "end";
                        return mermaidString;
                    },
                    ""
                );

                if (clusterContent?.length > 0)
                    mermaidString += "\n" + 'subgraph "Cluster - ' + clusterKey + '"' + clusterContent + "\n" + "end";
                return mermaidString;
            }, "") +
            Object.entries(mermaidObject.groups).reduce((mermaidString, [key, objectKys]) => {
                mermaidString += "\n" + 'subgraph "' + key + '"';
                _.each(_.uniq(objectKys), objectKey => {
                    if (this.hideBroadcasters && mermaidObject.objects[objectKey]?.server) return;
                    mermaidString += "\n" + objectKey;
                });
                return mermaidString + "\n" + "end";
            }, "");
    }

    // Get Prefix
    private getPrefix(type: string) {
        if (type === "feeder") return "f-";
        if (type === "broadcaster") return "b-";
        if (type === "no_broadcaster") return "nb-";
        if (type === "source") return "s-";
        if (type === "mediaconnect_source") return "mc-";
        if (type === "target") return "t-";
        if (type === "receiver") return "r-";
        if (type === "channel") return "c-";
        if (type === "zec") return "z-";
    }

    private getPostFix(subtype: string) {
        return "-" + subtype;
    }

    // Determine State
    private determineState(
        o: Broadcaster | Feeder | Receiver | Zec | Source | MediaConnectSource | TargetObjectType | ChannelTypes
    ) {
        if (
            o.generalStatus === "no_source" ||
            o.generalStatus === "no_flow" ||
            o.generalStatus === "flow_disabled" ||
            o.generalStatus === "no_channel" ||
            o.generalStatus === "channel_disabled"
        )
            return "disabled";
        else if (
            o.objectState /*&& o.state !== "pending"*/ &&
            (o.objectState.state === "error" || o.objectState.state === "warning")
        )
            return o.objectState.state;
        else if (o.is_enabled) return o.state === "pending" ? o.state : o.generalStatus;
        else return "disabled";
    }

    private addFeederObject(object: MermaidObject, x: Feeder) {
        const prefix = this.getPrefix("feeder");
        object.objects[prefix + x.id] = {
            type: "feeder",
            stream: false,
            server: false,
            value: this.feederContent(x)
        };
        object.status[prefix + x.id] = prefix + x.generalStatus;
        this.addObjectClick(object, "feeder", x.id);
    }
    private addZecObject(object: MermaidObject, x: Zec) {
        const prefix = this.getPrefix("zec");
        object.objects[prefix + x.id] = {
            type: "zec",
            stream: false,
            server: false,
            value: this.zecContent(x)
        };
        object.status[prefix + x.id] = prefix + x.generalStatus;
        this.addObjectClick(object, "zec", x.id);
    }
    private addBroadcasterObject(object: MermaidObject, x: Broadcaster, client?: boolean) {
        const prefix = this.getPrefix("broadcaster");
        object.objects[prefix + x.id] = {
            type: "broadcaster",
            stream: false,
            server: !client,
            value: this.broadcasterContent(x)
        };
        object.status[prefix + x.id] = prefix + x.generalStatus;
        this.addObjectClick(object, "broadcaster", x.id);
    }
    private addSourceObject(object: MermaidObject, x: Source, failoverChannel?: FailoverChannel) {
        const prefix = this.getPrefix("source");
        object.objects[prefix + x.id] = {
            type: "source",
            stream: true,
            server: false,
            value: this.sourceContent(x, false, failoverChannel)
        };
        object.status[prefix + x.id] = prefix + x.generalStatus;
        this.addObjectClick(object, "source", x.id);
    }
    private addMediaConnectSourceObject(object: MermaidObject, x: MediaConnectSource) {
        const prefix = this.getPrefix("mediaconnect_source");
        object.objects[prefix + x.id] = {
            type: "mediaconnect_source",
            stream: true,
            server: false,
            value: this.sourceContent(x, true)
        };
        object.status[prefix + x.id] = prefix + x.generalStatus;
        this.addObjectClick(object, "mediaconnect_source", x.id);
    }
    private addTargetObject(object: MermaidObject, anyT: AnyTarget, subtype?: string, channel?: ChannelTypes) {
        const x = anyT.target;
        const prefix = this.getPrefix("target");
        let postfix = subtype ? this.getPostFix(subtype) : "";
        object.objects[prefix + x.id + postfix] = {
            type: "target",
            stream: true,
            server: false,
            value: this.targetContent(anyT, channel)
        };
        object.status[prefix + x.id + postfix] = prefix + x.generalStatus + postfix;

        this.addObjectClick(object, "target", x.id, subtype);
    }
    private addReceiverObject(object: MermaidObject, x: Receiver) {
        const prefix = this.getPrefix("receiver");
        object.objects[prefix + x.id] = {
            type: "receiver",
            stream: false,
            server: false,
            value: this.receiverContent(x)
        };
        object.status[prefix + x.id] = prefix + x.generalStatus;
        this.addObjectClick(object, "receiver", x.id);
    }
    private addChannelObject(object: MermaidObject, x: ChannelTypes, subtype?: string) {
        const prefix = this.getPrefix("channel");
        let postfix = subtype ? this.getPostFix(subtype) : "";
        object.objects[prefix + x.id + postfix] = {
            type: "channel",
            stream: true,
            server: false,
            value: this.channelContent(x)
        };
        object.status[prefix + x.id + postfix] = prefix + x.generalStatus + postfix;
        this.addObjectClick(object, "channel", x.id, subtype);
    }

    // Add Path
    private addPath(
        object: MermaidObject,
        type1: string,
        id1: string | number,
        type2: string,
        id2: string | number,
        subtype1?: string,
        subtype2?: string,
        tags?: string[]
    ) {
        const prefix1 = this.getPrefix(type1);
        const prefix2 = this.getPrefix(type2);
        let postfix1;
        let postfix2;
        if (subtype1) postfix1 = this.getPostFix(subtype1);
        if (subtype2) postfix2 = this.getPostFix(subtype2);
        object.paths[prefix1 + id1 + (postfix1 ? postfix1 : "")] =
            object.paths[prefix1 + id1 + (postfix1 ? postfix1 : "")] || {};
        object.paths[prefix1 + id1 + (postfix1 ? postfix1 : "")][prefix2 + id2 + (postfix2 ? postfix2 : "")] = {
            tags
        };
    }

    private addObjectToBroadcaster(
        object: MermaidObject,
        cluster: string,
        broadcaster: Broadcaster | string | undefined,
        type: string,
        id: string | number,
        subtype?: string
    ) {
        const broadcasterKey =
            broadcaster instanceof Broadcaster ? broadcaster.name : broadcaster ?? "No Active Broadcaster";
        const prefix = this.getPrefix(type);
        let postfix = "";
        if (subtype) postfix = this.getPostFix(subtype);
        object.clusters[cluster] = object.clusters[cluster] || {};
        object.clusters[cluster][broadcasterKey] = object.clusters[cluster][broadcasterKey] || [];
        object.clusters[cluster][broadcasterKey].push(prefix + id + (postfix ? postfix : ""));
    }

    // Add Click Event
    private addObjectClick(object: MermaidObject, type: string, id: number | string, subtype?: string) {
        /* const prefix = this.getPrefix(type);
        const postfix = subtype ? this.getPostFix(subtype) : "";
        object.clicks.push(prefix + id + postfix); */
    }

    // Get Status Icon
    private getStatusIcon(status?: string) {
        if (status) {
            // Good
            if (status === "good") {
                return "<i class='fa fa-check-circle fa-sm status-good'></i>&nbsp;";
                // Bad
            } else if (status === "bad" || status === "error") {
                return "<i class='fa fa-minus-circle fa-sm status-bad'></i>&nbsp;";
                // Disabled or No
            } else if (
                status === "no" ||
                status === "disabled" ||
                status === "no_source" ||
                status === "no_flow" ||
                status === "flow_disabled" ||
                status === "no_channel" ||
                status === "channel_disabled"
            ) {
                return "<i class='fa fa-ban fa-sm status-disabled'></i>&nbsp;";
                // Pending
            } else if (status === "pending") {
                return "<i class='fa fa-dot-circle fa-sm status-pending'></i>&nbsp;";
                // Default
            } else if (status === "warning" || status === "med") {
                return "<i class='fa fa-exclamation-circle fa-sm status-warning'></i>&nbsp;";
                // Default
            } else {
                return "<i class='fa fa-circle fa-sm'></i>&nbsp;";
            }
        }
    }

    // Channel Content
    private channelContent(c: ChannelTypes) {
        let channelType = "";
        // Handle MediaConnect Flow and MediaLiveChannel
        if (c instanceof MediaConnectFlow || c instanceof MediaLiveChannel) {
            if (c.medialive) {
                channelType = "MediaLive Channel";
            } else {
                channelType = "MediaConnect Flow";
                c.type = "mediaconnect";
                c.mediaconnect = true;
            }
        }

        let Icon = "";
        let displayError = "";
        let displayBitrates = "";
        let Details = "";
        // Channel Status Icon
        Icon = this.getStatusIcon(this.determineState(c));
        // Channel Short Error Message
        const errorState = this.sharedService.getLastError(c);
        if (errorState) {
            const prettyError = errorState.message.replace(/"|'/g, "`");
            displayError =
                "<span>Error: " +
                "<span class='error' title='" +
                prettyError +
                "'>" +
                errorState.short_message +
                "</span></span> <br/>";
        }
        // Channel Bitrates
        if (c instanceof AdaptiveChannel) {
            let Bitrates = "";
            let label = "";
            // Add Each Bitrate
            _.each(c.bitrates, bitrate => {
                let message = "";
                // Bitrate Error Message
                if (bitrate.error && bitrate.error.event_type !== "success") {
                    message = " - " + bitrate.error.short_message;
                }

                let Profile = "";
                // Bitrate Profile
                if (bitrate.profile) {
                    if (bitrate.profile.do_video) {
                        Profile +=
                            "Encoding Profile: " +
                            this.translate.instant(bitrate.profile.encoding_profile.toUpperCase()) +
                            "#13;" +
                            "Resolution: " +
                            bitrate.profile.width +
                            "x" +
                            bitrate.profile.height +
                            "#13;" +
                            "FPS: " +
                            (bitrate.profile.fps || "Original") +
                            "#13;" +
                            "Avg. Video Bitrate: " +
                            bitrate.profile.bitrate_avg +
                            "#13;" +
                            "Max. Video Bitrate: " +
                            bitrate.profile.bitrate_max +
                            "#13;" +
                            "Performance: " +
                            this.constants.videoPerformances[bitrate.profile.performance].name +
                            "#13;#13;";
                    }

                    if (!bitrate.profile.do_video) {
                        Profile += "Video: " + (bitrate.profile.keep_video ? "Original" : "Remove") + "#13;#13;";
                    }

                    if (bitrate.profile.do_audio) {
                        Profile +=
                            "Audio Profile: " +
                            this.constants.audioProfiles[bitrate.profile.audio_encoder_profile] +
                            "#13;" +
                            "Audio Bitrate: " +
                            bitrate.profile.audio_bitrate +
                            "#13;" +
                            "Audio Sample Rate: " +
                            (bitrate.profile.audio_sample_rate || "Original");
                    }

                    if (!bitrate.profile.do_audio) {
                        Profile += "Audio: " + (bitrate.profile.keep_audio ? "Original" : "Remove");
                    }
                }

                // Bitrate Profile
                Bitrates +=
                    "  <span class='" +
                    (bitrate.profile ? "info" : "") +
                    "' title='" +
                    Profile +
                    "'>" +
                    bitrate.kbps +
                    " kbps" +
                    message +
                    "</span><br/>";
            });
            // Bitrate Label
            if (c.bitrates.length > 1) {
                label = "Bitrates:";
            } else {
                label = "Bitrate:";
            }
            displayBitrates = "<span>" + label + "<br/>" + "<span>" + Bitrates + "</span></span>";
        }
        //
        if (!this.hideDetails) {
            Details = displayBitrates;
        }
        //
        return (
            "<span><i class='fa fa-project-diagram fa-sm'></i> " +
            (c.mediaconnect || c.medialive ? channelType : "Channel") +
            "</span>  <br/>" +
            "<span class='name'>" +
            Icon +
            "<a href='/channels/" +
            (c.type === "delivery" ? "pass-through" : c.type) +
            "/" +
            urlBuilder.encode(c.id) +
            "/" +
            this.urlBuildService.encodeRFC3986URIComponent(c.name) +
            "'>" +
            c.name +
            "</a></span><br/>" +
            displayError +
            Details
        );
    }

    // Broadcaster Content
    private broadcasterContent(b: Broadcaster) {
        let Icon = "";
        let IP = "";
        let CPU = "";
        let Memory = "";
        let InBitrate = "";
        let OutBitrate = "";
        let Version = "";
        let displayError = "";
        let Link = "";
        let Details = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(b));
        // Error Message
        const errorState = this.sharedService.getLastError(b);
        if (errorState) {
            displayError =
                "<span>Error: " +
                "<span class='error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span><br/>";
        }
        if (b.configure_link) {
            Link = b.configure_link;
        }
        if (b.status) {
            // IP
            if (b.status.source_ip) {
                IP = "<span>IP: " + b.status.source_ip + "</span> <br/>";
            }
            // CPU
            if (b.status.cpu) {
                CPU = "<span>CPU: " + this.percentPipe.transform(b.status.cpu / 100, "1.0-2") + "</span> <br/>";
            }
            // Memory
            if (b.status.ram) {
                Memory = "<span>Memory: " + this.percentPipe.transform(b.status.ram / 100, "1.0-2") + "</span> <br/>";
            }
            // Input Bitrate
            if (b.status.input_kbps) {
                InBitrate = "<span>In Bitrate: " + b.status.input_kbps + "</span> <br/>";
            }
            // Out Bitrate
            if (b.status.output_kbps) {
                OutBitrate = "<span>Out Bitrate: " + b.status.output_kbps + "</span> <br/>";
            }
            // Version
            if (b.status.about) {
                Version =
                    "<span>Version: " +
                    b.status.about.version_minor +
                    "." +
                    b.status.about.version_minor2 +
                    "." +
                    b.status.about.version_build +
                    "</span>";
            }
        }
        //
        if (!this.hideDetails) {
            Details = IP + CPU + Memory + InBitrate + OutBitrate + Version;
        }
        //
        if (b.readOnly) {
            return (
                "<span><i class='fa fa-cloud fa-sm'></i> " +
                "Broadcaster" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                b.name +
                "</span><br/>" +
                displayError
            );
        } else {
            return (
                "<span><i class='fa fa-cloud fa-sm'></i> " +
                "Broadcaster" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                "<a href='/clusters/" +
                urlBuilder.encode(b.broadcaster_cluster_id) +
                "/broadcasters/" +
                urlBuilder.encode(b.id) +
                "/" +
                this.urlBuildService.encodeRFC3986URIComponent(b.name) +
                "'>" +
                b.name +
                "</a> - <a href='" +
                Link +
                "' title='Open' target='_blank'><i class='fa fa-external-link-alt fa-xs'></i></a></span><br/>" +
                displayError +
                Details
            );
        }
    }

    // Feeder Content
    private feederContent(f: Feeder) {
        let Icon = "";
        let IP = "";
        let CPU = "";
        let Memory = "";
        let Version = "";
        let displayError = "";
        let Link = "";
        let Details = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(f));
        // Error Message
        const errorState = this.sharedService.getLastError(f);
        if (errorState) {
            displayError =
                "<span>Error: " +
                "<span class='error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span><br/>";
        }
        if (f.configure_link) {
            Link = f.configure_link;
        }
        if (f.status) {
            // IP
            if (f.status.source_ip) {
                IP = "<span>IP: " + f.status.source_ip + "</span> <br/>";
            }
            // CPU
            if (f.status.cpu) {
                CPU = "<span>CPU: " + this.percentPipe.transform(f.status.cpu / 100, "1.0-2") + "</span> <br/>";
            }
            // Memory
            if (f.status.ram) {
                Memory = "<span>Memory: " + this.percentPipe.transform(f.status.ram / 100, "1.0-2") + "</span> <br/>";
            }
            // Version
            if (f.status.about) {
                Version =
                    "<span>Version: " +
                    f.status.about.version_minor +
                    "." +
                    f.status.about.version_minor2 +
                    "." +
                    f.status.about.version_build +
                    "</span>";
            }
        }
        //
        if (!this.hideDetails) {
            Details = IP + CPU + Memory + Version;
        }
        //
        if (f.readOnly) {
            return (
                "<span><i class='fa fa-rss fa-sm'></i> " +
                "Feeder" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                f.name +
                "</span><br/>" +
                displayError
            );
        } else
            return (
                "<span><i class='fa fa-rss fa-sm'></i> " +
                "Feeder" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                "<a href='/zec/feeder/" +
                urlBuilder.encode(f.id) +
                "/" +
                this.urlBuildService.encodeRFC3986URIComponent(f.name) +
                "'>" +
                f.name +
                "</a> - <a href='" +
                Link +
                "' title='Open' target='_blank'><i class='fa fa-external-link-alt fa-xs'></i></a></span><br/>" +
                displayError +
                Details
            );
    }

    // ZEC Content
    private zecContent(z: Zec) {
        // Check If ZEC Exists

        let Icon = "";
        let IP = "";
        let CPU = "";
        let Memory = "";
        let InBitrate = "";
        let OutBitrate = "";
        let Version = "";
        let displayError = "";
        let Link = "";
        let Details = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(z));
        // Error Message
        const errorState = this.sharedService.getLastError(z);
        if (errorState) {
            displayError =
                "<span>Error: " +
                "<span class='error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span><br/>";
        }
        if (z.configure_link) {
            Link = z.configure_link;
        }
        if (z.status) {
            // IP
            if (z.status.source_ip) {
                IP = "<span>IP: " + z.status.source_ip + "</span> <br/>";
            }
            // CPU
            if (z.status.cpu) {
                CPU = "<span>CPU: " + this.percentPipe.transform(z.status.cpu / 100, "1.0-2") + "</span> <br/>";
            }
            // Memory
            if (z.status.ram) {
                Memory = "<span>Memory: " + this.percentPipe.transform(z.status.ram / 100, "1.0-2") + "</span> <br/>";
            }
            // Input Bitrate
            if (z.status.input_kbps) {
                InBitrate = "<span>In Bitrate: " + z.status.input_kbps + "</span> <br/>";
            }
            // Out Bitrate
            if (z.status.output_kbps) {
                OutBitrate = "<span>Out Bitrate: " + z.status.output_kbps + "</span> <br/>";
            }
            // Version
            if (z.status.about) {
                Version =
                    "<span>Version: " +
                    z.status.about.version_minor +
                    "." +
                    z.status.about.version_minor2 +
                    "." +
                    z.status.about.version_build +
                    "</span>";
            }
        }
        //
        if (!this.hideDetails) {
            Details = IP + CPU + Memory + InBitrate + OutBitrate + Version;
        }
        //
        if (z.readOnly) {
            return (
                "<span><i class='fa fa-cloud fa-sm'></i> " +
                "ZEC" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                z.name +
                "</span><br/>" +
                displayError
            );
        } else {
            return (
                "<span><i class='fa fa-cloud fa-sm'></i> " +
                "ZEC" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                "<a href='/zec/zec/" +
                urlBuilder.encode(z.id) +
                "/" +
                this.urlBuildService.encodeRFC3986URIComponent(z.name) +
                "'>" +
                z.name +
                "</a> - <a href='" +
                Link +
                "' title='Open' target='_blank'><i class='fa fa-external-link-alt fa-xs'></i></a></span><br/>" +
                displayError +
                Details
            );
        }
    }

    // Source Content
    private sourceContent(
        source: Source | MediaConnectSource,
        mediaconnect: boolean,
        failoverChannel?: FailoverChannel
    ) {
        let s: Source | MediaConnectSource;
        if (mediaconnect) {
            s = new MediaConnectSource();
            Object.assign(s, source as MediaConnectSource);
            s.zixi = false;
        } else {
            s = new Source();
            Object.assign(s, source as Source);
            s.zixi = true;
        }
        //
        let Icon = "";
        let displayProfile = "";
        let Bitrate = "";
        let healthscore: string | null = null;
        let TR101 = "";
        let P1 = "-";
        let P2 = "-";
        let displayType = "";
        let displayInput = "";
        let displaySources = "";
        let displayError = "";
        let Latency = "";
        let Details = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(s));
        // Error Message
        const errorState = this.sharedService.getLastError(s);
        if (errorState) {
            displayError =
                "<span>Error: " +
                "<span class='error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span><br/>";
        }
        // Healthscore
        if (source.health?.healthScore != null) {
            if (source.health?.healthScore <= 30) {
                healthscore = "<i class='fa fa-minus-circle fa-sm status-error'></i> ";
            } else if (source.health?.healthScore > 30 && source.health?.healthScore <= 80) {
                healthscore = "<i class='fa fa-exclamation-circle fa-sm status-warning'></i> ";
            } else {
                healthscore = "<i class='fa fa-check-circle fa-sm status-good'></i> ";
            }
            healthscore += " " + source.health?.healthScore.toFixed(0);
        }
        // Bitrate & TR101 Status
        if (s.status) {
            // Bitrate
            if (s.status.bitrate) {
                Bitrate = "<span>Bitrate: " + s.status.bitrate.toString() + "</span><br/>";
            }
            // TR101
            if (s.status.tr101) {
                if (s.status.tr101.status) {
                    // p1
                    if (s.status.tr101.status.p1_ok) {
                        P1 = "<i class='fa fa-check-circle fa-sm status-good'></i> ";
                    } else if (s.status.tr101.status.p1_ok === 0 || s.status.tr101.status.p1_ok === false) {
                        P1 = "<i class='fa fa-minus-circle fa-sm status-bad'></i> ";
                    }
                    // p2
                    if (s.status.tr101.status.p2_ok) {
                        P2 = "<i class='fa fa-check-circle fa-sm status-good'></i>";
                    } else if (s.status.tr101.status.p2_ok === 0 || s.status.tr101.status.p2_ok === false) {
                        P2 = "<i class='fa fa-minus-circle fa-sm status-bad'></i>";
                    }
                    TR101 = "<span>TR101: " + P1 + P2 + "</span> <br/>";
                }
            }
        }

        if (s.zixi) {
            if (s.transcodeProfile) {
                // Profile
                let Profile = "";

                if (s.transcodeProfile.do_video) {
                    Profile +=
                        "Encoding Profile: " +
                        this.translate.instant(s.transcodeProfile.encoding_profile.toUpperCase()) +
                        "#13;" +
                        "Resolution: " +
                        s.transcodeProfile.width +
                        "x" +
                        s.transcodeProfile.height +
                        "#13;" +
                        "FPS: " +
                        (s.transcodeProfile.fps || "Original") +
                        "#13;" +
                        "Avg. Video Bitrate: " +
                        s.transcodeProfile.bitrate_avg +
                        "#13;" +
                        "Max. Video Bitrate: " +
                        s.transcodeProfile.bitrate_max +
                        "#13;" +
                        "Performance: " +
                        this.constants.videoPerformances[s.transcodeProfile.performance].name +
                        "#13;#13;";
                }

                if (!s.transcodeProfile.do_video) {
                    Profile += "Video: " + (s.transcodeProfile.keep_video ? "Original" : "Remove") + "#13;#13;";
                }

                if (s.transcodeProfile.do_audio) {
                    Profile +=
                        "Audio Profile: " +
                        this.constants.audioProfiles[s.transcodeProfile.audio_encoder_profile] +
                        "#13;" +
                        "Audio Bitrate: " +
                        s.transcodeProfile.audio_bitrate +
                        "#13;" +
                        "Audio Sample Rate: " +
                        (s.transcodeProfile.audio_sample_rate || "Original");
                }

                if (!s.transcodeProfile.do_audio) {
                    Profile += "Audio: " + (s.transcodeProfile.keep_audio ? "Original" : "Remove");
                }

                displayProfile =
                    "<span>Profile: " +
                    "<span class='info' title='" +
                    Profile +
                    "'>" +
                    s.transcodeProfile.name +
                    "</span></span>";
            }
        }

        // Input
        if (s.input_id) {
            let str = "";
            let subst = "";
            let InputVal = "";
            // Feeder Input
            if (s.feeder_id && s.feeder?.status?.inputs?.find(({ name }) => name === s.input_id)) {
                str = this.feederInputPipe.transform(_.find(s.feeder.status.inputs, { name: s.input_id }));
                subst = "<br/>";
                InputVal = str.replace(/[()]/g, subst);
            }
            // Broadcaster Input
            else if (s.broadcaster_id && s.broadcaster?.status?.inputs?.find(({ id }) => id === s.input_id)) {
                str = this.broadcasterInputPipe.transform(_.find(s.broadcaster.status.inputs, { id: s.input_id }));
                subst = "<br/>";
                InputVal = str.replace(/[()]/g, subst);
            } else {
                InputVal = s.input_id;
            }

            displayInput = "<span>Input: " + InputVal + "</span><br/>";
        }

        // Latency
        if (s.zixi) {
            displayType = "<span>Type: " + this.sourcesService.sourceType(s) + "</span><br/>";
        }

        if (s.zixi && !s.transcodeProfile && !s.failoverSources) {
            Latency = "<span>Latency: " + s.latency + "</span>";
        }

        if (!this.hideDetails) {
            Details =
                displayType +
                displayInput +
                Bitrate +
                (healthscore ? "<span>Health: " + healthscore + "</span><br/>" : "") +
                TR101 +
                Latency +
                displayProfile +
                displaySources;
        }

        const icon = failoverChannel ? "fa-project-diagram fa-sm" : "fa-video";
        const type = failoverChannel ? "Channel" : "Source";

        if (s.readOnly) {
            return (
                `<span><i class='fas ${icon} fa-sm'></i> ` +
                type +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                s.name +
                "</span><br/>" +
                displayError
            );
        } else {
            return (
                `<span><i class='fas ${icon} fa-sm'></i> ` +
                type +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                (failoverChannel
                    ? `<a href='/channels/failover/${urlBuilder.encode(
                          failoverChannel.id
                      )}/${this.urlBuildService.encodeRFC3986URIComponent(failoverChannel.name)}'>`
                    : `<a href='/sources/${
                          s.zixi ? urlBuilder.encode(s.id) : "mediaconnect"
                      }/${this.urlBuildService.encodeRFC3986URIComponent(s.name)}'>`) +
                (failoverChannel?.name ?? s.name) +
                "</a></span><br/>" +
                displayError +
                Details
            );
        }
    }

    // Target Content
    private targetContent(anyT: AnyTarget, channel?: ChannelTypes) {
        const t = anyT.target;
        const preppedTarget = this.targetsService.prepTarget(t);
        let Icon = "";
        let Output = "";
        let displayError = "";
        let Details = "";
        let type = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(t));
        if (t instanceof ZixiPullTarget) {
            // Output Name
            if (t.output_id && t.output_name) {
                Output = "<span>Output: " + t.output_name.split(", ").join("<br />") + "</span>";
            }
        } else if (anyT.output_target) {
            Output = "<span>Output: " + anyT.output_target + "</span>";
        }
        // Error Message
        const errorState = this.sharedService.getLastError(t);
        if (errorState) {
            const errorText = errorState.short_message.replace(/"|'/g, "`").replace(/[<>\[\]\n]/gm, " ");
            displayError =
                "<span>Error: " +
                "<span class='error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                (errorText.length > 100 ? errorText.substring(0, 100) + "..." : errorText) +
                "</span></span><br/>";
        }
        // Details
        if (!this.hideDetails) {
            if (channel) {
                Details += `<span>Channel: <a href='/channels/${
                    channel.type === "delivery" ? "pass-through" : channel.type
                }/${urlBuilder.encode(channel.id)}/${this.urlBuildService.encodeRFC3986URIComponent(channel.name)}'>${
                    channel.name
                }</a></span><br/>`;
            }
            Details += "<span>Type: " + this.translate.instant(t.type.toUpperCase()) + "</span> <br/>" + Output;
        }
        // Type
        type = preppedTarget.apiType;

        const Name =
            t.readOnly || this.hideEverything
                ? `<span class='name'>${Icon}${t.name}</span>`
                : `<span class='name'>${Icon}<a href='/targets/${type}/${urlBuilder.encode(
                      t.id
                  )}/${this.urlBuildService.encodeRFC3986URIComponent(t.name)}'>${t.name}</a></span>`;

        const Title = `<span><i class='fa fa-share fa-sm'></i> Target</span><br/>${Name}<br/>`;

        if (t.readOnly && !this.hideEverything) {
            return Title + displayError + Details;
        } else if (!t.readOnly && !this.hideEverything) {
            return Title + displayError + Details;
        } else {
            return Name;
        }
    }

    // Receiver Content
    private receiverContent(r: Receiver) {
        let Icon = "";
        let IP = "";
        let CPU = "";
        let Version = "";
        let displayError = "";
        let Link = "";
        let Details = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(r));
        // Error Message
        const errorState = this.sharedService.getLastError(r);
        if (errorState) {
            displayError =
                "<span>Error: " +
                "<span class='error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span><br/>";
        }
        if (r.configure_link) {
            Link = r.configure_link;
        }
        if (r.status) {
            // IP
            if (r.status.source_ip) {
                IP = "<span>IP: " + r.status.source_ip + "</span> <br/>";
            }
            // CPU
            if (r.status.cpu) {
                CPU = "<span>CPU: " + this.percentPipe.transform(r.status.cpu / 100, "1.0-2") + "</span> <br/>";
            }
            // Version
            if (r.status.about) {
                Version =
                    "<span>Version: " +
                    r.status.about.version_minor +
                    "." +
                    r.status.about.version_minor2 +
                    "." +
                    r.status.about.version_build +
                    "</span>";
            }
        }

        if (!this.hideDetails) {
            Details = IP + CPU + Version;
        }

        if (r.readOnly) {
            return (
                "<span><i class='fa fa-cloud-download fa-sm'></i> " +
                "Receiver" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                r.name +
                "</span><br/>" +
                displayError
            );
        } else {
            return (
                "<span><i class='fa fa-cloud-download fa-sm'></i> " +
                "Receiver" +
                "</span> <br/>" +
                "<span class='name'>" +
                Icon +
                "<a href='/zec/receiver/" +
                urlBuilder.encode(r.id) +
                "/" +
                this.urlBuildService.encodeRFC3986URIComponent(r.name) +
                "'>" +
                r.name +
                "</a> - <a href='" +
                Link +
                "' title='Open' target='_blank'><i class='fa fa-external-link-alt fa-xs'></i></a></span><br/>" +
                displayError +
                Details
            );
        }
    }
}
