import { ComponentRef } from "@angular/core";
import { Incident } from "@zixi/models";
import { KeyMap } from "../../../../../models/shared";
import { IncidentsNameColumnComponent } from "./incidents-name-column.component";

export function assignIncidentNameInputsFactory(
    statusComponentRef: ComponentRef<IncidentsNameColumnComponent>,
    row: KeyMap<Incident>,
    searchTerm: string[]
) {
    const statusComponentInstance = statusComponentRef.instance;
    statusComponentInstance.isZixiIncident = row.is_zixi;
    statusComponentInstance.incidentName = row.name;
    statusComponentInstance.searchTerm = searchTerm?.toString();
}
