import * as _ from "lodash";

export const camelToSnakeCase = (str: string) => {
    if (typeof str !== "string") {
        return str;
    }
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const prepareToServer = (requestBody: Record<string, unknown>) => {
    return _.mapKeys(requestBody, (value, key) => camelToSnakeCase(key));
};
