import { Component, Input } from "@angular/core";
import { OpenIssue } from "src/app/models/status";
import { UrlBuilderService } from "src/app/services/url-builder.service";

@Component({
    selector: "zx-event-object",
    template: `
        <span>
            <span class="badge badge-primary tag me-1">{{ item.object_type | objectTypeLetter }}</span
            ><a href="javascript:void(0)" (click)="goPage(item)">{{ item.object_name }}</a>
        </span>
    `
})
export class ZxEventObjectComponent {
    @Input() item: OpenIssue;
    constructor(private urlBuildService: UrlBuilderService) {}

    goPage(issue: OpenIssue) {
        this.urlBuildService.navigateToObject(issue);
    }
}
