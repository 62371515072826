import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "timestamp"
})
export class TimestampPipe implements PipeTransform {
    transform(value: number | string): string {
        const valueN = typeof value === "string" ? parseFloat(value) : value;
        if (isNaN(valueN)) return "";

        function z(n: number, p?: number): string {
            p = p || 2;
            return ("0000" + n).slice(-p);
        }

        const milliseconds = Math.floor(valueN % 1000);
        const totalSeconds = valueN / 1000;
        const seconds = Math.floor(totalSeconds % 60);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const hours = Math.floor(totalSeconds / 3600);

        return (
            z(hours, Math.max(hours.toString().length, 2)) +
            ":" +
            z(minutes) +
            ":" +
            z(seconds) +
            "." +
            z(milliseconds, 3)
        );
    }
}
