import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { RecoveryState } from "src/app/models/shared";

@Component({
    selector: "app-details-page-header-drop-down",
    templateUrl: "./details-page-header-drop-down.component.html",
    styleUrls: ["./details-page-header-drop-down.component.scss"]
})
export class DetailsPageHeaderDropDownComponent {
    @Input() isMaintenance?: boolean;
    @Input() canEdit: boolean;
    @Input() state?: string;
    @Input() linkUrl?: string;
    @Input() maintenanceBtn?: boolean;
    @Input() terminationProtection?: boolean;
    @Input() add?: {
        route: string[];
        text: string;
    };
    @Input() cloneBtn?: boolean;
    @Input() shareBtn?: boolean;
    @Input() userPermissions?: boolean;
    @Input() tracerouteBtn?: boolean;
    @Input() configBtn?: boolean;
    @Input() muted?: boolean;
    @Input() isEnabled?: boolean;
    @Input() canToggle?: boolean;
    @Input() canEnable? = true;
    @Input() editUrl: string[];
    @Input() altActionBtn: RecoveryState = null;

    @Output() delete? = new EventEmitter<void>();
    @Output() clone? = new EventEmitter<void>();
    @Output() share? = new EventEmitter<void>();
    @Output() toggleMaintenance? = new EventEmitter<void>();
    @Output() traceroute? = new EventEmitter<void>();
    @Output() config? = new EventEmitter<void>();
    @Output() toggleMute = new EventEmitter<void>();
    @Output() toggle = new EventEmitter<void>();
    @Output() altAction? = new EventEmitter<void>();

    @ViewChild(MatMenuTrigger) headerMenu: MatMenuTrigger;

    recoveryState = RecoveryState;

    constructor(private router: Router) {}

    edit() {
        this.router.navigate(this.editUrl);
    }

    openLink() {
        window.open(this.linkUrl, "_blank");
    }

    addLink() {
        this.router.navigate(this.add.route);
    }

    @Output() muteUntil = new EventEmitter<Date>();
    showPicker = false;
    muteUntilVal: Date;
    muteUntilString: string;

    @HostListener("click", ["$event"])
    clickInside(event: MouseEvent) {
        event.stopPropagation();
    }

    @HostListener("document:click")
    clickOutside() {
        this.showPicker = false;
        this.muteUntilString = null;
    }

    async openDatePicker(event) {
        this.showPicker = true;
        this.muteUntilVal = null;
        this.headerMenu.closeMenu();
        event.stopPropagation();
    }

    close() {
        this.showPicker = false;
        this.muteUntilString = null;
    }

    dateChanged(event: string) {
        if (event !== null) {
            this.muteUntilVal = new Date(event);
        }
    }

    save(date: Date) {
        this.muteUntil.emit(date);
        this.close();
    }
}
