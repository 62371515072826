import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { RolesService } from "../../../pages/account-management/roles/roles.service";
import { Role } from "./../../../models/shared";

@Component({
    selector: "zx-roles-select",
    templateUrl: "./zx-roles-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxRolesSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    roles: Role[];
    private rolesSubscription: Subscription;

    constructor(private gs: RolesService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.gs.refreshRoles();
        this.rolesSubscription = this.gs.roles.subscribe(roles => {
            this.roles = [...roles];
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.rolesSubscription.unsubscribe();
    }
}
