import { Component, Input } from "@angular/core";
import { ZecOutput } from "src/app/models/shared";

@Component({
    selector: "app-zec-output-column",
    templateUrl: "./zec-output-name-column.component.html"
})
export class ZecOutputNameColumnComponent {
    @Input() output: ZecOutput;
}
