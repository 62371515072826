import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";

import { AmazonAwsService } from "../../../pages/configuration/amazon-aws/amazon-aws.service";
import { AmazonAWSMediaConnectFlow } from "../../../pages/configuration/amazon-aws/amazon-aws";

@Component({
    selector: "zx-aws-mediaconnect-flow-select",
    templateUrl: "./zx-aws-mediaconnect-flow-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxAWSMediaConnectFlowSelectComponent implements OnChanges {
    @Input() name: string;
    @Input() arn: string;
    @Input() awsAccountId: number;
    @Input() region: string;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Output() arnChange = new EventEmitter();

    mediaconnectFlows: AmazonAWSMediaConnectFlow[] = [];

    loading: boolean;

    constructor(private aas: AmazonAwsService) {}

    modelChanged() {
        this.arnChange.emit(this.arn);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (this.awsAccountId == null || this.region == null) return;

        if (changes.region) {
            if (changes.region.previousValue !== changes.region.currentValue) {
                this.loading = true;
                this.mediaconnectFlows = [];

                this.mediaconnectFlows = await this.aas.getAWSAccountMediaConnectFlows(this.awsAccountId, this.region);
                this.loading = false;
            }
        }
    }
}
