import { ComponentRef } from "@angular/core";
import { KeyMap, ZixiObject } from "src/app/models/shared";
import { GridObjectsColumnComponent } from "./grid-objects-column.component";

export function assignComponentsGridObjectsAdapter(
    componentRef: ComponentRef<GridObjectsColumnComponent>,
    row: KeyMap<ZixiObject>,
    searchTerm: string[]
) {
    const componentInstance = componentRef.instance;
    componentInstance.object = row;
    componentInstance.searchTerm = searchTerm;
}
