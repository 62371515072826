import { Component } from "@angular/core";

@Component({
    selector: "app-table-material-wrapper",
    template: `
        <div class="list-section">
            <ng-content></ng-content>
        </div>
    `
})
export class TableMaterialWrapperComponent {}
