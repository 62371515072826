import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";

export interface Breadcrumb {
    label: string;
    url?: string;
}

@Injectable({
    providedIn: "root"
})
export class BreadcrumbsService {
    readonly breadcrumbs$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
            const root = this.router.routerState.snapshot.root;
            return this.addBreadcrumb(root, []);
        })
    );

    constructor(private router: Router) {}

    private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs?: Breadcrumb[]) {
        if (!breadcrumbs) {
            breadcrumbs = [];
        }

        if (route) {
            // Construct the route URL
            const routeUrl = parentUrl.concat(route.url.map(url => url.path));

            if (route.data.breadcrumbs) {
                for (const breadcrumb of route.data.breadcrumbs) {
                    breadcrumbs.push(breadcrumb);
                }
            }

            this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }

        return breadcrumbs;
    }
}
