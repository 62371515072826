import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-search-input",
    templateUrl: "./search-input.component.html",
    styleUrls: ["./search-input.component.scss"]
})
export class SearchInputComponent {
    @Input() isMr2: boolean;
    @Input() userFilter: string;
    @Input() filterPlaceholder: string;
    @Input() rowsAmount: number;
    @Input() filteredRowsAmount: number;
    @Output() userFilterChange = new EventEmitter<string>();
}
