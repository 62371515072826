import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { TranslateService } from "@ngx-translate/core";
import { DetailsData } from "src/app/components/shared/new-details-page/details-section/details-section.component";
import { ModalService } from "../../../modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { Constants } from "src/app/constants/constants";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
    selector: "app-new-details",
    templateUrl: "./new-details.component.html",
    styleUrls: ["./new-details.component.scss"]
})
export class NewDetailsComponent implements OnInit {
    @Input() details: DetailsData;
    @Input() isBoldContent = false;
    @Output() refreshPage = new EventEmitter<Boolean>();

    showPassword = false;
    showAuthPassword = false;
    specialCase = false;
    urls = Constants.urls;
    canVLC = false;

    constructor(
        private cbs: ClipboardService,
        private translate: TranslateService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private deviceService: DeviceDetectorService
    ) {
        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
    }

    ngOnInit(): void {
        this.specialCase =
            this.details.title === this.translate.instant("API_CREDENTIALS") ||
            this.details.title === this.translate.instant("LOCATION") ||
            this.details.title === this.translate.instant("TAGS") ||
            this.details.title === this.translate.instant("CLUSTER_TAGS") ||
            this.details.title === this.translate.instant("ELASTIC_IPS") ||
            this.details.title === this.translate.instant("SSH_KEY") ||
            this.details.title === this.translate.instant("HEALTH") ||
            this.details.title === this.translate.instant("THUMBNAIL") ||
            this.details.title === this.translate.instant("INPUT") ||
            this.details.title === this.translate.instant("PLAY") ||
            this.details.title === this.translate.instant("INGEST_CLUSTER") ||
            this.details.title === this.translate.instant("PROCESSING_CLUSTER") ||
            this.details.title === this.translate.instant("ACTIVE_BROADCASTER") ||
            this.details.title === this.translate.instant("ALTERNATIVE_CLUSTER") ||
            this.details.title === this.translate.instant("ALTERNATIVE_CHANNEL") ||
            this.details.title === this.translate.instant("AWS_MEDIACONNECT_FLOW") ||
            this.details.title === this.translate.instant("TARGET_BROADCASTER_S");
    }

    copyPassword() {
        this.cbs.copy(this.details.content as string);
    }

    copy(content: string) {
        this.cbs.copy(content);
    }

    async openTags(detail) {
        const result = await this.modalService.editTags(detail.object, detail.type, detail.canEdit);
        if (result) {
            this.refreshPage.emit(true);
            return true;
        } else return false;
    }
}
