import { Pipe, PipeTransform } from "@angular/core";
import { BroadcasterOutput } from "../models/shared";

@Pipe({
    name: "broadcasterOutputError"
})
export class BroadcasterOutputErrorPipe implements PipeTransform {
    transform(output: BroadcasterOutput): string {
        if (output.error_code) return output.error_string;
        else if (output.status === "Offline" && !output.error_code) return "Source Error";
        else return "";
    }
}
