<ng-container *ngIf="object.mediaconnectFlow && object.mediaconnectFlow.name">
    <zx-mediaconnect-flow *ngIf="object.mediaconnectFlow" [model]="object.mediaconnectFlow" [showLink]="true" [searchTerm]="searchTerm"></zx-mediaconnect-flow>
</ng-container>

<ng-container *ngIf="object.processingCluster && object.processingCluster.name">
    <zx-cluster *ngIf="object.processingCluster" [model]="object.processingCluster" [showLink]="true" [searchTerm]="searchTerm"></zx-cluster>
</ng-container>

<ng-container *ngIf="object.inputCluster && object.inputCluster.dns_prefix">
    <zx-cluster *ngIf="object.inputCluster" [model]="object.inputCluster" [showLink]="true" [searchTerm]="searchTerm"></zx-cluster>
</ng-container>

<ng-container *ngIf="!object.inputCluster && !object.processingCluster && !object.mediaconnectFlow">-</ng-container>
