import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";

import { TargetsService, DeliveryTarget } from "../../../../pages/targets/targets.service";
import {
    AdaptiveChannel,
    DeliveryChannel,
    AnyTarget,
    PublishingTarget,
    UdpRtpTarget,
    RtmpPushTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    RistTarget,
    NdiTarget,
    MediaLiveHttpTarget
} from "../../../../pages/channels/channel";
import { Tag, MediaConnectFlow } from "./../../../../models/shared";
import { SourcePreferenceSelectionValues } from "../../zx-delivery-channel-source-select/zx-delivery-channel-source-select.component";
import { TranscodingProfile } from "../../../../pages/transcoding-profiles/transcoding-profile";

@Component({
    selector: "app-assign-target-dialog",
    templateUrl: "./assign-target-dialog.component.html"
})
export class AssignTargetDialogComponent implements OnInit, OnDestroy {
    channel: AdaptiveChannel | DeliveryChannel | MediaConnectFlow;
    resourceTags: Tag[];
    transcodeProfile: TranscodingProfile;

    targets: AnyTarget[] = [];
    loading: boolean;
    saving: boolean;
    forceSameBX = false;
    _selectedTarget: AnyTarget;
    get selectedTarget(): AnyTarget {
        return this._selectedTarget;
    }
    set selectedTarget(t: AnyTarget) {
        if (t.rtmp || t.udp_rtp) this.transcodeProfile = t.target.transcodingProfile;
        this._selectedTarget = t;
    }

    private targetsSubscription: Subscription;
    private routeSubscription: Subscription;

    constructor(public activeModal: NgbActiveModal, private router: Router, private targetsService: TargetsService) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnInit() {
        if (this.channel.medialive) this.getMediaLiveTargets();
        else if (!this.channel.adaptive) this.getDeliveryTargets();
        else this.getAdaptiveTargets();
    }

    async getMediaLiveTargets() {
        this.loading = true;
        await this.targetsService.getMediaLiveHTTPTargets();
        await this.targetsService.getRtmpTargets();
        await this.targetsService.getUdpRtpTargets();
        this.targetsSubscription = this.targetsService.targets.subscribe(targets => {
            const mlTargets = targets.filter(t => t.medialive === true);
            this.targets = _.filter(mlTargets, t => {
                const target = t.target as MediaLiveHttpTarget | RtmpPushTarget | UdpRtpTarget;
                return (
                    this.channel.id !== target.medialive_channel_id &&
                    _.intersection(_.map(this.resourceTags, "id"), _.map(target.resourceTags, "id")).length !== 0
                );
            });
            this.targets = _.orderBy(this.targets, target => target.target.name.toLowerCase(), ["asc", "desc"]);
            this.loading = false;
        });
    }

    async getAdaptiveTargets() {
        this.loading = true;
        await this.targetsService.getAdaptiveTargets();
        this.targetsSubscription = this.targetsService.targets.subscribe(targets => {
            const adaptiveTargets = targets.filter(t => t.adaptive === true);
            this.targets = _.filter(adaptiveTargets, t => {
                const target = t.target as PublishingTarget;
                return (
                    this.channel.id !== target.adaptive_channel_id &&
                    _.intersection(_.map(this.resourceTags, "id"), _.map(target.resourceTags, "id")).length !== 0
                );
            });
            this.targets = _.orderBy(this.targets, target => target.target.name.toLowerCase(), ["asc", "desc"]);
            this.loading = false;
        });
    }

    async getDeliveryTargets() {
        this.loading = true;
        await this.targetsService.getDeliveryTargets();
        this.targetsSubscription = this.targetsService.targets.subscribe(targets => {
            const deliveryTargets = targets.filter(t => t.delivery === true);
            this.targets = _.filter(deliveryTargets, t => {
                if (this.channel.mediaconnect && (t.target.type === "rtmp" || t.target.type === "mediaconnect"))
                    return false;

                const target = t.target as UdpRtpTarget | RistTarget | RtmpPushTarget | ZixiPullTarget | ZixiPushTarget;
                return (
                    this.channel.id !== target.delivery_channel_id &&
                    _.intersection(_.map(this.resourceTags, "id"), _.map(target.resourceTags, "id")).length !== 0
                );
            });
            this.targets = _.orderBy(this.targets, target => target.target.name.toLowerCase(), ["asc", "desc"]);
            this.loading = false;
        });
    }

    customSearchFn(term: string, item: AnyTarget) {
        term = term.toLowerCase();
        return item.target.name.toLowerCase().indexOf(term) > -1 || item.type_name.toLowerCase().indexOf(term) > -1;
    }

    typeOf(target: AnyTarget) {
        if (target.target instanceof UdpRtpTarget || RistTarget || RtmpPushTarget || ZixiPullTarget || ZixiPushTarget)
            return true;
        else return false;
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        let deliveryTarget: DeliveryTarget | NdiTarget | undefined;
        if (this.selectedTarget.delivery) {
            deliveryTarget = this.selectedTarget.target as DeliveryTarget | NdiTarget;
        }
        const model = {
            adaptive_channel_id: this.channel.adaptive ? this.channel.id : null,
            delivery_channel_id: this.channel.delivery ? this.channel.id : null,
            mediaconnect_flow_id: this.channel.mediaconnect ? this.channel.id : null,
            medialive_channel_id: this.channel.medialive ? this.channel.id : null,
            force_same_bx:
                this.channel.delivery || this.channel.adaptive
                    ? this.forceSameBX && !this.selectedTarget.pull && !this.selectedTarget.ndi
                    : undefined,
            preferred_source:
                this.channel.delivery &&
                deliveryTarget &&
                deliveryTarget.preferred_source != null &&
                deliveryTarget.preferred_source !== SourcePreferenceSelectionValues.previousSelection
                    ? deliveryTarget.preferred_source
                    : undefined,
            transcoding_profile_id:
                this.channel.medialive && this.transcodeProfile ? this.transcodeProfile.id : undefined
        };

        const result = await this.targetsService.updateTarget(this.selectedTarget.target, model);
        if (result !== false) {
            this.saving = false;
            this.activeModal.close(result);
        } else {
            this.saving = false;
        }
    }
}
