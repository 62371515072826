import { Component, OnInit, Input } from "@angular/core";
import { Constants } from "./../../../constants/constants";
import { RecoveryState, Source } from "src/app/models/shared";
import { SourcesService } from "src/app/pages/sources/sources.service";

@Component({
    selector: "zx-source",
    template: `
        <div class="ellipsis d-inline" title="{{ model | errorMessage }}" *ngIf="model.name">
            <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'SOURCE' | translate }}">S</span>
            <zx-status-full
                [icon]="showStatusIcon"
                [model]="model"
                [status]="status || (model.generalStatus | statusClass)"
                [showStatusText]="showStatusText"
                [showOtherIcons]="showOtherIcons"
            ></zx-status-full>
            <fa-icon
                *ngIf="showOtherIcons && ss.isAltPath(model)"
                class="me-1"
                icon="alt"
                size="sm"
                title="{{ 'ALT_PATH_ACTIVE' | translate }}"
            ></fa-icon>
            <!-- Link -->
            <a
                *ngIf="showLink"
                [routerLink]="['/' + urls.sources, model.mediaconnect ? 'mediaconnect' : (model.id | uId), model.name]"
                (click)="$event.stopPropagation()"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
                <span *ngIf="showCluster">
                    @
                    <ngb-highlight
                        title="{{ model.mediaconnect ? 'mediaconnect' : model.inputCluster?.name }}"
                        [result]="model.mediaconnect ? 'mediaconnect' : model.inputCluster?.name"
                        [term]="searchTerm"
                    ></ngb-highlight></span
            ></a>
            <!-- No Link -->
            <span *ngIf="!showLink && !showClusterLink"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
                <span *ngIf="showCluster">
                    @
                    <ngb-highlight
                        title="{{ model.mediaconnect ? 'mediaconnect' : model.inputCluster?.name }}"
                        [result]="model.mediaconnect ? 'mediaconnect' : model.inputCluster?.name"
                        [term]="searchTerm"
                    ></ngb-highlight></span
            ></span>
            <!-- Cluster Link -->
            <span *ngIf="!showLink && showClusterLink"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
                <span *ngIf="showCluster">
                    @
                    <a
                        [routerLink]="[
                            '/' + urls.clusters,
                            model.broadcaster_cluster_id | uId,
                            model.inputCluster?.name
                        ]"
                        (click)="$event.stopPropagation()"
                        ><ngb-highlight
                            title="{{ model.mediaconnect ? 'mediaconnect' : model.inputCluster?.name }}"
                            [result]="model.mediaconnect ? 'mediaconnect' : model.inputCluster?.name"
                            [term]="searchTerm"
                        ></ngb-highlight></a></span
            ></span>
        </div>
    `
})
export class ZxSourceComponent implements OnInit {
    @Input() model: Source;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() showCluster?: boolean = true;
    @Input() showClusterLink?: boolean = false;
    @Input() searchTerm?: string | string[];
    @Input() isMaterialDesign? = false;

    urls = Constants.urls;

    recoveryState = RecoveryState;

    constructor(private ss: SourcesService) {}

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
