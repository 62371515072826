<zx-zec
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'zec'"
></zx-zec>
<zx-feeder
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'feeder'"
></zx-feeder>
<zx-cluster
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'broadcaster_cluster'"
></zx-cluster>
<zx-broadcaster
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [showCluster]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'broadcaster'"
></zx-broadcaster>
<zx-receiver
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'receiver'"
></zx-receiver>
<zx-source
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'source'"
></zx-source>
<zx-mediaconnect-source
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'mediaconnect_sources'"
></zx-mediaconnect-source>
<zx-adaptive
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'adaptive_channel'"
></zx-adaptive>
<zx-delivery
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'delivery_channel'"
></zx-delivery>
<zx-medialive-channel
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'medialive_channels'"
></zx-medialive-channel>
<zx-failover-channel
    [model]="object"
    [showOtherIcons]="false"
    [showStatusIcon]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'failover_channel'"
></zx-failover-channel>
<zx-mediaconnect-flow
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'mediaconnect_flows'"
></zx-mediaconnect-flow>
<!-- 11 Types -->
<zx-target
    [model]="{ target: object, apiType: targetsService.getTargetApiType(object) }"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="sharedService.isTarget(object)"
></zx-target>
