<div class="d-inline" (click)="navigateTo()">
    <zx-status-icon
        *ngIf="icon"
        class="me-1"
        [model]="model"
        [status]="channelDisabled ? 'disabled' : status || (model?.generalStatus | statusClass)"
        [size]="size"
        [standby]="standby"
        [disabled]="channelDisabled"
    >
    </zx-status-icon>

    <div *ngIf="showStatusText" class="d-inline" [ngClass]="{ 'cursor-pointer': navigateOnClick }">
        <ngb-highlight
            class="me-1"
            *ngIf="!navigateOnClick"
            title="{{ text || (model | statusText) | translate }}"
            [result]="text || (model | statusText) | translate"
            [term]="searchTerm"
        ></ngb-highlight>

        <a *ngIf="navigateOnClick" class="me-1" title="{{ text || (model | statusText) | translate }}">{{ text || (model | statusText) | translate }}</a>
    </div>

    <fa-icon *ngIf="model?.acknowledged && showOtherIcons" icon="check" size="sm" title="{{ 'ERROR_STATE_ACKNOWLEDGED' | translate }}" class="me-1"></fa-icon>

    <fa-icon
        *ngIf="model?.active_mute && model?.muted_until && showOtherIcons"
        icon="bell-slash"
        size="sm"
        title="{{ 'NOTIFICATIONS_DISABLED_UNTIL' | translate }} {{ model?.muted_until }}"
        class="me-1"
    >
    </fa-icon>

    <fa-icon
        *ngIf="model?.active_mute && !model?.muted_until && showOtherIcons"
        icon="bell-slash"
        size="sm"
        title="{{ 'NOTIFICATIONS_DISABLED' | translate }}"
        class="me-1"
    ></fa-icon>

    <fa-icon *ngIf="lockIconText" icon="lock" [title]="lockIconText" class="ms-1"></fa-icon>
</div>
