<ng-container *ngIf="location as location">
    <div class="form-group row" *ngIf="(location.address || location.ip)?.display_name">
        <h5 class="col-12" translate>LOCATION</h5>
        <div class="col-12 flex-display">
            <img *ngIf="(location.address || location.ip).country_flag" src="{{ (location.address || location.ip).country_flag }}" class="svg-country-icon" />
            <span>{{ (location.address || location.ip).display_name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="location.ip">
        <h5 class="col-12" translate>NETWORK</h5>
        <div class="col-12">
            <span>{{ network }}</span>
        </div>
    </div>
</ng-container>
