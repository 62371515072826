import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { map } from "rxjs";
import { EventFilter } from "../components/events-filter-form/events-filter-form.component";

@Injectable({
    providedIn: "root"
})
export class QueryParamsService {
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private queryParams$ = this.route.queryParams;
    eventsParams$ = this.queryParams$.pipe(map(this.filterEventsParams));

    private filterEventsParams(params: Record<string, string>): Partial<EventFilter> {
        const eventParams: Partial<EventFilter> = {};
        if (params.msgFilter) eventParams.msgFilter = params.msgFilter;
        if (params.fromDate) eventParams.fromDate = moment(params.fromDate);
        if (params.toDate) eventParams.toDate = moment(params.toDate);
        if (params.msgTypes) {
            const enabledMsgTypes = params.msgTypes.split(",");
            const msgTypes = {};
            for (const key of enabledMsgTypes) {
                msgTypes[key] = true;
            }
            eventParams.msgTypes = msgTypes;
        }
        if (params.objectType) eventParams.objectType = { name: params.objectType };
        if (params.resourceTags) eventParams.resourceTags = params.resourceTags.split(",").map(Number);

        return eventParams;
    }

    updateEventParams(eventParams: EventFilter) {
        const params: Partial<Record<keyof EventFilter, string>> = {};
        if (eventParams.msgFilter) params.msgFilter = eventParams.msgFilter;
        if (eventParams.fromDate) params.fromDate = eventParams.fromDate.toString();
        if (eventParams.toDate) params.toDate = eventParams.toDate.toString();
        if (eventParams.msgTypes) {
            const msgTypesNames = [];
            for (const msgType in eventParams.msgTypes) if (eventParams.msgTypes[msgType]) msgTypesNames.push(msgType);

            if (msgTypesNames.length) {
                params.msgTypes = msgTypesNames.join(",");
            }
        }

        if (eventParams.resourceTags) params.resourceTags = eventParams.resourceTags.toString();

        if (eventParams.objectType) params.objectType = eventParams.objectType.name;

        this.updateParams(params);
    }

    private updateParams(params: Record<string, string>) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params
        });
    }
}
