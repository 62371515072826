<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "CONFIGURATION_HELP.TITLE" | translate }}&nbsp;-&nbsp;{{ broadcaster.name }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ol class="mb-0">
            <li>{{ "CONFIGURATION_HELP.LOGIN" | translate }} {{ "BROADCASTER" | translate }} {{ "UI" | translate }}</li>
            <li translate>CONFIGURATION_HELP.GO_SETTINGS_1</li>
            <li>
                {{ "CONFIGURATION_HELP.CLICK_ADD" | translate }}<br />
                <ul>
                    <li *ngFor="let tunnel_server of broadcaster?.Customer?.tunnel_servers">
                        {{ "HOST" | translate }}: <b>{{ tunnel_server.dns_prefix }}{{ tunnelersHost }}</b
                        ><button
                            class="btn btn-sm btn-xs btn-copy"
                            (click)="copyString(tunnel_server.dns_prefix + tunnelersHost)"
                            type="button"
                            title="{{ 'COPY' | translate }}"
                        >
                            <fa-icon icon="clipboard" size="md"></fa-icon>
                        </button>
                    </li>
                </ul>
            </li>
            <li>
                {{ "CONFIGURATION_HELP.CLICK_EDIT" | translate }}<br />
                {{ "USER" | translate }}: <b>{{ broadcaster.tunnel_username }}</b
                ><button class="btn btn-sm btn-xs btn-copy" (click)="copyString(broadcaster.tunnel_username)" type="button" title="{{ 'COPY' | translate }}">
                    <fa-icon icon="clipboard" size="md"></fa-icon></button
                ><br />
                <a
                    *ngIf="!broadcaster.accessKey.readOnly && broadcaster.accessKey.hasRsaPrivate"
                    href="/api/access_keys/{{ broadcaster.accessKey.id }}/download"
                >
                    <fa-icon icon="download" size="sm"></fa-icon>
                    {{ broadcaster.accessKey.name }}
                </a>
            </li>
            <li>
                {{ "CONFIGURATION_HELP.CONFIRM_SOURCE" | translate }}<br />
                {{ "REMOTE_SOURCE_PORT" | translate }}: <b>{{ broadcaster.tunnel?.port }}</b
                ><button class="btn btn-sm btn-xs btn-copy" (click)="copyString(broadcaster.tunnel?.port)" type="button" title="{{ 'COPY' | translate }}">
                    <fa-icon icon="clipboard" size="md"></fa-icon>
                </button>
            </li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
