import { Component, Input, OnInit } from "@angular/core";
import { AnyTarget, TargetObjectType } from "src/app/pages/channels/channel";

@Component({
    selector: "app-zx-target-channel-column",
    templateUrl: "./zx-target-channel-column.component.html"
})
export class ZxTargetChannelColumnComponent implements OnInit {
    @Input() target: AnyTarget | TargetObjectType;
    @Input() searchTerm: string;

    model: TargetObjectType;

    ngOnInit() {
        if ("target" in this.target && this.target.target) {
            this.model = this.target.target as TargetObjectType;
        } else {
            this.model = this.target as TargetObjectType;
        }
    }
}
