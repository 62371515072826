export class Customer {
    id: number;
    name: string;
    dns_prefix: string;
    created_at: string;
    updated_at: string;
    is_enabled: number;
    is_deleted: number;
    eip: string;
    aws_role_arn: string;
    aws_external_id: string;
    broadcasters_secret: string;
    instance_id: string;
    content_analysis: number;
    dtls_acm_cert_arn: string;
    monitor_id: string;
    monitor_time: string;
    traceroute_history: number;
    max_bx_version: string;
    allow_zixi_support: number;
    automation: number;
    live_events: number;
    allow_agentz: boolean;
    allow_non_sso_login: boolean;

    tunnel_servers: {
        id: number;
        dns_prefix: string;
        eip: string;
        created_at: string;
        updated_at: string;
        shared: number;
        instance_id: string;
        instance_type: string;
        ami: string;
        region: string;
    }[];
    admins: {
        id: number;
        sso_id: number;
        name: string;
        email: string;
        must_reset: number;
        is_admin: number;
        is_zixi_admin: number;
        is_zixi_support: number;
        is_zixi_support_write: number;
        is_enabled: number;
        created_at: string;
        updated_at: string;
    }[];
}
