<label for="ngModelPassword" [ngClass]="{ 'is-invalid': isFormSubmitted && ngModelPassword.errors }">
    {{ passwordName | translate }}
    <fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon>
    <fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon>
    <app-password-strength-icon [password]="password"></app-password-strength-icon>
</label>
<div class="input-group">
    <input
        type="{{ showPassword ? 'text' : 'password' }}"
        autocomplete="cc-csc"
        id="ngModelPassword"
        name="ngModelPassword"
        placeholder="{{ passwordName | translate }}"
        class="form-control"
        #ngModelPassword="ngModel"
        [required]="required"
        [ngClass]="{ 'is-invalid': isFormSubmitted && ngModelPassword.errors }"
        [(ngModel)]="password"
    />
    <button class="btn btn-outline-primary" *ngIf="generate" (click)="generatePassword()" type="button" title="{{ 'GENERATE_A_RANDOM_PASSWORD' | translate }}">
        <fa-icon icon="key"></fa-icon>
    </button>
    <button class="btn btn-outline-primary" (click)="toggleShowPassword()" type="button" title="{{ 'SHOW_PASSWORD' | translate }}">
        <fa-icon icon="eye" *ngIf="!showPassword"></fa-icon>
        <fa-icon icon="eye-slash" *ngIf="showPassword"></fa-icon>
    </button>
    <button class="btn btn-outline-primary" (click)="copyPassword()" type="button" title="{{ 'COPY_PASSWORD' | translate }}">
        <fa-icon icon="clipboard"></fa-icon>
    </button>
</div>
<div *ngIf="ngModelPassword.invalid" class="invalid-feedback">
    <div *ngIf="ngModelPassword.errors.required">{{ passwordName | translate }} {{ "IS_REQUIRED" | translate }}.</div>
</div>
