<div class="ellipsis d-inline" *ngIf="model.name">
    <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'ZEC' | translate }}">Z</span>
    <zx-status-full
        [icon]="showStatusIcon"
        [model]="model"
        [status]="status || (model.generalStatus | statusClass)"
        [showStatusText]="showStatusText"
        [showOtherIcons]="showOtherIcons"
    ></zx-status-full>
    <a *ngIf="showLink" [routerLink]="['/' + urls.zecs + '/' + urls.zecs, model.id | uId, model.name]" (click)="$event.stopPropagation()">
        <ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight>
    </a>
    <span *ngIf="!showLink"><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight></span>
</div>
