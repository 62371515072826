import { ComponentRef } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "../../../models/shared";
import { FunctionRef, ZxEventsTableRowActionsComponent } from "./zx-events-table-row-actions.component";
import { ZmEvent } from "../../../pages/events/event";

type booleanCallback<T = KeyMap<Base>> = (row: T) => boolean;

export const assignEventsTableRowActionsInputsFactory =
    <T = ZmEvent>(
        canCreateIncident: booleanCallback<T>,
        canManageEvent: booleanCallback<T>,
        createIncidentRef: FunctionRef<T>,
        manageEventRef: FunctionRef<T>
    ) =>
    (ComponentRef: ComponentRef<ZxEventsTableRowActionsComponent>, row: KeyMap<T>): void => {
        const comp = ComponentRef.instance;
        comp.canCreateIncident = canCreateIncident(row);
        comp.canManageEvent = canManageEvent(row);
        comp.createIncidentRef = createIncidentRef;
        comp.manageEventRef = manageEventRef;
        comp.row = row;
    };
