<app-table-list
    *ngIf="objectTracking; else loadingAnimation"
    [data]="objectTracking"
    tableName="object-tacking-main"
    [displayTableName]="'CHANGE_TRACKING' | translate"
    [(tableSchema)]="tableColumnsSchema"
    [selectable]="false"
    [hoverable]="false"
    [showFilter]="false"
    [showReport]="false"
    [showPagination]="true"
    [showExpandButton]="true"
    [showColumnsSelection]="false"
    [showSelectionCheckbox]="false"
    [expanded]="false"
    [autoRows]="autoRows"
>
</app-table-list>
<ng-template #loadingAnimation>
    <spinner-animation></spinner-animation>
</ng-template>
