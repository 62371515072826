import { Pipe, PipeTransform } from "@angular/core";

import { Constants, TimeZoneT } from "./../constants/constants";

@Pipe({
    name: "timezone"
})
export class TimezonePipe implements PipeTransform {
    transform(item: string, property: string): TimeZoneT {
        if (!item || !property) return null;

        const timezone: TimeZoneT = Constants.timeZones.find(tz => {
            return tz[property].find(tm => {
                return tm === item;
            });
        });

        if (timezone) return timezone;
        else return null;
    }
}
