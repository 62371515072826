import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ZixiObject } from "./../../../models/shared";

@Component({
    selector: "app-pagination",
    templateUrl: "./pagination.component.html"
})
export class PaginationComponent {
    @Input() object: ZixiObject;
    @Input() rowSelected?: boolean;
    @Input() startNum: number;
    @Input() endNum: number;
    @Input() total: number;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() selectedPageSize: string | number;

    @Output() pageChange = new EventEmitter();
    @Output() pageSizeChange = new EventEmitter();

    // Page Change
    pageChanged() {
        this.pageChange.emit(this.page);
    }

    // Page Size Change
    pageSizeChanged(pageSize) {
        this.pageSizeChange.emit(pageSize);
    }
}
