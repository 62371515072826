import { Component, Input } from "@angular/core";
import { MediaConnectSource, Source } from "src/app/models/shared";

@Component({
    selector: "app-zx-ingest-cluster-source",
    template: `
        <div class="ellipsis">
            <zx-cluster
                *ngIf="source.zixi && source.inputCluster"
                [model]="source.inputCluster"
                [showOtherIcons]="false"
                [showStatusIcon]="false"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
                [searchTerm]="searchTerm"
            ></zx-cluster>
            <zx-mediaconnect-flow
                *ngIf="
                    source.mediaconnect &&
                    source.mediaconnectFlow &&
                    (source.mediaconnect_flow_id || source.mediaconnect_flow_id === 0)
                "
                [model]="source.mediaconnectFlow"
                [showStatusIcon]="false"
                [showStatusText]="false"
                [showLink]="true"
                [showTag]="false"
                [searchTerm]="searchTerm"
            ></zx-mediaconnect-flow>
            <span
                *ngIf="
                    !(source.zixi && source.inputCluster) &&
                    !(
                        source.mediaconnectFlow &&
                        source.mediaconnect &&
                        (source.mediaconnect_flow_id || source.mediaconnect_flow_id === 0)
                    )
                "
                >-</span
            >
        </div>
    `
})
export class ZxIngestClusterSourceComponent {
    @Input() source: MediaConnectSource | Source;
    @Input() searchTerm: string;
}
