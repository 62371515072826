import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { Broadcaster } from "src/app/models/shared";
import { Constants } from "src/app/constants/constants";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import _ from "lodash";
import { Cluster } from "../../cluster";
import { TunnelServer } from "@zixi/models";
interface PortState {
    port: number;
    color: "badge-success" | "badge-danger" | "badge-light";
}
@Component({
    selector: "zx-broadcaster-details",
    templateUrl: "./broadcaster-details.component.html"
})
export class BroadcasterDetailsComponent implements OnInit, OnChanges {
    @Input() broadcaster: Broadcaster;
    @Input() cluster: Cluster;
    @Input() canEdit: boolean;

    pwVisible = false;
    allowAgentZ = false;
    isZixiUser = false;
    needsPortsConfig: boolean;
    hasPortsState: boolean;
    portsState: {
        regular: {
            public?: PortState[];
            private?: PortState[];
        };
        dtls: {
            public?: PortState[];
            private?: PortState[];
        };
    };
    applying: boolean;
    successfullyUpdateConfig: boolean;
    successfullyInstalledAgentZ: boolean;
    activeAgentZ: boolean;

    tunnelStatus: {
        text: string;
        status: string;
    };

    constructor(
        private cbs: ClipboardService,
        private modalService: ModalService,
        private bs: BroadcastersService,
        private us: UsersService
    ) {}

    ngOnInit(): void {
        this.successfullyUpdateConfig = false;
        this.successfullyInstalledAgentZ = false;
        this.activeAgentZ = false;
        this.tunnelStatus = {
            text: null,
            status: null
        };
        this.prep();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.cluster) {
            if (changes.cluster.previousValue && changes.cluster.currentValue) {
                if (changes.cluster.previousValue.id !== changes.cluster.currentValue.id) {
                    this.pwVisible = false;
                    this.prep();
                }
            }
        }

        if (changes.broadcaster) {
            if (changes.broadcaster.previousValue && changes.broadcaster.currentValue) {
                if (changes.broadcaster.previousValue.id !== changes.broadcaster.currentValue.id) {
                    this.pwVisible = false;
                    this.prep();
                }

                //  Lack of agentz_last_report field is handled in the function (possible correct state).
                //  If the value is not changed, it still might indicate a change in the agentz status because time has passed.
                this.activeAgentZ = this.bs.checkAgentZHasRecentReport(changes.broadcaster.currentValue);
            }
        }
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw.toString());
    }

    openLink(broadcaster: Broadcaster) {
        window.open(broadcaster.configure_link, "_blank");
    }

    getSingleTunnelStatus(t: TunnelServer) {
        if (this.broadcaster?.status?.tunnels) {
            if (t?.dns_prefix in this.broadcaster?.status?.tunnels) {
                if (this.broadcaster?.status?.tunnels.hasOwnProperty(t.dns_prefix)) {
                    if (
                        this.broadcaster?.status?.tunnels[t.dns_prefix].status === "On" &&
                        this.broadcaster?.status?.tunnels[t.dns_prefix].reverse_tunnel
                    )
                        return "ok";
                    else return "error";
                }
            }
        }
        return "error";
    }
    getSingleTunnelLatency(t: TunnelServer) {
        if (this.broadcaster?.status?.tunnels) {
            if (t?.dns_prefix in this.broadcaster?.status?.tunnels) {
                if (this.broadcaster?.status?.tunnels.hasOwnProperty(t.dns_prefix)) {
                    return this.broadcaster?.status?.tunnels[t.dns_prefix].latency ?? "-";
                }
            }
        }
        return "-";
    }

    async toggleMaintenance(broadcaster: Broadcaster) {
        const model = {
            maintenance: !broadcaster.maintenance
        };

        const result = await this.bs.updateBroadcaster(broadcaster, model);
        if (result !== false) {
            this.bs.refreshBroadcaster(this.broadcaster.id, true);
            return true;
        } else {
            return false;
        }
    }

    async applyConfig() {
        this.applying = true;

        if (!this.needsPortsConfig) {
            this.successfullyUpdateConfig = await this.completeApplyConfig();
        } else {
            const result = await this.modalService.confirm(
                "SAVE_RESTART",
                "BROADCASTER",
                async () => this.completeApplyConfig(),
                this.broadcaster.name
            );
            this.successfullyUpdateConfig = !!result;
        }

        this.applying = false;
    }

    async completeApplyConfig() {
        return await this.bs.applyBroadcasterConfig(this.broadcaster.id, this.needsPortsConfig);
    }

    async installAgentZ() {
        this.successfullyInstalledAgentZ = await this.modalService.confirmAgentzInstall(this.broadcaster, async () =>
            this.completeInstallAgentZ(this.broadcaster.id)
        );
    }

    async completeInstallAgentZ(id: number): Promise<boolean> {
        this.applying = true;
        const allGood = await this.bs.installAgentZ(id);
        this.applying = false;
        return allGood;
    }

    prep() {
        this.us.getCurrentUser().subscribe(user => {
            this.allowAgentZ = user.agentz_allowed;
            this.isZixiUser = !!(user.is_zixi_admin || user.is_zixi_support || user.is_zixi_support_write);
        });

        this.activeAgentZ = this.bs.checkAgentZHasRecentReport(this.broadcaster);

        this.tunnelStatus.status = this.bs.getTunnelStatus(this.broadcaster);
        this.tunnelStatus.text = this.bs.getTunnelStatusText(this.bs.getTunnelStatus(this.broadcaster));

        const zixiPorts = Constants.zixiPorts.regular;
        const dtlsPorts = Constants.zixiPorts.dtls;

        if (this.broadcaster && this.broadcaster.status && this.broadcaster.status.zixi_ports) {
            this.needsPortsConfig = false;
            this.hasPortsState = true;

            this.portsState = {
                regular: {
                    public: _.map(this.broadcaster.status.zixi_ports.regular.public, port => {
                        return {
                            port,
                            color: port === zixiPorts.public && this.cluster.dtls < 2 ? "badge-success" : "badge-light"
                        };
                    }),
                    private: _.map(this.broadcaster.status.zixi_ports.regular.private, port => {
                        return {
                            port,
                            color: port === zixiPorts.private && this.cluster.dtls < 2 ? "badge-success" : "badge-light"
                        };
                    })
                },
                dtls: {
                    public: _.map(this.broadcaster.status.zixi_ports.dtls.public, port => {
                        return { port, color: port === dtlsPorts.public ? "badge-success" : "badge-light" };
                    }),
                    private: _.map(this.broadcaster.status.zixi_ports.dtls.private, port => {
                        return { port, color: port === dtlsPorts.private ? "badge-success" : "badge-light" };
                    })
                }
            };

            if (this.cluster.dtls === 0 || this.cluster.dtls === 1) {
                if (!_.includes(this.broadcaster.status.zixi_ports.regular.public, zixiPorts.public)) {
                    this.portsState.regular.public.unshift({ port: zixiPorts.public, color: "badge-danger" });
                    this.needsPortsConfig = true;
                }
                if (!_.includes(this.broadcaster.status.zixi_ports.regular.private, zixiPorts.private)) {
                    this.portsState.regular.private.unshift({ port: zixiPorts.private, color: "badge-danger" });
                    this.needsPortsConfig = true;
                }
            }
            if (this.cluster.dtls) {
                if (!_.includes(this.broadcaster.status.zixi_ports.dtls.public, dtlsPorts.public)) {
                    this.portsState.dtls.public.unshift({ port: dtlsPorts.public, color: "badge-danger" });
                    this.needsPortsConfig = true;
                }
                if (!_.includes(this.broadcaster.status.zixi_ports.dtls.private, dtlsPorts.private)) {
                    this.portsState.dtls.private.unshift({ port: dtlsPorts.private, color: "badge-danger" });
                    this.needsPortsConfig = true;
                }
            }
        } else {
            this.hasPortsState = false;
            this.portsState = {
                regular: {
                    public: [{ port: zixiPorts.public, color: "badge-light" }],
                    private: [{ port: zixiPorts.private, color: "badge-light" }]
                },
                dtls: !this.cluster.dtls
                    ? {}
                    : {
                          public: [{ port: dtlsPorts.public, color: "badge-light" }],
                          private: [{ port: dtlsPorts.private, color: "badge-light" }]
                      }
            };
        }
    }
}
