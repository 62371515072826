import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Tag } from "src/app/models/shared";

@Component({
    selector: "app-details-page-header",
    templateUrl: "./details-page-header.component.html",
    styleUrls: ["./details-page-header.component.scss"]
})
export class DetailsPageHeaderComponent {
    @Input() tags: Tag[];
    @Input() canEdit: boolean;
    @Input() canDisable: boolean;
    @Output() closePage = new EventEmitter<Boolean>();

    saving = false;

    cancel() {
        this.closePage.emit(true);
    }
}
