<!-- Save/Reset button -->
<div class="save-layout-section" tourAnchor="secondCustomLayoutAnchor">
    <!-- List -->
    <fa-icon
        class="tourStartButton me-1"
        icon="question-circle"
        #tooltip="matTooltip"
        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_CUSTOMIZABLE_LAYOUTS_BY_CLICKING_HERE' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="left"
        size="md"
        (click)="tourService.start(); mixpanelService.sendEvent('custom layout feature guide')"
    ></fa-icon>
    <label class="mb-0 me-1" tourAnchor="firstCustomLayoutAnchor">{{ "LAYOUT" | translate }}:</label>
    <button
        *ngIf="!isLocked && showSaveButton"
        type="button"
        class="btn btn-sm btn-outline-prim border-0"
        [ngClass]="{ 'btn-primary': layoutChanged, ' btn-outline-primary': !layoutChanged }"
        (click)="saveLayout.emit()"
        [disabled]="!layoutChanged"
    >
        <fa-icon [fixedWidth]="true" icon="check" size="md"></fa-icon>
        <span translate>SAVE</span>
        <span *ngIf="savingLayout" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
    </button>
    <button
        *ngIf="!isLocked && showRevertButton"
        type="button"
        class="btn btn-sm border-0 btn-outline-secondary darkmode-button"
        (click)="revertLayout.emit()"
        [disabled]="!layoutChanged"
    >
        <fa-icon [fixedWidth]="true" icon="trash-undo" size="sm"></fa-icon>
        <span translate>REVERT</span>
    </button>
    <button *ngIf="!isLocked" type="button" class="btn btn-sm border-0 btn-outline-secondary darkmode-button" (click)="resetLayout.emit()">
        <fa-icon [fixedWidth]="true" icon="undo" size="sm"></fa-icon>
        <span translate>RESET</span>
    </button>
    <button type="button" class="btn btn-sm border-0 btn-outline-secondary darkmode-button" (click)="isLocked = !isLocked; lockChanged.emit(isLocked)">
        <fa-icon *ngIf="!isLocked" [fixedWidth]="true" icon="lock" size="sm"></fa-icon>
        <span *ngIf="!isLocked" translate>LOCK</span>
        <fa-icon *ngIf="isLocked && unlockText === 'UNLOCK'" [fixedWidth]="true" icon="unlock" size="sm"></fa-icon>
        <fa-icon *ngIf="isLocked && unlockText !== 'UNLOCK'" [fixedWidth]="true" icon="pencil" size="sm"></fa-icon>
        <span *ngIf="isLocked" translate>{{ unlockText }}</span>
    </button>

    <!-- Dropdown -->
    <!--<div>
        <button
            class="ms-2 btn btn-sm border-0 btn-outline-secondary btn-settings"
            id="layoutSettingsDropdown"
            [matMenuTriggerFor]="layoutMenu"
            container="body"
            ngbTooltip="{{ 'LAYOUT_SETTINGS' | translate }}"
        >
            <fa-layers>
                <fa-icon [icon]="'cog'" size="lg" transform="left-2"></fa-icon>
                <fa-icon
                    class="strokeColor"
                    *ngIf="layoutChanged"
                    [icon]="'circle'"
                    transform="shrink-4 down-8 right-8"
                    style="stroke: var(--white); stroke-width: 40px"
                ></fa-icon>
                <fa-icon class="hoverColor" *ngIf="layoutChanged" [icon]="'asterisk'" style="color: var(--white)" transform="shrink-8 down-8 right-8"></fa-icon>
            </fa-layers>
        </button>
        <div aria-labelledby="layoutSettingsDropdown" class="shadow-sm">
            <mat-menu #layoutMenu="matMenu" yPosition="below">
                <label class="w-100 pt-2 pb-2 ps-3 pe-3 m-0" for="layout" translate>LAYOUT_SETTINGS</label>
                <hr class="mt-0 mb-0" />
                <button type="button" class="text-secondary" (click)="resetLayout.emit()" mat-menu-item>
                    <fa-icon [fixedWidth]="true" icon="undo" size="sm"></fa-icon>
                    <span class="ms-1" translate>RESET_TO_DEFAULT</span>
                </button>
                <button type="button" class="text-secondary" (click)="revertLayout.emit()" mat-menu-item [disabled]="!layoutChanged">
                    <fa-icon [fixedWidth]="true" icon="trash-undo" size="sm"></fa-icon>
                    <span class="ms-1" translate>REVERT_CHANGES</span>
                </button>
                <button type="button" class="text-secondary" (click)="isLocked = !isLocked; lockChanged.emit(isLocked)" mat-menu-item>
                    <fa-icon *ngIf="!isLocked" [fixedWidth]="true" icon="lock" size="sm"></fa-icon>
                    <span *ngIf="!isLocked" class="ms-1" translate>LOCK_CHANGES</span>
                    <fa-icon *ngIf="isLocked" [fixedWidth]="true" icon="unlock" size="sm"></fa-icon>
                    <span *ngIf="isLocked" class="ms-1" translate>UNLOCK_CHANGES</span>
                </button>
                <button type="button" class="text-primary" ngbAutofocus (click)="saveLayout.emit()" mat-menu-item [disabled]="!layoutChanged">
                    <fa-icon [fixedWidth]="true" icon="check" size="md"></fa-icon>
                    <span class="ms-1" translate>SAVE_CHANGES</span>
                    <span *ngIf="savingLayout" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                </button>
            </mat-menu>
        </div>
    </div>-->
</div>
