import { BroadcasterOutput, FeederInput, ReceiverOutput, ZecInput, ZixiPlus } from "../../../models/shared";
import { LocationInfo } from "../../maps/map";
import { BroadcasterLicenseInfo, TunnelServer } from "@zixi/models";

class ZecBase<Input, Output> extends ZixiPlus {
    _frontData?: {
        sortableStatus?: string;
        lastRefresh: string;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo };
    termination_protection?: boolean;
    status: {
        about: {
            appliance: boolean;
            copyright: string;
            host_name: string;
            hostid: string;
            id: string;
            license_ok: boolean;
            platform: string;
            provider: string;
            rtmp_port_ok: boolean;
            service: boolean;
            transcoder: boolean;
            version: string;
            version_build: number;
            version_major: number;
            version_minor: number;
            version_minor2: number;
            admin_login?: boolean;
        };
        activation_key_link: string;
        hostid: string;
        up_time_seconds: number;
        cpu: number;
        inputs?: Input[];
        outputs?: Output[];
        nics: {
            name: string;
            nic: string;
            value: string;
        }[];
        ram: number;
        source_ip: string | number;
        license?: BroadcasterLicenseInfo;
        input_kbps: number;
        output_kbps: number;
    };
    feeder: boolean;
    receiver: boolean;
    Customer: {
        tunnel_servers: TunnelServer[];
    };
}

export class Zec extends ZecBase<ZecInput, BroadcasterOutput> {}

export class Receiver extends ZecBase<undefined, ReceiverOutput> {}

export class Feeder extends ZecBase<FeederInput, undefined> {}
