import { Component, Input, OnInit, Output, EventEmitter, inject } from "@angular/core";
import { map } from "rxjs";
import moment from "moment";
import _ from "lodash";

import { SharedService } from "src/app/services/shared.service";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { EventsService } from "src/app/pages/events/events.service";

import { Tag } from "src/app/models/shared";
import { Constants } from "src/app/constants/constants";
import { QueryParamsService } from "src/app/services/query-params.service";

interface ObjectType {
    name: string;
    type?: string[];
}

export interface TypeFilter {
    text: string;
    color: "danger" | "warning" | "info" | "success" | "secondary";
    key: string;
    enabled: boolean;
}

export interface EventFilter {
    fromDate: moment.Moment;
    toDate: moment.Moment;
    msgFilter: string;
    msgTypes: { [key: string]: boolean };
    objectType: ObjectType;
    resourceTags: number[];
}

@Component({
    selector: "app-events-filter-form",
    templateUrl: "./events-filter-form.component.html",
    styleUrls: ["./events-filter-form.component.scss"]
})
export class EventsFilterFormComponent implements OnInit {
    @Input() filterName: string;
    @Input() saveFilter = false;
    @Input() downloadEvents = false;
    @Input() responsive = false;
    @Input() columns: { any };
    @Input() objectTypes: ObjectType[] = [];
    @Input() resourceTags: Tag[] = [];
    @Input() typeFilters: TypeFilter[] = [
        { text: "Error", color: "danger", key: "error", enabled: true },
        { text: "Warning", color: "warning", key: "warning", enabled: true },
        { text: "Info", color: "info", key: "info", enabled: true },
        { text: "Ok", color: "success", key: "success", enabled: true }
    ];
    @Input() typeFilterLabel = "STAGES";
    @Input() showColumnSelection = true;
    @Input() hasEvents = true;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onApply: EventEmitter<EventFilter> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onReset: EventEmitter<void> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onToggleColumn: EventEmitter<{ [key: string]: boolean }> = new EventEmitter();

    msgFilter = "";
    fromDate: string;
    toDate: string;
    appliedFromDate: string;
    appliedToDate: string;
    constants = Constants;

    selectedResourceTags: number[];
    objectType: ObjectType;
    appliedObjectType: ObjectType;

    showFilter = false;
    showFromPicker = false;
    showToPicker = false;
    fromCounter = 0;
    toCounter = 0;

    private es = inject(EventsService);
    private queryParamsService = inject(QueryParamsService);
    private userService = inject(UsersService);
    sharedService = inject(SharedService);
    userPermissions$ = this.userService.userPermissions.pipe(
        map(
            userPermissions =>
                this.downloadEvents &&
                (userPermissions.is_zixi_support_write || userPermissions.is_zixi_admin || userPermissions.is_admin)
        )
    );

    ngOnInit(): void {
        if (this.objectTypes.length > 0) this.objectType = this.objectTypes[0];

        this.queryParamsService.eventsParams$.subscribe(params => {
            if (params.msgFilter) this.msgFilter = params.msgFilter;
            if (params.fromDate) {
                this.fromDate = params.fromDate.format(this.constants.DATETIME_SELECTOR_FORMAT);
            }
            if (params.toDate) this.toDate = params.toDate.format(this.constants.DATETIME_SELECTOR_FORMAT);
            if (params.msgTypes) {
                this.typeFilters = this.typeFilters.map(type => ({ ...type, enabled: false }));
                for (const [index, iterator] of this.typeFilters.entries()) {
                    if (params.msgTypes[iterator.key]) this.typeFilters[index].enabled = true;
                }
            }
            if (params.objectType)
                this.objectType = this.objectTypes.find(objectType => objectType.name === params.objectType.name);
            if (params.resourceTags) this.selectedResourceTags = params.resourceTags;
        });

        // Check localStorage
        if (this.saveFilter) {
            const ls = localStorage.getItem(`${this.filterName}.filter`);
            if (ls) {
                const filter = JSON.parse(ls);
                if (filter.msgFilter) this.msgFilter = filter.msgFilter;
                if (filter.msgTypes) {
                    this.typeFilters.forEach(type => {
                        if (filter.msgTypes[type.key]) {
                            type.enabled = true;
                        } else type.enabled = false;
                    });
                }
                if (filter.fromDate)
                    this.fromDate = moment(filter.fromDate).format(this.constants.DATETIME_SELECTOR_FORMAT);
                if (filter.toDate) this.toDate = moment(filter.toDate).format(this.constants.DATETIME_SELECTOR_FORMAT);
                if (filter.resourceTags && filter.resourceTags.length) this.selectedResourceTags = filter.resourceTags;
                if (filter.objectType) this.objectType = filter.objectType;
                this.apply();
            }
        }
    }

    apply() {
        this.appliedObjectType = Object.assign({}, this.objectType);
        this.appliedFromDate = this.fromDate;
        this.appliedToDate = this.toDate;
        //
        const msgTypes = {};
        this.typeFilters.map(type => {
            msgTypes[type.key] = type.enabled;
        });
        const eventsFilter: EventFilter = {
            fromDate: this.fromDate ? moment(this.fromDate) : null,
            toDate: this.toDate ? moment(this.toDate) : null,
            msgFilter: this.msgFilter,
            msgTypes,
            objectType: this.objectType,
            resourceTags: this.selectedResourceTags
        };
        this.queryParamsService.updateEventParams(eventsFilter);
        //
        if (this.saveFilter) localStorage.setItem(`${this.filterName}.filter`, JSON.stringify(eventsFilter));
        this.onApply.emit(eventsFilter);
    }

    reset() {
        // clear filter
        this.fromDate = null;
        this.toDate = null;
        this.msgFilter = null;
        this.typeFilters.forEach(type => (type.enabled = true));
        this.objectType = this.objectTypes[0];
        this.selectedResourceTags = [];
        localStorage.removeItem(`${this.filterName}.filter`);
        this.onReset.emit();
    }

    toggleTypeFilter(type: TypeFilter) {
        type.enabled = !type.enabled;
        this.onFilterChange();
    }

    onFilterChange() {
        if (this.responsive) setTimeout(() => this.apply(), 0);
    }

    // From Picker
    toggleFromPicker() {
        this.showFromPicker = true;
    }

    closeFromPicker() {
        window.focus();
        this.fromCounter = 0;
        this.showFromPicker = false;
    }

    clickOutsideFromPicker() {
        this.fromCounter = this.fromCounter + 1;
        if (this.fromCounter > 1) {
            this.closeFromPicker();
        }
    }

    fromDateChanged(event: Date) {
        if (event !== null) {
            this.fromDate = moment(this.fromDate).format(this.constants.DATETIME_SELECTOR_FORMAT);
            this.onFilterChange();
        }
    }

    clearFromDate() {
        this.fromDate = null;
    }

    // To Picker
    toggleToPicker() {
        this.showToPicker = true;
    }

    closeToPicker() {
        window.focus();
        this.toCounter = 0;
        this.showToPicker = false;
    }

    clickOutsideToPicker() {
        this.toCounter = this.toCounter + 1;
        if (this.toCounter > 1) {
            this.closeToPicker();
        }
    }

    clearToDate() {
        this.toDate = null;
    }

    toDateChanged(event: Date) {
        if (event !== null) {
            this.toDate = moment(this.toDate).format(this.constants.DATETIME_SELECTOR_FORMAT);
            this.onFilterChange();
        }
    }

    toggleColumn(column) {
        this.onToggleColumn.emit(column);
    }

    canReportEvents() {
        return this.fromDate && this.toDate && this.objectType.type;
    }

    eventsFilterChangesNotApplied() {
        if (
            this.appliedObjectType?.name === this.objectType?.name &&
            this.appliedFromDate === this.fromDate &&
            this.appliedToDate === this.toDate
        )
            return false;
        else return true;
    }

    getReportParameters() {
        if (!this.canReportEvents()) return;

        const eventsReportParams = {
            fromDate: this.fromDate ? moment(this.fromDate) : undefined,
            toDate: this.toDate ? moment(this.toDate) : undefined,
            msgFilter: this.msgFilter,
            objectType: this.objectType
        };

        return this.es.getEventsParameters(eventsReportParams, true).toString();
    }

    haveObjectTypes() {
        return this.objectTypes.length > 0;
    }
}
