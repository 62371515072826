import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import _ from "lodash";
import { TableSchema } from "../table-list/table-list.component";
import { TourService } from "ngx-ui-tour-md-menu";
import { Subscription } from "rxjs";

// enums
export enum ColumnFilterType {
    STRING = "string",
    STRING_ARRAY = "string_array",
    NUMBER = "number",
    NUMBER_ARRAY = "number_array",
    DURATION = "duration",
    SELECT = "select",
    DATE = "date"
}
enum DurationType {
    MINUTES = "MINUTES",
    HOURS = "HOURS",
    DAYS = "DAYS"
}

enum StringFilterFunction {
    EQUAL = "EQUALS",
    CONTAINS = "CONTAINS",
    START_WITH = "STARTS_WITH"
}
enum NumberFilterFunction {
    EQUAL = "=",
    LARGER_THAN = ">",
    SMALLER_THAN = "<"
}

enum SelectFilterFunction {
    CONTAINS = "IS"
}
// interfaces
export interface ColumnFilter {
    columnHeader: string;
    rawValue: string | number | string[] | number[];
    value: string | number;
    operatorType: string;
    columnType: ColumnFilterType;
}

export interface TableFilter {
    columnHeader: undefined;
    value: string;
}

interface CheckBoxColumnFilter extends Omit<ColumnFilter, "value"> {
    rawValue: string[];
    value: string[];
    options: string[];
}

interface NumberColumnFilter extends Omit<ColumnFilter, "value"> {
    rawValue: string;
    value: number;
}

interface NumberArrayColumnFilter extends Omit<ColumnFilter, "value"> {
    rawValue: string[];
    value: number[];
}

interface DurationColumnFilter extends Omit<ColumnFilter, "value"> {
    rawValue: string;
    value: number;
    durationType: DurationType;
}

interface StringArrayColumnFilter extends Omit<ColumnFilter, "value"> {
    rawValue: string[];
    value: string[];
}

interface FilterFunctions {
    [ColumnFilterType.STRING]: {
        [key in StringFilterFunction]: (cellData: string, filterValue: string) => boolean;
    };
    [ColumnFilterType.STRING_ARRAY]: {
        [key in StringFilterFunction]: (cellData: string[], filterValue: string) => boolean;
    };
    [ColumnFilterType.NUMBER]: {
        [key in NumberFilterFunction]: (cellData: string | number, filterValue: number) => boolean;
    };
    [ColumnFilterType.NUMBER_ARRAY]: {
        [key in NumberFilterFunction]: (cellData: (string | number)[], filterValue: number) => boolean;
    };
    [ColumnFilterType.DURATION]: {
        [key in NumberFilterFunction]: (cellData: string | number, filterValue: number) => boolean;
    };
    [ColumnFilterType.SELECT]: {
        [key in SelectFilterFunction]: (cellData: string, options: string[]) => boolean;
    };
    /* [ColumnFilterType.DATE]: {
        [key in StringFilterFunction]: (cellData: string, filterValue: string) => boolean;
    }; */
}

type Filter =
    | TableFilter
    | ColumnFilter
    | CheckBoxColumnFilter
    | DurationColumnFilter
    | StringArrayColumnFilter
    | NumberArrayColumnFilter;

function validFilterString(value: string | null) {
    return value && value !== "";
}
function validFilterNumber(value: number | string | null) {
    return value != null && !Number.isNaN(value);
}

function parseCellDataNumber(cellData: number | string) {
    return typeof cellData === "string" ? Number.parseInt(cellData) : cellData;
}

function isDurationFilter(filter: Filter): filter is DurationColumnFilter {
    return !!(filter as DurationColumnFilter).durationType;
}

function isNumberFilter(filter: Filter): filter is NumberColumnFilter {
    return !isTableFilter(filter) && filter.columnType === ColumnFilterType.NUMBER;
}

export function isTableFilter(filter: Filter): filter is TableFilter {
    return filter.columnHeader === undefined;
}

export const filterFunctions: FilterFunctions = {
    [ColumnFilterType.STRING]: {
        [StringFilterFunction.EQUAL]: (cellData, filterValue) =>
            !validFilterString(filterValue) || cellData.toLocaleLowerCase() === filterValue.toLocaleLowerCase(),
        [StringFilterFunction.CONTAINS]: (cellData, filterValue) =>
            !validFilterString(filterValue) || cellData.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()),
        [StringFilterFunction.START_WITH]: (cellData, filterValue) =>
            !validFilterString(filterValue) || cellData.toLocaleLowerCase().startsWith(filterValue.toLocaleLowerCase())
    },
    [ColumnFilterType.NUMBER]: {
        [NumberFilterFunction.EQUAL]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || parseCellDataNumber(cellData) === filterValue,
        [NumberFilterFunction.LARGER_THAN]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || parseCellDataNumber(cellData) > filterValue,
        [NumberFilterFunction.SMALLER_THAN]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || parseCellDataNumber(cellData) < filterValue
    },
    [ColumnFilterType.DURATION]: {
        [NumberFilterFunction.EQUAL]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || parseCellDataNumber(cellData) === filterValue,
        [NumberFilterFunction.LARGER_THAN]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || parseCellDataNumber(cellData) > filterValue,
        [NumberFilterFunction.SMALLER_THAN]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || parseCellDataNumber(cellData) < filterValue
    },
    [ColumnFilterType.SELECT]: { [SelectFilterFunction.CONTAINS]: (cellData, options) => options.includes(cellData) },
    [ColumnFilterType.STRING_ARRAY]: {
        [StringFilterFunction.EQUAL]: (cellData, filterValue) =>
            !validFilterString(filterValue) ||
            !!cellData.find(s => s.toLocaleLowerCase() === filterValue.toLocaleLowerCase()),
        [StringFilterFunction.CONTAINS]: (cellData, filterValue) =>
            !validFilterString(filterValue) ||
            !!cellData.find(s => s.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())),
        [StringFilterFunction.START_WITH]: (cellData, filterValue) =>
            !validFilterString(filterValue) ||
            !!cellData.find(s => s.toLocaleLowerCase().startsWith(filterValue.toLocaleLowerCase()))
    },
    [ColumnFilterType.NUMBER_ARRAY]: {
        [NumberFilterFunction.EQUAL]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || !!cellData.find(cd => parseCellDataNumber(cd) === filterValue),
        [NumberFilterFunction.LARGER_THAN]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || !!cellData.find(cd => parseCellDataNumber(cd) > filterValue),
        [NumberFilterFunction.SMALLER_THAN]: (cellData, filterValue) =>
            !validFilterNumber(filterValue) || !!cellData.find(cd => parseCellDataNumber(cd) < filterValue)
    }
};

export const operatorTypes = {
    [ColumnFilterType.STRING]: Object.values(StringFilterFunction),
    [ColumnFilterType.STRING_ARRAY]: Object.values(StringFilterFunction),
    [ColumnFilterType.NUMBER]: Object.values(NumberFilterFunction),
    [ColumnFilterType.NUMBER_ARRAY]: Object.values(NumberFilterFunction),
    [ColumnFilterType.DURATION]: Object.values(NumberFilterFunction),
    [ColumnFilterType.SELECT]: Object.values(SelectFilterFunction)
};

@Component({
    selector: "app-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"]
})
export class FilterComponent implements OnInit, OnDestroy {
    @Input() set schema(schema: TableSchema[]) {
        this.columns = schema.filter(column => column.columnFilterType);
    }

    @Input() filterName: string;
    @Input() tableFilter = "";
    @Input() dataLength: number;
    @Input() filteredLength: number;
    @Input() collapsed: number;
    @Input() showFilter? = true;
    @Input() showTour? = false;
    @Input() childTable? = false;

    @Output() tableFilterChange = new EventEmitter<string>();

    @ViewChild("addFilterTrigger") addFilterTrigger: MatMenuTrigger;
    @ViewChildren("filterOptionsMenuTrigger") filterOptionsMenuTrigger: QueryList<MatMenuTrigger>;
    @ViewChildren("focusInput") focusInputs: QueryList<ElementRef>;

    genericFilter: TableFilter = {
        columnHeader: undefined,
        value: ""
    };

    columns: TableSchema[];
    public filters: Filter[] = [];
    readonly durationTypes = Object.values(DurationType);
    readonly operatorTypes = operatorTypes;

    private tourSubscription: Subscription;

    constructor(public tourService: TourService) {}

    ngOnInit() {
        if (this.tableFilter && this.tableFilter.length > 0) {
            this.loadFilters(this.tableFilter);
        } else {
            if (!this.childTable) {
                const cachedFilter = localStorage.getItem(`${this.filterName}.cacheFilter`);
                if (cachedFilter && cachedFilter.length > 0) {
                    this.tableFilter = cachedFilter;
                    this.loadFilters(this.tableFilter);
                } else this.loadFilters();
            } else this.loadFilters();
        }

        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.anchorId === "firstTableAnchor") {
                if (this.addFilterTrigger) this.addFilterTrigger.closeMenu();
            }
            if (step.step.anchorId === "secondTableAnchor") {
                if (this.addFilterTrigger) this.addFilterTrigger.openMenu();
            }
            if (step.step.anchorId === "fourthTableAnchor") {
                if (this.addFilterTrigger) this.addFilterTrigger.closeMenu();
            }
        });
    }

    ngOnDestroy() {
        this.tourSubscription.unsubscribe();
    }

    loadFilters(filter?: string) {
        if (filter) this.filters = JSON.parse(filter);

        const genericFilter = this.filters.find(f => isTableFilter(f)) as TableFilter | undefined;
        if (genericFilter) this.genericFilter = genericFilter;
        else this.filters.unshift(this.genericFilter);

        setTimeout(() => this.applyFilters(), 1);
    }

    applyFilters() {
        const filtersCopy = _.cloneDeep(this.filters);

        for (const filter of filtersCopy) {
            if (isDurationFilter(filter)) {
                switch (filter.durationType) {
                    case DurationType.MINUTES:
                        filter.value = Number.parseInt(filter.rawValue) * 60;
                        break;
                    case DurationType.HOURS:
                        filter.value = Number.parseInt(filter.rawValue) * 60 * 60;
                        break;
                    case DurationType.DAYS:
                        filter.value = Number.parseInt(filter.rawValue) * 60 * 60 * 24;
                        break;
                }
            } else if (isNumberFilter(filter)) {
                filter.value = Number.parseInt(filter.rawValue);
            } else if (!isTableFilter(filter)) {
                filter.value = filter.rawValue;
            }
        }

        const stringFilter = JSON.stringify(filtersCopy);
        if (!this.childTable) localStorage.setItem(`${this.filterName}.cacheFilter`, stringFilter);
        this.tableFilterChange.emit(stringFilter);
    }

    applyParentFilter(filter?: string) {
        const arr = JSON.parse(filter);
        if (arr[0].value !== this.filters[0].value) this.loadFilters(filter);
    }

    addFilter(column: TableSchema) {
        //TODO Make better! keep DRI
        switch (column.columnFilterType) {
            case ColumnFilterType.SELECT:
                const typeFilter: CheckBoxColumnFilter = {
                    columnHeader: column.header,
                    columnType: column.columnFilterType,
                    rawValue: [...column.columnSelectOptions],
                    value: [...column.columnSelectOptions],
                    options: column.columnSelectOptions,
                    operatorType: SelectFilterFunction.CONTAINS
                };
                this.filters.push(typeFilter);
                break;
            case ColumnFilterType.DURATION:
                const durationFilter: DurationColumnFilter = {
                    columnHeader: column.header,
                    columnType: ColumnFilterType.DURATION,
                    operatorType: NumberFilterFunction.LARGER_THAN,
                    durationType: DurationType.DAYS,
                    rawValue: "",
                    value: 0
                };
                this.filters.push(durationFilter);
                break;
            case ColumnFilterType.STRING:
            case ColumnFilterType.STRING_ARRAY:
                const stringFilter: ColumnFilter = {
                    columnHeader: column.header,
                    columnType: column.columnFilterType,
                    operatorType: StringFilterFunction.CONTAINS,
                    rawValue: "",
                    value: ""
                };
                this.filters.push(stringFilter);
                break;
            case ColumnFilterType.NUMBER:
            case ColumnFilterType.NUMBER_ARRAY:
                const numericFilter: ColumnFilter = {
                    columnHeader: column.header,
                    columnType: column.columnFilterType,
                    operatorType: NumberFilterFunction.LARGER_THAN,
                    rawValue: "",
                    value: ""
                };
                this.filters.push(numericFilter);
                break;
        }
        this.applyFilters();
        // Open new filter mat-menu
        setTimeout(() => {
            this.filterOptionsMenuTrigger.last.openMenu();
            if (this.focusInputs?.last?.nativeElement.localName === "input")
                this.focusInputs?.last?.nativeElement.nativeElement?.focus();
        }, 0);
    }

    removeFilter(i: number) {
        this.filters.splice(i, 1);
        this.applyFilters();
    }

    resetFilterConfig() {
        for (let i = this.filters.length - 1; i >= 0; i -= 1) {
            if (this.filters[i].columnHeader) {
                this.filters.splice(i, 1);
            } else {
                if (this.filters[i].value) {
                    this.filters[i].value = "";
                    this.genericFilter.value = "";
                }
            }
        }
        this.applyFilters();
    }

    addOrRemoveOption(filterValue: string[], newOption: string) {
        const indexOfNewOption = filterValue.indexOf(newOption);
        indexOfNewOption !== -1 ? filterValue.splice(indexOfNewOption, 1) : filterValue.push(newOption);
        this.applyFilters();
    }

    getSelectOptions(columnHeader: string) {
        return this.columns.find(column => column.header === columnHeader)?.columnSelectOptions ?? [];
    }

    menuClosed(filter) {
        if (filter.rawValue === "" || filter.rawValue.length === 0) {
            const index = this.filters.findIndex(
                f => f.columnHeader === filter.columnHeader && f.value === filter.value
            );
            if (index !== -1) this.filters.splice(index, 1);
            this.applyFilters();
        }
    }

    menuOpened(i: number) {
        const input = this.focusInputs?.get(i - 1);
        if (input.nativeElement.localName === "input") input.nativeElement?.focus();
    }
}
