import { OnInit } from "@angular/core";
import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[zmid]"
})
export class ZmidDirective implements OnInit {
    @Input() zmid: string;
    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.el.nativeElement.setAttribute("data-zm", this.zmid);
    }
}
