import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { Subscription } from "rxjs";

import { PidMappingsService } from "../../../pages/pid-mappings/pid-mappings.service";
import { PIDMappingProfile } from "../../../pages/pid-mappings/pid-mapping";

@Component({
    selector: "zx-pid-mapping-profile-select",
    templateUrl: "./zx-pid-mapping-profile-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxPidMappingProfileSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: PIDMappingProfile;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    pidMappingProfiles: PIDMappingProfile[];
    private pidMappingProfilesSubscription: Subscription;

    constructor(private pms: PidMappingsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.pms.refreshPIDMappingProfiles();
        this.pidMappingProfilesSubscription = this.pms.pidMappingProfiles.subscribe(pidMappingProfiles => {
            this.pidMappingProfiles = [...pidMappingProfiles];
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.pidMappingProfilesSubscription.unsubscribe();
    }
}
