import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIResponse, SharedResource } from "../models/shared";
import { Constants } from "../constants/constants";
import { map, catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { prepareToServer } from "src/app/utils/prepare-to-server";
import { SharedResourcePermissions } from "@zixi/models";
export { SharedResourcePermissions };
export type SharedPaged = {
    url: string;
    password: string;
};

@Injectable({
    providedIn: "root"
})
export class SharedPageService {
    constructor(
        private http: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        private translateService: TranslateService
    ) {}

    generateSharedPage(
        sharedPageRoute: string,
        name: string,
        mainObjectId: number,
        mainObjectType: string,
        startTime: string,
        endTime: string,
        permissions: SharedResourcePermissions[]
    ): Observable<{
        result?: SharedPaged;
        error?: string;
    }> {
        const sharedResource = prepareToServer({ name, mainObjectId, mainObjectType, startTime, endTime, permissions });
        return this.http
            .post<APIResponse<{ token: string; password: string }>>(
                Constants.apiUrl + Constants.apiUrls.shared,
                sharedResource
            )
            .pipe(
                map(res => {
                    if (!res.success) {
                        throw res.error;
                    }
                    return {
                        result: {
                            url: `${this.document.location.origin}/${sharedPageRoute}?token=${res.result.token}`,
                            password: res.result.password
                        }
                    };
                }),
                catchError(error => {
                    // eslint-disable-next-line no-console
                    console.log(this.translateService.instant("ERROR"), error);
                    return of({
                        error: `${this.translateService.instant("ERROR")} ${error?.error?.error ?? ""}`
                    });
                })
            );
    }

    regeneratePassword(sharedResource: SharedResource): Observable<{
        result?: { password: string };
        error?: string;
    }> {
        return this.http
            .put<APIResponse<{ password: string }>>(
                `${Constants.apiUrl}${Constants.apiUrls.shared}/${sharedResource.id}/regenerate`,
                {}
            )
            .pipe(
                map(res => {
                    if (!res.success) {
                        throw res.error;
                    }
                    return {
                        result: {
                            password: res.result.password
                        }
                    };
                }),
                catchError(error => {
                    // eslint-disable-next-line no-console
                    console.log(this.translateService.instant("ERROR"), error);
                    return of({
                        error: `${this.translateService.instant("ERROR")} ${error?.error?.error ?? ""}`
                    });
                })
            );
    }

    deleteSharedResource(sharedResource: SharedResource): Observable<Boolean> {
        return this.http
            .delete<APIResponse<string>>(`${Constants.apiUrl}${Constants.apiUrls.shared}/${sharedResource.id}`)
            .pipe(map(res => res.success));
    }
}
