import { Component, Input } from "@angular/core";
import { MediaConnectSource, Source } from "src/app/models/shared";

@Component({
    selector: "app-zx-input-source-col",
    templateUrl: "./zx-input-source-col.component.html"
})
export class ZxInputSourceColComponent {
    @Input() source: MediaConnectSource | Source;
    @Input() searchTerm: string;
    @Input() ngIf? = true;
}
