<div class="row">
    <div class="col">
        <button type="button" class="btn btn-outline-primary me-2" (click)="explain()" [disabled]="loading">
            <span>{{ "EXPLAIN_NOT_RECOVERED" | translate }}</span>
        </button>
        <button type="button" class="btn btn-outline-primary me-2" (click)="close()" [disabled]="loading" *ngIf="!iframeDisabled">
            <span>{{ "CLOSE" | translate }}</span>
        </button>
    </div>
</div>
<ngb-alert *ngIf="error" type="danger" [dismissible]="false" class="mt-3">
    <strong>{{ "ERROR" | translate }}</strong> - {{ error }}
</ngb-alert>
<ngb-alert *ngIf="!iframeDisabled && dateRangeChanged" type="info" [dismissible]="false" class="mt-3">
    <span [innerHTML]="'CLICK_TO_UPDATE_EXP' | translate"></span>
</ngb-alert>
<div class="nd-viz mt-3">
    <spinner-animation *ngIf="loading"></spinner-animation>
    <div class="row" *ngIf="!iframeDisabled">
        <div class="col">
            <iframe
                #iframe
                src="../../../../../assets/iframes/nd.html"
                frameborder="no"
                scrolling="no"
                marginwidth="0"
                marginheight="0"
                hspace="0"
                vspace="0"
                class="iframe-load-animation"
                (load)="iframeOnLoad()"
            ></iframe>
        </div>
    </div>
</div>
<zx-history-graph
    [title]=""
    [hideAddIncident]="true"
    [graphUrl]="targetHealthscoreDashboardLink"
    (from)="setFrom($event)"
    (to)="setTo($event)"
></zx-history-graph>
