<div [ngClass]="{ loading: saving }">
    <div class="modal-header">
        <h3 class="modal-title">
            <span *ngIf="canEdit">{{ "EDIT" | translate }}&nbsp;</span>{{ "TAGS" | translate }}
        </h3>
        <button type="button" class="close" aria-label="Close" [disabled]="saving" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
        <div class="modal-body pb-0">
            <div class="form-group" *ngIf="!canEdit">
                <label translate>
                    ACCESS_TAGS
                    <fa-icon icon="info-circle" [stickyPopover]="ATContent" popoverClass="mimic-tooltip"></fa-icon>
                    <ng-template #ATContent translate>TOOLTIP.ACCESS_TAGS</ng-template>
                </label>
                <ul class="mb-0 list-style-none mimic-tags">
                    <li *ngFor="let tag of object.resourceTags; index as i">
                        {{ tag.name }}
                    </li>
                </ul>
            </div>
            <!-- Access Tags -->
            <zx-access-tags-select
                *ngIf="canEdit"
                [formControl]="tagsControl"
                [label]="'TAGS' | translate"
                name="resource_tag_ids"
                [isParentFormSubmitted]="form.submitted"
            >
            </zx-access-tags-select>
            <app-error [marginBottom]="true" [marginTop]="false"></app-error>
        </div>
        <div class="modal-footer">
            <button *ngIf="canEdit" type="button" class="btn btn-outline-secondary" [disabled]="saving" (click)="activeModal.close()">
                <fa-icon icon="times"></fa-icon>
                {{ "CANCEL" | translate }}
            </button>
            <button
                *ngIf="canEdit"
                type="button"
                class="btn btn-primary"
                ngbAutofocus
                [disabled]="saving"
                zmid="form-submit"
                type="submit"
                [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
            >
                <fa-icon icon="check" size="sm"></fa-icon>
                {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
            </button>
            <button *ngIf="!canEdit" type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
                {{ "CLOSE" | translate }}
            </button>
        </div>
    </form>
</div>
