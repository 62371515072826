import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, Observable, ReplaySubject } from "rxjs";
import { share, map } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "./../../constants/constants";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";
import { Customer } from "@zixi/models";

export class IoServer {
    _frontData?: {
        sortableStatus: string;
        sortableTunnels: string;

        onlineTunnels?: number;
        offlineTunnels?: number;
    };
    id: number;
    dns_prefix: string;
    eip?: string;
    created_at?: string;
    updated_at?: string;
    shared: number;
    instance_id: string;
    instance_type: "t4g.medium" | "t4g.xlarge" | "t4g.2xlarge";
    ami: string;
    region: string | null;
    Customer?: Customer;

    status?: {
        cpu: number;
        dns_prefix: string;
        domain: string;
        online: boolean;
        status: string;
        activeTunnels: number;
        configuredTunnels: number;
        uptime: number;
    };
}

class AMIOption {
    ami_id: string;
    name: string;
    description: string;

    created_at: string;
}

@Injectable({
    providedIn: "root"
})
export class IoServersService {
    ioServers: Observable<IoServer[]>;
    private ioServers$: ReplaySubject<IoServer[]>;
    private dataStore: {
        ioServers: IoServer[];
    };

    private lastRefresh: number;

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            ioServers: []
        };

        this.lastRefresh = null;

        this.ioServers$ = new ReplaySubject<IoServer[]>(1);
        this.ioServers = this.ioServers$.asObservable();
    }

    private prepIoServer(ioServer: IoServer) {
        ioServer._frontData = {
            sortableStatus: "",
            sortableTunnels: ""
        };

        if (ioServer.status?.activeTunnels != null && ioServer.status?.configuredTunnels != null) {
            ioServer._frontData.onlineTunnels = ioServer.status.activeTunnels;
            ioServer._frontData.offlineTunnels = ioServer.status.configuredTunnels - ioServer.status.activeTunnels;
        }

        if (ioServer.status?.online) ioServer._frontData.sortableStatus += "1";
        else ioServer._frontData.sortableStatus += "0";

        ioServer._frontData.sortableTunnels += "-" + ioServer._frontData.onlineTunnels ?? 0;
        ioServer._frontData.sortableTunnels += "-" + ioServer._frontData.offlineTunnels ?? 0;
    }

    private updateStore(newIoServer: IoServer, merge: boolean): void {
        this.prepIoServer(newIoServer);

        const currentIoServerIndex = this.dataStore.ioServers.findIndex(ioServer => ioServer.id === newIoServer.id);
        if (currentIoServerIndex === -1) {
            this.dataStore.ioServers.push(newIoServer);
            return;
        } else if (merge) {
            const currentIoServer = this.dataStore.ioServers[currentIoServerIndex];

            Object.assign(currentIoServer, newIoServer);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (currentIoServer[overwrite.id] == null) currentIoServer[overwrite.obj] = null;
            });
        } else {
            this.dataStore.ioServers[currentIoServerIndex] = newIoServer;
        }
    }

    refreshIoServers(force?: boolean): Observable<IoServer[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastRefresh <= 60000) return this.ioServers;
        this.lastRefresh = _.now();

        const ioServers$ = this.http
            .get<{ success: boolean; result: IoServer[] }>(Constants.apiUrl + Constants.apiUrls.ioServers)
            .pipe(share());

        ioServers$.subscribe(
            data => {
                const ioServers = data.result;

                this.dataStore.ioServers.forEach((existingIoServer, existingIndex) => {
                    const newIndex = ioServers.findIndex(ioServer => ioServer.id === existingIoServer.id);
                    if (newIndex === -1) this.dataStore.ioServers.splice(existingIndex, 1);
                });

                ioServers.forEach(refreshedIoServer => this.updateStore(refreshedIoServer, true));

                this.ioServers$.next(Object.assign({}, this.dataStore).ioServers);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SERVERS"), error)
        );
        return ioServers$.pipe(map(r => r.result));
    }

    getCachedIoServer(id: number) {
        if (this.dataStore.ioServers && id) return this.dataStore.ioServers.find(ios => ios.id === id);
        else return undefined;
    }

    async deleteIoServer(ioServer: IoServer) {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl + Constants.apiUrls.ioServers + "/" + `${ioServer.id}`
                )
                .toPromise();

            const deletedId: number = result.result;
            const ioServerIndex = this.dataStore.ioServers.findIndex(f => f.id === deletedId);
            if (ioServerIndex !== -1) this.dataStore.ioServers.splice(ioServerIndex, 1);

            this.ioServers$.next(Object.assign({}, this.dataStore).ioServers);
            return true;
        } catch (error) {
            return false;
        }
    }

    async addIoServer(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<{ success: boolean; result: IoServer }>(Constants.apiUrl + Constants.apiUrls.ioServers, model)
                .toPromise();
            const ioServer: IoServer = result.result;

            this.updateStore(ioServer, true);

            this.ioServers$.next(Object.assign({}, this.dataStore).ioServers);
            return ioServer;
        } catch (error) {
            return false;
        }
    }

    async updateIoServer(ioServer: IoServer, model: Record<string, unknown>) {
        try {
            const result = await this.http
                .put<{ success: boolean; result: IoServer }>(
                    Constants.apiUrl + Constants.apiUrls.ioServers + "/" + `${ioServer.id}`,
                    model
                )
                .toPromise();
            const updatedIoServer: IoServer = result.result;

            this.updateStore(updatedIoServer, true);

            this.ioServers$.next(Object.assign({}, this.dataStore).ioServers);
            return updatedIoServer;
        } catch (error) {
            return false;
        }
    }

    async options() {
        try {
            const result = await firstValueFrom(
                this.http.get<{ success: boolean; result: Record<string, AMIOption[]> }>(
                    Constants.apiUrl + Constants.apiUrls.ioServers + "/amis"
                )
            );

            const options: Record<string, AMIOption[]> = result.result;
            return options;
        } catch (error) {
            return false;
        }
    }
}
