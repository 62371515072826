import { Pipe, PipeTransform } from "@angular/core";
import { BroadcasterInput, ZecInput } from "./../models/shared";

@Pipe({
    name: "broadcasterInput"
})
export class BroadcasterInputPipe implements PipeTransform {
    transform(input: BroadcasterInput): string {
        let type = input.type_string;
        if (type === "rtmp_pull" || type === "rtmp_push") type = "RTMP";
        if (input.error_code) {
            if (type !== "Transcoded")
                return (
                    input.id +
                    " (" +
                    type +
                    ": " +
                    (input.error && input.error.length > 0 ? input.error : "Error") +
                    ")"
                );
            else {
                let error = input.transcoder_error;
                if (!error || error.length === 0) error = input.error;
                if (!error || error.length === 0) error = "Transcoder Error";
                return input.id + " (" + type + ": " + error.trim() + ")";
            }
        } else if (input.status === "Offline") return input.id + " (" + type + ": " + input.status + ")";
        else if (type === "HLS") return input.id + " (" + type + ": " + input.hls_url + ")";
        else return input.id + " (" + type + ": " + input.source + ")";
    }
}

@Pipe({
    name: "broadcasterInputPartial"
})
export class BroadcasterInputPartialPipe implements PipeTransform {
    transform(input: BroadcasterInput | ZecInput): string {
        let type = input.type_string;
        if (type === "rtmp_pull" || type === "rtmp_push") type = "RTMP";
        if (input.error_code) {
            if (type !== "Transcoded")
                return type + ": " + (input.error && input.error.length > 0 ? input.error : "Error");
            else {
                let error = input.transcoder_error;
                if (!error || error.length === 0) error = input.error;
                if (!error || error.length === 0) error = "Transcoder Error";
                return type + ": " + error.trim();
            }
        } else if (input.status === "Offline") return type + ": " + input.status;
        else if (type === "HLS") return type + ": " + input.hls_url;
        else return type + ": " + input.source;
    }
}
