<span class="d-inline-block">
    <fa-icon
        icon="info-circle"
        size="sm"
        [ngClass]="{
            'status-error': passwordLevel === 'low',
            'status-warning': passwordLevel === 'fair',
            'status-good': passwordLevel === 'good'
        }"
        [ngbTooltip]="PasswordStrengthContent"
        triggers="hover click"
        [closeDelay]="500"
    ></fa-icon>
    <ng-template #PasswordStrengthContent>Password level: {{ passwordLevel }}</ng-template>
</span>
