<ng-select
    class="form-control minw-300px"
    name="{{ name }}"
    [disabled]="disabled"
    [items]="sources"
    [clearable]="clearable || false"
    [loading]="loading"
    bindLabel="name"
    bindValue="id"
    placeholder="{{ 'SELECT' | translate }} {{ 'SOURCE' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    required="{{ required || false }}"
>
    <ng-template ng-label-tmp let-item="item" *ngIf="!loading"> <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }} </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}
    </ng-template>
</ng-select>
