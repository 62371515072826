import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-details-page-header-action-buttons",
    templateUrl: "./details-page-header-action-buttons.component.html"
})
export class DetailsPageHeaderActionButtonsComponent {
    @Input() canEdit: boolean;
    @Input() isEnabled: boolean;
    @Input() canToggle = true;
    @Input() canEnable? = true;
    @Input() muted: boolean;
    @Input() editUrl: string[];
    @Input() refreshing? = false;
    @Output() toggleMute = new EventEmitter<void>();
    @Output() toggle = new EventEmitter<void>();
    @Output() refresh = new EventEmitter<void>();
    router = inject(Router);

    edit() {
        this.router.navigate(this.editUrl);
    }
}
