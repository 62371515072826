import { Component, Input } from "@angular/core";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { SharedService } from "src/app/services/shared.service";
import { GridObject } from "../grid-detail.component";

@Component({
    selector: "app-grid-objects-column",
    templateUrl: "./grid-objects-column.component.html"
})
export class GridObjectsColumnComponent {
    @Input() searchTerm: string[];
    @Input() object: GridObject;

    constructor(private targetsService: TargetsService, public sharedService: SharedService) {}
}
