import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Subscription } from "rxjs";

import { ErrorService } from "./error.service";

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html"
})
export class ErrorComponent implements OnInit, OnDestroy {
    @Input() marginTop?: boolean;
    @Input() marginBottom?: boolean;

    errorResponse: HttpErrorResponse;
    error: {
        status: number;
        statusText: string;
        message: string;
    };

    private errorSubscription: Subscription;
    @Output() tagIsDeletableFound = new EventEmitter<boolean>();

    constructor(private es: ErrorService) {}

    ngOnInit() {
        // TODO: update this component to Zod's error structure, it currently just shows a generic error
        this.errorSubscription = this.es.lastHttpErrorResponse.subscribe(errorResponse => {
            if (errorResponse === null || errorResponse.status === 428 || errorResponse.status === 424)
                this.error = null;
            else {
                this.errorResponse = errorResponse;
                this.error = {
                    status: errorResponse.status,
                    statusText: errorResponse.statusText,
                    message: errorResponse?.error?.error || (errorResponse as any)?.body?.error // In case the error comes from zen-rpc its exists in the body
                };
            }
        });
    }

    accessTagError() {
        if (this.error?.message?.includes("Tag references")) {
            /* setTimeout(() => {
                this.error = null;
            }, 0); */
            return true;
        } else return false;
    }

    ngOnDestroy() {
        if (this.errorSubscription) this.errorSubscription.unsubscribe();
    }
}
