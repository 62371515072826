import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { Tag } from "../../../models/shared";

import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class TagsService {
    tags: Observable<Tag[]>;
    private tags$: ReplaySubject<Tag[]>;
    private dataStore: {
        tags: Tag[];
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            tags: []
        };

        this.tags$ = new ReplaySubject(1) as ReplaySubject<Tag[]>;
        this.tags = this.tags$.asObservable();
    }

    private prepTag(tag: Tag) {
        return tag;
    }

    private updateStore(tag: Tag, merge: boolean): void {
        this.prepTag(tag);

        const currentIndex = this.dataStore.tags.findIndex(g => g.id === tag.id);
        if (currentIndex === -1) {
            this.dataStore.tags.push(tag);
            return;
        } else if (merge) {
            const current = this.dataStore.tags[currentIndex];

            Object.assign(current, tag);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.tags[currentIndex] = tag;
        }
    }

    refreshTags(): Observable<Tag[]> {
        const tags$ = this.http
            .get<{ result: Tag[]; success: boolean }>(Constants.apiUrl + Constants.apiUrls.resourceTags)
            .pipe(share());
        tags$.subscribe(
            data => {
                const tags: Tag[] = data.result;

                this.dataStore.tags.forEach((existing, existingIndex) => {
                    const newIndex = tags.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.tags.splice(existingIndex, 1);
                });

                tags.forEach(tp => this.updateStore(tp, true));

                this.tags$.next(Object.assign({}, this.dataStore).tags);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return tags$.pipe(map(r => r.result));
    }

    async addTag(model: string | Tag): Promise<Tag | false> {
        try {
            const result = await this.http
                .post<{ success: boolean; result: Tag }>(Constants.apiUrl + Constants.apiUrls.resourceTags, model)
                .toPromise();
            const tag: Tag = result.result;

            this.updateStore(tag, false);

            this.tags$.next(Object.assign({}, this.dataStore).tags);
            return tag;
        } catch (error) {
            return false;
        }
    }

    async deleteTag(tag: Tag | number, force_delete = false): Promise<boolean> {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl +
                        Constants.apiUrls.resourceTags +
                        "/" +
                        `${typeof tag === "number" ? tag : tag.id}`,
                    {
                        params: {
                            force_delete: force_delete ? "true" : "false"
                        }
                    }
                )
                .toPromise();

            const deletedId: number = result.result;

            const index = this.dataStore.tags.findIndex(tp => tp.id === deletedId);
            if (index !== -1) this.dataStore.tags.splice(index, 1);

            this.tags$.next(Object.assign({}, this.dataStore).tags);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateTag(tag: Tag | number, model: Tag | string): Promise<Tag | false> {
        try {
            const result = await this.http
                .put<{ success: boolean; result: Tag }>(
                    Constants.apiUrl +
                        Constants.apiUrls.resourceTags +
                        "/" +
                        `${typeof tag === "number" ? tag : tag.id}`,
                    typeof model === "string" ? { name: model } : model
                )
                .toPromise();

            const updatedTag: Tag = result.result;
            this.updateStore(updatedTag, true);

            this.tags$.next(Object.assign({}, this.dataStore).tags);

            return updatedTag;
        } catch (error) {
            return false;
        }
    }

    isObjectVip(Tags?: Tag[]): boolean {
        for (const tag of Tags ?? []) {
            if (tag.vip) {
                return true;
            }
        }
        return false;
    }
}
